import useInputValidate from "hooks/useInputValidate/useInputValidate"
import { ChangeEvent, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { useAddGroupMemberMutation, useEditGroupMemberMutation } from "services/api/livelihoodSlice"
import { LOAN_STATUS } from "types/livelihood"

interface Props {
  onClose: () => void
  member?: any
}

export default function useSingleMember({ member, onClose }: Props) {
  const { validateFormData } = useInputValidate()
  const { id: groupId } = useParams()
  const [formData, setFormData] = useState({
    firstName: member?.first_name ?? "",
    lastName: member?.last_name ?? "",
    gender: member?.gender ?? "",
    nin: member?.nin ?? "",
    bvn: member?.bvn ?? "",
    savingPerCycle: member?.saving_per_cycle ?? "",
    amountSaved: member?.amount_saved ?? "",
    loanCollected: member?.loan_collected ?? "",
    loanDate: member?.loan_date ?? "",
    repaymentStatus: member?.repayment_status ?? "",
    reasonForDefaulting: member?.reason_for_defaulting ?? "",
    remark: member?.remark ?? "",
  })

  const [addMember, { isLoading: isAddingMember }] = useAddGroupMemberMutation()
  const [editMember, { isLoading: isEditingMember }] = useEditGroupMemberMutation()

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    if (["nin", "bvn"].includes(name)) {
      setFormData({ ...formData, [name]: value.replace(/[^0-9]/g, "") })
      return
    }
    setFormData({ ...formData, [name]: value })
  }

  const submit = async (e: any) => {
    e.preventDefault()
    let payload
    const { amountSaved, savingPerCycle, loanCollected } = formData
    payload = [
      {
        ...formData,
        amountSaved: Number(amountSaved),
        savingPerCycle: Number(savingPerCycle),
        loanCollected: Number(loanCollected),
      },
    ]
    if (formData.repaymentStatus !== LOAN_STATUS.DEFAULTER) {
      delete payload[0].reasonForDefaulting
    }
    if (!formData.loanCollected) {
      delete payload[0].loanDate
      delete payload[0].repaymentStatus
    }
    const res = !member
      ? await addMember({ members: payload, groupId }).unwrap()
      : await editMember({ ...payload[0], memberId: member?.member_id }).unwrap()
    if ([200, 201].includes(res?.code)) {
      toast.success(res?.message)
      onClose()
    }
  }

  const buttonStatus = useMemo(() => {
    const { loanCollected, loanDate, repaymentStatus, gender } = formData
    if (loanCollected) return !loanDate || !repaymentStatus
    return !validateFormData({ ...formData }) || !gender
  }, [formData])

  return {
    submit,
    formData,
    setFormData,
    handleChange,
    loading: isAddingMember || isEditingMember,
    buttonStatus,
  }
}
