import { Button, Modal } from "components/core"
import { GeneratePaymentScheduleProps } from "types/payments"
import { downloadPdf } from "utils/exportData"
import PaymentTable from "./PaymentTable/PaymentTable"
import useDownloadPayments from "./useDownloadPayments"
import { numberToWords } from "utils/convertNumberToText"
import { addCommasToNumbers } from "utils/addCommasToNumbers"
import { ReactComponent as FGLogo } from "assets/svg/fg-logo.svg"
import { ReactComponent as PaymentsLogo } from "assets/svg/payments.svg"

const DownloadPaymentSchedule = ({ onClose }: GeneratePaymentScheduleProps) => {
  const elementId = "divToPrint"
  const today = new Date()
  const formattedDate = `${String(today.getDate()).padStart(2, "0")}/${String(today.getMonth() + 1).padStart(
    2,
    "0",
  )}/${today.getFullYear()}`

  const { downloadData, tableData, paymentDate } = useDownloadPayments()

  const downloadName =
    downloadData &&
    `${downloadData?.state}-${downloadData?.intervention_title}-${downloadData?.payment_cycle}-${formattedDate}`

  return (
    <main>
      <Modal
        title={`Download Payment Schedule (${downloadData?.schedule_reference})`}
        position="modal-right"
        width="w-[631px]"
        closeModal={onClose}
      >
        <div>
          <div className="modal-right-body" id={elementId}>
            <div>
              <div className="flex justify-between items-center mb-2">
                <div>
                  <PaymentsLogo />
                </div>
                <div>
                  <FGLogo />
                </div>
              </div>
              <div className="text-headers text-sm font-semibold uppercase border-b-[1px] border-headers pb-3">
                <h1 className="text-center">National Cash Transfer Office</h1>
                <h1 className="">Federal Ministry of Humanitarian Affairs And Poverty Alleviation</h1>
              </div>
              <h1 className="text-headers text-sm font-semibold pt-3 flex justify-end">DATE: {formattedDate}</h1>

              <div className="text-body font-semibold text-xs space-y-1">
                <h1>TO: Project Accountant</h1>
                <h1>FROM: National Project Management</h1>
                <h1>Dear Sir/Ma</h1>
              </div>

              <div className="my-6">
                <h1 className="text-center font-semibold mb-4">PAYMENT ADVICE</h1>
                <p className="text-body capitalize">
                  The Sum of {addCommasToNumbers(downloadData?.amount)} {`(${numberToWords(downloadData?.amount)})`}{" "}
                  Naira Only For The Month of {`(${paymentDate})`} <span className="lowercase">being </span>Payment to
                  CCT Beneficiaries From The {downloadData?.intervention_title} is Hereby Approved As Detailed Below:
                </p>
              </div>

              <div className="mb-6 text-sm">
                <PaymentTable data={tableData} />
              </div>

              <div className="flex flex-col gap-y-2 font-semibold text-headers text-sm mt-6">
                <p>Abdullahi Alhassan Imam, </p> <span>FCNA, NIM, CITN</span>
                <p>NPM (NCTO)</p>
              </div>
            </div>
          </div>
          <div className="modal-right-button-container mb-3">
            <Button
              theme="primary"
              className="min-w-[105px] mr-[8px] p-[8px]"
              onClick={() => downloadPdf(elementId, downloadName)}
            >
              Download
            </Button>

            <Button theme="transparent" className="w-[105px] px-[30px]" type="button" onClick={onClose}>
              Discard
            </Button>
          </div>
        </div>
      </Modal>
    </main>
  )
}

export default DownloadPaymentSchedule
