import { Button, Modal, Dropdown } from "components/core"
import { GeneratePaymentScheduleProps } from "types/payments"
import usePaymentUnit from "pages/payments/PaymentUnit/usePaymentUnit"
import { FormEventHandler } from "react"
import LoadingSchedule from "./LoadingSchedule/LoadingSchedule"

export const GeneratePaymentSchedule = ({ onClose }: GeneratePaymentScheduleProps) => {
  const {
    generateScheduleForm,
    setGenerateScheduleForm,
    interventionsOptions,
    paymentCycle,
    paymentPSP,
    paymentStates,
    isFetchingInterventions,
    createPaymentSchedule,
    isCreatingPaymentSchedule,
    isFetchingPaymentCycle,
    isFetchingIntervention,
  } = usePaymentUnit()

  const handleDisabledButton =
    generateScheduleForm?.intervention_id.length === 0 ||
    generateScheduleForm?.state?.length === 0 ||
    generateScheduleForm?.type?.length === 0

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e): void => {
    e.preventDefault()
    createPaymentSchedule(onClose)
  }

  return (
    <main data-testid="generate-payment-schedule">
      <Modal title={"Generate Payment Schedule"} position="modal-right" width="w-[432px]" closeModal={onClose}>
        {isCreatingPaymentSchedule ? (
          <LoadingSchedule />
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="modal-right-body">
              <Dropdown
                label={"Intervention"}
                loading={isFetchingInterventions}
                options={interventionsOptions}
                enableSearchOptions={true}
                selected={generateScheduleForm.intervention_id?.label}
                onSelect={(data: any) => {
                  setGenerateScheduleForm({ ...generateScheduleForm, intervention_id: data })
                }}
              />
              <Dropdown
                label="State"
                loading={isFetchingIntervention}
                options={paymentStates ?? []}
                multiple={false}
                selected={generateScheduleForm.state?.label}
                onSelect={(data: any) => {
                  setGenerateScheduleForm({ ...generateScheduleForm, state: data })
                }}
              />
              <Dropdown
                label="Payment Cycle"
                loading={isFetchingPaymentCycle}
                options={paymentCycle}
                enableSearchOptions={true}
                selected={String(generateScheduleForm.payment_cycle) || "0"}
                disabled={true}
                onSelect={() => {
                  return
                }}
              />

              <Dropdown
                label="Payment recurrence"
                options={[]}
                selected={generateScheduleForm?.payment_recurrence}
                onSelect={() => {
                  return
                }}
                disabled={true}
              />
              <Dropdown
                label="PSP"
                options={paymentPSP}
                selected={generateScheduleForm.type}
                onSelect={(data: any) => {
                  setGenerateScheduleForm({ ...generateScheduleForm, type: data.value })
                }}
              />
            </div>
            <div className="modal-right-button-container mb-3">
              <Button
                theme="primary"
                className="min-w-[105px] mr-[8px] p-[8px]"
                type="submit"
                loading={isCreatingPaymentSchedule || isFetchingPaymentCycle}
                disabled={isFetchingPaymentCycle || isCreatingPaymentSchedule || handleDisabledButton}
              >
                Generate Schedule
              </Button>
              <Button theme="transparent" className="w-[105px] px-[30px]" type="button" onClick={onClose}>
                Discard
              </Button>
            </div>
          </form>
        )}
      </Modal>
    </main>
  )
}
