import { Accordion, Button, Checkbox, Input, Modal, Textarea } from "components/core"
import useRoleManagement from "./useRoleManagement"
import { SingleRole } from "types/roles"

interface RoleManagementProps {
  onClose: () => void
  title: string
  role?: SingleRole
}

export const RoleManagement = ({ onClose, title, role }: RoleManagementProps) => {
  const {
    onSubmit,
    formData,
    handleChange,
    permissions,
    toggleSelectAllActions,
    selectPermission,
    button,
    isLoading,
    isLoadingPermissions,
  } = useRoleManagement(title, onClose, role)

  return (
    <Modal title={`${title} role`} position="modal-right" width="w-[702px]" closeModal={onClose}>
      <form className="relative" onSubmit={onSubmit}>
        <div className="modal-right-body">
          <div className="pb-[24px] border-b border-b-[#E6E6E6] mb-[24px]">
            <Input
              label="Role Name"
              type="text"
              id="roleName"
              name="roleName"
              disabled={title === "edit"}
              // readOnly={loading}
              value={formData?.roleName}
              onChange={handleChange}
              className="mb-[16px]"
            />
            <Textarea
              label="Description"
              id="description"
              name="description"
              rows={4}
              placeholder="Type here..."
              // readOnly={loading}
              value={formData?.description}
              onChange={handleChange}
              maxLength={320}
            />
          </div>
          <div>
            <p className="text-lg text-headers font-medium">Permissions</p>
            {isLoadingPermissions && <p className="text-sm">Fetching resources...</p>}
            {permissions?.map((item: any) => (
              <Accordion
                key={item.module}
                className="pt-[16px] border-b border-b-neutral_200"
                header={
                  <div className="flex items-center">
                    <Checkbox
                      id={item.module}
                      className="text-12 text-neutral_400 font-normal"
                      checked={item.action.length && item.action.length === item.availableActions.length}
                      onChange={(e) => toggleSelectAllActions(e, item.module)}
                    />
                    <h6 className="text-base text-headers font-semibold ml-[8px]">{item.module}</h6>
                  </div>
                }
                status={`
                                ${
                                  item.action.length && item.action.length === item.availableActions.length
                                    ? "All"
                                    : item.action.length
                                } Selected
                                `}
              >
                <>
                  {item.availableActions.map((action: string) => (
                    <Checkbox
                      key={action}
                      label={action}
                      id={action}
                      checked={item.action.includes(action)}
                      className="text-base text-body font-normal mr-[16px] mb-[4px]"
                      onChange={(e) => selectPermission(e, action, item.module)}
                    />
                  ))}
                </>
              </Accordion>
            ))}
          </div>
        </div>
        <div className="modal-right-button-container">
          <Button
            theme="primary"
            size="44"
            className="min-w-[105px] mr-[8px]"
            loading={isLoading}
            disabled={button.disabled}
          >
            {button.name}
          </Button>
          <Button
            theme="transparent"
            size="44"
            className="w-[105px]"
            //loading={loading}
            type="button"
            onClick={onClose}
            //disabled={button.disabled}
          >
            Discard
          </Button>
        </div>
      </form>
    </Modal>
  )
}
