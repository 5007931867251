import { createApi } from "@reduxjs/toolkit/query/react"
import { axiosBaseQuery } from "../axiosBaseQuery"

const baseUrl = `${process.env.REACT_APP_BASE_URL}/reports`

export const reportSlice = createApi({
  reducerPath: "reportSlice",
  baseQuery: axiosBaseQuery({ baseUrl }),
  tagTypes: [
    "reports",
    "report",
    "intervention-summary",
    "coresponsibility-summary",
    "livelihood-summary",
    "grm-summary",
    "beneficiary-summary",
    "payment-unit-summary",
    "account-summary",
    "audit-summary",
  ],
  refetchOnMountOrArgChange: true,
  endpoints(build) {
    return {
      generateReport: build.mutation<any, any>({
        query: (data) => ({ url: "", method: "post", data }),
        invalidatesTags: ["reports"],
      }),
      getReports: build.query<any, any>({
        query: ({ type, ...params }) => ({ url: `?type=${type}`, method: "get", params }),
        providesTags: ["reports"],
        // providesTags: (result, error, { type }) => {
        //   switch (type) {
        //     case "intervention":
        //       return [{ type: "reports", id: "intervention" }]
        //     case "beneficiary":
        //       return [{ type: "reports", id: "beneficiary" }]
        //     case "payment-unit":
        //       return [{ type: "reports", id: "payment-unit" }]
        //     case "account":
        //       return [{ type: "reports", id: "account" }]
        //     default:
        //       return [{ type: "reports" }]
        //   }
        // },
      }),
      getReport: build.query<any, any>({
        query: ({ reportId, ...params }) => ({ url: `/${reportId}`, method: "get", params }),
        providesTags: (result, error, { reportId }) => [{ type: "report", id: reportId }],
      }),
      deleteReport: build.mutation<any, any>({
        query: (reportId) => ({ url: `/${reportId}`, method: "delete" }),
        invalidatesTags: ["reports"],
      }),
      getInterventionSummary: build.query<any, any>({
        query: (params) => ({ url: "/intervention/summary", method: "get", params }),
        providesTags: ["intervention-summary"],
      }),
      exportReport: build.query<any, any>({
        query: (reportId) => ({ url: `/${reportId}?noLimit=true`, method: "get" }),
      }),
      getCoresponsiblitySummary: build.query<any, any>({
        query: (params) => ({ url: "/co-responsibility/summary", method: "get", params }),
        providesTags: ["coresponsibility-summary"],
      }),
      getLivelihoodSummary: build.query<any, any>({
        query: (params) => ({ url: "/livelihood/summary", method: "get", params }),
        providesTags: ["livelihood-summary"],
      }),
      getGRMSummary: build.query<any, any>({
        query: (params) => ({ url: "/grm/summary", method: "get", params }),
        providesTags: ["grm-summary"],
      }),
      getAuditSummary: build.query<any, any>({
        query: (params) => ({ url: "/audit/summary", method: "get", params }),
        providesTags: ["audit-summary"],
      }),
      getBeneficiarySummary: build.query<any, any>({
        query: (params) => ({ url: "/beneficiary/summary", method: "get", params }),
        providesTags: ["beneficiary-summary"],
      }),
      getPaymentUnitSummary: build.query<any, any>({
        query: (params) => ({ url: "/payment-unit/summary", method: "get", params }),
        providesTags: ["payment-unit-summary"],
      }),
      getAccountSummary: build.query<any, any>({
        query: (params) => ({ url: "/account/summary", method: "get", params }),
        providesTags: ["account-summary"],
      }),
    }
  },
})

export const {
  useGenerateReportMutation,
  useGetReportsQuery,
  useGetReportQuery,
  useDeleteReportMutation,
  useGetInterventionSummaryQuery,
  useLazyExportReportQuery,
  useGetCoresponsiblitySummaryQuery,
  useGetLivelihoodSummaryQuery,
  useGetGRMSummaryQuery,
  useGetBeneficiarySummaryQuery,
  useGetPaymentUnitSummaryQuery,
  useGetAccountSummaryQuery,
  useGetAuditSummaryQuery,
} = reportSlice
