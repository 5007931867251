import { useResendOtp } from "hooks/useResendOtp/useResendOtp"
import { useMemo, useState } from "react"
import { useResendOtpMutation, useVerifyOtpMutation } from "services/api/authSlice"

interface P {
  // eslint-disable-next-line no-unused-vars
  callback: (e: any) => void
  email: string
}

export default function useVerifyOTP({ callback, email }: P) {
  const { countDown, resetTimer } = useResendOtp()
  const [verifyOtp, { isLoading }] = useVerifyOtpMutation()
  const [resendOtp, { isLoading: isResendingOtp }] = useResendOtpMutation()
  const [otp, setOtp] = useState({
    code: "",
    id: "",
  })

  const handleChange = (value: string) => {
    setOtp(({ id }) => ({ id, code: value }))
  }

  const onPaste = (e: any) => {
    e.stopPropagation()
    e.preventDefault()

    const clipboardData = e.clipboardData
    const pastedData = clipboardData?.getData("Text")

    setOtp(({ id }) => ({ id, code: pastedData }))
  }

  const onSubmit = async () => {
    const res = await verifyOtp({ userOtp: otp.code }).unwrap()
    if (res?.code === 200) callback(res.data)
  }

  const resendOtpCode = async () => {
    const res = await resendOtp({ email: email }).unwrap()
    if (res?.code === 200) {
      setOtp({ code: "", id: "" })
      resetTimer()
    }
  }

  const disabledButton = useMemo(() => {
    return otp.code.length !== 6
  }, [otp])

  return {
    otp,
    handleChange,
    onSubmit,
    setOtp,
    onPaste,
    loading: isLoading || isResendingOtp,
    isResendingOtp,
    resendOtpCode,
    countDown,
    disabledButton,
  }
}
