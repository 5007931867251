import React from "react"
import { motion } from "framer-motion"
import { Icon } from "@iconify/react"
import { useNavigate } from "react-router-dom"
import { Breadcrumb, Search, Filter, Button, Calendar, Table, ManageItem } from "components/core"
import useCourses from "./useCourses"
import CourseStatistics from "components/pages/Courses/CourseStats/CourseStatistics"
import { TrainingType } from "mocks/training"
import { courseListHeading } from "constants/trainings"
import AddCourse from "components/pages/Courses/AddCourse/AddCourse"
import EditCourse from "components/pages/Courses/EditCourse/EditCourse"
import { fullDateFormatter } from "utils/dateFormatter"

const Courses = () => {
  const navigate = useNavigate()

  const {
    changePage,
    params,
    selectLimit,
    breadCrumbs,
    handleSearch,
    selectedTrainingType,
    setSelectedTrainingType,
    date,
    setDate,
    setSelectedAction,
    selectedAction,
    isFetchingCourses,
    coursesData,
    selectedCourse,
    setSelectedCourse,
    handleDeleteCourse,
    isDeletingCourse,
    exportCourseInformation,
  } = useCourses()
  const MotionTr = motion.tr

  const handleEditCourse = (course: any) => {
    setSelectedAction("edit-course")
    setSelectedCourse(course)
  }

  const handleNavigateToCourse = (id: string) => {
    navigate(`/training/courses/${id}`)
  }

  return (
    <main data-testid="courses-list">
      <div>
        <Breadcrumb options={breadCrumbs} showBack goBackRoute="/training" />
        <h1 className="text-headers text-2xl font-bold">Course List</h1>
      </div>
      <CourseStatistics />

      <div className="mt-6 flex flex-wrap items-center justify-between gap-4 mb-4" data-testid="courses-tables">
        <div className="flex space-x-3">
          <Search placeholder="Search" id="search" onChange={handleSearch} name="search" />
          <Filter
            title="Training Type"
            options={TrainingType}
            loading={false}
            selectedOption={selectedTrainingType}
            setSelectedOption={setSelectedTrainingType}
          />
          <Calendar selectsRange={true} startDate={date?.startDate} endDate={date?.endDate} onChange={setDate} />
        </div>

        <div className="flex space-x-2">
          <Button type="button" size="40" theme="transparent" onClick={exportCourseInformation}>
            <Icon icon="uiw:download" className="text-[#747F9C] w-4 h-4 mr-3" />
            Export
          </Button>

          <Button
            type="button"
            size="40"
            theme="primary"
            onClick={() => setSelectedAction("create-course")}
            data-testid="add-course-btn"
          >
            <Icon icon="ph:plus" className="w-6 h-6 mr-[8px]" />
            Add New Course
          </Button>
        </div>
      </div>

      <div>
        <Table
          data={coursesData?.courses ?? []}
          headers={courseListHeading}
          onPageChange={changePage}
          selectLimit={selectLimit}
          totalCount={coursesData?.total}
          loading={isFetchingCourses}
          perPage={Number(params?.limit)}
          page={Number(params?.page)}
        >
          <>
            {coursesData?.courses
              ? coursesData?.courses?.map((course: any) => (
                  <React.Fragment key={course.course_id}>
                    <MotionTr
                      initial={{ opacity: 0, y: -20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3 }}
                      className="cc-custom-table-data-row"
                    >
                      <td onClick={() => handleNavigateToCourse(course?.course_id)}>
                        <div className="text-sm">
                          <h2 className="text-body ">{course?.title}</h2>
                          <h2 className="text-grey-base">{course?.course_reference}</h2>
                        </div>
                      </td>
                      <td onClick={() => handleNavigateToCourse(course?.course_id)} className="text-body text-sm">
                        {course?.modules?.length}
                      </td>
                      <td
                        onClick={() => handleNavigateToCourse(course?.course_id)}
                        className="capitalize text-sm text-body"
                      >
                        {course?.training_type}
                      </td>
                      <td
                        onClick={() => handleNavigateToCourse(course?.course_id)}
                        className="capitalize text-sm text-body"
                      >
                        {course?.skill_type}
                      </td>
                      <td
                        onClick={() => handleNavigateToCourse(course?.course_id)}
                        className="capitalize text-sm text-body"
                      >
                        {course?.created_at ? fullDateFormatter(course?.created_at) : "--"}
                      </td>
                      <td>
                        <div className="space-x-3.5">
                          <button
                            className="text-body align-middle py-[8px] px-[12px] rounded-lg border border-grey-200 cursor-pointer"
                            onClick={() => handleEditCourse(course)}
                          >
                            <Icon icon="ph:pencil-simple" className="w-4 h-4" />
                          </button>

                          <button
                            className="text-body align-middle py-[8px] px-[12px] rounded-lg border border-grey-200 cursor-pointer"
                            onClick={() => {
                              setSelectedAction("delete-course")
                              setSelectedCourse(course)
                            }}
                          >
                            <Icon icon="ph:trash" className="w-4 h-4" />
                          </button>
                        </div>
                      </td>
                    </MotionTr>
                  </React.Fragment>
                ))
              : null}
          </>
        </Table>
      </div>
      {selectedAction === "create-course" && <AddCourse onClose={() => setSelectedAction("")} />}

      {selectedAction === "edit-course" && <EditCourse onClose={() => setSelectedAction("")} course={selectedCourse} />}

      {selectedAction === "delete-course" && (
        <ManageItem
          onClose={() => setSelectedAction("")}
          title="Do you want to delete this Course?"
          actionName="Delete"
          cancelBtnText="Discard"
          loading={isDeletingCourse}
          onSubmit={() => handleDeleteCourse(selectedCourse?.course_id ?? "", () => setSelectedAction(""))}
          icon={
            <div className="bg-error-lightest w-12 h-12 flex items-center justify-center rounded-lg">
              <Icon icon="ph:trash" className="w-10 h-10 text-error-main" />
            </div>
          }
          caption={
            "Confirming this action will delete this course and it is irreversible. Be sure you want to perform this action."
          }
        />
      )}
    </main>
  )
}

export default Courses
