import { useTable } from "hooks"
import { useMemo } from "react"
import { Period } from "types/common"
import { useGetLivelihoodSummaryQuery, useGetReportsQuery } from "services/api/reportSlice"
import { removeSpecificKeys } from "utils/removeSpecificKeys"
import { calculatePercentage, getReportCustomDateRange, getReportYear } from "utils/report"
import { addCommasToNumbers } from "utils/addCommasToNumbers"
import { formatCurrency } from "utils/formatCurrency"
import useReportSummary from "hooks/useReportSummary/useReportSummary"

export default function useLivelihoodSummary() {
  const {
    actions,
    deleteReport,
    isDeletingReport,
    selectedReportId,
    setSelectedReportId,
    date,
    setDate,
    periodFilter,
    setPeriodFilter,
    navigate,
  } = useReportSummary()

  const { changePage, params, selectLimit } = useTable()
  const {
    data: reports,
    isLoading: isLoadingHistory,
    isFetching: isFetchingHistory,
  } = useGetReportsQuery({
    type: "livelihoods",
    ...removeSpecificKeys({ ...params }, ["sortBy"]),
  })
  const { data: summary, isLoading: isLoadingSummary } = useGetLivelihoodSummaryQuery({
    month: periodFilter.type === Period.MONTHLY ? periodFilter?.selected : "",
    year: getReportYear(periodFilter),
    ...getReportCustomDateRange(date, periodFilter),
  })
  const overview = [
    {
      title: "Total Livelihood (Count)",
      count: addCommasToNumbers(summary?.data?.intervention_count ?? 0),
      icon: "ph:users-three",
      stat: summary?.data?.intervention_count_percentage_increase,
    },
    {
      title: "Total Livelihood (Value)",
      count: formatCurrency(summary?.data?.total_intervention_amount ?? 0),
      icon: "ph:handshake",
      stat: summary?.data?.total_intervention_amount_percentage_increase,
    },
    {
      title: "Total Beneficiaries",
      count: addCommasToNumbers(summary?.data?.total_beneficiaries ?? 0),
      icon: "ph:handshake",
      stat: summary?.data?.total_beneficiaries_percentage_increase,
    },
  ]

  const chartData = useMemo(() => {
    const data = [summary?.data?.paidCount, summary?.data?.inProgressCount, summary?.data?.unpaidCount]
    const percentage = calculatePercentage(data)

    const filteredData = percentage.reduce<{ labels: string[]; data: number[]; backgroundColor: string[] }>(
      (acc, value, index) => {
        if (Number(data[index]) !== 0) {
          acc.labels.push(["Paid", "In progress", "Unpaid"][index])
          acc.data.push(value)
          acc.backgroundColor.push(["#2B892B", "#C6E4C4", "#024F0B"][index])
        }
        return acc
      },
      { labels: [], data: [], backgroundColor: [] },
    )
    return {
      labels: filteredData.labels,
      datasets: [
        {
          data: filteredData.data,
          backgroundColor: filteredData.backgroundColor,
          borderWidth: 6,
        },
      ],
    }
  }, [summary?.data])

  return {
    navigate,
    summary: summary?.data,
    reports: reports?.data,
    overview,
    date,
    setDate,
    setPeriodFilter,
    changePage,
    selectLimit,
    periodFilter,
    actions,
    chartData,
    selectedReportId,
    setSelectedReportId,
    isDeletingReport,
    deleteReport,
    params,
    isFetchingHistory,
    isLoadingHistory,
    isLoadingSummary,
  }
}
