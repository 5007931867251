import { Status } from "components/core"
import { ReportPreviewLayout } from "components/layouts/Reports"
import { fullDateFormatter } from "utils/dateFormatter"

interface Props {
  close: () => void
  state: any
}

export const Preview = ({ close, state }: Props) => {
  const { formData, date, isGeneratingReport, generateReport } = state

  return (
    <ReportPreviewLayout
      close={close}
      loading={isGeneratingReport}
      generateReport={generateReport}
      name={formData?.reportTitle}
    >
      <>
        {formData?.status?.label && (
          <div className="flex mb-[24px]">
            <p className="text-base text-body w-[245px] shrink-0">Beneficiary Status:</p>
            <p className="text-base text-headers">{formData?.status?.label}</p>
          </div>
        )}
        {!!formData?.paymentStatus?.length && (
          <div className="flex mb-[24px]">
            <p className="text-base text-body w-[245px] shrink-0">Payment Status:</p>
            {formData?.paymentStatus.map((item: any) => (
              <Status key={item?.label} status={item?.label} className="mr-[8px] mb-[8px] bg-[#E3E5EB] text-body" />
            ))}
          </div>
        )}
        {date.createdDate.startDate && (
          <div className="flex mb-[24px]">
            <p className="text-base text-body w-[245px] shrink-0">Date Created:</p>
            <p className="text-base text-headers">
              {fullDateFormatter(date.createdDate.startDate)} - {fullDateFormatter(date.createdDate.endDate)}
            </p>
          </div>
        )}
        {!!formData.states?.length && (
          <div className="flex mb-[24px]">
            <p className="text-base text-body w-[245px] shrink-0">States:</p>
            <div className="flex flex-wrap">
              {formData?.states.map((item: any) => (
                <Status key={item?.label} status={item?.label} className="mr-[8px] mb-[8px] bg-[#E3E5EB] text-body" />
              ))}
            </div>
          </div>
        )}
        {!!formData.lga?.length && (
          <div className="flex mb-[24px]">
            <p className="text-base text-body w-[245px] shrink-0">Local Government Area:</p>
            <div className="flex flex-wrap">
              {formData?.lga.map((item: any) => (
                <Status key={item?.label} status={item?.label} className="mr-[8px] mb-[8px] bg-[#E3E5EB] text-body" />
              ))}
            </div>
          </div>
        )}
        {!!formData.ward?.length && (
          <div className="flex mb-[24px]">
            <p className="text-base text-body w-[245px] shrink-0">Ward:</p>
            <div className="flex flex-wrap">
              {formData?.ward.map((item: any) => (
                <Status key={item?.label} status={item?.label} className="mr-[8px] mb-[8px] bg-[#E3E5EB] text-body" />
              ))}
            </div>
          </div>
        )}
        {!!formData.sourceRegister?.length && (
          <div className="flex mb-[24px]">
            <p className="text-base text-body w-[245px] shrink-0">Source Register:</p>
            <div className="flex flex-wrap">
              {formData?.sourceRegister.map((item: any) => (
                <Status key={item?.label} status={item?.label} className="mr-[8px] mb-[8px] bg-[#E3E5EB] text-body" />
              ))}
            </div>
          </div>
        )}
        {!!formData.communities?.length && (
          <div className="flex mb-[24px]">
            <p className="text-base text-body w-[245px] shrink-0">Communities:</p>
            <div className="flex flex-wrap">
              {formData?.communities.map((item: any) => (
                <Status key={item?.label} status={item?.label} className="mr-[8px] mb-[8px] bg-[#E3E5EB] text-body" />
              ))}
            </div>
          </div>
        )}
      </>
    </ReportPreviewLayout>
  )
}
