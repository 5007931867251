import { FormEvent, useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { useDeleteRoleMutation, useGetRolesQuery, useReassignRoleMutation } from "services/api/rolesSlice"
import { Staff } from "types/staff"
import { tryCatch } from "utils/tryCatch"

interface Props {
  initialStaff: any
  roleId: string | undefined
}

export default function useReassignRole({ initialStaff, roleId = "" }: Props) {
  const navigate = useNavigate()
  const [staff, setStaff] = useState<Staff[]>([])
  const { data: fetchedRoles } = useGetRolesQuery({ noLimit: true })
  const [reassignRole, { isLoading: isReassigning }] = useReassignRoleMutation()
  const [deleteRole, { isLoading: isDeletingRole }] = useDeleteRoleMutation()

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    tryCatch(async () => {
      const payload = staff.map((item) => ({ role: item.role, staffId: item.staff_id }))

      const res = await reassignRole(payload).unwrap()
      if (res?.code === 200) {
        toast.success(res?.message)

        const deleteRes = await deleteRole(roleId).unwrap()
        if (deleteRes?.code === 200) {
          toast.success(deleteRes?.message)
          navigate(-1)
        }
      }
    })
  }

  useEffect(() => {
    if (initialStaff) {
      const i = initialStaff.map((item: Staff) => ({
        staff_number: item.staff_number || "N/A",
        role: "",
        first_name: item.first_name,
        last_name: item.last_name,
        staff_id: item.staff_id,
      }))
      setStaff(i)
    }
  }, [])

  const disabled = useMemo(() => {
    return !!staff.filter((item) => item.role === "").length
  }, [staff])

  const rolesWithoutCurrentRole = useMemo(() => {
    return fetchedRoles?.roles.filter((item) => item.role_id !== roleId)
  }, [fetchedRoles, roleId])

  return {
    roles: rolesWithoutCurrentRole,
    staff,
    setStaff,
    submit,
    disabled,
    loading: isDeletingRole || isReassigning,
  }
}
