import { Fragment } from "react"
import { Breadcrumb, Avatar, Search, Button, Filter, Calendar, Table, ManageItem } from "components/core"
import { Icon } from "@iconify/react"
import { motion } from "framer-motion"
import useTrainingBeneficiary from "./useTrainingBeneficiary"
import { trainingBeneficiaryHeaders, trainingBeneficiaryTableHeader } from "constants/trainings"
import { fullDateFormatter } from "utils/dateFormatter"
import TrainingStatus from "components/pages/Training/TrainingTable/TrainingStatus/TrainingStatus"
import HeaderLoader from "components/core/Loader/HeaderLoader/HeaderLoader"
import { RenderIf } from "components/hoc"
import { BeneficiaryAttendanceStatus } from "mocks/training"

const TrainingBeneficiary = () => {
  const {
    breadCrumbs,
    trainingHeadersData,
    handleSearch,
    changePage,
    params,
    selectLimit,
    date,
    setDate,
    selectedStatus,
    setSelectedStatus,
    isFetchingAttendanceData,
    attendanceData,
    training_id,
    selectedAction,
    setSelectedAction,
    selectedBeneficiary,
    setSelectedBeneficiary,
    handleMarkAttendance,
    isMarkingAttendance,
    exportTrainingAttendance,
  } = useTrainingBeneficiary()

  const MotionTr = motion.tr

  // console.log(selectedAction)

  return (
    <>
      <main data-testid="training-beneficiary">
        <RenderIf condition={!isFetchingAttendanceData}>
          <div>
            <Breadcrumb options={breadCrumbs} showBack />
            <div className="flex gap-4">
              <Avatar image={attendanceData?.name} size="89" />
              <div className="flex flex-col gap-2">
                <h1 className="text-headings font-semibold text-2xl ">{attendanceData?.name}</h1>
                <div>
                  <div className="inline-block px-[8px] py-[3px] font-[475] text-xs bg-grey-100 text-grey-800 rounded-full text-center">
                    {attendanceData?.course_title}
                  </div>
                </div>
                <h1 className="text-grey-base text-xs font-medium uppercase">{training_id}</h1>
              </div>
            </div>
          </div>
          <div className="bg-primary-900 mt-6 text-primary-30 font-normal text-sm min-h-[86px] rounded-lg p-6 flex flex-col">
            <div className="grid grid-cols-6 divide-x-[0.5px] divide-[#E6E6E6] child-not-first:pl-4">
              {trainingBeneficiaryHeaders?.map((header, index) => (
                <div key={header}>
                  <span className="text-[#C6E4C4] text-xs font-medium">{header}</span>
                  <p className="text-primary-30 capitalize-first font-normal text-sm mt-[2px] truncate">
                    {trainingHeadersData[index]}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </RenderIf>
        <RenderIf condition={isFetchingAttendanceData}>
          <HeaderLoader />
        </RenderIf>

        <div className="my-6">
          <h1 className="text-headings text-lg font-medium mb-3">Attendance</h1>
          <div className="flex justify-between items-center">
            <div className="flex gap-2">
              <Search name="search" id="search" placeholder="Search" onChange={handleSearch} />
              <Filter
                title="Status"
                options={BeneficiaryAttendanceStatus}
                loading={false}
                selectedOption={selectedStatus}
                setSelectedOption={setSelectedStatus}
              />
              <Calendar startDate={date?.startDate} endDate={date?.endDate} onChange={setDate} selectsRange />
            </div>
            <div>
              <Button type="button" size="40" theme="transparent" onClick={() => exportTrainingAttendance()}>
                <Icon icon="uiw:download" className="text-[#747F9C] w-4 h-4 mr-3" />
                Export
              </Button>
            </div>
          </div>
        </div>

        <div>
          <Table
            headers={trainingBeneficiaryTableHeader}
            data={attendanceData?.training_participant_attendance ?? []}
            onPageChange={changePage}
            selectLimit={selectLimit}
            loading={isFetchingAttendanceData}
            totalCount={attendanceData?.total}
            perPage={Number(params?.limit)}
            page={Number(params?.page)}
          >
            <>
              {attendanceData?.training_participant_attendance
                ? attendanceData?.training_participant_attendance?.map((data: any) => (
                    <Fragment key={data?.attendance_id}>
                      <MotionTr
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.3 }}
                        className="cc-custom-table-data-row cursor-pointer"
                      >
                        <td>{`Day ${data?.training_day}`}</td>
                        <td>{data?.marked_at ? fullDateFormatter(data?.marked_at) : null}</td>
                        <td>
                          <TrainingStatus status={data?.status} />
                        </td>
                        <td>
                          <div className="flex  gap-x-4">
                            <button
                              onClick={() => {
                                setSelectedAction("mark-present")
                                setSelectedBeneficiary(data)
                              }}
                              className={` cursor-pointer flex gap-x-2 items-center rounded py-1.5 px-3 text-xs justify-center font-medium border ${
                                data?.marked_at && "bg-grey-100 text-body"
                              }`}
                              disabled={data?.marked_at}
                            >
                              <Icon icon="ph:check" />
                              Present
                            </button>
                            <button
                              onClick={() => {
                                setSelectedAction("mark-absent")
                                setSelectedBeneficiary(data)
                              }}
                              disabled={data?.marked_at}
                              className={`cursor-pointer flex gap-x-2 items-center rounded py-1.5 px-3 text-xs justify-center font-medium border ${
                                data?.marked_at && "bg-grey-100 text-body"
                              }`}
                            >
                              <Icon icon="ph:x" className="text-inherit" />
                              Absent
                            </button>
                          </div>
                        </td>
                      </MotionTr>
                    </Fragment>
                  ))
                : null}
            </>
          </Table>
        </div>
      </main>
      {selectedAction === "mark-present" && (
        <ManageItem
          onClose={() => setSelectedAction("")}
          caption={`Do you want to mark this user as Present`}
          caption2={`Confirming this action will make the user present for this particular
          day (${selectedBeneficiary?.training_day}).
          Be sure you want to perform this action as it is irreversible`}
          title="Mark User Present"
          icon={
            <div className="bg-grey-50 w-12 h-12 flex items-center justify-center rounded-lg">
              <Icon icon="ph:check" className="w-10 h-10 text-body" />
            </div>
          }
          actionName="Mark as Present"
          cancelBtnText="Cancel"
          caption2Color={false}
          onSubmit={() => handleMarkAttendance({ status: "attended", id: selectedBeneficiary?.attendance_id })}
          loading={isMarkingAttendance}
        />
      )}

      {selectedAction === "mark-absent" && (
        <ManageItem
          onClose={() => setSelectedAction("")}
          caption={`Do you want to mark this user as Absent`}
          caption2={`Confirming this action will make the user absent for this particular day (${selectedBeneficiary?.training_day}).
          Be sure you want to perform this action as it is irreversible`}
          title="Mark User Absent"
          icon={
            <div className="bg-grey-50 w-12 h-12 flex items-center justify-center rounded-lg">
              <Icon icon="ph:x" className="w-10 h-10 text-body" />
            </div>
          }
          actionName="Mark as Absent"
          cancelBtnText="Cancel"
          caption2Color={false}
          onSubmit={() => handleMarkAttendance({ status: "absent", id: selectedBeneficiary?.attendance_id })}
          loading={isMarkingAttendance}
        />
      )}
    </>
  )
}

export default TrainingBeneficiary
