import { Avatar, Filter, Search, Status, Table } from "components/core"
import useBeneficiaries from "./useBeneficiaries"
import { beneficiariesHeader } from "constants/intervention"
import { fullDateFormatter } from "utils/dateFormatter"
import { useSelector } from "react-redux"
import { convertStateToDropdownOptions } from "utils/dropdown"
import { defaultFilter } from "mocks/staffs"

interface BeneficiariesProps {
  interventionId: string
}

export const Beneficiaries = ({ interventionId }: BeneficiariesProps) => {
  const { states } = useSelector((state: any) => state.common)
  const {
    handleSearch,
    selectedGender,
    selectedState,
    setSelectedGender,
    setSelectedState,
    genderOptions,
    tabs,
    currentTab,
    setCurrentTab,
    beneficiaries,
    isFetchingBeneficiaries,
    changePage,
    resetParamsProxy,
    selectLimit,
    limit,
    page,
  } = useBeneficiaries(interventionId)

  return (
    <div>
      <div className="flex items-center justify-between mb-[16px]">
        <div className="flex">
          <Search id="search" name="search" placeholder="Search" onChange={handleSearch} className="mr-[8px]" />
          <Filter
            title="State"
            options={[...defaultFilter, ...convertStateToDropdownOptions(states)]}
            selectedOption={selectedState}
            setSelectedOption={setSelectedState}
            containerClass="mr-[8px]"
          />
          <Filter
            title="Gender"
            options={genderOptions}
            selectedOption={selectedGender}
            setSelectedOption={setSelectedGender}
          />
        </div>
        <div className="bg-[#E3E5EB] p-[4px] grid grid-cols-2 rounded-[4px] sticky top-[-24px]">
          {tabs.map((tab) => (
            <button
              key={tab.name}
              data-testid={tab.value}
              type="button"
              className={`
              flex items-center justify-between text-sm font-medium px-[16px] py-[6px]
              ${currentTab === tab.name ? "bg-white rounded-[4px] text-headings" : "text-paragraph"}
              `}
              onClick={() => {
                resetParamsProxy()
                setCurrentTab(tab.name)
              }}
            >
              {tab.value}
              <Status
                status={tab?.count ?? "..."}
                className={`text-white ml-[4px] ${currentTab === tab.name ? "bg-primary-actions" : "bg-placeholder"}`}
              />
            </button>
          ))}
        </div>
      </div>
      <Table
        headers={beneficiariesHeader}
        paginateData={!!beneficiaries}
        data={beneficiaries?.beneficiaries || []}
        onPageChange={changePage}
        selectLimit={selectLimit}
        totalCount={beneficiaries?.total}
        perPage={limit}
        page={page}
        loading={isFetchingBeneficiaries}
      >
        <>
          {beneficiaries?.beneficiaries?.map((item) => (
            <tr data-testid={item?.nin} key={item?.nin} className="cc-custom-table-data-row">
              <td className="flex items-center ">
                <div className="flex items-center">
                  <Avatar
                    key={item?.nin}
                    //image={item?.profile_pic || item?.name}
                    alt={item?.name}
                    size="24"
                  />
                  <div className="ml-[16px]">
                    <p title={item?.name} className="max-w-[200px] truncate capitalize">
                      {item?.name}
                    </p>
                    <p className="text-grey-base text-xs">{item?.household_id}</p>
                  </div>
                </div>
              </td>
              <td className="capitalize-first">{item?.state || "N/A"}</td>
              <td>{fullDateFormatter(item?.date_of_birth) || "N/A"}</td>
              <td>{item?.nin || "N/A"}</td>
              <td>{item?.bvn || "N/A"}</td>
              <td>{item?.account_number || "N/A"}</td>
              <td className="capitalize-first">{item?.gender || "N/A"}</td>
              <td className="uppercase">{item?.source_register || "N/A"}</td>
            </tr>
          ))}
        </>
      </Table>
    </div>
  )
}
