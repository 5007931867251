import { ReactNode } from "react"
import { ReactComponent as Logo } from "assets/svg/logo.svg"

interface AuthFormProps {
  children: ReactNode
  showLogo?: boolean
  title: string
  caption: string
  onSubmit?: () => void
}

export const AuthForm = ({ children, title, caption, onSubmit, showLogo = true }: AuthFormProps) => {
  const submit = (e: any) => {
    e.preventDefault()
    if (onSubmit) onSubmit()
  }

  return (
    <div>
      {showLogo && <Logo className="mb-[40px] mx-auto" />}
      <form className="w-full sm:w-[464px] p-[40px] bg-white" onSubmit={submit}>
        <div className="mb-[24px]">
          <h2 className="text-2xl text-center text-headers font-semibold mb-[8px]">{title}</h2>
          <p className="text-sm text-center text-body font-normal">{caption}</p>
        </div>
        {children}
      </form>
    </div>
  )
}
