import { InterventionDetails } from "components/pages/Interventions"
import { useLocation } from "react-router-dom"

export default function ViewRequest() {
  const { pathname } = useLocation()
  const interventionId = pathname.split("/")[3] ?? ""

  return (
    <main data-testid="view-request" className="relative">
      <InterventionDetails purpose="requesting-approval" interventionId={interventionId} />
    </main>
  )
}
