import { Breadcrumb } from "components/core"
import useNewIntervention from "./useNewIntervention"
import BasicInformation from "components/pages/Interventions/NewIntervention/BasicInformation/BasicInformation"
import DepartmentAccess from "components/pages/Interventions/NewIntervention/DepartmentAccess/DepartmentAccess"
import Payment from "components/pages/Interventions/NewIntervention/Payment/Payment"
import QuotaStructure from "components/pages/Interventions/NewIntervention/QuotaStructure/QuotaStructure"
import Review from "components/pages/Interventions/NewIntervention/Review/Review"
import SelectionCriteria from "components/pages/Interventions/NewIntervention/SelectionCriteria/SelectionCriteria"
import { interventionSteps } from "constants/intervention"
import AdditionalEligibility from "components/pages/Interventions/NewIntervention/AdditionalEligibility/AdditionalEligibility"
import { Icon } from "@iconify/react"
import { RenderIf } from "components/hoc"

const NewIntervention = () => {
  const {
    breadCrumbs,
    view,
    handleChange,
    formData,
    setFormData,
    id,
    proceed,
    step,
    departmentOptions,
    isFetchingDepartments,
    isFetchingDetails,
    selectionCriteria,
    isFetchingSelectionCriteria,
  } = useNewIntervention()

  const views = {
    "Basic Information": (
      <BasicInformation
        formData={formData}
        handleChange={handleChange}
        setFormData={setFormData}
        proceed={() => proceed("Quota Structure", "next")}
      />
    ),
    "Quota Structure": (
      <QuotaStructure
        formData={formData}
        setFormData={setFormData}
        proceed={() => proceed("Selection Criteria", "next")}
        back={() => proceed("Basic Information", "prev")}
      />
    ),
    "Selection Criteria": (
      <SelectionCriteria
        formData={formData}
        setFormData={setFormData}
        proceed={() => proceed("Payment", "next")}
        back={() => proceed("Quota Structure", "prev")}
        conditions={selectionCriteria}
        loading={isFetchingSelectionCriteria}
      />
    ),
    Payment: (
      <Payment
        formData={formData}
        setFormData={setFormData}
        handleChange={handleChange}
        proceed={() => proceed("Additional Eligibility", "next")}
        back={() => proceed("Selection Criteria", "prev")}
      />
    ),
    "Additional Eligibility": (
      <AdditionalEligibility
        setFormData={setFormData}
        formData={formData}
        back={() => proceed("Payment", "prev")}
        proceed={() => proceed("Departmental Access", "next")}
      />
    ),
    "Departmental Access": (
      <DepartmentAccess
        formData={formData}
        setFormData={setFormData}
        loading={isFetchingDepartments}
        departments={departmentOptions}
        back={() => proceed("Additional Eligibility", "prev")}
        proceed={() => proceed("Review", "next")}
      />
    ),
    Review: <Review back={() => proceed("Departmental Access", "prev")} formData={formData} id={id} />,
  }
  const View = views[view as keyof typeof views]

  return (
    <main data-testid="new-intervention" className="overflow-y-hidden h-[82vh]">
      <div className="border-b pb-3 flex items-center">
        <div>
          <Breadcrumb options={breadCrumbs} showBack={true} />
          <h1 className="text-headers text-2xl font-bold">{`${id ? "Edit" : "New"} Intervention`}</h1>
        </div>
      </div>
      <div className="mt-10 flex">
        <section className="bg-[#FAFAFA] border border-grey-200 py-4 pl-4 pr-8 w-[215px] h-fit rounded-lg shrink-0">
          <p className="text-headers list-none font-semibold mb-4">Sections</p>
          <ol className="text-sm leading-7 font-medium">
            {interventionSteps.map((item, index) => (
              <li
                key={item}
                className={`${item === view || step > index ? "text-primary-actions" : "text-body"} 
                  ${step - 1 && step > index + 1 ? "flex items-center" : "ml-1"} list-none`}
              >
                {step - 1 && step > index + 1 ? (
                  <Icon icon="lets-icons:check-fill" className="mr-2 text-lg" />
                ) : (
                  <span className="mr-2">{index + 1}.</span>
                )}
                {item}
              </li>
            ))}
          </ol>
        </section>
        <section className="h-screen overflow-y-scroll overflow-x-hidden pb-[400px] px-[4px] no-scrollbar ml-[77px] min-w-[633px]">
          <RenderIf condition={!isFetchingDetails}>
            <>
              <p className="text-headers font-semibold mb-2">{view}</p>
              <p className="text-body text-sm">
                {["Payments", "Quota Structure", "Basic Informations"].includes(view)
                  ? "Ensure that all information provided here are verified and correct before proceeding"
                  : "Please provide essential details for this intervention"}
              </p>
              {view && View}
            </>
          </RenderIf>
          <RenderIf condition={isFetchingDetails}>
            <p>Fetching resource...</p>
          </RenderIf>
        </section>
      </div>
    </main>
  )
}

export default NewIntervention
