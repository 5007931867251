import { ChangeEvent, useState } from "react"
import { toast } from "react-toastify"
import { useAddGroupMutation } from "services/api/livelihoodSlice"
import { tryCatch } from "utils/tryCatch"

interface Props {
  community: any
  onClose: () => any
}

export default function useAddGroup({ community, onClose }: Props) {
  const [file, setFile] = useState<any>(null)
  const [selectedPeriod, setSelectedPeriod] = useState<any>({})
  const [formData, setFormData] = useState({
    groupName: "",
    state: community?.state_name,
    lga: community?.lga_name,
    ward: "",
    amount: "",
    corporative: "",
    duration: "",
  })

  const [addGroup, { isLoading }] = useAddGroupMutation()

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    if (["amount", "duration"].includes(name)) {
      setFormData({ ...formData, [name]: value.replace(/[^0-9]/g, "") })
      return
    }
    setFormData({ ...formData, [name]: value })
  }

  const submit = (e: any) => {
    e.preventDefault()
    tryCatch(async () => {
      const { duration, groupName, ward, amount, corporative } = formData
      const payload = {
        duration: `${duration} ${selectedPeriod?.value}`,
        groupName,
        ward,
        corporative,
        amount: Number(amount),
        state: community?.state_id?.toString(),
        lga: community?.lga_id?.toString(),
        communityId: community?.community_id,
      }
      const res = await addGroup(payload).unwrap()
      if ([200, 201].includes(res?.code)) {
        toast.success(res?.message)
        onClose()
      }
    })
  }

  return {
    formData,
    setFormData,
    handleChange,
    file,
    setFile,
    submit,
    isLoading,
    setSelectedPeriod,
    selectedPeriod,
  }
}
