import { useState } from "react"
import instance from "axios"
import { toast } from "react-toastify"

interface P {
  file: any
  res: any
  formData?: any
  timestamp?: string
  callback?: () => void
  alert?: boolean
}

export const useUpload = () => {
  const [uploading, setUploading] = useState(false)

  const upload = async ({ file, res, timestamp, callback, alert = false }: P) => {
    try {
      setUploading(true)
      if (res?.code === 200) {
        let reqInstance = instance.create({
          headers: {
            "Content-Type": "/image/*",
          },
        })

        const response = await reqInstance.put(res?.data, file)
        if (response?.status === 200) {
          alert && toast.success("Upload successful")
          if (callback) callback()
          return { response, url: `${process.env.REACT_APP_S3_BUCKET}/${timestamp}${file.name}` }
        }
      }
    } catch (err) {
      toast.error("Something went wrong.")
    } finally {
      setUploading(false)
    }
  }

  return {
    upload,
    uploading: uploading,
    setUploading,
  }
}
