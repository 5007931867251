import React from "react"
import useIntervention from "./useIntervention"
import { Icon } from "@iconify/react"
import { Button, CardLoader, Filter, Search } from "components/core"
import { useNavigate } from "react-router-dom"
import { interventionStatus, paymentTypes } from "constants/intervention"
import { RenderIf, Restricted } from "components/hoc"
import { EmptyState } from "components/core/Table/EmptyState/EmptyState"
import { defaultFilter } from "mocks/staffs"
import { InterventionCardContainer } from "components/pages/Interventions"
import { AllPermissions, PermissionModule } from "types/permission"

const Intervention = () => {
  const {
    interventionDetails,
    selectedState,
    setSelectedState,
    setPaymentType,
    interventions,
    paymentType,
    pendingInterventions,
    isFetchingInterventions,
    inProgressInterventions,
    completedInterventions,
    loggedUserId,
    setDataSource,
    dataSource,
    setSelectedStatus,
    isFetchingSources,
    dataSources,
    selectedStatus,
    handleSearch,
    actions,
    nigerianStates,
  } = useIntervention()
  const navigate = useNavigate()

  const t = [!!pendingInterventions?.length, !!inProgressInterventions?.length, !!completedInterventions?.length]
  const countOfStatusWithData = t.filter((item) => !!item).length
  const gridColumns = () => {
    if (countOfStatusWithData === 3) return "grid-cols-3"
    if (countOfStatusWithData === 2) return "grid-cols-2"
    return "grid-cols-1"
  }
  const { INTERVENTION } = PermissionModule
  const { VIEW_ALL_INTERVENTIONS, CREATE_INTERVENTION } = AllPermissions

  return (
    <Restricted module={INTERVENTION} permission={VIEW_ALL_INTERVENTIONS} showError={true}>
      <main data-testid="intervention">
        <h1 className="text-headers text-2xl font-bold">Interventions</h1>
        <div className="flex items-center py-[24px] border-y mt-11">
          {interventionDetails.map((details) => (
            <div key={details.title} className="flex items-center gap-4 mr-[40px]">
              <div className="relative w-10 h-10 grid place-items-center bg-grey-100 rounded-full">
                <Icon icon={details.icon} className="w-6 h-6 text-body" />
              </div>
              <div className="grid">
                <p className="text-sm text-body">{details.title}</p>
                <div className="text-base font-medium text-headers">
                  {details.count}
                  {details.title === "Requesting Approval" && (
                    <button
                      className="text-primary-actions ml-1 cursor-pointer"
                      onClick={() => navigate(`requesting-approval?reviewerId=${loggedUserId}`)}
                    >
                      {`(View Intervention>>>)`}
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="flex items-center justify-between mb-4 mt-6">
          <div className="flex space-x-2">
            <Search id="search" name="search" placeholder="Search" onChange={handleSearch} />
            <Filter
              title="Status"
              options={interventionStatus}
              selectedOption={selectedStatus}
              multiple={true}
              setSelectedOption={setSelectedStatus}
            />
            <Filter
              title="State"
              options={nigerianStates}
              loading={false}
              multiple={true}
              selectedOption={selectedState}
              setSelectedOption={setSelectedState}
            />
            <Filter
              title="Payment type"
              options={paymentTypes}
              loading={false}
              multiple={true}
              selectedOption={paymentType}
              setSelectedOption={setPaymentType}
            />
            <Filter
              title="Data source"
              options={[...defaultFilter, ...dataSources]}
              multiple={true}
              loading={isFetchingSources}
              selectedOption={dataSource}
              setSelectedOption={setDataSource}
            />
          </div>
          <Restricted module={INTERVENTION} permission={CREATE_INTERVENTION}>
            <Button type="button" size="40" theme="primary" onClick={() => navigate("new-intervention")}>
              <Icon icon="ph:plus" className="w-6 h-6 mr-[8px]" />
              New Intervention
            </Button>
          </Restricted>
        </div>
        <RenderIf condition={isFetchingInterventions}>
          <div className="grid gap-4 grid-cols-3">
            {[1, 2, 3, 4, 5, 6].map((item) => (
              <CardLoader key={item} />
            ))}
          </div>
        </RenderIf>
        <RenderIf condition={!isFetchingInterventions && interventions?.interventions?.length! > 0}>
          <div className={`grid ${gridColumns()} gap-x-4`}>
            {!!pendingInterventions?.length && (
              <InterventionCardContainer
                title="PENDING"
                actions={actions}
                data={pendingInterventions}
                countOfStatusWithData={countOfStatusWithData}
              />
            )}
            {!!inProgressInterventions?.length && (
              <InterventionCardContainer
                title="IN-PROGRESS"
                actions={actions}
                data={inProgressInterventions}
                countOfStatusWithData={countOfStatusWithData}
              />
            )}
            {!!completedInterventions?.length && (
              <InterventionCardContainer
                title="COMPLETED"
                actions={actions}
                data={completedInterventions}
                countOfStatusWithData={countOfStatusWithData}
              />
            )}
          </div>
        </RenderIf>
        <RenderIf condition={!isFetchingInterventions && !interventions?.interventions?.length}>
          <EmptyState emptyStateText="Uh oh! There are no interventions at the moment" />
        </RenderIf>
      </main>
    </Restricted>
  )
}

export default Intervention
