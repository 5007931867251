/* eslint-disable no-unused-vars */
export enum BENEFICIARY_STATUS {
  VERIFIED = "Verified",
  UNVERIFIED = "Unverified",
}

export interface DateType {
  startDate: Date | null
  endDate: Date | null
}

export interface SingleBeneficiary {
  reference: string
  name: string
  state: string
  nin: string
  bvn: string
  account_number: string
  account_name: string
  bank: string
  bank_code: string
  household_id?: string
  source_register_id: string
  source_register: string
  member_id: string
  created_at: string
  updated_at: string
  gender: string
  date_of_birth: string
  age: string
  lga: string
  ward: string
  remark?: string
  image?: string
  error_remark?: any
  beneficiary_id: string
}

export interface FetchedBeneficiary {
  data: SingleBeneficiary
}

export interface FetchedBeneficiaries {
  beneficiaries: SingleBeneficiary[]
  total: number
  currentPage: number
  per_page: number
  limit: number
  page: number
  intervention: string
}

export interface FetchedBeneficiariesExport {
  data: string
  total: number
  currentPage: number
  per_page: number
  limit: number
  page: number
  status: any
  message: any
}

export interface SingleBeneficiaryIntervention {
  intervention_id: string
  intervention_title: string
  amount: any
  created_at: string
  updated_at: string
  status: string
  payment_cycle: string
  data_source: string
  transaction_id: string
  destination_account: string
  payment_date: string
  payment_status: string
}

export interface FetchedBeneficiaryIntervention {
  interventions: SingleBeneficiaryIntervention[]
  total: number
}

export interface InterventionHistoryProps {
  date: any
  setDate: any
  interventions: any
  isFetchingInterventions: boolean
  handleSearch: any
  changePage: any
  statusOptions: any
  selectedStatus: any
  setSelectedStatus: any
  exportBeneficiaries: any
  isExporting: boolean
  interventionStatus: any
}

export interface PaymentHistoryProps {
  date: any
  setDate: any
  beneficiaryPaymentHistory: any
  handleSearchPayment: any
  changePage: any
  statusOptions: any
  selectedStatus: any
  setSelectedStatus: any
  exportPaymentHistory: any
  isExportingLoading: boolean
  isFetchingPaymentHistory: boolean
}
