import { useState } from "react"
import { useTable } from "hooks"
import { useGetRolesQuery } from "services/api/rolesSlice"
import { usePermission } from "hooks/usePermission/usePermission"
import { PermissionModule } from "types/permission"

export default function useRolesAndPermissions() {
  const { ROLES_AND_PERMISSION } = PermissionModule
  const { handleSearch, params } = useTable()
  const { hasModulePermission } = usePermission()
  const [selectedStatus, setSelectedStatus] = useState<any>([])
  const [selectedAction, setSelectedAction] = useState("")
  const { data: roles, isFetching: isFetchingRoles } = useGetRolesQuery(
    {
      ...params,
      roleStatus: selectedStatus.value ?? "",
    },
    { skip: !hasModulePermission(ROLES_AND_PERMISSION) },
  )

  const breadCrumbs = [{ title: "Settings" }, { title: "Roles & Permissions" }]

  const filterOptions = [
    { label: "All", value: "" },
    { label: "Active", value: "active" },
    { label: "Deactivated", value: "deactivated" },
  ]

  return {
    breadCrumbs,
    handleSearch,
    selectedStatus,
    setSelectedStatus,
    filterOptions,
    selectedAction,
    setSelectedAction,
    roles,
    isFetchingRoles,
  }
}
