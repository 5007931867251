import { useState } from "react"
import { toast } from "react-toastify"
import { tryCatch } from "utils/tryCatch"
import { exportData } from "utils/exportData"
import { useTable } from "hooks"
import { DateType } from "types/data"
import { useFetchTrainingsQuery, useDeleteTrainingMutation } from "services/api/trainingSlice"
import { fullDateFormatter } from "utils/dateFormatter"

const useTrainingTable = () => {
  const { handleSearch, changePage, params, selectLimit } = useTable()
  const [date, setDate] = useState<DateType>({ startDate: null, endDate: null })
  const [selectedTraining, setSelectedTraining] = useState<any>(null)
  const [selectedAction, setSelectedAction] = useState("")
  const [selectedParticipantType, setSelectedParticipantType] = useState<any>([])
  const [selectedTrainingUnit, setSelectedTrainingUnit] = useState<any>([])
  const [uploadMessage, setUploadMessage] = useState<any>("")
  const [selectedSkillType, setSelectedSkillType] = useState<any>([])

  const queryParams = {
    search: params?.search ?? "",
    page: params?.page,
    limit: params?.limit,
    from: date?.startDate,
    to: date?.endDate,
    training_type: selectedParticipantType.length !== 0 ? selectedParticipantType?.value : undefined,
    skill_type: selectedSkillType.length !== 0 ? selectedSkillType?.value : undefined,
    training_unit: selectedTrainingUnit.length !== 0 ? selectedTrainingUnit.value : undefined,
  }

  const { data: trainingsData, isFetching: isFetchingTrainingData } = useFetchTrainingsQuery({ params: queryParams })

  const [deleteTraining, { isLoading: isDeletingTraining }] = useDeleteTrainingMutation({})

  const handleDeleteTraining = async (trainingId: string, onClose: () => void) => {
    const callback = (res: any) => {
      if ([200, 201].includes(res?.code)) {
        toast.success(res.message)
      }
    }

    tryCatch(async () => {
      let res = await deleteTraining(trainingId).unwrap()
      callback(res)
      onClose()
    })
  }

  const exportTrainingInformation = () => {
    tryCatch(() => {
      const data = trainingsData?.trainings?.map((training: any) => {
        const date = fullDateFormatter(training.training_start_date)
        const created_at = fullDateFormatter(training?.created_at)
        return {
          "Training Name": training?.name ?? "--",
          "Training ID": training?.training_id ?? "--",
          "Number of participants": training?.participants ?? "0",
          "Training Reference": training?.training_reference ?? "--",
          "Training Type": training?.training_type ?? "--",
          "Training Unit": training?.training_unit ?? "--",
          "Created At": created_at?.replace(/,/g, ""),
          "Training Duration": `${training?.duration} day(s)`,
          "Training Start Date": date?.replace(/,/g, ""),
          "Training Status": training?.status ?? "--",
        }
      })

      exportData(data, "Training Information")
    })
  }

  return {
    startDate: date.startDate,
    endDate: date.endDate,
    handleDate: setDate,
    handleSearch,
    changePage,
    params,
    selectLimit,
    selectedAction,
    setSelectedAction,
    selectedParticipantType,
    setSelectedParticipantType,
    selectedTrainingUnit,
    setSelectedTrainingUnit,
    isFetchingTrainingData,
    trainingsData,
    selectedTraining,
    setSelectedTraining,
    handleDeleteTraining,
    isDeletingTraining,
    exportTrainingInformation,
    uploadMessage,
    setUploadMessage,
    selectedSkillType,
    setSelectedSkillType,
  }
}

export default useTrainingTable
