import { currencies } from "constants/currency"
import { useMemo } from "react"

interface Props {
  formData: any
}

export default function usePayment({ formData }: Props) {
  const disableBtn = useMemo(() => {
    return (
      !formData.payment_method ||
      !formData.amount ||
      !formData.payment_recurrence ||
      !formData.payment_mechanism ||
      !formData.payment_cycle ||
      !formData.currency
    )
  }, [formData])

  const allCurrencies = useMemo(() => {
    return currencies.map((item) => {
      const { name, currency, symbol, disabled } = item
      return {
        label: `${name} - ${currency}(${symbol})`,
        disabled: disabled,
        value: `${name}`,
      }
    })
  }, [currencies])

  const selectedCurrencyName = useMemo(() => {
    const item = allCurrencies.find((item: any) => item.value === formData.currency)
    return item ? item.label : ""
  }, [formData.currency])

  return {
    disableBtn,
    allCurrencies,
    selectedCurrencyName,
  }
}
