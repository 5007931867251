import { useTable } from "hooks"
import { useMemo, useState } from "react"
import {
  useDeactivateStaffMutation,
  useActivateStaffMutation,
  useGetAllStaffQuery,
  useLazyGetAllStaffQuery,
} from "services/api/staffSlice"
import { useGetRolesQuery } from "services/api/rolesSlice"
import { defaultFilter } from "mocks/staffs"
import { toast } from "react-toastify"
import { tryCatch } from "utils/tryCatch"
import { exportData } from "utils/exportData"
import { stringifyArray } from "utils/stringifyArray"
import { removeKeysWithEmptyValues } from "utils/objectFormatter"
import { AllPermissions } from "types/permission"

export default function useStaffManagement() {
  const { handleSearch, changePage, params, selectLimit } = useTable()
  const [selectedStatus, setSelectedStatus] = useState<any>([])
  const [selectedAction, setSelectedAction] = useState("")
  const [selectedStaff, setSelectedStaff] = useState<any>({})
  const [selectedRole, setSelectedRole] = useState<any>([])
  const { UPDATE_STAFF } = AllPermissions

  const { data: staffs, isLoading: isFetchingStaffs } = useGetAllStaffQuery({
    ...params,
    status: selectedStatus.length ? stringifyArray(selectedStatus) : "",
    role: selectedRole.length ? stringifyArray(selectedRole) : "",
  })
  const [deactivateStaff, { isLoading: isDeactivatingStaff }] = useDeactivateStaffMutation()
  const [activateStaff, { isLoading: isActivatingStaff }] = useActivateStaffMutation()
  const [getAllStaff, { isFetching: isExporting }] = useLazyGetAllStaffQuery()

  const { data: roles, isLoading: isFetchingRoles } = useGetRolesQuery({
    noLimit: true,
  })
  const roleOptions = useMemo(
    () =>
      roles?.roles?.map((item) => {
        return {
          label: item.role_name,
          value: item.role_name,
        }
      }) ?? [],
    [roles],
  )
  const roleFilterOptions = [...defaultFilter, ...roleOptions]
  const breadCrumbs = [{ title: "Settings" }, { title: "Staff Management" }]

  const activeRoles = useMemo(() => {
    const active = roles?.roles?.filter((role: any) => role.status?.toLowerCase() === "active") ?? []
    return (
      active?.map((item) => {
        return {
          label: item.role_name,
          value: item.role_name,
        }
      }) ?? []
    )
  }, [roles])

  const actions = [
    {
      name: "Edit staff",
      method: (item: any) => {
        setSelectedStaff(item)
        setSelectedAction("Edit")
      },
      permission: UPDATE_STAFF,
    },
    {
      name: "View staff",
      method: (item: any) => {
        setSelectedStaff(item)
        setSelectedAction("View")
      },
      permission: UPDATE_STAFF,
    },
    {
      name: "Deactivate staff",
      method: (item: any) => {
        setSelectedStaff(item)
        setSelectedAction("Deactivate")
      },
      permission: UPDATE_STAFF,
    },
    {
      name: "Activate staff",
      method: (item: any) => {
        setSelectedStaff(item)
        setSelectedAction("Activate")
      },
      permission: UPDATE_STAFF,
    },
  ]
  const activateDeactivateStaff = async () => {
    const callback = (res: any) => {
      if ([200, 201].includes(res?.code)) {
        toast.success(res?.message)
        setSelectedAction("")
      }
    }
    tryCatch(async () => {
      if (selectedAction === "Deactivate") {
        const res = await deactivateStaff(selectedStaff.staff_id).unwrap()
        callback(res)
      } else {
        const res = await activateStaff({ staffId: selectedStaff.staff_id, data: { status: "active" } }).unwrap()
        callback(res)
      }
    })
  }
  const resetSelection = () => {
    setSelectedAction("")
  }
  const exportAllStaff = () => {
    tryCatch(async () => {
      const res = await getAllStaff(
        removeKeysWithEmptyValues({
          status: selectedStatus.length ? stringifyArray(selectedStatus) : "",
          role: selectedRole.length ? stringifyArray(selectedRole) : "",
          search: params.search ?? "",
          noLimit: true,
        }),
      ).unwrap()
      const data = res?.staff?.map((item: any) => {
        return {
          "Staff name": `${item?.first_name} ${item?.last_name}`,
          "Employee ID": item?.staff_number ?? "N/A",
          "Phone Number": item?.phone_number ?? "N/A",
          "Role assigned": item?.role,
          Status: item?.status,
        }
      })
      exportData(data, "Staff List")
    })
  }
  return {
    actions,
    changePage,
    resetSelection,
    exportAllStaff,
    selectedAction,
    activateDeactivateStaff,
    selectedStatus,
    setSelectedStatus,
    breadCrumbs,
    isFetchingRoles,
    roleFilterOptions,
    roles,
    roleOptions,
    selectLimit,
    isExporting,
    setSelectedStaff,
    staffs,
    isFetchingStaffs,
    setSelectedRole,
    setSelectedAction,
    selectedRole,
    selectedStaff,
    handleSearch,
    isActivatingStaff,
    isDeactivatingStaff,
    activeRoles,
  }
}
