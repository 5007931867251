import { Icon } from "@iconify/react"
import { Button, Input, Modal, Textarea } from "components/core"
import useConfigurationManagement from "./useConfigurationManagement"
import { Configuration } from "types/configuration"
import { Dropdown } from "components/core/Dropdown/Dropdown"
import { FileUploader } from "react-drag-drop-files"
import { toast } from "react-toastify"
import { RenderIf } from "components/hoc"
import { MatchVariables } from "./MatchVariables/MatchVariables"

interface ConfigurationManagementProps {
  onClose: () => void
  title: string
  configuration: Configuration
  // eslint-disable-next-line no-unused-vars
  setSelectedAction: (e: any) => void
  setSelectedConfiguration: any
  type: string
}

const fileTypes = ["JPG", "JPEG", "PNG"]

export const ConfigurationManagement = ({
  onClose,
  title,
  configuration,
  setSelectedAction,
  setSelectedConfiguration,
  type,
}: ConfigurationManagementProps) => {
  const {
    formData,
    setFormData,
    handleChange,
    button,
    modeOfConnections,
    handleImageDrop,
    file,
    setFile,
    showMatch,
    setShowMatch,
  } = useConfigurationManagement({ title, type, configuration })
  const purposeIsEdit = title === "edit"
  const modalTitle = purposeIsEdit ? `Edit ${configuration.configuration_name}` : "Add Configuration"

  return (
    <>
      <RenderIf condition={!showMatch}>
        <Modal title={modalTitle} position="modal-right" width="w-[631px]" closeModal={onClose}>
          <form
            className="relative"
            onSubmit={(e) => {
              e.preventDefault()
              setShowMatch(true)
            }}
          >
            <div className="modal-right-body">
              <Input
                label="Configuration Name"
                type="text"
                id="configurationName"
                name="configurationName"
                // readOnly={loading}
                value={formData?.configurationName}
                onChange={handleChange}
                className="mb-[16px]"
              />
              <Textarea
                label="Short Description"
                id="description"
                name="description"
                rows={4}
                placeholder="Type here..."
                // readOnly={loading}
                value={formData?.description}
                className="mb-[16px]"
                onChange={handleChange}
                maxLength={320}
              />
              <Dropdown
                label="Mode of Connection"
                placeholder="-Select mode of connection-"
                options={modeOfConnections}
                selectInput={true}
                search={false}
                selected={formData.modeOfConnection}
                onSelect={(data: any) => {
                  setFormData({ ...formData, modeOfConnection: data.value })
                }}
              />
              <Input
                label="Handler"
                type="text"
                id="handler"
                name="handler"
                disabled={true}
                value={formData?.configurationName.toLowerCase()}
                onChange={handleChange}
                className="mb-[16px]"
              />
              <div>
                <p className="text-body text-xs font-medium mb-[8px]">
                  {!purposeIsEdit && !configuration?.logo
                    ? "Add logo (Optional)"
                    : "Upload new logo (this will overwrite the current logo)"}
                </p>
                <RenderIf condition={!file?.name}>
                  <FileUploader
                    handleChange={handleImageDrop}
                    onTypeError={(err: any) => toast.error(err)}
                    name="file"
                    types={fileTypes}
                  >
                    <div className="border-dashed border border-grey-200 p-[8px] rounded-[6px]">
                      <div className="flex items-center">
                        <Icon icon="ph:export" className="text-paragraph w-[18px] h-[18px] mr-[15px]" />
                        <p className="text-xs font-medium">
                          Drag & drop files or <button className="text-primary-actions">browse files</button>
                        </p>
                      </div>
                    </div>
                  </FileUploader>
                </RenderIf>
                <RenderIf condition={file?.name}>
                  <div className="border border-grey-200 p-[8px] rounded-[6px] flex justify-between">
                    <p className="text-sm text-body">{file?.name}</p>
                    <button type="button" onClick={() => setFile(null)}>
                      <Icon icon="ph:trash" className="w-[18px] h-[19.5px] text-paragraph" />
                    </button>
                  </div>
                </RenderIf>
              </div>
            </div>
            <div className="modal-right-button-container">
              <Button
                theme="primary"
                size="44"
                className="min-w-[105px] mr-[8px] capitalize"
                disabled={button.disabled}
              >
                {button.name}
              </Button>
              <Button
                theme="transparent"
                size="44"
                className="min-w-[105px] mr-[8px] capitalize"
                type="button"
                onClick={onClose}
              >
                Discard
              </Button>
            </div>
          </form>
        </Modal>
      </RenderIf>
      <RenderIf condition={showMatch}>
        <MatchVariables
          title={title}
          formData={formData}
          configuration={configuration}
          setSelectedAction={setSelectedAction}
          setSelectedConfiguration={setSelectedConfiguration}
          onClose={() => setSelectedAction("")}
          file={file}
        />
      </RenderIf>
    </>
  )
}
