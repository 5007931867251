import React, { Fragment } from "react"
import { Icon } from "@iconify/react"
import { ReactComponent as Logo } from "assets/svg/logo.svg"
import { Menu, Transition } from "@headlessui/react"
import { RenderIf } from "components/hoc"
import { clearTokenCookie } from "utils/getCookie"
import { useDispatch, useSelector } from "react-redux"
import { Avatar } from "../Avatar/Avatar"

export const Header: React.FC = () => {
  const dispatch = useDispatch()
  const { first_name, last_name } = useSelector((state: any) => state.auth)
  const name = `${first_name} ${last_name}`
  const dropdownItems = [
    {
      label: "My Profile",
      icon: name,
      action: () => null,
    },
    {
      label: "Logout",
      icon: "ph:sign-out",
      action: () => {
        clearTokenCookie()
        dispatch({ type: "logout" })
      },
    },
  ]

  return (
    <header className="flex items-center justify-between w-full pt-2 pb-4 pl-6 pr-12 border-b border-b-neutral-10">
      <div className="flex items-center gap-2">
        <Logo />
        {/* <div className="flex items-center justify-center text-center py-1 px-3 bg-secondary-base rounded-2xl font-medium text-white text-sm">
          Super Admin
        </div> */}
      </div>
      <div className="flex items-center gap-3">
        <button
          type="button"
          className="relative rounded-full grid content-center justify-items-center h-10 w-10 bg-neutral-5 border border-neutral-10"
        >
          <Icon icon="ph:bell-simple" className="text-neutral-80 text-2xl" />
          <span className="absolute w-2.5 h-2.5 bg-primary-base border border-white rounded-full top-0 right-0" />
        </button>
        <Menu as="div" className="relative">
          <Menu.Button
            data-testid="menuBtn"
            className="rounded-full flex items-center gap-2 w-fit py-[3px] px-1 bg-white border border-neutral-10"
          >
            <div className="relative w-8 h-8">
              <Avatar size="32" image={name} alt={name} />
            </div>
            <Icon icon="ph:caret-down" className="text-neutral-80 w-6 h-6" />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-50"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-50"
          >
            <Menu.Items className="absolute z-[1] right-0 mt-2 p-3 w-40 origin-top-right rounded-lg bg-white shadow-lg focus:outline-none">
              {dropdownItems.map((item) => (
                <Menu.Item key={item.label}>
                  {({ active }) => (
                    <button
                      type="button"
                      className={`${
                        active ? "bg-neutral-5 text-body" : "text-neutral-base"
                      } group flex w-full items-center gap-3 rounded-lg p-3 text-sm font-semibold`}
                      onClick={() => item.action()}
                      data-testid={item.label}
                    >
                      {/* <RenderIf condition={!item.icon.startsWith("http")}>
                        <Icon className="h-6 w-6" aria-hidden="true" icon={item.icon} />
                      </RenderIf> */}
                      <RenderIf condition={item.icon.startsWith("http")}>
                        <div className="rounded-full overflow-hidden w-6 h-6">
                          <img src={item.icon} alt="user" className="object-cover w-6 h-6" />
                        </div>
                      </RenderIf>
                      {item.label}
                    </button>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </header>
  )
}
