import instance from "./axios-instance"
import type { BaseQueryFn } from "@reduxjs/toolkit/query"
import type { AxiosRequestConfig, AxiosError } from "axios"
import { toast } from "react-toastify"
import { handleError } from "utils/handleErrors"
import { removeKeysWithEmptyValues } from "utils/objectFormatter"

interface P {
  url: string
  method?: AxiosRequestConfig["method"]
  data?: AxiosRequestConfig["data"]
  params?: AxiosRequestConfig["params"]
  headers?: AxiosRequestConfig["headers"]
}

const myErrs = ["jwt malformed", "refresh token is invalid", "invalid token"]

export const axiosBaseQuery =
  ({ baseUrl }: { baseUrl: string } = { baseUrl: "" }): BaseQueryFn<P, unknown, unknown> =>
  async ({ url, method, data, params, headers }) => {
    try {
      const result = await instance({
        url: baseUrl + url,
        method,
        data,
        params: removeKeysWithEmptyValues({ ...params }),
        headers,
      })

      if (![200, 201].includes(result?.data?.code) && !myErrs.includes(result?.data?.message?.toLowerCase())) {
        toast.error(
          handleError({
            status: result?.data?.code ? result?.data?.code : result?.data?.statusCode,
            message: result?.data?.message || "Internal Server Error",
          }),
        )
      }

      return { data: result?.data }
    } catch (axiosError) {
      const err = axiosError as AxiosError
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      }
    }
  }
