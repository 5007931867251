import React from "react"
import { ReactComponent as Folder } from "assets/svg/folder.svg"
import { Button } from "components/core"
import BulkStaffTemplate from "assets/templates/BulkStaffTemplate.csv"
import { FileUploader } from "react-drag-drop-files"

interface MultiStaffProps {
  closeModal: () => void
  checkPreview?: () => void
  // eslint-disable-next-line no-unused-vars
  convertCsvtoJson?: (e: any) => void
  uploadedFile: any
}

const MultiStaff = ({ closeModal, convertCsvtoJson, uploadedFile, checkPreview }: MultiStaffProps) => {
  const fileTypes = ["csv"]

  return (
    <div>
      <div>
        <div className="px-6">
          <FileUploader handleChange={convertCsvtoJson} name="file" types={fileTypes}>
            <div
              className="h-60 flex flex-col items-center justify-center border-[0.3px]
              border-primary-actions bg-primary-30 mt-6 rounded-lg cursor-pointer"
              data-testid="test-file"
            >
              <Folder />
              {uploadedFile?.name ? (
                <div className="mt-3 flex justify-center items-center flex-col">
                  <p className="text-base font-semibold text-center mb-3 overflow-x-scroll break-all px-6 max:w-3/5">
                    {uploadedFile.name}
                  </p>
                  <div className="border border-neutral-200 text-body rounded py-1.5 px-3 text-xs">
                    Choose another file
                  </div>
                </div>
              ) : (
                <div className="text-sm text-body text-center mt-6">
                  <p>
                    Drag or <span className="text-primary-actions">choose file</span> to upload
                  </p>
                  <p>Only .csv allowed and must not exceed 5mb</p>
                </div>
              )}
            </div>
          </FileUploader>
        </div>
        <div className="bg-error-lightest py-4 px-8 border-t border-grey-200 fixed bottom-[80px] w-full">
          <p className="text-sm">
            Need to upload multiple admin?{" "}
            <a href={BulkStaffTemplate} className="text-primary-actions" download="BulkStaffTemplate.csv">
              Download Template
            </a>
          </p>
        </div>
      </div>
      <div className="!border-0 modal-right-button-container !pb-3">
        <Button
          theme="primary"
          className="min-w-[105px] mr-[8px]"
          loading={false}
          onClick={checkPreview}
          disabled={!uploadedFile?.name}
          size="44"
        >
          Upload
        </Button>
        <Button theme="transparent" className="w-[105px] px-[30px]" type="button" onClick={closeModal} size="44">
          Discard
        </Button>
      </div>
    </div>
  )
}

export default MultiStaff
