import EmptyGrievanceObject from "assets/svg/empty-grievance.svg"

const EmptyGrievanceState = ({ currentTab }: { currentTab: string }) => {
  return (
    <div className="flex h-full w-full items-center justify-center flex-col">
      <img src={EmptyGrievanceObject} alt="empty state" />
      <h1 className="text-center text-headers font-medium">No Data Yet</h1>
      <p className="text-body w-8/12 text-sm text-center capitalize">
        {` All ${currentTab} grievances will be displayed here`}
      </p>
    </div>
  )
}

export default EmptyGrievanceState
