import Papa from "papaparse"
import { useState } from "react"
import { toast } from "react-toastify"
import { useCreateTrainingBeneficiaryMutation } from "services/api/trainingSlice"
import { tryCatch } from "utils/tryCatch"

export default function useBulkUpload({ currentTab }) {
  const [file, setFile] = useState({})
  const [csvData, setCsvData] = useState([])

  const [addBeneficiaries, { isLoading: isCreatingBulkTraining }] = useCreateTrainingBeneficiaryMutation({})

  const convertCsvtoJson = (file) => {
    setFile({
      name: file.name,
      size: (file.size / 1000).toFixed(2),
    })
    if (file) {
      const fileDetails = file
      const reader = new FileReader()
      reader.onload = () => {
        const data = reader.result
        // Parse CSV using PapaParse
        const result = Papa.parse(data, {
          header: true,
        })
        setCsvData(result.data)
      }
      reader.readAsText(fileDetails)
    }
  }

  const handleAddBulkBeneficiaries = async ({ id, onClose }) => {
    if (!csvData || csvData.length === 0) {
      toast.error("No data in this file, please upload another.")
      setFile([])
      setCsvData([])
      return
    }

    const beneficiaryData = csvData.map((item) => ({
      beneficiary_name: item["Name"],
      phone_number: item["Phone Number"],
      lga: item["LGA"],
      dob: item["Date Of Birth"],
      state: item["State"],
      age: item["age"] ?? null,
      beneficiary_reference_id: item['Household Id']
    }))

    const payload = {
      id: id,
      entry_mode: currentTab,
      beneficiary_type: "citizen",
      beneficiary_data: beneficiaryData,
    }

    const callback = (res) => {
      if ([200, 201].includes(res?.code)) {
        toast.success(res.message)
        onClose()
      }
    }
    tryCatch(async () => {
      let res = await addBeneficiaries(payload).unwrap()
      callback(res)
    })
  }

  return { file, csvData, setFile, setCsvData, convertCsvtoJson, handleAddBulkBeneficiaries, isCreatingBulkTraining }
}
