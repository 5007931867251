import { toast } from "react-toastify";
import { authSlice, updateToken } from "./api/authSlice";
import { getCookie } from 'utils/getCookie';

let store
export const injectStore = _store => store = _store
export const getUser = () => store?.getState()?.auth

export const refreshUserToken = async () => {
    const { refreshToken: refreshTokenProxy } = authSlice.endpoints;
    const userRefreshToken = getUser()?.refreshToken
    
    if (!userRefreshToken || !getCookie("token")) {
        toast.error("Session expired")
        store.dispatch({ type: 'logout' })
        return
    }

    const res = await store.dispatch(refreshTokenProxy.initiate({ refreshToken: userRefreshToken }))

    if (res?.data?.code !== 200) {
        store.dispatch({ type: 'logout' })
        return
    }

    store.dispatch(updateToken(res?.data?.data))

    return res
}