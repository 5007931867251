import { useEffect, type ReactNode } from "react"
import { Header, Sidebar } from "components/core"
import { RequireAuth } from "components/hoc/RequireAuth/RequireAuth"
import { useLazyGetAllStatesQuery, useLazyGetZonesQuery } from "services/api/commonSlice"

const DashboardLayout = ({ children }: { children: ReactNode }) => {
  const [getAllStates] = useLazyGetAllStatesQuery()
  const [getZones] = useLazyGetZonesQuery()

  useEffect(() => {
    Promise.all([getAllStates({}), getZones({})])
  }, [])

  return (
    <RequireAuth>
      <div className="flex flex-col w-full h-screen">
        <Header />
        <div className="flex flex-1 overflow-hidden">
          <Sidebar />
          <div className="w-full overflow-y-scroll px-12 pt-8 pb-10 transition-all ease-linear duration-700">
            {children}
          </div>
        </div>
      </div>
    </RequireAuth>
  )
}

export default DashboardLayout
