import { useState, useMemo, useEffect } from "react"
import { useFetchCoursesQuery, useEditTrainingMutation, useFetchSingleTrainingQuery } from "services/api/trainingSlice"
import { tryCatch } from "utils/tryCatch"
import { toast } from "react-toastify"

interface Course {
  id: string
  to_delete?: boolean
  to_insert?: boolean
}

const formatDateForInput = (dateString: any) => {
  const date = new Date(dateString)
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, "0")
  const day = String(date.getDate()).padStart(2, "0")
  return `${year}-${month}-${day}`
}

const generateFormData = (data: any) => {
  return {
    training_type: data?.training_type ?? "",
    training_unit: data?.training_unit ?? "",
    skill_type: data?.skill_type ?? "",
    name: data?.name ?? "",
    courses_id: [],
    courses: [],
    attendance_required: data?.attendance_required ?? "",
    training_needs: data?.training_needs ?? "",
    start_date: data?.training_start_date ? formatDateForInput(data?.training_start_date) : "",
    end_date: data?.training_end_date ? formatDateForInput(data?.training_end_date) : "",
    training_vendor: data?.vendors ?? [""],
  }
}

export default function useEditTraining({ training }: any) {
  const { data: singleTraining, isFetching: isFetchingSingleTraining } = useFetchSingleTrainingQuery(
    {
      id: training?.training_id,
    },
    { skip: !training?.training_id },
  )

  const [originalFormData, setOriginalFormData] = useState<any>(() => generateFormData(training))
  const [formData, setFormData] = useState<any>(() => generateFormData(training))
  const [defaultOptions, setDefaultOptions] = useState([])
  const [courseDropdownElements, setCourseDropdownElements] = useState([])

  const {
    data: coursesData,
    isFetching: isFetchingCourses,
    refetch: refetchCourses,
  } = useFetchCoursesQuery(
    {
      limit: 1000,
      training_type: training?.training_type,
      skill_type: formData?.skill_type,
    },
    { skip: !(training?.training_type && formData?.skill_type) },
  )

  useEffect(() => {
    if (formData.skill_type !== training?.skill_type) {
      refetchCourses()
      setDefaultOptions([])

      setFormData((prevFormData: any) => ({
        ...prevFormData,
        courses: [],
        courses_id: [],
      }))
    }
  }, [formData?.skill_type])

  useEffect(() => {
    const courseIds = singleTraining?.courses.map((course: { course_id: string }) => course.course_id)
    const selectedCourses = singleTraining?.courses.map((course: any) => ({
      value: course?.course_id,
      label: course.course_title,
    }))

    setDefaultOptions(selectedCourses)

    setFormData((prevFormData: any) => ({
      ...prevFormData,
      courses_id: courseIds,
    }))

    setOriginalFormData((prevFormData: any) => ({
      ...prevFormData,
      courses_id: courseIds,
    }))
  }, [isFetchingSingleTraining, training?.training_id])

  const [editTraining, { isLoading: isEditingTraining }] = useEditTrainingMutation({})

  useEffect(() => {
    const courseDrop = coursesData?.courses.map((course: any) => {
      return {
        label: course?.title,
        value: course.course_id,
      }
    })
    setCourseDropdownElements(courseDrop)
  }, [coursesData, isFetchingCourses, formData?.skill_type])

  const addNewVendor = () => {
    setFormData((prevData: any) => ({
      ...prevData,
      training_vendor: [...prevData.training_vendor, ""],
    }))
  }

  const handleVendorChange = (index: number, value: string) => {
    const newVendors = [...formData.training_vendor]
    newVendors[index] = value
    setFormData({ ...formData, training_vendor: newVendors })
  }

  const handleDeleteVendor = (index: number) => {
    const filteredVendors = formData?.training_vendor?.filter((_value: any, valueIndex: any) => valueIndex !== index)

    setFormData((prevData: any) => ({
      ...prevData,
      training_vendor: filteredVendors,
    }))
  }

  const isFormChanged = useMemo(() => {
    return JSON.stringify(formData) !== JSON.stringify(originalFormData)
  }, [formData, originalFormData])

  const isFormValid = useMemo(() => {
    const hasValidCourses = formData?.courses && formData.courses.length > 0
    const hasValidCoursesId = formData?.courses_id && formData.courses_id.length > 0
    return (
      !formData.training_type ||
      !formData.name ||
      !hasValidCoursesId || // Ensure courses_id is not empty
      !hasValidCourses ||
      !formData?.skill_type ||
      !formData.start_date ||
      !formData.end_date ||
      !formData?.training_needs ||
      !formData?.attendance_required ||
      formData?.training_vendor?.some((vendor: any) => !vendor)
    )
  }, [formData])

  const handleCourseSelection = (selectedCourse: any) => {
    const newCourseInfo: Course[] = formData?.courses_id?.map((course: any) => {
      const isSelected = selectedCourse.some((selected: { value: string; label: string }) => selected.value === course)

      if (!isSelected) {
        return {
          id: course,
          to_delete: true,
        }
      }
      return { id: course }
    })

    selectedCourse?.forEach((selected: { value: string; label: string }) => {
      const isAlreadyAdded = originalFormData.courses_id.includes(selected.value)

      if (!isAlreadyAdded) {
        newCourseInfo.push({
          id: selected.value,
          to_insert: true,
        })
      }
    })

    setFormData((prevFormData: any) => ({
      ...prevFormData,
      courses: newCourseInfo,
    }))
  }

  const handleEditTraining = async (onClose: () => void) => {
    // eslint-disable-next-line no-unused-vars
    const { attendance_required, courses_id, training_vendor, ...data } = formData

    const payload = {
      ...data,
      ...(training_vendor.length > 0 && { training_vendor }),
    }

    const callback = (res: any) => {
      if ([200, 201].includes(res?.code)) {
        toast.success(res.message)
        onClose()
      }
    }

    tryCatch(async () => {
      let res = await editTraining({ id: training?.training_id, data: payload }).unwrap()
      callback(res)
    })
  }

  return {
    formData,
    setFormData,
    isFormChanged,
    addNewVendor,
    courseDropdownElements,
    isFetchingCourses,
    handleVendorChange,
    handleDeleteVendor,
    isFormValid,
    isEditingTraining,
    handleEditTraining,
    isFetchingSingleTraining,
    defaultOptions,
    handleCourseSelection,
  }
}
