import { useTable } from "hooks"
import { useState } from "react"
import { useParams } from "react-router-dom"
import {
  useGetSingleLivelihoodBeneficiaryQuery,
  useLazyGetSingleLivelihoodBeneficiaryQuery,
} from "services/api/livelihoodSlice"
import { shortDate } from "utils/dateFormatter"
import { removeKeysWithEmptyValues } from "utils/objectFormatter"
import { removeSpecificKeys } from "utils/removeSpecificKeys"
import { stringifyArray } from "utils/stringifyArray"
import { tryCatch } from "utils/tryCatch"
import { exportData } from "utils/exportData"
import dayjs from "dayjs"

interface DateType {
  startDate: Date | null
  endDate: Date | null
}

export default function useViewLivelihoodBeneficiary() {
  const { handleSearch, changePage, selectLimit, params } = useTable()
  const [date, setDate] = useState<DateType>({ startDate: null, endDate: null })
  const [selectedStatus, setSelectedStatus] = useState<any>([])
  const dateRangeFilter = date.endDate && { fromDate: shortDate(date.startDate), toDate: shortDate(date.endDate) }
  const { beneficiary_id } = useParams()
  const {
    data: beneficiary,
    isFetching,
    isLoading,
  } = useGetSingleLivelihoodBeneficiaryQuery(
    {
      id: beneficiary_id,
      params: {
        category: "livelihood",
        status: selectedStatus.length ? stringifyArray(selectedStatus) : "",
        ...dateRangeFilter,
        ...removeSpecificKeys({ ...params }, ["sortBy"]),
      },
    },
    { skip: !beneficiary_id },
  )
  const [getSingleLivelihoodBeneficiary, { isFetching: isExporting }] = useLazyGetSingleLivelihoodBeneficiaryQuery()

  const breadCrumbs = [{ title: "Livelihood" }, { title: beneficiary?.beneficiary?.name }]
  const summaryData: { name: string; value: any }[] = [
    { name: "Gender", value: beneficiary?.beneficiary?.gender },
    { name: "Beneficiary ID", value: beneficiary?.beneficiary?.beneficiary_id },
    { name: "State", value: beneficiary?.beneficiary?.state },
    { name: "LGA", value: beneficiary?.beneficiary?.lga },
    { name: "Ward", value: beneficiary?.beneficiary?.ward },
  ]

  const exportInterventionHistory = () => {
    tryCatch(async () => {
      const res = await getSingleLivelihoodBeneficiary(
        removeKeysWithEmptyValues({
          id: beneficiary_id,
          params: {
            noLimit: true,
            category: "livelihood",
            status: selectedStatus.length ? stringifyArray(selectedStatus) : "",
            ...dateRangeFilter,
          },
        }),
      ).unwrap()

      const data = res?.history?.map((item: any) => {
        return {
          "Intervention ID": item?.intervention_id,
          "Intervention name": item?.intervention_title,
          "Total amount": item?.amount,
          "Top-up amount": item?.top_amount,
          "Payment Cycle": item?.payment_cycle,
          "Data Source": item?.data_source ?? "N/A",
          "Date Created": dayjs(item.created_at).format("MMMM DD YYYY"),
          Status: item?.status,
        }
      })
      exportData(data, `${beneficiary?.beneficiary?.name}-Intervention History`)
    })
  }

  return {
    breadCrumbs,
    summaryData,
    handleSearch,
    changePage,
    selectLimit,
    date,
    setDate,
    selectedStatus,
    setSelectedStatus,
    isFetching,
    isLoading,
    beneficiary,
    exportInterventionHistory,
    isExporting,
  }
}
