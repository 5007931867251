import { Icon } from "@iconify/react/dist/iconify.js"
import { Checkbox, Spinner } from "components/core"
import { RenderIf } from "components/hoc"
import { useOutsideTouch } from "hooks"
import { useCallback, useState } from "react"

interface Option {
  label: string
  value: string | number
}

interface FilterProps {
  options: Option[]
  title: string
  loading?: boolean
  disabled?: boolean
  selectedOption?: Option[] | Option
  // eslint-disable-next-line no-unused-vars
  setSelectedOption: (data: Option | Option[]) => void
  multiple?: boolean // If user is allowed to select more than one filter
  containerClass?: string
}

export const Filter = ({
  options,
  title,
  // selectedOption,
  setSelectedOption,
  disabled = false,
  multiple = false,
  loading,
  containerClass,
}: FilterProps) => {
  const { ref, display, setDisplay } = useOutsideTouch()
  const [selected, setSelected] = useState<any>([])

  const toggleDisplay = (e: any) => {
    e.stopPropagation()
    setDisplay(!display)
  }

  const handleChange = useCallback(
    (option: Option) => {
      if (!multiple) {
        if (selected?.label === option.label) {
          setSelected([])
          setSelectedOption([])
        } else {
          setSelected(option)
          setSelectedOption(option)
        }
        return
      }

      // Multiple select mode
      const selectedOptionsArray = selected as Option[]
      const isSelected = selectedOptionsArray.some((item: Option) => item.label === option.label)

      let result
      if (isSelected) {
        // Unselect the option if already selected
        result = selectedOptionsArray.filter((item: Option) => item.label !== option.label)
      } else {
        // Select the option if not selected
        result = [...selectedOptionsArray, option]
      }

      setSelected(result)
      setSelectedOption(result) // Pass selected options to parent
    },
    [selected, setSelectedOption, multiple],
  )

  const isChecked = (option: Option): boolean => {
    if (multiple) {
      const selectedOptionsArray = selected as Option[]
      return selectedOptionsArray.some((item: Option) => item.label === option.label)
    }
    const singleOption = selected as Option
    return singleOption?.label === option?.label
  }

  const selectedOptionLength = Array.isArray(selected) && selected?.length

  const clearSelection = () => {
    setSelected([])
    setSelectedOption([])
  }

  return (
    <div className="flex gap-x-2 items-center">
      <div className={`relative  ${containerClass}`}>
        <button
          disabled={disabled}
          onClick={toggleDisplay}
          className="relative flex justify-between items-center w-full h-fit gap-2 rounded-lg border
         border-neutral-10 py-2 pr-2 pl-4 disabled:bg-neutral-5 disabled:border-neutral-10
        disabled:text-neutral-30"
        >
          <div>
            <span className="block truncate font-medium text-sm text-neutral-base">{title}</span>
          </div>
          <div className="flex">
            <RenderIf condition={multiple && !!selectedOptionLength}>
              <p
                className="text-xs bg-[#859B6F] text-white px-[8px] py-[4px] shrink-0 rounded-[24px]"
                data-testid={`test-${selectedOptionLength}`}
              >
                {selectedOptionLength}
              </p>
            </RenderIf>
            <Icon
              icon="ph:caret-down"
              className="h-6 w-6 text-neutral-base pointer-events-none items-center"
              aria-hidden="true"
            />
          </div>
        </button>
        {display && (
          <div
            ref={ref}
            className="absolute z-10 left-0 mt-2 min-w-max origin-top-left max-h-60 overflow-auto rounded-lg
                    bg-white p-3 text-base shadow-lg focus:outline-none sm:text-sm"
          >
            {options?.length
              ? options?.map((option) => (
                  <Checkbox
                    key={option.label.toLowerCase()}
                    label={option.label}
                    id={option.label}
                    checked={isChecked(option)}
                    className="relative cursor-pointer select-none rounded-lg px-3 py-[8px]"
                    onChange={() => handleChange(option)}
                  />
                ))
              : ""}
            {loading && !options?.length && <Spinner />}
            {!loading && !options?.length && (
              <p className="font-inter text-center text-body py-[20px]">No data found</p>
            )}
          </div>
        )}
      </div>
      <RenderIf condition={multiple && selected.length !== 0}>
        <button onClick={clearSelection} className="p-2 rounded-full bg-grey-100">
          <Icon icon="ph:x" className="h-3 w-3 text-neutral-base pointer-events-none items-center" aria-hidden="true" />
        </button>
      </RenderIf>
    </div>
  )
}
