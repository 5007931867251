import { Avatar, Breadcrumb, Filter, Search, Table } from "components/core"
import HeaderLoader from "components/core/Loader/HeaderLoader/HeaderLoader"
import { RenderIf, Restricted } from "components/hoc"
import { singleLivelihoodHeader } from "constants/livelihood"
import { genderOptionsII } from "mocks/payments"
import { defaultFilter } from "mocks/staffs"
import { useNavigate } from "react-router-dom"
import { convertStateToDropdownOptions } from "utils/dropdown"
import useViewLivelihood from "./useViewLivelihood"
import { AllPermissions, PermissionModule } from "types/permission"
import { usePermission } from "hooks"

const ViewLivelihood = () => {
  const {
    breadCrumbs,
    summaryData,
    setSelectedGender,
    selectedGender,
    changePage,
    handleSearch,
    selectLimit,
    nigerianStates,
    selectedState,
    setSelectedState,
    selectedCategory,
    setSelectedCategory,
    isFetching,
    livelihood,
    categoryOptions,
    isLoading,
    isFetchingCategory,
  } = useViewLivelihood()
  const navigate = useNavigate()

  const { LIVELIHOOD } = PermissionModule
  const { VIEW_SINGLE_LIVELIHOOD, VIEW_SINGLE_BENEFICIARY } = AllPermissions
  const { hasPermission } = usePermission()

  return (
    <Restricted module={LIVELIHOOD} permission={VIEW_SINGLE_LIVELIHOOD} showError={true}>
      <main data-testid="view-livelihood">
        <RenderIf condition={isLoading}>
          <HeaderLoader />
        </RenderIf>
        <RenderIf condition={!isLoading}>
          <Breadcrumb options={breadCrumbs} showBack={true} />
          <div className="p-6 bg-primary-900 rounded-[8px] mt-3 gap-8">
            <div className="flex justify-between border-b-[.3px] border-b-grey-200 pb-9">
              <h3 className="text-white font-semibold text-2xl capitalize">{livelihood?.result?.intervention_title}</h3>
            </div>
            <div className="mt-3 flex divide-x child-not-last:pr-16 child-not-first:pl-6 ">
              {summaryData.map((data: any) => (
                <div key={data.name}>
                  <p className="text-primary-100 text-xs font-medium">{data.name}</p>
                  <p className="text-primary-30 text-sm mt-[2px]">{data.value || "N/A"}</p>
                </div>
              ))}
            </div>
          </div>
        </RenderIf>
        <div className="flex space-x-2 mt-9 mb-4">
          <Search id="search" name="search" placeholder="Search" onChange={handleSearch} />
          <Filter
            title="State"
            options={[...defaultFilter, ...convertStateToDropdownOptions(nigerianStates)]}
            multiple={true}
            selectedOption={selectedState}
            setSelectedOption={setSelectedState}
          />
          <Filter
            title="Gender"
            options={genderOptionsII.slice(1)}
            selectedOption={selectedGender}
            setSelectedOption={setSelectedGender}
          />
          <Filter
            title="Category"
            options={categoryOptions}
            loading={isFetchingCategory}
            selectedOption={selectedCategory}
            setSelectedOption={setSelectedCategory}
          />
        </div>
        <Table
          headers={singleLivelihoodHeader}
          data={livelihood?.result?.beneficiaries ?? []}
          onPageChange={changePage}
          selectLimit={selectLimit}
          totalCount={livelihood?.result?.no_of_beneficiary}
          perPage={livelihood?.result?.limit}
          page={livelihood?.result?.page}
          loading={isFetching}
        >
          <>
            {livelihood?.result?.beneficiaries?.map((item: any) => (
              <tr
                data-testid={item?.beneficiary_id}
                key={item?.beneficiary_id}
                className="cc-custom-table-data-row cursor-pointer"
                onClick={() =>
                  hasPermission(LIVELIHOOD, VIEW_SINGLE_BENEFICIARY) ? navigate(`${item?.beneficiary_id}`) : {}
                }
              >
                <td className="flex items-center">
                  <Avatar
                    key={item?.beneficiary_id}
                    image={item?.beneficiary_name}
                    alt={item?.beneficiary_name}
                    size="24"
                  />
                  <div className="ml-3">
                    <p className="capitalize max-w-[150px] truncate">{item?.beneficiary_name}</p>
                    <p className="text-grey-base text-xs font-medium">{item?.beneficiary_id}</p>
                  </div>
                </td>
                <td>
                  <p className="w-max">{item?.state?.name}</p>
                </td>
                <td>
                  <p className="w-max">{item?.lga?.name}</p>
                </td>
                <td>
                  <p className="w-max">{item?.ward}</p>
                </td>
                <td>{item.community}</td>
                <td>{item.gender}</td>
              </tr>
            ))}
          </>
        </Table>
      </main>
    </Restricted>
  )
}

export default ViewLivelihood
