import { Modal } from "components/core"
import { AddEditStaffProps } from "types/staff"
import MultiStaff from "./MultiStaff/MultiStaff"
import { ReactComponent as Single } from "assets/icons/single.svg"
import { ReactComponent as Multi } from "assets/icons/multi.svg"
import SingleStaff from "./SingleStaff/SingleStaff"
import useAddEditStaff from "../useAddEditStaff"
import { RenderIf } from "components/hoc"
import BulkUploadPreview from "../../BulkUploadPreview/BulkUploadPreview"
import useBulkUploadStaff from "./MultiStaff/useBulkUploadStaff"

const AddEditStaff = ({ closeModal, action, staff, refetch, resetSelection, defaultRole }: AddEditStaffProps) => {
  const {
    setViewType,
    viewType,
    setPhone,
    phone,
    isInvitingStaff,
    handleInviteStaff,
    formData,
    setFormData,
    handleChange,
    disableBtn,
    isUpdatingStaff,
    showPreview,
    setShowPreview,
  } = useAddEditStaff({ action, refetch, closeModal, staffDetails: staff, defaultRole })

  const { convertCsvtoJson, file, tableData, checkPreview, handleBulkUpload, isUploadingStaffs } = useBulkUploadStaff(
    () => setShowPreview(true),
    resetSelection,
  )

  const tabs = [
    {
      title: "Single Staff",
      component: (
        <SingleStaff
          defaultRole={defaultRole}
          action={action}
          closeModal={closeModal}
          staffDetails={staff}
          setPhone={setPhone}
          phone={phone}
          isInvitingStaff={isInvitingStaff}
          handleInviteStaff={handleInviteStaff}
          formData={formData}
          setFormData={setFormData}
          handleChange={handleChange}
          disableBtn={disableBtn}
          isUpdatingStaff={isUpdatingStaff}
        />
      ),
      id: "single-staff",
      icon: <Single fill={viewType === "single-staff" ? "#1F1F1F" : "#5C5C5C"} />,
    },
    {
      title: "Multiple Staff",
      component: (
        <MultiStaff
          closeModal={closeModal}
          uploadedFile={file}
          checkPreview={checkPreview}
          convertCsvtoJson={convertCsvtoJson}
        />
      ),
      id: "multi-staff",
      icon: <Multi fill={viewType === "multi-staff" ? "#1F1F1F" : "#5C5C5C"} />,
    },
  ]

  return (
    <>
      <RenderIf condition={!showPreview}>
        <Modal
          title={`${action} ${action === "Add" ? "New" : ""} Staff`}
          position="modal-right"
          width="w-[432px]"
          closeModal={closeModal}
        >
          <div
            className={`overflow-y-auto h-[85vh] ${viewType === "multi-staff" ? "flex justify-between flex-col" : ""}`}
          >
            <div className="">
              {action === "Add" && (
                <div className="border border-neutral-10 bg-neutral-3 rounded-lg p-1 grid grid-cols-2 h-14 mx-6 mt-6">
                  {tabs.map((t) => (
                    <button
                      className={`
                        text-16 font-normal py-2 flex justify-center items-center
                        ${viewType === t.id ? "text-grey-250 bg-white rounded shadow-sm" : "text-paragraph"}`}
                      onClick={() => {
                        setViewType(t.id)
                      }}
                      key={t.id}
                    >
                      {t.icon}
                      <p className="ml-2 font-medium text-sm">{t.title}</p>
                    </button>
                  ))}
                </div>
              )}
              {tabs.find((t) => t.id === viewType)?.component}
            </div>
          </div>
        </Modal>
      </RenderIf>
      <RenderIf condition={showPreview}>
        <BulkUploadPreview
          closeModal={resetSelection}
          uploadStaffs={handleBulkUpload}
          uploadNew={() => setShowPreview(false)}
          tableData={tableData}
          loading={isUploadingStaffs}
        />
      </RenderIf>
    </>
  )
}

export default AddEditStaff
