import { Avatar } from "components/core"
import dayjs from "dayjs"

interface Props {
  profile: any
  selected: boolean
  onClick?: () => void
}

const InfoCard = ({ profile, selected, onClick }: Props) => {
  return (
    <div
      onClick={onClick}
      data-testid={`test-${profile?.complaint_ticket}`}
      className={`p-4 w-full ${selected ? "bg-grey-100 border-l-[2px] border-l-primary-actions" : "bg-white"} border-b`}
    >
      <div className="flex gap-x-2 w-full">
        <Avatar image={profile?.complaint_name} size="40" stroke={selected} />
        <div className="w-full flex gap-y-1 flex-col">
          <div className="w-full flex justify-between">
            <h1 className="text-headers">{profile?.complaint_name}</h1>
            <span className="text-grey-base font-medium ">{dayjs(profile.created_at).format("h:mm A")}</span>
          </div>
          <div className="grid grid-cols-3 border-r text-start last:border-r-0 text-grey-base text-sm">
            <h3 className="border-r">{profile?.complaint_ticket}</h3>
            <h3 className="border-r pl-1 capitalize text-center">
              {profile?.entry_mode === "manual" ? "Manual Entry" : profile?.entry_mode}
            </h3>
            <h3 className="pl-1 capitalize text-center">
              {profile?.complaint_type === "non-beneficiary" ? "Non Beneficiary" : "Beneficiary"}
            </h3>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InfoCard
