import React from "react"
import { motion } from "framer-motion"
import { Breadcrumb, Button, Filter, Search, Table, Avatar } from "components/core"
import useViewPaymentSchedule from "./useViewPaymentSchedule"
import PaymentScheduleDetails from "components/pages/Payments/ViewPaymentSchedule/PaymentScheduleDetails"
import { paymentScheduleInfoHeaders } from "constants/payments"
import { RenderIf } from "components/hoc"
import ApprovalModal from "components/pages/Payments/ViewPaymentSchedule/ApprovalModal/ApprovalModal"
import DeclineModal from "components/pages/Payments/ViewPaymentSchedule/DeclineModal.tsx/DeclineModal"
import { genderOptionsII } from "mocks/payments"
import { fullDateFormatter } from "utils/dateFormatter"
import HeaderLoader from "components/core/Loader/HeaderLoader/HeaderLoader"

const MotionTr = motion.tr

export default function ViewPaymentUnit() {
  const {
    breadCrumbs,
    unit,
    handleSearch,
    selectedGender,
    setSelectedGender,
    selectedAction,
    setSelectedAction,
    beneficiariesData,
    isFetchingBeneficiaries,
    scheduleId,
    params,
    changePage,
    selectLimit,
    singlePaymentSchedule,
    showApproveButton,
    isFetchingSinglePaymentSchedule,
  } = useViewPaymentSchedule()

  const shouldShowButtons = () => {
    const approvalCount = singlePaymentSchedule?.approval_count ?? 0
    const approvalState = singlePaymentSchedule?.approval_state ?? null

    if (beneficiariesData?.beneficiaries?.length === 0) return false

    if (approvalState && Object.values(approvalState).some((arr: any) => arr.includes(false))) {
      return false
    }

    if (showApproveButton) {
      if (approvalCount === 0 && unit === "account") {
        return true
      }
      if (approvalCount === 1 && unit === "audit") {
        return true
      }
      if (approvalCount === 2 && unit === "program-manager") {
        return true
      }
      if (approvalCount >= 3) {
        return false
      }
      return false
    }
    return false
  }

  return (
    <main>
      <Breadcrumb options={breadCrumbs} showBack />
      <div className="mt-2 mb-8">
        <RenderIf condition={isFetchingSinglePaymentSchedule || singlePaymentSchedule === "undefined"}>
          <HeaderLoader />
        </RenderIf>
        <RenderIf condition={!isFetchingSinglePaymentSchedule}>
          <PaymentScheduleDetails
            showActions={unit === "account"}
            unit={unit ?? ""}
            showApproved={unit === "payment-unit"}
          />
        </RenderIf>
      </div>

      <div className="flex space-x-2">
        <Search id="search" name="search" placeholder="Search" onChange={handleSearch} />
        <Filter
          title="Gender"
          options={genderOptionsII}
          multiple={false}
          loading={false}
          selectedOption={selectedGender}
          setSelectedOption={setSelectedGender}
        />
      </div>

      <Table
        headers={paymentScheduleInfoHeaders}
        data={beneficiariesData?.beneficiaries ?? []}
        totalCount={beneficiariesData?.total}
        perPage={Number(params?.limit)}
        onPageChange={changePage}
        selectLimit={selectLimit}
        page={Number(params?.page)}
        loading={isFetchingBeneficiaries}
      >
        <>
          {beneficiariesData?.beneficiaries?.map((item: any) => (
            <React.Fragment key={item.id}>
              <MotionTr
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="cc-custom-table-data-row"
                data-testid={item.id}
              >
                <td className="flex items-center">
                  <div className="flex items-center">
                    <Avatar key={item?.id} image={item?.name} alt={item?.name} size="32" />
                    <div className="ml-[16px]">
                      <p className="max-w-[280px] truncate capitalize">{item?.name}</p>
                      <p className="text-grey-base text-xs">{item?.intervention_no}</p>
                    </div>
                  </div>
                </td>
                <td className="text-sm text-body font-normal uppercase">{item.state}</td>
                <td className="max-w-[150px] truncate capitalize">
                  {item?.date_of_birth ? fullDateFormatter(item.date_of_birth) : "--"}
                </td>
                <td>{item?.lga}</td>
                <td>{item?.ward}</td>
                <td>{item?.community ?? "--"}</td>
                <td>{item.account_number}</td>
                <td>{item?.account_name ?? "--"}</td>
                <td>{item?.bank ?? "--"}</td>
                <td>{item?.nin ?? "--"}</td>
                <td>{item?.bvn ?? "--"}</td>
                <td className="capitalize">{item.gender ?? "--"}</td>
                <td>{item.source_register_name}</td>
              </MotionTr>
            </React.Fragment>
          ))}
        </>
      </Table>

      <RenderIf condition={shouldShowButtons()}>
        <div className="flex justify-end gap-x-2">
          <Button theme="transparent" size="40" onClick={() => setSelectedAction("open-decline-modal")}>
            Decline
          </Button>
          <Button theme="primary" size="40" onClick={() => setSelectedAction("open-approval-modal")}>
            Approve
          </Button>
        </div>
      </RenderIf>

      {selectedAction === "open-decline-modal" && (
        <DeclineModal
          closeModal={() => setSelectedAction("")}
          unit={unit ?? ""}
          approvalState={singlePaymentSchedule?.approval_state}
          id={scheduleId ?? ""}
        />
      )}

      {selectedAction === "open-approval-modal" && (
        <ApprovalModal
          closeModal={() => setSelectedAction("")}
          unit={unit ?? ""}
          approvalState={singlePaymentSchedule?.approval_state}
          id={scheduleId ?? ""}
        />
      )}
    </main>
  )
}
