import { PermissionModule } from "types/permission"

interface SubRoutes {
  name: string
  href: string
  icon: string
  module?: string
}
export interface AppRoutes extends Omit<SubRoutes, "icon"> {
  inactiveIcon: string
  activeIcon: string
  children: SubRoutes[]
}

const { ROLES_AND_PERMISSION, CONFIGURATION, STAFF_MANAGEMENT } = PermissionModule

export const appRoutes = [
  // {
  //   name: "Dashboard",
  //   href: "/dashboard",
  //   inactiveIcon: "ph:stack",
  //   activeIcon: "ph:stack-fill",
  //   children: [],
  // },
  {
    name: "Intervention",
    href: "/intervention",
    inactiveIcon: "ph:handshake",
    activeIcon: "ph:handshake-fill",
    children: [],
  },
  {
    name: "Beneficiary",
    href: "/beneficiary?category=banked-beneficiary",
    inactiveIcon: "ph:users-three",
    activeIcon: "ph:users-three-fill",
    children: [],
  },
  {
    name: "Data",
    href: "/data-mining",
    inactiveIcon: "ph:files",
    activeIcon: "ph:files-fill",
    children: [],
  },
  {
    name: "GRM",
    href: "/grievance?tab=activity-log",
    inactiveIcon: "ph:note",
    activeIcon: "ph:note-fill",
    children: [],
  },
  {
    name: "Payments",
    href: "/payments/payment-unit",
    inactiveIcon: "ph:money",
    activeIcon: "ph:money-fill",
    children: [
      {
        name: "Payment Unit",
        href: "/payments/payment-unit",
        icon: "ph:money",
      },
      {
        name: "Account",
        href: "/payments/account",
        icon: "ph:calculator",
      },
      {
        name: "Audit",
        href: "/payments/audit",
        icon: "ph:archive",
      },
      {
        name: "Program Manager",
        href: "/payments/program-manager",
        icon: "ph:shield-star",
      },
      {
        name: "Transactions",
        href: "/payments/transactions",
        icon: "ph:archive",
      },
    ],
  },
  {
    name: "Co-responsibility",
    href: "/co-responsibility",
    inactiveIcon: "ph:user-switch",
    activeIcon: "ph:user-switch-fill",
    children: [],
  },
  {
    name: "Livelihood",
    href: "/livelihood",
    inactiveIcon: "ph:user-switch",
    activeIcon: "ph:user-switch-fill",
    children: [],
  },
  {
    name: "Training",
    href: "/training",
    inactiveIcon: "ph:graduation-cap",
    activeIcon: "ph:graduation-cap-fill",
    children: [],
  },
  {
    name: "Reports",
    href: "/reports",
    inactiveIcon: "ph:chart-bar-horizontal-duotone",
    activeIcon: "ph:chart-bar-horizontal-fill",
    children: [],
  },
  {
    name: "Settings",
    href: "/settings/roles-and-permissions",
    inactiveIcon: "ph:gear-fine",
    activeIcon: "ph:gear-fine-fill",
    children: [
      {
        name: "Roles & Permissions",
        href: "/settings/roles-and-permissions",
        icon: "ph:user-gear-fill",
        module: ROLES_AND_PERMISSION,
      },
      {
        name: "Staff Management",
        href: "/settings/staff-management",
        icon: "mdi:user-multiple-outline",
        module: STAFF_MANAGEMENT,
      },
      {
        name: "Configuration",
        href: "/settings/configuration",
        icon: "ph:wrench",
        module: CONFIGURATION,
      },
    ],
  },
] satisfies AppRoutes[]
