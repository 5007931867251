import ReactDatePicker from "react-datepicker"
import { Icon } from "@iconify/react"
import "react-datepicker/dist/react-datepicker.css"
import { RenderIf } from "components/hoc"
import "./DatePicker.css"

interface DatePickerProps {
  startDate?: Date | null
  endDate?: Date | null
  disabled?: boolean
  value?: Date
  maxDate?: Date
  minDate?: Date
  id?: string
  label?: string
  containerVariant?: string
  variant?: string
  placeholder?: string
  dateFormat?: string
  showCalendar?: boolean
  // eslint-disable-next-line no-unused-vars
  onChange: (data: any) => void
  selectsRange?: boolean
}

export const Calendar = ({
  startDate,
  endDate,
  onChange,
  dateFormat = "dd, MMM yyyy",
  placeholder,
  maxDate,
  minDate,
  disabled,
  showCalendar = true,
  containerVariant,
  variant,
  value,
  id,
  label,
  selectsRange = false,
}: DatePickerProps) => {
  const formatDate = (date: Date) => {
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    })
  }

  const dateRangeToString = () => {
    if (startDate && endDate) {
      return `${formatDate(startDate)} - ${formatDate(endDate)}`
    } else if (startDate) {
      return formatDate(startDate)
    }
    return "Select Date Range"
  }

  const handleDateChange = (dates: any) => {
    if (selectsRange) {
      const [start, end] = dates
      onChange({ startDate: start, endDate: end })
      return
    }
    onChange({ startDate: dates })
  }

  return (
    <>
      {selectsRange ? (
        <div className={`date-range-picker ${containerVariant}`}>
          <ReactDatePicker
            selected={startDate}
            onChange={handleDateChange}
            startDate={startDate}
            endDate={endDate}
            maxDate={maxDate}
            selectsRange={selectsRange}
            placeholderText="Select date range"
            className="p-5 h-[42px]"
            customInput={
              <button
                type="button"
                className={`flex gap-1 cc-button--transparent cc-button cc-button--40 w-full border !border-[#E3E5EB] ${variant}`}
              >
                <Icon icon="carbon:calendar" className="text-xl text-[#80898E]" />
                <span className="text-sm text-[#B8B8B8] pr-4 ">{dateRangeToString()}</span>
              </button>
            }
            isClearable
          />
        </div>
      ) : (
        <div className={`${containerVariant || "w-full"}`}>
          <RenderIf condition={!!label}>
            <label
              htmlFor={id}
              className={`
                    ${disabled ? "bg-none" : "bg-brand_white"} block
                    text-sm text-headers mb-[4px] cursor-default font-medium`}
            >
              {label}
            </label>
          </RenderIf>
          <div className="relative">
            <ReactDatePicker
              disabled={disabled}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              selected={value}
              id={id}
              maxDate={maxDate}
              minDate={minDate}
              dateFormat={dateFormat}
              onChange={onChange}
              autoComplete="off"
              placeholderText={placeholder}
              data-testid={`test-${id}`}
              aria-labelledby={id}
              className={`${variant}
          p-5 h-16 text-body text-base w-full outline-0 border hide_tap
          rounded-[4px] bg-[#F9F9FB] focus:border-primary-200 pl-10
          ${disabled ? "bg-grey-50" : "bg-white"} `}
            />
            {showCalendar && (
              <div className="absolute inset-y-0 left-0 items-center pl-3 pointer-events-none top-0 flex">
                <Icon icon="carbon:calendar" className="text-xl text-[#80898E]" />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}
