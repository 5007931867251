import { Breadcrumb, Button, CardLoader, Filter, ManageItem, ModuleStepper, Search } from "components/core"
import useConfiguration from "./useConfiguration"
import { Icon } from "@iconify/react"
import { ConfigurationCard, ConfigurationManagement, ConfigurationDetails } from "components/pages/Configuration"
import { RenderIf, Restricted } from "components/hoc"
import { AllPermissions, PermissionModule } from "types/permission"

export default function Configuration() {
  const { CONFIGURATION } = PermissionModule
  const { ADD_NEW_CONFIG } = AllPermissions
  const {
    breadCrumbs,
    handleSearch,
    filterOptions,
    selectedStatus,
    setSelectedStatus,
    setSelectedAction,
    tabs,
    currentTab,
    configurations,
    cardActions,
    buttonName,
    selectedAction,
    selectedConfiguration,
    setSelectedConfiguration,
    manageConfiguration,
    configurationType,
    isFetchingConfigurations,
    isDeletingConfiguration,
    isChangingStatus,
    updateConfigStatus,
    resetFilters,
    searchValue,
  } = useConfiguration()

  return (
    <main data-testid="configuration">
      <div className="mb-[23px]">
        <Breadcrumb options={breadCrumbs} />
        <h1 className="text-headers text-2xl font-bold">Configuration Settings</h1>
      </div>
      <div>
        <ModuleStepper callback={resetFilters} tabs={tabs} currentTab={currentTab} />
        <div className="flex items-center justify-between mb-[16px]">
          <div className="flex">
            <Search
              id="search"
              name="search"
              placeholder="Search"
              onChange={handleSearch}
              className="mr-[8px]"
              value={searchValue}
            />
            <Filter
              title="Status"
              options={filterOptions}
              selectedOption={selectedStatus}
              setSelectedOption={setSelectedStatus}
            />
          </div>
          <Restricted module={CONFIGURATION} permission={ADD_NEW_CONFIG}>
            <Button type="button" size="40" theme="primary" onClick={() => setSelectedAction("add")}>
              <Icon icon="ph:plus" className="w-6 h-6 mr-[8px]" />
              Add {buttonName}
            </Button>
          </Restricted>
        </div>
        <div className="grid gap-4 grid-cols-3 2xl:grid-cols-4">
          <RenderIf condition={isFetchingConfigurations}>
            <>
              {[1, 2, 3].map((item) => (
                <CardLoader key={item} />
              ))}
            </>
          </RenderIf>
          <RenderIf condition={!isFetchingConfigurations}>
            {configurations?.configurationSettings?.map((item) => (
              <ConfigurationCard
                key={item.id}
                configuration={item}
                actions={cardActions.filter((action) =>
                  ["connected", "active"].includes(item?.status?.toLowerCase())
                    ? action.name !== "Connect"
                    : action.name !== "Disconnect",
                )}
              />
            ))}
          </RenderIf>
        </div>
        <RenderIf
          condition={
            !isFetchingConfigurations && (configurations?.configurationSettings?.length! < 1 || !configurations)
          }
        >
          <div className="flex flex-col gap-2 py-36 w-full max-w-96 items-center mx-auto">
            <Icon icon="ph:grid-nine-fill" className="text-neutral-10 w-32 h-32" />
            <p className="text-base text-center text-neutral-base">
              Uh oh! It seems the configurations desk is a little lonely. Time to bring some life into this corner of
              the universe!
            </p>
          </div>
        </RenderIf>
      </div>
      <RenderIf condition={selectedAction === "view"}>
        <ConfigurationDetails
          onClose={() => setSelectedAction("")}
          setSelectedAction={setSelectedAction}
          configuration={selectedConfiguration}
          loading={isChangingStatus}
          onSubmit={updateConfigStatus}
        />
      </RenderIf>
      <RenderIf condition={selectedAction === "delete"}>
        <ManageItem
          title="Do you want to delete configuration?"
          actionName="Delete"
          cancelBtnText="Cancel"
          caption="Confirming this action will delete this configuration. Be sure you want to perform this action."
          loading={isDeletingConfiguration}
          onClose={() => setSelectedAction("")}
          onSubmit={manageConfiguration}
        />
      </RenderIf>
      <RenderIf condition={["add", "edit"].includes(selectedAction)}>
        <ConfigurationManagement
          onClose={() => {
            setSelectedAction("")
            setSelectedConfiguration({})
          }}
          type={configurationType}
          setSelectedAction={setSelectedAction}
          title={selectedAction}
          configuration={selectedConfiguration}
          setSelectedConfiguration={setSelectedConfiguration}
        />
      </RenderIf>
    </main>
  )
}
