import { useMemo, useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { useLocation } from "react-router-dom"
import { DateType } from "types/data"
import { useTable } from "hooks"
import { useGetInterventionsQuery, useGetInterventionQuery } from "services/api/interventionSlice"
import {
  useCreatePaymentScheduleMutation,
  useGetPaymentScheduleQuery,
  setScheduleData,
  useGetPaymentCycleQuery,
} from "services/api/paymentSlice"
import { toast } from "react-toastify"
import { tryCatch } from "utils/tryCatch"
// import { stringifyArrayII } from "utils/stringifyArray"
import { exportData } from "utils/exportData"
import { fullDateFormatter, shortDate } from "utils/dateFormatter"

interface FormTypes {
  intervention_id: any
  state: any
  type: string
  payment_cycle: number
  payment_recurrence?: string
}

interface Option {
  value: string
  label: string
}

export default function usePaymentUnit() {
  const dispatch = useDispatch()
  // const [selectedStatus, setSelectedStatus] = useState<any>([])
  const [selectedAction, setSelectedAction] = useState("")
  const [date, setDate] = useState<DateType>({ startDate: null, endDate: null })
  const [paymentStates, setPaymentStates] = useState<Option[]>([])

  const location = useLocation()
  const pathname = location.pathname
  const unit = pathname.split("/").pop()

  const [generateScheduleForm, setGenerateScheduleForm] = useState<FormTypes>({
    intervention_id: "",
    state: "",
    type: "",
    payment_cycle: 0,
    payment_recurrence: "",
  })

  const { handleSearch, changePage, params, selectLimit } = useTable()

  const breadCrumbs = [{ title: "Payments" }, { title: "Payment Unit" }]

  const paymentPSP = useMemo(() => {
    return [
      { label: "Remita", value: "REMITA" },
      // { label: "eTRANZACT", value: "ETRANZACT" },
      // { label: "NIBBS", value: "NIBBS" },
    ]
  }, [])

  const paymentCycle = useMemo(() => {
    return [
      { label: "1", value: "1" },
      { label: "2", value: "2" },
      { label: "3", value: "3" },
    ]
  }, [])

  const { data: interventions, isFetching: isFetchingInterventions } = useGetInterventionsQuery({
    // search: "",
    status: "in-progress",
  })

  const { data: paymentScheduleData, isFetching: isFetchingPaymentScheduleData } = useGetPaymentScheduleQuery({
    search: params?.search ?? "",
    // status: selectedStatus.length ? stringifyArrayII(selectedStatus) : "",
    limit: params?.limit,
    page: params?.page,
    from: shortDate(date?.startDate) ?? shortDate(date?.endDate) ?? "",
    to: shortDate(date?.endDate) ?? shortDate(date?.startDate) ?? "",
  })

  const interventionsOptions = useMemo(() => {
    return (
      interventions?.interventions?.map((intervention: any) => {
        return {
          label: intervention?.intervention_title,
          value: intervention?.intervention_id,
        }
      }) || []
    )
  }, [interventions])

  useEffect(() => {
    setPaymentStates([])
    const filteredValue = interventions?.interventions?.filter(
      (intervention: any) => intervention.intervention_id === generateScheduleForm.intervention_id?.value,
    )

    if (filteredValue !== undefined) {
      if (filteredValue.length !== 0) {
        setGenerateScheduleForm((previousData) => ({
          ...previousData,
          payment_recurrence: filteredValue[0]?.payment_recurrence ?? "",
          state: "",
          payment_cycle: 0,
        }))
      } else {
        setGenerateScheduleForm((previousData) => ({
          ...previousData,
          payment_recurrence: "",
          state: "",
          payment_cycle: 0,
        }))
      }
    }
  }, [generateScheduleForm.intervention_id])

  const { data: intervention, isLoading: isFetchingIntervention } = useGetInterventionQuery(
    generateScheduleForm?.intervention_id?.value,
    {
      skip: generateScheduleForm?.intervention_id?.length === 0,
    },
  )

  const cycleData = {
    intervention_id: generateScheduleForm?.intervention_id?.value,
    state: generateScheduleForm?.state?.value,
  }

  const { data: paymentCycleData, isFetching: isFetchingPaymentCycle } = useGetPaymentCycleQuery(cycleData, {
    skip: generateScheduleForm?.intervention_id?.length === 0 || generateScheduleForm?.state?.length === 0,
  })

  useEffect(() => {
    if (paymentCycleData !== undefined) {
      setGenerateScheduleForm((previousData) => ({
        ...previousData,
        payment_cycle: paymentCycleData?.next_payment_cycle,
      }))
    } else {
      setGenerateScheduleForm((previousData) => ({
        ...previousData,
        payment_cycle: 0,
      }))
    }
  }, [paymentCycleData, isFetchingPaymentCycle])

  useEffect(() => {
    setGenerateScheduleForm((prevState) => ({
      ...prevState,
      state: "",
    }))
    setPaymentStates([])

    const newPaymentState =
      intervention !== undefined
        ? intervention?.states?.map((state: { id: string; name: string }) => ({
            value: state.id,
            label: state.name,
          }))
        : []

    setGenerateScheduleForm((previousData) => ({
      ...previousData,
      payment_recurrence: intervention !== undefined ? intervention?.payment_recurrence : "",
    }))
    setPaymentStates(newPaymentState)
  }, [isFetchingIntervention, intervention])

  const [createPayment, { isLoading: isCreatingPaymentSchedule }] = useCreatePaymentScheduleMutation()

  const createPaymentSchedule = async (onClose: any) => {
    const callback = (res: any) => {
      if ([200, 201].includes(res?.code)) {
        toast.success(res.message)
        onClose()
      }
    }

    const data = {
      ...generateScheduleForm,
      intervention_id: generateScheduleForm.intervention_id?.value,
      state: generateScheduleForm?.state?.value,
    }

    tryCatch(async () => {
      let res = await createPayment(data).unwrap()
      dispatch(setScheduleData(res.data))
      callback(res)
    })
  }

  const exportPaymentSchedule = () => {
    tryCatch(() => {
      const data = paymentScheduleData?.schedules?.map((item: any) => {
        const date = fullDateFormatter(item.created_at)
        return {
          Intervention: `${item?.intervention_title}`,
          "No. of Beneficiaries": item?.scheduled_beneficiaries_count,
          "Schedule ID": item?.schedule_reference ?? item.schedule_id,
          "Payment Cycle": item?.payment_cycle,
          Amount: item?.amount ? Number(item?.amount) * Number(item?.scheduled_beneficiaries_count) : 0,
          "Date Created": date?.replace(/,/g, ""),
          Status: item?.status,
          "Review Status": item?.review_status ?? "--",
        }
      })
      exportData(data, `Payment Schedule Data`)
    })
  }

  return {
    breadCrumbs,
    date,
    setDate,
    handleSearch,
    changePage,
    selectLimit,
    params,
    unit,
    interventionsOptions,
    paymentPSP,
    paymentCycle,
    paymentStates,
    // selectedStatus,
    // setSelectedStatus,
    generateScheduleForm,
    setGenerateScheduleForm,
    isFetchingInterventions,
    createPaymentSchedule,
    isCreatingPaymentSchedule,
    paymentScheduleData,
    isFetchingPaymentScheduleData,
    exportPaymentSchedule,
    isFetchingPaymentCycle,
    isFetchingIntervention,
    selectedAction,
    setSelectedAction,
  }
}
