import { RenderIf } from "components/hoc"
interface TransactionInfoProps {
  data: any[]
  title?: string
  headers: string[]
}

const TransactionInfo = ({ data, title, headers }: TransactionInfoProps) => {
  return (
    <div className="bg-primary-900 min-h-[172px] rounded-lg p-6 flex flex-col justify-between">
      <RenderIf condition={!!title}>
        <div className="flex w-full">
          <h1 className="text-2xl text-primary-30 font-semibold">{title}</h1>
        </div>
      </RenderIf>

      <div className="h-[0.5px] bg-grey-200 my-6" />

      <div className={`grid grid-cols-${data.length} divide-x-[0.5px] divide-[#E6E6E6] child-not-first:pl-4`}>
        {headers?.map((header, index) => (
          <div className="flex  flex-col gap-[2px]" key={header}>
            <span className="text-[#C6E4C4] text-xs font-medium">{header}</span>
            <p className="text-primary-30 font-normal text-sm mt-[2px] truncate">{data[index]}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default TransactionInfo
