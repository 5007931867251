export const beneficiariesHeaders = [
  {
    name: "full name",
    sort: true,
    action: null,
  },
  {
    name: "state",
    sort: true,
    action: null,
  },
  {
    name: "No of interventions",
    sort: false,
    action: null,
  },
  {
    name: "NIN",
    sort: false,
    action: null,
  },
  {
    name: "BVN",
    sort: false,
    action: null,
  },
  {
    name: "account number",
    sort: false,
    action: null,
  },
  {
    name: "household id",
    sort: false,
    action: null,
  },
  {
    name: "source register",
    sort: false,
    action: null,
  },
  {
    name: "",
    sort: false,
    action: null,
  },
]

export const unbankedBeneficiariesHeaders = [
  {
    name: "full name",
    sort: false,
    action: null,
  },
  {
    name: "state",
    sort: false,
    action: null,
  },
  {
    name: "NIN",
    sort: false,
    action: null,
  },
  {
    name: "BVN",
    sort: false,
    action: null,
  },
  {
    name: "account number",
    sort: false,
    action: null,
  },
  {
    name: "household id",
    sort: false,
    action: null,
  },
  {
    name: "Error Remark",
    sort: false,
    action: null,
  },
  {
    name: "source register",
    sort: false,
    action: null,
  },
  {
    name: "",
    sort: false,
    action: null,
  },
]

export const beneficiariesHistoryHeaders = [
  {
    name: "file title",
    sort: true,
    action: null,
  },
  {
    name: "file id",
    sort: false,
    action: null,
  },
  {
    name: "date added",
    sort: false,
    action: null,
  },
  {
    name: "found beneficiary",
    sort: false,
    action: null,
  },
  {
    name: "updated",
    sort: false,
    action: null,
  },
  {
    name: "not updated",
    sort: false,
    action: null,
  },
  {
    name: "",
    sort: false,
    action: null,
  },
]

export const beneficiaryDetailsCardHeaders = [
  "Gender",
  "Date of Birth",
  "Age",
  "State",
  "LGA",
  "Ward",
  "Household ID",
  "NIN",
  "BVN",
  "Bank Name",
  "Account Name",
  "Account Number",
]

export const bankedBeneficiaryPaymentHistoryTableHeader = [
  {
    name: "Transaction ID",
    sort: true,
    action: null,
  },
  {
    name: "amount",
    sort: false,
    action: null,
  },
  {
    name: "date added",
    sort: false,
    action: null,
  },
  {
    name: "destination account",
    sort: false,
    action: null,
  },
  {
    name: "status",
    sort: false,
    action: null,
  },
]
export const bankedBeneficiaryInterventionTableHeader = [
  {
    name: "intervention title",
    sort: true,
    action: null,
  },
  {
    name: "amount",
    sort: false,
    action: null,
  },
  {
    name: "date added",
    sort: false,
    action: null,
  },
  {
    name: "status",
    sort: false,
    action: null,
  },
  {
    name: "payment cycle",
    sort: false,
    action: null,
  },
]

export const beneficiaryStatus = [
  {
    label: "Verified",
    value: "verified"
  },
  {
    label: "Unverified",
    value: "unverified"
  },
]