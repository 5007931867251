/* eslint-disable no-unused-vars */
export interface Zone {
  id: number
  name: string
}

export interface DateRangeType {
  startDate: Date | null
  endDate: Date | null
}

export enum Period {
  MONTHLY = "monthly",
  YEARLY = "yearly",
  CUSTOM = "custom",
}
