import { useTable } from "hooks"
import useReportSummary from "hooks/useReportSummary/useReportSummary"
import { useMemo } from "react"
import { useGetAccountSummaryQuery, useGetReportsQuery } from "services/api/reportSlice"
import { Period } from "types/common"
import { addCommasToNumbers } from "utils/addCommasToNumbers"
import { formatCurrency } from "utils/formatCurrency"
import { calculatePercentage, getReportCustomDateRange, getReportYear } from "utils/report"

export default function useAccountSummary() {
  const {
    actions,
    deleteReport,
    isDeletingReport,
    selectedReportId,
    setSelectedReportId,
    date,
    setDate,
    periodFilter,
    setPeriodFilter,
  } = useReportSummary()
  const { params, changePage, selectLimit } = useTable()

  const {
    data: reports,
    isLoading: isLoadingHistory,
    isFetching: isFetchingHistory,
  } = useGetReportsQuery({ type: "account" })
  const {
    data: summary,
    isLoading: isLoadingSummary,
    isFetching: isFetchingSummary,
  } = useGetAccountSummaryQuery({
    month: periodFilter.type === Period.MONTHLY ? periodFilter?.selected : "",
    year: getReportYear(periodFilter),
    ...getReportCustomDateRange(date, periodFilter),
  })

  const overview = [
    {
      title: "Total Payment Schedule Count",
      count: addCommasToNumbers(summary?.data?.total_payment_schedule_count ?? 0),
      icon: "ph:users-three",
      stat: summary?.data?.total_payment_schedule_count_percentage_increase,
    },
    {
      title: "Total Payment Schedule value",
      count: formatCurrency(summary?.data?.total_payment_schedule_amount ?? 0),
      icon: "ph:handshake",
      stat: summary?.data?.total_payment_schedule_amount_percentage_increase,
    },
    {
      title: "Total Paid Schedule",
      count: addCommasToNumbers(summary?.data?.total_paid_payment_schedule_amount ?? 0),
      icon: "ph:handshake",
      stat: summary?.data?.total_paid_payment_schedule_amount_percentage_increase,
    },
  ]

  const overviewB = [
    {
      title: "Total approved by Account",
      count: formatCurrency(summary?.data?.total_approved_by_account ?? 0),
      icon: "ph:handshake",
      stat: summary?.data?.total_approved_by_account_percentage_increase ?? 0,
    },
    {
      title: "Total declined by account",
      count: formatCurrency(summary?.data?.total_declined_by_account ?? 0),
      icon: "ph:handshake",
      stat: summary?.data?.total_declined_by_account_percentage_increase ?? 0,
    },
  ]

  const chartData = useMemo(() => {
    const dataValues = [
      summary?.data?.unpaid_status_amount,
      summary?.data?.in_progress_status_amount,
      summary?.data?.paid_status_amount,
    ]

    const percentage = calculatePercentage(dataValues)

    const filteredData = percentage.reduce<{ labels: string[]; data: number[]; backgroundColor: string[] }>(
      (acc, value, index) => {
        if (Number(dataValues[index]) !== 0) {
          acc.labels.push(["Unpaid", "In progress", "Paid"][index])
          acc.data.push(value)
          acc.backgroundColor.push(["#024F0B", "#C6E4C4", "#2B892B"][index])
        }
        return acc
      },
      { labels: [], data: [], backgroundColor: [] },
    )

    return {
      labels: filteredData.labels,
      datasets: [
        {
          data: filteredData.data,
          backgroundColor: filteredData.backgroundColor,
          borderWidth: 6,
        },
      ],
    }
  }, [summary?.data])

  return {
    overview,
    overviewB,
    actions,
    chartData,
    changePage,
    selectLimit,
    params,
    reports: reports?.data,
    summary: summary?.data,
    date,
    setDate,
    periodFilter,
    setPeriodFilter,
    isFetchingHistory,
    deleteReport,
    isDeletingReport,
    selectedReportId,
    setSelectedReportId,
    isLoadingSummary,
    isLoadingHistory,
    isFetchingSummary,
  }
}
