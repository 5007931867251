import { Fragment, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Icon } from "@iconify/react"
import { motion } from "framer-motion"
import { Search, Filter, Button, Calendar, Table, ManageItem } from "components/core"
import useTrainingTable from "./useTrainingTable"
import AddTraining from "../AddTraining/AddTraining"
import { MainTrainingsHeading } from "constants/trainings"
import { skillTypes, TrainingType, CitizenTrainingUnit, StaffTrainingUnit } from "mocks/training"
import TrainingStatus from "./TrainingStatus/TrainingStatus"
import EditTraining from "../EditTraining/EditTraining"
import { fullDateFormatter } from "utils/dateFormatter"

const TrainingTable = () => {
  const navigate = useNavigate()

  const {
    params,
    selectLimit,
    changePage,
    startDate,
    endDate,
    handleDate,
    handleSearch,
    selectedParticipantType,
    setSelectedParticipantType,
    setSelectedAction,
    selectedAction,
    selectedTrainingUnit,
    setSelectedTrainingUnit,
    trainingsData,
    selectedTraining,
    setSelectedTraining,
    handleDeleteTraining,
    isDeletingTraining,
    exportTrainingInformation,
    isFetchingTrainingData,
    uploadMessage,
    setUploadMessage,
    selectedSkillType,
    setSelectedSkillType,
  } = useTrainingTable()

  const MotionTr = motion.tr

  const handleNavigateToTraining = (id: string) => {
    navigate(`/training/${id}`)
  }

  const trainingUnitSelected =
    selectedParticipantType.length !== 0
      ? selectedParticipantType.value === "staff"
        ? StaffTrainingUnit
        : CitizenTrainingUnit
      : []

  useEffect(() => {
    setSelectedTrainingUnit([])
  }, [selectedParticipantType])

  const handleEditTraining = (training: any) => {
    setSelectedAction("edit-training")
    setSelectedTraining(training)
  }

  return (
    <>
      <div className="mt-6 flex flex-wrap items-center justify-between gap-4 mb-4" data-testid="training-tables">
        <div className="flex space-x-3">
          <Search placeholder="Search" id="search" onChange={handleSearch} name="search" />
          <Filter
            title="Participant Type:"
            options={TrainingType}
            loading={false}
            selectedOption={selectedParticipantType}
            setSelectedOption={setSelectedParticipantType}
          />
          <Filter
            title="Training Unit:"
            options={trainingUnitSelected}
            loading={false}
            selectedOption={selectedTrainingUnit}
            setSelectedOption={setSelectedTrainingUnit}
          />
          <Filter
            title="Training Type:"
            options={skillTypes}
            loading={false}
            selectedOption={selectedSkillType}
            setSelectedOption={setSelectedSkillType}
          />
          <Calendar selectsRange={true} startDate={startDate} endDate={endDate} onChange={handleDate} />
        </div>

        <div className="flex space-x-2">
          <Button type="button" size="40" theme="transparent" onClick={exportTrainingInformation}>
            <Icon icon="uiw:download" className="text-[#747F9C] w-4 h-4 mr-3" />
            Export
          </Button>

          <Button
            type="button"
            size="40"
            theme="primary"
            onClick={() => setSelectedAction("create-training")}
            data-testid="add-training-btn"
          >
            <Icon icon="ph:plus" className="w-6 h-6 mr-[8px]" />
            Add Training
          </Button>
        </div>
      </div>
      <div>
        <Table
          headers={MainTrainingsHeading}
          data={trainingsData?.trainings ?? []}
          onPageChange={changePage}
          selectLimit={selectLimit}
          loading={isFetchingTrainingData}
          totalCount={trainingsData?.total}
          perPage={Number(params?.limit)}
          page={Number(params?.page)}
        >
          <>
            {trainingsData?.trainings
              ? trainingsData?.trainings?.map((training: any) => (
                  <Fragment key={training.training_id}>
                    <MotionTr
                      initial={{ opacity: 0, y: -20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3 }}
                      className="cc-custom-table-data-row"
                    >
                      <td onClick={() => handleNavigateToTraining(training.training_id)}>
                        <div className="flex flex-col text-sm">
                          <h1 className="text-body">{training?.name}</h1>
                          <h1>
                            <span className="font-semibold">{training?.training_participants_count ?? 0}</span>{" "}
                            Participants
                          </h1>
                        </div>
                      </td>
                      <td
                        onClick={() => handleNavigateToTraining(training.training_id)}
                        className="text-body capitalize"
                      >
                        {training?.training_reference}
                      </td>
                      <td className="capitalize" onClick={() => handleNavigateToTraining(training.training_id)}>
                        {training?.skill_type}
                      </td>
                      <td
                        onClick={() => handleNavigateToTraining(training.training_id)}
                        className="text-body capitalize"
                      >
                        {training?.training_type}
                      </td>
                      <td
                        onClick={() => handleNavigateToTraining(training.training_id)}
                        className="text-body uppercase"
                      >
                        {training?.training_unit}
                      </td>
                      <td onClick={() => handleNavigateToTraining(training.training_id)}>
                        <div>
                          <TrainingStatus status={training?.status} />
                        </div>
                      </td>
                      <td onClick={() => handleNavigateToTraining(training.training_id)}>
                        {training?.attendance_required} Sessions
                      </td>
                      <td onClick={() => handleNavigateToTraining(training.training_id)}>
                        {training?.created_at ? fullDateFormatter(training?.created_at) : "--"}
                      </td>
                      <td
                        onClick={() => handleNavigateToTraining(training.training_id)}
                      >{`${training?.duration} Day(s)`}</td>

                      <td>
                        <div>
                          <div className="flex gap-2">
                            <button
                              className={`text-body align-middle  py-[8px] px-[12px] rounded-lg border border-grey-200 ${
                                ["in-progress", "completed"].includes(training.status)
                                  ? "bg-grey-50 opacity-65 cursor-not-allowed"
                                  : "bg-white cursor-pointer"
                              }`}
                              disabled={["in-progress", "completed"].includes(training.status)}
                              onClick={() => handleEditTraining(training)}
                            >
                              <Icon icon="ph:pencil-simple" className="w-4 h-4" />
                            </button>

                            <button
                              className={`text-body align-middle  py-[8px] px-[12px] rounded-lg border border-grey-200 ${
                                ["in-progress", "completed"].includes(training.status)
                                  ? "bg-grey-50 opacity-65 cursor-not-allowed"
                                  : "bg-white cursor-pointer"
                              }`}
                              disabled={["in-progress", "completed"].includes(training.status)}
                              onClick={() => {
                                setSelectedAction("delete-course")
                                setSelectedTraining(training)
                              }}
                            >
                              <Icon icon="ph:trash" className="w-4 h-4" />
                            </button>
                          </div>
                        </div>
                      </td>
                    </MotionTr>
                  </Fragment>
                ))
              : null}
          </>
        </Table>
      </div>

      {selectedAction === "create-training" && (
        <AddTraining setSelectedAction={setSelectedAction} setUploadMessage={setUploadMessage} />
      )}

      {selectedAction === "delete-course" && (
        <ManageItem
          onClose={() => setSelectedAction("")}
          title="Do you want to delete this Training"
          actionName="Delete"
          cancelBtnText="Discard"
          loading={isDeletingTraining}
          onSubmit={() => handleDeleteTraining(selectedTraining?.training_id ?? "", () => setSelectedAction(""))}
          icon={
            <div className="bg-error-lightest w-12 h-12 flex items-center justify-center rounded-lg">
              <Icon icon="ph:trash" className="w-10 h-10 text-error-main" />
            </div>
          }
          caption={
            "Confirming this action will delete this training and it is irreversible. Be sure you want to perform this action."
          }
        />
      )}
      {selectedAction === "edit-training" && (
        <EditTraining training={selectedTraining} onClose={() => setSelectedAction("")} />
      )}

      {selectedAction === "upload-success" && (
        <ManageItem
          caption={"Training has been created successfully"}
          title="Training Uploaded Successfully"
          actionName={uploadMessage?.unprocessed === 0 ? "Close" : "Download Inconsistent Data"}
          showCloseIcon={true}
          icon={
            <div className="bg-primary-50 w-12 h-12 flex items-center justify-center rounded-lg">
              <Icon icon="ph:circle-half" className="w-10 h-10 text-primary-actions" />
            </div>
          }
          onClose={() => setSelectedAction("")}
          showCancelBtn={false}
          showCancelText={false}
          onSubmit={uploadMessage?.unprocessed === 0 ? () => setSelectedAction("") : () => setSelectedAction("")}
        >
          <div className="space-y-4">
            <div className="text-base">
              <p className="text-body">{`No. of Training Uploaded - ${uploadMessage?.processed ?? 0}`}</p>
              <p className="text-error-main">{`No. of Training Failed - ${uploadMessage?.unprocessed ?? 0}`}</p>
            </div>
          </div>
        </ManageItem>
      )}
    </>
  )
}

export default TrainingTable
