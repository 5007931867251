import { Icon } from "@iconify/react"
import { Calendar, Dropdown } from "components/core"
import { beneficiaryStatus } from "constants/beneficiary"
import { defaultFilter } from "constants/global"
import { useMemo } from "react"
import { useGetConfigurationsQuery } from "services/api/configurationSlice"
import { CONFIG_TYPE } from "types/configuration"

interface Props {
  state: any
}

export const BeneficiaryDetails = ({ state }: Props) => {
  const { date, setDate, formData, setFormData } = state

  const { data: configurations, isFetching: isFetchingConfigurations } = useGetConfigurationsQuery({
    configurationType: CONFIG_TYPE.source_register,
  })

  const sourceRegisterOptions = useMemo(() => {
    return configurations?.configurationSettings?.length
      ? configurations?.configurationSettings?.map((config) => {
          return { label: config?.configuration_name, value: config?.configuration_name }
        })
      : []
  }, [configurations?.configurationSettings])

  return (
    <div>
      <p className="mb-[8px] font-medium text-sm text-headers">
        <Icon icon="ph:pencil-line" className="inline w-[20px] h-[20px]" /> BENEFICIARY DETAILS
      </p>
      <div className="pl-[24px] w-full mb-[20px] rounded-sm">
        <table className="w-full">
          <thead>
            <tr className="text-left border text-xs text-body">
              <th className="p-[8px] font-medium border-r text-start w-[270px]">Status</th>
              <th className="p-[8px] font-medium border-r text-start w-[270px]">Source Register</th>
              <th className="p-[8px] font-medium text-start w-[270px]">Created Date</th>
            </tr>
          </thead>
          <tbody>
            <tr className="border h-12 text-sm text-body">
              <td className="border-r col-span-5">
                <Dropdown
                  //selected={formData.status?.label}
                  containerVariant="!border-none !shadow-none"
                  wrapperClassName="!mb-0"
                  options={beneficiaryStatus}
                  onSelect={(data: any) => setFormData({ ...formData, status: data })}
                />
              </td>
              <td className="border-r col-span-5">
                <Dropdown
                  selected={formData.sourceRegister}
                  containerVariant="!border-none !shadow-none"
                  wrapperClassName="!mb-0"
                  options={[...defaultFilter, ...sourceRegisterOptions]}
                  loading={isFetchingConfigurations}
                  enableSearchOptions={true}
                  multiple={true}
                  count={true}
                  onSelect={(data: any) => setFormData({ ...formData, sourceRegister: data })}
                />
              </td>
              <td className="px-3 border-r col-span-5">
                <Calendar
                  startDate={date.createdDate.startDate}
                  endDate={date.createdDate.endDate}
                  onChange={(e) => setDate({ ...date, createdDate: e })}
                  selectsRange={true}
                  variant="!border-0 !px-0 !justify-start"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}
