import PaymentUnit from "pages/payments/PaymentUnit/PaymentUnit"
import PaymentsAccounts from "pages/payments/Account/Account"
import PaymentAudits from "pages/payments/Audit/Audit"
import ViewPaymentSchedule from "pages/payments/ViewPaymentUnit/ViewPaymentUnit"
import PaymentsProgramManager from "pages/payments/ProgramManager/ProgramManager"
import PaymentsTransactions from "pages/payments/Transactions/Transactions"
import ViewSingleTransaction from "pages/payments/ViewTransactions/ViewTransactions"
import ViewTransactionBatch from "pages/payments/ViewTransactionsBatches/ViewTransactionBatches"

export const paymentsRoutes = [
    {
        path: "payments/payment-unit",
        element: <PaymentUnit />,
    },
    {
        path: "payments/account",
        element: <PaymentsAccounts />,
    },
    {
        path: "payments/audit",
        element: <PaymentAudits />,
    },
    {
        path: "payments/program-manager",
        element: <PaymentsProgramManager />,
    },
    {
        path: "payments/:id/:unit",
        element: <ViewPaymentSchedule />,
    },
    {
        path: "payments/transactions",
        element: <PaymentsTransactions />,
    },
    {
        path: "payments/transactions/:id",
        element: <ViewSingleTransaction />,
    },
    {
        path: "payments/transactions/:id/batch/:batchId",
        element: <ViewTransactionBatch />,
    },
]