import { useApprovePaymentScheduleMutation } from "services/api/paymentSlice"
import { toast } from "react-toastify"
import { tryCatch } from "utils/tryCatch"

export default function useApprovalModal({ closeModal }: { closeModal: () => void; unit: string }) {
  const [approvePaymentSchedule, { isLoading: isApprovingPaymentSchedule }] = useApprovePaymentScheduleMutation({})

  const approvePayment = async (data: any) => {
    const callback = (res: any) => {
      if ([200, 201].includes(Number(res?.code))) {
        closeModal()
        toast.success(res.message)
      } else {
        toast.error(res.message)
      }
      closeModal()
    }

    tryCatch(async () => {
      let res = await approvePaymentSchedule(data).unwrap()
      callback(res)
    })
  }

  return { approvePayment, isApprovingPaymentSchedule }
}
