import { useState } from "react"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { useAddGroupMemberMutation } from "services/api/livelihoodSlice"
import { csvToJson } from "utils/fileFormatter"

interface Props {
  onClose: () => void
}

export default function useMultipleMember({ onClose }: Props) {
  const { id: groupId } = useParams()
  const [file, setFile] = useState<any>(null)

  const [addMember, { isLoading: isAddingMember }] = useAddGroupMemberMutation()

  const handleImageDrop = (file: any) => {
    setFile(file)
  }

  const submit = async (e: any) => {
    e.preventDefault()
    const payload = await csvToJson(file)
    const res = await addMember({ members: payload, groupId }).unwrap()
    if ([200, 201].includes(res?.code)) {
      toast.success(res?.message)
      onClose()
    }
  }

  return {
    submit,
    loading: isAddingMember,
    file,
    setFile,
    handleImageDrop,
  }
}
