export const statuses = [
    {
        label: "All",
        value: ''
    },
    {
        label: "Active",
        value: 'active'
    },
    {
        label: "Deactivated",
        value: "deactivated"
    },
    {
        label: "Inactive",
        value: "inactive"
    }
]