import React from "react"
import { ReactComponent as Axe } from "assets/icons/axe.svg"
import { Dropdown } from "components/core/Dropdown/Dropdown"
import { operators } from "constants/intervention"
import { Button, Input } from "components/core"
import { Icon } from "@iconify/react"
import useSelectionCriteria from "./useSelectionCriteria"

interface props {
  formData: any
  // eslint-disable-next-line no-unused-vars
  setFormData: (e: any) => void
  proceed: () => void
  back: () => void
  conditions: any
  loading: boolean
}

const SelectionCriteria = ({ formData, setFormData, proceed, back, conditions, loading }: props) => {
  const { selectionCriteria, addTableRows, deleteTableRows, handleSelectionCriteria, disableBtn, nextPage } =
    useSelectionCriteria({ formData, setFormData, proceed })

  return (
    <>
      <div className="relative pl-4 pb-4 border-l-[1px] border-dashed border-body ml-2 mt-6 w-full px-4">
        <div className="absolute top-0 left-0 translate-x-[-50%] translate-y-[0%]">
          <Axe />
        </div>
        <p className="text-xs font-medium text-headers pb-2">
          Criteria 1 <span className="text-error-main">*</span>
        </p>
        <table className="w-full">
          <thead>
            <tr className="text-left h-8 border text-xs text-headers grid grid-cols-12 ">
              <th className="pl-3 font-medium border-r col-span-5 py-2">Condition</th>
              <th className="pl-3 font-medium border-r col-span-4 py-2">Operator</th>
              <th className="pl-3 font-medium col-span-2 py-2 border-r">Value</th>
            </tr>
          </thead>
          <tbody>
            {selectionCriteria.length >= 0 &&
              selectionCriteria.map((item: any, index: number) => {
                const { condition, operator, value } = item
                return (
                  <tr className="border h-12 text-sm text-body grid grid-cols-12" key={index}>
                    <td className="border-r col-span-5">
                      <Dropdown
                        selected={condition ?? item.conditions}
                        containerVariant="!border-none h-10 !shadow-none"
                        options={conditions}
                        search={false}
                        loading={loading}
                        onSelect={(data: any) => handleSelectionCriteria("condition", data.value, index)}
                      />
                    </td>
                    <td className="border-r col-span-4">
                      <Dropdown
                        containerVariant="!border-none h-10 !shadow-none"
                        selected={operator}
                        search={false}
                        options={operators}
                        onSelect={(data: any) => handleSelectionCriteria("operator", data.value, index)}
                      />
                    </td>
                    <td className="px-3 col-span-2 border-r">
                      <Input
                        type="number"
                        id="value"
                        name="value"
                        value={value}
                        onChange={(e: any) => handleSelectionCriteria("value", e.target.value, index)}
                        className="w-full"
                        variant="border-none !p-0 !h-[40px]"
                      />
                    </td>
                    <td className="p-3 col-span-1 flex justify-center items-center">
                      <div data-testid="delete-row" onClick={() => deleteTableRows(index)}>
                        <Icon icon="material-symbols:delete" className="text-2xl cursor-pointer text-grey-300" />
                      </div>
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
        <button
          className="
          absolute bottom-0 left-0 translate-x-[-10%] translate-y-[150%] font-inter
          text-sm text-headers text-medium cursor-pointer flex items-center"
          onClick={addTableRows}
        >
          &nbsp;
          <span className="bg-primary-actions h-4 w-4 text-sm flex justify-center items-center pb-0.5 mr-3 text-white rounded-full cursor-pointer">
            +
          </span>
          Add new criteria
        </button>
      </div>

      <div className="flex justify-start items-center mt-20 space-x-4">
        <Button theme="primary" size="44" className="min-w-[171px]" onClick={nextPage} disabled={!disableBtn}>
          Save & Proceed
        </Button>
        <Button theme="transparent" className="w-[171px] px-[30px]" type="button" onClick={back} size="44">
          Back
        </Button>
      </div>
    </>
  )
}

export default SelectionCriteria
