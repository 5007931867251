import React, { ReactNode } from "react"
import { useOutsideTouch } from "hooks"
import { Icon } from "@iconify/react"
import { RenderIf } from "components/hoc"
import { usePermission } from "hooks/usePermission/usePermission"
import { useSelector } from "react-redux"

interface ActionsList {
  name: string
  // eslint-disable-next-line no-unused-vars
  method?: (data: any) => void
  icon?: ReactNode
  permission?: string
}

interface ActionsProps {
  actions: ActionsList[]
  id: number | string
  item?: any
  trigger?: any
  buttonClassName?: string
  module?: string
  iconType?: "dot" | "caret"
}

export const Actions: React.FC<ActionsProps> = ({
  actions,
  id,
  item,
  trigger,
  buttonClassName,
  module,
  iconType = "dot",
}) => {
  const { hasPermission } = usePermission()
  const { ref, display, setDisplay } = useOutsideTouch()
  const staffId = useSelector((state: any) => state.auth)?.staff_id

  const styles = (action: string) => {
    const enhancedAction = action.toLowerCase().split(" ")
    if (
      (enhancedAction.includes("deactivate") && (item.status === "deactivated" || item.status === "inactive")) ||
      (enhancedAction.includes("activate") && (item.status === "active" || item.status === "inactive")) ||
      (enhancedAction.includes("deactivate") && staffId === item.staff_id)
    ) {
      return "hidden"
    }
    return "block"
  }

  const showDropdown = (e: any) => {
    e.stopPropagation()
    setDisplay(!display)
  }

  return (
    <div className="relative">
      {trigger ? (
        <button onClick={showDropdown} data-testid={`action-${id}`} className="cursor-pointer">
          {trigger}
        </button>
      ) : (
        <button
          className={`text-body border rounded p-1 border-grey_200 cursor-pointer ${buttonClassName}`}
          onClick={showDropdown}
          data-testid={`action-${id}`}
        >
          <Icon
            icon={iconType === "dot" ? "pepicons-pencil:dots-y" : "ph:caret-down"}
            className="text-paragraph w-5 h-6"
          />
        </button>
      )}
      {display && (
        <div
          ref={ref}
          className="
                    text-sm text-body font-normal absolute z-10 right-[0px] top-[20px]
                    py-[8px] px-[8px] overflow-auto bg-white drop-shadow-xl w-max rounded-[8px] font-inter"
        >
          {actions?.map((action) => (
            <RenderIf
              condition={!action.permission ? true : hasPermission(module, action.permission)}
              key={action.name}
            >
              <button
                data-testid={action.name}
                onClick={(e) => {
                  e.stopPropagation()
                  setDisplay(false)
                  if (action.method) action.method(item)
                }}
                className={`${styles(action.name)}
                            w-full py-[8px] px-[10px] font-normal flex gap-x-3 items-center cursor-pointer hide_tap
                            transition ease-in-out duration-500 hover:bg-[#F2F3F3] rounded-[8px]`}
              >
                <div>{action?.icon}</div>
                {action.name}
              </button>
            </RenderIf>
          ))}
        </div>
      )}
    </div>
  )
}
