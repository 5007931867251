import useInputValidate from "hooks/useInputValidate/useInputValidate"
import { ChangeEvent, useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { setCredentials, useLoginMutation } from "services/api/authSlice"
import { cookieDateFormat } from "utils/dateFormatter"
import { tryCatch } from "utils/tryCatch"

export default function useLogin() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { validateFormData } = useInputValidate()
  const [login, { isLoading }] = useLoginMutation()
  const [showVerify, setShowVerify] = useState(false)
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  })

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const onSubmit = () => {
    tryCatch(async () => {
      const res = await login(formData).unwrap()
      if (res?.code === 200) setShowVerify(true)
    })
  }

  const verifyCallback = (data: any) => {
    tryCatch(() => {
      const { token, expires } = data
      const formattedDate = cookieDateFormat(expires)
      document.cookie = `token=${token}; expiry=${formattedDate}; path=/`
      dispatch(setCredentials(data))
      navigate("/intervention")
    })
  }

  const disabledButton = useMemo(() => {
    return !validateFormData(formData)
  }, [formData])

  return {
    formData,
    handleChange,
    showVerify,
    setShowVerify,
    onSubmit,
    isLoading,
    verifyCallback,
    disabledButton,
  }
}
