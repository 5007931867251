import React, { useState } from "react"
import { Icon } from "@iconify/react"
import { Button, Filter, Search, Table, Avatar, ManageItem, Dropdown } from "components/core"
import { useNavigate } from "react-router-dom"
import { nigerianStates } from "constants/intervention"
import useBeneficiary from "./useBeneficiary"
import { RenderIf, Restricted } from "components/hoc"
import { motion } from "framer-motion"
import { beneficiariesHeaders, unbankedBeneficiariesHeaders } from "constants/beneficiary"
import { UpdateBeneficiary } from "components/pages/Beneficiary/UpdateBeneficiary/UpdateBeneficiary"
import getFirstTwoNames from "utils/getFirstTwoNames"
import { AllPermissions, PermissionModule } from "types/permission"

const MotionTr = motion.tr

const Beneficiary = () => {
  const {
    beneficiaryDetails,
    setState,
    state,
    handleSearch,
    tabs,
    currentTab,
    selectedAction,
    setSelectedAction,
    beneficiaries,
    isFetchingBeneficiaries,
    changePage,
    params,
    analytics,
    isFetchingAnalytics,
    selectLimit,
    exportBeneficiaries,
    isExporting,
    remarkType,
    setRemarkType,
    remarkOptions,
    handleDownload,
    interventionsOptions,
    setIntervention,
    isFetchingInterventions,
  } = useBeneficiary()

  const navigate = useNavigate()
  const [openPercentageModal, setOpenPercentageModal] = useState<boolean | undefined>(false)
  const [openCompleteUpdateModal, setOpenCompleteUpdateModal] = useState<boolean>(false)
  const [updateResponse, setUpdateResponse] = useState<any>([])
  const updated_info = updateResponse?.data?.beneficiaryUpdateHistory
  const errorFileUrl = updateResponse?.data?.errorFileUrl
  const { BENEFICIARIES } = PermissionModule
  const { VIEW_ALL_BENEFICIARIES } = AllPermissions
  return (
    <Restricted module={BENEFICIARIES} permission={VIEW_ALL_BENEFICIARIES} showError={true}>
      <main data-testid="beneficiary">
        <h1 className="text-headers text-2xl font-bold">Beneficiary</h1>
        <div className="flex items-center py-[24px] border-y mt-11">
          {beneficiaryDetails.map((details) => (
            <div key={details.title} className="flex items-center gap-4 mr-[40px]">
              <div className="relative w-10 h-10 grid place-items-center bg-grey-100 rounded-full">
                <Icon icon={details.icon} className="w-6 h-6 text-body" />
              </div>
              <div className="grid">
                <span className="text-sm text-body">{details.title}</span>
                <p className="text-base font-medium text-headers">
                  <RenderIf condition={!isFetchingAnalytics && analytics}>{details.count}</RenderIf>

                  {details.title === "Beneficiary Update History" && (
                    <span
                      className="text-primary-actions ml-1 cursor-pointer"
                      onClick={() => navigate("beneficiary-update-history")}
                    >{`View Details>>>`}</span>
                  )}
                </p>
              </div>
            </div>
          ))}
        </div>
        <div className="mt-[23px]">
          <div className="w-full border-b-[1.5px] border-b-grey-200">
            {tabs.map((tab) => (
              <button
                key={tab.title}
                disabled={currentTab === tab.path}
                className={`
                  text-sm font-normal mr-[24px] pt-[8px] pb-[16px] px-[16px] 
                  ${currentTab === tab.path ? "selected-tab" : "unselected-tab"}
                  `}
                onClick={() => navigate(`?category=${tab.path}`)}
              >
                {tab.title}
              </button>
            ))}
          </div>
          <div className="flex items-center justify-between mb-4 mt-6">
            <div className="flex space-x-2">
              <Search id="search" name="search" placeholder="Search by name" onChange={handleSearch} />
              <Filter
                title="State"
                options={nigerianStates}
                loading={false}
                multiple={true}
                selectedOption={state}
                setSelectedOption={setState}
              />
              <RenderIf condition={currentTab === "unbanked-beneficiary"}>
                <Filter
                  title="Remark:"
                  options={remarkOptions ?? []}
                  loading={false}
                  selectedOption={remarkType}
                  setSelectedOption={setRemarkType}
                />
              </RenderIf>
              <div className="h-[40px]">
                <Dropdown
                  placeholder="Intervention"
                  loading={isFetchingInterventions}
                  options={interventionsOptions}
                  selectInput={true}
                  enableSearchOptions={true}
                  onSelect={setIntervention}
                  containerVariant="h-[40px] min-w-[200px]"
                />
              </div>
            </div>
            <div className="flex space-x-2">
              <Button
                type="button"
                size="40"
                theme="transparent"
                disabled={isExporting || beneficiaries?.beneficiaries?.length === 0}
                onClick={exportBeneficiaries}
              >
                <Icon icon="uiw:download" className="text-[#747F9C] w-6 h-6 mr-3" />
                {isExporting ? "Exporting..." : "Export"}
              </Button>
              <RenderIf condition={currentTab === "unbanked-beneficiary"}>
                <Button type="button" size="40" theme="primary" onClick={() => setSelectedAction("update-beneficiary")}>
                  <Icon icon="ph:plus" className="w-6 h-6 mr-[8px]" />
                  Update Beneficiary
                </Button>
              </RenderIf>
            </div>
          </div>
        </div>
        <Table
          headers={currentTab === "banked-beneficiary" ? beneficiariesHeaders : unbankedBeneficiariesHeaders}
          data={beneficiaries?.beneficiaries ?? []}
          onPageChange={changePage}
          selectLimit={selectLimit}
          totalCount={beneficiaries?.total}
          perPage={params?.limit}
          page={beneficiaries?.currentPage}
          loading={isFetchingBeneficiaries}
          emptyStateText={"No data yet"}
          emptyStateImage={"layout"}
        >
          <>
            {beneficiaries?.beneficiaries?.map((item: any) => (
              <React.Fragment key={item.id}>
                <MotionTr
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3 }}
                  className="cc-custom-table-data-row"
                  data-testid={item.id}
                >
                  <td className="flex items-center ">
                    <div className="flex items-center">
                      <div className="h-full">
                        <Avatar image={item?.image ?? item?.name} size="40" />
                      </div>{" "}
                      <div className="ml-[16px]">
                        <p className="max-w-[280px] truncate capitalize">{getFirstTwoNames(item?.name)}</p>
                        <p className="text-grey-base text-xs">{item?.beneficiary_id ?? item.household_id}</p>
                      </div>
                    </div>
                  </td>
                  <td className="text-sm text-body font-normal capitalize">{item.state ?? "-"}</td>
                  <RenderIf condition={currentTab === "banked-beneficiary"}>
                    <td className="text-sm text-body font-normal">{item.number_of_interventions ?? "-"}</td>
                  </RenderIf>
                  <td className="text-sm text-body font-normal">{item.nin || "-"}</td>
                  <td className="text-sm text-body font-normal">{item.bvn || "-"}</td>
                  <td className="text-sm text-body font-normal">{item.account_number || "-"}</td>
                  <td className="text-sm text-body font-normal">{item.household_id ?? "-"}</td>
                  <RenderIf condition={currentTab === "unbanked-beneficiary"}>
                    <td className="font-normal capitalize">
                      {item?.error_remark?.map((errorMessage: any, index: any) => (
                        <span
                          key={index}
                          className="text-[10px] inline-block px-2 py-0 mr-2 mb-2 bg-error-lightest text-[#E03137] rounded-[24px]"
                        >
                          {errorMessage}
                        </span>
                      ))}
                    </td>
                  </RenderIf>
                  <td className="text-xs text-body font-normal">{item.source_register ?? "-"}</td>
                  <td
                    onClick={() =>
                      currentTab === "banked-beneficiary"
                        ? navigate(`/beneficiary/${item.reference}?category=banked-intervention-history`)
                        : navigate(`/beneficiary/${item.reference}?category=${currentTab}`)
                    }
                    className=""
                  >
                    <div className="border border-[#E6E6E6] grid place-items-center p-[6px] rounded-[4px] cursor-pointer">
                      <Icon icon="ph:eye" className="w-4 h-4" />
                    </div>
                  </td>
                </MotionTr>
              </React.Fragment>
            ))}
          </>
        </Table>
        {selectedAction === "update-beneficiary" && (
          <UpdateBeneficiary
            onCloseUpdate={() => setSelectedAction("")}
            openPercentageModal={openPercentageModal}
            setOpenPercentageModal={setOpenPercentageModal}
            setOpenCompleteUpdateModal={setOpenCompleteUpdateModal}
            setUpdateResponse={setUpdateResponse}
          />
        )}
        <RenderIf condition={openCompleteUpdateModal}>
          <ManageItem
            title={`Update Complete`}
            actionName={updated_info?.not_updated === 0 ? "No Inconsistent Data" : "Download Inconsistent Data"}
            caption={`Beneficiaries Updated - ${updated_info?.updated} `}
            caption2={`Beneficiaries not Found - ${updated_info?.not_updated}`}
            onClose={() => setOpenCompleteUpdateModal(false)}
            onSubmit={() => handleDownload(errorFileUrl, setOpenCompleteUpdateModal)}
            showCancelBtn={false}
            showCloseIcon={true}
            disabled={updated_info?.not_updated === 0}
          />
        </RenderIf>
      </main>
    </Restricted>
  )
}

export default Beneficiary
