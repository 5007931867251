import { createApi } from "@reduxjs/toolkit/query/react"
import { axiosBaseQuery } from "../axiosBaseQuery"
import { FetchedLivelihood, AddCommunityType, FetchedCommunities, FetchedGroups, FetchedGroup } from "types/livelihood"

const baseUrl = `${process.env.REACT_APP_BASE_URL}`

export const livelihoodSlice = createApi({
  reducerPath: "livelihoodSlice",
  baseQuery: axiosBaseQuery({ baseUrl }),
  tagTypes: ["Livelihoods", "Communities", "groups", "Livelihood", "Livelihood Benefeciaries"],
  refetchOnMountOrArgChange: true,
  endpoints(build) {
    return {
      getAllLivelihood: build.query<FetchedLivelihood, any>({
        query: (params) => ({ url: "/livelihoods", method: "get", params }),
        transformResponse: (response: any) => response.data,
        providesTags: ["Livelihoods"],
      }),
      getSingleLivelihood: build.query<any, any>({
        query: ({ id, params }) => ({ url: `/livelihoods/${id}`, method: "get", params }),
        transformResponse: (response: any) => response.data,
        providesTags: ["Livelihood"],
      }),
      getSingleLivelihoodBeneficiary: build.query<any, any>({
        query: ({ id, params }) => ({ url: `/livelihoods/intervention-history/${id}`, method: "get", params }),
        transformResponse: (response: any) => response.data,
        providesTags: ["Livelihood Benefeciaries"],
      }),
      getAllCommunities: build.query<FetchedCommunities, any>({
        query: (params) => ({ url: "/community", method: "get", params }),
        transformResponse: (response: any) => response.data,
        providesTags: ["Communities"],
      }),
      addCommunity: build.mutation<AddCommunityType, any>({
        query: (data) => ({ url: `/community`, method: "post", data }),
        invalidatesTags: ["Communities"],
      }),
      editCommunity: build.mutation<AddCommunityType, { id: string; data: any }>({
        query: ({ id, data }) => ({ url: `/community/edit/${id}`, method: "patch", data }),
        invalidatesTags: ["Communities"],
      }),
      getAllGroups: build.query<FetchedGroups, any>({
        query: ({ communityId, ...rest }) => ({
          url: `/livelihoods/groups/${communityId}`,
          method: "get",
          params: rest,
        }),
        transformResponse: (response: any) => response.data,
        providesTags: ["groups"],
      }),
      exportCommunity: build.query<any, any>({
        query: ({ communityId, ...rest }) => ({
          url: `/livelihoods/groups/${communityId}?noLimit=true`,
          method: "get",
          params: rest,
        }),
        transformResponse: (response: any) => response.data,
      }),
      addGroup: build.mutation<any, any>({
        query: (data) => ({ url: `/livelihoods/groups`, method: "post", data }),
        invalidatesTags: ["groups"],
      }),
      getGroup: build.query<FetchedGroup, any>({
        query: ({ groupId, ...rest }) => ({
          url: `/livelihoods/groups/${groupId}/members`,
          method: "get",
          params: rest,
        }),
        transformResponse: (response: any) => response.data,
        providesTags: ["groups"],
      }),
      addGroupMember: build.mutation<any, any>({
        query: ({ groupId, ...rest }) => ({
          url: `/livelihoods/groups/${groupId}/members`,
          method: "post",
          data: rest,
        }),
        invalidatesTags: ["groups"],
      }),
      editGroupMember: build.mutation<any, any>({
        query: ({ memberId, ...rest }) => ({
          url: `/livelihoods/member/${memberId}`,
          method: "patch",
          data: rest,
        }),
        invalidatesTags: ["groups"],
      }),
    }
  },
})

export const {
  useGetAllLivelihoodQuery,
  useLazyGetAllLivelihoodQuery,
  useAddCommunityMutation,
  useEditCommunityMutation,
  useGetAllCommunitiesQuery,
  useLazyGetAllCommunitiesQuery,
  useGetAllGroupsQuery,
  useLazyExportCommunityQuery,
  useAddGroupMutation,
  useGetGroupQuery,
  useGetSingleLivelihoodQuery,
  useGetSingleLivelihoodBeneficiaryQuery,
  useLazyGetSingleLivelihoodBeneficiaryQuery,
  useAddGroupMemberMutation,
  useEditGroupMemberMutation,
} = livelihoodSlice
