import { Icon } from "@iconify/react"
import { Avatar, Breadcrumb, Button, Table } from "components/core"
import { beneficiaryReportHeader } from "constants/reports"
import useBeneficiaryReport from "./useBeneficiaryReport"
import { RenderIf } from "components/hoc"
import HeaderLoader from "components/core/Loader/HeaderLoader/HeaderLoader"

export const BeneficiaryReport = () => {
  const { report, ...state } = useBeneficiaryReport()

  return (
    <div className="w-full">
      <Breadcrumb options={state.breadcrumbs} showBack={true} goBackRoute="/reports/beneficiary" />
      <RenderIf condition={state.isLoadingReport}>
        <HeaderLoader />
      </RenderIf>
      <RenderIf condition={!state.isLoadingReport}>
        <>
          <div className="mb-[32px]">
            <h1 className="text-xl text-headers font-semibold mb-[8px] capitalize">{report?.data?.reportTitle}</h1>
          </div>
          <div className="flex items-center py-[24px] border-y mb-[32px] w-full">
            {state.overview.map((details) => (
              <div key={details.title} className="flex items-center gap-4 mr-[40px]">
                <div className="relative w-10 h-10 grid place-items-center bg-grey-100 rounded-full shrink-0">
                  <Icon icon={details.icon} className="w-6 h-6 text-secondary-base" />
                </div>
                <div className="grid">
                  <span className="text-sm text-body">{details.title}</span>
                  <p className="text-base font-medium text-headers">{details.count}</p>
                </div>
              </div>
            ))}
          </div>
          <RenderIf condition={!!report?.data?.result?.beneficiaries?.length}>
            <div className="flex justify-end">
              <Button
                type="button"
                size="40"
                disabled={state.isExporting}
                onClick={state.exportReport}
                theme="transparent"
              >
                <Icon icon="uiw:download" className="text-paragraph w-[18px] h-[18px] mr-3" />
                {state.isExporting ? "Exporting..." : "Export"}
              </Button>
            </div>
          </RenderIf>
          <Table
            headers={beneficiaryReportHeader}
            data={report?.data?.result?.beneficiaries || []}
            onPageChange={state.changePage}
            selectLimit={state.selectLimit}
            totalCount={Number(report?.data?.result?.totalBeneficiaryCount || 0)}
            perPage={state.params?.limit}
            page={Number(report?.data?.page ?? 0)}
            loading={state.isLoadingReport}
          >
            <>
              {report?.data?.result?.beneficiaries?.map((item: any) => (
                <tr data-testid={item?.beneficiary_id} key={item?.beneficiary_id} className="cc-custom-table-data-row">
                  <td>
                    <div className="flex items-center">
                      <Avatar image={item?.image ?? item?.beneficiary_name} size="40" />
                      <div className="ml-[16px]">
                        <p className="max-w-[280px] truncate capitalize">{item?.beneficiary_name}</p>
                        <p className="text-grey-base text-xs">{item?.beneficiary_id}</p>
                      </div>
                    </div>
                  </td>
                  <td>{item?.state ?? "N/A"}</td>
                  <td>{item?.lga ?? "N/A"}</td>
                  <td>{item?.ward ?? "N/A"}</td>
                  <td>{item?.community ?? "N/A"}</td>
                  <td className="capitalize">{item?.status}</td>
                </tr>
              ))}
            </>
          </Table>
        </>
      </RenderIf>
    </div>
  )
}
