export const shortenString = (str: string, maxLength: number): string => {
  if (str.length <= maxLength) {
    return str
  }
  return str?.substring(0, maxLength - 3) + "..."
}

export const getLastWord = (str: string): string => {
  // Split the string into words by whitespace
  const words: string[] = str?.trim()?.split(/\s+/)

  // Return the last word
  return words[words.length - 1]
}
