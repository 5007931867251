import { useTable } from "hooks"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useLazyGetLgaInStateQuery } from "services/api/commonSlice"
import { useGetAllCommunitiesQuery, useLazyGetAllCommunitiesQuery } from "services/api/livelihoodSlice"
import { formatCurrency } from "utils/formatCurrency"
import { removeKeysWithEmptyValues } from "utils/removeKeysWithEmptyValues"
import { stringifyArray } from "utils/stringifyArray"
import { tryCatch } from "utils/tryCatch"
import { exportData } from "utils/exportData"
import { useSelector } from "react-redux"
import { AllPermissions } from "types/permission"

export default function useCommnunity() {
  const [selectedState, setSelectedState] = useState<any>([])
  const [selectedLga, setSelectedLga] = useState<any>([])
  const [selectedAction, setSelectedAction] = useState("")
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const { handleSearch, changePage, selectLimit, params } = useTable()
  const [selectedCommunity, setSelectedCommunity] = useState<any>({})
  const [lga, setLga] = useState([])
  const navigate = useNavigate()
  const { states: nigerianStates } = useSelector((state: any) => state.common)
  const { data: communities, isFetching } = useGetAllCommunitiesQuery({
    ...params,
    lga: selectedLga.length ? stringifyArray(selectedLga) : "",
    state: selectedState.value,
  })
  const [getAllCommunities, { isFetching: isExporting }] = useLazyGetAllCommunitiesQuery()
  const { VIEW_SINGLE_COMMUNITY, EDIT_COMMUNITY } = AllPermissions

  const [getLgaInState, { isFetching: isFetchingLga }] = useLazyGetLgaInStateQuery()

  const overviewDetails = [
    {
      title: "Total Community List",
      icon: "ph:users-three",
      count: communities?.total_community_list ?? 0,
    },
    {
      title: "Total Amount Contributed",
      icon: "ph:handshake",
      count: formatCurrency(communities?.total_amount_contributed ?? 0),
    },
    {
      title: "Total Loan Given",
      icon: "cil:brush-alt",
      count: formatCurrency(communities?.total_loan_given ?? 0),
    },
  ]
  const breadCrumbs = [{ title: "Livelihood" }, { title: "Community List" }]
  const actions = [
    {
      name: "Edit community",
      method: (item: any) => {
        setSelectedCommunity(item)
        setSelectedAction("Edit")
      },
      permission: EDIT_COMMUNITY,
    },
    {
      name: "View community",
      method: (item: any) => {
        setSelectedCommunity(item)
        navigate(`${item?.community_id}`)
      },
      permission: VIEW_SINGLE_COMMUNITY,
    },
  ]

  useEffect(() => {
    setLga([])
    setSelectedLga([])
    if (selectedState.value) {
      tryCatch(async () => {
        const res = await getLgaInState({
          ids: selectedState.id,
        }).unwrap()
        const options = res?.map((item: any) => ({
          label: item?.name,
          value: item?.name,
          id: item.id,
        }))
        setLga(options)
      })
    }
    setLga([])
  }, [selectedState])

  const exportAllCommunities = () => {
    tryCatch(async () => {
      const res = await getAllCommunities(
        removeKeysWithEmptyValues({
          search: params.search ?? "",
          lga: selectedLga.length ? stringifyArray(selectedLga) : "",
          state: selectedState.length ? stringifyArray(selectedState) : "",
          noLimit: true,
        }),
      ).unwrap()
      const data = res?.communities?.map((item: any) => {
        return {
          "Community ID": item?.community_id,
          "Community name": item?.community_name,
          "Number of Groups": item?.no_of_groups,
          "Number of beneficiary": item?.no_of_beneficiaries,
          State: item?.state?.name,
          LGA: item?.lga?.name,
          "Total Loan Given": item?.total_loan_given,
          "Total Savings Amount": item?.total_savings_amount,
        }
      })
      exportData(data, "Community List")
    })
  }

  return {
    breadCrumbs,
    overviewDetails,
    nigerianStates,
    selectedState,
    handleSearch,
    selectLimit,
    changePage,
    setSelectedState,
    setSelectedLga,
    selectedLga,
    selectedAction,
    setSelectedAction,
    selectedCommunity,
    actions,
    communities,
    isFetching,
    isFetchingLga,
    lga,
    exportAllCommunities,
    isExporting,
    showSuccessModal,
    setShowSuccessModal,
  }
}
