import { useMemo, useState } from "react"
import { useLazyGetInterventionsQuery } from "services/api/interventionSlice"
import { ISODateFormatter, shortDate } from "utils/dateFormatter"
import { tryCatch } from "utils/tryCatch"
import { useNavigate } from "react-router-dom"
import { useGenerateReportMutation } from "services/api/reportSlice"
import { toast } from "react-toastify"
import { removeSpecificKeys } from "utils/removeSpecificKeys"
import { removeKeysWithEmptyValues } from "utils/removeKeysWithEmptyValues"

const initialDate = {
  createdDate: { startDate: null, endDate: null },
  startDate: { startDate: null, endDate: null },
  endDate: { startDate: null, endDate: null },
}
const initialFormData = {
  reportType: "co-responsibility",
  reportTitle: "",
  interventions: [],
  additionalEligibility: [],
  interventionStatus: [],
  paymentStatus: [],
  zones: [],
  states: [],
  lga: [],
  fromEligibilityAmount: "",
  toEligibilityAmount: "",
  fromTotalAmount: "",
  toTotalAmount: "",
}

export default function useGenerateCoResponsibility() {
  const navigate = useNavigate()
  const [showPreview, setShowPreview] = useState(false)
  const breadcrumbs = [{ title: "Co-responsibility" }, { title: "Report Parameters" }]
  const [date, setDate] = useState<any>({ ...initialDate })
  const [formData, setFormData] = useState({ ...initialFormData })
  const [getInterventionsMutation, { data: interventionsData, isFetching: isFetchingInterventions }] =
    useLazyGetInterventionsQuery()
  const [generateReportMutation, { isLoading: isGeneratingReport }] = useGenerateReportMutation()

  const getInterventions = () => {
    tryCatch(async () => {
      await getInterventionsMutation({
        fromCreatedDate: shortDate(date.createdDate.startDate),
        toCreatedDate: shortDate(date.createdDate.endDate),
        fromStartDate: shortDate(date.startDate.startDate),
        toStartDate: shortDate(date.startDate.endDate),
        fromEndDate: shortDate(date.endDate.startDate),
        toEndDate: shortDate(date.endDate.endDate),
      })
    })
  }
  const checkDate = (date: Date) => {
    return date ? ISODateFormatter(date) : ""
  }
  const enhancedIntervention = useMemo(() => {
    return interventionsData?.interventions?.length
      ? interventionsData?.interventions.map((item) => {
          return { label: item.intervention_title, value: item.intervention_id }
        })
      : []
  }, [interventionsData?.interventions])

  const isDateRangeEmpty = (dateRange: { startDate: any; endDate: any }) => {
    return dateRange.startDate === null && dateRange.endDate === null
  }

  const disableApply = [date.createdDate, date.startDate, date.endDate].every(isDateRangeEmpty)
  const generateReport = () => {
    tryCatch(async () => {
      const { interventions, zones, states, lga, paymentStatus, additionalEligibility, interventionStatus } = formData

      const payload = {
        ...formData,
        zones: zones?.length ? zones?.map((item: any) => item.value.toString()) : "",
        states: states?.length ? states?.map((item: any) => item.id.toString()) : "",
        lga: lga?.length ? lga?.map((item: any) => item.id.toString()) : "",
        interventionIds: interventions.length ? interventions?.map((item: any) => item.value) : "",
        additionalEligibility: additionalEligibility.length
          ? additionalEligibility.map((item: any) => item.value.toLowerCase())
          : "",
        paymentStatus: paymentStatus.length ? paymentStatus.map((item: any) => item.value) : "",
        interventionStatus: interventionStatus.length ? interventionStatus.map((item: any) => item.value) : "",
        fromCreatedDate: checkDate(date.createdDate.startDate),
        toCreatedDate: checkDate(date.createdDate.endDate),
        fromStartDate: checkDate(date.startDate.startDate),
        toStartDate: checkDate(date.startDate.endDate),
        fromEndDate: checkDate(date.endDate.startDate),
        toEndDate: checkDate(date.endDate.endDate),
      }

      const res = await generateReportMutation(
        removeKeysWithEmptyValues(removeSpecificKeys({ ...payload }, ["interventions"])),
      ).unwrap()

      if ([200, 201].includes(res?.code)) {
        toast.success(res?.message)
        navigate(`/reports/co-responsibility/${res?.data?.report_id}`)
      }
    })
  }

  const refresh = () => {
    setFormData(initialFormData)
    setDate(initialDate)
  }

  const handleChange = (e: any) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }
  const proceed = (e: any) => {
    e.preventDefault()
    setShowPreview(true)
  }
  const close = () => {
    setShowPreview(false)
  }
  return {
    proceed,
    formData,
    setFormData,
    breadcrumbs,
    date,
    close,
    generateReport,
    handleChange,
    showPreview,
    isFetchingInterventions,
    setDate,
    getInterventions,
    disableApply,
    refresh,
    isGeneratingReport,
    interventions: enhancedIntervention,
  }
}
