import { paymentHeader } from "constants/payments"

const PaymentTable = ({ data }: any) => {
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-headers">
        <thead className="">
          <tr>
            {paymentHeader.map((header) => (
              <th
                key={header}
                className="px-6 py-3 text-left text-sm text-headers uppercase tracking-wider border font-semibold border-headers"
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-black">
          <tr>
            <td className="px-6 py-4 whitespace-nowrap uppercase text-headers font-semibold border border-headers">
              {data.state}
            </td>
            <td className="px-6 py-4 whitespace-nowrap uppercase text-headers font-semibold border border-headers">
              {data.batch}
            </td>
            <td className="px-6 py-4 whitespace-nowrap uppercase text-headers font-semibold border border-headers">
              {data.benCount}
            </td>
            <td className="px-6 py-4 whitespace-nowrap uppercase text-headers font-semibold border border-headers">
              ₦{data.Amount}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default PaymentTable
