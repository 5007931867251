import { compulsoryDepartment } from "constants/intervention"
import { useState, useEffect, useMemo } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useGetDepartmentsQuery } from "services/api/commonSlice"
import { useGetInterventionQuery, useGetInterventionSelectionCriteriaQuery } from "services/api/interventionSlice"
import { SelectionCriteria } from "types/intervention"

const formatArray = (array: any, type?: string) => {
  if (array) {
    return array.map((item: any) => ({
      label: item.name,
      value: item.name,
      id: item.id,
    }))
  }
  return type === "departments" ? compulsoryDepartment : []
}

const generateFormData = (data: any) => ({
  intervention_title: data?.intervention_title ?? "",
  description: data?.description ?? "",
  intervention_reviewer: data?.intervention_reviewer ?? "",
  reviewer_name: data?.reviewer_name ?? "",
  end_date: data?.end_date ?? "",
  start_date: data?.start_date ?? "",
  total_number_of_beneficiary: data?.total_number_of_beneficiary ?? "",
  number_of_eligible_household_member: data?.number_of_eligible_household_member ?? "",
  version: data?.version ?? "",
  data_source: data?.data_source ?? "",
  zones: formatArray(data?.zones),
  departments: formatArray(data?.departments, "departments"),
  amount: data?.amount ?? "",
  states: formatArray(data?.states),
  lga: formatArray(data?.lgas),
  quota_structure: data?.quota_structure ?? {},
  payment_method: data?.payment_method ?? "",
  payment_mechanism: data?.payment_mechanism ?? "",
  payment_recurrence: data?.payment_recurrence ?? "",
  payment_cycle: data?.payment_cycle ?? "",
  eligibility_criteria: data?.eligibility ?? {},
  selection_criteria: data?.selection_criteria ?? null,
  data_source_id: data?.data_source_id ?? "",
  mine_history_id: data?.mine_history_id ?? "",
  currency: data?.currency ?? "",
})

export default function useNewIntervention() {
  const [view, setView] = useState<string>("Basic Information")
  const [step, setStep] = useState<number>(1)
  const [searchParams] = useSearchParams()
  const id = searchParams.get("interventionId")
  const navigate = useNavigate()
  const { data, isLoading: isFetchingDetails } = useGetInterventionQuery(id ?? "", { skip: !id })
  const { data: departments, isLoading: isFetchingDepartments } = useGetDepartmentsQuery({})
  const { data: criteriaData = [], isLoading: isFetchingSelectionCriteria } =
    useGetInterventionSelectionCriteriaQuery(null)

  const selectionCriteria = useMemo(() => {
    return criteriaData.length
      ? criteriaData.map((item: SelectionCriteria) => {
          return { label: item.criteria, value: item.criteria }
        })
      : []
  }, [criteriaData])

  const breadCrumbs = [
    { title: "Settings" },
    { title: "Intervention" },
    { title: `${id ? "Edit" : "New"} Intervention` },
  ]

  const [formData, setFormData] = useState(() => generateFormData(data))

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      ...generateFormData(data),
    }))
  }, [data])

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const departmentOptions = useMemo(
    () =>
      departments?.map((item: any) => {
        return {
          label: `${item?.name}`,
          value: `${item?.name}`,
          id: item.id,
        }
      }) ?? [],
    [departments],
  )

  const proceed = (page: string, dir: string) => {
    if (dir === "next") {
      setStep(step + 1)
    } else {
      setStep(step - 1)
    }
    setView(page)
  }

  return {
    breadCrumbs,
    setView,
    proceed,
    step,
    id,
    data,
    navigate,
    setFormData,
    formData,
    isFetchingDetails,
    departmentOptions,
    isFetchingDepartments,
    handleChange,
    view,
    selectionCriteria,
    isFetchingSelectionCriteria,
  }
}
