import React from "react"
import { Icon } from "@iconify/react"
import { motion } from "framer-motion"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { Breadcrumb, Table } from "components/core"
import TransactionInfo from "components/pages/Payments/Transactions/TransactionInfo/TransactionInfo"
import useViewTransaction from "./useViewTransaction"
import { transactionsInfoHeader, singleTransactionsHeaders } from "constants/payments"
import { addCommasToNumbers } from "utils/addCommasToNumbers"
import { formatTimestamp } from "utils/dateFormatter"
import { singleTransactionBatch } from "services/api/paymentSlice"
import { AllPermissions, PermissionModule } from "types/permission"
import { Restricted, RenderIf } from "components/hoc"
import { usePermission } from "hooks"
import PaymentReviewStatus from "components/pages/Payments/ReviewStatus/ReviewStatus"

const ViewSingleTransaction = () => {
  const { TRANSACTIONS } = PermissionModule
  const { hasPermission } = usePermission()
  const { VIEW_BATCHES, VIEW_BENEFICIARIES } = AllPermissions

  const {
    breadCrumbs,
    transactionInfoData,
    params,
    changePage,
    selectLimit,
    id,
    isFetchingTransactionBatches,
    transactionsBatches,
    transactionInfoTitle,
  } = useViewTransaction()

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const MotionTr = motion.tr

  const showBeneficiaries = hasPermission(TRANSACTIONS, VIEW_BENEFICIARIES)

  const navigateToBatchesPage = (item: { transaction_batch_id: string }) => {
    if (showBeneficiaries) {
      dispatch(singleTransactionBatch(item))
      navigate(`/payments/transactions/${id}/batch/${item?.transaction_batch_id}`)
    }
  }

  return (
    <Restricted module={TRANSACTIONS} permission={VIEW_BATCHES}>
      <main>
        <Breadcrumb options={breadCrumbs} showBack />
        <div className="mt-2 mb-8">
          <TransactionInfo title={transactionInfoTitle} headers={transactionsInfoHeader} data={transactionInfoData} />
        </div>

        <div>
          <Table
            headers={singleTransactionsHeaders}
            data={transactionsBatches?.transaction_batch ?? []}
            onPageChange={changePage}
            perPage={Number(params?.limit)}
            totalCount={Number(transactionsBatches?.total)}
            page={Number(params?.page)}
            selectLimit={selectLimit}
            loading={isFetchingTransactionBatches}
          >
            <>
              {transactionsBatches
                ? transactionsBatches?.transaction_batch?.map((item: any) => (
                    <React.Fragment key={item.id}>
                      <MotionTr
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.3 }}
                        className="cc-custom-table-data-row"
                        data-testid={`transaction-${item?.transaction_batch_id}`}
                        onClick={() => navigateToBatchesPage(item)}
                      >
                        <td>{item?.batch_reference}</td>
                        <td>
                          {item?.batch_beneficiaries_count ? addCommasToNumbers(item?.batch_beneficiaries_count) : "--"}
                        </td>
                        <td>
                          {item?.currency} {addCommasToNumbers(item?.total_amount_paid)}
                        </td>
                        <td>
                          {item?.fee_amount
                            ? `${item?.currency ?? ""} ${addCommasToNumbers(item?.fee_amount)}`
                            : "NGN 0"}
                        </td>
                        <td>{formatTimestamp(item?.transaction_date)}</td>
                        <td>
                          {item?.batch_status ? (
                            <PaymentReviewStatus status={String(item?.batch_status)?.toLowerCase()} />
                          ) : (
                            "--"
                          )}
                        </td>
                        <RenderIf condition={showBeneficiaries}>
                          <td onClick={() => navigateToBatchesPage(item)}>
                            <div className="border border-[#E6E6E6] grid place-items-center p-[6px] rounded-[4px] cursor-pointer">
                              <Icon icon="ph:eye" className="w-4 h-4" />
                            </div>
                          </td>
                        </RenderIf>
                      </MotionTr>
                    </React.Fragment>
                  ))
                : null}
            </>
          </Table>
        </div>
      </main>
    </Restricted>
  )
}

export default ViewSingleTransaction
