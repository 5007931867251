import { Icon } from "@iconify/react"
import { Actions, Breadcrumb, Button, Filter, ManageItem, Search, Table } from "components/core"
import AddEditCommunity from "components/pages/Livelihood/AddEditCommunity"
import { communityHeader } from "constants/livelihood"
import { convertStateToDropdownOptions } from "utils/dropdown"
import useCommnunity from "./useCommunity"
import { ReactComponent as MiscFeature } from "assets/icons/misc-feature.svg"
import { formatCurrency, getCurrencyFormat } from "utils/formatCurrency"
import { AllPermissions, PermissionModule } from "types/permission"
import { Restricted } from "components/hoc"

const Community = () => {
  const {
    breadCrumbs,
    overviewDetails,
    handleSearch,
    nigerianStates,
    selectedState,
    setSelectedState,
    selectedLga,
    setSelectedLga,
    changePage,
    selectLimit,
    selectedAction,
    selectedCommunity,
    setSelectedAction,
    actions,
    communities,
    isFetching,
    lga,
    isFetchingLga,
    exportAllCommunities,
    isExporting,
    setShowSuccessModal,
    showSuccessModal,
  } = useCommnunity()

  const { LIVELIHOOD } = PermissionModule
  const { VIEW_COMMUNITY_LIST, ADD_COMMUNITY } = AllPermissions

  return (
    <Restricted module={LIVELIHOOD} permission={VIEW_COMMUNITY_LIST} showError={true}>
      <main data-testid="community">
        <Breadcrumb options={breadCrumbs} showBack={true} />
        <h1 className="text-headers text-2xl font-bold my-6">Community List</h1>
        <div className="flex items-center py-[24px] border-y">
          {overviewDetails.map((item: any) => (
            <div key={item.title} className="flex items-center gap-4 mr-[40px]">
              <div className="relative w-10 h-10 grid place-items-center bg-grey-100 rounded-full">
                <Icon icon={item.icon} className="w-6 h-6 text-body" />
              </div>
              <div className="grid">
                <p className="text-sm text-body">{item.title}</p>
                <div className="text-base font-medium text-headers">{isFetching ? "..." : item.count}</div>
              </div>
            </div>
          ))}
        </div>
        <div className="flex items-center justify-between mb-4 mt-6">
          <div className="flex space-x-2">
            <Search id="search" name="search" placeholder="Search" onChange={handleSearch} />
            <Filter
              title="State:"
              options={convertStateToDropdownOptions(nigerianStates)}
              selectedOption={selectedState}
              setSelectedOption={setSelectedState}
            />
            <Filter
              title="LGA:"
              options={lga}
              disabled={!selectedState.value || isFetchingLga}
              loading={isFetchingLga}
              multiple={true}
              selectedOption={selectedLga}
              setSelectedOption={setSelectedLga}
            />
          </div>
          <div className="flex space-x-2">
            <Restricted module={LIVELIHOOD} permission={VIEW_COMMUNITY_LIST}>
              <Button type="button" size="40" disabled={isExporting} theme="transparent" onClick={exportAllCommunities}>
                <Icon icon="uiw:download" className="text-[#747F9C] w-5 h-5 mr-3" />
                {isExporting ? "Exporting..." : "Export"}
              </Button>
            </Restricted>
            <Restricted module={LIVELIHOOD} permission={ADD_COMMUNITY}>
              <Button type="button" size="40" theme="primary" onClick={() => setSelectedAction("Add")}>
                <Icon icon="ph:plus" className="w-6 h-6 mr-[8px]" />
                Add Community
              </Button>
            </Restricted>
          </div>
        </div>
        <Table
          headers={communityHeader}
          data={communities?.communities ?? []}
          onPageChange={changePage}
          selectLimit={selectLimit}
          totalCount={communities?.total_community_list}
          perPage={communities?.limit}
          page={communities?.page}
          loading={isFetching}
        >
          <>
            {communities?.communities?.map((item) => (
              <tr
                data-testid={item?.community_name}
                key={item?.community_id}
                className="cc-custom-table-data-row cursor-pointer"
              >
                <td className="flex items-center ">
                  <div>
                    <p className="capitalize">{item?.community_name}</p>
                    <p className="text-grey-base text-xs max-w-[250px] truncate font-medium">{item?.community_id}</p>
                  </div>
                </td>
                <td>
                  <p className="w-max">{item?.state?.name}</p>
                </td>
                <td>
                  <p className="w-max">{item?.lga?.name}</p>
                </td>
                <td>
                  <p className="w-max ">{item?.no_of_groups}</p>
                </td>
                <td>{formatCurrency(item?.total_savings_amount, getCurrencyFormat(item?.currency))}</td>
                <td>{item?.no_of_beneficiaries}</td>
                <td>{formatCurrency(item?.total_loan_given, getCurrencyFormat(item?.currency))}</td>
                <td className="w-[20px]">
                  <Actions actions={actions} module={LIVELIHOOD} id={item?.community_id} item={item} />
                </td>
              </tr>
            ))}
          </>
        </Table>
        {["Add", "Edit"].includes(selectedAction) && (
          <AddEditCommunity
            closeModal={() => setSelectedAction("")}
            action={selectedAction}
            showSuccesModal={() => setShowSuccessModal(true)}
            communityDetails={selectedCommunity}
            nigerianStates={[...convertStateToDropdownOptions(nigerianStates)]}
          />
        )}
        {showSuccessModal && (
          <ManageItem
            title="Community Added"
            actionName="Cancel"
            caption="A community has being added to the Livelihood list"
            icon={<MiscFeature />}
            onSubmit={() => setShowSuccessModal(false)}
            showCancelBtn={false}
          />
        )}
      </main>
    </Restricted>
  )
}

export default Community
