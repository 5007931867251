import React from "react"

interface ProgressBarProps {
  progress: number // Should be between 0 and 100
}

const ProgressBar: React.FC<ProgressBarProps> = ({ progress }) => {
  const getColor = (progress: number) => {
    return progress === 100 ? "bg-[#2B892B]" : "bg-[#FFD023]"
  }

  return (
    <div className="w-full h-1 bg-gray-200 rounded-full">
      <div
        data-testid="progress-bar"
        className={`h-full rounded-full ${getColor(progress)}`}
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  )
}

export default ProgressBar
