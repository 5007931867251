import { useParams } from "react-router-dom"
import { useState, useMemo } from "react"
import { useFetchSingleTrainingQuery } from "services/api/trainingSlice"
import { fullDateFormatter } from "utils/dateFormatter"
import { useTable } from "hooks"

export default function useSingleTraining() {
  const { id } = useParams()
  const [selectedAction, setSelectedAction] = useState("")
  const { handleSearch, changePage, params, selectLimit } = useTable()

  const { data: trainingData, isFetching: isFetchingTrainingData } = useFetchSingleTrainingQuery({
    id,
    params: {
      search: params?.search ?? "",
      limit: params?.limit ?? 10,
      page: params?.page,
    },
  })

  const moduleCount = useMemo(() => {
    return trainingData?.courses?.reduce((total: any, course: any) => total + course?.module_count, 0)
  }, [trainingData])

  const breadCrumbs = [
    { title: "Training List" },
    { title: !isFetchingTrainingData ? trainingData?.name : "Loading..." },
  ]

  const trainingInfo = {
    name: trainingData?.name ?? "--",
    description: trainingData?.training_needs ?? "--",
  }

  // TODO: Refactor this garbage
  const vendorsCount = trainingData?.vendors
    ? `${
        trainingData?.vendors?.length > 1
          ? `${trainingData?.vendors[0]} + ${trainingData?.vendors?.length - 1}  vendor(s)`
          : `${trainingData?.vendors[0]}`
      }`
    : "--"

  // Get first course and remaining courses
  const firstCourseName = trainingData?.courses?.[0]?.course_title ?? "--"
  const remainingCourses = trainingData?.courses?.slice(1).map((course: any) => course.course_title)
  const remainingCount = remainingCourses?.length

  const trainingDetailsInformation = [
    trainingData?.training_reference ?? "--",
    trainingData?.training_participants_count ?? "--",
    String(trainingData?.training_unit).toUpperCase(),
    trainingData?.training_type,
    vendorsCount,
    remainingCount > 0 ? `${firstCourseName} + ${remainingCount} more` : firstCourseName,
    moduleCount ?? "--",
    trainingData?.created_at ? fullDateFormatter(trainingData?.created_at) : "--",
    trainingData?.duration ? `${trainingData?.duration} days` : "--",
    `${trainingData?.attendance_required} Sessions`,
    trainingData?.training_start_date ? fullDateFormatter(trainingData?.training_start_date) : "--",
    trainingData?.training_end_date ? fullDateFormatter(trainingData?.training_end_date) : "--",
  ]

  return {
    breadCrumbs,
    trainingInfo,
    trainingDetailsInformation,
    handleSearch,
    changePage,
    params,
    selectLimit,
    selectedAction,
    setSelectedAction,
    isFetchingTrainingData,
    trainingData,
  }
}
