import React, { useRef, useState } from "react"
import { ReactComponent as Folder } from "assets/svg/folder.svg"
import { ReactComponent as CSV } from "assets/svg/csv-file.svg"
import { ReactComponent as Delete } from "assets/svg/delete.svg"
import { RenderIf } from "components/hoc"
import { AnimatePresence, motion } from "framer-motion"
import "./mine-data.css"
import { UpdateStatus } from "./UploadStatus"

interface UploadFileCardProps {
  currentTab?: string
  // eslint-disable-next-line no-unused-vars
  handleFileUpload?: (e: React.ChangeEvent<HTMLInputElement>, currentTab?: string) => void
  // eslint-disable-next-line no-unused-vars
  handleFileDelete?: (currentTab?: string) => void
  householdFiles?: any
  memberFiles?: any
  setHouseholdFiles?: any
  setMemberFiles?: any
  uploadProgress?: any
  householdProgress?: number | undefined
  memberProgress?: number | undefined
}

const ProgressBar = ({ progress }: { progress: number }) => (
  <div className="ml-4  h-1 mt-2 bg-gray-300 rounded-sm">
    <div className="h-full bg-primary-actions rounded-sm" style={{ width: `${progress}%` }}></div>
  </div>
)

const UploadDataCard = ({
  currentTab,
  handleFileUpload,
  householdFiles,
  memberFiles,
  handleFileDelete,
  householdProgress,
  memberProgress,
}: UploadFileCardProps) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const fileRef = useRef<HTMLInputElement>(null)
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      if (file.size > 5 * 1024 * 1024) {
        setErrorMessage("File size exceeds 5MB")
      } else {
        setErrorMessage(null)
        handleFileUpload && handleFileUpload(e, currentTab)
      }
    }
  }

  const handleClick = () => {
    if (fileRef.current) {
      fileRef.current.value = ""
    }
  }

  return (
    <div>
      <div className="border-[0.3px] border-primary-actions bg-primary-30 mt-6 rounded-lg h-auto">
        <label htmlFor="file" onClick={handleClick}>
          <div className="h-60 flex flex-col items-center justify-center">
            <Folder />
            <div className="text-sm text-body text-center mt-6">
              <p>
                Drag or{" "}
                <span
                  className={`text-primary-actions cu ${
                    (currentTab === "Household" && householdFiles.length !== 0) ||
                    (currentTab === "Members" && memberFiles.length !== 0)
                      ? "cursor-not-allowed bg-gray-200"
                      : "cursor-pointer"
                  }`}
                >
                  choose file
                </span>{" "}
                to upload
              </p>
              <p>Only .csv allowed and must not exceed 5mb</p>
            </div>
          </div>
        </label>
        <input
          data-testid="test-file"
          type="file"
          id="file"
          name="file"
          accept=".csv"
          className="hidden"
          ref={fileRef}
          onChange={handleFileChange}
          disabled={currentTab === "Household" ? householdFiles.length !== 0 : memberFiles.length !== 0}
        />
        <AnimatePresence>
          {errorMessage && (
            <motion.p
              initial={{ opacity: 0, height: "auto" }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: "auto" }}
              transition={{ duration: 0.5 }}
              className="text-red-500 text-center text-xs mb-2 mt-[-20px]"
            >
              -{errorMessage}-
            </motion.p>
          )}
        </AnimatePresence>
      </div>
      <RenderIf condition={currentTab === "Household"}>
        {householdFiles.name && (
          <div className="border border-[#E8E8E8] pr-4 pt-2 pb-2 mt-6 rounded-lg">
            <div className="flex justify-between   items-center">
              <div className="flex">
                <CSV />
                <div>
                  <p className="text-sm mt-1 text_neutral_600 font-medium">{householdFiles?.name}</p>
                  <div className="flex items-center gap-1.5 divide-x-2 mt-[3px]">
                    <span className="text-[11px] text-neutral-base">{householdFiles?.size} MB</span>
                    <span>
                      <UpdateStatus progress={householdProgress} />
                    </span>
                  </div>
                </div>
              </div>

              <Delete
                onClick={() => {
                  handleFileDelete && handleFileDelete(currentTab)
                }}
                className="cursor-pointer"
              />
            </div>
            {!!householdProgress && householdProgress !== 100 && <ProgressBar progress={householdProgress} />}
          </div>
        )}
      </RenderIf>

      <RenderIf condition={currentTab === "Members"}>
        {memberFiles.name && (
          <div className="border border-[#E8E8E8] pr-4 pt-2 pb-4 mt-6 rounded-lg">
            <div className="flex justify-between   items-center">
              <div className="flex">
                <CSV />
                <div>
                  <p className="text-sm mt-1 text_neutral_600 font-medium">{memberFiles?.name}</p>
                  <div className="flex items-center gap-1.5 divide-x-2 mt-[3px]">
                    <span className="text-[11px] text-neutral-base">{memberFiles?.size}MB</span>
                    <span>
                      <UpdateStatus progress={memberProgress} />
                    </span>
                  </div>
                </div>
              </div>

              <Delete onClick={() => handleFileDelete && handleFileDelete(currentTab)} className="cursor-pointer" />
            </div>
            {!!memberProgress && memberProgress !== 100 && <ProgressBar progress={memberProgress} />}
          </div>
        )}
      </RenderIf>
    </div>
  )
}

export default UploadDataCard
