import { Avatar, Breadcrumb, Button, Calendar, Filter, Search, Status, Table } from "components/core"
import useCoresponsibiltyBeneficiary from "./useCoresponsibiltyBeneficiary"
import { Icon } from "@iconify/react"
import { fullDateFormatter } from "utils/dateFormatter"
import { formatCurrency } from "utils/formatCurrency"
import { beneficiaryHeader } from "constants/co-responsibility"
import { RenderIf } from "components/hoc"
import HeaderLoader from "components/core/Loader/HeaderLoader/HeaderLoader"
import { interventionStatus } from "constants/global"

export default function CoresponsibiltyBeneficiary() {
  const communityState = useCoresponsibiltyBeneficiary()

  return (
    // <Restricted module={ROLES_AND_PERMISSION} permission={VIEW_ALL_ROLES} showError={true}>
    <main>
      <Breadcrumb options={communityState.breadCrumbs} showBack={true} />
      <RenderIf condition={communityState?.isLoading}>
        <HeaderLoader />
      </RenderIf>
      <RenderIf condition={!communityState?.isLoading}>
        <>
          <div className="mb-[23px]">
            <div className="flex items-center mb-[32px]">
              <Avatar
                image={communityState?.beneficiary?.beneficiary?.name}
                alt={communityState?.beneficiary?.beneficiary?.name}
                size="89"
              />
              <div className="ml-[10px]">
                <p className="capitalize text-headers text-2xl font-semibold mb-[8px]">
                  {communityState?.beneficiary?.beneficiary?.name}
                </p>
                <p className="text-xs font-medium text-grey-base mt-[8px]">CC-12345-BS</p>
              </div>
            </div>
            <div className="p-6 bg-primary-900 rounded-[8px] mt-3 gap-8 mb-[36px]">
              <div className="grid grid-cols-6 divide-x child-not-last:pr-6 child-not-first:pl-6">
                {communityState.overview.map((item) => (
                  <div key={item.name}>
                    <p className="text-primary-100 text-xs font-medium">{item?.name}</p>
                    <p className="text-primary-30 text-sm mt-[2px]">{item?.value || "N/A"}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div>
            <h2 className="text-lg text-headers mb-[12px] font-medium">Intervention History</h2>
            <div className="flex items-center justify-between mb-[16px]">
              <div className="flex items-center">
                <Search
                  id="search"
                  name="search"
                  placeholder="Search"
                  onChange={communityState.handleSearch}
                  className="mr-[8px]"
                />
                <Filter
                  title="Status"
                  options={interventionStatus}
                  selectedOption={communityState.selectedStatus}
                  setSelectedOption={communityState.setSelectedStatus}
                  containerClass="w-[120px] mr-[8px]"
                />
                <Calendar
                  startDate={communityState.date.startDate}
                  endDate={communityState.date.endDate}
                  onChange={communityState.setDate}
                  selectsRange={true}
                />
              </div>
              <Button
                type="button"
                size="40"
                disabled={false}
                onClick={communityState.exportHistoryProxy}
                theme="transparent"
              >
                <Icon icon="uiw:download" className="text-paragraph w-[18px] h-[18px] mr-3" />
                {communityState?.isExporting ? "Exporting..." : "Export"}
              </Button>
            </div>
            <Table
              headers={beneficiaryHeader}
              paginateData={!!communityState?.beneficiary?.history}
              data={communityState?.beneficiary?.history || []}
              onPageChange={communityState.changePage}
              selectLimit={communityState.selectLimit}
              totalCount={communityState?.beneficiary?.count}
              perPage={communityState.params.limit}
              page={communityState?.beneficiary?.page}
              loading={communityState?.isFetching}
            >
              <>
                {communityState?.beneficiary?.history?.map((item: any) => (
                  <tr data-testid={item?.nin} key={item?.nin} className="cc-custom-table-data-row">
                    <td className="flex items-center">
                      <span title={item?.intervention_title} className="max-w-[200px] truncate capitalize">
                        {item?.intervention_title}
                      </span>
                    </td>
                    <td className="capitalize-first">{formatCurrency(item?.amount) || "N/A"}</td>
                    <td>{formatCurrency(item?.top_amount)}</td>
                    <td>
                      <Status status={item?.status} />
                    </td>
                    <td>{fullDateFormatter(item?.created_at) || "N/A"}</td>
                    <td>{item?.payment_cycle || "N/A"}</td>
                  </tr>
                ))}
              </>
            </Table>
          </div>
        </>
      </RenderIf>
    </main>
    // </Restricted>
  )
}
