import { useState } from "react"
import { tryCatch } from "utils/tryCatch"
import { usePostGrmCommentMutation } from "services/api/grievanceSlice"
import { toast } from "react-toastify"

export default function useSendGrievanceComment() {
  const [comment, setComment] = useState("")
  // const [tags, setTags] = useState([])

  const [postComment, { isLoading: isPostingComment }] = usePostGrmCommentMutation({})

  const handlePostComment = async (grievance: any) => {
    const data = {
      comment,
      grievance_id: grievance?.grievance_id,
      tags: null,
    }

    const callback = (res: { code: number; message: string }) => {
      if ([200, 201].includes(res?.code)) {
        toast.success(res?.message)
        setComment("")
      } else {
        toast.error(res?.message)
        setComment("")
      }
    }

    tryCatch(async () => {
      let res = await postComment({ data }).unwrap()
      callback(res)
      setComment("")
    })
  }

  return { comment, setComment, isPostingComment, handlePostComment }
}
