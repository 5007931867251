import useAudit from "./useAudit"
import { Breadcrumb } from "components/core"
import PaymentScheduleDetails from "components/pages/Payments/PaymentScheduleDetails/PaymentScheduleDetails"
import PaymentTableFilters from "components/pages/Payments/PaymentTable/PaymentFilters/PaymentFilters"
import PaymentScheduleTable from "components/pages/Payments/PaymentTable/PaymentTable"
// import { paymentStatus } from "mocks/payments"
import { AllPermissions, PermissionModule } from "types/permission"
import { usePermission } from "hooks/usePermission/usePermission"
import { Restricted } from "components/hoc"

const PaymentAudits = () => {
  const { AUDIT } = PermissionModule
  const { VIEW_PAYMENT_SCHEDULE_ALL, EXPORT_ALL_PAYMENT_SCHEDULE, VIEW_SINGLE_SCHEDULE } = AllPermissions
  const { hasPermission } = usePermission()

  const {
    breadCrumbs,
    handleSearch,
    // selectedStatus,
    // setSelectedStatus,
    date,
    setDate,
    unit,
    params,
    changePage,
    selectLimit,
    exportPaymentSchedule,
    isFetchingPaymentScheduleData,
    scheduleData,
  } = useAudit()

  return (
    <Restricted module={AUDIT} permission={VIEW_PAYMENT_SCHEDULE_ALL}>
      <main data-testid="accounts-unit">
        <div className="mb-[23px]">
          <Breadcrumb options={breadCrumbs} />
          <h1 className="text-headers text-2xl font-bold">Payment Schedule</h1>
        </div>

        <PaymentScheduleDetails />

        <PaymentTableFilters
          // filterOptions={paymentStatus}
          // filterLoading={false}
          // filterMultiple={true}
          // selectedOption={selectedStatus}
          // setSelectedOption={setSelectedStatus}
          showGenerateScheduleButton={false}
          searchName="search"
          handleSearch={handleSearch}
          startDate={date?.startDate}
          endDate={date?.endDate}
          handleDate={setDate}
          selectsRange={true}
          exportFunction={exportPaymentSchedule}
          showExport={hasPermission(AUDIT, EXPORT_ALL_PAYMENT_SCHEDULE)}
        />

        <PaymentScheduleTable
          data={scheduleData ?? []}
          onPageChange={changePage}
          selectLimit={selectLimit}
          totalCount={scheduleData?.length}
          perPage={Number(params?.limit)}
          page={Number(params?.page)}
          loading={isFetchingPaymentScheduleData}
          unit={unit}
          module={AUDIT}
          permissions={{
            singleView: VIEW_SINGLE_SCHEDULE,
          }}
        />
      </main>
    </Restricted>
  )
}

export default PaymentAudits
