import { Button, Calendar, Filter, Search, Table } from "components/core"
import InterventionStatus from "components/pages/Beneficiary/Status/InterventionStatus"
import React from "react"
import { Icon } from "@iconify/react"
import { bankedBeneficiaryInterventionTableHeader } from "constants/beneficiary"
import { motion } from "framer-motion"
import { InterventionHistoryProps, SingleBeneficiaryIntervention } from "types/beneficiary"
import { fullDateFormatter } from "utils/dateFormatter"
import { addCommasToNumbers } from "utils/addCommasToNumbers"

const MotionTr = motion.tr

export default function InterventionHistory({
  date,
  setDate,
  interventions,
  isFetchingInterventions,
  handleSearch,
  changePage,
  statusOptions,
  selectedStatus,
  setSelectedStatus,
  exportBeneficiaries,
  isExporting,
  interventionStatus,
}: InterventionHistoryProps) {
  return (
    <>
      <div className="flex items-center justify-between mb-6">
        <div className="flex space-x-2">
          <Search id="search" name="search" placeholder="Search" onChange={handleSearch} />
          <Filter
            title="Status"
            multiple={false}
            options={statusOptions}
            selectedOption={selectedStatus}
            setSelectedOption={setSelectedStatus}
          />
          <Calendar startDate={date.startDate} endDate={date.endDate} onChange={setDate} selectsRange={true} />
        </div>
        <Button type="button" size="40" theme="transparent" disabled={isExporting} onClick={exportBeneficiaries}>
          <Icon icon="uiw:download" className="text-[#747F9C] w-6 h-6 mr-3" />
          {isExporting ? "Exporting..." : "Export"}
        </Button>
      </div>
      <Table
        headers={bankedBeneficiaryInterventionTableHeader}
        data={interventions?.interventions ?? []}
        onPageChange={changePage}
        totalCount={interventions?.total}
        perPage={10}
        page={1}
        loading={isFetchingInterventions}
        paginateData={!!interventions?.interventions}
        emptyStateText={"No data yet"}
        emptyStateImage={"layout"}
      >
        <>
          {interventions?.interventions?.map((item: SingleBeneficiaryIntervention) => (
            <React.Fragment key={item.intervention_id}>
              <MotionTr
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="cc-custom-table-data-row"
                data-testid={item.intervention_id}
              >
                <td className="text-sm text-body font-normal">{item.intervention_title ?? "-"}</td>
                <td className="text-sm text-body font-normal">{addCommasToNumbers(item.amount) ?? "-"}</td>
                <td className="text-sm text-body font-normal">{fullDateFormatter(item.created_at ?? "-")}</td>
                <td className="text-sm text-body font-normal">
                  {<InterventionStatus status={interventionStatus(item.status)} />}
                </td>
                <td className="text-sm text-body font-normal">{item.payment_cycle ?? "-"}</td>
              </MotionTr>
            </React.Fragment>
          ))}
        </>
      </Table>
    </>
  )
}
