import RequestingApproval from "pages/intervention/RequestingApproval/RequestingApproval"
import Intervention from "pages/intervention/Intervention"
import NewIntervention from "pages/intervention/NewIntervention/NewIntervention"
import ViewIntervention from "pages/intervention/ViewIntervention/ViewIntervention"
import ViewRequest from "pages/intervention/RequestingApproval/ViewRequest/ViewRequest"

export const interventionRoutes = [
    {
        path: "intervention",
        element: <Intervention />,
    },
    {
        path: "intervention/:id",
        element: <ViewIntervention />,
    },
    {
        path: "intervention/requesting-approval",
        element: <RequestingApproval />,
    },
    {
        path: "intervention/requesting-approval/:id",
        element: <ViewRequest />,
    },
    {
        path: "intervention/new-intervention",
        element: <NewIntervention />,
    },
]