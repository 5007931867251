import { createApi } from "@reduxjs/toolkit/query/react"
import { createSlice } from "@reduxjs/toolkit"
import { axiosBaseQuery } from "services/axiosBaseQuery"

const baseUrl = `${process.env.REACT_APP_PAYMENTS_BASE_URL}/`

export const paymentSlice = createApi({
  reducerPath: "paymentSlice",
  baseQuery: axiosBaseQuery({ baseUrl }),
  tagTypes: [
    "Payments",
    "Payment",
    "Transactions",
    "Transaction",
    "PaymentsBeneficiaries",
    "InterventionDataAnalysis",
    "InterventionDisbursementAnalysis",
    "CoResponsibility",
    "GrievanceLogs",
  ],
  refetchOnMountOrArgChange: true,
  endpoints(build) {
    return {
      createPaymentSchedule: build.mutation<any, any>({
        query: (data) => ({ url: `payments/schedules`, method: "post", data }),
        invalidatesTags: ["Payments"],
      }),
      getPaymentSchedule: build.query<any, any>({
        query: (params) => ({ url: "payments/schedules", method: "get", params }),
        transformResponse: (response: any) => response.data,
        providesTags: ["Payments"],
      }),
      getPaymentScheduleAnalytics: build.query<any, any>({
        query: () => ({ url: "payments/schedules/analytics", method: "get" }),
        transformResponse: (response: any) => response.data,
        providesTags: ["Payments"],
      }),
      approvePaymentSchedule: build.mutation<any, any>({
        query: (data) => ({ url: `payments/schedules/approve`, method: "patch", data }),
        invalidatesTags: ["Payments", "Payment"],
      }),
      declinePaymentSchedule: build.mutation<any, any>({
        query: (data) => ({ url: `payments/schedules/decline`, method: "patch", data }),
        invalidatesTags: ["Payments", "Payment"],
      }),
      deletePaymentSchedule: build.mutation<any, any>({
        query: ({ id, data }) => ({ url: `payments/schedules/${id}`, method: "delete", data }),
        invalidatesTags: ["Payments", "Payment"],
      }),
      getBeneficiaries: build.query<any, any>({
        query: ({ params, id }) => ({ url: `payments/scheduled-beneficiaries/${id}`, method: "get", params }),
        transformResponse: (response: any) => response.data,
        providesTags: ["PaymentsBeneficiaries"],
      }),
      getActivityLog: build.query<any, any>({
        query: (params) => ({ url: `payments/activity-log`, method: "get", params }),
        transformResponse: (response: any) => response.data,
        providesTags: ["Payments", "GrievanceLogs"],
      }),
      makePayments: build.mutation<any, any>({
        query: (data) => ({ url: `transactions/initiate`, method: "post", data }),
        invalidatesTags: ["Payments", "Payment"],
      }),
      getPaymentCycle: build.query<any, any>({
        query: (data) => ({
          url: `payments/cycle`,
          method: "get",
          params: data,
        }),
        transformResponse: (response: any) => response.data,
        providesTags: ["Payments"],
      }),
      getTransactions: build.query<any, any>({
        query: (params) => ({ url: `transactions`, method: "get", params }),
        transformResponse: (response: any) => response.data,
        providesTags: ["Transactions", "Transaction"],
      }),
      getTransactionBatches: build.query<any, any>({
        query: ({ id, params }) => ({ url: `transactions/batch/${id}`, method: "get", params }),
        transformResponse: (response: any) => response.data,
        providesTags: ["Transactions", "Transaction"],
      }),
      getTransactionBeneficiaries: build.query<any, any>({
        query: ({ id, params }) => ({ url: `transactions/${id}`, method: "get", params }),
        transformResponse: (response: any) => response.data,
        providesTags: ["Transactions", "Transaction"],
      }),
      getTransactionReport: build.query<any, any>({
        query: () => ({ url: `transactions/report`, method: "get" }),
        transformResponse: (response: any) => response.data,
        providesTags: ["Transactions", "Transaction"],
      }),
      getSinglePaymentSchedule: build.query<any, any>({
        query: (id) => ({ url: `payments/${id}`, method: "get" }),
        transformResponse: (response: any) => response.data,
        providesTags: ["Payment"],
      }),
      getBeneficiaryPaymentHistory: build.query<any, any>({
        query: ({ reference, paramsHistory }) => ({
          url: `/transactions/beneficiary/${reference}`,
          method: "get",
          params: paramsHistory,
        }),
        transformResponse: (response: any) => response.data,
        providesTags: ["Transactions"],
      }),
      getInterventionAnalysis: build.query<any, any>({
        query: ({ params, data }) => ({ url: `transactions/interventions/data-analysis`, method: "get", params, data }),
        transformResponse: (response: any) => response.data,
        providesTags: ["InterventionDataAnalysis"],
      }),
      getInterventionDisbursementAnalysis: build.query<any, any>({
        query: ({ interventionId, data }) => ({
          url: `transactions/interventions/transfer-analysis/${interventionId}`,
          method: "get",
          data,
        }),
        transformResponse: (response: any) => response.data,
        providesTags: ["InterventionDisbursementAnalysis"],
      }),
      signScheduleAdvice: build.mutation<any, any>({
        query: (id) => ({ url: `payments/schedules/sign/${id}`, method: "patch" }),
        invalidatesTags: ["Payments", "Payment"],
      }),
      updateGenerateScheduleAdvice: build.mutation<any, any>({
        query: (id) => ({
          url: `payments/schedules/view/${id}`,
          method: "patch",
        }),
        invalidatesTags: ["Payments", "Payment"],
      }),
      getCoResponsibilityInterventionDetails: build.query<any, any>({
        query: ({ interventionId, ...rest }) => ({
          url: `transactions/interventions/co-responsibility?intervention_id=${interventionId}`,
          method: "get",
          params: rest,
        }),
        transformResponse: (response: any) => response.data,
        providesTags: ["CoResponsibility"],
      }),
    }
  },
})

export const {
  useCreatePaymentScheduleMutation,
  useGetPaymentScheduleQuery,
  useGetPaymentScheduleAnalyticsQuery,
  useApprovePaymentScheduleMutation,
  useDeclinePaymentScheduleMutation,
  useDeletePaymentScheduleMutation,
  useGetActivityLogQuery,
  useGetBeneficiariesQuery,
  useMakePaymentsMutation,
  useGetPaymentCycleQuery,
  useGetTransactionsQuery,
  useGetTransactionBatchesQuery,
  useGetTransactionBeneficiariesQuery,
  useGetTransactionReportQuery,
  useGetBeneficiaryPaymentHistoryQuery,
  useGetSinglePaymentScheduleQuery,
  useGetInterventionAnalysisQuery,
  useGetInterventionDisbursementAnalysisQuery,
  useUpdateGenerateScheduleAdviceMutation,
  useSignScheduleAdviceMutation,
  useGetCoResponsibilityInterventionDetailsQuery,
} = paymentSlice

const payments = createSlice({
  name: "payments",
  initialState: {},
  reducers: {
    setScheduleData: (state, action) => {
      Object.assign(state, action.payload)
    },
    singleScheduleData: (state, action) => {
      Object.assign(state, action.payload)
    },
  },
})

const transactions = createSlice({
  name: "transactions",
  initialState: {},
  reducers: {
    singleTransactionData: (state, action) => {
      Object.assign(state, action.payload)
    },
    singleTransactionBatch: (state, action) => {
      Object.assign(state, action.payload)
    },
  },
})

export const { singleTransactionBatch, singleTransactionData } = transactions.actions
export const { setScheduleData, singleScheduleData } = payments.actions
export const paymentsReducer = payments.reducer
export const transactionsReducer = transactions.reducer
