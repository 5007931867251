import { createApi } from "@reduxjs/toolkit/query/react"
import { axiosBaseQuery } from "../axiosBaseQuery"
import { FetchedRoles, FetchedRole } from "types/roles"

const baseUrl = `${process.env.REACT_APP_BASE_URL}/roles`

export const rolesSlice = createApi({
  reducerPath: "rolesSlice",
  baseQuery: axiosBaseQuery({ baseUrl }),
  tagTypes: ["Roles", "Role"],
  refetchOnMountOrArgChange: true,
  endpoints(build) {
    return {
      getRoles: build.query<FetchedRoles, any>({
        query: (params) => ({ url: "", method: "get", params }),
        transformResponse: (response: any) => response.data,
        providesTags: ["Roles"],
      }),
      getRole: build.query<FetchedRole, string>({
        query: (roleId) => ({ url: `/${roleId}`, method: "get" }),
        providesTags: ["Role"],
      }),
      createRole: build.mutation<any, any>({
        query: (data) => ({ url: "", method: "post", data }),
        invalidatesTags: ["Roles"],
      }),
      deleteRole: build.mutation<any, string>({
        query: (roleId) => ({ url: `/${roleId}`, method: "delete" }),
        invalidatesTags: ["Roles"],
      }),
      updateRoleStatus: build.mutation<any, { roleId: string; status: string }>({
        query: ({ roleId, status }) => ({ url: `/${roleId}/${status}`, method: "patch" }),
        invalidatesTags: ["Roles", "Role"],
      }),
      updateRole: build.mutation<any, any>({
        query: (data) => ({ url: "", method: "put", data }),
        invalidatesTags: ["Roles", "Role"],
      }),
      reassignRole: build.mutation<any, { staffId: string; role: string }[]>({
        query: (data) => ({ url: `/bulk-re-assign`, method: "patch", data }),
        invalidatesTags: ["Roles", "Role"],
      }),
    }
  },
})

export const {
  useGetRolesQuery,
  useGetRoleQuery,
  useCreateRoleMutation,
  useDeleteRoleMutation,
  useUpdateRoleStatusMutation,
  useUpdateRoleMutation,
  useReassignRoleMutation,
} = rolesSlice
