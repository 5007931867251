import { createApi } from "@reduxjs/toolkit/query/react"
import { axiosBaseQuery } from "../axiosBaseQuery"
import { FetchedInterventions, Intervention } from "types/intervention"

const baseUrl = `${process.env.REACT_APP_BASE_URL}/interventions`

export const interventionSlice = createApi({
  reducerPath: "interventionSlice",
  baseQuery: axiosBaseQuery({ baseUrl }),
  tagTypes: ["Interventions", "Intervention", "Criteria", "Additional Eligibility"],
  refetchOnMountOrArgChange: true,
  endpoints(build) {
    return {
      createIntervention: build.mutation<any, any>({
        query: (data) => ({ url: ``, method: "post", data }),
        invalidatesTags: ["Interventions"],
      }),
      editIntervention: build.mutation<any, any>({
        query: ({ id, data }) => ({ url: `/edit/${id}`, method: "patch", data }),
        invalidatesTags: ["Interventions"],
      }),
      getInterventions: build.query<FetchedInterventions, any>({
        query: (params) => ({ url: ``, method: "get", params }),
        transformResponse: (response: any) => response.data,
        providesTags: ["Interventions"],
      }),
      getIntervention: build.query<Intervention, any>({
        query: (id) => ({ url: `/${id}`, method: "get" }),
        transformResponse: (response: any) => response.data,
        providesTags: ["Intervention"],
      }),
      updateInterventionStatus: build.mutation<any, any>({
        query: ({ interventionId, data }) => ({ url: `/change-status/${interventionId}`, method: "patch", data }),
        invalidatesTags: (_, error) => (error ? [] : ["Interventions", "Intervention"]),
      }),
      getInterventionSelectionCriteria: build.query<any, any>({
        query: () => ({ url: `/selection-criteria`, method: "get" }),
        transformResponse: (response: any) => response.data,
        providesTags: ["Criteria"],
      }),
      getInterventionAdditionalEligibility: build.query<any, any>({
        query: () => ({ url: `/additional-eligibility`, method: "get" }),
        transformResponse: (response: any) => response.data,
        providesTags: ["Additional Eligibility"],
      }),
    }
  },
})

export const {
  useGetInterventionQuery,
  useEditInterventionMutation,
  useUpdateInterventionStatusMutation,
  useGetInterventionsQuery,
  useLazyGetInterventionsQuery,
  useLazyGetInterventionQuery,
  useCreateInterventionMutation,
  useGetInterventionSelectionCriteriaQuery,
  useGetInterventionAdditionalEligibilityQuery,
} = interventionSlice
