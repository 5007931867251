import { Button, Modal, Table } from "components/core"
import { uploadPreviewHeaders } from "mocks/staffs"
import React from "react"
import { FadeLoader } from "react-spinners"

interface BulkProps {
  closeModal?: () => void
  uploadNew: () => void
  uploadStaffs: () => void
  tableData: any
  loading: boolean
}

const BulkUploadPreview = ({ closeModal, uploadNew, tableData, uploadStaffs, loading }: BulkProps) => {
  return (
    <Modal
      title={
        <>
          Invite Staff
          <span className="text-sm text-white px-3 py-1 rounded-full bg-success-light ml-2">
            {loading ? "Uploading" : `${tableData?.length} rows found`}
          </span>
        </>
      }
      position="modal-center"
      width="min-w-[96%]"
      closeModal={closeModal}
    >
      {loading ? (
        <div className="flex justify-center items-center flex-col py-10">
          <FadeLoader color="#282828" />
          <p className="text-grey-250 font-semibold mt-6">20% Uploaded</p>
          <p className="text-paragraph text-sm text-center mt-2">
            Please wait while we upload your file, depending
            <br /> on the file size, completion time may vary.
          </p>
        </div>
      ) : (
        <div>
          <Table headers={uploadPreviewHeaders} data={tableData} loading={false} paginateData={false}>
            <>
              {tableData?.map((item: any) => (
                <tr data-testid={item?.staff_number} key={item?.staff_number} className="cc-custom-table-data-row">
                  <td className="flex items-center capitalize w-max">
                    {item?.first_name} {item.last_name}
                  </td>
                  <td className="w-max">{item?.role}</td>
                  <td className="w-max">{item?.email}</td>
                  <td className="w-max">{item.user_type}</td>
                  <td>
                    <p className="w-max">{item?.staff_number}</p>
                  </td>
                  <td>
                    <p className="w-max">{item?.phone_number ? `0${item?.phone_number}` : "N/A"}</p>
                  </td>
                </tr>
              ))}
            </>
          </Table>
          <div className="px-8 py-4 border-t border-neutral-10 flex justify-between items-center">
            <div className="flex space-x-2">
              <Button
                theme="primary"
                className="min-w-[105px] mr-[8px]"
                size="44"
                onClick={uploadStaffs}
                loading={loading}
              >
                Send Invites
              </Button>
              <Button theme="transparent" className="w-[105px] px-[30px]" type="button" onClick={closeModal} size="44">
                Discard
              </Button>
            </div>
            <p className="text-sm text-paragraph">
              Need to change file?
              <span className="text-primary-actions cursor-pointer ml-1" onClick={uploadNew}>
                Upload New
              </span>
            </p>
          </div>
        </div>
      )}
    </Modal>
  )
}

export default BulkUploadPreview
