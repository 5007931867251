export const coResponsibilityheader = [
    {
        name: 'co-responsibility id/intervention name',
        sort: false,
        action: null
    },
    {
      name: 'base amount',
      sort: false,
      action: null
    },
    {
        name: 'top-up amount',
        sort: false,
        action: null
    },
    {
        name: 'no of beneficia..',
        sort: false,
        action: null
    },
    {
        name: 'mode of payme..',
        sort: false,
        action: null
    },
    {
        name: 'date created',
        sort: false,
        action: null
    },
    {
        name: 'status',
        sort: false,
        action: null
    },
]

export const beneficiaryHeader = [
    {
      name: "Intervention Title",
      sort: false,
      action: null,
    },
    {
      name: "Base Amount",
      sort: false,
      action: null,
    },
    {
      name: "Top Up Amount",
      sort: false,
      action: null,
    },
    {
      name: "status",
      sort: false,
      action: null,
    },
    {
      name: "Date Created",
      sort: false,
      action: null,
    },
    {
      name: "Payment Cycle",
      sort: false,
      action: null,
    }
  ]