import { Icon } from "@iconify/react"
import { Breadcrumb } from "components/core"
import useCreateGrievance from "./useCreateGrievance"
import { createGrievanceSteps } from "../../../constants/grievances"
import BasicInformation from "components/pages/Grievance/CreateGrievance/BasicInformation/BasicInformation"
// import AssignOfficer from "components/pages/Grievance/CreateGrievance/AssignOfficer/AssignOfficer"
import ReviewGrievance from "components/pages/Grievance/CreateGrievance/Review/Review"

const CreateGrievance = () => {
  const {
    breadCrumbs,
    step,
    view,
    handleChange,
    formData,
    proceed,
    setFormData,
    isLoadingCreateGrievance,
    handleSubmit,
  } = useCreateGrievance()

  const views = {
    "Basic Information": (
      <BasicInformation
        formData={formData}
        handleChange={handleChange}
        // proceed={() => proceed("Assign GRM Officer", "next")}
        proceed={() => proceed("Review", "next")}
        setFormData={setFormData}
      />
    ),
    // "Assign GRM Officer": (
    //   <AssignOfficer
    //     formData={formData}
    //     handleChange={handleChange}
    //     proceed={() => proceed("Review", "next")}
    //     back={() => proceed("Basic Information", "prev")}
    //     setFormData={setFormData}
    //   />
    // ),
    Review: (
      <ReviewGrievance
        formData={formData}
        back={() => proceed("Basic Information", "prev")}
        // back={() => proceed("Assign GRM Officer", "prev")}
        isLoading={isLoadingCreateGrievance}
        handleSubmit={handleSubmit}
        setFormData={setFormData}
      />
    ),
  }

  const View = views[view as keyof typeof views]

  return (
    <main data-testid="create-grievance" className="overflow-y-hidden h-[82vh]">
      <div className="border-b pb-3 flex items-center">
        <div>
          <Breadcrumb options={breadCrumbs} showBack={true} />
          <h1 className="text-headers text-2xl font-bold">New Grievance</h1>
        </div>
      </div>

      <div className="mt-10 flex">
        <section className="bg-[#FAFAFA] border border-grey-200 py-4 pl-4 pr-8 w-[215px] h-fit rounded-lg shrink-0">
          <p className="text-headers list-none font-semibold mb-4">Sections</p>
          <ol className="text-sm leading-7 font-medium">
            {createGrievanceSteps.map((item, index) => (
              <li
                key={item}
                className={`${item === view || step > index ? "text-primary-actions" : "text-body"}
                  ${step - 1 && step > index + 1 ? "flex items-center" : "ml-1"} list-none`}
              >
                {step - 1 && step > index + 1 ? (
                  <Icon icon="lets-icons:check-fill" className="mr-2 text-lg" />
                ) : (
                  <span className="mr-2">{index + 1}.</span>
                )}
                {item}
              </li>
            ))}
          </ol>
        </section>
        <section className="h-screen overflow-y-scroll overflow-x-hidden pb-[400px] px-[4px] no-scrollbar ml-[77px] min-w-[633px]">
          <p className="text-headers font-semibold mb-2">{view}</p>
          <p className="text-body text-sm">
            Ensure that all information provided here are verified and correct before proceeding
          </p>
          <div className="mt-8">{view && View}</div>
        </section>
      </div>
    </main>
  )
}

export default CreateGrievance
