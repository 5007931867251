import { useParams } from "react-router-dom"
import { useTable } from "hooks"
import { useSelector } from "react-redux"
import { addCommasToNumbers } from "utils/addCommasToNumbers"
import { useGetTransactionBatchesQuery } from "services/api/paymentSlice"

export default function useViewTransaction() {
  const { id } = useParams()

  const transactionInfo = useSelector((state: any) => state?.transactions)

  const breadCrumbs = [{ title: "Transactions" }, { title: `Transaction Batch` }]

  const { params, changePage, selectLimit } = useTable()

  const transactionInfoTitle = transactionInfo?.narration

  const transactionInfoData = [
    transactionInfo?.schedule_reference,
    addCommasToNumbers(Number(transactionInfo?.transaction_batch_count ?? 0)),
    addCommasToNumbers(Number(transactionInfo?.amount)),
    addCommasToNumbers(Number(transactionInfo?.beneficiaries_count)),
  ]

  const { data: transactionsBatches, isFetching: isFetchingTransactionBatches } = useGetTransactionBatchesQuery({
    id,
    params: {
      page: params?.page,
      limit: params?.limit,
    },
  })

  return {
    breadCrumbs,
    transactionInfoData,
    params,
    changePage,
    selectLimit,
    id,
    isFetchingTransactionBatches,
    transactionsBatches,
    transactionInfoTitle,
  }
}
