import React from "react"
import { Button, Modal } from "components/core"
import { AddBatchProps } from "types/data"
import RadioButton from "components/core/RadioButton/RadioButton"
import useMineUpdateData from "components/pages/DataMining/MineData/MineUpdateData/useMineUpdateData"
import { motion } from "framer-motion"
import { RenderIf } from "components/hoc"
import UploadDataCard from "../MineData/UploadDataCard"

export const AddBatch = ({ onClose, selectedAction, selectedData, refetchMineHistory }: AddBatchProps) => {
  const {
    formData,
    setFormData,
    currentTab,
    setCurrentTab,
    isAddingBatch,
    householdFiles,
    memberFiles,
    handleFileUpload,
    handleFileDelete,
    handleAddBatch,
  } = useMineUpdateData(selectedData?.reference, selectedAction, onClose, refetchMineHistory)
  const uploadTypes = ["File Upload", "API"]
  const tabs = [{ title: "Household" }, { title: "Members" }]

  return (
    <>
      <Modal title={"Add Batch"} position="modal-right" width="w-[432px]" closeModal={onClose}>
        <form className="relative" onSubmit={(e) => handleAddBatch(e, selectedData?.reference, onClose)}>
          <div className="modal-right-body">
            <div>
              <div className="pb-[24px] border-b border-b-[#E6E6E6] mb-[24px]">
                <div>
                  <div className="flex justify-between">
                    <div className="text-[#1F1F1F] font-[600] truncate max-w-[220px]">{selectedData?.label}</div>
                    <div className="border-[#E6E6E6] border-[1px] rounded-[24px] text-[12px] text-[#5C5C5C] font-[500] py-[4px] px-[8px]">
                      {selectedData?.batches?.length ?? 0} Batches Added
                    </div>
                  </div>
                  <div className="text-[14px] text-[#5C5C5C] font-[400] mt-2">{selectedData?.description}</div>
                </div>
                <div className="mt-6">
                  <p className="text-body font-semibold mb-3">Select type</p>
                  <div className="flex space-x-4">
                    {uploadTypes.map((item: string) => (
                      <RadioButton
                        key={item}
                        name={item}
                        label={item}
                        checked={item === formData.type}
                        value={formData.type}
                        onChange={() => setFormData({ ...formData, type: item })}
                        size="36"
                      />
                    ))}
                  </div>
                  <RenderIf condition={formData.type === "File Upload"}>
                    <div className="w-full border-b-[1.5px] border-b-grey-200 mt-8">
                      {tabs.map((tab) => (
                        <motion.button
                          type="button"
                          key={tab.title}
                          className={`text-sm font-normal mr-[24px] pt-[8px] pb-[16px] px-[16px] ${
                            currentTab === tab.title ? "selected-tab" : "unselected-tab"
                          }`}
                          onClick={() => setCurrentTab(tab.title)}
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{ duration: 0.3 }}
                        >
                          {tab.title}
                        </motion.button>
                      ))}
                    </div>
                    <div className="pb-2">
                      <UploadDataCard
                        handleFileUpload={handleFileUpload}
                        householdFiles={householdFiles}
                        memberFiles={memberFiles}
                        currentTab={currentTab}
                        handleFileDelete={handleFileDelete}
                      />{" "}
                    </div>
                  </RenderIf>
                </div>
              </div>
            </div>
          </div>

          <div className="modal-right-button-container">
            <Button
              theme="primary"
              className="min-w-[105px] mr-[8px] p-[8px]"
              type="submit"
              loading={isAddingBatch}
              disabled={isAddingBatch || householdFiles?.length === 0 || memberFiles?.length === 0}
            >
              Add
            </Button>
            <Button theme="transparent" className="w-[105px] px-[30px]" type="button" onClick={onClose}>
              Close
            </Button>
          </div>
        </form>
      </Modal>
    </>
  )
}
