import { Button, Input, Modal } from "components/core"
import { UpdateBeneficiaryProps } from "types/data"
import { RenderIf } from "components/hoc"
import { MiningStatus } from "components/pages/DataMining/MiningStatus/MiningStatus"
import UploadBeneficiaryCard from "./UploadBeneficiaryCard"
import useUpdateBeneficiary from "./useUpdateBeneficiary"

export const UpdateBeneficiary = ({
  onCloseUpdate,
  setOpenPercentageModal,
  setOpenCompleteUpdateModal,
  setUpdateResponse,
}: UpdateBeneficiaryProps) => {
  const {
    formData,
    handleChange,
    handleSubmitBtn,
    handleFileUpload,
    validateFormData,
    handleFileDelete,
    isUpdating,
    uploadedFile,
  } = useUpdateBeneficiary(onCloseUpdate, setOpenCompleteUpdateModal, setUpdateResponse)

  const errors = validateFormData()
  return (
    <>
      <Modal title={"Update Beneficiary Details"} position="modal-right" width="w-[432px]" closeModal={onCloseUpdate}>
        <RenderIf condition={!isUpdating}>
          <form onSubmit={(e) => handleSubmitBtn(e, setOpenPercentageModal)} className="relative">
            <div className="modal-right-body">
              <Input
                label="Title"
                type="text"
                id="title"
                name="title"
                placeholder="Enter Document Title"
                value={formData?.title}
                onChange={handleChange}
                className="mb-[24px]"
              />
              <p className="text-body text-[14px] font-medium mb-3">Upload File</p>
              <div className="pb-2">
                <UploadBeneficiaryCard
                  handleFileUpload={handleFileUpload}
                  uploadedFile={uploadedFile}
                  handleFileDelete={handleFileDelete}
                />
              </div>
            </div>

            <div className="modal-right-button-container">
              <Button
                theme="primary"
                className="min-w-[105px] mr-[8px] p-[10px]"
                type="submit"
                loading={isUpdating}
                disabled={errors?.length > 0}
              >
                Update data
              </Button>
              <Button theme="transparent" className="w-[105px] px-[30px]" type="button" onClick={onCloseUpdate}>
                Discard
              </Button>
            </div>
          </form>
        </RenderIf>
      </Modal>
      <RenderIf condition={isUpdating}>
        <Modal
          closeModal={() => {
            onCloseUpdate()
          }}
          title={"Update Data"}
          position="modal-right"
          width="w-[432px]"
        >
          <div className="relative">
            <MiningStatus isUpdating={isUpdating} />
          </div>
        </Modal>
      </RenderIf>
    </>
  )
}
