import { Icon } from "@iconify/react"
import { addCommasToNumbers } from "utils/addCommasToNumbers"
import useCourses from "pages/training/courses/useCourses"
import { RenderIf } from "components/hoc"
import HeaderLoader from "components/core/Loader/HeaderLoader/HeaderLoader"

const CourseStatistics = () => {
  const { isLoadingStatistics, statisticsData } = useCourses()

  return (
    <div className="border-y mt-3 py-6 flex flex-col gap-6" data-testid="course-stats">
      <RenderIf condition={isLoadingStatistics}>
        <HeaderLoader />
      </RenderIf>
      <RenderIf condition={!isLoadingStatistics}>
        <div className="flex w-full flex-wrap gap-10">
          {statisticsData?.map((statistic) => (
            <div key={statistic.title} className="flex items-center gap-4">
              <div>
                <div className="w-10 h-10 grid place-items-center bg-grey-100 rounded-full">
                  <Icon icon={`${statistic.icon}`} className="w-6 h-6 text-body" />
                </div>
              </div>

              <div className="grid">
                <h1 className="text-body text-sm">{statistic.title}</h1>
                <p className="text-headers text-sm">{addCommasToNumbers(statistic.count)}</p>
              </div>
            </div>
          ))}
        </div>
      </RenderIf>
    </div>
  )
}

export default CourseStatistics
