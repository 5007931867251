import { Avatar, Breadcrumb } from "components/core"
import useViewBeneficiary from "./useViewBeneficiary"
import BeneficiaryDetailsCard from "components/pages/Beneficiary/ViewBeneficiary/BeneficiaryDetailsCard"
import React from "react"
import { motion } from "framer-motion"
import { RenderIf, Restricted } from "components/hoc"
import getFirstTwoNames from "utils/getFirstTwoNames"
import { BeneficiaryDetailsCardLoader } from "components/pages/Beneficiary/ViewBeneficiary/loader"
import { useNavigate } from "react-router-dom"
import InterventionHistory from "./InterventionHistory"
import PaymentHistory from "./PaymentHistory"
import { AllPermissions, PermissionModule } from "types/permission"

export default function ViewBeneficiary() {
  const {
    breadCrumbs,
    date,
    setDate,
    currentTab,
    beneficiary,
    isFetchingBeneficiary,
    interventions,
    isFetchingInterventions,
    handleSearch,
    changePage,
    statusOptions,
    selectedStatus,
    setSelectedStatus,
    exportBeneficiaries,
    isExporting,
    setParams,
    params,
    tabs,
    beneficiaryPaymentHistory,
    isFetchingPaymentHistory,
    exportPaymentHistory,
    isExportingLoading,
    handleSearchPayment,
    paramsHistory,
    changePageHistory,
    setParamsHistory,
    interventionStatus,
    selectedPaymentStatus,
    setSelectedPaymentStatus,
    statusPaymentOptions,
  } = useViewBeneficiary()

  const navigate = useNavigate()

  const handleTabChange = (tabPath: string) => {
    navigate(`?category=${tabPath}`)
    setParams({ ...params, search: "", page: 1 })
    setParamsHistory({ ...paramsHistory, search: "", page: 1 })
    setDate({ startDate: null, endDate: null })
    setSelectedStatus([])
    setSelectedPaymentStatus([])
  }

  const { BENEFICIARIES } = PermissionModule
  const { VIEW_SINGLE_BENEFICIARY } = AllPermissions

  return (
    <Restricted module={BENEFICIARIES} permission={VIEW_SINGLE_BENEFICIARY} showError={true}>
      <main>
        <Breadcrumb options={breadCrumbs} showBack={true} goBackRoute={"/beneficiary?category=banked-beneficiary"} />
        <div className="mt-6 flex gap-4 mb-8">
          <div className="h-full">
            <Avatar image={beneficiary?.image ?? beneficiary?.name} size="97" />
          </div>
          <div>
            <h3 className="text-headers font-semibold text-[24px] capitalize">
              {getFirstTwoNames(beneficiary?.name) ?? "-"}
            </h3>
            <div className="text-[#454545] bg-grey-100 text-[13px] w-fit py-1 px-2 rounded-[24px] mt-2">
              <RenderIf
                condition={currentTab === "banked-intervention-history" || currentTab === "banked-payment-history"}
              >
                Verified Beneficiary
              </RenderIf>
              <RenderIf condition={currentTab === "unbanked-beneficiary"}>Unverified Beneficiary</RenderIf>
            </div>
            <p className="text-[#A1A1A1] text-xs mt-2">{beneficiary?.beneficiary_id ?? beneficiary?.household_id}</p>
          </div>
        </div>

        <RenderIf condition={isFetchingBeneficiary}>
          <BeneficiaryDetailsCardLoader />
        </RenderIf>

        <RenderIf condition={!isFetchingBeneficiary && Object.keys(beneficiary || {}).length > 0}>
          {beneficiary && <BeneficiaryDetailsCard currentTab={currentTab} beneficiary={beneficiary} />}
        </RenderIf>

        <RenderIf condition={currentTab === "banked-intervention-history" || currentTab === "banked-payment-history"}>
          <div className="flex justify-between my-[24px]">
            <div className="w-full border-b-[1.5px] border-b-grey-200">
              {tabs.map((tab) => (
                <motion.button
                  key={tab.title}
                  disabled={currentTab === tab.path}
                  className={`text-sm font-normal mr-[24px] pt-[8px] pb-[16px] px-[16px] ${
                    currentTab === tab.path ? "selected-tab" : "unselected-tab"
                  }`}
                  onClick={() => handleTabChange(tab.path)}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.3 }}
                >
                  {tab.title}
                </motion.button>
              ))}
            </div>
          </div>

          <RenderIf condition={currentTab === "banked-intervention-history"}>
            <InterventionHistory
              date={date}
              setDate={setDate}
              interventions={interventions}
              isFetchingInterventions={isFetchingInterventions}
              handleSearch={handleSearch}
              changePage={changePage}
              statusOptions={statusOptions}
              selectedStatus={selectedStatus}
              setSelectedStatus={setSelectedStatus}
              exportBeneficiaries={exportBeneficiaries}
              isExporting={isExporting}
              interventionStatus={interventionStatus}
            />
          </RenderIf>

          <RenderIf condition={currentTab === "banked-payment-history"}>
            <PaymentHistory
              date={date}
              setDate={setDate}
              changePage={changePageHistory}
              statusOptions={statusPaymentOptions}
              selectedStatus={selectedPaymentStatus}
              setSelectedStatus={setSelectedPaymentStatus}
              beneficiaryPaymentHistory={beneficiaryPaymentHistory}
              isFetchingPaymentHistory={isFetchingPaymentHistory}
              exportPaymentHistory={exportPaymentHistory}
              isExportingLoading={isExportingLoading}
              handleSearchPayment={handleSearchPayment}
            />
          </RenderIf>
        </RenderIf>
      </main>
    </Restricted>
  )
}
