import { Icon } from "@iconify/react"
import { Button } from "components/core"
import { FileUploader } from "react-drag-drop-files"
import { toast } from "react-toastify"
import { RenderIf } from "components/hoc"
import { ReactComponent as Folder } from "assets/svg/folder.svg"
import GroupMemberTemplate from "assets/templates/GroupMembersTemplate.csv"
import useMultipleMember from "./useMultipleMember"

interface Props {
  onClose: () => void
}

const fileTypes = ["csv"]

export const MultipleMember = ({ onClose }: Props) => {
  const state = useMultipleMember({ onClose })

  return (
    <form className="relative" onSubmit={state.submit}>
      <div className="modal-right-body !h-[75vh]">
        <RenderIf condition={!state.file?.name}>
          <FileUploader
            handleChange={state.handleImageDrop}
            onTypeError={(err: any) => toast.error(err)}
            name="file"
            types={fileTypes}
          >
            <div className="h-60 flex flex-col items-center justify-center border border-primary-actions rounded-md bg-primary-30 cursor-pointer">
              <Folder />
              {state.file?.name ? (
                <div className="mt-3 flex justify-center items-center flex-col">
                  <p className="text-base font-semibold text-center mb-3 overflow-x-scroll break-all px-6 max:w-3/5">
                    {state.file?.name}
                  </p>
                  <div className="border border-neutral-200 text-body rounded py-1.5 px-3 text-xs">
                    Choose another file
                  </div>
                </div>
              ) : (
                <div className="text-sm text-body text-center mt-6">
                  <p>
                    Drag or <span className="text-primary-actions">choose file</span> to upload
                  </p>
                  <p>Only .csv allowed and must not exceed 5mb</p>
                </div>
              )}
            </div>
          </FileUploader>
        </RenderIf>
        <RenderIf condition={state.file?.name}>
          <div className="border border-grey-200 p-[8px] rounded-[6px] flex justify-between">
            <p className="text-sm text-body">{state.file?.name}</p>
            <button type="button" onClick={() => state.setFile(null)}>
              <Icon icon="ph:trash" className="w-[18px] h-[19.5px] text-paragraph" />
            </button>
          </div>
        </RenderIf>
      </div>
      <div className="absolute bottom-0 left-0 w-full">
        <div className="bg-success-lightest py-4 px-8 border-t border-grey-200 w-full">
          <p className="text-sm text-center">
            <a href={GroupMemberTemplate} className="text-primary-actions" download="GroupMemberTemplate.csv">
              Download Template
            </a>{" "}
            to upload Members here
          </p>
        </div>
        <div className="flex items-center pt-[12px] px-[32px]">
          <Button
            theme="primary"
            size="44"
            className="min-w-[105px] mr-[8px] capitalize"
            disabled={!state?.file}
            loading={state.loading}
          >
            Create Members
          </Button>
          <Button
            theme="transparent"
            size="44"
            className="min-w-[105px] mr-[8px] capitalize"
            type="button"
            disabled={state.loading}
            onClick={onClose}
          >
            Discard
          </Button>
        </div>
      </div>
    </form>
  )
}
