export const staffMembersHeaders = [
    {
        name: 'full name',
        sort: false,
        action: null
    },
    {
        name: 'Email address',
        sort: false,
        action: null
    },
    {
        name: 'added on',
        sort: false,
        action: null
    },
    {
        name: 'Status',
        sort: false,
        action: null
    },
    {
        name: '',
        sort: false,
        action: null
    },
]