import { useSelector } from "react-redux";

// Check if user has permission via a boolean
export const usePermission = () => {
  const { permissions, role } = useSelector((state) => state.auth)

  const hasPermission = (module = '', permission = '') => {
    if (role !== 'super-admin') {
      const checkPermission = permissions
        ?.filter((p) => p?.action?.length > 0)
        ?.some((p) => p?.module?.toLowerCase() === module?.toLowerCase() && p?.action?.includes(permission));
      return checkPermission;
    }
    return true
  }

  const hasModulePermission = (module = '') => {
    if (role !== 'super-admin') {
      const checkPermission = permissions?.find((p) => p?.module?.toLowerCase() === module?.toLowerCase())?.action || []
      return !!checkPermission?.length;
    }
    return true
  }

  return { hasPermission, hasModulePermission };
}