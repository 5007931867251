import React from "react"
import { Icon } from "@iconify/react"
import { Input, Dropdown, Textarea } from "components/core"
import RadioButton from "components/core/RadioButton/RadioButton"
import { TrainingType } from "mocks/training"
import { RenderIf } from "components/hoc"
import { toast } from "react-toastify"

const SingleTrainingUpload = ({
  formData,
  setFormData,
  courseDropdownElements,
  isFetchingCourses,
  addNewVendor,
  handleDeleteVendor,
}: any) => {
  const currentDate = new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split("T")[0]

  const courseOptions = courseDropdownElements ? [{ label: "All", value: "All" }, ...courseDropdownElements] : []

  const handleVendorChange = (index: number, value: string) => {
    const newVendors = [...formData.training_vendor]
    newVendors[index] = value
    setFormData({ ...formData, training_vendor: newVendors })
  }

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newStartDate = e.target.value
    if (new Date(newStartDate) < new Date(currentDate)) {
      toast.error("Start date cannot be before today")
    } else if (formData.end_date && new Date(newStartDate) > new Date(formData.end_date)) {
      toast.error("Start date cannot be after end date")
    } else {
      setFormData({ ...formData, start_date: newStartDate })
    }
  }

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEndDate = e.target.value
    if (new Date(newEndDate) < new Date(currentDate)) {
      toast.error("End date cannot be before today")
    } else if (formData.start_date && new Date(newEndDate) < new Date(formData.start_date)) {
      toast.error("End date cannot be before start date")
    } else {
      setFormData({ ...formData, end_date: newEndDate })
    }
  }

  const handleCourseSelection = (selectedCourses: any) => {
    setFormData((prevFormData: any) => {
      let updatedCourseIds = [...(prevFormData.course_id || [])]

      selectedCourses.forEach((selectedCourse: any) => {
        const courseExists = updatedCourseIds.includes(selectedCourse.value)

        if (courseExists) {
          updatedCourseIds = updatedCourseIds.filter((id) => id === selectedCourse.value)
        } else {
          updatedCourseIds.push(selectedCourse.value)
        }
      })

      return {
        ...prevFormData,
        course_id: updatedCourseIds,
      }
    })
  }

  return (
    <main className="flex flex-col gap-y-6" data-testid="single-training-upload">
      <Dropdown
        label="Participant Type"
        options={TrainingType}
        selected={formData?.training_type}
        onSelect={(data: any) => {
          setFormData({ ...formData, training_type: data?.value })
        }}
      />

      <div className="">
        <p className="text-headers font-medium text-sm">Training Unit</p>
        <RenderIf condition={formData?.training_type === ""}>
          <p className="text-sm font-medium text-body">Please select a participant type</p>
        </RenderIf>
        <RenderIf condition={formData?.training_type === "staff"}>
          <div className="flex gap-3 mt-3">
            <RadioButton
              label="NTOT"
              name="ntot"
              checked={formData?.training_unit === "ntot"}
              onChange={() => setFormData({ ...formData, training_unit: "ntot" })}
            />
            <RadioButton
              label="TOT"
              name="TOT"
              checked={formData?.training_unit === "tot"}
              onChange={() => setFormData({ ...formData, training_unit: "tot" })}
            />

            <RadioButton
              label="CTF"
              name="CTF"
              checked={formData?.training_unit === "ctf"}
              onChange={() => setFormData({ ...formData, training_unit: "ctf" })}
            />
          </div>
        </RenderIf>
        <RenderIf condition={formData?.training_type === "citizen"}>
          <div className="flex gap-3 mt-3">
            <RadioButton
              label="Beneficiary"
              name="beneficiary"
              checked={formData?.training_unit === "beneficiary"}
              onChange={() => setFormData({ ...formData, training_unit: "beneficiary" })}
            />

            <RadioButton
              label="Livelihood"
              name="livelihood"
              checked={formData?.training_unit === "livelihood"}
              onChange={() => setFormData({ ...formData, training_unit: "livelihood" })}
            />
          </div>
        </RenderIf>
      </div>

      <div>
        <h1 className="text-sm font-medium text-headers mb-3">Training Type</h1>
        <div className="flex gap-4">
          <RadioButton
            label="Hard Skills"
            name="hard-skills"
            checked={formData?.skill_type === "hard skills"}
            onChange={() => setFormData({ ...formData, skill_type: "hard skills" })}
          />
          <RadioButton
            label="Soft Skills"
            name="soft-skills"
            checked={formData?.skill_type === "soft skills"}
            onChange={() => setFormData({ ...formData, skill_type: "soft skills" })}
          />
        </div>
      </div>

      <Input
        name="name"
        value={formData?.name}
        label="Training Name"
        id="name"
        type="text"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setFormData({ ...formData, name: e.target.value })
        }}
      />

      <Dropdown
        label="Select Course"
        // selected={formData.course_id}
        options={courseOptions ?? []}
        multiple={true}
        loading={isFetchingCourses}
        onSelect={handleCourseSelection}
        count={true}
      />
      <Input
        name="Attendance Required"
        value={formData?.attendance_required}
        label="Attendance Required"
        id="attendance_required"
        type="number"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setFormData({ ...formData, attendance_required: e.target.value })
        }}
      />
      <Textarea
        label="Training Needs"
        rows={6}
        id="training_needs"
        name="training_needs"
        maxLength={320}
        value={formData?.training_needs}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setFormData({ ...formData, training_needs: e.target.value })
        }
      />

      <div className="grid gap-2 grid-cols-2">
        <div>
          <label className="text-sm text-headers mb-[4px] cursor-default font-medium">Start Date</label>
          <input
            type="date"
            className="p-[12px] h-[40px] text-body text-sm w-full outline-0 border hide_tap
                        rounded-[4px] border-grey-200 focus:border-primary-200 placeholder:text-sm
                         bg-white"
            value={formData.start_date || ""}
            onChange={handleStartDateChange}
            placeholder=""
            min={currentDate}
            data-testid="start-date"
          />
        </div>

        <div>
          <label className="text-sm text-headers mb-[4px] cursor-default font-medium">End Date</label>
          <input
            type="date"
            className="p-[12px] h-[40px] text-body text-sm w-full outline-0 border hide_tap
                        rounded-[4px] border-grey-200 focus:border-primary-200 placeholder:text-sm
                         bg-white"
            value={formData.end_date || ""}
            onChange={handleEndDateChange}
            placeholder="end date"
            min={
              formData.start_date
                ? new Date(new Date(formData.start_date).setDate(new Date(formData.start_date).getDate() + 1))
                    .toISOString()
                    .split("T")[0]
                : currentDate
            }
            data-testid="end-date"
          />
        </div>
      </div>
      <div className="flex flex-col gap-2">
        {formData?.training_vendor
          ? formData?.training_vendor?.map((vendor: any, index: number) => (
              <div className="flex w-full space-x-2" key={index}>
                <Input
                  name="Enter Vendor"
                  id={`vendor ${index}`}
                  value={vendor}
                  type="text"
                  className="w-full"
                  label={index === 0 && "Enter Vendor"}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleVendorChange(index, e.target.value)}
                />
                <button
                  className="cursor-pointer"
                  onClick={() => handleDeleteVendor(index)}
                  data-testid={`del-module-${index}`}
                >
                  <Icon icon="ph:x" height={12} className="text-[#ADACB0] w-[12px]" />
                </button>
              </div>
            ))
          : null}
        <button
          className="flex w-full justify-end mt-1 items-center text-xs text-primary-actions gap-1"
          onClick={addNewVendor}
          data-testid="add-vendor-btn"
        >
          <Icon icon="ph:plus" />
          Add New Vendor
        </button>
      </div>
    </main>
  )
}

export default SingleTrainingUpload
