import { useMemo } from "react"
import { useFetchGrmCommentsQuery } from "services/api/grievanceSlice"
import { formatTime } from "utils/timeFormatter"

export default function useGrievanceComments({ data }: any) {
  const { data: grmComments, status } = useFetchGrmCommentsQuery({
    id: data?.grievance_id,
  })

  const comments = useMemo(() => {
    const grievanceComments = grmComments?.grievance_comments || []

    return grievanceComments.map((comment: any) => ({
      text: comment.comment,
      time: comment.created_at ? formatTime(comment.created_at) : "--",
      sender: `${comment.first_name} ${comment.last_name}`,
    }))
  }, [grmComments?.grievance_comments])

  return { isFetchingMessages: status === "pending", comments }
}
