import { Icon } from "@iconify/react"
import { Dropdown, Input } from "components/core"
import { defaultFilter, paymentPSP } from "constants/global"
import { paymentRecurrence } from "constants/intervention"

interface Props {
  state: any
}

export const PaymentStructure = ({ state }: Props) => {
  const { formData, setFormData } = state

  return (
    <div className="mb-[56px]">
      <p className="mb-[8px] font-medium text-sm text-headers">
        <Icon icon="ph:pencil-line" className="inline w-[20px] h-[20px]" /> PAYMENT STRUCTURE
      </p>
      <div className="pl-[24px] w-full mb-[20px] rounded-sm">
        <table className="w-full">
          <thead>
            <tr className="text-left font-medium border text-xs text-body">
              <th className="p-[8px] font-medium border-r text-start w-[270px]">Payment Cycle</th>
              <th className="p-[8px] font-medium border-r text-start w-[270px]">Payment Recurrence</th>
              <th className="p-[8px] font-medium text-start w-[270px]">PSP</th>
            </tr>
          </thead>
          <tbody>
            <tr className="border h-12 text-sm text-body">
              <td className="border-r px-3">
                <Input
                  type="text"
                  id="paymentCycle"
                  name="paymentCycle"
                  // readOnly={loading}
                  placeholder="Enter Cycle"
                  value={formData?.paymentCycle}
                  onChange={state.handleChange}
                  className="!w-full"
                  variant="border-none !p-0 !h-[40px]"
                />
              </td>
              <td className="border-r">
                <Dropdown
                  placeholder="Please Select"
                  defaultOption={formData?.paymentRecurrence}
                  containerVariant="!border-none !shadow-none"
                  wrapperClassName="!mb-0"
                  options={[...defaultFilter, ...paymentRecurrence]}
                  multiple={true}
                  count={true}
                  onSelect={(data: any) => setFormData({ ...formData, paymentRecurrence: data })}
                />
              </td>
              <td className="border-r">
                <Dropdown
                  defaultOption={formData?.psp}
                  placeholder="Please Select"
                  containerVariant="!border-none !shadow-none"
                  wrapperClassName="!mb-0"
                  options={[...defaultFilter, ...paymentPSP]}
                  multiple={true}
                  count={true}
                  onSelect={(data: any) => setFormData({ ...formData, psp: data })}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}
