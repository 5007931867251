import Beneficiary from "pages/beneficiary/Beneficiary"
import UpdateBeneficiaryHistory from "components/pages/Beneficiary/UpdateBeneficiaryHistory/UpdateBeneficiaryHistory"
import ViewBeneficiary from "pages/beneficiary/ViewBeneficiary/ViewBeneficiary"

export const beneficiaryRoutes = [
    {
        path: "beneficiary",
        element: <Beneficiary />,
    },
    {
        path: "beneficiary/beneficiary-update-history",
        element: <UpdateBeneficiaryHistory />,
    },
    {
        path: "beneficiary/:id",
        element: <ViewBeneficiary />,
    },
]