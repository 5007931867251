import { Avatar, Breadcrumb, Filter, Search, Table } from "components/core"
import useViewCoResponsibility from "./useViewCoresponsibility"
import { coResponsibilityHeader } from "constants/coResponsibility"
import { RenderIf } from "components/hoc"
import HeaderLoader from "components/core/Loader/HeaderLoader/HeaderLoader"
import { defaultFilter } from "mocks/staffs"
import { useNavigate } from "react-router-dom"

export default function ViewCoResponsibility() {
  const navigate = useNavigate()
  const {
    breadCrumbs,
    overviewDetails,
    handleSearch,
    changePage,
    selectLimit,
    coResponsibility,
    isFetchingCoResponsibility,
    params,
    setSelectedGender,
    isLoadingCoResponsibility,
    isLoadingIntervention,
    selectedState,
    setSelectedCategory,
    setSelectedState,
    interventionStates,
    //interventionStatesLga,
    isFetchingLga,
    setSelectedLga,
    categories,
    isFetchingEligibility,
    statesLga,
  } = useViewCoResponsibility()

  return (
    <main data-testid="view-coresponsibility">
      <Breadcrumb options={breadCrumbs} showBack={true} />
      <RenderIf condition={isLoadingCoResponsibility || isLoadingIntervention}>
        <HeaderLoader />
      </RenderIf>
      <RenderIf condition={!isLoadingCoResponsibility && !isLoadingIntervention}>
        <>
          <div className="p-6 bg-primary-900 rounded-[8px] mt-3 gap-8 mb-[36px]">
            <div className="flex justify-between border-b-[.3px] border-b-grey-200 pb-[32px]">
              <h3 className="text-white font-semibold text-2xl capitalize">{coResponsibility?.intervention_title}</h3>
            </div>
            <div className="mt-[16px] flex divide-x child-not-last:pr-6 child-not-first:pl-6 ">
              {overviewDetails.map((detail) => (
                <div key={detail.name}>
                  <p className="text-primary-100 text-xs font-medium">{detail?.name}</p>
                  <p className="text-primary-30 text-sm mt-[2px]">{detail?.value ?? "N/A"}</p>
                </div>
              ))}
            </div>
          </div>
          <div>
            <div className="flex items-center justify-between mb-[16px]">
              <div className="flex">
                <Search id="search" name="search" placeholder="Search" onChange={handleSearch} className="mr-[8px]" />
                <Filter
                  title="State"
                  options={[...defaultFilter, ...interventionStates]}
                  multiple={true}
                  setSelectedOption={setSelectedState}
                  containerClass="w-[120px] mr-[8px]"
                />
                {!!selectedState?.length && (
                  <Filter
                    title="LGA"
                    options={[...defaultFilter, ...statesLga]}
                    multiple={true}
                    loading={isFetchingLga}
                    setSelectedOption={setSelectedLga}
                    containerClass="w-[120px] mr-[8px]"
                  />
                )}
                <Filter
                  title="Category"
                  options={[...defaultFilter, ...categories]}
                  multiple={true}
                  setSelectedOption={setSelectedCategory}
                  containerClass="w-[160px] mr-[8px]"
                  loading={isFetchingEligibility}
                />
                <Filter
                  title="Gender"
                  options={[...defaultFilter, { label: "Male", value: "male" }, { label: "Female", value: "female" }]}
                  setSelectedOption={setSelectedGender}
                  containerClass="w-[120px]"
                />
              </div>
            </div>
            <Table
              headers={coResponsibilityHeader}
              data={coResponsibility?.beneficiaries || []}
              onPageChange={changePage}
              selectLimit={selectLimit}
              totalCount={Number(coResponsibility?.no_of_beneficiary || 0)}
              perPage={params?.limit}
              page={Number(coResponsibility?.page || 0)}
              loading={isFetchingCoResponsibility}
            >
              <>
                {coResponsibility?.beneficiaries?.map((item: any) => (
                  <tr
                    data-testid={item?.beneficiary_id}
                    key={item?.beneficiary_id}
                    className="cc-custom-table-data-row cursor-pointer"
                    onClick={() => navigate(`beneficiary/${item?.beneficiary_id}`)}
                  >
                    <td className="flex items-center ">
                      <div className="flex items-center">
                        <Avatar
                          key={item?.beneficiary_id}
                          image={item?.profile_pic || item?.beneficiary_name}
                          alt={item?.beneficiary_name}
                          size="24"
                        />
                        <div className="ml-[12px]">
                          <p title={item?.beneficiary_name} className="max-w-[200px] truncate capitalize">
                            {item?.beneficiary_name}
                          </p>
                          <p className="text-grey-base text-xs">{item?.household_id}</p>
                        </div>
                      </div>
                    </td>
                    <td className="capitalize-first">{item?.state?.name || "N/A"}</td>
                    <td className="capitalize-first">{item?.lga?.name || "N/A"}</td>
                    <td className="capitalize-first">{item?.ward || "N/A"}</td>
                    <td className="capitalize-first">{item?.community || "N/A"}</td>
                    <td>{item?.gender || "N/A"}</td>
                  </tr>
                ))}
              </>
            </Table>
          </div>
        </>
      </RenderIf>
    </main>
  )
}
