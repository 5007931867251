import { singleCourseDetailsHeaders } from "constants/trainings"
import { RenderIf } from "components/hoc"
import HeaderLoader from "components/core/Loader/HeaderLoader/HeaderLoader"

interface SingleCourseProps {
  isLoading: boolean
  courseTitle: string
  detailsData: string[]
}

const SingleCourseInfo = ({ isLoading, courseTitle, detailsData }: SingleCourseProps) => {
  return (
    <div>
      <RenderIf condition={isLoading}>
        <HeaderLoader />
      </RenderIf>
      <RenderIf condition={!isLoading}>
        <div
          data-testid="course-info"
          className="bg-primary-900 text-primary-30 font-normal text-sm min-h-[172px] rounded-lg p-6 flex flex-col"
        >
          <h1 className="text-lg font-semibold pb-8 border-b border-b-grey-200">{courseTitle}</h1>
          <div className="mt-4 grid grid-cols-3 grid-rows-2 gap-y-4">
            {singleCourseDetailsHeaders?.map((header, index) => (
              <div
                className={`flex flex-col gap-[8px]
                      ${index >= 3 ? "border-t-[0.5px] border-[#E6E6E6] pt-4" : ""}
                      ${index % 3 !== 0 ? "border-l-[0.5px] border-[#E6E6E6] pl-4" : ""}`}
                key={header}
              >
                <span className="text-[#C6E4C4] text-xs font-medium">{header}</span>
                <p className="text-primary-30 font-normal text-sm mt-[2px] truncate">{detailsData[index]}</p>
              </div>
            ))}
          </div>
        </div>
      </RenderIf>
    </div>
  )
}

export default SingleCourseInfo
