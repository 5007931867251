import { useGetSingleMineHistoryQuery } from "services/api/dataSlice"

interface UseProgressDialogProps {
  fetchSingleMinedDataId: string
}

export default function useProgressDialog({ fetchSingleMinedDataId }: UseProgressDialogProps) {
  const { data: history, refetch: refetchHistory } = useGetSingleMineHistoryQuery(fetchSingleMinedDataId)

  return {
    history,
    refetchHistory,
  }
}
