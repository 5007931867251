import { Button, Dropdown, Input, Modal } from "components/core"
import React from "react"
import { checkEmptyProperties } from "utils/checkEmptyProperties"
import useAddEditCommunity from "./useAddEditCommunity"

export interface AddEditCommunityProps {
  closeModal?: () => void
  showSuccesModal?: () => void
  action: string
  communityDetails?: any
  nigerianStates: any
}

const AddEditCommunity = ({
  closeModal,
  action,
  communityDetails,
  nigerianStates,
  showSuccesModal,
}: AddEditCommunityProps) => {
  const {
    formData,
    handleChange,
    setFormData,
    lga,
    isFetchingLga,
    isAddingCommunity,
    handleSubmit,
    isUpdatingCommunity,
  } = useAddEditCommunity({ communityDetails, action, closeModal, showSuccesModal })

  return (
    <Modal
      title={`${action} ${action === "Add" ? "New" : ""} Community`}
      position="modal-right"
      width="w-[432px]"
      closeModal={closeModal}
    >
      <form className="mb-6" onSubmit={handleSubmit}>
        <div className="px-6 pt-6 pb-[90px]">
          <Input
            label="Community Name"
            type="text"
            placeholder="Enter Community Name"
            id="title"
            name="title"
            value={formData?.title}
            onChange={handleChange}
            className="mb-[16px]"
          />
          <Dropdown
            label="Community’s State"
            placeholder="-Select State-"
            enableSearchOptions={true}
            selected={formData?.state?.value}
            options={nigerianStates}
            onSelect={(data: any) => {
              setFormData((prevFormData: any) => ({
                ...prevFormData,
                state: data,
              }))
            }}
          />
          <Dropdown
            label="Community’s LGA"
            options={lga}
            clearSelected={!formData.lga.value}
            loading={isFetchingLga}
            enableSearchOptions={true}
            placeholder={isFetchingLga ? "Fetching LGA..." : "-Select LGA-"}
            disabled={!formData?.state?.value || isFetchingLga}
            selected={formData?.lga?.value}
            onSelect={(data: any) => {
              setFormData({ ...formData, lga: data })
            }}
          />
        </div>
        <div className="modal-right-button-container !pb-3">
          <Button
            theme="primary"
            size="44"
            className="min-w-[105px] mr-[8px]"
            loading={isAddingCommunity || isUpdatingCommunity}
            disabled={checkEmptyProperties(formData) || isAddingCommunity || isUpdatingCommunity}
          >
            {action === "Add" ? "Create Community" : "Save Changes"}
          </Button>
          <Button theme="transparent" className="w-[105px] px-[30px]" type="button" onClick={closeModal} size="44">
            Discard
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default AddEditCommunity
