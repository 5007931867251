export const currencies = [
  {
    name: "Naira",
    currency: "NGN",
    symbol: "₦",
    disabled: false,
  },
  {
    name: "US Dollars",
    currency: "USD",
    symbol: "$",
    disabled: true,
  },
  {
    name: "British Pound",
    currency: "GBP",
    symbol: "£",
    disabled: true,
  },
  {
    name: "Euro",
    currency: "EUR",
    symbol: "€",
    disabled: true,
  },
  {
    name: "Chinese Yuan",
    currency: "CN¥",
    symbol: "¥",
    disabled: true,
  },
]
