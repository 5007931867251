import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useCreateGrievanceMutation } from "services/api/grievanceSlice"
import { toast } from "react-toastify"
import { tryCatch } from "utils/tryCatch"

export default function useCreateGrievance() {
  const [step, setStep] = useState<number>(1)
  const [view, setView] = useState<string>("Basic Information")

  const navigate = useNavigate()

  const breadCrumbs = [{ title: "Grievance Redress Mechanism" }, { title: "New Grievance" }]

  const [formData, setFormData] = useState({
    complaint_name: "",
    complaint_type: "",
    complaint_mode: "",
    description: "",
    gender: "",
    // location: "",
    state_id: "",
    state_name: "",
    level: "",
    // assigned_to: "",
    // assigned_to_name: "",
    // assigned_to_email: "",
    // assigned_dept: "",
    category_id: "",
    category_id_name: "",
    sub_category_id_name: "",
    sub_category_id: "",
    complaint_type_name: "",
    zone_name: "",
    zone_id: "",
    lga_id: "",
    lga_name: "",
    ward: "",
    community_name: null,
    community_id: null,
    complaint_resolved_details: null,
    complaint_satisfaction: null,
    resolved: false,
    status: "pending",
    complaint_phone_number: null,
    complaint_address: "",
    nassp: null,
    complaint_feedback: null,
  })

  const [createGrievance, { isLoading: isLoadingCreateGrievance }] = useCreateGrievanceMutation()

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const proceed = (page: string, dir: string) => {
    if (dir === "next") {
      setStep(step + 1)
    } else {
      setStep(step - 1)
    }
    setView(page)
  }

  const handleSubmit = async () => {
    // eslint-disable-next-line no-unused-vars
    const { complaint_type_name, ward, resolved, ...data } = formData

    data.gender = data.gender?.toLowerCase()
    data.level = data.level.toLowerCase()
    data.complaint_mode = data?.complaint_mode?.toLowerCase()

    // Set `complaint_resolved_details` and `complaint_satisfaction` to `null` if `resolved` is false
    if (!resolved) {
      data.complaint_resolved_details = null
      data.complaint_satisfaction = null
      data.complaint_feedback = null
      data.status = "pending"
    } else {
      data.status = "resolved"
    }

    const callback = (res: any) => {
      if ([200, 201].includes(res?.code)) {
        toast.success(res.message)
        navigate("/grievance?tab=activity-log")
      } else {
        toast.error(res.message)
      }
    }

    tryCatch(async () => {
      let res = await createGrievance(data).unwrap()
      callback(res)
    })
  }

  return {
    breadCrumbs,
    step,
    setStep,
    view,
    setView,
    handleChange,
    formData,
    proceed,
    setFormData,
    handleSubmit,
    isLoadingCreateGrievance,
  }
}
