import React from "react"
import PropTypes from "prop-types"
import { usePermission } from "hooks/usePermission/usePermission"

interface Props {
  module: string
  permission: string
  children: any
  showError?: boolean
}

// Check if user has permission via a HOC
export const Restricted = ({ module, permission, children, showError = false }: Props) => {
  const { hasPermission, hasModulePermission } = usePermission()

  if (!permission && module && hasModulePermission(module)) return <>{children}</>
  if (hasPermission(module, permission)) return <>{children}</>
  if (showError) return <p>User does not have access to resource</p>

  return null
}

Restricted.propTypes = {
  permission: PropTypes.string,
  module: PropTypes.string,
  children: PropTypes.element,
}
