import { Button, Dropdown, Input, Modal } from "components/core"
import useAddGroup from "./useAddGroup"
import { duration } from "constants/global"
import { checkEmptyProperties } from "utils/checkEmptyProperties"

interface Props {
  onClose: () => void
  community: any
}

export const AddNewGroup = ({ onClose, community }: Props) => {
  const groupState = useAddGroup({ community, onClose })

  return (
    <Modal title="Add New Group" position="modal-right" width="w-[432px]" closeModal={onClose}>
      <form className="relative" onSubmit={groupState.submit}>
        <div className="modal-right-body !pb-[200px]">
          <Input
            label="Group Name"
            type="text"
            id="groupName"
            name="groupName"
            // readOnly={loading}
            value={groupState.formData?.groupName}
            onChange={groupState.handleChange}
            className="mb-[16px]"
          />
          <Input
            label="Group State"
            type="text"
            id="state"
            name="state"
            // readOnly={loading}
            disabled={true}
            value={groupState.formData?.state}
            className="mb-[16px]"
            onChange={groupState.handleChange}
          />
          <Input
            label="Local Government"
            type="text"
            id="lga"
            name="lga"
            // readOnly={loading}
            disabled={true}
            value={groupState.formData?.lga}
            onChange={groupState.handleChange}
            className="mb-[16px]"
          />
          <Input
            label="Group Ward"
            type="text"
            id="ward"
            name="ward"
            // readOnly={loading}
            // disabled={true}
            value={groupState.formData?.ward}
            onChange={groupState.handleChange}
            className="mb-[16px]"
          />
          <Input
            label="Amount"
            type="text"
            id="amount"
            name="amount"
            // disabled={true}
            value={groupState.formData?.amount}
            onChange={groupState.handleChange}
            className="mb-[16px]"
          />
          <Input
            label="Corporative"
            type="text"
            id="corporative"
            name="corporative"
            // readOnly={loading}
            value={groupState.formData?.corporative}
            onChange={groupState.handleChange}
            className="mb-[16px]"
          />
          <div className="mb-[16px] grid grid-cols-2 gap-[12px] items-end">
            <Input
              label="Duration"
              type="text"
              placeholder="Enter Duration"
              id="duration"
              name="duration"
              // readOnly={loading}
              // disabled={true}
              value={groupState.formData?.duration}
              onChange={groupState.handleChange}
            />
            <Dropdown
              placeholder="-Select Period-"
              options={duration}
              selectInput={true}
              search={false}
              selected={groupState.selectedPeriod?.label}
              containerVariant="h-[48px] w-full"
              wrapperClassName="!mb-0"
              onSelect={(data: any) => {
                groupState.setSelectedPeriod(data)
              }}
            />
          </div>
        </div>
        <div className="absolute bottom-0 left-0 w-full">
          <div className="flex items-center py-[12px] px-[32px]">
            <Button
              theme="primary"
              size="44"
              loading={groupState.isLoading}
              className="min-w-[105px] mr-[8px] capitalize"
              disabled={checkEmptyProperties(groupState.formData) || !groupState.selectedPeriod?.label}
            >
              Create Group
            </Button>
            <Button
              theme="transparent"
              size="44"
              className="min-w-[105px] mr-[8px] capitalize"
              type="button"
              disabled={groupState.isLoading}
              onClick={onClose}
            >
              Discard
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  )
}
