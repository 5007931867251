import { useState } from "react"

interface ItemTableHeader {
  condition: string
  operator: string
  value: string
}

interface Props {
  setFormData: any
  formData: any
  proceed: () => void
}

export default function useSelectionCriteria({ formData, setFormData, proceed }: Props) {
  const [selectionCriteria, setSelectionCriteria] = useState<ItemTableHeader[]>(
    formData?.selection_criteria ?? [
      {
        condition: "",
        operator: "",
        value: "",
      },
    ],
  )

  const handleSelectionCriteria = (name: any, data: any, index: number) => {
    const newPayload: any = [...selectionCriteria]

    newPayload[index] = {
      ...newPayload[index],
      [name]: data,
    }
    //Update the item rowData table
    setSelectionCriteria(newPayload)
  }

  const addTableRows = () => {
    const rowsInput: ItemTableHeader = {
      condition: "",
      operator: "",
      value: "",
    }

    setSelectionCriteria([...selectionCriteria, rowsInput])
  }

  const deleteTableRows = (index: number) => {
    let rows: ItemTableHeader[] = []

    if (selectionCriteria.length > 0) {
      rows = [...selectionCriteria]
      rows.splice(index, 1)
    }
    setSelectionCriteria(rows)
  }

  const nextPage = () => {
    setFormData({ ...formData, selection_criteria: selectionCriteria })
    proceed()
  }

  const disableBtn =
    selectionCriteria.length > 0 && selectionCriteria.every((obj) => Object.values(obj).every((value) => value !== ""))

  return {
    deleteTableRows,
    addTableRows,
    disableBtn,
    selectionCriteria,
    nextPage,
    handleSelectionCriteria,
  }
}
