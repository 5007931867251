import { Button } from "components/core"
import { ReactComponent as Close } from "assets/icons/close.svg"
import { ReactNode } from "react"

interface Props {
  close: () => void
  children: ReactNode
  name: string
  generateReport?: () => void
  loading?: boolean
}

export const ReportPreviewLayout = ({ close, children, name, generateReport, loading = false }: Props) => {
  return (
    <div className="absolute top-0 left-0 bg-white z-50 w-full py-[24px] min-h-screen">
      <div className="pb-[12px] px-[80px]">
        <button disabled={loading}>
          <Close className="rounded-full" onClick={close} />
        </button>
      </div>
      <hr className="mb-[40px]" />
      <div className="max-w-[633px] w-full mx-auto">
        <h2 className="text-2xl text-headers mb-[16px] font-semibold">Report Preview</h2>
        <div className="flex">
          <p className="text-base text-body mr-[8px]">Report Name:</p>
          <p className="text-base text-headers">{name}</p>
        </div>
        <hr className="mt-[24px] mb-[16px]" />
        <div className="mb-[72px]">{children}</div>
        <Button
          theme="primary"
          size="44"
          className="w-[171px]"
          type="button"
          onClick={generateReport}
          loading={loading}
        >
          Generate Report
        </Button>
      </div>
    </div>
  )
}
