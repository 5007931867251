import { Icon } from "@iconify/react"
import { Dropdown, Input } from "components/core"
import { paymentTypes } from "constants/intervention"
import { useMemo } from "react"
import { currencies } from "constants/currency"

interface Props {
  state: any
}

export const PaymentStructure = ({ state }: Props) => {
  const { formData, setFormData, handleChange } = state

  const currencyOptions = useMemo(() => {
    return currencies.map((item: any) => {
      return { label: item.name, value: item.name }
    })
  }, [])
  return (
    <div className="mb-[56px]">
      <p className="mb-[8px] font-medium text-sm text-headers">
        <Icon icon="ph:pencil-line" className="inline w-[20px] h-[20px]" /> PAYMENT STRUCTURE
      </p>
      <div className="pl-[24px] w-full mb-[20px] rounded-sm">
        <table className="w-full">
          <thead>
            <tr className="text-left border text-xs text-body">
              <th className="p-[8px] border-r font-medium text-start w-[270px]">Eligibility Amount</th>
              <th className="p-[8px] border-r font-medium text-start w-[270px]">Payment Type</th>
              <th className="p-[8px] border-r font-medium text-start w-[270px]">Currency</th>
              <th className="p-[8px] text-start font-medium w-[270px]">Total Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr className="border h-12 text-sm text-body">
              <td className="px-3 border-r">
                <div className="flex">
                  <Input
                    type="number"
                    id="eligibilityAmountFrom"
                    name="fromEligibilityAmount"
                    placeholder="From"
                    value={formData?.fromEligibilityAmount}
                    onChange={handleChange}
                    className="!w-full border-r"
                    variant="border-none !p-0 !h-[40px]"
                  />
                  <Input
                    type="number"
                    id="eligibilityAmountTo"
                    name="toEligibilityAmount"
                    placeholder="To"
                    value={formData?.toEligibilityAmount}
                    onChange={handleChange}
                    className="!w-full pl-3"
                    variant="border-none !p-0 !h-[40px]"
                  />
                </div>
              </td>
              <td className="border-r">
                <Dropdown
                  selected={formData?.paymentType}
                  placeholder="Please Select"
                  containerVariant="!border-none !shadow-none"
                  wrapperClassName="!mb-0"
                  options={paymentTypes}
                  search={false}
                  multiple={true}
                  onSelect={(data: any) => setFormData({ ...formData, paymentType: data })}
                />
              </td>
              <td className="border-r">
                <Dropdown
                  placeholder="Please Select"
                  selected={formData?.currency}
                  containerVariant="!border-none !shadow-none"
                  wrapperClassName="!mb-0"
                  options={currencyOptions}
                  multiple={true}
                  search={false}
                  onSelect={(data: any) => setFormData({ ...formData, currency: data })}
                />
              </td>
              <td className="px-3">
                <div className="flex">
                  <Input
                    type="number"
                    id="totalAmountFrom"
                    name="fromTotalAmount"
                    placeholder="From"
                    value={formData?.fromTotalAmount}
                    onChange={handleChange}
                    className="!w-full border-r"
                    variant="border-none !p-0 !h-[40px]"
                  />
                  <Input
                    type="number"
                    id="totalAmountTo"
                    name="toTotalAmount"
                    placeholder="To"
                    value={formData?.toTotalAmount}
                    onChange={handleChange}
                    className="!w-full pl-3"
                    variant="border-none !p-0 !h-[40px]"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}
