import { ReactNode, useState } from "react"
import { ReactComponent as CaretRight } from "assets/icons/caret-right.svg"
import { ReactComponent as CaretUp } from "assets/icons/caret-up.svg"
import { ReactComponent as ArrowBendDownRight } from "assets/icons/arrow-bend-down-right.svg"
import { AnimatePresence, motion } from "framer-motion"
import { Status } from "../Status/Status"

interface AccordionProps {
  children: ReactNode
  display?: boolean
  className: string
  variant?: string
  header: ReactNode
  status?: string
}

export const Accordion = ({ children, className, display = false, variant, header, status }: AccordionProps) => {
  const [show, setShow] = useState(display)

  return (
    <div className={className}>
      <button
        data-testid="accordion"
        type="button"
        className="flex items-center justify-between cursor-pointer hide_tap w-full mb-[14px]"
        onClick={() => setShow(!show)}
      >
        <div className="flex items-center justify-between w-full">
          {header}
          <div className="flex items-center">
            <Status status={status} className="text-[#859B6F] bg-[#EBEEE7]" />
            <div className="flex justify-center items-center w-[20px] h-[20px] rounded-full bg-[#F9F9FB] ml-[4px]">
              {!show ? <CaretRight /> : <CaretUp />}
            </div>
          </div>
        </div>
      </button>
      <AnimatePresence>
        {show && (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: "auto", transition: { duration: 0.4 } }}
            exit={{ height: 0, transition: { duration: 0.4 } }}
            className={`${variant} pl-[34px] overflow-hidden relative`}
          >
            <ArrowBendDownRight className="absolute top-[-6px] left-0" />
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
