import { addCommasToNumbers } from "utils/addCommasToNumbers"
import useGrievanceStatistics from "../useGrievanceStatistics"

const StatisticsView = () => {
  const { statisticsBreakDown } = useGrievanceStatistics()

  return (
    <div className="border bg-grey-50 p-4 rounded-lg text-sm">
      <div className="flex items-center gap-4">
        {statisticsBreakDown.map((data, index) => (
          <div
            key={data?.title}
            className={`relative flex-1 space-y-4 px-4 ${
              index !== statisticsBreakDown.length - 1 ? "border-r border-gray-300" : ""
            } `}
          >
            <h1 className="font-medium">{data?.title}</h1>
            <div className="flex justify-between">
              {data?.stats?.map((stat) => (
                <div key={stat.title} className="border-r pr-2 last:border-r-0">
                  <h1 className="text-body">{stat?.title}</h1>
                  <h1 className="font-medium">{addCommasToNumbers(stat?.count)}</h1>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default StatisticsView
