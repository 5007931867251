import { ActivityLogsInterface } from "types/payments"
import { SpinnerLoader, Modal } from "components/core"
import { RenderIf } from "components/hoc"
import { EmptyState } from "components/core/Table/EmptyState/EmptyState"
import { formatTimestamp } from "utils/dateFormatter"

const ActivityTimeline = ({ onClose, data, isLoading }: ActivityLogsInterface) => {
  return (
    <main>
      <Modal title="Activity Timeline" position="modal-right" width="w-[432px]" closeModal={onClose}>
        <RenderIf condition={isLoading}>
          <SpinnerLoader size={60} />
        </RenderIf>
        <RenderIf condition={data?.activity_log?.length > 0}>
          <div className="px-8">
            {data?.activity_log?.map((item: any, index: number) => (
              <div key={index} className="border-l relative border-primary-100 pl-4 pt-6 pb-10  flex gap-3">
                <div className="absolute top-0 my-7 -left-1.5 w-3 h-3 bg-primary-actions rounded-full" />
                <div className="flex flex-col gap-1 text-body">
                  <p className="text-sm font-normal">{item?.created_at ? formatTimestamp(item.created_at) : "--"}</p>
                  <p>
                    <span className="text-headers">{item.initiator ?? ""} </span>
                    {item?.activity}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </RenderIf>
        <RenderIf condition={data?.activity_log?.length === 0}>
          <EmptyState />
        </RenderIf>
      </Modal>
    </main>
  )
}

export default ActivityTimeline
