import { useTable } from "hooks"
import { useGetReportQuery, useLazyExportReportQuery } from "services/api/reportSlice"
import { useParams } from "react-router-dom"
import { tryCatch } from "utils/tryCatch"
import { exportData } from "utils/exportData"
import { fullDateFormatter } from "utils/dateFormatter"
import { removeSpecificKeys } from "utils/removeSpecificKeys"

export default function useGRMReport() {
  const { reportId } = useParams()
  const breadcrumbs = [{ title: "Report" }, { title: "GRM" }]
  const { changePage, selectLimit, params } = useTable()
  const { data: report, isLoading: isLoadingReport } = useGetReportQuery({
    reportId,
    ...removeSpecificKeys({ ...params }, ["sortBy"]),
  })
  const [exportReportQuery, { isFetching: isExporting }] = useLazyExportReportQuery()

  const exportReport = async () => {
    tryCatch(async () => {
      const res = await exportReportQuery(reportId).unwrap()

      const data = res?.data?.grievances?.map((item: any) => {
        return {
          "Grievance Id": item?.grievanceId,
          "Complaint Name": item?.complaintName,
          Category: item?.categoryName,
          "Category Type": item?.subCategoryName,
          "Assigned Officer": item?.assignedTo,
          Level: item?.level,
          Status: item?.status,
          "Created Date": fullDateFormatter(item?.createdAt),
        }
      })
      exportData(data, `${report?.data?.reportTitle} Report`)
    })
  }

  return {
    breadcrumbs,
    changePage,
    selectLimit,
    exportReport,
    report: report?.data,
    isLoadingReport,
    isExporting,
  }
}
