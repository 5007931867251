import React from "react"
import { Icon } from "@iconify/react"
import RadioButton from "components/core/RadioButton/RadioButton"
import { Modal, Input, Dropdown, Button, Textarea } from "components/core"
import { RenderIf } from "components/hoc"
import { TrainingType } from "mocks/training"
import useAddCourses from "./useAddCourse"

const AddCourse = ({ onClose }: { onClose: () => void }) => {
  const {
    formData,
    setFormData,
    addNewModule,
    handleDeleteModule,
    handleCreateCourse,
    isCreatingCourse,
    isFormValid,
    isDisabledBtn,
  } = useAddCourses()

  const handleModuleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
    type: "title" | "desc",
  ) => {
    const newValue = e.target.value
    const newModule = [...formData.modules]
    if (type === "title") {
      newModule[index].name = newValue
    }

    if (type === "desc") {
      newModule[index].description = newValue
    }

    setFormData({
      ...formData,
      modules: newModule,
    })
  }

  return (
    <div data-testid="add-course">
      <Modal title="Add Course" position="modal-right" width="w-[432px]" closeModal={onClose}>
        <div className="modal-right-body flex flex-col gap-y-6">
          <Input
            label="Course Title"
            value={formData?.title}
            id="course_title"
            type="text"
            name="course_title"
            onChange={(e) => {
              setFormData({
                ...formData,
                title: e.target.value,
              })
            }}
          />

          <Textarea
            label="Course Description"
            rows={6}
            id="description"
            name="description"
            maxLength={320}
            value={formData?.description}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFormData({ ...formData, description: e.target.value })
            }
          />

          <Dropdown
            label="Select Training Participant"
            selected={formData?.training_type}
            options={TrainingType}
            onSelect={(data: any) => {
              setFormData({
                ...formData,
                training_type: data?.value,
              })
            }}
          />

          <div>
            <h1 className="text-sm font-medium text-headers mb-3">Training Course Type</h1>
            <div className="flex flex-col gap-4">
              <RadioButton
                label="Hard Skills"
                name="hard-skills"
                checked={formData?.skill_type === "hard skills"}
                onChange={() => setFormData({ ...formData, skill_type: "hard skills" })}
              />
              <RadioButton
                label="Soft Skills"
                name="hard-skills"
                checked={formData?.skill_type === "soft skills"}
                onChange={() => setFormData({ ...formData, skill_type: "soft skills" })}
              />
            </div>
          </div>
          <div>
            <h1 className="text-sm font-medium text-headers">Add course modules</h1>
            <div className="w-full gap-3 mt-3">
              {formData.modules.map((module: any, index: number) => (
                <div key={index} className="flex flex-col gap-4 mb-4">
                  <div className="grid items-center grid-cols-3 gap-2">
                    <label className="text-sm text-headers cursor-default font-medium">{`Module ${index + 1}`}</label>

                    <div className="col-span-2 w-full flex space-x-2">
                      <input
                        data-testid={`module-title-${index}`}
                        value={module.name}
                        onChange={(e) => handleModuleChange(e, index, "title")}
                        className="p-[12px] h-[40px] text-body text-sm w-full outline-0 border hide_tap
                        rounded-[4px] border-grey-200 focus:border-primary-200 placeholder:text-sm"
                      />
                      <RenderIf condition={formData?.modules.length > 1}>
                        <button
                          className="cursor-pointer"
                          onClick={() => handleDeleteModule(index)}
                          data-testid={`del-module-${index}`}
                        >
                          <Icon icon="ph:x" height={12} className="text-[#ADACB0] w-[12px]" />
                        </button>
                      </RenderIf>
                    </div>
                  </div>
                  <div>
                    <label className="text-sm text-headers cursor-default font-medium">Description</label>
                    <textarea
                      rows={3}
                      data-testid={`module-desc-${index}`}
                      value={module.description}
                      onChange={(e) => handleModuleChange(e, index, "desc")}
                      className="p-[12px] text-body text-sm w-full outline-0 border hide_tap
        rounded-[4px] border-grey-200 focus:border-primary-200"
                    />
                  </div>
                </div>
              ))}
            </div>
            <RenderIf condition={formData?.modules.length < 5}>
              <button
                className="flex w-full justify-end mt-1 items-center text-xs text-primary-actions gap-1"
                onClick={addNewModule}
                data-testid="add-module-btn"
              >
                <Icon icon="ph:plus" />
                Add New Module
              </button>
            </RenderIf>
          </div>
        </div>

        <div className="modal-right-button-container mb-3">
          <Button
            theme="primary"
            className="min-w-[105px] mr-[8px] p-[8px]"
            type="button"
            data-testid="submit-btn"
            loading={isCreatingCourse}
            onClick={() => handleCreateCourse(onClose)}
            disabled={isCreatingCourse || isFormValid() || isDisabledBtn}
          >
            Save Course
          </Button>
          <Button theme="transparent" className="w-[105px] px-[30px]" type="button" onClick={onClose}>
            Discard
          </Button>
        </div>
      </Modal>
    </div>
  )
}

export default AddCourse
