import { useState, useEffect, useMemo } from "react"
import { Icon } from "@iconify/react"
import {
  useEscalateGrievanceMutation,
  useResolveGrievanceMutation,
  useUnResolveGrievanceMutation,
} from "services/api/grievanceSlice"
import { toast } from "react-toastify"
import { tryCatch } from "utils/tryCatch"
import { useGetActivityLogQuery } from "services/api/paymentSlice"
import ActivityTimeline from "./ActivityTimeline/ActivityTimeline"
import GrievanceComments from "./Comments"
import GrievanceResolution from "./Resolution"
import { useSearchParams } from "react-router-dom"

export default function useGrievanceDetails({ grievance }: any) {
  const [selectedAction, setSelectedAction] = useState("")
  const [escalateReason, setEscalateReason] = useState("")

  const [escalateGrievance, { isLoading: isEscalatingGrievance }] = useEscalateGrievanceMutation({})

  const [resolveGrievance, { isLoading: isResolvingGrievance }] = useResolveGrievanceMutation({})

  const [unresolveGrievance, { isLoading: isUnResolvingGrievance }] = useUnResolveGrievanceMutation({})

  const [searchParams] = useSearchParams()
  const currentTab = searchParams.get("tab")

  const {
    data: activityLogs,
    isFetching: isFetchingActivityLogs,
    refetch,
  } = useGetActivityLogQuery({
    search: "GRM",
    operation_id: grievance?.grievance_id,
  })

  const handleEscalateGrievance = async (id: string) => {
    const data = {
      escalation_reason: escalateReason,
    }

    const callback = (res: { code: number; message: string }) => {
      if ([200, 201].includes(res?.code)) {
        toast.success(res?.message)
        setSelectedAction("")
      } else {
        toast.error(res?.message)
        setSelectedAction("")
      }
    }

    tryCatch(async () => {
      let res = await escalateGrievance({ id, data }).unwrap()
      callback(res)
      setEscalateReason("")
      refetch()
    })
  }

  const handleResolveGrievance = async ({ id, payload }: any) => {
    const callback = (res: { code: number; message: string }) => {
      if ([200, 201].includes(res?.code)) {
        toast.success(res?.message)
        setSelectedAction("")
      } else {
        setSelectedAction("")
      }
    }

    tryCatch(async () => {
      let res = await resolveGrievance({ id, payload }).unwrap()
      callback(res)
    })
  }

  const handleUnResolveGrievance = async () => {
    const callback = (res: { code: number; message: string }) => {
      if ([200, 201].includes(res?.code)) {
        toast.success(res?.message)
      }
    }

    tryCatch(async () => {
      let res = await unresolveGrievance(grievance?.grievance_id).unwrap()
      callback(res)
    })
  }

  useEffect(() => {
    refetch()
  }, [grievance])

  const actions = [
    {
      name: "Mark as Resolved",
      icon: <Icon icon="ph:check-circle" width={18} height={18} />,
      method: () => {
        setSelectedAction("resolve-grievance")
      },
    },
    {
      name: "Escalate Grievance",
      icon: <Icon icon="ph:paper-plane-tilt" width={18} height={18} />,
      method: () => {
        setSelectedAction("escalate-grievance")
      },
    },
  ]

  const baseTabs = [
    {
      title: "Activity Log",
      component: activityLogs && <ActivityTimeline data={activityLogs} isLoading={isFetchingActivityLogs} />,
      path: "?tab=activity-log",
      active: "activity-log",
    },
    {
      title: "Comments",
      component: <GrievanceComments data={grievance} />,
      path: "?tab=comments",
      active: "comments",
    },
  ]

  const tabs =
    grievance?.status === "resolved"
      ? [
          ...baseTabs,
          {
            title: "Resolution Info",
            component: grievance && <GrievanceResolution data={grievance} />,
            path: "?tab=resolution",
            active: "resolution",
          },
        ]
      : baseTabs

  const componentToBeDisplayed = useMemo(() => {
    return tabs.find((tab) => tab.active === currentTab)?.component
  }, [currentTab, grievance, isFetchingActivityLogs])

  return {
    actions,
    selectedAction,
    setSelectedAction,
    escalateReason,
    setEscalateReason,
    handleEscalateGrievance,
    isEscalatingGrievance,
    handleResolveGrievance,
    isResolvingGrievance,
    activityLogs,
    isFetchingActivityLogs,
    tabs,
    componentToBeDisplayed,
    currentTab,
    handleUnResolveGrievance,
    isUnResolvingGrievance,
  }
}
