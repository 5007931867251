import { Actions, Status } from "components/core"
import { Icon } from "@iconify/react"
import { formatCurrency } from "utils/formatCurrency"
import dayjs from "dayjs"
import { currencies } from "constants/currency"
import { addCommasToNumbers } from "utils/addCommasToNumbers"

interface InterventionProps {
  intervention?: any
  actions: any[]
  status: string
  showStatus?: boolean
  className?: string
}

const InterventionCard = ({ intervention, actions, status, showStatus = true, className }: InterventionProps) => {
  const currency = currencies.find((currency) => currency.name === intervention?.currency)

  return (
    <div
      className={`${className} rounded-xl border border-neutral-10 px-4 pt-4 pb-3 w-full bg-white max-h-[202px]
      first-of-type:mt-0 flex justify-between flex-col`}
    >
      <div className="flex justify-between items-center">
        {showStatus && <Status status={status} />}
        {!intervention.data_source && <Status status="no_data_source" />}
      </div>
      <div className="mt-3">
        <p className="text-headers font-semibold max-w-[200px] truncate">{intervention?.intervention_title}</p>
        <p className="text-headers text-xs mt-3 font-medium">
          Created on: <span className="text-body ml-3">{dayjs(intervention.created_at).format("MMM DD, YYYY")}</span>
        </p>
        <p className="text-headers text-xs mt-2 font-medium">
          Beneficiaries:{" "}
          <span className="text-body ml-3">{addCommasToNumbers(intervention?.total_number_of_beneficiary ?? 0)}</span>
        </p>
      </div>
      <div className="flex justify-between items-center mt-4 pt-2.5 border-t">
        <div className="flex items-center space-x-2">
          <Icon icon="ph:money-fill" className="w-6 h-6 text-body" />
          <p className="text-sm text-headers">
            {formatCurrency(intervention?.amount, currency?.currency, currency?.symbol)}
          </p>
        </div>
        <Actions
          id={intervention?.id}
          item={intervention}
          module="Intervention"
          actions={actions}
          trigger={<Icon icon="pepicons-pencil:dots-x" className="w-6 h-6 text-body" />}
        />
      </div>
    </div>
  )
}

export default InterventionCard
