import { Icon } from "@iconify/react"
import useGrievance from "./useGrievanceStatistics"
import { addCommasToNumbers } from "utils/addCommasToNumbers"
import { RenderIf } from "components/hoc"
import StatisticsView from "./StatisticsView/StatisticsView"
import HeaderLoader from "components/core/Loader/HeaderLoader/HeaderLoader"

const GrievanceStatistics = () => {
  const { handleToggle, viewGrievanceStats, statisticsData, isLoadingAnalytics } = useGrievance()

  return (
    <div className="border-y mt-3 py-6 flex flex-col gap-6" data-testid="grievance-stats">
      <RenderIf condition={isLoadingAnalytics}>
        <HeaderLoader />
      </RenderIf>

      <RenderIf condition={!isLoadingAnalytics}>
        <div className="flex">
          {statisticsData?.map((statistic) => (
            <div key={statistic.title} className="flex items-center gap-4 mr-[40px]">
              <div className="">
                <div className="w-10 h-10 grid place-items-center bg-grey-100 rounded-full">
                  <Icon icon="ph:note" className="w-6 h-6 text-body" />
                </div>
              </div>
              <div className="grid">
                <span className="text-body text-sm">{statistic.title}</span>
                <p className="text-headers text-sm">
                  {addCommasToNumbers(statistic.count)}
                  <RenderIf condition={statistic.viewStats}>
                    {" "}
                    <button
                      data-testid="toggle-grievance"
                      onClick={handleToggle}
                      className=" text-semibold text-[#2B892B]"
                    >
                      {viewGrievanceStats ? "(Hide Breakdown >>>)" : "(View Breakdown >>>)"}
                    </button>
                  </RenderIf>
                </p>
              </div>
            </div>
          ))}
        </div>
        <RenderIf condition={viewGrievanceStats}>
          <StatisticsView />
        </RenderIf>
      </RenderIf>
    </div>
  )
}

export default GrievanceStatistics
