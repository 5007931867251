import { ManageItem } from "components/core"
import useViewData from "./useViewDataSource"
import { ViewDataResourceBreadCrumbs, ViewDataResourceCard } from "components/pages/DataMining"
import { useState } from "react"
import { RenderIf } from "components/hoc"
import { AddBatch } from "components/pages/DataMining/AddBatch/AddBatch"
import useDataMining from "../DataExchange/useDataMining"
import { MineUpdateData } from "components/pages/DataMining/MineData/MineUpdateData/MineUpdateData"
import { DataTabs } from "components/pages/DataMining/ViewDataSource/DataTabs/DataTabs"
import { ViewDataSourceFilters } from "components/pages/DataMining/ViewDataSource/ViewDataSourceFilters/ViewDataSourceFilters"
import MineHistoryTable from "components/pages/DataMining/ViewDataSource/MineHistoryTable/MineHistoryTable"
import ProgressDialog from "components/pages/DataMining/ViewDataSource/ProgressDialog/ProgressDialog"
import { AnimatePresence } from "framer-motion"

export default function ViewDataSource() {
  const {
    breadCrumbs,
    refetchMineHistory,
    refetchSingleDataSourceDetails,
    viewDataResourceCardDetails,
    historyTableProps,
    filtersProps,
    dataTabProps,
  } = useViewData()
  const {
    setSelectedAction,
    selectedAction,
    actions,
    handleDelete,
    isDeleting,
    selectedData,
    addBatchAction,
    setSelectedData,
  } = useDataMining(refetchMineHistory, refetchSingleDataSourceDetails)
  const [openPercentageModal, setOpenPercentageModal] = useState<boolean | undefined>(false)
  const [openCompleteMiningModal, setOpenCompleteMiningModal] = useState(false)
  const [openStatusDialog, setOpenStatusDialog] = useState<boolean>(false)
  const [historyData, setHistoryData] = useState({})

  return (
    <main data-testid="view-data-source" className="relative">
      <ViewDataResourceBreadCrumbs options={breadCrumbs} />
      <ViewDataResourceCard {...viewDataResourceCardDetails} />

      <DataTabs {...dataTabProps} />

      <ViewDataSourceFilters
        {...filtersProps}
        setSelectedAction={setSelectedAction}
        setSelectedData={setSelectedData}
      />
      <MineHistoryTable {...historyTableProps} actions={actions} addBatchAction={addBatchAction} />
      {["mine-data", "update-data"].includes(selectedAction) && (
        <MineUpdateData
          selectedData={selectedData}
          selectedAction={selectedAction}
          source_name={viewDataResourceCardDetails?.header}
          title="create"
          onCloseMineData={() => setSelectedAction("")}
          openPercentageModal={openPercentageModal}
          setOpenPercentageModal={setOpenPercentageModal}
          setOpenCompleteMiningModal={() => setOpenCompleteMiningModal(true)}
          refetchMineHistory={refetchMineHistory}
          refetchSingleDataSourceDetails={refetchSingleDataSourceDetails}
          setHistoryData={setHistoryData}
        />
      )}
      {selectedAction === "Delete" && (
        <ManageItem
          title={`Do you want to ${selectedAction.toLowerCase()}  the data?`}
          actionName={selectedAction}
          cancelBtnText="Cancel"
          caption={`Confirming this action will ${selectedAction.toLowerCase()} this data and 
          it would no longer appear on the data list.`}
          loading={isDeleting}
          onClose={() => setSelectedAction("")}
          onSubmit={() => handleDelete()}
        />
      )}
      {selectedAction === "add-batch" && (
        <AddBatch
          selectedData={selectedData}
          onClose={() => setSelectedAction("")}
          refetchMineHistory={refetchMineHistory}
        />
      )}
      <RenderIf condition={openCompleteMiningModal}>
        <ManageItem
          showCloseIcon
          title="Do you want to complete mining in 
          the background?"
          actionName="Keep Running"
          cancelBtnText="Yes, Proceed"
          caption="Mining these large data can take hours and we advise to run the mining process in the background"
          onClose={() => {
            setOpenCompleteMiningModal(false)
            setOpenStatusDialog(true)
          }}
          onSubmit={() => {
            setSelectedAction("mine-data")
            setOpenPercentageModal && setOpenPercentageModal(true)
            setOpenCompleteMiningModal && setOpenCompleteMiningModal(false)
          }}
        />
      </RenderIf>
      <AnimatePresence>
        {openStatusDialog && (
          <ProgressDialog
            refetch={refetchMineHistory}
            onClose={() => setOpenStatusDialog(false)}
            percentage={historyData}
          />
        )}
      </AnimatePresence>
    </main>
  )
}
