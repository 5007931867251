import { Avatar, Button, Checkbox, Textarea, Dropdown } from "components/core"
import { useNavigate } from "react-router-dom"
import { RenderIf } from "components/hoc"
import { grievanceSatisfiedOptions } from "mocks/grievance"

interface Props {
  formData: any
  // eslint-disable-next-line no-unused-vars
  handleSubmit?: () => void
  back: () => void
  isLoading?: boolean
  setFormData?: any
}

const ReviewGrievance = ({ formData, handleSubmit, back, isLoading, setFormData }: Props) => {
  const navigate = useNavigate()

  const handleBack = () => {
    back()
    navigate(`?grm=nctu`)
  }

  return (
    <div data-testid="review-create-grievance">
      <div className="flex gap-x-3  py-3 border-b">
        <div className="self-center">
          <Avatar
            size="97"
            key={formData?.complaint_name}
            image={formData?.complaint_name}
            alt={formData?.complaint_name}
          />
        </div>
        <div className="flex gap-y-1 flex-col">
          <div className="flex gap-x-2">
            <h1 className="text-headers text-2xl font-semibold">{formData?.complaint_name}</h1>
            <h1 className="bg-grey-50 rounded-3xl py-[2px] px-2  text-center font-medium text-grey-600 text-xs self-center">
              {formData?.gender}
            </h1>
          </div>
          <p className="text-body text-sm max-w-[450px]">{formData?.description}</p>
        </div>
      </div>

      <div className="mt-4">
        <h1 className="text-headers font-medium text-lg">Additional Information</h1>

        <div className="flex mt-6 flex-col gap-y-6">
          <div className="grid grid-cols-2">
            <h1 className="text-body">Complaint Type:</h1>
            <h1 className="capitalize text-headers">
              {formData?.complaint_type === "non-beneficiary" ? "Non-Beneficiary" : "Beneficiary"}
            </h1>
          </div>
          <div className="grid grid-cols-2">
            <h1 className="text-body">Complaint Mode:</h1>
            <h1 className="uppercase text-headers">{formData?.complaint_mode}</h1>
          </div>
          <div className="grid grid-cols-2">
            <h1 className="text-body">Zone:</h1>
            <h1 className="capitalize text-headers">{formData?.zone_name ?? "--"}</h1>
          </div>
          <div className="grid grid-cols-2">
            <h1 className="text-body">State:</h1>
            <h1 className="capitalize text-headers">{formData?.state_name}</h1>
          </div>
          <div className="grid grid-cols-2">
            <h1 className="text-body">LGA:</h1>
            <h1 className="capitalize text-headers">{formData?.lga_name ?? "--"}</h1>
          </div>
          <div className="grid grid-cols-2">
            <h1 className="text-body">Ward:</h1>
            <h1 className="capitalize text-headers">{formData?.ward ?? "--"}</h1>
          </div>
          <div className="grid grid-cols-2">
            <h1 className="text-body">Community:</h1>
            <h1 className="capitalize text-headers">{formData?.community_name}</h1>
          </div>
          <div className="grid grid-cols-2">
            <h1 className="text-body">Level:</h1>
            <h1 className="capitalize text-headers">{formData?.level}</h1>
          </div>
          <div className="grid grid-cols-2">
            <h1 className="text-body">Category:</h1>
            <h1 className="capitalize text-headers">{formData?.category_id_name}</h1>
          </div>
          <div className="grid grid-cols-2">
            <h1 className="text-body">Sub-Category:</h1>
            <h1 className="capitalize text-headers">{formData?.sub_category_id_name}</h1>
          </div>
          <RenderIf condition={formData?.category_id === "8c4d7624-36eb-11ef-bdcd-6f3f37ec1b91"}>
            <div className="grid grid-cols-2">
              <h1 className="text-body">Do you want to continue with NASSP?</h1>
              <h1 className="capitalize text-headers">{formData?.nassp ? "Yes" : "No"}</h1>
            </div>
          </RenderIf>

          {/* <div className="grid grid-cols-2">
            <h1 className="text-body">Assigned GRM Officer:</h1>
            <div className="capitalize text-headers flex items-center gap-x-1">
              <Avatar
                key={formData?.assigned_to_name}
                image={formData?.assigned_to_name}
                alt={formData?.assigned_to_name}
              />
              {formData?.assigned_to_name} {`(${formData?.assigned_dept})`}
            </div>
          </div> */}
        </div>
      </div>

      <div className="border-t-[0.8px] border-t-grey-200 py-6 mt-6">
        <div className="mb-6">
          <Checkbox
            label="Tick if this Grievance has being resolved"
            id="resolved"
            checked={formData?.resolved}
            onChange={() => {
              setFormData({
                ...formData,
                resolved: !formData?.resolved,
              })
            }}
          />
        </div>
        <RenderIf condition={formData?.resolved}>
          <div className="space-y-6">
            <Textarea
              label="How was this grievance resolved"
              id="complaint_resolved_details"
              name="complaint_resolved_details"
              rows={4}
              value={formData?.complaint_resolved_details ?? ""}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  complaint_resolved_details: e.target.value,
                })
              }
              maxLength={340}
            />
            <Dropdown
              label="Complaint Satisfaction"
              selected={formData?.complaint_satisfaction}
              options={grievanceSatisfiedOptions}
              onSelect={(data: any) => {
                setFormData({
                  ...formData,
                  complaint_satisfaction: data?.value,
                })
              }}
            />

            <Textarea
              label="Complainant Feedback"
              id="complaint_feedback"
              name="complaint_feedback"
              rows={4}
              value={formData?.complaint_feedback ?? ""}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  complaint_feedback: e.target.value,
                })
              }
              maxLength={340}
            />
          </div>
        </RenderIf>
      </div>

      <div className="flex justify-start items-center mt-12 space-x-4">
        <Button theme="primary" size="44" className="min-w-[171px]" loading={isLoading} onClick={handleSubmit}>
          Proceed
        </Button>
        <Button theme="transparent" className="w-[171px] px-[30px]" type="button" size="44" onClick={handleBack}>
          Back
        </Button>
      </div>
    </div>
  )
}

export default ReviewGrievance
