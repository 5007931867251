import { useState, useMemo } from "react"
import { toast } from "react-toastify"
import { useCreateCourseMutation } from "services/api/trainingSlice"
import { tryCatch } from "utils/tryCatch"

interface Module {
  name: string
  description: string
}

interface CourseFormData {
  title: string
  description: string
  training_type: string
  skill_type: string
  modules: Module[]
}

const useAddCourses = () => {
  const [formData, setFormData] = useState<CourseFormData>({
    title: "",
    description: "",
    training_type: "",
    skill_type: "",
    modules: [
      {
        name: "",
        description: "",
      },
    ],
  })

  const [createCourse, { isLoading: isCreatingCourse }] = useCreateCourseMutation({})

  const addNewModule = () => {
    setFormData((prevData: any) => ({
      ...prevData,
      modules: [...prevData.modules, { name: "", description: "" }],
    }))
  }

  const handleDeleteModule = (index: number) => {
    const filteredModule = formData.modules.filter((_value, valueIndex) => valueIndex !== index)
    setFormData((prevData: any) => ({
      ...prevData,
      modules: filteredModule,
    }))
  }

  const handleCreateCourse = async (onClose: () => void) => {
    const callback = (res: any) => {
      if ([200, 201].includes(res?.code)) {
        toast.success(res.message)
        onClose()
      }
    }

    tryCatch(async () => {
      let res = await createCourse(formData).unwrap()
      callback(res)
    })
  }

  const isFormValid = () => {
    const { title, training_type, modules, description, skill_type } = formData
    const isModuleValid = modules.some((module) => module.name && module.description)

    if (!title || !training_type || isModuleValid || !description || skill_type) {
      return false
    }

    return true
  }

  const isDisabledBtn = useMemo(() => {
    return (
      !formData.training_type ||
      !formData?.description ||
      !formData?.skill_type ||
      !formData?.title ||
      !formData?.modules.every((module) => module.name.length > 0 && module.description.length > 0)
    )
  }, [formData])

  return {
    formData,
    setFormData,
    addNewModule,
    handleDeleteModule,
    handleCreateCourse,
    isCreatingCourse,
    isFormValid,
    isDisabledBtn,
  }
}

export default useAddCourses
