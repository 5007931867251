import React, { useEffect } from "react"
import { Icon } from "@iconify/react"
import { removePercentageAndConvertToNumber } from "utils/percentageToNumberConverter"
import "./Dialog.css"
import { motion } from "framer-motion"
import useProgressDialog from "./useProgressDialog"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"

interface ProgressDialogProps {
  percentage?: any
  onClose: () => void
  refetch: () => void
}

const ProgressDialog: React.FC<ProgressDialogProps> = ({ onClose, refetch }) => {
  const fetchSingleMinedDataId = useSelector((state: any) => state?.data?.minedDataId)
  const { history, refetchHistory } = useProgressDialog({ fetchSingleMinedDataId })

  useEffect(() => {
    if (history && removePercentageAndConvertToNumber(history.status) === 100) {
      setTimeout(() => {
        onClose()
      }, 2000)
      toast.success("Data mining completed!")
      refetch()
    }
  }, [history])

  useEffect(() => {
    const interval = setInterval(() => {
      refetchHistory()
    }, 10000)

    return () => clearInterval(interval)
  }, [refetchHistory])
  return (
    <motion.div
      data-testid="motion-div"
      initial={{ y: 200 }}
      animate={{ y: "0", transition: { duration: 0.6 } }}
      exit={{ y: 200, transition: { duration: 0.6 } }}
      className={`w-80 fixed right-0 bottom-0 bg-white shadow-xl p-4 rounded-lg text-sm`}
    >
      <div className="absolute right-0">
        <button data-testid="close" className="cursor-pointer" onClick={onClose}>
          <Icon icon="ph:x" className="w-5 h-5 mr-2 text-[#ADACB0]" />
        </button>
      </div>

      <div className="flex gap-4">
        <span>
          {history && removePercentageAndConvertToNumber(history.status) === 100
            ? "Mining completed"
            : "Mining in progress...."}
        </span>
      </div>
      <div className="relative mt-4 h-1 w-full bg-gray-200 rounded-full ">
        <div
          data-testid="progress"
          className="absolute top-0 left-0 h-full bg-green-500 rounded-full"
          style={{ width: `${history && removePercentageAndConvertToNumber(history.status)}%` }}
        ></div>
      </div>
    </motion.div>
  )
}

export default ProgressDialog
