import React from "react"
import { motion } from "framer-motion"
import { Breadcrumb, Table, Search, Filter } from "components/core"
import TransactionInfo from "components/pages/Payments/Transactions/TransactionInfo/TransactionInfo"
import useTransactionBatch from "./useViewTransactionBatch"
import { transactionsBatchInfoHeader, transactionBatchHeaders } from "constants/payments"
import { genderOptionsII } from "mocks/payments"
import { addCommasToNumbers } from "utils/addCommasToNumbers"
import { formatTimestamp } from "utils/dateFormatter"
import PaymentReviewStatus from "components/pages/Payments/ReviewStatus/ReviewStatus"
import { AllPermissions, PermissionModule } from "types/permission"
import { Restricted } from "components/hoc"

const ViewTransactionBatch = () => {
  const { TRANSACTIONS } = PermissionModule
  const { VIEW_BENEFICIARIES } = AllPermissions

  const {
    breadCrumbs,
    transactionInfoData,
    params,
    changePage,
    selectLimit,
    handleSearch,
    selectedGender,
    setSelectedGender,
    transactionsBeneficiaries,
    isFetchingTransactionBeneficiaries,
    transactionInfoTitle,
  } = useTransactionBatch()

  const MotionTr = motion.tr

  return (
    <Restricted module={TRANSACTIONS} permission={VIEW_BENEFICIARIES}>
      <main>
        <Breadcrumb options={breadCrumbs} showBack />
        <div className="mt-2 mb-8">
          <TransactionInfo
            title={transactionInfoTitle}
            headers={transactionsBatchInfoHeader}
            data={transactionInfoData}
          />
        </div>

        <div className="flex flex-wrap gap-4 items-center mb-4 mt-6">
          <Search id="search" name="Search" placeholder="Search" onChange={handleSearch} />
          <Filter
            title="Gender"
            options={genderOptionsII}
            multiple={true}
            loading={false}
            selectedOption={selectedGender}
            setSelectedOption={setSelectedGender}
          />
        </div>

        <div>
          <Table
            headers={transactionBatchHeaders}
            data={transactionsBeneficiaries?.transactions ?? []}
            onPageChange={changePage}
            totalCount={Number(transactionsBeneficiaries?.total)}
            perPage={Number(params?.limit)}
            page={Number(params?.page)}
            selectLimit={selectLimit}
            loading={isFetchingTransactionBeneficiaries}
          >
            <>
              {transactionsBeneficiaries
                ? transactionsBeneficiaries?.transactions?.map((item: any) => (
                    <React.Fragment key={item.id}>
                      <MotionTr
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.3 }}
                        className="cc-custom-table-data-row"
                        data-testid={`transaction-${item?.transaction_id}`}
                      >
                        <td className="capitalize">{item?.name}</td>
                        <td className="truncate capitalize">{item?.beneficiary_id}</td>
                        <td className="capitalize">{item?.state}</td>
                        <td>{item?.destination_account ?? "--"}</td>
                        <td className="capitalize">{item?.gender}</td>
                        <td>{addCommasToNumbers(item?.amount)}</td>
                        <td className="truncate">{item?.transaction_reference}</td>
                        <td className="text-nowrap">{formatTimestamp(item.payment_date)}</td>
                        <td>
                          <PaymentReviewStatus status={String(item?.payment_status)?.toLowerCase()} />
                        </td>
                      </MotionTr>
                    </React.Fragment>
                  ))
                : null}
            </>
          </Table>
        </div>
      </main>
    </Restricted>
  )
}

export default ViewTransactionBatch
