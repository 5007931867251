import { useFetchTrainingAnalyticsQuery } from "services/api/trainingSlice"

export default function useTraining() {
  const { data, isFetching: isLoadingStatistics } = useFetchTrainingAnalyticsQuery({})
  const totalTraining = data ? Number(data?.beneficiary) + Number(data?.livelihood) + Number(data?.staff) : 0
  const statisticsData = [
    {
      title: "Total Training Count",
      count: totalTraining,
      icon: "ph:users-three",
      viewCourses: false,
    },
    {
      title: "Total Beneficiaries Trainings",
      count: data?.beneficiary ?? 0,
      icon: "ph:handshake",
      viewCourses: false,
    },
    {
      title: "Total Livelihood Trainings",
      count: data?.livelihood ?? 0,
      icon: "ph:handshake",
      viewCourses: false,
    },
    {
      title: "Total Staff Trainings",
      count: data?.staff ?? 0,
      icon: "ph:handshake",
      viewCourses: false,
    },
    {
      title: "Total number of courses",
      count: data?.total_courses ?? 0,
      icon: "ph:stamp",
      viewCourses: true,
    },
  ]

  return { isLoadingStatistics, statisticsData }
}
