import { Status } from "components/core"
import { Intervention } from "types/intervention"
import { convertObjectToArrayOfObject } from "utils/objectFormatter"
import { Icon } from "@iconify/react"
import "./additionalInformation.css"
import { RenderIf } from "components/hoc"
import { addCommasToNumbers } from "utils/addCommasToNumbers"

interface AdditionalInformationProps {
  data: Intervention
}

export const AdditionalInformation = ({ data }: AdditionalInformationProps) => {
  const quotaStructure = convertObjectToArrayOfObject(data?.quota_structure, ["state", "quota"])
  const hasEligibity = !!data?.eligibility
  const livelihood = hasEligibity ? data?.eligibility?.livelihood ?? [] : []
  const co_responsibility = hasEligibity ? data?.eligibility["co-responsibility"] ?? [] : []

  return (
    <div>
      <div>
        <p className="text-lg text-headers font-medium mb-[24px]">Details</p>
        <div className="grid grid-cols-3 gap-y-[24px] mb-[24px] border-b border-b-grey-200 pb-[16px]">
          <div className="font-normal">
            <p className="text-body text-sm">Intervention type:</p>
            <p className="text-headers text-base mt-[2px] capitalize-first">{data?.intervention_type}</p>
          </div>
          <div className="font-normal">
            <p className="text-body text-sm">Number of eligible household member:</p>
            <p className="text-headers text-base mt-[2px]">{data?.number_of_eligible_household_member}</p>
          </div>
          <div className="font-normal">
            <p className="text-body text-sm">Intervention reviewer:</p>
            <p className="text-headers text-base mt-[2px] capitalize">{data?.reviewer_name}</p>
          </div>
          <div className="font-normal">
            <p className="text-body text-sm mb-[8px]">Zones:</p>
            <div className="flex flex-wrap">
              {data?.zones?.map((item) => (
                <Status key={item?.id} status={item?.name} className="mr-[8px] mb-[8px] bg-[#E3E5EB] text-body" />
              ))}
            </div>
          </div>
          <div className="font-normal">
            <p className="text-body text-sm mb-[8px]">States:</p>
            <div className="flex flex-wrap">
              {data?.states?.map((item) => (
                <Status key={item?.id} status={item?.name} className="mr-[8px] mb-[8px] bg-[#E3E5EB] text-body" />
              ))}
            </div>
          </div>
          <div className="font-normal">
            <p className="text-body text-sm mb-[8px]">Local Government Area:</p>
            <div className="flex flex-wrap">
              {data?.lgas?.map((item) => (
                <Status key={item?.id} status={item?.name} className="mr-[8px] mb-[8px] bg-[#E3E5EB] text-body" />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="mb-[24px] border-b border-b-grey-200 pb-[24px]">
        <p className="text-lg text-headers font-medium mb-[24px]">Quota Structure</p>
        <div className="ai-table-container border border-neutral-10 rounded-[8px] py-[4px]">
          <table className="ai-table">
            <thead>
              <tr className="text-xs text-headers font-medium">
                <th className="ai-text">States</th>
                <th className="ai-text">Quota</th>
              </tr>
            </thead>
            <tbody>
              {quotaStructure?.map((item) => (
                <tr key={item?.state} className="text-sm text-body font-normal">
                  <td className="ai-text">{item?.state}</td>
                  <td className="ai-text">{addCommasToNumbers(item?.quota ?? 0)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <RenderIf condition={!!data?.selection_criteria?.length}>
        <div className="mb-[24px] border-b border-b-grey-200 pb-[24px]">
          <p className="text-lg text-headers font-medium mb-[24px]">Selection Criteria</p>
          <div className="grid grid-cols-3 gap-x-[24px]">
            {data?.selection_criteria?.map((item, i) => (
              <div className="flex" key={i}>
                <Icon icon="ph:gavel" className="mr-[16px] shrink-0" />
                <div className="font-normal">
                  <p className="text-body text-sm">Criteria {i + 1}:</p>
                  <p className="text-headers text-base mt-[2px] capitalize-first">
                    {item?.condition} <span className="text-primary-actions italic font-medium">{item?.operator}</span>{" "}
                    {item?.value}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </RenderIf>
      <div className="mb-[24px] border-b border-b-grey-200 pb-[16px]">
        <p className="text-lg text-headers font-medium mb-[24px]">Payment</p>
        <div className="grid grid-cols-3 gap-y-[24px]">
          <div className="font-normal">
            <p className="text-body text-sm">Payment Recurrence:</p>
            <p className="text-headers text-base mt-[2px] capitalize-first">{data?.payment_recurrence ?? "N/A"}</p>
          </div>
          <div className="font-normal">
            <p className="text-body text-sm">Payment Mechanism:</p>
            <p className="text-headers text-base mt-[2px]">{data?.payment_mechanism ?? "N/A"}</p>
          </div>
          <div className="font-normal">
            <p className="text-body text-sm">Payment Cycle:</p>
            <p className="text-headers text-base mt-[2px] capitalize">{data?.payment_cycle ?? "N/A"}</p>
          </div>
          <div className="font-normal">
            <p className="text-body text-sm">Payment Arrears Configuration:</p>
            <p className="text-headers text-base mt-[2px]">{data?.payment_arrears_configuration ?? "N/A"}</p>
          </div>
          <div className="font-normal">
            <p className="text-body text-sm">Payment Confirmation Cycle:</p>
            <p className="text-headers text-base mt-[2px] capitalize">{data?.payment_confirmation_cycle ?? "N/A"}</p>
          </div>
        </div>
      </div>
      <RenderIf condition={!!co_responsibility?.length || !!livelihood?.length}>
        <div className="mb-[24px] border-b border-b-grey-200 pb-[24px]">
          <p className="text-lg text-headers font-medium mb-[24px]">Additional Eligibility</p>
          <div className="grid grid-cols-2 gap-x-[24px]">
            <RenderIf condition={!!co_responsibility?.length}>
              <div className="ai-table-container border border-neutral-10 rounded-[8px] py-[4px]">
                <table className="ai-table">
                  <thead>
                    <tr className="text-xs text-headers font-medium">
                      <th colSpan={2} className="ai-text">
                        Co-responsibilty
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {co_responsibility?.map((item: any) => (
                      <tr key={item?.condition} className="text-sm text-body font-normal">
                        <td className="ai-text">{item?.condition}</td>
                        <td className="ai-text">{item?.percentage}%</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </RenderIf>
            <RenderIf condition={!!livelihood?.length}>
              <div className="ai-table-container border border-neutral-10 rounded-[8px] py-[4px]">
                <table className="ai-table">
                  <thead>
                    <tr className="text-xs text-headers font-medium">
                      <th colSpan={2} className="ai-text">
                        Livelihood
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {livelihood?.map((item: any) => (
                      <tr key={item?.condition} className="text-sm text-body font-normal">
                        <td className="ai-text">{item?.condition}</td>
                        <td className="ai-text w-[40px]">{item?.percentage}%</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </RenderIf>
          </div>
        </div>
      </RenderIf>
      <div className="pb-[24px]">
        <p className="text-lg text-headers font-medium mb-[24px]">Departmental Access</p>
        <div>
          <p className="text-base text-body font-normal mb-[8px]">Departments:</p>
          <div className="flex">
            {data?.departments?.map((item) => (
              <Status key={item?.id} status={item?.name} className="mr-[8px] mb-[8px] bg-[#E3E5EB] text-body" />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
