import { DateTimeFormatOptions } from "types/utils"
import dayjs from "dayjs"

export const fullDateFormatter = (date: any) => {
  const inputDate = new Date(date)
  const formattedDate = inputDate.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  })

  return formattedDate
}

export const ISODateFormatter = (d: any) => {
  const date = new Date(d)
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, "0") // Month is zero-based
  const day = String(date.getDate()).padStart(2, "0")
  const hours = String(date.getHours()).padStart(2, "0")
  const minutes = String(date.getMinutes()).padStart(2, "0")
  const seconds = String(date.getSeconds()).padStart(2, "0")
  const milliseconds = String(date.getMilliseconds()).padStart(3, "0")

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`
}

export const shortDate = (date: any) => {
  if (!date) return
  const formattedDate = dayjs(date).format("YYYY-MM-DD")
  return formattedDate
}

export const cookieDateFormat = (date: Date) => {
  // Convert to a Date object
  const dateObject = new Date(date)

  // Options for formatting
  const options: DateTimeFormatOptions = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZone: "UTC",
  }

  // Format the date
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(dateObject)

  return formattedDate
}

export function formatTimestamp(timestamp: string): string {
  // Parse the timestamp string to a Date object
  const date = new Date(timestamp)

  // Options for formatting the date and time
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  }

  // Create a formatter with the specified options
  const formatter = new Intl.DateTimeFormat("en-US", options)

  // Format the date object to the desired string format
  const formattedTimestamp = formatter.format(date)

  // Split the formatted date and time
  const [datePart, timePart] = formattedTimestamp.split(", ")

  // Combine them into the desired format
  return `${datePart}, ${timePart}`
}

export const getPreviousYears = (): { label: string; value: string }[] => {
  const currentYear = new Date().getFullYear()
  const years: { label: string; value: string }[] = []

  for (let year = 2020; year <= currentYear; year++) {
    years.push({ label: `${year}`, value: `${year}` })
  }

  return years
}
