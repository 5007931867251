export const checkEmptyProperties = (obj: any) => {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (typeof obj[key] === "object") {
        // If the current property value is an object, recursively check its properties
        if (checkEmptyProperties(obj[key])) {
          return true // Return true if any nested property is empty
        }
      } else if (obj[key] === "") {
        return true // Return true if the current property value is an empty string
      }
    }
  }
  return false // No empty properties found
}
