import { Icon } from "@iconify/react"
import { Dropdown } from "components/core"
import { defaultFilter, paymentStatus } from "constants/global"
import { useMemo } from "react"
import { useGetAllCommunitiesQuery } from "services/api/commonSlice"

interface Props {
  state: any
}

export const PaymentSchedule = ({ state }: Props) => {
  const { formData, setFormData } = state
  const { data: communities, isFetching } = useGetAllCommunitiesQuery("")

  const communityOptions = useMemo(() => {
    return communities?.length
      ? communities?.map((item: any) => {
          return { label: item.community, value: item.community }
        })
      : []
  }, [communities])

  return (
    <div className="mb-[56px]">
      <p className="mb-[8px] font-medium text-sm text-headers">
        <Icon icon="ph:pencil-line" className="inline w-[20px] h-[20px]" /> PAYMENT STRUCTURE
      </p>
      <div className="pl-[24px] w-full mb-[20px] rounded-sm">
        <table className="w-full">
          <thead>
            <tr className="text-left border text-xs text-body">
              <th className="p-[8px] font-medium border-r text-start w-[270px]">Communities</th>
              <th className="p-[8px] font-medium text-start w-[270px]">Payment Status</th>
            </tr>
          </thead>
          <tbody>
            <tr className="border h-12 text-sm text-body">
              <td className="border-r">
                <Dropdown
                  selected={formData?.communities}
                  containerVariant="!border-none !shadow-none"
                  wrapperClassName="!mb-0"
                  options={[...defaultFilter, ...communityOptions]}
                  enableSearchOptions={true}
                  multiple={true}
                  count={true}
                  loading={isFetching}
                  onSelect={(data: any) => setFormData({ ...formData, communities: data })}
                />
              </td>
              <td className="border-r">
                <Dropdown
                  selected={formData?.paymentStatus}
                  containerVariant="!border-none !shadow-none"
                  wrapperClassName="!mb-0"
                  options={paymentStatus}
                  enableSearchOptions={true}
                  multiple={true}
                  count={true}
                  onSelect={(data: any) => setFormData({ ...formData, paymentStatus: data })}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}
