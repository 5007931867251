import { useState, ChangeEvent, useMemo } from "react"
import { useEditCourseMutation } from "services/api/trainingSlice"
import { toast } from "react-toastify"
import { tryCatch } from "utils/tryCatch"

type moduleType = {
  name: string
  description: string
  id?: string
  to_delete?: boolean | undefined
}

type courseData = {
  title: string
  course_id: string
  description: string
  skill_type: string
  training_type: string
  modules: moduleType[]
}

interface UseEditCourseProps {
  onClose: () => void
  course: courseData
}

const generateFormData = (data: courseData) => ({
  title: data?.title ?? "",
  description: data?.description ?? "",
  training_type: data?.training_type ?? "",
  skill_type: data?.skill_type ?? "",
  modules: data?.modules?.map(
    (module: { name: string; description: string; id?: string; to_delete?: boolean | undefined }) => ({
      name: module?.name,
      description: module?.description,
      id: module?.id,
      to_delete: module?.to_delete ?? "undefined",
    }),
  ),
})

export default function useEditCourse({ course, onClose }: UseEditCourseProps) {
  const [originalFormData] = useState(() => generateFormData(course))
  const [formData, setFormData] = useState(() => generateFormData(course))

  const addNewModule = () => {
    setFormData((prevData: any) => ({
      ...prevData,
      modules: [...prevData.modules, { name: "", description: "", to_delete: "undefined" }],
    }))
  }

  const handleDeleteModule = (index: number) => {
    setFormData((prevData: any) => {
      const modules = prevData.modules.map((module: moduleType, i: number) =>
        i === index && module.id ? { ...module, to_delete: true } : module,
      )

      // If the module doesn't have an id, remove it from the array
      const updatedModules = modules.filter((module: any, i: number) => !(i === index && !module.id))

      return {
        ...prevData,
        modules: updatedModules,
      }
    })
  }

  const isFormChanged = useMemo(() => {
    return JSON.stringify(formData) !== JSON.stringify(originalFormData)
  }, [formData, originalFormData])

  const handleModuleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
    type: "title" | "desc",
  ) => {
    const newValue = e.target.value
    const newModule = [...formData.modules]
    if (type === "title") {
      newModule[index].name = newValue
    }

    if (type === "desc") {
      newModule[index].description = newValue
    }

    setFormData({
      ...formData,
      modules: newModule,
    })
  }

  const isFormValid = () => {
    const { title, training_type, modules, skill_type } = formData
    const isModuleValid = modules.every((module) => module.name.length > 0 && module.description.length > 0)

    if (!title || !training_type || !isModuleValid || !skill_type) {
      return false
    }

    return true
  }

  const [editCourse, { isLoading: isEditingCourse }] = useEditCourseMutation({})

  const handleEditCourse = async () => {
    const cleanedModules = formData.modules.map((module) => {
      if (module.to_delete === "undefined") {
        // eslint-disable-next-line no-unused-vars
        const { to_delete, ...rest } = module
        return rest
      }
      return module
    })

    const cleanedFormData = { ...formData, modules: cleanedModules }

    const callback = (res: any) => {
      if ([200, 201].includes(res?.code)) {
        toast.success(res.message)
        onClose()
      }
    }

    tryCatch(async () => {
      let res = await editCourse({ id: course?.course_id, data: cleanedFormData }).unwrap()
      callback(res)
    })
  }

  return {
    formData,
    setFormData,
    handleModuleChange,
    addNewModule,
    handleDeleteModule,
    isFormChanged,
    isFormValid,
    isEditingCourse,
    handleEditCourse,
  }
}
