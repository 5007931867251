import { useTable } from "hooks"
import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { useDeleteRoleMutation, useGetRoleQuery, useUpdateRoleStatusMutation } from "services/api/rolesSlice"
import { fullDateFormatter, shortDate } from "utils/dateFormatter"
import { useGetStaffByRoleQuery, useLazyExportStaffByRoleQuery } from "services/api/staffSlice"
import { tryCatch } from "utils/tryCatch"
import { exportData } from "utils/exportData"
import { Staff } from "types/staff"
import { stringifyArray } from "utils/stringifyArray"
import { usePermission } from "hooks/usePermission/usePermission"
import { PermissionModule } from "types/permission"
import { removeSpecificKeys } from "utils/removeSpecificKeys"

interface DateType {
  startDate: Date | null
  endDate: Date | null
}

export default function useViewRole() {
  const navigate = useNavigate()
  const { ROLES_AND_PERMISSION } = PermissionModule
  const { hasModulePermission } = usePermission()
  const [selectedStaff, setSelectedStaff] = useState<Staff>()
  const [date, setDate] = useState<DateType>({ startDate: null, endDate: null })
  const { handleSearch, params, changePage, selectLimit } = useTable()
  const [selectedStatus, setSelectedStatus] = useState<any>([])
  const [selectedAction, setSelectedAction] = useState("")
  const { pathname } = useLocation()
  const roleId = pathname.split("/")[3]

  const [exportStaffByRole, { isFetching: isExporting }] = useLazyExportStaffByRoleQuery()
  const [deleteRole, { isLoading: isDeletingRole }] = useDeleteRoleMutation()
  const [updateRoleStatus, { isLoading: isUpdatingRoleStatus }] = useUpdateRoleStatusMutation()
  const { data: fetchedRoles, isLoading: isLoadingRole } = useGetRoleQuery(roleId, {
    skip: !hasModulePermission(ROLES_AND_PERMISSION),
  })
  const role = fetchedRoles?.data
  const dateRange = date.endDate && { fromDate: shortDate(date.startDate), toDate: shortDate(date.endDate) }
  let enhancedParams = {
    role: role?.role_name,
    ...dateRange,
    status: stringifyArray(selectedStatus) ?? "",
    ...params,
  }
  const {
    data: fetchedStaff,
    isFetching: isLoadingStaff,
    refetch: refetchStaff,
  } = useGetStaffByRoleQuery(enhancedParams, { skip: !role?.role_name })

  const breadCrumbs = [{ title: "Settings" }, { title: "Roles & Permissions" }, { title: role?.role_name ?? "" }]

  const roleDetails = [
    {
      title: "Date Created",
      count: fullDateFormatter(role?.created_at),
      icon: "ph:calendar-blank",
    },
    {
      title: "Total No. of Staff",
      count: fetchedStaff?.count ?? 0,
      icon: "ph:users-three",
    },
    {
      title: "Active Staff",
      count: fetchedStaff?.activeCount ?? 0,
      theme: "bg-success-main",
      icon: "ph:users-three",
    },
    {
      title: "Inactive Staff",
      count: fetchedStaff?.inactiveCount ?? 0,
      theme: "bg-error-main",
      icon: "ph:users-three",
    },
    {
      title: "Deactivated Staff",
      count: fetchedStaff?.deactivatedCount ?? 0,
      theme: "bg-warning-dark",
      icon: "ph:users-three",
    },
  ]

  const manageRole = () => {
    tryCatch(async () => {
      const callback = (res: any) => {
        if (res?.code === 200) {
          toast.success(res?.message)
          setSelectedAction("")
          refetchStaff()
        }
      }

      if (selectedAction === "updateRoleStatus") {
        const status = role?.status === "active" ? "deactivated" : "active"
        const res = await updateRoleStatus({ roleId, status }).unwrap()
        callback(res)
      } else if (selectedAction === "deleteRole" && fetchedStaff?.staff?.length) {
        setSelectedAction("reassignRole")
      } else {
        const res = await deleteRole(roleId).unwrap()
        callback(res)
        if (res?.code === 200) navigate(-1)
      }
    })
  }

  const exportStaff = () => {
    tryCatch(async () => {
      const newParams = removeSpecificKeys(enhancedParams, ["role", "page", "noLimit", "sortBy"])
      const res = await exportStaffByRole({ roleName: role?.role_name ?? "", params: newParams }).unwrap()

      const data = res?.staff?.map((item: any) => {
        return {
          "Full name": `${item?.first_name} ${item?.last_name}`,
          "Email address": item?.email,
          "Added on": item?.added_on,
          Status: item?.status,
        }
      })
      exportData(data, `${role?.role_name} - Staff Members`)
    })
  }

  return {
    breadCrumbs,
    selectedAction,
    setSelectedAction,
    roleDetails,
    manageRole,
    staff: fetchedStaff,
    role,
    isDeletingRole,
    isUpdatingRoleStatus,
    handleSearch,
    selectedStatus,
    setSelectedStatus,
    changePage,
    isLoadingStaff,
    date,
    setDate,
    exportStaff,
    isExporting,
    setSelectedStaff,
    selectedStaff,
    refetchStaff,
    isLoadingRole,
    selectLimit,
    params,
  }
}
