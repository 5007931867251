import { Icon } from "@iconify/react"
import { paymentScheduleDetailsHeaders } from "constants/payments"
import useViewPaymentSchedule from "pages/payments/ViewPaymentUnit/useViewPaymentSchedule"
import { Button, Actions, ManageItem } from "components/core"
import ActivityTimeline from "./ActivityTimeline/ActivityTimeline"
import DeleteModal from "./DeleteModal/DeleteModal"
import { RenderIf } from "components/hoc"
import DownloadPaymentSchedule from "components/pages/Payments/GeneratePaymentSchedule/DownloadPaymentSchedule/DownloadPaymentSchedule"

const PaymentScheduleDetails = ({
  showActions,
  unit,
  showApproved,
}: {
  showActions: boolean
  unit: string
  showApproved: boolean
}) => {
  const {
    actions,
    paymentScheduleDetailsData,
    selectedAction,
    setSelectedAction,
    isFetchingActivityLogs,
    activityLogs,
    isMakingPayment,
    scheduleId,
    handleMakePayments,
    singlePaymentSchedule,
    isSigningScheduleAdvice,
    isUpdatingSchedule,
    handleSignAdvice,
    handleGeneratePaymentAdvice,
  } = useViewPaymentSchedule()

  return (
    <>
      <div className="bg-primary-900 min-h-[172px] rounded-lg p-6 flex flex-col justify-between">
        <div className="flex w-full justify-between">
          <h1 className="text-2xl text-primary-30 font-semibold">{singlePaymentSchedule?.intervention_title}</h1>
          <div className="flex gap-x-4">
            <RenderIf condition={unit === "payment-unit" && singlePaymentSchedule?.view_payment_advice_count === 0}>
              <Button
                disabled={
                  singlePaymentSchedule?.approval_count < 3 && singlePaymentSchedule?.view_payment_advice_count === 0
                }
                size="40"
                className="bg-primary-50 border-primary-200 text-primary-actions"
                onClick={handleGeneratePaymentAdvice}
                loading={isUpdatingSchedule}
              >
                Generate payment advice
              </Button>
            </RenderIf>
            <RenderIf
              condition={
                showApproved &&
                singlePaymentSchedule?.view_payment_advice_count > 0 &&
                !singlePaymentSchedule?.is_schedule_signed
              }
            >
              <Button
                disabled={singlePaymentSchedule?.approval_count < 3 && !singlePaymentSchedule?.is_schedule_signed}
                size="40"
                onClick={() => setSelectedAction("payment-approved")}
                className="bg-primary-50 border-primary-200 text-primary-actions"
              >
                Payment Approved?
              </Button>
            </RenderIf>
            <RenderIf
              condition={
                showActions && !singlePaymentSchedule?.initiated_payment && singlePaymentSchedule?.is_schedule_signed
              }
            >
              <Button
                size="40"
                className="bg-primary-50 border-primary-200 text-primary-actions"
                loading={isMakingPayment}
                // onClick={handleOpenDownload}
                onClick={() => setSelectedAction("confirm-payment")}
                disabled={!singlePaymentSchedule?.is_schedule_signed}
              >
                Make Payment
              </Button>
            </RenderIf>
            <Actions
              id="payment-schedule-actions"
              actions={actions}
              item={[]}
              buttonClassName="bg-primary-50 border-primary-200 cc-button--40"
            />
          </div>
        </div>
        <div className="h-[0.5px] bg-grey-200 my-6" />

        <div className="grid grid-cols-6 divide-x-[0.5px] divide-[#E6E6E6] child-not-first:pl-4">
          {paymentScheduleDetailsHeaders?.map((header, index) => (
            <div className="flex  flex-col gap-[2px]" key={header}>
              <span className="text-[#C6E4C4] text-xs font-medium">{header}</span>
              <p className="text-primary-30 font-normal text-sm mt-[2px] truncate">
                {paymentScheduleDetailsData[index]}
              </p>
            </div>
          ))}
        </div>
      </div>
      {selectedAction === "activity-timeline" && (
        <ActivityTimeline
          data={activityLogs}
          isLoading={isFetchingActivityLogs}
          onClose={() => setSelectedAction("")}
        />
      )}

      {selectedAction === "open-delete-modal" && (
        <DeleteModal closeModal={() => setSelectedAction("")} unit={unit ?? ""} id={scheduleId ?? ""} />
      )}

      {selectedAction === "download-schedule" && (
        <DownloadPaymentSchedule
          onClose={() => setSelectedAction("")}
          showMakePaymentButton={
            showActions && !singlePaymentSchedule?.initiated_payment && singlePaymentSchedule?.is_schedule_signed
          }
          isMakingPayment={isMakingPayment}
          makePayment={handleMakePayments}
        />
      )}

      {selectedAction === "confirm-payment" && (
        <ManageItem
          onClose={() => setSelectedAction("")}
          loading={isMakingPayment}
          caption="Are you sure you want to make this payment?"
          title="Make Payment"
          actionName="Yes, Proceed"
          onSubmit={handleMakePayments}
          cancelBtnText="No, Go Back"
          icon={
            <div className="bg-primary-50 w-12 h-12 flex items-center justify-center rounded-lg">
              <Icon icon="ph:circle-half" className="w-10 h-10 text-primary-actions" />
            </div>
          }
        />
      )}

      {selectedAction === "payment-approved" && (
        <ManageItem
          onClose={() => setSelectedAction("")}
          loading={isSigningScheduleAdvice}
          onSubmit={handleSignAdvice}
          title="Payment Approved"
          actionName="Yes, Proceed"
          cancelBtnText="No, Go Back"
          caption="Please confirm that the payment has been approved by the Program Manager"
          icon={
            <div className="bg-primary-50 w-12 h-12 flex items-center justify-center rounded-lg">
              <Icon icon="ph:circle-half" className="w-10 h-10 text-primary-actions" />
            </div>
          }
        />
      )}
    </>
  )
}

export default PaymentScheduleDetails
