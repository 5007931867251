import { SpinnerLoader } from "components/core"
import { RenderIf } from "components/hoc"
import { EmptyState } from "components/core/Table/EmptyState/EmptyState"
import { formatTimestamp } from "utils/dateFormatter"
import { Icon } from "@iconify/react"

interface Props {
  data: any
  isLoading: boolean
}

const ActivityTimeline = ({ data, isLoading }: Props) => {
  return (
    <div>
      <RenderIf condition={isLoading}>
        <SpinnerLoader />
      </RenderIf>
      <RenderIf condition={data?.activity_log?.length > 0 && !isLoading}>
        <div className="flex gap-y-5 flex-col">
          {data?.activity_log?.map((item: any, index: number) => (
            <div key={index} className="flex items-center gap-4">
              <div className="self-center">
                <div className=" w-10 h-10 rounded-full bg-primary-actions flex items-center justify-center self-center">
                  <Icon icon="ph:note" className="text-white" />
                </div>
              </div>

              <div className="text-body flex flex-wrap items-center gap-2">
                <p>{item?.activity}</p>
                <div className="bg-[#D9D9D9] rounded-full w-2 h-2" />
                <p>{item?.created_at ? formatTimestamp(item.created_at) : "--"}</p>
              </div>
            </div>
          ))}
        </div>
      </RenderIf>
      <RenderIf condition={data?.activity_log?.length === 0}>
        <EmptyState />
      </RenderIf>
    </div>
  )
}

export default ActivityTimeline
