import { useState } from "react"
import { useTable } from "hooks"
import { formatCurrency } from "utils/formatCurrency"
import { DateRangeType } from "types/common"
import {
  useGetInterventionBeneficiaryHistoryQuery,
  useLazyExportInterventionBeneficiaryHistoryQuery,
} from "services/api/coResponsibilitySlice"
import { useParams } from "react-router-dom"
import { fullDateFormatter, shortDate } from "utils/dateFormatter"
import { tryCatch } from "utils/tryCatch"
import { exportData } from "utils/exportData"
import { removeSpecificKeys } from "utils/removeSpecificKeys"
// import { useGetRolesQuery } from "services/api/rolesSlice"
// import { usePermission } from "hooks/usePermission/usePermission"
// import { PermissionModule } from "types/permission"

export default function useCoresponsibiltyBeneficiary() {
  //const { ROLES_AND_PERMISSION } = PermissionModule
  const { beneficiaryId, id: interventionId } = useParams()
  const { handleSearch, params, changePage, selectLimit } = useTable()
  const [selectedGender, setSelectedGender] = useState<any>([])
  const [selectedStatus, setSelectedStatus] = useState<any>([])
  const [date, setDate] = useState<DateRangeType>({ startDate: null, endDate: null })
  const dateRange = date.endDate && { fromDate: shortDate(date.startDate), toDate: shortDate(date.endDate) }
  //const { hasModulePermission } = usePermission()
  const { data, isLoading, isFetching } = useGetInterventionBeneficiaryHistoryQuery(
    {
      beneficiaryId,
      category: "co-responsibility",
      status: selectedStatus?.value,
      ...dateRange,
      ...removeSpecificKeys({ ...params }, ["sortBy"]),
    },
    { skip: !beneficiaryId },
  )
  const [exportHistory, { isFetching: isExporting }] = useLazyExportInterventionBeneficiaryHistoryQuery()

  const breadCrumbs = [{ title: "Co-responsibility" }, { title: data?.beneficiary?.name }]

  const overview = [
    { name: "Gender", value: data?.beneficiary?.gender },
    { name: "Intervention ID", value: interventionId },
    { name: "State", value: data?.beneficiary?.state },
    { name: "LGA", value: data?.beneficiary?.lga },
    { name: "Ward", value: data?.beneficiary?.ward },
  ]

  const exportHistoryProxy = () => {
    tryCatch(async () => {
      const res = await exportHistory({
        beneficiaryId,
        category: "co-responsibility",
        status: selectedStatus?.value,
        ...dateRange,
        ...params,
      }).unwrap()
      const data = res?.history?.map((item: any) => {
        return {
          "Intervention Title": item?.intervention_title,
          "Base Amount": formatCurrency(item?.amount),
          "Top Up Amount": formatCurrency(item?.top_amount),
          Status: item?.status,
          "Date Created": fullDateFormatter(item?.created_at),
          "Payment Cycle": item?.payment_cycle,
        }
      })
      exportData(data, "Intervention History")
    })
  }

  return {
    breadCrumbs,
    handleSearch,
    overview,
    changePage,
    selectLimit,
    params,
    selectedGender,
    setSelectedGender,
    selectedStatus,
    setSelectedStatus,
    exportHistoryProxy,
    beneficiary: data,
    date,
    setDate,
    isLoading,
    isFetching,
    isExporting,
  }
}
