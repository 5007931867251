/* eslint-disable no-unused-vars */
export enum PermissionModule {
  INTERVENTION = "Intervention",
  ROLES_AND_PERMISSION = "Roles and Permission",
  CONFIGURATION = "Configuration",
  DASHBOARD = "Dashboard",
  BENEFICIARIES = "Beneficiaries",
  DATA_MINING = "Data Mining",
  STAFF_MANAGEMENT = "Staff Mgmt",
  PAYMENT_UNIT = "Payment",
  // PAYMENT_UNIT = "Payment Unit",
  ACCOUNT = "Account",
  AUDIT = "Audit",
  PROGRAM_MANAGER = "Program Manager",
  TRANSACTIONS = "Transactions",
  CO_RESPONSIBILITY = "Co-responsibility",
  LIVELIHOOD = "Livelihood",
  TRAINING = "Training",
}

export enum AllPermissions {
  // Dashboard
  VIEW_DASHBOARD = "View Dashboard",

  // Beneficiaries
  VIEW_ALL_BENEFICIARIES = "View all Beneficiaries",
  VIEW_SINGLE_BENEFICIARY = "View single beneficiary",

  // Intervention
  CREATE_INTERVENTION = "Create Intervention",
  VIEW_ALL_INTERVENTIONS = "View all interventions",
  VIEW_SINGLE_INTERVENTION = "View single intervention",
  EDIT_INTERVENTION = "Edit intervention",
  VIEW_ALL_INTERVENTION_REQUESTS = "View all intervention requests",
  APPROVE_INTERVENTION = "Approve intervention",
  REJECT_INTERVENTION = "Reject intervention",

  // Configuration
  ADD_NEW_CONFIG = "Add new config",
  VIEW_SINGLE_CONFIG = "View single config",
  VIEW_ALL_CONFIG = "View all config",
  UPDATE_SINGLE_CONFIG = "Update single config",
  DELETE_CONFIG = "Delete config",

  // Data Mining
  MINE_DATA = "Mine data",
  VIEW_ALL_MINED_DATA = "View all mined data",
  VIEW_SINGLE_MINED_DATA = "View single mined data",
  UPDATE_BATCH = "Update batch",

  // Roles and Permissions
  CREATE_ROLE = "Create role",
  VIEW_ALL_ROLES = "View all roles",
  VIEW_SINGLE_ROLE = "View single role",
  EDIT_ROLE = "Edit role",
  DELETE_ROLE = "Delete role",
  REASSIGN_ROLE = "Reassign role",
  DEACTIVATE_ROLE = "Deactivate role",
  ADD_STAFF_TO_ROLE = "Add staff to a role",
  EDIT_STAFF = "Edit staff",

  // Staff Management
  ADD_STAFF = "Add staff",
  VIEW_ALL_STAFF = "View all staff",
  VIEW_SINGLE_STAFF = "View single staff",
  UPDATE_STAFF = "Update staff",
  DELETE_STAFF = "Delete staff",

  // Payment
  GENERATE_PAYMENT_SCHEDULE = "Generate payment schedule",
  VIEW_ALL_PAYMENT_SCHEDULE = "View all payment schedule",
  EXPORT_ALL_PAYMENT_SCHEDULE = "Export all payment schedule",
  VIEW_SINGLE_PAYMENT_SCHEDULE = "View single payment schedule",
  DOWNLOAD_SINGLE_PAYMENT_SCHEDULE = "Download single payment schedule",
  DELETE_PAYMENT_SCHEDULE = "Delete payment schedule",
  // DELETE_PAYMENT_SCHEDULE = "Delete",
  VIEW_ACTIVITY_TIMELINE = "View Activity Timeline",

  // Account, Audit, Program Manager
  VIEW_PAYMENT_SCHEDULE_ALL = "View Payment Schedule",
  APPROVE_DECLINE_PAYMENT_SCHEDULE = "Approve/Decline Payment Schedule",
  EXPORT_PAYMENT_SCHEDULE_ALL = "Download",
  DELETE_PAYMENT_SCHEDULE_ALL = "Delete",
  VIEW_SINGLE_SCHEDULE = "View Single Payment Schedule",

  // Program Manager (Unique)
  MAKE_PAYMENT = "Make Payment",

  // Transactions
  VIEW_TRANSACTIONS = "View transactions",
  VIEW_BATCHES = "View Batches",
  VIEW_BENEFICIARIES = "View Beneficiaries",

  // Coresponsibility
  VIEW_ALL_CORESPONSIBILITY = "View all co-responsibilities",
  VIEW_SINGLE_CORESPONSIBILITY = "View single co-responsibility",

  // Livelihood
  VIEW_SINGLE_MEMBER = "View single member",
  VIEW_ALL_LIVELIHOODS = "View all livelihoods",
  DELETE_COMMUNITY = "Delete community",
  VIEW_SINGLE_GROUP = "View single group",
  VIEW_COMMUNITY_LIST = "View community list",
  EDIT_GROUP = "Edit group",
  VIEW_SINGLE_COMMUNITY = "View single community",
  VIEW_SINGLE_LIVELIHOOD = "View single livelihood",
  DELETE_GROUP = "Delete group",
  ADD_GROUP = "Add group",
  ADD_COMMUNITY = "Add community",
  EDIT_COMMUNITY = "Edit community",
}
