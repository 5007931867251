export const paymentScheduleData = [
  {
    id: 1,
    title: "Monthly household payment for the poor and needy in the north",
    beneficiaries: 10234,
    scheduleId: "0001-A0M6-M028",
    paymentCycle: 1,
    dateCreated: "February 23, 2023 ",
    status: "paid",
    reviewStatus: "pending review (Account)",
  },
  {
    id: 2,
    title: "Monthly household payment for the poor and needy in the north",
    beneficiaries: 102,
    scheduleId: "0001-A0M6-M022",
    paymentCycle: 3,
    dateCreated: "February 23, 2023 ",
    status: "in-progress",
    reviewStatus: "approved (Account)",
  },
  {
    id: 3,
    title: "Monthly household payment for the poor and needy in the north",
    beneficiaries: 1034,
    scheduleId: "0001-A0M6-M027",
    paymentCycle: 5,
    dateCreated: "February 23, 2023 ",
    status: "unpaid",
    reviewStatus: "declined (Audit)",
  },
]

export const paymentData = [
  {
    id: 1,
    title: "Monthly household payment for the poor and needy in the north",
    beneficiaries: 10234,
    scheduleId: "0001-A0M6-M028",
    paymentCycle: 1,
    dateCreated: "February 23, 2023 ",
    amount: 300000,
    status: "paid",
  },
  {
    id: 2,
    title: "Monthly household payment for the poor and needy in the north",
    beneficiaries: 102,
    scheduleId: "0001-A0M6-M022",
    paymentCycle: 3,
    dateCreated: "February 23, 2023 ",
    amount: 300000,
    status: "in-progress",
  },
  {
    id: 3,
    title: "Monthly household payment for the poor and needy in the north",
    beneficiaries: 1034,
    scheduleId: "0001-A0M6-M027",
    paymentCycle: 5,
    dateCreated: "February 23, 2023 ",
    amount: 300000,
    status: "unpaid",
  },
]

export const paymentBeneficiaries = [
  {
    id: 1,
    fullname: "Aminat Adeola",
    state: "Cross River",
    dateofBirth: "April 28, 1970",
    nin: "2786111763",
    bvn: "3910793817",
    account_no: "1701815051",
    household_id: "8273877",
    source_register: "NASSCO",
    gender: "Male",
    intervention_no: "CC-12345-BS",
    image: "https://res.cloudinary.com/dygu2s48r/image/upload/v1715254613/w1cqrxf18ocok3ltndet.png",
  },
  {
    id: 1,
    fullname: "Aminat Adeola",
    state: "Cross River",
    dateofBirth: "December 28, 1970",
    nin: "2786111763",
    bvn: "3910793817",
    account_no: "1701815051",
    household_id: "8273877",
    source_register: "NASSCO",
    gender: "Male",
    intervention_no: "CC-12345-BS",
    image: "https://res.cloudinary.com/dygu2s48r/image/upload/v1715254613/w1cqrxf18ocok3ltndet.png",
  },
  {
    id: 1,
    fullname: "Aminat Adeola",
    state: "Cross River",
    dateofBirth: "April 28, 1970",
    nin: "2786111763",
    bvn: "3910793817",
    account_no: "1701815051",
    household_id: "8273877",
    source_register: "NASSCO",
    gender: "Male",
    intervention_no: "CC-12345-BS",
    image: "https://res.cloudinary.com/dygu2s48r/image/upload/v1715254613/w1cqrxf18ocok3ltndet.png",
  },
  {
    id: 1,
    fullname: "Aminat Adeola",
    state: "Cross River",
    dateofBirth: "April 28, 1970",
    nin: "2786111763",
    bvn: "3910793817",
    account_no: "1701815051",
    household_id: "8273877",
    source_register: "NASSCO",
    gender: "Male",
    intervention_no: "CC-12345-BS",
    image: "https://res.cloudinary.com/dygu2s48r/image/upload/v1715254613/w1cqrxf18ocok3ltndet.png",
  },
  {
    id: 1,
    fullname: "Aminat Adeola",
    state: "Cross River",
    dateofBirth: "April 28, 1970",
    nin: "2786111763",
    bvn: "3910793817",
    account_no: "1701815051",
    household_id: "8273877",
    source_register: "NASSCO",
    gender: "Male",
    intervention_no: "CC-12345-BS",
    image: "https://res.cloudinary.com/dygu2s48r/image/upload/v1715254613/w1cqrxf18ocok3ltndet.png",
  },
  {
    id: 1,
    fullname: "Aminat Adeola",
    state: "Cross River",
    dateofBirth: "April 28, 1970",
    nin: "2786111763",
    bvn: "3910793817",
    account_no: "1701815051",
    household_id: "8273877",
    source_register: "NASSCO",
    gender: "Male",
    intervention_no: "CC-12345-BS",
    image: "https://res.cloudinary.com/dygu2s48r/image/upload/v1715254613/w1cqrxf18ocok3ltndet.png",
  },
  {
    id: 1,
    fullname: "Aminat Adeola",
    state: "Cross River",
    dateofBirth: "April 28, 1970",
    nin: "2786111763",
    bvn: "3910793817",
    account_no: "1701815051",
    household_id: "8273877",
    source_register: "NASSCO",
    gender: "Male",
    intervention_no: "CC-12345-BS",
    image: "https://res.cloudinary.com/dygu2s48r/image/upload/v1715254613/w1cqrxf18ocok3ltndet.png",
  },
  {
    id: 1,
    fullname: "Aminat Adeola",
    state: "Cross River",
    dateofBirth: "April 28, 1970",
    nin: "2786111763",
    bvn: "3910793817",
    account_no: "1701815051",
    household_id: "8273877",
    source_register: "NASSCO",
    gender: "Male",
    intervention_no: "CC-12345-BS",
    image: "https://res.cloudinary.com/dygu2s48r/image/upload/v1715254613/w1cqrxf18ocok3ltndet.png",
  },
  {
    id: 1,
    fullname: "Aminat Adeola",
    state: "Cross River",
    dateofBirth: "April 28, 1970",
    nin: "2786111763",
    bvn: "3910793817",
    account_no: "1701815051",
    household_id: "8273877",
    source_register: "NASSCO",
    gender: "Male",
    intervention_no: "CC-12345-BS",
    image: "https://res.cloudinary.com/dygu2s48r/image/upload/v1715254613/w1cqrxf18ocok3ltndet.png",
  },
  {
    id: 1,
    fullname: "Aminat Adeola",
    state: "Cross River",
    dateofBirth: "April 28, 1970",
    nin: "2786111763",
    bvn: "3910793817",
    account_no: "1701815051",
    household_id: "8273877",
    source_register: "NASSCO",
    gender: "Male",
    intervention_no: "CC-12345-BS",
    image: "https://res.cloudinary.com/dygu2s48r/image/upload/v1715254613/w1cqrxf18ocok3ltndet.png",
  },
]

export const paymentScheduleActivityTimeline = [
  {
    time: "January 11, 2023 - 01:49pm",
    activity: "created a payment schedule",
    initiator: "Barakat Dolapo",
  },
  {
    time: "January 11, 2023 - 01:49pm",
    activity: "created a payment schedule",
    initiator: "Account Unit",
  },
  {
    time: "January 11, 2023 - 01:49pm",
    activity: "created a payment schedule",
    initiator: "Audit Unit",
  },
]

export const paymentAdviceDownload = [
  {
    state: "Abia",
    batch: 1,
    benCount: 1234,
    Amount: 123000,
  },
  {
    state: "Enugu",
    batch: 3,
    benCount: 1234,
    Amount: 123000,
  },
]

export const paymentStatus = [
  {
    label: "All",
    value: "",
  },
  {
    value: "PAID",
    label: "Paid",
  },
  {
    value: "UNPAID",
    label: "Unpaid",
  },
  {
    value: "IN-PROGRESS",
    label: "In-Progress",
  },
]

export const genderOptions = [
  { label: "All", value: "" },
  { label: "Male", value: "MALE" },
  { label: "Female", value: "FEMALE" },
]

export const genderOptionsII = [
  { label: "All", value: "" },
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
]

export const paymentsTransactions = [
  {
    id: 1,
    schedule_id: "PSC-7Y7878T6T6",
    total_amount: 90000,
    created_at: "2024-05-28T15:43:53.030Z",
    narration: 'success'
  },
  {
    id: 2,
    schedule_id: "PSC-7Y7878T6T6",
    total_amount: 4000,
    created_at: "2024-05-28T15:43:53.030Z",
    narration: 'pending'
  },
  {
    id: 3,
    schedule_id: "PSC-7Y7878T6T6",
    total_amount: 1000,
    created_at: "2024-05-28T15:43:53.030Z",
    narration: 'failed'
  }
]

export const transactionsBatches = [
  {
    id: 1,
    batch_ref: 'Pay-583785hdsui',
    number_of_beneficiaries: 80,
    total_amount: 500,
    transaction_fee: 10,
    created_at: "2024-05-28T15:43:53.030Z",
    status: 'failed'
  }
]

export const transactionBeneficiaries = [
  {
    id: "us-jgkngj3",
    name: 'SOS Thirsty',
    state: 'Lagos',
    account_no: "1234567890",
    gender: 'Male',
    amount: 60000,
    transaction_ref: "ref-8090",
    created_at: "2024-05-28T15:43:53.030Z",
    status: "Paid"
  }
]
