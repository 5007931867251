export const nigerianStates = [
  {
    label: "All",
    value: "",
  },
  {
    label: "FCT",
    value: "FCT",
  },
  {
    label: "Abia",
    value: "Abia",
  },
  {
    label: "Adamawa",
    value: "Adamawa",
  },
  {
    label: "Anambra",
    value: "Anambra",
  },
  {
    label: "Akwa Ibom",
    value: "Akwa Ibom",
  },
  {
    label: "Bauchi",
    value: "Bauchi",
  },
  {
    label: "Bayelsa",
    value: "Bayelsa",
  },
  {
    label: "Benue",
    value: "Benue",
  },
  {
    label: "Borno",
    value: "Borno",
  },
  {
    label: "Cross River",
    value: "Cross River",
  },
  {
    label: "Delta",
    value: "Delta",
  },
  {
    label: "Ebonyi",
    value: "Ebonyi",
  },
  {
    label: "Edo",
    value: "Edo",
  },
  {
    label: "Ekiti",
    value: "Ekiti",
  },
  {
    label: "Enugu",
    value: "Enugu",
  },
  {
    label: "Gombe",
    value: "Gombe",
  },
  {
    label: "Imo",
    value: "Imo",
  },
  {
    label: "Jigawa",
    value: "Jigawa",
  },
  {
    label: "Kaduna",
    value: "Kaduna",
  },
  {
    label: "Kano",
    value: "Kano",
  },
  {
    label: "Katsina",
    value: "Katsina",
  },
  {
    label: "Kebbi",
    value: "Kebbi",
  },
  {
    label: "Kogi",
    value: "Kogi",
  },
  {
    label: "Kwara",
    value: "Kwara",
  },
  {
    label: "Lagos",
    value: "Lagos",
  },
  {
    label: "Nasarawa",
    value: "Nasarawa",
  },
  {
    label: "Niger",
    value: "Niger",
  },
  {
    label: "Ogun",
    value: "Ogun",
  },
  {
    label: "Ondo",
    value: "Ondo",
  },
  {
    label: "Osun",
    value: "Osun",
  },
  {
    label: "Oyo",
    value: "Oyo",
  },
  {
    label: "Plateau",
    value: "Plateau",
  },
  {
    label: "Rivers",
    value: "Rivers",
  },
  {
    label: "Sokoto",
    value: "Sokoto",
  },
  {
    label: "Taraba",
    value: "Taraba",
  },
  {
    label: "Yobe",
    value: "Yobe",
  },
  {
    label: "Zamfara",
    value: "Zamfara",
  },
]

export const operators = [
  {
    label: "Equal to (=)",
    value: "Equal to (=)",
  },
  {
    label: "Greater than(>)",
    value: "Greater than(>)",
  },
  {
    label: "Less than (<)",
    value: "Less than (<)",
  },
  {
    label: "Less than or equal to (<=)",
    value: "Less than or equal to (<=)",
  },
  {
    label: "Greater than or equal to (>=)",
    value: "Greater than or equal to (>=)",
  },
]

export const paymentMechanism = [
  {
    label: "PSP",
    value: "PSP",
  },
  {
    label: "Switch",
    value: "Switch",
  },
]

export const interventionSteps = [
  "Basic Information",
  "Quota Structure",
  "Selection Criteria",
  "Payment",
  "Additional Eligibility",
  "Department Access",
  "Review",
]

export const eligibilityCriterias = [
  {
    label: "Pregnant woman",
    value: "pregnant woman",
  },
  {
    label: "Lactating mother",
    value: "lactating mother",
  },
  {
    label: "Girl child (5-15 years)",
    value: "girl child (5-15 years)",
  },
  {
    label: "Erosion control",
    value: "erosion control",
  },
]

export const compulsoryDepartment = [
  {
    label: "Intervention",
    value: "Intervention",
  },
  {
    label: "Beneficiary/Enrollment",
    value: "Beneficiary/Enrollment",
  },
  {
    label: "Payment",
    value: "Payment",
  },
]

export const livelihoods = [
  {
    label: "Agile/strong",
    value: "agile/strong",
  },
  {
    label: "Willing to engage in productive excercise",
    value: "willing to engage in productive excercise",
  },
  {
    label: "Productive age (15-60 years)",
    value: "productive age (15-60 years)",
  },
]

export const paymentRecurrence = [
  {
    label: "Daily",
    value: "daily",
  },
  {
    label: "Weekly",
    value: "weekly",
  },
  {
    label: "Bi-weekly",
    value: "bi-weekly",
  },
  {
    label: "Monthly",
    value: "monthly",
  },
  {
    label: "Quarterly",
    value: "quarterly",
  },
  {
    label: "Annually",
    value: "annually",
  },
]

export const beneficiariesHeader = [
  {
    name: "Full name",
    sort: false,
  },
  {
    name: "state",
    sort: false,
  },
  {
    name: "date of birth",
    sort: false,
  },
  {
    name: "nin",
    sort: false,
  },
  {
    name: "bvn",
    sort: false,
  },
  {
    name: "account number",
    sort: false,
  },
  {
    name: "gender",
    sort: false,
  },
  {
    name: "source register",
    sort: false,
  },
]

export const dataAnalysisHeader = [
  {
    name: "State",
    sort: false,
  },
  {
    name: "expected no. of beneficiary",
    sort: false,
  },
  {
    name: "no. of beneficiary received",
    sort: false,
  },
  {
    name: "amount disbursed",
    sort: false,
  },
  {
    name: "data received (%)",
    sort: false,
  },
  {
    name: "source register",
    sort: false,
  },
]

export const interventionStatus = [
  {
    label: "All",
    value: "",
  },
  {
    label: "Pending",
    value: "pending_approval,awaiting_start_date,rejected",
  },
  {
    label: "In-progress",
    value: "in-progress",
  },
  {
    label: "Completed",
    value: "completed",
  },
]

export const paymentTypes = [
  {
    label: "All",
    value: "",
  },
  {
    label: "Cash",
    value: "cash",
  },
  {
    label: "Kind",
    value: "kind",
  },
]

export const dataSources = [
  {
    label: "Nassco",
    value: "Nassco",
  },
  {
    label: "BVN",
    value: "BVN",
  },
  {
    label: "NIN",
    value: "NIN",
  },
]

export const additionalEligibility = [
  {
    label: "All",
    value: "",
  },
  {
    label: "Co-responsibility",
    value: "co-responsibility",
  },
  {
    label: "Livelihood",
    value: "livelihood",
  }
]
