const ProfileInfoLoader = () => {
  return (
    <div className="p-6 rounded-[8px] mt-3 gap-8 min-h-[194px] animate-pulse">
      <div className="flex flex-col gap-2 pb-[32px]">
        <div className="bg-neutral-200 h-6 w-1/3 rounded-full"></div>
        <div className="bg-neutral-200 h-5 w-1/2 rounded-full"></div>
      </div>
      <div className="border-b border-neutral-200"></div>
      <div className="mt-4 space-y-4">
        {[1, 2, 3].map((item) => (
          <div key={item} className="flex items-center space-x-4">
            <div className="bg-neutral-200 h-10 w-10 rounded-full"></div>
            <div className="flex-1 space-y-2">
              <div className="bg-neutral-200 h-4 w-1/3 rounded-full"></div>
              <div className="bg-neutral-200 h-4 w-1/2 rounded-full"></div>
              <div className="bg-neutral-200 h-4 w-1/4 rounded-full"></div>
            </div>
          </div>
        ))}
      </div>
      <div className="border-b border-neutral-200"></div>
      <div className="mt-4 space-y-4">
        {[1, 2].map((item) => (
          <div key={item} className="flex items-center space-x-4">
            <div className="bg-neutral-200 h-10 w-10 rounded-full"></div>
            <div className="flex-1 space-y-2">
              <div className="bg-neutral-200 h-4 w-1/3 rounded-full"></div>
              <div className="bg-neutral-200 h-4 w-1/2 rounded-full"></div>
              <div className="bg-neutral-200 h-4 w-1/4 rounded-full"></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ProfileInfoLoader
