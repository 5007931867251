import { useMemo, useEffect } from "react"
import { Icon } from "@iconify/react"
import { Modal, Input, Button } from "components/core"
import { RenderIf } from "components/hoc"
import useAddStaffBeneficiary from "./useAddStaff"
import SingleStaffUpload from "./SingleUpload/SingleUpload"
import BulkUploadStaffBeneficiaries from "./BulkUpload/BulkUpload"
import useBulkUpload from "./BulkUpload/useBulkUpload"

interface Props {
  onClose: () => void
  trainingId: string
}

const AddStaffTrainingBeneficiary = ({ onClose, trainingId }: Props) => {
  const {
    currentTab,
    setCurrentTab,
    tabs,
    staffId,
    setStaffId,
    isFetchingStaff,
    staffData,
    isAddSingleBeneficiary,
    handleCreateSingleTraining,
  } = useAddStaffBeneficiary()

  const { file, setFile, setCsvData, convertCsvtoJson, handleAddBulkBeneficiaries, isCreatingBulkTraining } =
    useBulkUpload({ currentTab })

  useEffect(() => {
    setStaffId("")
    setCsvData([])
    setFile([])
  }, [currentTab])

  const handleCreate = ({ id, onClose }: any) => {
    if (currentTab === "manual") {
      handleCreateSingleTraining({ id, onClose })
    } else if (currentTab === "bulk") {
      handleAddBulkBeneficiaries({ id, onClose })
    }
  }

  const isBtnDisabled = useMemo(() => {
    if (currentTab === "manual") {
      return !staffData || !staffId
    }

    if (currentTab === "bulk") {
      return Object.keys(file).length === 0
    }

    return false
  }, [staffData, staffId, file])

  return (
    <div>
      <Modal title="Add Staff Beneficiary" position="modal-right" width="w-[432px]" closeModal={onClose}>
        <div className="modal-right-body flex flex-col gap-y-4">
          <div className={`${currentTab === "manual" ? "pb-6 border-b border-b-grey-200 flex flex-col gap-y-6" : ""}`}>
            <div className="bg-[#E3E5EB] p-[4px] grid grid-cols-2 rounded-[4px]">
              {tabs.map((tab) => (
                <button
                  key={tab.name}
                  data-testid={tab.value}
                  type="button"
                  className={`
              flex items-center justify-center text-sm font-medium px-[16px] py-[6px] gap-x-2
              ${currentTab === tab.value ? "bg-white rounded-[4px] text-headings" : "text-paragraph"}
              `}
                  onClick={() => {
                    setCurrentTab(tab.value)
                  }}
                >
                  <Icon icon={tab.icon} className="w-4 h-4 text-inherit" />
                  {tab.name}
                </button>
              ))}
            </div>
            <RenderIf condition={currentTab === "manual"}>
              <Input
                label="Staff ID"
                type="text"
                name="staffid"
                value={staffId}
                id="staffid"
                onChange={(e: any) => {
                  setStaffId(e.target.value)
                }}
              />
            </RenderIf>
          </div>

          <RenderIf condition={currentTab === "manual"}>
            <SingleStaffUpload isFetchingStaff={isFetchingStaff} staffData={staffData} staffId={staffId} />
          </RenderIf>

          <RenderIf condition={currentTab === "bulk"}>
            <BulkUploadStaffBeneficiaries convertCsvtoJson={convertCsvtoJson} uploadedFile={file} />
          </RenderIf>

          <div className="modal-right-button-container mb-3">
            <Button
              theme="primary"
              className="min-w-[105px] mr-[8px] p-[8px]"
              type="button"
              data-testid="submit-btn"
              onClick={() => handleCreate({ id: trainingId, onClose })}
              loading={isAddSingleBeneficiary || isCreatingBulkTraining}
              disabled={isBtnDisabled || isAddSingleBeneficiary || isCreatingBulkTraining}
            >
              Add Beneficiary
            </Button>
            <Button theme="transparent" className="w-[105px] px-[30px]" type="button" onClick={onClose}>
              Discard
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default AddStaffTrainingBeneficiary
