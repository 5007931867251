import { useEffect } from "react"
import { FadeLoader } from "react-spinners"
import { toast } from "react-toastify"

export const MiningStatus = ({
  percentage,
  onClose,
  refetchMineHistory,
  isUpdating,
}: {
  percentage?: string
  onClose?: () => void
  refetchMineHistory?: () => void
  isUpdating?: boolean
}) => {
  useEffect(() => {
    if (percentage === "100%" && onClose) {
      toast.success("Data mining completed")
      refetchMineHistory && refetchMineHistory()
      setTimeout(() => {
        onClose()
      }, 2000)
    }
  }, [percentage, onClose])

  return (
    <div className="h-[85vh] grid text-center gap-3 justify-items-center content-center ">
      <FadeLoader color="#282828" />
      {!isUpdating ? (
        <div className="text-[#1F1F1F] font-[500] text-[18px]">{percentage ?? "0%"} Uploaded</div>
      ) : (
        <div className="text-[#1F1F1F] font-[500] text-[18px]">Updating...</div>
      )}
      <div className="text-[#5C5C5C] font-[400] text-[14px] w-[329px]">
        Please wait while we update selected data, depending on the file size, completion time may vary.
      </div>
    </div>
  )
}
