import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { useDeleteReportMutation } from "services/api/reportSlice"
import { DateRangeType, Period } from "types/common"
import { tryCatch } from "utils/tryCatch"

export default function useReportSummary() {
  const navigate = useNavigate()
  const [selectedReportId, setSelectedReportId] = useState("")
  const [periodFilter, setPeriodFilter] = useState({
    type: Period.CUSTOM,
    selected: "",
  })
  const [date, setDate] = useState<DateRangeType>({ startDate: null, endDate: null })

  const [deleteReportMutation, { isLoading: isDeletingReport }] = useDeleteReportMutation()

  const actions = [
    {
      name: "View Report",
      method: (item: any) => navigate(`${item?.report_id}`),
    },
    {
      name: "Delete",
      method: (item: any) => setSelectedReportId(item?.report_id),
    },
  ]

  const deleteReport = () => {
    tryCatch(async () => {
      const res = await deleteReportMutation(selectedReportId).unwrap()
      if ([200, 201].includes(res?.code)) {
        toast.success(res?.message)
        setSelectedReportId("")
      }
    })
  }

  return {
    actions,
    deleteReport,
    isDeletingReport,
    selectedReportId,
    setSelectedReportId,
    date,
    navigate,
    setDate,
    periodFilter,
    setPeriodFilter,
  }
}
