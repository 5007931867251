import { Icon } from "@iconify/react"
import { Breadcrumb, Button, Status, Table } from "components/core"
import { RenderIf } from "components/hoc"
import { grevianceHistoryHeader } from "constants/reports"
import { fullDateFormatter } from "utils/dateFormatter"
import useGRMReport from "./useGRMReport"

const GRMReport = () => {
  const { breadcrumbs, report, changePage, isLoadingReport, selectLimit, isExporting, exportReport } = useGRMReport()

  return (
    <div className="w-full">
      <Breadcrumb options={breadcrumbs} showBack={true} />
      <h1 className="text-xl text-headers font-semibold mt-6">{report?.reportTitle}</h1>
      <RenderIf condition={!!report?.grievances?.length}>
        <div className="flex justify-end mb-6">
          <Button type="button" size="40" theme="transparent" disabled={isExporting} onClick={exportReport}>
            <Icon icon="uiw:download" className="text-paragraph w-[18px] h-[18px] mr-3" />
            {isExporting ? "Exporting..." : "Export"}
          </Button>
        </div>
      </RenderIf>
      <Table
        headers={grevianceHistoryHeader}
        paginateData={!!report?.grievances?.length}
        data={report?.grievances ?? []}
        onPageChange={changePage}
        selectLimit={selectLimit}
        totalCount={Number(report?.totalGrievanceCount || 0)}
        perPage={report?.limit}
        page={Number(report?.page ?? 0)}
        loading={isLoadingReport}
      >
        <>
          {report?.grievances?.map((item: any) => (
            <tr data-testid={item?.id} key={item?.id} className="cc-custom-table-data-row">
              <td>
                <div>
                  <p>{item?.complaintName}</p>
                  <p className="text-xs font-semibold">{item?.grievanceId}</p>
                </div>
              </td>
              <td>{item?.categoryName}</td>
              <td>{item?.subCategoryName}</td>
              <td>{item?.assignedTo}</td>
              <td>{item.level}</td>
              <td>
                <Status status={item?.status} />
              </td>
              <td>{fullDateFormatter(item?.createdAt)}</td>
            </tr>
          ))}
        </>
      </Table>
    </div>
  )
}

export default GRMReport
