/* eslint-disable no-unused-vars */
import { useTable } from "hooks"
import { useState } from "react"
import { DateType } from "types/beneficiary"
import { ChangeEvent } from "react"
import { tryCatch } from "utils/tryCatch"
import { toast } from "react-toastify"
import { useUpdateBeneficiaryMutation } from "services/api/beneficiarySlice"

interface FormData {
  title: string
  beneficiaries: File | null
}

interface SourceOption {
  image: string
  label: string
  value: string
}

export default function useUpdateBeneficiary(
  onCloseUpdate?: any,
  setOpenCompleteUpdateModal?: (value: boolean) => void,
  setUpdateResponse?: any,
) {
  const [date, setDate] = useState<DateType>({ startDate: null, endDate: null })
  const [uploadedFile, setUploadedFile] = useState<any>([])
  const { handleSearch, params } = useTable()

  const [formData, setFormData] = useState<FormData>({
    title: "",
    beneficiaries: null,
  })
  const [updateBeneficiary, { isLoading: isUpdating }] = useUpdateBeneficiaryMutation()

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleFileUpload = (e: any) => {
    const file = e.target.files[0]
    setUploadedFile({
      name: file?.name,
      size: (file?.size / 1000).toFixed(2),
    })
    setFormData({
      ...formData,
      beneficiaries: file,
    })
  }

  const handleFileDelete = () => {
    setUploadedFile([])
    setFormData({
      ...formData,
      beneficiaries: null,
    })
  }

  const handleSubmitBtn = (e?: any, setOpenPercentageModal?: (value: boolean) => void) => {
    e.preventDefault()
    setOpenPercentageModal && setOpenPercentageModal(true)
    tryCatch(async () => {
      const callback = (res: any) => {
        if ([200, 201].includes(res?.code)) {
          toast.success(res?.message)
          onCloseUpdate()
          setOpenCompleteUpdateModal && setOpenCompleteUpdateModal(true)
        }
      }
      const payload = new FormData()

      const formDataKeys = Object.keys(formData) as Array<keyof typeof formData>
      formDataKeys.forEach((key) => {
        if (key !== "beneficiaries" && formData[key]) {
          payload.append(key, formData[key])
        }
      })
      if (formData.beneficiaries) {
        payload.append("beneficiaries", formData.beneficiaries)
      }
      const res = await updateBeneficiary(payload).unwrap()
      callback(res)
      setUpdateResponse(res)
    })
  }

  const validateFormData = () => {
    const errors = []
    if (!formData.title) {
      errors.push({ field: "title", message: "Title is required" })
    }
    if (!formData.beneficiaries) {
      errors.push({ field: "beneficiaries", message: "File is required" })
    }
    return errors
  }

  return {
    formData,
    setFormData,
    handleChange,
    handleSubmitBtn,
    handleFileUpload,
    date,
    setDate,
    handleSearch,
    validateFormData,
    isUpdating,
    uploadedFile,

    setUploadedFile,
    handleFileDelete,
  }
}
