export const staffs = [
  {
    id: 1,
    user_type: 'NCTU',
    staff_number: "NCIS-0127419",
    email: "reginaphalange@email.com",
    first_name: "Regina",
    last_name: "Phalange",
    phone_number: '0819872201',
    role: "Administrator",
    created_at: "2024-02-27T09:33:38.605Z",
    updated_at: "2024-02-27T09:33:38.605Z",
    status: 'active'
  },
  {
    id: 2,
    staff_number: "NCIS-0127419",
    email: "kenadams@email.com",
    first_name: "Ken",
    last_name: "Adams",
    user_type: 'LCTU',
    phone_number: '0819872201',
    role: "Intervention Officer",
    created_at: "2024-02-27T09:33:38.605Z",
    updated_at: "2024-02-27T09:33:38.605Z",
    status: 'active'
  }
]
export const staffManagementHeaders = [
    {
        name: 'staff name',
        sort: false,
        action: null
    },
    {
      name: 'Staff Id',
      sort: false,
      action: null
    },
    {
        name: 'Employee Id',
        sort: false,
        action: null
    },
    {
      name: 'Phone',
      sort: false,
      action: null
  },
    {
        name: 'Role Assigned',
        sort: false,
        action: null
    },
    {
        name: 'Status',
        sort: false,
        action: null
    },
    {
        name: '',
        sort: false,
        action: null
    },
]
export const uploadPreviewHeaders = [
  {
    name: 'Full Name',
    sort: false,
    action: null
  },
  {
    name: 'Assigned Role',
    sort: false,
    action: null
  },
  {
    name: 'Email Address',
    sort: false,
    action: null
  },
  {
    name: 'User Type',
    sort: false,
    action: null
  },
  {
    name: 'ID',
    sort: false,
    action: null
  },
  {
    name: 'Phone Number(optional)',
    sort: false,
    action: null
  },
]
export const defaultFilter = [
  {
    label: 'All',
    value: '',
  }
]
