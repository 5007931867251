/* eslint-disable no-unused-vars */
import React, { forwardRef } from "react"
import "./radioButton.css"
// eslint-disable-next-line react/prop-types

interface RadioButtonProps extends React.HTMLAttributes<HTMLInputElement> {
  /**
   * className attribute for checkbox
   */
  className?: string
  customClass?: string
  /**
   * className attribute for checkbox
   */
  label?: string
  variant?: string
  /**
   * Name attribute for checkbox
   */
  name: string
  /**
   * Value of checkbox
   */
  value?: any
  /**
   * Checked state for checkbox
   */
  checked: boolean
  /**
   * Disabled state for checkbox
   */
  disabled?: boolean
  /**
   * How large should the checkbox be?
   */
  size?: "16" | "18" | "24" | "32" | "36" | "44"
  /**
   * Other unknown attributes
   */
  [x: string]: any
}

/**
 * Checkbox component for user selection
 */

const RadioButton: React.FC<RadioButtonProps> = forwardRef(
  (
    {
      className,
      name,
      value,
      label,
      variant,
      checked = false,
      disabled = false,
      size = "16", // Default value for size
      customClass,
      ...props
    },
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const sizes: { [key in NonNullable<RadioButtonProps["size"]>]: string } = {
      "16": "cc-radio--16",
      "18": "cc-radio--18",
      "24": "cc-radio--24",
      "32": "cc-radio--32",
      "36": "cc-radio--36",
      "44": "cc-radio--44",
    }
    // Check that size is a valid key in the sizes object
    const sizeClass = sizes[size as keyof typeof sizes] || ""
    return (
      <div className={`${variant} flex items-center`}>
        <input
          ref={ref}
          type="radio"
          name={name}
          value={value}
          checked={checked}
          disabled={disabled}
          data-testid={`test-${name}`}
          className={`${customClass} cc-radio, ${size ? sizeClass : ""}, cc-radio-${className} cursor-pointer`}
          {...props}
        />
        <label className="ml-3 font-medium text-body text-sm">{label}</label>
      </div>
    )
  },
)

RadioButton.displayName = "SearchDropDown"
export default RadioButton
