export const complaintType = [
  {
    label: "Beneficiary",
    value: "beneficiary",
  },
  {
    label: "Non Beneficiary",
    value: "non-beneficiary",
  },
]

export const assignedStatus = [
  {
    label: "Assigned",
    value: "assigned",
  },
  {
    label: "Unassigned",
    value: "unassigned",
  },
]

export const complaintLevel = [
  {
    label: "Local",
    value: "Local",
  },
  {
    label: "State",
    value: "State",
  },
  {
    label: "National",
    value: "National",
  },
]

export const complaintMode = [
  {
    label: "Select",
    value: ""
  },

  {
    label: "Telephone",
    value: "telephone",
  },
  {
    label: "In Person",
    value: "in person",
  },
  {
    label: "Letter",
    value: "letter",
  },
  {
    label: "Email",
    value: "email",
  },
  {
    label: "Social Media",
    value: "social media",
  },
  {
    label: "CSOs",
    value: "csos",
  },
  {
    label: "TPMs",
    value: "tpms",
  },
]

export const grievanceSatisfiedOptions = [
  {label: "Select", value: ""},
  { label: "Satisfied", value: "satisfied" },
  { label: "Partly Satisfied", value: "partly satisfied" },
  { label: "Not Satisfied", value: "not satisfied" },
]
