import { tryCatch } from "./tryCatch"

export const removeKeysWithEmptyValues = (obj: any) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === "") delete obj[key]
  })
  return obj
}

export const convertObjectToArrayOfObject = (obj: { [key: string]: string }, arrayKeys: string[]) => {
  let result = [] as any[]
  tryCatch(() => {
    result = Object.entries(obj).map(([key, value]) => ({
      [arrayKeys[0]]: key,
      [arrayKeys[1]]: value,
    }))
  })
  return result
}
