import { createApi } from "@reduxjs/toolkit/query/react"
import { createSlice } from "@reduxjs/toolkit"
import { axiosBaseQuery } from "services/axiosBaseQuery"

const baseUrl = `${process.env.REACT_APP_PAYMENTS_BASE_URL}/`

export const trainingSlice = createApi({
  reducerPath: "trainingSlice",
  baseQuery: axiosBaseQuery({ baseUrl }),
  tagTypes: [
    "Training",
    "Trainings",
    "Courses",
    "Course",
    "Training-Analytics",
    "Course-Analytics",
    "Training-Beneficiaries",
    "Training-Beneficiary",
  ],
  refetchOnMountOrArgChange: true,
  endpoints(build) {
    return {
      createCourse: build.mutation<any, any>({
        query: (data) => ({ url: `trainings/courses`, method: "post", data }),
        invalidatesTags: ["Courses", "Course-Analytics"],
      }),
      fetchTrainingAnalytics: build.query<any, any>({
        query: () => ({ url: `trainings/analytics`, method: "get" }),
        transformResponse: (response: any) => response.data,
        providesTags: ["Training-Analytics"],
      }),
      fetchTrainings: build.query<any, any>({
        query: ({ params }) => ({ url: "trainings", method: "get", params }),
        transformResponse: (response: any) => response.data,
        providesTags: ["Trainings", "Training-Analytics"],
      }),
      fetchCourses: build.query<any, any>({
        query: (params) => ({ url: "trainings/courses", method: "get", params }),
        transformResponse: (response: any) => response.data,
        providesTags: ["Courses"],
      }),
      deleteCourse: build.mutation<any, any>({
        query: (id) => ({ url: `trainings/courses/${id}`, method: "delete" }),
        invalidatesTags: ["Courses", "Course-Analytics"],
      }),
      fetchCourseAnalytics: build.query<any, any>({
        query: () => ({ url: "trainings/courses/analytics", method: "get" }),
        transformResponse: (response: any) => response.data,
        providesTags: ["Course-Analytics", "Courses"],
      }),
      fetchSingleCourse: build.query<any, any>({
        query: ({ id }) => ({ url: `trainings/courses/${id}`, method: "get" }),
        transformResponse: (response: any) => response.data,
        providesTags: ["Course"],
      }),
      editTraining: build.mutation<any, any>({
        query: ({ id, data }) => ({ url: `trainings/${id}`, method: "patch", data }),
        invalidatesTags: ["Trainings", "Training"],
      }),
      deleteModule: build.mutation<any, any>({
        query: (id) => ({ url: `trainings/modules/${id}`, method: "delete" }),
        invalidatesTags: ["Course", "Courses"],
      }),
      editCourse: build.mutation<any, any>({
        query: ({ id, data }) => ({ url: `trainings/courses/${id}`, method: "patch", data }),
        invalidatesTags: ["Courses", "Course-Analytics"],
      }),
      createTraining: build.mutation<any, any>({
        query: (data) => ({ url: `trainings`, method: "post", data }),
        invalidatesTags: ["Trainings", "Training", "Training-Analytics"],
      }),
      deleteTraining: build.mutation<any, any>({
        query: (id) => ({ url: `trainings/${id}`, method: "delete" }),
        invalidatesTags: ["Trainings", "Training-Analytics"],
      }),
      fetchSingleTraining: build.query<any, any>({
        query: ({ id, params }) => ({ url: `trainings/${id}`, method: "get", params }),
        transformResponse: (response: any) => response.data,
        providesTags: ["Training"],
      }),
      fetchTrainingBeneficiary: build.query<any, any>({
        query: (params) => ({ url: `trainings/beneficiaries`, method: "get", params }),
        transformResponse: (response: any) => response.data,
        providesTags: ["Training", "Training-Beneficiaries"],
      }),
      createTrainingBeneficiary: build.mutation<any, any>({
        query: (data) => ({ url: `trainings/beneficiaries-upload`, method: "post", data }),
        invalidatesTags: ["Training-Beneficiaries", "Training"],
      }),
      fetchTrainingAttendance: build.query<any, any>({
        query: ({ id, params }) => ({ url: `trainings/attendances/${id}`, method: "get", params }),
        transformResponse: (response: any) => response.data,
        providesTags: ["Training", "Training-Beneficiary"],
      }),
      markAttendance: build.mutation<any, any>({
        query: ({ id, data }) => ({ url: `trainings/attendances/${id}`, method: "patch", data }),
        invalidatesTags: ["Training-Beneficiary"],
      }),
    }
  },
})

export const {
  useCreateCourseMutation,
  useFetchCoursesQuery,
  useFetchTrainingsQuery,
  useFetchTrainingAnalyticsQuery,
  useDeleteCourseMutation,
  useFetchCourseAnalyticsQuery,
  useFetchSingleCourseQuery,
  useEditTrainingMutation,
  useDeleteModuleMutation,
  useEditCourseMutation,
  useCreateTrainingMutation,
  useDeleteTrainingMutation,
  useFetchSingleTrainingQuery,
  useFetchTrainingBeneficiaryQuery,
  useCreateTrainingBeneficiaryMutation,
  useFetchTrainingAttendanceQuery,
  useMarkAttendanceMutation,
} = trainingSlice

const training = createSlice({
  name: "training",
  initialState: {},
  reducers: {},
})

export const trainingReducer = training.reducer
