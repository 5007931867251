export const TrainingType = [
  {
    label: "Staff",
    value: "staff",
  },
  {
    label: "Citizen",
    value: "citizen",
  },
]

export const StaffTrainingUnit = [
  {
    label: "NTOT",
    value: "ntot",
  },
  {
    label: "TOT",
    value: "tot",
  },
  {
    label: "CTF",
    value: "ctf",
  },
]

export const BeneficiaryAttendanceStatus = [
  {
    label: "Attended",
    value: "attended",
  },
  {
    label: "Absent",
    value: "absent",
  },
  {
    label: "Unknown",
    value: "unknown",
  },
]

export const CitizenTrainingUnit = [
  {
    label: "Beneficiary",
    value: "beneficiary",
  },
  {
    label: "Livelihood",
    value: "livelihood",
  },
]

export const TrainingBeneficiaryData = [
  {
    id: "TC-68T78",
    full_name: "Mr Money-LunguBoy",
    attendance: 90,
    total_attendance: 100,
    phone_number: "08067946321",
    state: "Lagos",
    lga: "money island",
    gender: "Male",
  },
]

export const skillTypes = [
  {
    value: "soft skills",
    label: "Soft Skills",
  },
  {
    value: "hard skills",
    label: "Hard Skills",
  },
]

export const testCourses = [
  {
    label: "Course one",
    value: "Course-nfjsdkbfhj",
  },
  {
    label: "Course two",
    value: "Course-jsfjsknfjkj",
  },
  {
    label: "Course three",
    value: "Course-bhjhjsbhj",
  },
]

