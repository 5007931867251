import { ChangeEvent, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { useLazyGetInterventionsQuery } from "services/api/interventionSlice"
import { useGenerateReportMutation } from "services/api/reportSlice"
import { ISODateFormatter, shortDate } from "utils/dateFormatter"
import { removeKeysWithEmptyValues } from "utils/objectFormatter"
import { tryCatch } from "utils/tryCatch"

const initialDate = {
  createdDate: { startDate: null, endDate: null },
  reviewDate: { startDate: null, endDate: null },
  startDate: { startDate: null, endDate: null },
  endDate: { startDate: null, endDate: null },
}

const initialFormData = {
  reportTitle: "",
  interventions: [],
  fromTotalAmount: "",
  toTotalAmount: "",
  status: [],
  reviewStatus: [],
  paymentCycle: "",
  paymentRecurrence: [],
  psp: [],
}

export default function useAccountGenerateReport() {
  const navigate = useNavigate()
  const [showPreview, setShowPreview] = useState(false)
  const [date, setDate] = useState<any>({ ...initialDate })
  const [formData, setFormData] = useState({ ...initialFormData })

  const isDateRangeEmpty = (dateRange: { startDate: any; endDate: any }) => {
    return dateRange.startDate === null && dateRange.endDate === null
  }

  const disableApply = [date.createdDate, date.reviewDate, date.startDate, date.endDate].every(isDateRangeEmpty)

  const [generateReportMutation, { isLoading: isGeneratingReport }] = useGenerateReportMutation()
  const [getInterventionsMutation, { data: interventionsData, isFetching: isFetchingInterventions }] =
    useLazyGetInterventionsQuery()

  const getInterventions = () => {
    tryCatch(async () => {
      await getInterventionsMutation({
        fromCreatedDate: shortDate(date.createdDate.startDate),
        toCreatedDate: shortDate(date.createdDate.endDate),
        fromReviewDate: shortDate(date.reviewDate.startDate),
        toReviewDate: shortDate(date.reviewDate.endDate),
        fromStartDate: shortDate(date.startDate.startDate),
        toStartDate: shortDate(date.startDate.endDate),
        fromEndDate: shortDate(date.endDate.startDate),
        toEndDate: shortDate(date.endDate.endDate),
      })
    })
  }

  const enhancedIntervention = useMemo(() => {
    const { interventions } = interventionsData || {}
    if (!interventions) return interventions === null ? null : []

    return interventions.map(({ intervention_title, intervention_id }) => ({
      label: intervention_title,
      value: intervention_id,
    }))
  }, [interventionsData])

  const breadcrumbs = [{ title: "Account" }, { title: "Report Parameters" }]

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const proceed = (e: any) => {
    e.preventDefault()
    setShowPreview(true)
  }

  const close = () => {
    setShowPreview(false)
  }

  const checkDate = (date: Date) => {
    return date ? ISODateFormatter(date) : ""
  }

  const generateReport = () => {
    tryCatch(async () => {
      const {
        reportTitle,
        interventions,
        fromTotalAmount,
        toTotalAmount,
        status,
        psp,
        reviewStatus,
        paymentCycle,
        paymentRecurrence,
      } = formData
      const payload = {
        reportType: "account",
        reportTitle,
        paymentCycle: paymentCycle ? paymentCycle.split(",") : "",
        paymentRecurrence: paymentRecurrence?.length ? paymentRecurrence?.map((item: any) => item.value) : "",
        reviewStatus: reviewStatus?.length ? reviewStatus?.map((item: any) => item.value) : "",
        psp: psp?.length ? psp?.map((item: any) => item.value) : "",
        fromTotalAmount: fromTotalAmount ? Number(fromTotalAmount) : "",
        toTotalAmount: toTotalAmount ? Number(toTotalAmount) : "",
        paymentStatus: status?.length ? status?.map((item: any) => item.value) : "",
        interventionIds: interventions.length ? interventions?.map((item: any) => item.value) : "",
        fromCreatedDate: checkDate(date.createdDate.startDate),
        toCreatedDate: checkDate(date.createdDate.endDate),
        fromReviewDate: checkDate(date.reviewDate.startDate),
        toReviewDate: checkDate(date.reviewDate.endDate),
        fromStartDate: checkDate(date.startDate.startDate),
        toStartDate: checkDate(date.startDate.endDate),
        fromEndDate: checkDate(date.endDate.startDate),
        toEndDate: checkDate(date.endDate.endDate),
      }

      const res = await generateReportMutation(removeKeysWithEmptyValues(payload)).unwrap()

      if ([200, 201].includes(res?.code)) {
        toast.success(res?.message)
        navigate(`/reports/account/${res?.data?.report_id}`)
      }
    })
  }

  const refresh = () => {
    setFormData(initialFormData)
    setDate(initialDate)
  }

  return {
    breadcrumbs,
    formData,
    setFormData,
    handleChange,
    date,
    setDate,
    proceed,
    showPreview,
    close,
    disableApply,
    interventions: enhancedIntervention,
    isFetchingInterventions,
    generateReport,
    isGeneratingReport,
    refresh,
    getInterventions,
  }
}
