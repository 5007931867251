import { Icon } from "@iconify/react"
import { Input } from "components/core"

interface Props {
  state: any
}

export const ReportDetails = ({ state }: Props) => {
  const { formData } = state

  return (
    <div>
      <p className="mb-[8px] font-medium text-sm text-headers">
        <Icon icon="ph:pencil-line" className="inline w-[20px] h-[20px]" /> REPORT DETAILS
      </p>
      <div className="pl-[24px] w-[300px]">
        <table className="w-full">
          <thead>
            <tr className="text-left h-8 border text-xs text-headers">
              <th className="pl-3 font-medium border-r col-span-6 py-2">Report Title</th>
            </tr>
          </thead>
          <tbody>
            <tr className="border h-12 text-sm text-body">
              <td className="px-3">
                <Input
                  type="text"
                  id="reportTitle"
                  name="reportTitle"
                  // readOnly={loading}
                  placeholder="Enter text"
                  value={formData?.reportTitle}
                  onChange={state.handleChange}
                  className="!w-full"
                  variant="border-none !p-0 !h-[40px]"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}
