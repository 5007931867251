import { monthSummaryOptions } from "constants/global"
import { Period } from "types/common"
import { shortDate } from "./dateFormatter"

export const currentMonthValue = () => {
  const currentMonthIndex = new Date().getMonth() // Returns the month index (0 for January, 11 for December)
  return monthSummaryOptions[currentMonthIndex]
}

export const getReportYear = (periodFilter: any) => {
  let year = ""

  if (periodFilter.type === Period.MONTHLY && periodFilter.selected) {
    year = String(new Date().getFullYear())
  } else if (periodFilter.type === Period.YEARLY) {
    year = periodFilter.selected
  }

  return year
}

export const getReportCustomDateRange = (date: any, periodFilter: any) => {
  let range = {}
  if (date.startDate && date.endDate) {
    range = {
      fromDate: periodFilter.type === Period.CUSTOM ? shortDate(date.startDate) : "",
      toDate: periodFilter.type === Period.CUSTOM ? shortDate(date.endDate) : "",
    }
  }

  return range
}

export function calculatePercentage(numbers: number[]): number[] {
  const total = numbers.reduce((sum, num) => sum + Number(num), 0)
  const fixed = numbers.map((num) => ((num / total) * 100).toFixed(1))
  return fixed.map((item) => Number(item))
}
