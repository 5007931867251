import axios from 'axios';
import { getCookie } from 'utils/getCookie';
import { refreshUserToken } from "./token.service.js"

let isRefreshing = false;
let failedRequests = [];
const instance = axios.create({ crossdomain: true });

const processFailedRequests = (error, token = null) => {
	failedRequests.forEach((prom) => {
		if (error) {
			prom.reject(error);
		} else {
			if (token) prom.config.headers['Authorization'] = `Bearer ${token}`;
			prom.resolve(instance(prom.config));
		}
	});
	failedRequests = [];
};

instance.interceptors.request.use((config) => {
	const token = getCookie("token")
	config.headers["Authorization"] = `Bearer ${token}`;
	return config;
});

instance.interceptors.response.use(
	(response) => {
		return response;
	},
	async (error) => {
		const myErr = ["session expired", "jwt malformed", 'invalid token']
		// const myErr = ["session expired", "token is invalid", "jwt malformed"]
		const originalRequest = error.config;
		// Check if the error is due to an expired token
		if (originalRequest?.url !== "/" && myErr.includes(error.response?.data?.message?.toLowerCase()) && !originalRequest._retry) {
			if (!isRefreshing) {
				isRefreshing = true;
				try {
					const res = await refreshUserToken();
					const newToken = res?.data;
					if (newToken) {
						instance.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
						originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
						processFailedRequests(null, newToken);
						originalRequest._retry = true;
						return instance(originalRequest);
					}
				} catch (err) {
					isRefreshing = false;
					processFailedRequests(err);
					return Promise.reject(err);
				} finally {
					isRefreshing = false;
				}
			} else {
				return new Promise((resolve, reject) => {
					failedRequests.push({ resolve, reject, config: originalRequest });
				});
			}
		}

		return error.response;
	}
);

export default instance;
