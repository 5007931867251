import { useEffect, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import { toast } from "react-toastify"
import { useTable } from "hooks"
import {
  useGetBeneficiaryInterventionHistoryQuery,
  useGetBeneficiaryQuery,
  useLazyGetBeneficiariesExportByIdQuery,
} from "services/api/beneficiarySlice"
import { useGetBeneficiaryPaymentHistoryQuery } from "services/api/paymentSlice"
import { shortDate } from "utils/dateFormatter"
import { exportData } from "utils/exportData"
import getFirstTwoNames from "utils/getFirstTwoNames"
import { tryCatch } from "utils/tryCatch"

export interface DateType {
  startDate: Date | null
  endDate: Date | null
}

export default function useViewBeneficiary() {
  const [date, setDate] = useState<DateType>({ startDate: null, endDate: null })
  const { handleSearch, params, changePage, setParams } = useTable()
  const {
    handleSearch: handleSearchPayment,
    params: paramsHistory,
    changePage: changePageHistory,
    setParams: setParamsHistory,
  } = useTable()
  const [selectedStatus, setSelectedStatus] = useState<any>([])
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState<any>([])
  const [isExportingLoading, setIsExportingLoading] = useState<boolean>(false)
  const [searchParams] = useSearchParams()
  const { id } = useParams()
  const currentTab = searchParams.get("category")

  const interventionStatus = (item: any) => {
    switch (item) {
      case "in-progress":
        return "pending"
      case "completed":
        return "paid"
      default:
        return "Not Available"
    }
  }

  const [getBeneficiaries, { isFetching: isExporting }] = useLazyGetBeneficiariesExportByIdQuery()

  const { data: beneficiary, isFetching: isFetchingBeneficiary } = useGetBeneficiaryQuery({
    reference: id,
    params: {
      type: currentTab === "unbanked-beneficiary" ? "unbanked" : "banked",
    },
  })

  const { data: beneficiaryPaymentHistory, isFetching: isFetchingPaymentHistory } =
    useGetBeneficiaryPaymentHistoryQuery({
      reference: id,
      paramsHistory: {
        search: paramsHistory?.search ?? "",
        status: selectedPaymentStatus.value ?? "",
        from: shortDate(date.startDate) ?? shortDate(date.endDate) ?? "",
        to: shortDate(date.endDate) ?? shortDate(date.startDate) ?? "",
      },
    })

  const { data: interventions, isFetching: isFetchingInterventions } = useGetBeneficiaryInterventionHistoryQuery({
    reference: id,
    params: {
      ...params,
      sortBy: "",
      page: 1,
      limit: 10,
      from: shortDate(date.startDate) ?? shortDate(date.endDate) ?? "",
      to: shortDate(date.endDate) ?? shortDate(date.startDate) ?? "",
      status: selectedStatus.value ?? "",
    },
  })

  const breadCrumbs = [{ title: "Beneficiary" }, { title: getFirstTwoNames(beneficiary?.name) ?? "-" }]

  const statusOptions = [
    { label: "All", value: "" },
    { label: "Pending", value: "in-progress" },
    { label: "Paid", value: "completed" },
  ]

  const statusPaymentOptions = [
    { label: "All", value: "" },
    { label: "Pending", value: "PENDING_CREDIT" },
    { label: "Paid", value: "completed" },
  ]

  const exportBeneficiaries = async () => {
    try {
      const res: any = await getBeneficiaries({
        beneficiary_id: id,
        type: "intervention_history",
      }).unwrap()
      if (res.status === "success" && res.data) {
        const downloadUrl = res.data
        window.location.href = downloadUrl
      } else {
        toast.error("Failed to export beneficiaries:", res.message)
      }
    } catch (error: any) {
      toast.error("An error occurred while exporting beneficiaries:", error)
    }
  }

  const exportPaymentHistory = () => {
    setIsExportingLoading(true)
    if (isFetchingPaymentHistory) {
      toast.error("Beneficiaries are still being fetched")
      setIsExportingLoading(false)
      return
    }

    const data = beneficiaryPaymentHistory?.transactions

    if (!data || data.length === 0) {
      toast.error("There are no Transactions in this payment history")
      setIsExportingLoading(false)
      return
    }

    const download_data = data.map((item: any) => ({
      "Transaction Id": item?.transaction_id,
      "Amount (₦)": item.amount,
      "Payment Date": shortDate(item.payment_date) || "-",
      "Destination Account": item.destination_account,
      Status: item.payment_status === "completed" ? "paid" : item.payment_status === "PENDING_CREDIT" ? "pending" : "-",
    }))

    tryCatch(() => {
      exportData(download_data, "Beneficiary Payment History")
    })
    setIsExportingLoading(false)
  }

  const tabs = [
    { title: "Intervention History", path: "banked-intervention-history", component: <p>Intervention</p> },
    { title: "Payment History", path: "banked-payment-history", component: <p>Payment</p> },
  ]

  useEffect(() => {
    setParams({ ...params, search: "", page: 1, sortBy: "" })
    setParamsHistory({ ...paramsHistory, search: "", page: 1, sortBy: "" })
    setDate({ startDate: null, endDate: null })
    setSelectedStatus([])
  }, [currentTab])

  return {
    breadCrumbs,
    date,
    setDate,
    currentTab,
    beneficiary,
    isFetchingBeneficiary,
    interventions,
    isFetchingInterventions,
    handleSearch,
    changePage,
    statusOptions,
    selectedStatus,
    setSelectedStatus,
    exportBeneficiaries,
    isExporting,
    setParams,
    params,
    tabs,
    beneficiaryPaymentHistory,
    isFetchingPaymentHistory,
    exportPaymentHistory,
    isExportingLoading,
    handleSearchPayment,
    paramsHistory,
    changePageHistory,
    setParamsHistory,
    interventionStatus,
    selectedPaymentStatus,
    setSelectedPaymentStatus,
    statusPaymentOptions,
  }
}
