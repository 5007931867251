import { useTable } from "hooks"
import useReportSummary from "hooks/useReportSummary/useReportSummary"
import { useMemo } from "react"
import { useGetBeneficiarySummaryQuery, useGetReportsQuery } from "services/api/reportSlice"
import { Period } from "types/common"
import { addCommasToNumbers } from "utils/addCommasToNumbers"
import { calculatePercentage, getReportCustomDateRange, getReportYear } from "utils/report"

export default function useBeneficiarySummary() {
  const {
    actions,
    deleteReport,
    isDeletingReport,
    selectedReportId,
    setSelectedReportId,
    date,
    setDate,
    periodFilter,
    setPeriodFilter,
  } = useReportSummary()
  const { params, changePage, selectLimit } = useTable()

  const {
    data: reports,
    isLoading: isLoadingHistory,
    isFetching: isFetchingHistory,
  } = useGetReportsQuery({ type: "beneficiary" })
  const {
    data: summary,
    isLoading: isLoadingSummary,
    isFetching: isFetchingSummary,
  } = useGetBeneficiarySummaryQuery({
    month: periodFilter.type === Period.MONTHLY ? periodFilter?.selected : "",
    year: getReportYear(periodFilter),
    ...getReportCustomDateRange(date, periodFilter),
  })

  const overview = [
    {
      title: "Total Beneficiary Count",
      count: addCommasToNumbers(summary?.data?.total_beneficiary_count ?? 0),
      icon: "ph:users-three",
      stat: summary?.data?.total_beneficiary_count_percentage_increase,
    },
    {
      title: "Total Verified Beneficiary",
      count: addCommasToNumbers(summary?.data?.total_verified_beneficiary_count ?? 0),
      icon: "ph:handshake",
      stat: summary?.data?.verified_beneficiary_count_percentage,
    },
    {
      title: "Total Unverified Beneficiary",
      count: addCommasToNumbers(summary?.data?.total_unverified_beneficiary_count ?? 0),
      icon: "ph:handshake",
      stat: summary?.data?.unverified_beneficiary_count_percentage,
    },
  ]

  const chartData = useMemo(() => {
    const dataValues = [summary?.data?.verified_beneficiary_count, summary?.data?.unverified_beneficiary_count]

    const percentage = calculatePercentage(dataValues)

    const filteredData = percentage.reduce<{ labels: string[]; data: number[]; backgroundColor: string[] }>(
      (acc, value, index) => {
        if (Number(dataValues[index]) !== 0) {
          acc.labels.push(["Verified", "Unverified"][index])
          acc.data.push(value)
          acc.backgroundColor.push(["#024F0B", "#C6E4C4"][index])
        }
        return acc
      },
      { labels: [], data: [], backgroundColor: [] },
    )

    return {
      labels: filteredData.labels,
      datasets: [
        {
          data: filteredData.data,
          backgroundColor: filteredData.backgroundColor,
          borderWidth: 6,
        },
      ],
    }
  }, [summary?.data])

  return {
    overview,
    actions,
    chartData,
    changePage,
    selectLimit,
    params,
    reports: reports?.data,
    summary: summary?.data,
    date,
    setDate,
    periodFilter,
    setPeriodFilter,
    isFetchingHistory,
    deleteReport,
    isDeletingReport,
    selectedReportId,
    setSelectedReportId,
    isLoadingSummary,
    isLoadingHistory,
    isFetchingSummary,
  }
}
