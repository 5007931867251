import { Button, Input, VerifyOTP } from "components/core"
import { AuthForm } from "components/pages/Auth"
import { Link, useNavigate } from "react-router-dom"
import useResetPassword from "./useResetPassword"
import { RenderIf } from "components/hoc"

export default function ResetPassword() {
  const navigate = useNavigate()
  const { formData, handleChange, onSubmit, showVerify, isLoading, disabledButton } = useResetPassword()

  return (
    <>
      <AuthForm title="Reset password" caption="Enter your email address to proceed" onSubmit={onSubmit}>
        <div>
          <Input
            label="Email Address"
            type="text"
            id="email"
            name="email"
            readOnly={isLoading}
            value={formData?.email}
            onChange={handleChange}
            className="mb-[24px]"
          />
          <Button theme="primary" className="w-full mb-[32px]" size="44" loading={isLoading} disabled={disabledButton}>
            Proceed
          </Button>
          <p className="text-sm text-center">
            <span className="text-body font-normal">Remember password?</span>{" "}
            <Link to="/" className="text-primary-actions font-medium">
              Sign in here
            </Link>
          </p>
        </div>
      </AuthForm>
      <RenderIf condition={showVerify}>
        <VerifyOTP email={formData.email} onClose={() => navigate(`/create-password?email=${formData.email}`)} />
      </RenderIf>
    </>
  )
}
