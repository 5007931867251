import { useParams, useNavigate } from "react-router-dom"
import { Icon } from "@iconify/react"
import { Breadcrumb, Button, Search } from "components/core"
import useSingleTraining from "./useSingleTraining"
import SingleTrainingInfo from "components/pages/Training/SingleTraining/SingleTrainingInfo/SingleTrainingInfo"
import AddStaffTrainingBeneficiary from "components/pages/Training/AddBeneficiary/AddStaff/AddStaff"
import AddCitizenBeneficiary from "components/pages/Training/AddBeneficiary/AddCitizen/AddCitizen"
import CitizenTrainingBeneficiaryTable from "./tables/citizenTable"
import StaffTrainingBeneficiaryTable from "./tables/staffTable"
import { RenderIf } from "components/hoc"

const SingleTraining = () => {
  const navigate = useNavigate()
  const {
    breadCrumbs,
    trainingDetailsInformation,
    trainingInfo,
    handleSearch,
    changePage,
    params,
    selectLimit,
    selectedAction,
    setSelectedAction,
    trainingData,
    isFetchingTrainingData,
  } = useSingleTraining()

  const { id } = useParams()
  const trainingType = trainingData?.training_type ?? ""

  const handleNavigateToBeneficiary = (beneficiaryId: string) => {
    navigate(`/training/beneficiary/${id}/${beneficiaryId}`)
  }

  return (
    <main data-testid="single-training">
      <div>
        <div>
          <Breadcrumb options={breadCrumbs} showBack goBackRoute="/training" />
        </div>
        <SingleTrainingInfo
          isLoading={isFetchingTrainingData}
          detailsData={trainingDetailsInformation}
          trainingInfo={trainingInfo}
        />
      </div>

      <div className="mt-6 flex items-center justify-between">
        <div className="flex gap-2">
          <Search
            name="search"
            id="search"
            onChange={handleSearch}
            placeholder={trainingType === "citizen" ? "Search by Name and HH ID" : "Search by Staff Name & ID"}
          />
        </div>
        <div className="">
          <Button
            type="button"
            size="40"
            theme="primary"
            onClick={() => setSelectedAction("add-beneficiary")}
            className="text-sm"
            data-testid="add-course-btn"
          >
            <Icon icon="ph:plus" className="w-4 h-4 mr-[8px]" />
            Add Beneficiary
          </Button>
        </div>
      </div>

      <RenderIf condition={trainingType === "citizen"}>
        <CitizenTrainingBeneficiaryTable
          data={trainingData}
          changePage={changePage}
          selectLimit={selectLimit}
          isLoading={isFetchingTrainingData}
          params={params}
          onClickRow={handleNavigateToBeneficiary}
        />
      </RenderIf>

      <RenderIf condition={trainingType === "staff"}>
        <StaffTrainingBeneficiaryTable
          data={trainingData}
          changePage={changePage}
          selectLimit={selectLimit}
          isLoading={isFetchingTrainingData}
          params={params}
          onClickRow={handleNavigateToBeneficiary}
        />
      </RenderIf>

      {selectedAction === "add-beneficiary" && trainingType === "citizen" && (
        <AddCitizenBeneficiary onClose={() => setSelectedAction("")} trainingId={id ?? ""} />
      )}

      {selectedAction === "add-beneficiary" && trainingType === "staff" && (
        <AddStaffTrainingBeneficiary onClose={() => setSelectedAction("")} trainingId={id ?? ""} />
      )}
    </main>
  )
}

export default SingleTraining
