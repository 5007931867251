export function formatTime(dateString: string): string {
  const date = new Date(dateString)
  const now = new Date()

  // Check if the provided date is on the same day as the current date
  const isSameDay =
    date.getFullYear() === now.getFullYear() && date.getMonth() === now.getMonth() && date.getDate() === now.getDate()

  // Format the time as HH:mm
  const timeOptions: Intl.DateTimeFormatOptions = { hour: "2-digit", minute: "2-digit", hour12: false }
  const formattedTime = date.toLocaleTimeString([], timeOptions)

  if (isSameDay) {
    return formattedTime // Return only time if the date is today
  }

  // Format the date as "MMM dd" (e.g., Nov 02)
  const dateOptions: Intl.DateTimeFormatOptions = { month: "short", day: "2-digit" }
  const formattedDate = date.toLocaleDateString([], dateOptions)

  return `${formattedDate}, ${formattedTime}` // Return date and time otherwise
}
