import { useState } from "react"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { useFetchSingleCourseQuery, useDeleteModuleMutation } from "services/api/trainingSlice"
import { fullDateFormatter } from "utils/dateFormatter"
import { useTable } from "hooks"
import { tryCatch } from "utils/tryCatch"
import { exportData } from "utils/exportData"

export default function useSingleCourse() {
  const { id } = useParams()
  const { handleSearch, changePage, params, selectLimit } = useTable()

  const [selectedAction, setSelectedAction] = useState<any>("")
  const [selectedModule, setSelectedModule] = useState<any>(null)

  const { data: singleCourseData, isFetching: isFetchingSingleCourse } = useFetchSingleCourseQuery({ id })

  const singleCourseDetailsData = [
    singleCourseData?.course_reference ? singleCourseData?.course_reference : singleCourseData?.course_id,
    singleCourseData?.training_attached ?? "--",
    singleCourseData?.training_type ?? "--",
    singleCourseData?.skill_type ?? "--",
    singleCourseData?.modules?.length ?? "0",
    singleCourseData?.created_at ? fullDateFormatter(singleCourseData?.created_at) : "--",
  ]

  const [deleteCourse, { isLoading: isDeletingModule }] = useDeleteModuleMutation({})

  const handleDeleteModule = async (moduleId: string, onClose: () => void) => {
    const callback = (res: any) => {
      if ([200, 201].includes(res?.code)) {
        toast.success(res.message)
      }
    }
    tryCatch(async () => {
      let res = await deleteCourse(moduleId).unwrap()
      callback(res)
      onClose()
    })
  }

  const breadCrumbs = [{ title: "Training" }, { title: "Course List" }]

  const exportModuleInformation = () => {
    tryCatch(() => {
      const data = singleCourseData?.modules?.map((module: any) => {
        return {
          "Module Reference": module?.course_module_reference,
          "Module Title": module?.name,
          "Module Description": module?.description,
        }
      })
      exportData(data, "Course Module Data")
    })
  }

  return {
    breadCrumbs,
    isFetchingSingleCourse,
    singleCourseData,
    singleCourseDetailsData,
    handleSearch,
    params,
    changePage,
    selectLimit,
    selectedAction,
    setSelectedAction,
    selectedModule,
    setSelectedModule,
    isDeletingModule,
    handleDeleteModule,
    exportModuleInformation,
  }
}
