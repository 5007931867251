import { Icon } from "@iconify/react"
import { Breadcrumb, Button, Status, Table } from "components/core"
import HeaderLoader from "components/core/Loader/HeaderLoader/HeaderLoader"
import { RenderIf } from "components/hoc"
import { interventionReportHeader } from "constants/reports"
import { addCommasToNumbers } from "utils/addCommasToNumbers"
import { fullDateFormatter } from "utils/dateFormatter"
import { formatCurrency } from "utils/formatCurrency"
import useCoResponsibilityReport from "./useCoresponsibilityReport"

const CoResponsibilityReport = () => {
  const { breadcrumbs, overview, changePage, selectLimit, report, params, isLoadingReport, isExporting, exportReport } =
    useCoResponsibilityReport()

  return (
    <div className="w-full">
      <Breadcrumb options={breadcrumbs} showBack={true} />
      <RenderIf condition={isLoadingReport}>
        <HeaderLoader />
      </RenderIf>
      <RenderIf condition={!isLoadingReport}>
        <h1 className="text-xl text-headers font-semibold my-6">{report?.reportTitle}</h1>
        <div className="flex items-center py-6 mb-6 border-y w-full">
          {overview.map((details) => (
            <div key={details.title} className="flex items-center gap-4 mr-[40px]">
              <div className="relative w-10 h-10 grid place-items-center bg-grey-100 rounded-full shrink-0">
                <Icon icon={details.icon} className="w-6 h-6 text-secondary-base" />
              </div>
              <div className="grid">
                <span className="text-sm text-body">{details.title}</span>
                <p className="text-base font-medium text-headers">{details.count}</p>
              </div>
            </div>
          ))}
        </div>
        <RenderIf condition={!!report?.interventions?.length}>
          <div className="flex justify-end">
            <Button type="button" size="40" theme="transparent" disabled={isExporting} onClick={exportReport}>
              <Icon icon="uiw:download" className="text-paragraph w-[18px] h-[18px] mr-3" />
              {isExporting ? "Exporting..." : "Export"}
            </Button>
          </div>
        </RenderIf>
      </RenderIf>
      <Table
        headers={interventionReportHeader}
        paginateData={!!report?.interventions?.length}
        data={report?.interventions ?? []}
        onPageChange={changePage}
        selectLimit={selectLimit}
        totalCount={Number(report?.totalInterventionCount || 0)}
        perPage={params?.limit}
        page={Number(report?.page ?? 0)}
        loading={isLoadingReport}
      >
        <>
          {report?.interventions?.map((item: any) => (
            <tr
              data-testid={item?.interventionTitle}
              key={item?.interventionTitle}
              className="cc-custom-table-data-row"
            >
              <td>
                <div>
                  <p>{item?.interventionTitle}</p>
                  <p className="text-xs font-semibold">
                    <span className="text-black">{addCommasToNumbers(item?.beneficiaryCount)}</span> Beneficiaries
                  </p>
                </div>
              </td>
              <td>{item?.numberOfStates}</td>
              <td>{item?.numberOfZones}</td>
              <td>{item?.numberOfLgas}</td>
              <td>{formatCurrency(item?.totalAmount)}</td>
              <td>
                <Status status={item?.paymentStatus} />
              </td>
              <td>{fullDateFormatter(item?.createdAt)}</td>
              <td>co-responsibility</td>
            </tr>
          ))}
        </>
      </Table>
    </div>
  )
}

export default CoResponsibilityReport
