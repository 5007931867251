import { useTable } from "hooks"
import useReportSummary from "hooks/useReportSummary/useReportSummary"
import { useMemo } from "react"
import { useGetInterventionSummaryQuery, useGetReportsQuery } from "services/api/reportSlice"
import { Period } from "types/common"
import { addCommasToNumbers } from "utils/addCommasToNumbers"
import { formatCurrency } from "utils/formatCurrency"
import { calculatePercentage, getReportCustomDateRange, getReportYear } from "utils/report"

export default function useInterventionSummary() {
  const {
    actions,
    deleteReport,
    isDeletingReport,
    selectedReportId,
    setSelectedReportId,
    date,
    setDate,
    periodFilter,
    setPeriodFilter,
  } = useReportSummary()
  const { params, changePage, selectLimit } = useTable()

  const {
    data: reports,
    isLoading: isLoadingHistory,
    isFetching: isFetchingHistory,
  } = useGetReportsQuery({ type: "intervention" })
  const {
    data: summary,
    isLoading: isLoadingSummary,
    isFetching: isFetchingSummary,
  } = useGetInterventionSummaryQuery({
    month: periodFilter.type === Period.MONTHLY ? periodFilter?.selected : "",
    year: getReportYear(periodFilter),
    ...getReportCustomDateRange(date, periodFilter),
  })

  const overview = [
    {
      title: "Total Intervention (Count)",
      count: addCommasToNumbers(summary?.data?.intervention_count ?? 0),
      icon: "ph:handshake",
      stat: summary?.data?.intervention_count_percentage_increase,
    },
    {
      title: "Total Intervention (Value)",
      count: formatCurrency(summary?.data?.total_intervention_amount ?? 0),
      icon: "ph:handshake",
      stat: summary?.data?.intervention_amount_percentage_increase,
    },
    {
      title: "Total Beneficiaries",
      count: addCommasToNumbers(summary?.data?.total_beneficiaries ?? 0),
      icon: "ph:handshake",
      stat: summary?.data?.total_beneficiaries_percentage_increase,
    },
  ]

  const chartData = useMemo(() => {
    const dataValues = [summary?.data?.pending_count, summary?.data?.in_progress_count, summary?.data?.completed_count]

    const percentage = calculatePercentage(dataValues)

    const filteredData = percentage.reduce<{ labels: string[]; data: number[]; backgroundColor: string[] }>(
      (acc, value, index) => {
        if (Number(dataValues[index]) !== 0) {
          acc.labels.push(["Pending", "Inprogress", "Completed"][index])
          acc.data.push(value)
          acc.backgroundColor.push(["#024F0B", "#C6E4C4", "#2B892B"][index])
        }
        return acc
      },
      { labels: [], data: [], backgroundColor: [] },
    )

    return {
      labels: filteredData.labels,
      datasets: [
        {
          data: filteredData.data,
          backgroundColor: filteredData.backgroundColor,
          borderWidth: 6,
        },
      ],
    }
  }, [summary?.data])

  return {
    overview,
    actions,
    chartData,
    changePage,
    selectLimit,
    params,
    reports: reports?.data,
    summary: summary?.data,
    date,
    setDate,
    periodFilter,
    setPeriodFilter,
    isFetchingHistory,
    deleteReport,
    isDeletingReport,
    selectedReportId,
    setSelectedReportId,
    isLoadingSummary,
    isLoadingHistory,
    isFetchingSummary,
  }
}
