import React, { ChangeEvent } from "react"
import { Icon } from "@iconify/react"
import { Button, Modal, Dropdown, Input, Textarea } from "components/core"
import RadioButton from "components/core/RadioButton/RadioButton"
import { RenderIf } from "components/hoc"
import { TrainingType } from "mocks/training"
import useEditCourse from "./useEditCourse"

interface EditCourseProps {
  course: any
  onClose: () => void
}

const EditCourse = ({ course, onClose }: EditCourseProps) => {
  const {
    formData,
    setFormData,
    handleModuleChange,
    addNewModule,
    handleDeleteModule,
    isFormChanged,
    isFormValid,
    isEditingCourse,
    handleEditCourse,
  } = useEditCourse({
    course,
    onClose,
  })

  const visibleModules = formData.modules.filter((module) => module?.to_delete === "undefined")

  return (
    <main data-testid="edit-course">
      <Modal title="Edit Course" position="modal-right" width="w-[432px]" closeModal={onClose}>
        <div>
          <div className="modal-right-body flex flex-col gap-y-4">
            <Input
              type="text"
              value={formData?.title}
              id="title"
              label="Course Title"
              name="title"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setFormData(() => ({
                  ...formData,
                  title: e.target.value,
                }))
              }
            />

            <Textarea
              label="Course Description"
              rows={6}
              id="description"
              name="description"
              maxLength={320}
              value={formData?.description}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFormData({ ...formData, description: e.target.value })
              }
            />

            <Dropdown
              label="Select Training Type"
              selected={formData?.training_type}
              options={TrainingType}
              onSelect={(data: any) => {
                setFormData({
                  ...formData,
                  training_type: data?.value,
                })
              }}
            />
            <div>
              <h1 className="text-sm font-medium text-headers mb-3">Course Type</h1>
              <div className="flex flex-col gap-4">
                <RadioButton
                  label="Hard Skills"
                  name="hard-skills"
                  checked={formData?.skill_type === "hard skills"}
                  onChange={() => setFormData({ ...formData, skill_type: "hard skills" })}
                />
                <RadioButton
                  label="Soft Skills"
                  name="soft-skills"
                  checked={formData?.skill_type === "soft skills"}
                  onChange={() => setFormData({ ...formData, skill_type: "soft skills" })}
                />
              </div>
            </div>
            <div>
              <h1 className="text-sm font-medium text-headers">Add course modules</h1>
              <div className="w-full gap-3 mt-3">
                {visibleModules.map((module: any, index: number) => (
                  <div key={index} className="flex flex-col gap-4 mb-4">
                    <div className="grid items-center grid-cols-3 gap-2">
                      <label className="text-sm text-headers cursor-default font-medium">{`Module ${index + 1}`}</label>

                      <div className="col-span-2 w-full flex space-x-2">
                        <input
                          data-testid={`module-title-${index}`}
                          value={module.name}
                          onChange={(e) => handleModuleChange(e, index, "title")}
                          className="p-[12px] h-[40px] text-body text-sm w-full outline-0 border hide_tap
                        rounded-[4px] border-grey-200 focus:border-primary-200 placeholder:text-sm"
                        />
                        <RenderIf condition={visibleModules?.length > 1}>
                          <button
                            className="cursor-pointer"
                            onClick={() => handleDeleteModule(index)}
                            data-testid={`del-module-${index}`}
                          >
                            <Icon icon="ph:x" height={12} className="text-[#ADACB0] w-[12px]" />
                          </button>
                        </RenderIf>
                      </div>
                    </div>
                    <div>
                      <label className="text-sm text-headers cursor-default font-medium">Description</label>
                      <textarea
                        rows={3}
                        data-testid={`module-desc-${index}`}
                        value={module.description}
                        onChange={(e) => handleModuleChange(e, index, "desc")}
                        className="p-[12px] text-body text-sm w-full outline-0 border hide_tap
        rounded-[4px] border-grey-200 focus:border-primary-200"
                      />
                    </div>
                  </div>
                ))}
              </div>
              <RenderIf condition={visibleModules.length < 5}>
                <button
                  className="flex w-full justify-end mt-1 items-center text-xs text-primary-actions gap-1"
                  onClick={addNewModule}
                  data-testid="add-module-btn"
                >
                  <Icon icon="ph:plus" />
                  Add New Module
                </button>
              </RenderIf>
            </div>
          </div>

          <div className="modal-right-button-container mb-3">
            <Button
              theme="primary"
              className="min-w-[105px] mr-[8px] p-[8px]"
              type="button"
              onClick={() => handleEditCourse()}
              data-testid="submit-btn"
              loading={isEditingCourse}
              disabled={!isFormChanged || isEditingCourse || !isFormValid()}
            >
              Save Changes
            </Button>
            <Button theme="transparent" className="w-[105px] px-[30px]" type="button" onClick={onClose}>
              Discard
            </Button>
          </div>
        </div>
      </Modal>
    </main>
  )
}

export default EditCourse
