import TrainingStatistics from "components/pages/Training/TrainingStats/TrainingStats"
import TrainingTable from "components/pages/Training/TrainingTable/TrainingTable"
import useTraining from "./useTraining"

const Training = () => {
  const { isLoadingStatistics, statisticsData } = useTraining()
  return (
    <main>
      <div>
        <h1 className="text-headers text-2xl font-bold">Training</h1>
      </div>
      <TrainingStatistics isLoading={isLoadingStatistics} statisticsData={statisticsData} />
      <TrainingTable />
    </main>
  )
}

export default Training
