/* eslint-disable no-unused-vars */
import React from "react"
import { useTable } from "hooks"
import { useEffect, useMemo, useState } from "react"
import { useGetDataSourcesQuery } from "services/api/dataSlice"
import { DateType } from "types/beneficiary"
import { shortDate } from "utils/dateFormatter"
import { shortenString } from "utils/shortenText"
import { ChangeEvent } from "react"
import { tryCatch } from "utils/tryCatch"
import { toast } from "react-toastify"
import {
  useAddBatchMutation,
  useGetSingleMineHistoryQuery,
  useMineDataMutation,
  useUpdateDataMutation,
  useGetMineHistoryQuery,
} from "services/api/dataSlice"
import { extractVersion } from "utils/extractVersion"
import { useDispatch } from "react-redux"
import { setMinedData } from "services/api/dataSlice"
import { useSelector } from "react-redux"

interface FormData {
  source_id: string
  batch_id: string
  description: string
  version: string
  type: string
  households: File | null
  members: File | null
}

interface SourceOption {
  image: string
  label: string
  value: string
}

export default function useMineUpdateData(
  reference?: string,
  selectedAction?: string,
  onCloseMineData?: () => void,
  refetchMineHistory?: () => void,
  refetchSingleDataSourceDetails?: () => void,
  openPercentageModal?: boolean,
  source_name?: string,
  openStatusDialog?: boolean,
) {
  const [date, setDate] = useState<DateType>({ startDate: null, endDate: null })
  const [currentTab, setCurrentTab] = useState<string>("Household")
  const [householdFiles, setHouseholdFiles] = useState<any>([])
  const [memberFiles, setMemberFiles] = useState<any>([])
  const [minedDataId, setMinedDataId] = useState<any>(undefined)
  const [selectedVersion, setSelectedVersion] = useState<string>("")
  const [availableVersions, setAvailableVersions] = useState<any[]>([])
  const [householdProgress, setHouseholdProgress] = useState(0)
  const [memberProgress, setMemberProgress] = useState(0)

  const { handleSearch, params } = useTable()
  const dispatch = useDispatch()

  const { data: sources, isFetching: isFetchingSources } = useGetDataSourcesQuery({
    ...params,
    fromDate: shortDate(date.startDate) ?? "",
    toDate: shortDate(date.endDate) ?? "",
  })

  const sourceList = sources?.data?.configurationSettings
  const sourceOptions = useMemo(() => {
    if (sourceList && Array.isArray(sourceList) && sourceList.length > 0) {
      return sourceList.map((item) => ({
        label: shortenString(item.configuration_name, 35),
        image: item.logo,
        value: item.configuration_id,
        name: item.configuration_name,
      }))
    }
    return []
  }, [sourceList])
  const foundObject = sourceOptions.find((item) => item.name === source_name)
  const defaultSource = useMemo(() => {
    const foundObject = sourceOptions.find((item) => item.name === source_name)
    return [
      {
        image: foundObject?.image ?? "",
        label: foundObject?.label ?? "",
        value: foundObject?.value ?? "",
      },
    ]
  }, [sourceOptions, source_name])

  const [formData, setFormData] = useState<FormData>({
    source_id: defaultSource[0]?.value || "",
    batch_id: "",
    description: "",
    version: "",
    type: "File Upload",
    households: null,
    members: null,
  })

  const dataTypeOptions = [
    { label: "Households", value: "household" },
    { label: "Members", value: "members" },
  ]

  const fetchSingleMinedDataId = useSelector((state: any) => state?.data?.minedDataId)
  // const shouldFetchMineHistory = !!formData.source_id;

  const {
    data: history,
    isFetching: isFetchingHistory,
    refetch: refetchHistory,
  } = useGetSingleMineHistoryQuery(reference ?? fetchSingleMinedDataId, { skip: !reference && !fetchSingleMinedDataId })

  const { data: historyWithVersions, isFetching: isFetchingHistoryWithVersions } = useGetMineHistoryQuery(
    { ...params, limit: Number.MAX_SAFE_INTEGER, source_id: formData.source_id, sortBy: "" },
    { skip: !formData.source_id },
  )

  const generateDataVersionOptions = () => {
    const existingVersions = historyWithVersions?.mineHistory?.map((item: any) => {
      const versionNumber = parseInt(item.version.match(/\d+/)[0])

      return versionNumber
    })

    const excludedNumbers = new Set(existingVersions)

    const options = []
    for (let i = 1; i <= 50; i++) {
      if (!excludedNumbers.has(i)) {
        const version = `version ${i}`
        options.push({ label: `Version ${i}`, value: version })
      }
    }
    return options
  }

  const dataVersionOptions = useMemo(() => {
    const generateDataVersionOptions = () => {
      const existingVersions = historyWithVersions?.mineHistory?.map((item) => {
        const versionNumber = item?.version?.split("")?.[1] ?? ""
        return parseInt(versionNumber)
      })

      const excludedNumbers = new Set(existingVersions)

      const options = []
      for (let i = 1; i <= 50; i++) {
        if (!excludedNumbers.has(i)) {
          const version = `version ${i}`
          options.push({ label: `Version ${i}`, value: version })
        }
      }
      return options
    }

    return generateDataVersionOptions()
  }, [historyWithVersions?.mineHistory])

  useEffect(() => {
    setAvailableVersions(generateDataVersionOptions())
  }, [historyWithVersions?.mineHistory])

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>
    if (openPercentageModal) {
      interval = setInterval(() => {
        refetchHistory()
      }, 10000)
    }

    return () => {
      clearInterval(interval)
    }
  }, [openPercentageModal, refetchHistory])

  const [mineData, { isLoading: isMiningData }] = useMineDataMutation()
  const [addBatch, { isLoading: isAddingBatch }] = useAddBatchMutation()
  const [updateData, { isLoading: isUpdatingData }] = useUpdateDataMutation()

  const batchesOptions = useMemo(() => {
    if (history && Array.isArray(history?.batches) && history?.batches.length > 0) {
      return history?.batches?.map((item: any) => ({
        label: item.label,
        value: item.reference,
        name: item.label,
      }))
    }
    return []
  }, [history?.batches])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleFileUpload = (e: any, currentTab?: string) => {
    const uploadedFile = e.target.files[0]
    const simulateProgress = (setProgress: React.Dispatch<React.SetStateAction<number>>) => {
      let progress = 0
      const interval = setInterval(() => {
        if (progress >= 100) {
          clearInterval(interval)
        } else {
          progress += 10
          setProgress(progress)
        }
      }, 200)
    }

    if (currentTab === "Household") {
      setHouseholdFiles({
        name: uploadedFile?.name,
        size: (uploadedFile?.size / (1024 * 1024)).toFixed(2),
      })
      setFormData({
        ...formData,
        households: uploadedFile,
      })
      simulateProgress(setHouseholdProgress)
    }
    if (currentTab === "Members") {
      setMemberFiles({
        name: uploadedFile?.name,
        size: (uploadedFile?.size / (1024 * 1024)).toFixed(2),
      })
      setFormData({
        ...formData,
        members: uploadedFile,
      })
      simulateProgress(setMemberProgress)
    }
  }

  const handleFileDelete = (currentTab?: string) => {
    if (currentTab === "Household") {
      setHouseholdFiles([])
      setFormData({
        ...formData,
        households: null,
      })
    }
    if (currentTab === "Members") {
      setMemberFiles([])
      setFormData({
        ...formData,
        members: null,
      })
    }
  }

  const handleSubmitBtn = (e?: any, setOpenPercentageModal?: (value: boolean) => void) => {
    e.preventDefault()
    tryCatch(async () => {
      const callback = (res: any) => {
        if ([200, 201].includes(res?.code)) {
          toast.success(res?.message)
          setMinedDataId(res?.data?.reference)
          dispatch(setMinedData(res?.data))
          setOpenPercentageModal && setOpenPercentageModal(true)
          refetchMineHistory && refetchHistory()
          refetchSingleDataSourceDetails && refetchSingleDataSourceDetails()
        } else {
          toast.error(res?.message)
        }
      }
      const payload = new FormData()
      const formDataKeys = Object.keys(formData) as Array<keyof typeof formData>
      formDataKeys.forEach((key) => {
        if (
          key !== "type" &&
          key !== "households" &&
          key !== "members" &&
          key !== "batch_id" &&
          (selectedAction !== "update-data" || (key !== "source_id" && key !== "description" && key !== "version"))
        ) {
          payload.append(key, formData[key])
        }
      })
      // if (source_id) {
      //   payload.append("source_id", source_id)
      // }
      if (formData.type === "File Upload") {
        payload.append("type", "csv")
      } else {
        payload.append("type", "json")
      }
      if (formData.households) {
        payload.append("households", formData.households)
      }
      if (formData.members) {
        payload.append("members", formData.members)
      }
      const batch_id = formData.batch_id
      if (selectedAction === "update-data") {
        const res = await updateData({ batch_id, payload }).unwrap()
        callback(res)
        // const res = await updateData(payload).unwrap();
        // callback(res);
      } else {
        const res = await mineData(payload).unwrap()
        callback(res)
      }
    })
  }

  const handleAddBatch = (
    e?: any,
    mine_history_id?: string,
    onClose?: any,
    setOpenCompleteAddBatchModal?: (value: boolean) => void,
  ) => {
    e.preventDefault()
    tryCatch(async () => {
      const callback = (res: any) => {
        if ([200, 201].includes(res?.code)) {
          toast.success(res?.message)
          onClose()
          refetchMineHistory && refetchMineHistory()
        }
      }
      const payload = new FormData()
      const formDataKeys = Object.keys(formData) as Array<keyof typeof formData>
      formDataKeys.forEach((key) => {
        if (key !== "type" && key !== "households" && key !== "members" && formData[key]) {
          payload.append(key, formData[key])
        }
      })
      if (mine_history_id) {
        payload.append("mine_history_id", mine_history_id)
      }
      if (formData.type === "File Upload") {
        payload.append("type", "csv")
      } else {
        payload.append("type", "json")
      }
      if (formData.households) {
        payload.append("households", formData.households)
      }
      if (formData.members) {
        payload.append("members", formData.members)
      }
      const res = await addBatch(payload).unwrap()
      callback(res)
    })
  }

  const handleSelectVersion = (selected: { label: string; value: string }) => {
    const newAvailableVersions = availableVersions.filter((version) => version.value !== selected.value)
    if (selectedVersion) {
      newAvailableVersions.push({ label: selectedVersion, value: selectedVersion })
    }
    setSelectedVersion(selected.value)
    setAvailableVersions(
      newAvailableVersions.sort((a, b) => parseInt(a.value.split(" ")[1]) - parseInt(b.value.split(" ")[1])),
    )
    setFormData((prevFormData) => ({
      ...prevFormData,
      version: selected.value,
    }))
  }

  const defaultVersion = dataVersionOptions.find((item) => item.value === extractVersion(history?.label))
  const defaultVersionSelection = [
    {
      label: defaultVersion?.label ?? "",
      value: defaultVersion?.value ?? "",
    },
  ]

  const validateFormData = () => {
    const errors = []

    if (selectedAction !== "update-data") {
      if (!formData.description) {
        errors.push({ field: "description", message: "Description is required" })
      }

      if (!formData.version) {
        errors.push({ field: "version", message: "Version is required" })
      }

      if (!formData.source_id) {
        errors.push({ field: "source_id", message: "Source ID is required" })
      }
    }

    // Specific validations for 'update-data' action
    if (selectedAction === "update-data") {
      if (!formData.batch_id) {
        errors.push({ field: "batch_id", message: "Batch ID is required for updates" })
      }

      // Check for file presence only if they are being updated
      if (formData.households === null) {
        errors.push({ field: "households", message: "Households file is required for updates" })
      }

      if (formData.members === null) {
        errors.push({ field: "members", message: "Members file is required for updates" })
      }
    } else {
      // Validations for actions other than 'update-data'
      if (!formData.households) {
        errors.push({ field: "households", message: "Households file is required" })
      }

      if (!formData.members) {
        errors.push({ field: "members", message: "Members file is required" })
      }
    }

    return errors
  }

  const uploadTypes = ["File Upload", "API"]
  const tabs = [{ title: "Household" }, { title: "Members" }]

  return {
    formData,
    setFormData,
    sourceOptions,
    dataTypeOptions,
    dataVersionOptions,
    handleChange,
    currentTab,
    setCurrentTab,
    handleSubmitBtn,
    handleFileUpload,
    householdFiles,
    setHouseholdFiles,
    memberFiles,
    setMemberFiles,
    date,
    setDate,
    handleSearch,
    isFetchingSources,
    isMiningData,
    history,
    isFetchingHistory,
    defaultVersionSelection,
    minedDataId,
    isAddingBatch,
    validateFormData,
    batchesOptions,
    handleFileDelete,
    handleAddBatch,
    isUpdatingData,
    uploadTypes,
    tabs,
    defaultSource,
    isFetchingHistoryWithVersions,
    handleSelectVersion,
    availableVersions,
    setSelectedVersion,
    selectedVersion,
    householdProgress,
    memberProgress,
  }
}
