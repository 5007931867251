import { useState } from "react"
import { useParams } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { useTable } from "hooks"
import {
  useGetBeneficiariesQuery,
  useGetActivityLogQuery,
  useMakePaymentsMutation,
  useGetSinglePaymentScheduleQuery,
  setScheduleData,
  useUpdateGenerateScheduleAdviceMutation,
  useSignScheduleAdviceMutation,
} from "services/api/paymentSlice"
import { addCommasToNumbers } from "utils/addCommasToNumbers"
import { formatTimestamp, fullDateFormatter } from "utils/dateFormatter"
import { toast } from "react-toastify"
import { tryCatch } from "utils/tryCatch"
import { exportData } from "utils/exportData"
import { AllPermissions, PermissionModule } from "types/permission"
import { usePermission } from "hooks/usePermission/usePermission"

export interface DateType {
  startDate: Date | null
  endDate: Date | null
}

type UnitType = "program-manager" | "audit" | "account" | "payment-unit"

export default function useViewPaymentSchedule() {
  const { unit, id } = useParams()
  let scheduleId = id
  const dispatch = useDispatch()
  const current_unit = (unit ?? "payment-unit") as UnitType

  const { PROGRAM_MANAGER, AUDIT, ACCOUNT, PAYMENT_UNIT } = PermissionModule

  const {
    DOWNLOAD_SINGLE_PAYMENT_SCHEDULE,
    DELETE_PAYMENT_SCHEDULE,
    EXPORT_PAYMENT_SCHEDULE_ALL,
    APPROVE_DECLINE_PAYMENT_SCHEDULE,
    DELETE_PAYMENT_SCHEDULE_ALL,
    VIEW_ACTIVITY_TIMELINE,
  } = AllPermissions
  const { hasPermission } = usePermission()

  const unitToPermissionMap: Record<UnitType, (typeof PermissionModule)[keyof typeof PermissionModule]> = {
    "program-manager": PROGRAM_MANAGER,
    audit: AUDIT,
    account: ACCOUNT,
    "payment-unit": PAYMENT_UNIT,
  }
  const currentModule = unitToPermissionMap[current_unit]

  const showDelete = hasPermission(
    currentModule,
    current_unit === "payment-unit" ? DELETE_PAYMENT_SCHEDULE : DELETE_PAYMENT_SCHEDULE_ALL,
  )

  const showExport = hasPermission(
    currentModule,
    current_unit === "payment-unit" ? DOWNLOAD_SINGLE_PAYMENT_SCHEDULE : EXPORT_PAYMENT_SCHEDULE_ALL,
  )

  const showActivityTimeLine = hasPermission(currentModule, VIEW_ACTIVITY_TIMELINE)

  const showApproveButton = hasPermission(currentModule, APPROVE_DECLINE_PAYMENT_SCHEDULE)

  const [date, setDate] = useState<DateType>({ startDate: null, endDate: null })
  const [selectedGender, setSelectedGender] = useState<any>("")
  const { handleSearch, changePage, params, selectLimit } = useTable()
  const [selectedAction, setSelectedAction] = useState("")

  const { data: singlePaymentSchedule, isLoading: isFetchingSinglePaymentSchedule } = useGetSinglePaymentScheduleQuery(
    scheduleId,
    { skip: !scheduleId },
  )

  const { data: activityLogs, isFetching: isFetchingActivityLogs } = useGetActivityLogQuery({
    search: "PSC",
    operation_id: scheduleId,
  })

  const [makePayment, { isLoading: isMakingPayment }] = useMakePaymentsMutation()

  const [updateGenerateSchedule, { isLoading: isUpdatingSchedule }] = useUpdateGenerateScheduleAdviceMutation({})

  const [signScheduleAdvice, { isLoading: isSigningScheduleAdvice }] = useSignScheduleAdviceMutation({})

  const handleGeneratePaymentAdvice = () => {
    const callback = (res: any) => {
      if ([200, 201].includes(Number(res?.code))) {
        toast.success(res.message)
        dispatch(setScheduleData(singlePaymentSchedule))
        setSelectedAction("download-schedule")
      } else {
        toast.error(res.message)
      }
    }

    tryCatch(async () => {
      let res = await updateGenerateSchedule(scheduleId).unwrap()
      callback(res)
    })
  }

  const handleSignAdvice = async () => {
    const callback = (res: any) => {
      if ([200, 201].includes(Number(res?.code))) {
        toast.success(res.message)
        setSelectedAction("")
      } else {
        toast.error(res.message)
      }
    }

    tryCatch(async () => {
      let res = await signScheduleAdvice(scheduleId).unwrap()
      callback(res)
    })
  }

  const handleOpenDownload = () => {
    setSelectedAction("download-schedule")
    dispatch(setScheduleData(singlePaymentSchedule))
  }

  const handleMakePayments = async () => {
    dispatch(setScheduleData(singlePaymentSchedule))
    const callBack = (res: any) => {
      if ([200, 201].includes(res?.code)) {
        toast.success(res.message)
        setSelectedAction("")
      }
    }

    const data = {
      payment_schedule_id: scheduleId,
    }

    tryCatch(async () => {
      let res = await makePayment(data).unwrap()
      callBack(res)
    })
  }

  const staffId = useSelector((state: any) => state.auth)?.staff_id

  const { data: beneficiariesData, isFetching: isFetchingBeneficiaries } = useGetBeneficiariesQuery(
    {
      id: scheduleId,
      params: {
        search: params?.search,
        page: params?.page,
        limit: params?.limit,
        gender: selectedGender?.label === "All" ? "" : selectedGender?.value,
      },
    },
    { skip: !scheduleId },
  )

  const paymentScheduleDetailsData = [
    singlePaymentSchedule?.schedule_reference ?? singlePaymentSchedule?.schedule_id,
    `₦ ${addCommasToNumbers(
      Number(singlePaymentSchedule?.amount * Number(singlePaymentSchedule?.scheduled_beneficiaries_count)),
    )}`,
    singlePaymentSchedule?.created_at ? formatTimestamp(singlePaymentSchedule?.created_at) : "",
    addCommasToNumbers(beneficiariesData?.total ?? 0),
    singlePaymentSchedule?.type ?? "--",
    singlePaymentSchedule?.payment_cycle ?? "--",
  ]

  const breadCrumbs = [
    { title: "Payment Schedule" },
    {
      title: singlePaymentSchedule?.intervention_title
        ? `${singlePaymentSchedule?.intervention_title} (${singlePaymentSchedule?.schedule_reference ?? scheduleId})`
        : "Loading...",
    },
  ]

  const exportBeneficiariesData = () => {
    if (isFetchingBeneficiaries) {
      toast.error("Beneficiaries are still being fetched")
      return
    }

    const data = beneficiariesData?.beneficiaries

    if (!data || data.length === 0) {
      toast.error("There are no beneficiaries in this payment schedule")
      return
    }

    const download_data = data.map((item: any) => {
      const dob = fullDateFormatter(item.date_of_birth)
      return {
        Name: item?.name,
        State: item?.state ?? "--",
        DOB: dob.replace(/,/g, ""),
        NIN: String(item?.nin),
        BVN: String(item?.bvn),
        "ACCOUNT NUMBER": item?.account_number ?? "--",
        GENDER: item?.gender ?? "--",
        "SOURCE REGISTER": item?.source_register_name,
      }
    })

    tryCatch(() => {
      exportData(download_data, "Beneficiaries Data")
    })
  }

  let actions = []

  if (
    ["payment-unit"]?.includes(unit ?? "") &&
    singlePaymentSchedule?.view_payment_advice_count > 0 &&
    singlePaymentSchedule?.approval_count === 3
  ) {
    actions.push({
      name: "Payment Advice",
      method: () => {
        dispatch(setScheduleData(singlePaymentSchedule))
        setSelectedAction("download-schedule")
      },
    })
  }

  if (["payment-unit"]?.includes(unit ?? "") && singlePaymentSchedule?.approval_count <= 0 && showDelete) {
    actions.push({
      name: "Delete",
      method: () => {
        setSelectedAction("open-delete-modal")
      },
    })
  }

  if (showExport) {
    actions.push({
      name: "Download",
      method: () => {
        exportBeneficiariesData()
      },
    })
  }

  if (showActivityTimeLine) {
    actions.push({
      name: "Activity Timeline",
      method: () => {
        setSelectedAction("activity-timeline")
      },
    })
  }

  return {
    actions,
    breadCrumbs,
    date,
    handleSearch,
    changePage,
    selectLimit,
    params,
    setDate,
    staffId,
    scheduleId,
    selectedGender,
    unit,
    beneficiariesData,
    setSelectedGender,
    selectedAction,
    setSelectedAction,
    isFetchingBeneficiaries,
    paymentScheduleDetailsData,
    activityLogs,
    isFetchingActivityLogs,
    handleMakePayments,
    isMakingPayment,
    singlePaymentSchedule,
    isFetchingSinglePaymentSchedule,
    showApproveButton,
    isSigningScheduleAdvice,
    isUpdatingSchedule,
    updateGenerateSchedule,
    handleSignAdvice,
    handleGeneratePaymentAdvice,
    handleOpenDownload,
  }
}
