import { useState } from "react"
import { useTable } from "hooks"
import { toast } from "react-toastify"
import { DateType } from "types/data"
import { useFetchCoursesQuery, useDeleteCourseMutation, useFetchCourseAnalyticsQuery } from "services/api/trainingSlice"
import { shortDate } from "utils/dateFormatter"
import { tryCatch } from "utils/tryCatch"
import { exportData } from "utils/exportData"
import { fullDateFormatter } from "utils/dateFormatter"

export default function useCourses() {
  const { handleSearch, changePage, params, selectLimit } = useTable()
  const [date, setDate] = useState<DateType>({ startDate: null, endDate: null })
  const [selectedAction, setSelectedAction] = useState("")
  const [selectedTrainingType, setSelectedTrainingType] = useState<any>([])

  const [selectedCourse, setSelectedCourse] = useState<any>(null)
  const breadCrumbs = [{ title: "Training" }, { title: "Course List" }]

  const { data: courseAnalytics, isFetching: isLoadingStatistics } = useFetchCourseAnalyticsQuery({})

  const statisticsData = [
    {
      title: "Total Course List",
      count: courseAnalytics?.total_course ?? 0,
      icon: "ph:users-three",
    },
    {
      title: "Total Staff Courses",
      count: courseAnalytics?.staff ?? 0,
      icon: "ph:handshake",
    },
    {
      title: "Total Citizen Courses",
      count: courseAnalytics?.citizen ?? 0,
      icon: "ph:handshake",
    },
  ]

  const { data: coursesData, isFetching: isFetchingCourses } = useFetchCoursesQuery({
    search: params?.search ?? "",
    limit: params?.limit ?? 10,
    training_type: selectedTrainingType?.value,
    page: params?.page,
    from: shortDate(date?.startDate) ?? shortDate(date?.endDate) ?? "",
    to: shortDate(date?.endDate) ?? shortDate(date?.startDate) ?? "",
  })

  const [deleteCourse, { isLoading: isDeletingCourse }] = useDeleteCourseMutation({})

  const handleDeleteCourse = async (courseId: string, onClose: () => void) => {
    const callback = (res: any) => {
      if ([200, 201].includes(res?.code)) {
        toast.success(res.message)
      }
    }
    tryCatch(async () => {
      let res = await deleteCourse(courseId).unwrap()
      callback(res)
      onClose()
    })
  }

  const exportCourseInformation = () => {
    tryCatch(() => {
      const data = coursesData?.courses?.map((course: any) => {
        const date = fullDateFormatter(course.created_at)
        return {
          "Course Title": course?.title ?? "--",
          "Course ID": course?.course_reference ?? "--",
          "Number of Modules": course?.modules?.length ?? 0,
          "Participant Type": course?.training_type ?? "--",
          "Skill Type": course?.skill_type ?? "--",
          "Date Created": date?.replace(/,/g, "") ?? "--",
        }
      })
      exportData(data, "Course Information Data")
    })
  }

  return {
    breadCrumbs,
    handleSearch,
    params,
    changePage,
    selectLimit,
    date,
    setDate,
    selectedAction,
    setSelectedAction,
    selectedTrainingType,
    setSelectedTrainingType,
    isFetchingCourses,
    isLoadingStatistics,
    statisticsData,
    coursesData,
    selectedCourse,
    setSelectedCourse,
    handleDeleteCourse,
    isDeletingCourse,
    exportCourseInformation,
  }
}
