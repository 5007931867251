import { Icon } from "@iconify/react"
import { Button, ManageItem } from "components/core"
import { ReactComponent as Axe } from "assets/icons/axe.svg"
import dayjs from "dayjs"
import { ReactComponent as MiscFeature } from "assets/icons/misc-feature.svg"
import useReview from "./useReview"
import { formatCurrency } from "utils/formatCurrency"
import { currencies } from "constants/currency"

interface Props {
  back: () => void
  formData: any
  id: any
}

const Review = ({ back, formData, id }: Props) => {
  const {
    showMore,
    setShowMore,
    confirmIntervention,
    setConfirmIntervention,
    isCreatingIntervention,
    createEditIntervention,
    isEditingIntervention,
    quotaStructure,
  } = useReview({ formData })
  const currency = currencies.find((currency) => currency.name === formData?.currency)

  return (
    <div className="mt-8">
      <p className="text-3xl text-headers font-semibold">{formData.intervention_title}</p>
      <p className="mt-4 text-body">{formData.description}</p>
      <div className="mt-6 border-t border-neutral-200 pt-4">
        <p className="text-headers text-lg font-medium mb-6">Additional Information</p>
        <div className="border-b border-grey-200 pb-6">
          <div className="grid grid-cols-2 mt-6">
            <p className="text-body">Intervention Reviewer:</p>
            <p className="text-headers">{formData.reviewer_name}</p>
          </div>
          <div className="grid grid-cols-2 mt-6">
            <p className="text-body">Start Date:</p>
            <p className="text-headers">
              {formData.start_date ? dayjs(formData.start_date).format("MMM DD, YYYY") : "N/A"}
            </p>
          </div>
          <div className="grid grid-cols-2 mt-6">
            <p className="text-body">End Date:</p>
            <p className="text-headers">
              {formData.end_date ? dayjs(formData.end_date).format("MMM DD, YYYY") : "N/A"}
            </p>
          </div>
          <div className="grid grid-cols-2 mt-6">
            <p className="text-body">Total Number of Recipient/Beneficiary:</p>
            <p className="text-headers">{formData.total_number_of_beneficiary}</p>
          </div>
          <div className="grid grid-cols-2 mt-6">
            <p className="text-body">Number of eligible household member:</p>
            <p className="text-headers">{formData.number_of_eligible_household_member}</p>
          </div>
          <div className="grid grid-cols-2 mt-6">
            <p className="text-body">Zones:</p>
            <p className="flex flex-wrap gap-2">
              {formData.zones.map((zone: any) => (
                <span className="text-xs text-body bg-grey-50 py-1 px-3 w-fit rounded-2xl font-medium" key={zone.value}>
                  {zone.value}
                </span>
              ))}
            </p>
          </div>
          <div className="grid grid-cols-2 mt-6">
            <p className="text-body">States:</p>
            <p className="flex flex-wrap gap-2">
              {formData.states.map((state: any) => (
                <span
                  className="text-xs text-body bg-grey-50 py-1 px-3 w-fit rounded-2xl font-medium"
                  key={state.value}
                >
                  {state.value}
                </span>
              ))}
            </p>
          </div>
          <div className="grid grid-cols-2 mt-6">
            <p className="text-body">Local Government Area:</p>
            <p className="flex flex-wrap gap-2">
              {formData.lga.map((item: any) => (
                <span className="text-xs text-body bg-grey-50 py-1 px-3 w-fit rounded-2xl font-medium" key={item.value}>
                  {item.value}
                </span>
              ))}
            </p>
          </div>
        </div>
        {showMore ? (
          <div className="mt-4">
            <div>
              <p className="text-headers font-medium text-lg mb-6">Quota Structure</p>
              <table className="w-full">
                <thead>
                  <tr className="text-left h-12 border text-sm text-headers grid grid-cols-12 ">
                    <th className="pl-3 font-medium border-r col-span-9 py-3">States</th>
                    <th className="pl-3 font-medium col-span-3 py-3">Quota</th>
                  </tr>
                </thead>
                <tbody>
                  {quotaStructure.map((item: any) => (
                    <tr className="border h-12 text-sm text-body grid grid-cols-12" key={item.state}>
                      <td className="pl-3 border-r col-span-9 py-3">{item.state}</td>
                      <td className="px-3 col-span-3 py-3">{item.quota}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="mt-6 pt-4 border-t border-neutral-200">
              <p className="text-headers font-medium text-lg">Selection Criteria</p>
              {formData.selection_criteria.length > 0 && (
                <>
                  {formData.selection_criteria.map((item: any) => (
                    <div
                      className="relative pl-4 pb-[18px] last-of-type:border-none border-l-[1px] 
                    border-dashed border-body ml-2 w-full first-of-type:pt-0 first-of-type:mt-4"
                      key={item.condition}
                    >
                      <div className="absolute top-0 left-0 translate-x-[-50%] translate-y-[0%]">
                        <Axe />
                      </div>
                      <p className="font-medium text-body pb-2">
                        {item.condition} <span className="text-primary-actions italic">{item.operator}</span>{" "}
                        {item.value}
                      </p>
                    </div>
                  ))}
                </>
              )}
            </div>
            <div className="pt-4 border-t border-neutral-200">
              <p className="text-headers font-medium text-lg">Payment</p>
              <div className="grid grid-cols-2 mt-6">
                <p className="text-body">Payment method:</p>
                <p className="text-headers">{formData.payment_method}</p>
              </div>
              <div className="grid grid-cols-2 mt-6">
                <p className="text-body">Amount:</p>
                <p className="text-headers">{formatCurrency(formData?.amount, currency?.currency, currency?.symbol)}</p>
              </div>
              <div className="grid grid-cols-2 mt-6">
                <p className="text-body">Payment Recurrence:</p>
                <p className="text-headers">{formData.payment_recurrence}</p>
              </div>
              <div className="grid grid-cols-2 mt-6">
                <p className="text-body">Payment Mechanism:</p>
                <p className="text-headers">{formData.payment_mechanism}</p>
              </div>
              <div className="grid grid-cols-2 mt-6">
                <p className="text-body">Payment Cycle:</p>
                <p className="text-headers">{formData.payment_cycle}</p>
              </div>
            </div>
            <div className="mt-4">
              {formData?.eligibility_criteria?.["co-responsibility"]?.length > 0 && (
                <div className="pt-4 border-t border-neutral-200">
                  <p className="text-headers font-medium text-lg">Additional Eligibility</p>
                  <table className="w-full mt-4">
                    <thead>
                      <tr className="text-left h-12 border text-sm text-headers grid grid-cols-12 ">
                        <th className="pl-3 font-medium col-span-12 py-3">Co-responsibility</th>
                      </tr>
                    </thead>
                    <tbody>
                      {formData?.eligibility_criteria?.["co-responsibility"]?.map((item: any) => (
                        <tr className="h-12 text-sm text-body grid grid-cols-12 border-b" key={item.percentage}>
                          <td className="pl-3 col-span-9 py-3 border-x">{item.condition}</td>
                          <td className="px-3 col-span-3 border-r py-3">{item.percentage}%</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
              {formData?.eligibility_criteria?.["livelihood"]?.length > 0 && (
                <table className="w-full mt-6">
                  <thead>
                    <tr className="text-left h-12 border text-sm text-headers grid grid-cols-12 ">
                      <th className="pl-3 font-medium col-span-12 py-3">Livelihood</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formData?.eligibility_criteria?.["livelihood"]?.map((item: any) => (
                      <tr className="h-12 text-sm text-body grid grid-cols-12 border-b" key={item.percentage}>
                        <td className="pl-3 col-span-9 py-3 border-x">{item.condition}</td>
                        <td className="px-3 col-span-3 border-r py-3">{item.percentage}%</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
            <div className="mt-4 py-4 border-y border-neutral-200">
              <p className="text-headers font-medium text-lg">Departmental Access</p>
              <div className="grid grid-cols-2 mt-6">
                <p className="text-body">Department</p>
                <p className="flex flex-wrap gap-1">
                  {formData.departments.map((department: any) => (
                    <span
                      className="text-xs text-body bg-grey-50 py-1 px-3 w-fit rounded-2xl font-medium mr-2"
                      key={department.value}
                    >
                      {department.label}
                    </span>
                  ))}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="border-b border-grey-200 py-4 text-12 font-medium text-headers text-xs flex justify-center items-center cursor-pointer"
            data-testid="showMore"
            onClick={() => setShowMore((prevState: any) => !prevState)}
          >
            <p>Show More</p>
            <Icon icon="solar:alt-arrow-down-line-duotone" className="text-base ml-1" />
          </div>
        )}
      </div>
      <div className="flex space-x-4 mt-12">
        <Button
          theme="primary"
          size="44"
          className="min-w-[171px]"
          onClick={() => {
            id ? createEditIntervention() : setConfirmIntervention(true)
          }}
          loading={id && isEditingIntervention}
        >
          {id ? "Save changes" : "Submit"}
        </Button>
        <Button theme="transparent" className="w-[171px] px-[30px]" type="button" size="44" onClick={back}>
          Back
        </Button>
      </div>
      {confirmIntervention && (
        <ManageItem
          title="Are you sure?"
          actionName="Submit"
          cancelBtnText="Discard"
          caption="Once submitted, this intervention will be sent to the reviewer for approval. Kindly ensure all information are correct."
          icon={<MiscFeature />}
          loading={isCreatingIntervention}
          onClose={() => setConfirmIntervention((prev: any) => !prev)}
          onSubmit={createEditIntervention}
        />
      )}
    </div>
  )
}

export default Review
