import { RenderIf } from "components/hoc"
import HeaderLoader from "components/core/Loader/HeaderLoader/HeaderLoader"
import { singleTrainingDetailsHeaders } from "constants/trainings"

interface SingleTrainingInfoProps {
  isLoading: boolean
  detailsData: any
  trainingInfo: { name: string; description: string }
}

const SingleTrainingInfo = ({ isLoading, detailsData, trainingInfo }: SingleTrainingInfoProps) => {
  return (
    <div>
      <RenderIf condition={isLoading}>
        <HeaderLoader />
      </RenderIf>
      <RenderIf condition={!isLoading}>
        <div
          data-testid="training-info"
          className="bg-primary-900 text-primary-30 font-normal text-sm min-h-[172px] rounded-lg p-6 flex flex-col"
        >
          <div className="pb-8 border-b-[0.3px] border-b-grey-200 space-y-2">
            <h1 className="text-lg font-semibold capitalize">{trainingInfo?.name}</h1>
            <p className="text-sm capitalize">{trainingInfo?.description}</p>
          </div>
          <div className="mt-4 grid grid-cols-6 grid-rows-2 gap-y-4">
            {singleTrainingDetailsHeaders?.map((header, index) => (
              <div
                className={`flex flex-col gap-[8px]
                      ${index >= 6 ? "border-t-[0.3px] border-[#E6E6E6] pt-4" : ""}
                      ${index % 6 !== 0 ? "border-l-[0.5px] border-[#E6E6E6] pl-4" : ""}`}
                key={header}
              >
                <span className="text-[#C6E4C4] text-xs font-medium">{header}</span>
                <p className="text-primary-30 font-normal text-sm mt-[2px] truncate">{detailsData[index]}</p>
              </div>
            ))}
          </div>
        </div>
      </RenderIf>
    </div>
  )
}

export default SingleTrainingInfo
