import Papa from "papaparse"
import { useState, useEffect, useMemo } from "react"
import { toast } from "react-toastify"
import { useCreateTrainingMutation, useFetchCoursesQuery } from "services/api/trainingSlice"
import { tryCatch } from "utils/tryCatch"

// interface FormDataInterface {
//   training_type: string
//   training_unit: string
//   name: string
//   course: string
//   course_id: string
//   attendance_required: string
//   training_needs: string
//   start_date: string | null
//   end_date: string | null
//   training_vendor: string[]
//   entry_mode: string
// }

const useAddTraining = () => {
  const [currentTab, setCurrentTab] = useState("manual")
  const [file, setFile] = useState({})
  const [csvData, setCsvData] = useState([])

  const tabs = [
    {
      name: "Enter Manually",
      value: "manual",
      icon: "ph:user",
    },

    {
      name: "Bulk Upload",
      value: "bulk",
      icon: "ph:users-three",
    },
  ]
  const [formData, setFormData] = useState({
    training_type: "",
    skill_type: "",
    training_unit: "",
    name: "",
    course: "",
    course_id: [],
    attendance_required: "",
    training_needs: "",
    start_date: null,
    end_date: null,
    training_vendor: [""],
    entry_mode: currentTab,
  })

  const { data: coursesData, isFetching: isFetchingCourses } = useFetchCoursesQuery(
    {
      limit: 1000,
      training_type: formData?.training_type,
      skill_type: formData?.skill_type
    },
    { skip: !(formData?.training_type && formData?.skill_type) },
  )

  const convertCsvtoJson = (file) => {
    setFile({
      name: file.name,
      size: (file.size / 1000).toFixed(2),
    })
    if (file) {
      const fileDetails = file
      const reader = new FileReader()
      reader.onload = () => {
        const data = reader.result
        // Parse CSV using PapaParse
        const result = Papa.parse(data, {
          header: true,
        })
        setCsvData(result.data)
      }
      reader.readAsText(fileDetails)
    }
  }

  const courseDropdownElements = useMemo(() => {
    return coursesData?.courses?.map((course) => {
      return {
        label: course?.title,
        value: course.course_id,
      }
    })
  }, [coursesData])

  const [createTraining, { isLoading: isCreatingTraining }] = useCreateTrainingMutation({})

  const addNewVendor = () => {
    setFormData((prevData) => ({
      ...prevData,
      training_vendor: [...prevData.training_vendor, ""],
    }))
  }

  const handleDeleteVendor = (index) => {
    const filteredVendors = formData?.training_vendor?.filter((_value, valueIndex) => valueIndex !== index)

    setFormData((prevData) => ({
      ...prevData,
      training_vendor: filteredVendors,
    }))
  }

  // this is supposed to reset the training type anytime we change a training type since they unit depends on the type selected
  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      training_unit: "",
      course_id: [],
      course: "",
    }))
  }, [formData?.training_type])

  const handleCreateBulkTraining = async (setSelectedAction, setUploadMessage) => {
    if (!csvData || csvData.length === 0) {
      toast.error("No data in this file, please upload another.")
      setFile([])
      setCsvData([])
      return
    }

    const trainingDataArray = csvData.map((item) => ({
      name: item["Training name"],
      // course_id: item["Course ID"],
      start_date: item["Start Date"],
      end_date: item["End Date"],
      training_vendor: item["Vendor"] ? [item["Vendor"]] : [], // Assumes single vendor in CSV
      training_needs: item["Training Needs"] ?? "", // Add this if required from other sources
      attendance_required: item["Attendance Required"],
    }))

    const payload = {
      training_type: csvData[0]["Training Type"]?.toLowerCase(), // Assuming the same training type for all rows
      training_unit: csvData[0]["Training Unit"]?.toLowerCase(), // Assuming the same unit for all rows
      skill_type: csvData[0]["Skill Type"]?.toLowerCase(),
      entry_mode: currentTab,
      training_data: trainingDataArray,
    }

    const callback = (res) => {
      if ([200, 201].includes(res?.code)) {
        toast.success(res.message)
        setSelectedAction('upload-success')
        setUploadMessage(res.data)
      }
    }

    tryCatch(async () => {
      let res = await createTraining(payload).unwrap()
      callback(res)
    })
  }

  const handleCreateTraining = async (setSelectedAction) => {
    // eslint-disable-next-line no-unused-vars
    const {
      // eslint-disable-next-line no-unused-vars
      course,
      training_vendor,
      attendance_required,
      end_date,
      start_date,
      name,
      course_id,
      training_needs,
      ...data
    } = formData

    const payload = {
      ...data,
      training_data: {
        name,
        courses_id: course_id,
        start_date,
        end_date,
        ...(training_vendor.length > 0 && { training_vendor }),
        training_needs,
        attendance_required,
      },
    }
    const callback = (res) => {
      if ([200, 201].includes(res?.code)) {
        toast.success(res.message)
        setSelectedAction("")
      }
    }

    tryCatch(async () => {
      let res = await createTraining(payload).unwrap()
      callback(res)
    })
  }

  const isDisabledBtn = useMemo(() => {
    if (currentTab === "manual") {
      return (
        !formData.training_type ||
        !formData.name ||
        !formData.skill_type||
        !formData.start_date ||
        !formData.end_date ||
        !formData?.course_id ||
        !formData?.training_needs ||
        !formData?.attendance_required ||
        formData?.training_vendor?.some((module) => !module)
      )
    }

    if (currentTab === "bulk") {
      return Object.keys(file).length === 0
    }

    return false
  }, [formData, currentTab, file])

  useEffect(() => {
    setFormData({
      training_type: "",
      training_unit: "",
      skill_type: "",
      name: "",
      course: "",
      course_id: [],
      attendance_required: "",
      training_needs: "",
      start_date: null,
      end_date: null,
      training_vendor: [""],
      entry_mode: currentTab,
    })
    setFile({})
    setCsvData([])
  }, [currentTab])

  const handleSubmit = async ( setSelectedAction, setUploadMessage) => {
    if (currentTab === "manual") {
      await handleCreateTraining( setSelectedAction)
    } else if (currentTab === "bulk") {
      await handleCreateBulkTraining(setSelectedAction, setUploadMessage)
    }
  }

  return {
    formData,
    setFormData,
    addNewVendor,
    isCreatingTraining,
    isDisabledBtn,
    courseDropdownElements,
    isFetchingCourses,
    handleDeleteVendor,
    currentTab,
    setCurrentTab,
    tabs,
    convertCsvtoJson,
    file,
    setFile,
    handleSubmit,
  }
}

export default useAddTraining
