import { Breadcrumb, Button } from "components/core"
import { RenderIf } from "components/hoc"
import { ReportDetails } from "components/pages/Reports"
import CategoryDetails from "./components/CategoryDetails"
import { DateRange } from "./components/DateRange"
import GRMStructure from "./components/GRMStructure"
import { Preview } from "./components/Preview"
import useGenerateGRMReport from "./useGenerateGRMReport"

const GenerateGRMReport = () => {
  const { close, refresh, ...state } = useGenerateGRMReport()

  return (
    <>
      <div className="max-w-[1100px] w-full">
        <Breadcrumb options={state.breadcrumbs} showBack={true} />
        <div className="mb-[32px]">
          <h1 className="text-xl text-headers font-semibold mb-[8px]">Report Parameters</h1>
          <p className="text-sm text-body">
            Ensure that all information provided here are verified and correct before proceeding
          </p>
        </div>
      </div>
      <div className="">
        <ReportDetails state={state} />
        <hr className="ml-6 my-6" />
        <DateRange state={state} />
        <hr className="ml-6 my-6" />
        <CategoryDetails state={state} />
        <hr className="ml-6 my-6" />
        <GRMStructure state={state} />
        <div className="flex justify-start items-center mt-8">
          <Button
            theme="primary"
            size="44"
            className="min-w-[171px] mr-[16px]"
            onClick={state.proceed}
            disabled={!state.formData.reportTitle}
          >
            Proceed
          </Button>
          <Button theme="transparent" className="w-[171px] px-[30px]" type="button" size="44" onClick={refresh}>
            Refresh
          </Button>
        </div>
      </div>
      <RenderIf condition={state.showPreview}>
        <Preview close={close} state={state} />
      </RenderIf>
    </>
  )
}

export default GenerateGRMReport
