import { Icon } from "@iconify/react"
import { Button, Calendar, Filter, Search, Table } from "components/core"
import PaymentStatus from "components/pages/Payments/Status/PaymentStatus"
import { livelihoodHeader } from "constants/livelihood"
import { paymentStatus } from "mocks/payments"
import { defaultFilter } from "mocks/staffs"
import { useNavigate } from "react-router-dom"
import useLivelihood from "./useLivelihood"
import dayjs from "dayjs"
import { formatCurrency } from "utils/formatCurrency"
import { getCurrencyFormat } from "utils/formatCurrency"
import { convertStateToDropdownOptions } from "utils/dropdown"
import { Restricted } from "components/hoc"
import { AllPermissions, PermissionModule } from "types/permission"
import { usePermission } from "hooks"

const Livelihood = () => {
  const navigate = useNavigate()
  const {
    summaryData,
    nigerianStates,
    setSelectedStatus,
    selectedStatus,
    selectedState,
    setSelectedState,
    handleSearch,
    setDate,
    date,
    livelihoods,
    selectLimit,
    changePage,
    isFetching,
    isExporting,
    exportAllLivelihoods,
  } = useLivelihood()

  const { LIVELIHOOD } = PermissionModule
  const { VIEW_ALL_LIVELIHOODS, VIEW_SINGLE_LIVELIHOOD } = AllPermissions
  const { hasPermission } = usePermission()

  return (
    <Restricted module={LIVELIHOOD} permission={VIEW_ALL_LIVELIHOODS} showError={true}>
      <main data-testid="livelihood">
        <h1 className="text-headers text-2xl font-bold">Livelihood</h1>
        <div className="flex items-center py-[24px] border-y mt-11">
          {summaryData.map((item) => (
            <div key={item.title} className="flex items-center gap-4 mr-[40px]">
              <div className="relative w-10 h-10 grid place-items-center bg-grey-100 rounded-full">
                <Icon icon={item.icon} className="w-6 h-6 text-body" />
              </div>
              <div className="grid">
                <p className="text-sm text-body">{item.title}</p>
                <div className="text-base font-medium text-headers">
                  {isFetching ? "..." : item.count}
                  {item.title === "Total Community List" && (
                    <button className="text-primary-actions ml-1 cursor-pointer" onClick={() => navigate("community")}>
                      {`(View Community>>>)`}
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="flex items-center justify-between mb-4 mt-6">
          <div className="flex space-x-2">
            <Search id="search" name="search" placeholder="Search" onChange={handleSearch} />
            <Filter
              title="Status:"
              options={paymentStatus}
              selectedOption={selectedStatus}
              multiple={true}
              setSelectedOption={setSelectedStatus}
            />
            <Filter
              title="State:"
              options={[...defaultFilter, ...convertStateToDropdownOptions(nigerianStates)]}
              multiple={true}
              selectedOption={selectedState}
              setSelectedOption={setSelectedState}
            />
            <Calendar startDate={date.startDate} endDate={date.endDate} onChange={setDate} selectsRange={true} />
          </div>
          <Restricted module={LIVELIHOOD} permission={VIEW_ALL_LIVELIHOODS}>
            <Button type="button" size="40" disabled={isExporting} theme="transparent" onClick={exportAllLivelihoods}>
              <Icon icon="uiw:download" className="text-[#747F9C] w-5 h-5 mr-3" />
              {isExporting ? "Exporting..." : "Export"}
            </Button>
          </Restricted>
        </div>
        <Table
          headers={livelihoodHeader}
          data={livelihoods?.livelihood ?? []}
          onPageChange={changePage}
          selectLimit={selectLimit}
          totalCount={livelihoods?.totalCount}
          perPage={livelihoods?.limit}
          page={livelihoods?.page}
          loading={isFetching}
        >
          <>
            {livelihoods?.livelihood?.map((item) => (
              <tr
                data-testid={item?.livelihood_id}
                key={item?.livelihood_id}
                className="cc-custom-table-data-row cursor-pointer"
                onClick={() =>
                  hasPermission(LIVELIHOOD, VIEW_SINGLE_LIVELIHOOD) ? navigate(`${item?.intervention_id}`) : {}
                }
              >
                <td className="flex items-center ">
                  <div>
                    <p className="max-w-[280px] truncate capitalize">{item?.intervention_title}</p>
                    <p className="text-grey-base text-xs max-w-[250px] truncate font-medium">
                      <span className="text-headers">{item?.total_number_of_beneficiary}</span> Livelihood Beneficiaries
                    </p>
                  </div>
                </td>
                <td>
                  <p className="w-max">{item.livelihood_id}</p>
                </td>
                <td>
                  <p className="w-max">{formatCurrency(item?.total_amount, getCurrencyFormat(item?.currency))}</p>
                </td>
                <td>
                  <p className="w-max">{formatCurrency(item?.top_amount, getCurrencyFormat(item?.currency))}</p>
                </td>
                <td>{dayjs(item.created_at).format("MMMM DD, YYYY")}</td>
                <td>
                  <PaymentStatus status={item?.status} />
                </td>
              </tr>
            ))}
          </>
        </Table>
      </main>
    </Restricted>
  )
}

export default Livelihood
