import { Button } from "components/core"
import { Dropdown } from "components/core/Dropdown/Dropdown"
import { compulsoryDepartment } from "constants/intervention"
interface props {
  formData: any
  setFormData: any
  proceed: () => void
  back: () => void
  departments: any
  loading: boolean
}

const DepartmentAccess = ({ setFormData, formData, proceed, back, departments, loading }: props) => {
  return (
    <div className="mt-8">
      <Dropdown
        label="Departments"
        defaultOption={formData.departments}
        multiple={true}
        loading={loading}
        fixedOptions={compulsoryDepartment}
        options={departments}
        search={false}
        onSelect={(data: any) => {
          setFormData({ ...formData, departments: data })
        }}
      />
      <div className="flex justify-start items-center mt-12 space-x-4">
        <Button
          theme="primary"
          size="44"
          className="min-w-[171px]"
          onClick={proceed}
          disabled={!formData.departments.length}
        >
          Save & Proceed
        </Button>
        <Button theme="transparent" className="w-[171px] px-[30px]" type="button" onClick={back} size="44">
          Back
        </Button>
      </div>
    </div>
  )
}

export default DepartmentAccess
