function getFirstTwoNames(namesString: any): string {
  const namesArray: string[] = namesString?.split(" ")

  const firstTwoNames: string[] = namesArray?.slice(0, 2)

  const result: string = firstTwoNames?.join(" ")

  return result
}

export default getFirstTwoNames
