import { Button } from "components/core"
import GrievanceStatistics from "components/pages/Grievance/GrievanceStatistics/GrievanceStatistics"
import { Icon } from "@iconify/react"
import { useNavigate } from "react-router-dom"
import GrievanceInfo from "components/pages/Grievance/GrievanceInfo/GrievanceInfo"

const Grievance = () => {
  const navigate = useNavigate()

  const routeToCreateGrievance = () => {
    navigate(`/grievance/create`)
  }

  return (
    <main>
      <div className="flex items-center justify-between">
        <h1 className="text-headers text-2xl font-bold">Grievance Redress Mechanism (GRM)</h1>
        <Button
          data-testid="create-btn"
          size="40"
          theme="primary"
          className="font-medium text-sm"
          onClick={routeToCreateGrievance}
        >
          <Icon icon="ph:plus" className="w-6 h-6 mr-[8px]" />
          New Grievance
        </Button>
      </div>
      <GrievanceStatistics />
      <GrievanceInfo />
    </main>
  )
}

export default Grievance
