import { Icon } from "@iconify/react"
import { Actions, Button, Calendar, ChartWrapper, Dropdown, ManageItem, Table } from "components/core"
import { RenderIf } from "components/hoc"
import { reportHistoryHeader } from "constants/reports"
import { reportChartOptions } from "utils/chartOptions"
import useLivelihoodSummary from "./useLivelihoodSummary"
import { Period } from "types/common"
import { monthSummaryOptions, period } from "constants/global"
import { useNavigate } from "react-router-dom"
import { fullDateFormatter, getPreviousYears } from "utils/dateFormatter"
import HeaderLoader from "components/core/Loader/HeaderLoader/HeaderLoader"
import { addCommasToNumbers } from "utils/addCommasToNumbers"
import { OverviewItem } from "components/pages/Reports"

const LivelihoodSummary = () => {
  const navigate = useNavigate()
  const { reports, date, selectedReportId, ...state } = useLivelihoodSummary()

  return (
    <div className="w-full pr-20">
      <div>
        <h1 className="text-xl text-headers font-semibold mb-2">Livelihood Report</h1>
        <p className="text-sm text-body">
          Ensure that all information provided here are verified and correct before proceeding
        </p>
      </div>
      <RenderIf condition={state.isLoadingSummary && state.isLoadingHistory}>
        <HeaderLoader />
      </RenderIf>
      <RenderIf condition={!state.isLoadingSummary}>
        <div className="grid grid-cols-3 gap-4 py-6 border-y my-8 w-full">
          {state.overview.map((details) => (
            <OverviewItem
              key={details.title}
              icon={<Icon icon={details.icon} className="w-6 h-6 text-secondary-base" />}
              title={details.title}
              value={details.count}
              stat={details.stat}
            />
          ))}
        </div>
        <div className="mb-8 border border-[#E6E6E6] rounded-[10px] p-6 w-11/12 mt-3">
          <div className="mb-10 flex items-center">
            <Dropdown
              label=""
              selected={state.periodFilter?.type}
              wrapperClassName="w-32 !mb-0"
              containerVariant="h-10"
              options={period}
              onSelect={(data: any) => {
                state.setPeriodFilter({ type: data.value, selected: "" })
              }}
            />
            <div className="ml-[24px]">
              <RenderIf condition={state.periodFilter?.type === Period.MONTHLY}>
                <Dropdown
                  selected={state.periodFilter?.selected}
                  wrapperClassName="w-32 !mb-0"
                  containerVariant="h-10"
                  options={monthSummaryOptions}
                  onSelect={(data: any) => {
                    state.setPeriodFilter({ ...state.periodFilter, selected: data.value })
                  }}
                />
              </RenderIf>
              <RenderIf condition={state.periodFilter?.type === Period.YEARLY}>
                <Dropdown
                  selected={state.periodFilter?.selected}
                  wrapperClassName="w-32 !mb-0"
                  containerVariant="h-10"
                  options={getPreviousYears()}
                  onSelect={(data: any) => {
                    state.setPeriodFilter({ ...state.periodFilter, selected: data.value })
                  }}
                />
              </RenderIf>
              <RenderIf condition={state.periodFilter?.type === Period.CUSTOM}>
                <Calendar
                  startDate={date.startDate}
                  endDate={date.endDate}
                  onChange={state.setDate}
                  selectsRange={true}
                  containerVariant="w-fit"
                />
              </RenderIf>
            </div>
          </div>
          <div className="flex items-center">
            <div className="mr-12">
              <ChartWrapper
                type="doughnut"
                options={reportChartOptions}
                data={state.chartData}
                containerClass="h-[305px]"
              />
            </div>
            <div className="w-full">
              <div>
                <div className="mb-[4px] flex items-center">
                  <div className="w-[10px] h-[10px] rounded-full bg-[#2B892B] mr-[5px]" />
                  <span className="text-sm text-[#7F8493]">Paid</span>
                </div>
                <p className="text-xl text-[#363840] font-semibold">{addCommasToNumbers(state.summary?.paidCount)}</p>
              </div>
              <hr className="my-6" />
              <div>
                <div className="mb-[4px] flex items-center">
                  <div className="w-[10px] h-[10px] rounded-full bg-[#024F0B] mr-[5px]" />
                  <span className="text-sm text-[#7F8493]">Unpaid</span>
                </div>
                <p className="text-xl text-[#363840] font-semibold">{addCommasToNumbers(state.summary?.unpaidCount)}</p>
              </div>
              <hr className="my-6" />
              <div>
                <div className="mb-[4px] flex items-center">
                  <div className="w-[10px] h-[10px] rounded-full bg-[#C6E4C4] mr-[5px]" />
                  <span className="text-sm text-[#7F8493]">In progress</span>
                </div>
                <p className="text-xl text-[#363840] font-semibold">
                  {addCommasToNumbers(state.summary?.inProgressCount)}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end mb-6">
          <Button type="button" size="40" theme="primary" onClick={() => navigate("generate-report")}>
            Generate Report
          </Button>
        </div>
        <Table
          headers={reportHistoryHeader}
          paginateData={!!reports?.reports.length}
          data={reports?.reports ?? []}
          onPageChange={state.changePage}
          selectLimit={state.selectLimit}
          totalCount={reports?.total}
          perPage={reports?.limit}
          page={Number(1)}
          loading={state?.isFetchingHistory}
        >
          <>
            {reports?.reports?.map((item: any) => (
              <tr data-testid={item?.report_id} key={item?.report_id} className="cc-custom-table-data-row">
                <td>{item?.report_title}</td>
                <td>{fullDateFormatter(item?.generated_date)}</td>
                <td>{item?.generated_by}</td>
                <td className="w-[60px]">
                  <Actions actions={state.actions} id={item.report_id} item={item} />
                </td>
              </tr>
            ))}
          </>
        </Table>
      </RenderIf>
      <RenderIf condition={!!selectedReportId}>
        <ManageItem
          title="Do you want to delete report?"
          actionName="Delete"
          cancelBtnText="Cancel"
          caption="Confirming this action will delete this report. Be sure you want to perform this action."
          loading={state.isDeletingReport}
          onClose={() => state.setSelectedReportId("")}
          onSubmit={state.deleteReport}
        />
      </RenderIf>
    </div>
  )
}

export default LivelihoodSummary
