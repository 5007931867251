export const livelihoodHeader = [
  {
    name: "intervention title",
    sort: false,
    action: null,
  },
  {
    name: "livelihood ID",
    sort: false,
    action: null,
  },
  {
    name: "Total Base Amount",
    sort: false,
    action: null,
  },
  {
    name: "Total Top Up amount",
    sort: false,
    action: null,
  },
  {
    name: "date created",
    sort: false,
    action: null,
  },
  {
    name: "status",
    sort: false,
    action: null,
  },
]
export const singleLivelihoodHeader = [
  {
    name: "Full name",
    sort: false,
    action: null,
  },
  {
    name: "State",
    sort: false,
    action: null,
  },
  {
    name: "Lga",
    sort: false,
    action: null,
  },
  {
    name: "Ward",
    sort: false,
    action: null,
  },
  {
    name: "Community",
    sort: false,
    action: null,
  },
  {
    name: "Gender",
    sort: false,
    action: null,
  },
]
export const communityHeader = [
  {
    name: "Community Title",
    sort: false,
    action: null,
  },
  {
    name: "State",
    sort: false,
    action: null,
  },
  {
    name: "Lga",
    sort: false,
    action: null,
  },
  {
    name: "Number of Groups",
    sort: false,
    action: null,
  },
  {
    name: "Savings Amount",
    sort: false,
    action: null,
  },
  {
    name: "Number of Beneficiary",
    sort: false,
    action: null,
  },
  {
    name: "Total Loan Given",
    sort: false,
    action: null,
  },
]
export const singleCommunityHeader = [
  {
    name: "Groups",
    sort: false,
    action: null,
  },
  {
    name: "Ward",
    sort: false,
    action: null,
  },
  {
    name: "Number of Members",
    sort: false,
    action: null,
  },
  {
    name: "Total Saving Amount",
    sort: false,
    action: null,
  },
  {
    name: "Total Loan Given",
    sort: false,
    action: null,
  },
  {
    name: "Duration",
    sort: false,
    action: null,
  },
  {
    name: "Date Created",
    sort: false,
    action: null,
  },
]
export const groupHeader = [
  {
    name: "Full Name",
    sort: false,
    action: null,
  },
  {
    name: "Amount Saved",
    sort: false,
    action: null,
  },
  {
    name: "Saving Per cycle",
    sort: false,
    action: null,
  },
  {
    name: "Loan COllected",
    sort: false,
    action: null,
  },
  {
    name: "Repayment status",
    sort: false,
    action: null,
  },
  {
    name: "GENDER",
    sort: false,
    action: null,
  },
  {
    name: "REMARK",
    sort: false,
    action: null,
  },
]
export const interventionHistoryHeader = [
  {
    name: "intervention title",
    sort: false,
    action: null,
  },
  {
    name: "Base Amount",
    sort: false,
    action: null,
  },
  {
    name: "Top Up amount",
    sort: false,
    action: null,
  },
  {
    name: "status",
    sort: false,
    action: null,
  },
  {
    name: "date created",
    sort: false,
    action: null,
  },
  {
    name: "payment cycle",
    sort: false,
    action: null,
  },
]
