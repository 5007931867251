import React from "react"
import { Button, Calendar, Filter, Search, Table } from "components/core"
import InterventionStatus from "components/pages/Beneficiary/Status/InterventionStatus"
import { Icon } from "@iconify/react"
import { bankedBeneficiaryPaymentHistoryTableHeader } from "constants/beneficiary"
import { motion } from "framer-motion"
import { PaymentHistoryProps, SingleBeneficiaryIntervention } from "types/beneficiary"
import { fullDateFormatter } from "utils/dateFormatter"
import { addCommasToNumbers } from "utils/addCommasToNumbers"
import { paymentHistoryStatus } from "utils/payment"

const MotionTr = motion.tr

export default function PaymentHistory({
  date,
  setDate,
  changePage,
  statusOptions,
  selectedStatus,
  setSelectedStatus,
  beneficiaryPaymentHistory,
  isFetchingPaymentHistory,
  exportPaymentHistory,
  isExportingLoading,
  handleSearchPayment,
}: PaymentHistoryProps) {
  return (
    <>
      <div className="flex items-center justify-between mb-6">
        <div className="flex space-x-2">
          <Search id="search" name="search" placeholder="Search" onChange={handleSearchPayment} />
          <Filter
            title="Status"
            multiple={false}
            options={statusOptions}
            selectedOption={selectedStatus}
            setSelectedOption={setSelectedStatus}
          />
          <Calendar startDate={date.startDate} endDate={date.endDate} onChange={setDate} selectsRange={true} />
        </div>
        <Button
          type="button"
          size="40"
          theme="transparent"
          disabled={isExportingLoading}
          onClick={exportPaymentHistory}
        >
          <Icon icon="uiw:download" className="text-[#747F9C] w-6 h-6 mr-3" />
          {isExportingLoading ? "Exporting..." : "Export"}
        </Button>
      </div>
      <Table
        headers={bankedBeneficiaryPaymentHistoryTableHeader}
        data={beneficiaryPaymentHistory?.transactions}
        onPageChange={changePage}
        totalCount={beneficiaryPaymentHistory?.total}
        perPage={10}
        page={1}
        loading={isFetchingPaymentHistory}
        paginateData={!!beneficiaryPaymentHistory?.transactions}
        emptyStateText={"No data yet"}
        emptyStateImage={"layout"}
      >
        <>
          {beneficiaryPaymentHistory?.transactions?.map((item: SingleBeneficiaryIntervention) => (
            <React.Fragment key={item.intervention_id}>
              <MotionTr
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="cc-custom-table-data-row"
                data-testid={item.intervention_id}
              >
                <td className="text-sm text-body font-normal">{item?.transaction_id ?? "-"}</td>
                <td className="text-sm text-body font-normal">{addCommasToNumbers(item.amount) ?? "-"}</td>
                <td className="text-sm text-body font-normal">{fullDateFormatter(item.payment_date)}</td>
                <td className="text-sm text-body font-normal">{item.destination_account ?? "-"}</td>
                <td className="text-sm text-body font-normal">
                  {<InterventionStatus status={paymentHistoryStatus(item.payment_status)} />}
                </td>
              </MotionTr>
            </React.Fragment>
          ))}
        </>
      </Table>
    </>
  )
}
