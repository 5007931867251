import { RenderIf } from "components/hoc"
import React from "react"

interface OverviewProps {
  title: string
  description?: string
  details: any[]
  purpose?: string
  onClick?: () => void
  permitted?: boolean // User permission to access button
}

export const Overview: React.FC<OverviewProps> = ({
  title,
  description,
  details,
  purpose,
  onClick,
  permitted = true,
}) => {
  return (
    <div className="p-6 bg-primary-900 rounded-[8px] mt-3 gap-8 min-h-[194px]">
      <div className="flex justify-between border-b-[.3px] border-b-grey-200 pb-[32px]">
        <div className="w-[80%]">
          <h3 className="text-white font-semibold text-2xl capitalize">{title}</h3>
          <p className="text-grey-300 mt-1 text-base line-clamp-2 text-ellipsis overflow-hidden !capitalize-first">
            {description}
          </p>
        </div>
        <RenderIf condition={permitted}>
          {purpose !== "requesting-approval" && (
            <button
              className="cc-button w-[171px] h-[37px] text-primary-actions bg-primary-50 font-medium"
              onClick={onClick}
            >
              Edit Intervention
            </button>
          )}
        </RenderIf>
      </div>
      <div className="mt-[16px] flex divide-x child-not-last:pr-6 child-not-first:pl-6 ">
        {details.map((detail) => (
          <div key={detail.name}>
            <p className="text-primary-100 text-xs font-medium">{detail.name}</p>
            <p className="text-primary-30 text-sm mt-[2px]">{detail.value || "N/A"}</p>
          </div>
        ))}
      </div>
    </div>
  )
}
