import { Icon } from "@iconify/react"
import { Button } from "components/core"

interface Props {
  value: string
  onChange: any
  close: () => void
  loading?: boolean
  submit?: () => void
}

const EscalateGrievance = ({ value, onChange, close, loading, submit }: Props) => {
  return (
    <div
      className="absolute min-w-[715px] w-full h-fit top-[48%]
    left-1/2 transform -translate-x-1/2 bg-white border border-grey-200 p-4 z-50 rounded-lg"
      data-testid="escalate-grievance"
    >
      <textarea
        style={{ resize: "none" }}
        rows={3}
        data-testid="escalate-textarea"
        maxLength={320}
        onChange={(e: any) => onChange(e)}
        placeholder="What is the reason for escalation?..."
        value={value}
        className={` text-body text-sm w-full h-full outline-0 hide_tap
        rounded-[4px] border-none focus:border-primary-200
        `}
      />
      <div className="flex items-center justify-between mt-2 ">
        <p className="text-xs text-neutral-30 text-right">
          {value.length}/{"320"} words
        </p>
        <div className="flex gap-x-2">
          <Button size="32" onClick={close}>
            Cancel
          </Button>
          <Button
            theme="primary"
            size="32"
            loading={loading}
            data-testid={"escalate-button"}
            onClick={submit}
            disabled={value.length === 0 || value?.length > 320}
            className="space-x-1"
          >
            <span>Escalate</span>
            <Icon icon="ph:paper-plane-tilt" className="text-white" />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default EscalateGrievance
