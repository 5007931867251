export const handleError = ({ status, message }: { status: number; message: string }) => {
  switch (status) {
    case 400:
    case 401:
    case 403:
    case 404:
    case 422:
    case 500:
      return message
    default:
      return window.navigator.onLine ? message || "Something went wrong!" : "No internet connection"
  }
}
