import Training from "pages/training/Training"
import Courses from "pages/training/courses/Courses"
import SingleCourse from "pages/training/courses/SingleCourse/SingleCourse"
import SingleTraining from "pages/training/singleTraining/SingleTraining"
import TrainingBeneficiary from "pages/training/beneficiary/TrainingBeneficiary"

export const trainingRoutes = [
  {
    path: "training",
    element: <Training />
  },
  {
    path: "training/courses",
    element: <Courses />
  },
  {
    path: "training/courses/:id",
    element: <SingleCourse />
  },
  {
    path: "training/:id",
    element: <SingleTraining />
  },
  {
    path: "/training/beneficiary/:training_id/:id",
    element: <TrainingBeneficiary />
  }
]