import React, { ReactNode, useEffect, useState } from "react"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import PropTypes from "prop-types"
import useInputValidate from "hooks/useInputValidate/useInputValidate"
import "./PhoneInput.css"

interface PhoneInputProps {
  // eslint-disable-next-line
  onChange: (data: { phoneNumber: string; countryCode: string }) => void
  value: any
  variant?: string
  disabled?: boolean
  optional?: boolean
  showError?: boolean
  label: ReactNode
}
const Phone = ({ onChange, value, variant, disabled = false, label, showError }: PhoneInputProps, optional = false) => {
  const { error, validate, setError } = useInputValidate()
  const [enhancedPhoneNumber, setEnhancedPhoneNumber] = useState("")

  const formatPhoneNumber = (phoneNumber: string) => {
    setError("")
    let phone
    if (phoneNumber.charAt(0) === "0") phone = phoneNumber.slice(1)
    else phone = phoneNumber
    return phone
  }

  const handleChange = (val: string, country: any) => {
    setEnhancedPhoneNumber(val.slice(country?.dialCode.length))
  }

  useEffect(() => {
    // Perform side effects after the render phase
    if (onChange) {
      onChange({
        ...value,
        phoneNumber: formatPhoneNumber(enhancedPhoneNumber),
      })
    }
  }, [enhancedPhoneNumber])

  return (
    <div className={`flex flex-col ${variant || "mb-6"}`}>
      <div className="relative">
        <label
          htmlFor="phone_number"
          className={`
            ${disabled ? "bg-none" : "bg-white"} block
            text-sm text-headers mb-[4px] cursor-default font-medium ${variant}`}
        >
          {label}
          {optional && <span className="font-normal text-grey-350 ml-1">(Optional)</span>}
        </label>
        <PhoneInput
          placeholder="Phone number"
          containerStyle={{
            width: "100%",
            borderRadius: "8px",
          }}
          inputProps={{
            disabled: disabled,
            id: "phone_number",
          }}
          buttonStyle={{
            borderBottomLeftRadius: "8px",
            borderTopLeftRadius: "8px",
            borderLeft: "0px",
            borderTop: "0px",
            borderBottom: "0px",
            height: "44px",
            width: "44px",
            left: "2px",
            marginTop: "2px",
            backgroundColor: disabled ? "#F2F3F3" : "#FFFFFF",
          }}
          inputStyle={{
            borderRadius: "4px",
            width: "100%",
            color: "#424248",
            border: "1px solid #D7D7E3",
            height: "48px",
            fontFamily: "inter",
            fontSize: "14px",
            boxShadow: "",
            backgroundColor: disabled ? "#F2F3F3" : "#ffffff",
          }}
          onChange={(value, country) => handleChange(value, country)}
          onBlur={() => validate({ name: "phone_number", value: enhancedPhoneNumber })}
          value={`${value?.countryCode}${value?.phoneNumber}`}
          country={"ng"}
          countryCodeEditable={false}
          disableDropdown={true}
          enableSearch
          defaultMask="...-...-...."
          defaultErrorMessage="sfsfsfs"
        />
      </div>
      {showError && <p className={`text-primary_red_500 text-12 mt-1`}>{error}</p>}
    </div>
  )
}

export default Phone
Phone.propTypes = {
  value: PropTypes.object,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  showError: PropTypes.bool,
}
