import { useState } from "react"
import { useFetchTrainingBeneficiaryQuery, useCreateTrainingBeneficiaryMutation } from "services/api/trainingSlice"
import { tryCatch } from "utils/tryCatch"
import { toast } from "react-toastify"

export default function useAddStaffBeneficiary() {
  const [currentTab, setCurrentTab] = useState("manual")
  const [staffId, setStaffId] = useState("")

  const { data: staffData, isFetching: isFetchingStaff } = useFetchTrainingBeneficiaryQuery(
    {
      id: staffId,
      beneficiary_type: "staff",
    },
    { skip: !(staffId && staffId?.length === 11) },
  )

  const [addSingleStaffBeneficiary, { isLoading: isAddSingleBeneficiary }] = useCreateTrainingBeneficiaryMutation({})

  const handleCreateSingleTraining = async ({ id, onClose }: any) => {
    const payload: any = {
      id: id,
      beneficiary_type: "staff",
      entry_mode: currentTab,
      beneficiary_data: {
        beneficiary_reference_id: staffId,
        beneficiary_name: `${staffData?.first_name} ${staffData?.last_name}`,
        // email:  staffData?.email ?? "",
        phone_number: staffData?.phone_number ?? "",
        // user_type: staffData?.user_type ?? "",
      },
    }

    if (payload.beneficiary_type === "staff") {
      ;(payload.beneficiary_data.email = staffData?.email), (payload.beneficiary_data.user_type = staffData?.user_type)
    }

    const callback = (res: any) => {
      if ([200, 201].includes(res?.code)) {
        toast.success(res.message)
        onClose()
      }
    }

    tryCatch(async () => {
      let res = await addSingleStaffBeneficiary(payload).unwrap()
      callback(res)
    })
  }

  const tabs = [
    {
      name: "Enter Manually",
      value: "manual",
      icon: "ph:user",
    },

    {
      name: "Bulk Upload",
      value: "bulk",
      icon: "ph:users-three",
    },
  ]

  return {
    currentTab,
    setCurrentTab,
    tabs,
    staffId,
    setStaffId,
    isFetchingStaff,
    staffData,
    isAddSingleBeneficiary,
    handleCreateSingleTraining,
  }
}
