import { useState } from "react"
import { useParams } from "react-router-dom"
import { fullDateFormatter, shortDate } from "utils/dateFormatter"
import { useTable } from "hooks"
import { tryCatch } from "utils/tryCatch"
import { toast } from "react-toastify"
import { useFetchTrainingAttendanceQuery, useMarkAttendanceMutation } from "services/api/trainingSlice"
import { exportData } from "utils/exportData"

export default function useTrainingBeneficiary() {
  const { id, training_id } = useParams()
  const { handleSearch, changePage, params, selectLimit } = useTable()
  const [date, setDate] = useState({ startDate: null, endDate: null })
  const [selectedStatus, setSelectedStatus] = useState<any>([])
  const [selectedAction, setSelectedAction] = useState("")
  const [selectedBeneficiary, setSelectedBeneficiary] = useState<any>([])

  const { data: attendanceData, isFetching: isFetchingAttendanceData } = useFetchTrainingAttendanceQuery(
    {
      id,
      params: {
        search: params?.search ?? "",
        limit: params?.limit ?? 10,
        training_id: training_id,
        page: params?.page,
        from: shortDate(date?.startDate) ?? shortDate(date?.endDate) ?? "",
        to: shortDate(date?.endDate) ?? shortDate(date?.startDate) ?? "",
        status: selectedStatus?.length > 0 ? selectedStatus?.value : "",
      },
    },
    { skip: !training_id },
  )

  const [markAttendance, { isLoading: isMarkingAttendance }] = useMarkAttendanceMutation({})

  const handleMarkAttendance = async ({ status, id }: { status: string; id: string }) => {
    const callback = (res: any) => {
      if ([200, 201].includes(res?.code)) {
        toast.success(res.message)
        setSelectedAction("")
      }
    }

    const payload = {
      training_id,
      beneficiary_reference_id: attendanceData?.beneficiary_reference_id ?? "",
      status: status,
    }

    tryCatch(async () => {
      const response = await markAttendance({ id, data: payload }).unwrap()
      callback(response)
    })
  }

  const breadCrumbs = [{ title: "Training List" }, { title: attendanceData?.course_title ?? "--" }]

  const trainingHeadersData = [
    attendanceData?.gender ?? "--",
    attendanceData?.dob ? fullDateFormatter(attendanceData?.dob) : "--",
    attendanceData?.age ?? "--",
    attendanceData?.state ?? "--",
    attendanceData?.attendance_required ?? "--",
    attendanceData?.course_title ?? "--",
  ]

  const exportTrainingAttendance = () => {
    tryCatch(() => {
      const data = attendanceData?.training_participant_attendance?.map((participant: any) => {
        const date = participant?.marked_at ? fullDateFormatter(participant.marked_at) : ""
        return {
          Day: `Day ${participant?.training_day}`,
          "Date and Time": date?.replace(/,/g, ""),
          Status: participant?.status,
        }
      })

      exportData(data, `${attendanceData?.name} Attendance Information`)
    })
  }

  return {
    breadCrumbs,
    trainingHeadersData,
    handleSearch,
    changePage,
    params,
    selectLimit,
    date,
    setDate,
    selectedStatus,
    setSelectedStatus,
    isFetchingAttendanceData,
    attendanceData,
    training_id,
    isMarkingAttendance,
    selectedAction,
    setSelectedAction,
    handleMarkAttendance,
    selectedBeneficiary,
    setSelectedBeneficiary,
    exportTrainingAttendance,
  }
}
