import { useTable } from "hooks"
import { useMemo } from "react"
import { useGetAuditSummaryQuery, useGetReportsQuery } from "services/api/reportSlice"
import { Period } from "types/common"
import { calculatePercentage, getReportCustomDateRange, getReportYear } from "utils/report"
import { addCommasToNumbers } from "utils/addCommasToNumbers"
import { formatCurrency } from "utils/formatCurrency"
import useReportSummary from "hooks/useReportSummary/useReportSummary"
import { removeSpecificKeys } from "utils/removeSpecificKeys"

export default function useAuditSummary() {
  const {
    actions,
    deleteReport,
    isDeletingReport,
    selectedReportId,
    setSelectedReportId,
    date,
    setDate,
    periodFilter,
    setPeriodFilter,
    navigate,
  } = useReportSummary()
  const { params, changePage, selectLimit } = useTable()

  const { data: summary, isLoading: isLoadingSummary } = useGetAuditSummaryQuery({
    month: periodFilter.type === Period.MONTHLY ? periodFilter?.selected : "",
    year: getReportYear(periodFilter),
    ...getReportCustomDateRange(date, periodFilter),
  })
  const {
    data: reports,
    isLoading: isLoadingHistory,
    isFetching: isFetchingHistory,
  } = useGetReportsQuery({
    type: "audit",
    ...removeSpecificKeys({ ...params }, ["sortBy"]),
  })
  const overview = [
    {
      title: "Total Payment schedule count",
      count: addCommasToNumbers(summary?.data?.total_payment_schedule_count ?? 0),
      icon: "ph:users-three",
      stat: summary?.data?.total_payment_schedule_count_percentage_increase,
    },
    {
      title: "Total Payment schedule value",
      count: formatCurrency(summary?.data?.total_payment_schedule_amount ?? 0),
      icon: "ph:handshake",
      stat: summary?.data?.total_payment_schedule_amount_percentage_increase,
    },
    {
      title: "Total Paid schedule",
      count: formatCurrency(summary?.data?.total_paid_payment_schedule_amount ?? 0),
      icon: "ph:handshake",
      stat: summary?.data?.total_paid_payment_schedule_amount_percentage_increase,
    },
  ]
  const statusSummary = [
    {
      title: "Total approved by Audit",
      count: formatCurrency(summary?.data?.total_approved_by_audit ?? 0),
      icon: "ph:handshake",
      stat: summary?.data?.total_approved_by_audit_percentage_increase,
    },
    {
      title: "Total declined by Audit",
      count: formatCurrency(summary?.data?.total_declined_by_audit ?? 0),
      icon: "ph:users-three",
      stat: summary?.data?.total_declined_by_audit_percentage_increase,
    },
  ]
  const chartData = useMemo(() => {
    const data = [
      summary?.data?.paid_status_amount,
      summary?.data?.in_progress_status_amount,
      summary?.data?.unpaid_status_amount,
    ]
    const percentage = calculatePercentage(data)

    const filteredData = percentage.reduce<{ labels: string[]; data: number[]; backgroundColor: string[] }>(
      (acc, value, index) => {
        if (Number(data[index]) !== 0) {
          acc.labels.push(["Paid", "In progress", "Unpaid"][index])
          acc.data.push(value)
          acc.backgroundColor.push(["#2B892B", "#C6E4C4", "#024F0B"][index])
        }
        return acc
      },
      { labels: [], data: [], backgroundColor: [] },
    )

    return {
      labels: filteredData.labels,
      datasets: [
        {
          data: filteredData.data,
          backgroundColor: filteredData.backgroundColor,
          borderWidth: 6,
        },
      ],
    }
  }, [summary?.data])

  return {
    navigate,
    summary: summary?.data,
    reports: reports?.data,
    isLoadingHistory,
    isFetchingHistory,
    isLoadingSummary,
    selectedReportId,
    setSelectedReportId,
    isDeletingReport,
    deleteReport,
    setPeriodFilter,
    overview,
    changePage,
    selectLimit,
    periodFilter,
    actions,
    setDate,
    date,
    statusSummary,
    chartData,
  }
}
