import { Icon } from "@iconify/react"
import { Modal, Button } from "components/core"
import useAddTraining from "./useAddTraining"
import { RenderIf } from "components/hoc"
import SingleTrainingUpload from "./SingleTrainingUpload/SingleTraining"
import BulkUpload from "./BulkUpload/BulkUpload"

interface AddTrainingProps {
  setSelectedAction: any
  setUploadMessage: any
}

const AddTraining = ({ setSelectedAction, setUploadMessage }: AddTrainingProps) => {
  const {
    formData,
    setFormData,
    addNewVendor,
    handleSubmit,
    isDisabledBtn,
    isCreatingTraining,
    courseDropdownElements,
    isFetchingCourses,
    handleDeleteVendor,
    tabs,
    currentTab,
    setCurrentTab,
    convertCsvtoJson,
    file,
  } = useAddTraining()

  return (
    <div>
      <Modal title="Add Training" position="modal-right" width="w-[432px]" closeModal={() => setSelectedAction("")}>
        <div className="modal-right-body flex flex-col gap-y-4">
          <div>
            <div className="bg-[#E3E5EB] p-[4px] grid grid-cols-2 rounded-[4px]">
              {tabs.map((tab) => (
                <button
                  key={tab.name}
                  data-testid={tab.value}
                  type="button"
                  className={`
              flex items-center justify-center text-sm font-medium px-[16px] py-[10px] gap-x-2
              ${currentTab === tab.value ? "bg-white rounded-[4px] text-headings" : "text-paragraph"}
              `}
                  onClick={() => {
                    setCurrentTab(tab.value)
                  }}
                >
                  <Icon icon={tab.icon} className="w-4 h-4 text-inherit" />
                  {tab.name}
                </button>
              ))}
            </div>
          </div>
          <RenderIf condition={currentTab === "manual"}>
            <SingleTrainingUpload
              formData={formData}
              setFormData={setFormData}
              isFetchingCourses={isFetchingCourses}
              addNewVendor={addNewVendor}
              handleDeleteVendor={handleDeleteVendor}
              courseDropdownElements={courseDropdownElements}
            />
          </RenderIf>

          <RenderIf condition={currentTab !== "manual"}>
            <BulkUpload convertCsvtoJson={convertCsvtoJson} uploadedFile={file} />
          </RenderIf>

          <div className="modal-right-button-container mb-3">
            <Button
              theme="primary"
              className="min-w-[105px] mr-[8px] p-[8px]"
              type="button"
              data-testid="submit-btn"
              onClick={() => handleSubmit(setSelectedAction, setUploadMessage)}
              loading={isCreatingTraining}
              disabled={isDisabledBtn || isCreatingTraining}
            >
              Save Training
            </Button>
            <Button
              theme="transparent"
              className="w-[105px] px-[30px]"
              type="button"
              onClick={() => setSelectedAction("")}
            >
              Discard
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default AddTraining
