import { useMemo, useState } from "react"
import { toast } from "react-toastify";
import { useCreateRoleMutation, useUpdateRoleMutation } from "services/api/rolesSlice"
import { capitalizeFirstLetter } from "utils/textFormatter";
import { tryCatch } from "utils/tryCatch";
import useAssignPermissions from "./useAssignPermissions";

export default function useRoleManagement(title, closeModal, role) {
    const {
        permissions,
        enhancedPermission,
        toggleSelectAllActions,
        selectPermission,
        isLoadingPermissions
    } = useAssignPermissions(role?.permissions)
    const [formData, setFormData] = useState({
        roleName: capitalizeFirstLetter(role?.role_name) || "",
        description: role?.description || ""
    })
    const [createRole, { isLoading: isCreatingRole }] = useCreateRoleMutation()
    const [updateRole, { isLoading: isUpdatingRole }] = useUpdateRoleMutation()

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const onSubmit = (e) => {
        e.preventDefault()
        tryCatch(async () => {
            const callback = (res) => {
                if ([200, 201].includes(res?.code)) {
                    toast.success(res?.message)
                    closeModal()
                }
            }
            const payload = { ...formData, permissions: enhancedPermission }
            const res = title === "edit" ? await updateRole(payload).unwrap() : await createRole(payload).unwrap()
            callback(res)
        })
    }

    const button = useMemo(() => {
        if (title === "edit") return {
            name: "Save Changes",
            disabled: !formData.description || !enhancedPermission.length
        }
        return {
            name: "Create",
            disabled: !formData.roleName || !formData.description || !enhancedPermission.length
        }
    }, [title, formData, enhancedPermission])

    return {
        title,
        onSubmit,
        formData,
        handleChange,
        permissions,
        toggleSelectAllActions,
        selectPermission,
        button,
        isLoading: isCreatingRole || isUpdatingRole,
        isLoadingPermissions
    }
}