import { Icon } from "@iconify/react"
import { Breadcrumb, Button, Status, Table } from "components/core"
import HeaderLoader from "components/core/Loader/HeaderLoader/HeaderLoader"
import { RenderIf } from "components/hoc"
import { paymentUnitReportHeader } from "constants/reports"
import { fullDateFormatter } from "utils/dateFormatter"
import useAuditReport from "./useAuditReport"
import { formatCurrency } from "utils/formatCurrency"

const AuditReport = () => {
  const { report, isExporting, exportReport, ...state } = useAuditReport()

  return (
    <div className="w-full">
      <Breadcrumb options={state.breadcrumbs} showBack={true} />
      <RenderIf condition={state.isLoadingReport}>
        <HeaderLoader />
      </RenderIf>
      <RenderIf condition={!state.isLoadingReport}>
        <h1 className="text-xl text-headers font-semibold my-6">{report?.reportTitle}</h1>
        <div className="flex items-center py-[24px] border-y mb-[32px] w-full">
          {state.overview.map((details) => (
            <div key={details.title} className="flex items-center gap-4 mr-[40px]">
              <div className="relative w-10 h-10 grid place-items-center bg-grey-100 rounded-full shrink-0">
                <Icon icon={details.icon} className="w-6 h-6 text-secondary-base" />
              </div>
              <div className="grid">
                <span className="text-sm text-body">{details.title}</span>
                <p className="text-base font-medium text-headers">{details.count}</p>
              </div>
            </div>
          ))}
        </div>
        <RenderIf condition={!!report?.interventions?.length}>
          <div className="flex justify-end">
            <Button type="button" size="40" theme="transparent" disabled={isExporting} onClick={exportReport}>
              <Icon icon="uiw:download" className="text-paragraph w-[18px] h-[18px] mr-3" />
              {isExporting ? "Exporting..." : "Export"}
            </Button>
          </div>
        </RenderIf>
      </RenderIf>
      <Table
        headers={paymentUnitReportHeader}
        paginateData={!!report?.interventions?.length}
        data={report?.interventions ?? []}
        onPageChange={state.changePage}
        selectLimit={state.selectLimit}
        totalCount={Number(report?.total_interventions_list || 0)}
        perPage={report?.limit}
        page={Number(report?.page ?? 0)}
        loading={state.isLoadingReport}
      >
        <>
          {report?.interventions?.map((item: any) => (
            <tr data-testid={item?.intervention_id} key={item?.intervention_id} className="cc-custom-table-data-row">
              <td>
                <div>
                  <p>{item?.intervention_name}</p>
                  <p className="text-xs font-semibold">
                    <span className="text-black">{item?.beneficiary_count}</span> Beneficiaries
                  </p>
                </div>
              </td>
              <td>{item?.schedule_id}</td>
              <td>{item?.payment_cycle}</td>
              <td>{fullDateFormatter(item?.date_created)}</td>
              <td>{formatCurrency(item?.amount)}</td>
              <td>
                <Status status={item?.payment_status} />
              </td>
            </tr>
          ))}
        </>
      </Table>
    </div>
  )
}

export default AuditReport
