import Papa from "papaparse"
import { tryCatch } from "./tryCatch"

// eslint-disable-next-line no-unused-vars
export function csvToJson(file: File, callback?: (data: any) => void) {
  return new Promise((resolve, reject) => {
    tryCatch(() => {
      if (file) {
        const fileDetails = file
        const reader = new FileReader()
        reader.onload = () => {
          const data = reader.result
          // Parse CSV using PapaParse
          const result = Papa.parse(data as string, {
            header: true,
          })
          if (callback) callback(result.data)
          resolve(result.data)
        }
        reader.onerror = () => {
          reject(new Error("Failed to read file"))
        }
        reader.readAsText(fileDetails)
      }
    })
  })
}
