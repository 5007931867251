import { Breadcrumb, Button, ManageItem, ModuleStepper, Textarea } from "components/core"
import useInterventionDetails from "./useInterventionDetails"
import { Overview } from "components/pages/Interventions"
import { RenderIf, Restricted } from "components/hoc"
import { ReactComponent as ApproveIcon } from "assets/icons/approve.svg"
import HeaderLoader from "components/core/Loader/HeaderLoader/HeaderLoader"
import { AllPermissions, PermissionModule } from "types/permission"

interface InterventionDetailsProps {
  purpose?: string
  interventionId?: string
}

export const InterventionDetails = ({ purpose, interventionId = "" }: InterventionDetailsProps) => {
  const { INTERVENTION } = PermissionModule
  const { VIEW_SINGLE_INTERVENTION, EDIT_INTERVENTION } = AllPermissions
  const {
    breadCrumbs,
    overviewDetails,
    tabs,
    currentTab,
    componentToBeDisplayed,
    selectedAction,
    setSelectedAction,
    reason,
    setReason,
    intervention,
    updateInterventionStatusProxy,
    isUpdatingStatus,
    isFetchingIntervention,
    editIntervention,
    hasPermission,
  } = useInterventionDetails(interventionId)

  return (
    <Restricted module={INTERVENTION} permission={VIEW_SINGLE_INTERVENTION} showError={true}>
      <div>
        <Breadcrumb options={breadCrumbs} showBack={true} />
        <RenderIf condition={isFetchingIntervention}>
          <HeaderLoader />
        </RenderIf>
        <RenderIf condition={!!intervention && !isFetchingIntervention}>
          <Overview
            title={intervention?.intervention_title ?? ""}
            description={intervention?.description}
            details={overviewDetails}
            purpose={purpose}
            permitted={hasPermission(INTERVENTION, EDIT_INTERVENTION)}
            onClick={editIntervention}
          />
          <div className="mt-[36px] pb-[60px]">
            <ModuleStepper tabs={tabs} currentTab={currentTab} />
            {componentToBeDisplayed}
          </div>
          <RenderIf condition={purpose === "requesting-approval" && hasPermission(INTERVENTION, EDIT_INTERVENTION)}>
            <>
              <RenderIf condition={intervention?.status === "pending_approval" && !!intervention?.mine_history_id}>
                <div className="flex justify-end border-t border-t-grey-200 py-[24px] px-[48px] fixed bottom-0 left-0 bg-white w-full">
                  <Button
                    theme="transparent"
                    size="40"
                    className="min-w-[105px] mr-[8px] capitalize"
                    type="button"
                    onClick={() => setSelectedAction("decline")}
                    //onClick={onClose}
                    //loading={isLoading}
                    //disabled={button.disabled}
                  >
                    Decline
                  </Button>
                  <Button
                    theme="primary"
                    size="40"
                    className="min-w-[105px] mr-[8px] capitalize"
                    onClick={() => setSelectedAction("approve")}
                    //loading={isLoading}
                    //disabled={button.disabled}
                  >
                    Approve
                  </Button>
                </div>
              </RenderIf>
              <RenderIf condition={!intervention?.mine_history_id}>
                <div className="flex justify-center border-t border-t-grey-200 py-[24px] px-[48px] fixed bottom-0 left-0 bg-white w-full">
                  <p className="text-sm text-error-main capitalize-first font-medium">
                    This intervention has no data source and therefore cannot be approved. Kindly edit and add a data
                    source.
                  </p>
                </div>
              </RenderIf>
              <RenderIf condition={selectedAction === "approve"}>
                <ManageItem
                  title="Approve Intervention?"
                  actionName="Yes, proceed"
                  cancelBtnText="Discard"
                  icon={<ApproveIcon />}
                  caption="Approving this intervention will initiate its start date."
                  loading={isUpdatingStatus}
                  onClose={() => setSelectedAction("")}
                  onSubmit={() => updateInterventionStatusProxy("awaiting_start_date")}
                />
              </RenderIf>
              <RenderIf condition={selectedAction === "decline"}>
                <ManageItem
                  title="Reject Intervention?"
                  actionName="Submit"
                  cancelBtnText="Discard"
                  icon={<ApproveIcon />}
                  caption="Kindly input reason for rejection "
                  loading={isUpdatingStatus}
                  disabled={!reason}
                  onClose={() => setSelectedAction("")}
                  onSubmit={() => updateInterventionStatusProxy("rejected")}
                >
                  <Textarea
                    id="reason"
                    name="reason"
                    rows={4}
                    placeholder="Type here..."
                    // readOnly={loading}
                    value={reason}
                    className="mb-[16px]"
                    onChange={(e) => setReason(e.target.value)}
                    maxLength={320}
                  />
                </ManageItem>
              </RenderIf>
            </>
          </RenderIf>
        </RenderIf>
      </div>
    </Restricted>
  )
}
