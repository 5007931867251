import { Button, Dropdown, Modal, Textarea } from "components/core"
import RadioButton from "components/core/RadioButton/RadioButton"
import useMineUpdateData from "components/pages/DataMining/MineData/MineUpdateData/useMineUpdateData"
import { MineDataProps } from "types/data"
import { motion } from "framer-motion"
import { RenderIf } from "components/hoc"
import { dataMining } from "mocks/data-mining"
import { DataResourceCardLoader } from "../../DataResourceCard/loader"
import UploadDataCard from "../UploadDataCard"
import { MiningStatus } from "../../MiningStatus/MiningStatus"
import { useEffect, useState } from "react"

export const MineUpdateData = ({
  onCloseMineData,
  source_name,
  selectedAction,
  selectedData,
  refetchDataSources,
  setOpenCompleteMiningModal,
  openPercentageModal,
  setOpenPercentageModal,
  refetchMineHistory,
  refetchSingleDataSourceDetails,
}: MineDataProps) => {
  const {
    formData,
    setFormData,
    sourceOptions,
    batchesOptions,
    handleChange,
    currentTab,
    setCurrentTab,
    handleSubmitBtn,
    isMiningData,
    householdFiles,
    memberFiles,
    handleFileUpload,
    history,
    isFetchingHistory,
    defaultVersionSelection,
    validateFormData,
    handleFileDelete,
    isUpdatingData,
    uploadTypes,
    tabs,
    defaultSource,
    isFetchingHistoryWithVersions,
    handleSelectVersion,
    availableVersions,
    setSelectedVersion,
    householdProgress,
    memberProgress,
  } = useMineUpdateData(
    selectedData?.reference,
    selectedAction,
    onCloseMineData,
    refetchDataSources,
    refetchSingleDataSourceDetails,
    openPercentageModal,
    source_name,
  )
  const errors = validateFormData()
  const [availableDefaultSource, setAvailableDefaultSource] = useState(defaultSource)

  useEffect(() => {
    setAvailableDefaultSource(defaultSource)
    setFormData({ ...formData, source_id: defaultSource[0]?.value })
  }, [defaultSource])

  return (
    <>
      <Modal
        title={selectedAction === "update-data" ? "Update Batch" : "Mine Data"}
        position="modal-right"
        width={"w-[432px]"}
        closeModal={onCloseMineData}
      >
        <RenderIf condition={selectedAction === "update-data" && isFetchingHistory}>
          <div className="p-6 space-y-2">
            {dataMining.slice(0, -1)?.map((data) => (
              <DataResourceCardLoader key={data.id} />
            ))}
          </div>
        </RenderIf>
        <RenderIf condition={!(selectedAction === "update-data" && isFetchingHistory)}>
          <form onSubmit={(e) => handleSubmitBtn(e, setOpenPercentageModal)} className="relative">
            <div className="modal-right-body">
              <Dropdown
                defaultOption={availableDefaultSource[0]?.label ? availableDefaultSource : []}
                disabled={selectedAction === "update-data" || !!availableDefaultSource[0]?.label}
                label="Source Name"
                options={sourceOptions}
                enableSearchOptions={true}
                onSelect={(data: any) => {
                  setFormData((prevFormData: any) => ({
                    ...prevFormData,
                    source_id: data.value,
                    version: "",
                  }))
                  setSelectedVersion("")
                }}
              />
              <RenderIf condition={selectedAction === "update-data"}>
                <Dropdown
                  label="Batch ID"
                  options={batchesOptions}
                  selectInput={true}
                  enableSearchOptions={true}
                  onSelect={(data: any) => {
                    setFormData({ ...formData, batch_id: data.value })
                  }}
                />
              </RenderIf>
              <Dropdown
                key={formData.version}
                disabled={selectedAction === "update-data"}
                defaultOption={selectedAction === "update-data" ? defaultVersionSelection : []}
                label="Select Version"
                options={availableVersions ?? []}
                selectInput={true}
                onSelect={(data: any) => {
                  handleSelectVersion(data)
                }}
                selected={formData.version}
                loading={isFetchingHistoryWithVersions}
              />

              <Textarea
                disabled={selectedAction === "update-data"}
                label="Description"
                id="description"
                name="description"
                rows={4}
                placeholder="Type here..."
                value={selectedAction === "update-data" ? history?.description ?? "" : formData?.description ?? ""}
                onChange={handleChange}
                maxLength={320}
              />
              <div className="mt-6">
                <p className="text-body font-semibold mb-3">Select type</p>
                <div className="flex space-x-4">
                  {uploadTypes.map((item: string) => (
                    <RadioButton
                      key={item}
                      name={item}
                      label={item}
                      checked={item === formData.type}
                      value={formData.type}
                      onChange={() => setFormData({ ...formData, type: item })}
                      size="36"
                    />
                  ))}
                </div>
              </div>
              <RenderIf condition={formData.type === "File Upload"}>
                <div className="w-full border-b-[1.5px] border-b-grey-200 mt-8">
                  {tabs.map((tab) => (
                    <motion.button
                      type="button"
                      key={tab.title}
                      className={`text-sm font-normal mr-[24px] pt-[8px] pb-[16px] px-[16px] ${
                        currentTab === tab.title ? "selected-tab" : "unselected-tab"
                      }`}
                      onClick={() => setCurrentTab(tab.title)}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 0.3 }}
                    >
                      {tab.title}
                    </motion.button>
                  ))}
                </div>
                <div className="pb-2">
                  <UploadDataCard
                    handleFileUpload={handleFileUpload}
                    householdFiles={householdFiles}
                    memberFiles={memberFiles}
                    currentTab={currentTab}
                    handleFileDelete={handleFileDelete}
                    householdProgress={householdProgress}
                    memberProgress={memberProgress}
                  />
                </div>
              </RenderIf>
            </div>

            <div className="modal-right-button-container">
              <Button
                theme="primary"
                className="min-w-[105px] mr-[8px] p-[10px]"
                type="submit"
                loading={isMiningData || isUpdatingData}
                disabled={isMiningData || errors?.length > 0}
              >
                {selectedAction === "update-data" ? "Update Batch" : "Mine Data"}
              </Button>
              <Button theme="transparent" className="w-[105px] px-[30px]" type="button" onClick={onCloseMineData}>
                Close
              </Button>
            </div>
          </form>
        </RenderIf>
      </Modal>
      <RenderIf condition={!!openPercentageModal}>
        <Modal
          closeModal={() => {
            setOpenPercentageModal && setOpenPercentageModal(false)
            refetchMineHistory && refetchMineHistory()
            onCloseMineData()
          }}
          title={selectedAction === "update-data" ? "Update Data" : "Mine Data"}
          position="modal-right"
          width="w-[432px]"
        >
          <div className="relative">
            <MiningStatus
              percentage={history?.status}
              onClose={() => {
                setOpenPercentageModal && setOpenPercentageModal(false)
                refetchMineHistory && refetchMineHistory()
                onCloseMineData()
                refetchDataSources && refetchDataSources()
              }}
            />
            <div className="modal-right-button-container">
              <Button
                onClick={() => {
                  setOpenPercentageModal && setOpenPercentageModal(false)
                  setOpenCompleteMiningModal && setOpenCompleteMiningModal()
                  onCloseMineData()
                }}
                className="min-w-[105px] mr-[8px] p-[10px] px-[20px]"
                theme="primary"
                type="button"
              >
                Run in the Background
              </Button>
              <Button
                onClick={() => {
                  setOpenPercentageModal && setOpenPercentageModal(false)
                  refetchMineHistory && refetchMineHistory()
                  onCloseMineData()
                }}
                theme="transparent"
                className="w-[105px] p-[10px] px-[30px]"
                type="button"
              >
                Close
              </Button>
            </div>
          </div>
        </Modal>
      </RenderIf>
    </>
  )
}
