import InterventionCard from "../InterventionCard"

interface InterventionCardContainerProps {
  actions: any[]
  title: string
  showStatus?: boolean
  data?: any
  countOfStatusWithData?: number
  cardCanvasClass?: string
}

export const InterventionCardContainer = ({
  actions,
  title,
  showStatus,
  data,
  countOfStatusWithData = 1,
  cardCanvasClass = "",
}: InterventionCardContainerProps) => {
  const gridColumns = () => {
    if (countOfStatusWithData === 3) return "grid-cols-1"
    if (countOfStatusWithData === 2) return "grid-cols-2"
    return "grid-cols-3 2xl:grid-cols-4"
  }

  return (
    <div className="bg-grey-50 pt-[18px] px-4 rounded-xl font-inter">
      <p className="text-xs font-medium pb-4">
        {title} <span className="py-1 px-2.5 bg-headers rounded-3xl text-white font-bold ml-2">{data.length}</span>
      </p>
      <div
        className={`${cardCanvasClass || "h-[calc(100vh-430px)]"} overflow-y-scroll grid ${gridColumns()} gap-4 pb-10`}
      >
        {data.map((intervention: any) => (
          <InterventionCard
            actions={actions}
            intervention={intervention}
            status={intervention.status}
            key={intervention?.intervention_id}
            showStatus={showStatus}
          />
        ))}
      </div>
    </div>
  )
}
