import { useEffect, useState } from "react"

export const useResendOtp = () => {
  const [timeLeft, setTimeLeft] = useState<number>(60)

  const formatTime = (time: any) => {
    const minutes = Math.floor(time / 60)
      .toString()
      .padStart(2, "0")
    const seconds = (time % 60).toString().padStart(2, "0")
    return `${minutes}:${seconds}`
  }

  const resetTimer = () => {
    setTimeLeft(60)
  }

  useEffect(() => {
    let timer = setInterval(() => {
      if (timeLeft !== 0) setTimeLeft((prevState) => prevState - 1)
    }, 1000)

    return () => clearInterval(timer)
  }, [timeLeft])

  const countDown: string = formatTime(timeLeft)

  return { timeLeft, countDown, resetTimer }
}
