import { Fragment } from "react"
import { Icon } from "@iconify/react"
import { useNavigate } from "react-router-dom"

interface Option {
  title: string
  method?: () => void
}

interface BreadcrumbProps {
  options: Option[]
  showBack?: boolean
  goBackRoute?: string
}

export const Breadcrumb = ({ options, showBack, goBackRoute }: BreadcrumbProps) => {
  const navigate = useNavigate()

  return (
    <div className="flex items-center py-[8px] overflow-x-auto mb-[24px]">
      {showBack && (
        <button
          type="button"
          className="
          grid place-items-center w-[30px] h-[30px] rounded-full border border-neutral-10
          hover:bg-neutral-5 transition-colors duration-300 ease-out mr-[8px]
          "
          onClick={() => (goBackRoute ? navigate(goBackRoute) : navigate(-1))}
        >
          <Icon icon="ph:arrow-left" className="text-neutral-base" />
        </button>
      )}
      {options?.map((option, i) => (
        <Fragment key={option.title}>
          <button
            data-testid="previous"
            onClick={() => (option?.method ? option?.method() : {})}
            className={`
              ${options?.length !== i + 1 ? "text-grey-base cursor-pointer" : "text-headers cursor-default"}
              text-base font-medium hide_tap capitalize whitespace-nowrap
            `}
          >
            {option?.title}
          </button>
          {options?.length !== i + 1 ? <Icon icon="lucide:chevrons-right" className="text-grey-base mx-[4px]" /> : ""}
        </Fragment>
      ))}
    </div>
  )
}
