import { createApi } from "@reduxjs/toolkit/query/react"
import { axiosBaseQuery } from "../axiosBaseQuery"
import { FetchedConfigurations } from "types/configuration"

const baseUrl = `${process.env.REACT_APP_BASE_URL}/config-settings`

export const configurationSlice = createApi({
  reducerPath: "configurationSlice",
  baseQuery: axiosBaseQuery({ baseUrl }),
  tagTypes: ["Configurations"],
  refetchOnMountOrArgChange: true,
  endpoints(build) {
    return {
      createConfiguration: build.mutation<any, any>({
        query: (data) => ({ url: "", method: "post", data }),
        invalidatesTags: ["Configurations"],
      }),
      getConfigurations: build.query<FetchedConfigurations, any>({
        query: (params) => ({ url: "", method: "get", params }),
        transformResponse: (response: any) => response.data,
        providesTags: ["Configurations"],
      }),
      updateConfiguration: build.mutation<any, any>({
        query: ({ configId, ...data }) => ({ url: `/${configId}`, method: "put", data }),
        invalidatesTags: ["Configurations"],
      }),
      deleteConfiguration: build.mutation<any, any>({
        query: (configId) => ({ url: `/${configId}`, method: "delete" }),
        invalidatesTags: ["Configurations"],
      }),
      changeConfigurationStatus: build.mutation<any, any>({
        query: ({ configId, status }) => ({ url: `/${configId}/${status}`, method: "patch" }),
        invalidatesTags: ["Configurations"],
      }),
    }
  },
})

export const {
  useCreateConfigurationMutation,
  useGetConfigurationsQuery,
  useDeleteConfigurationMutation,
  useUpdateConfigurationMutation,
  useChangeConfigurationStatusMutation,
} = configurationSlice
