import { SpinnerLoader } from "components/core"

const LoadingSchedule = () => {
  return (
    <div className="w-full h-full flex flex-col gap-6 items-center justify-center" data-testid="loading-schedule">
      <div>
        <SpinnerLoader size={98} />
      </div>
      <div className="flex flex-col gap-2">
        <h2 className="text-headers text-lg text-center">Please Wait</h2>
        <p className="text-body text-sm">Your Payment schedule is being generated</p>
      </div>
    </div>
  )
}

export default LoadingSchedule
