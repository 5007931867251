import React, { ReactNode, useEffect, useMemo, useRef, useState } from "react"
import { ReactComponent as EyeOpen } from "assets/icons/eye-open.svg"
import { ReactComponent as EyeClosed } from "assets/icons/eye-close.svg"
import useInputValidate from "hooks/useInputValidate/useInputValidate"
import "./Input.css"
import { RenderIf } from "components/hoc"

interface InputProps {
  id: string
  name: string
  placeholder?: string
  value: string
  label?: string | ReactNode
  // eslint-disable-next-line no-unused-vars
  onChange?: (e: any) => void
  type: string
  measurement?: string
  optional?: boolean
  readOnly?: boolean
  step?: string
  className?: string
  helperText?: string
  onBlur?: () => void
  maxLength?: number
  defaultValue?: string
  max?: string | number
  pattern?: string
  inputMode?: "none" | "text" | "tel" | "url" | "email" | "numeric" | "decimal" | "search"
  showError?: boolean
  disabled?: boolean
  variant?: string
  allowDecimals?: boolean
}

export const Input = ({
  id,
  name,
  placeholder,
  value,
  label,
  onChange,
  type,
  step = "1",
  readOnly,
  className,
  helperText,
  optional,
  measurement,
  onBlur,
  maxLength,
  defaultValue,
  max,
  pattern,
  inputMode,
  showError = true,
  disabled,
  variant,
  allowDecimals = true,
}: InputProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const { error, validate, setError } = useInputValidate()
  const [passwordView, setPasswordView] = useState(false)

  const inputError = useMemo(() => {
    return !(showError === false || !error)
  }, [error])

  const onBlurAction = () => {
    validate({ name, value })
    if (value && onBlur) {
      onBlur()
    }
  }
  const preventDecimal = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!allowDecimals && (event.key === "." || event.key === ",")) {
      event.preventDefault()
    }
  }

  const preventDecimalInput = (event: React.FormEvent<HTMLInputElement>) => {
    if (!allowDecimals) {
      const input = event.target as HTMLInputElement
      input.value = input.value.replace(/[^0-9]/g, "")
    }
  }

  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      if (document.activeElement === inputRef.current) {
        event.preventDefault()
      }
    }

    const handleArrowKeys = (event: KeyboardEvent) => {
      if ((event.key === "ArrowUp" || event.key === "ArrowDown") && document.activeElement === inputRef.current) {
        event.preventDefault()
      }
    }

    const inputElement = inputRef.current
    inputElement?.addEventListener("wheel", handleWheel)
    inputElement?.addEventListener("keydown", handleArrowKeys)

    return () => {
      inputElement?.removeEventListener("wheel", handleWheel)
      inputElement?.removeEventListener("keydown", handleArrowKeys)
    }
  }, [])

  return (
    <div className={`${className}`}>
      <div className={`relative input-container`}>
        <RenderIf condition={!!label}>
          <label
            htmlFor={id}
            className={`
                    ${disabled ? "bg-none" : "bg-brand_white"} block
                    text-sm text-headers mb-[4px] cursor-default font-medium`}
          >
            {label}
            {optional && <span className="font-normal text-grey-350 ml-1">(Optional)</span>}
          </label>
        </RenderIf>
        <input
          id={id}
          ref={inputRef}
          name={name}
          type={(type === "password" && (passwordView ? "text" : "password")) || type}
          placeholder={placeholder ?? ""}
          value={value}
          step={type === "number" && !allowDecimals ? "1" : step}
          disabled={disabled}
          defaultValue={defaultValue}
          maxLength={maxLength}
          inputMode={inputMode}
          max={max}
          pattern={pattern}
          data-testid={id}
          aria-labelledby={id}
          onChange={onChange}
          readOnly={readOnly}
          autoComplete="off"
          onBlur={onBlurAction}
          onKeyDown={(e) => {
            setError("")
            if (type === "number") preventDecimal(e)
          }}
          onInput={(e) => {
            if (type === "number") preventDecimalInput(e)
          }}
          className={`${variant} 
                        p-[12px] h-[48px] text-body text-base w-full outline-0 border hide_tap
                        rounded-[4px] border-grey-200 focus:border-primary-200 placeholder:text-sm
                        ${disabled ? "bg-grey-50" : "bg-white"} 
                `}
        />
        <RenderIf condition={type === "password"}>
          <button
            onClick={() => setPasswordView(!passwordView)}
            type="button"
            data-testid={!passwordView ? "show" : "hide"}
            className="flex items-center absolute top-3 right-[13.48px] cursor-pointer hide_tap h-full"
          >
            {!passwordView ? <EyeClosed /> : <EyeOpen />}
          </button>
        </RenderIf>
        <RenderIf condition={!!measurement}>
          <div className="flex items-center absolute top-0 right-[12px] h-full">
            <div className="text-14 text-[#80898E]">{measurement}</div>
          </div>
        </RenderIf>
      </div>
      <RenderIf condition={showError}>
        <p className={`${inputError ? "text-error-main" : "text-base"} text-xs`}>
          {(inputError && error) || helperText}
        </p>
      </RenderIf>
    </div>
  )
}
