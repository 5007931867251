import React, { useEffect, useMemo, useState } from "react"
import { type AppRoutes, appRoutes } from "constants/routes"
import { NavLink, useLocation } from "react-router-dom"
import { Icon } from "@iconify/react"
import "./sidebar.css"
import { NavigationLink } from "../NavLink/NavLink"
import { RenderIf } from "components/hoc"
import { usePermission } from "hooks/usePermission/usePermission"

export const Sidebar: React.FC = () => {
  const { hasModulePermission } = usePermission()
  const location = useLocation()
  const [activeRoute, setActiveRoute] = useState<AppRoutes>(
    appRoutes.find((route) => route.href === location.pathname)!,
  )

  const isActive = (route: any) => {
    let activeState
    const pathArray = location.pathname.split("/")
    if (pathArray.filter((item) => item === "").length === 1) {
      let filteredPathArray = pathArray.filter((item) => item !== "")
      activeState =
        filteredPathArray[0] === route.href.substring(1) ||
        (route.href as string).substring(1).startsWith(filteredPathArray[0])
    }
    return route.children.find((item: any) => item.href === location.pathname) || activeState
  }

  const extractHrefs = useMemo(() => {
    let urls: string[] = []

    const extract = (appRoutes: any) => {
      appRoutes.forEach((route: any) => {
        urls.push(route.href)
        if (route.children && route.children.length > 0) {
          extract(route.children)
        }
      })
    }

    extract(appRoutes)

    return urls
  }, [])

  const activeRouteHasChildren = activeRoute?.children?.length > 0

  useEffect(() => {
    for (const item of appRoutes) {
      let foundRoute = item.href === location.pathname
      if (!foundRoute && item.children.length > 0) {
        let newFoundRoute = item.children.find((itm) => itm.href === location.pathname)
        !!newFoundRoute && setActiveRoute(item)
      }
      if (foundRoute) setActiveRoute(item)
    }
  }, [activeRoute, location.pathname])

  return (
    <nav className="flex h-screen">
      <div className="bg-primary-1000 flex flex-col gap-4 h-full py-4 px-3 w-fit z-[3] overflow-y-auto pb-[90px]">
        {appRoutes.map((route) => (
          <NavLink
            to={route.href}
            key={route.href}
            className="grid justify-items-center"
            onClick={() => setActiveRoute(route)}
          >
            <div
              className={`
            ${location.pathname === route.href || isActive(route) ? "bg-primary-actions" : "hover:bg-primary-900"}
            relative group rounded grid content-center justify-items-center transition-all duration-300 ease-out p-2
            `}
            >
              <Icon
                icon={location.pathname === route.href || isActive(route) ? route.activeIcon : route.inactiveIcon}
                className={`
                ${
                  location.pathname === route.href || isActive(route)
                    ? "text-white"
                    : "text-grey-300 group-hover:text-white"
                } text-2xl
                `}
              />
              {/* <div
                className="
              invisible group-hover:visible group-hover:z-50 absolute w-fit h-fit top-1 left-11 whitespace-nowrap
              cc-sidebar-item--helper-display font-medium text-sm text-white border border-neutral-100/10 py-1 px-3 rounded-2xl
              "
              >
                {route.name}
              </div> */}
            </div>
            <span
              className={`
              ${
                location.pathname === route.href || isActive(route)
                  ? "text-white font-bold"
                  : "text-grey-300 font-semibold"
              }
              whitespace-nowrap text-[0.688rem] leading-[1.1rem] transition-all duration-300 ease-out
              `}
            >
              {route.name}
            </span>
          </NavLink>
        ))}
      </div>
      <div
        className={`
      flex flex-col gap-6 py-6 duration-500 bg-primary-30 border-r border-r-neutral-10 whitespace-nowrap
      ${
        activeRouteHasChildren && extractHrefs.includes(location.pathname)
          ? "w-[320px]"
          : "w-0 overflow-hidden opacity-0"
      }
      `}
      >
        {activeRouteHasChildren && <h2 className="pl-6 font-bold text-lg text-neutral-80">{activeRoute?.name} </h2>}
        <div className="grid gap-2 overflow-hidden">
          {activeRoute?.children?.map((subRoute) => (
            <RenderIf condition={subRoute.module ? hasModulePermission(subRoute.module) : true} key={subRoute?.href}>
              <NavigationLink to={subRoute?.href} name={subRoute?.name} icon={subRoute?.icon} />
            </RenderIf>
          ))}
        </div>
      </div>
    </nav>
  )
}
