import { ChangeEvent, useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { useGenerateReportMutation } from "services/api/reportSlice"
import { BENEFICIARY_STATUS } from "types/beneficiary"
import { ISODateFormatter } from "utils/dateFormatter"
import { removeKeysWithEmptyValues } from "utils/objectFormatter"
import { tryCatch } from "utils/tryCatch"

interface Status {
  label?: string
  value?: string | number
}

const initialDate = {
  createdDate: { startDate: null, endDate: null },
}

const initialFormData = {
  reportTitle: "",
  status: {} as Status,
  sourceRegister: [],
  states: [],
  lga: [],
  ward: [],
  communities: [],
  paymentStatus: [],
}

export default function useBeneficiaryGenerateReport() {
  const navigate = useNavigate()
  const [showPreview, setShowPreview] = useState(false)
  const [date, setDate] = useState<any>({ ...initialDate })
  const [formData, setFormData] = useState({ ...initialFormData })

  const breadcrumbs = [{ title: "Beneficiary" }, { title: "Report Parameters" }]

  const [generateReportMutation, { isLoading: isGeneratingReport }] = useGenerateReportMutation()

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const proceed = (e: any) => {
    e.preventDefault()
    setShowPreview(true)
  }

  const close = () => {
    setShowPreview(false)
  }

  const checkDate = (date: Date) => {
    return date ? ISODateFormatter(date) : ""
  }

  const generateReport = () => {
    tryCatch(async () => {
      const { reportTitle, status, ward, states, lga, paymentStatus, sourceRegister, communities } = formData
      let payload: any = {
        reportType: "beneficiary",
        reportTitle,
        beneficiaryStatus: status.label ? [status.label] : "",
        states: states?.length ? states?.map((item: any) => item.value.toString()) : "",
        lga: lga?.length ? lga?.map((item: any) => item.value.toString()) : "",
        ward: ward?.length ? ward?.map((item: any) => item.label) : "",
        sourceRegister: sourceRegister?.length ? sourceRegister.map((item: any) => item.label) : "",
        fromCreatedDate: checkDate(date.createdDate.startDate),
        toCreatedDate: checkDate(date.createdDate.endDate),
      }

      if (status.label === BENEFICIARY_STATUS.VERIFIED) {
        payload.paymentType = paymentStatus?.length ? paymentStatus.map((item: any) => item.label) : ""
        payload.communities = communities?.length ? communities.map((item: any) => item.label) : ""
      }

      const res = await generateReportMutation(removeKeysWithEmptyValues(payload)).unwrap()

      if ([200, 201].includes(res?.code)) {
        toast.success(res?.message)
        navigate(`/reports/beneficiary/${res?.data?.report_id}`)
      }
    })
  }

  const refresh = () => {
    setFormData(initialFormData)
    setDate(initialDate)
  }

  return {
    breadcrumbs,
    formData,
    setFormData,
    handleChange,
    date,
    setDate,
    proceed,
    showPreview,
    close,
    refresh,
    generateReport,
    isGeneratingReport,
  }
}
