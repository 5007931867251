import { useNavigate, useSearchParams } from "react-router-dom"
import { useEffect, useState, useMemo, ChangeEvent } from "react"
import { useGetAllStaffQuery } from "services/api/staffSlice"
import { removeSpecificKeys } from "utils/removeSpecificKeys"
import { checkEmptyProperties } from "utils/checkEmptyProperties"
import { useLazyGetStatesInZoneQuery, useGetZonesQuery, useLazyGetLgaInStateQuery } from "services/api/commonSlice"
import { tryCatch } from "utils/tryCatch"
import { useLazyGetMineHistoryQuery } from "services/api/dataSlice"
import { defaultFilter } from "mocks/staffs"
import useDataSources from "hooks/useDataSources/useDataSources"

interface Props {
  formData: any
  setFormData: any
  proceed: () => void
}
interface Option {
  label: string
  value: string
  id: string
}
interface LocationState {
  lga: Option[]
  states: Option[]
}

export default function useBasicInformation({ formData, proceed, setFormData }: Props) {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const id = searchParams.get("interventionId")
  const [location, setLocation] = useState<LocationState>({ lga: [], states: [] })
  const [versions, setVersions] = useState<Option[]>([])

  const { data: staffs, isLoading: isFetchingStaffs } = useGetAllStaffQuery({ noLimit: true })
  const { data: zones, isLoading: isFetchingZones } = useGetZonesQuery({})
  const [getStatesInZone, { isLoading: isFetchingStates }] = useLazyGetStatesInZoneQuery()
  const [getLgaInState, { isLoading: isFetchingLga }] = useLazyGetLgaInStateQuery()
  const { dataSources, isFetchingSources } = useDataSources()
  const [getMineHistory, { isFetching: isFetchingVersions }] = useLazyGetMineHistoryQuery()

  const reviewers = useMemo(() => {
    const filterActiveStaffs = staffs ? staffs?.staff.filter((item) => item.status === "active") : []
    return (
      filterActiveStaffs?.map((item) => {
        return {
          label: `${item?.first_name} ${item?.last_name}`,
          value: `${item?.first_name} ${item?.last_name}`,
          id: item.staff_id,
        }
      }) ?? []
    )
  }, [staffs])

  const geopoliticalZones = useMemo(
    () =>
      zones?.map((item: any) => {
        return {
          label: `${item?.name}`,
          value: `${item?.name}`,
          id: item.id,
        }
      }) ?? [],
    [zones],
  )

  const selectedDataSource = useMemo(
    () => dataSources.find((item) => item.value === formData.data_source)?.id,
    [dataSources, formData.data_source, id],
  )

  const getAllVersions = () => {
    tryCatch(async () => {
      const res = await getMineHistory({
        source_id: selectedDataSource ?? formData.source_id,
        status: "completed",
        page: 1,
        limit: Number.MAX_SAFE_INTEGER,
      }).unwrap()
      const fetchedVersions = res?.mineHistory?.map((item) => ({
        label: `${item.label}`,
        value: `${item.label}`,
        id: item.reference,
        version: item?.version,
      }))
      setVersions(fetchedVersions ?? [])
    })
  }

  useEffect(() => {
    if (selectedDataSource || formData.source_id) {
      getAllVersions()
    }
  }, [formData.source_id, selectedDataSource])

  useEffect(() => {
    if (formData.states?.length > 0) {
      tryCatch(async () => {
        const res = await getLgaInState({
          ids: formData.states.map((item: any) => item.id).join(","),
        }).unwrap()
        const options = res?.map((item: any) => ({
          label: item?.name,
          value: item?.name,
          id: item.id,
        }))
        setLocation((prevLocation) => ({
          ...prevLocation,
          lga: [...defaultFilter, ...options],
        }))
      })
      return
    }
    setLocation((prevLocation) => ({
      ...prevLocation,
      lga: [],
    }))
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      lga: [],
    }))
  }, [formData.states.length])

  useEffect(() => {
    if (formData.zones?.length > 0) {
      tryCatch(async () => {
        const res = await getStatesInZone({
          ids: formData.zones.map((item: any) => item.id).join(","),
        }).unwrap()
        const options = res?.length
          ? res?.map((item: any) => ({
              label: item?.name,
              value: item?.name,
              id: item.id,
            }))
          : []
        setLocation((prevLocation) => ({
          ...prevLocation,
          states: [...defaultFilter, ...options],
        }))
      })
      return
    }
    setLocation((prevLocation) => ({
      ...prevLocation,
      states: [],
    }))
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      states: [],
    }))
  }, [formData.zones.length])

  const nextPage = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault()
    proceed()
  }

  const selectedVersionName = useMemo(() => {
    if (!versions.length) return ""
    const versionItem = versions.find((item: any) => item.version === formData.version)
    return versionItem ? versionItem.value : ""
  }, [versions])

  const disableBtn = useMemo(() => {
    const dataWithStringValues = removeSpecificKeys({ ...formData }, [
      "zones",
      "departments",
      "data_source",
      "data_source_id",
      "version",
      "states",
      "lga",
      "selection_criteria",
      "quota_structure",
      "payment_mechanism",
      "payment_recurrence",
      "payment_cycle",
      "eligibility_criteria",
      "amount",
      "payment_method",
      "mine_history_id",
      "currency",
    ])

    return (
      checkEmptyProperties(dataWithStringValues) ||
      !formData.zones.length ||
      !formData.states.length ||
      !formData.lga.length
    )
  }, [formData])

  return {
    nextPage,
    geopoliticalZones,
    isFetchingZones,
    isFetchingStates,
    isFetchingLga,
    isFetchingStaffs,
    versions,
    isFetchingVersions,
    reviewers,
    isFetchingSources,
    dataSources,
    location,
    disableBtn,
    navigate,
    selectedVersionName,
  }
}
