import React from "react"
import { Icon } from "@iconify/react"
import { Button, Modal, Dropdown, Input, Textarea } from "components/core"
import useEditTraining from "./useEditTraining"
import { toast } from "react-toastify"

import RadioButton from "components/core/RadioButton/RadioButton"
import { RenderIf } from "components/hoc"
import { TrainingType } from "mocks/training"

interface EditTrainingProps {
  training: any
  onClose: () => void
}

const EditTraining = ({ training, onClose }: EditTrainingProps) => {
  const {
    formData,
    setFormData,
    isFormChanged,
    addNewVendor,
    courseDropdownElements,
    isFetchingCourses,
    isFormValid,
    handleVendorChange,
    handleDeleteVendor,
    isEditingTraining,
    handleEditTraining,
    defaultOptions,
    isFetchingSingleTraining,
    handleCourseSelection,
  } = useEditTraining({ training })

  const currentDate = new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split("T")[0]
  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newStartDate = e.target.value
    if (new Date(newStartDate) < new Date(currentDate)) {
      toast.error("Start date cannot be before today")
    } else if (formData.end_date && new Date(newStartDate) > new Date(formData.end_date)) {
      toast.error("Start date cannot be after end date")
    } else {
      setFormData({ ...formData, start_date: newStartDate })
    }
  }

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEndDate = e.target.value

    if (new Date(newEndDate) < new Date(currentDate)) {
      toast.error("End date cannot be before today")
    } else if (formData.start_date && new Date(newEndDate) < new Date(formData.start_date)) {
      toast.error("End date cannot be before start date")
    } else {
      setFormData({ ...formData, end_date: newEndDate })
    }
  }

  return (
    <main>
      <Modal position="modal-right" width="w-[432px]" closeModal={onClose} title="Edit Training">
        <div>
          <div className="modal-right-body flex flex-col gap-y-6">
            <Dropdown
              label="Training Type"
              options={TrainingType}
              selected={formData?.training_type}
              onSelect={(data: any) => {
                setFormData({ ...formData, training_type: data?.value })
              }}
              disabled={true}
            />
            <div>
              <p className="text-headers font-medium text-sm">Training Unit</p>
              <RenderIf condition={formData?.training_type === "staff"}>
                <div className="flex gap-3 mt-3">
                  <RadioButton
                    label="NTOT"
                    name="ntot"
                    checked={formData?.training_unit === "ntot"}
                    onChange={() => setFormData({ ...formData, training_unit: "ntot" })}
                  />
                  <RadioButton
                    label="TOT"
                    name="TOT"
                    checked={formData?.training_unit === "tot"}
                    onChange={() => setFormData({ ...formData, training_unit: "tot" })}
                  />

                  <RadioButton
                    label="CTF"
                    name="CTF"
                    checked={formData?.training_unit === "ctf"}
                    onChange={() => setFormData({ ...formData, training_unit: "ctf" })}
                  />
                </div>
              </RenderIf>

              <RenderIf condition={formData?.training_type === "citizen"}>
                <div className="flex gap-3 mt-3">
                  <RadioButton
                    label="Beneficiary"
                    name="beneficiary"
                    checked={formData?.training_unit === "beneficiary"}
                    onChange={() => setFormData({ ...formData, training_unit: "beneficiary" })}
                  />

                  <RadioButton
                    label="Livelihood"
                    name="livelihood"
                    checked={formData?.training_unit === "livelihood"}
                    onChange={() => setFormData({ ...formData, training_unit: "livelihood" })}
                  />
                </div>
              </RenderIf>
            </div>
            <div>
              <h1 className="text-sm font-medium text-headers mb-3">Course Type</h1>
              <div className="flex gap-4">
                <RadioButton
                  label="Hard Skills"
                  name="hard-skills"
                  checked={formData?.skill_type === "hard skills"}
                  disabled
                  onChange={() => {
                    setFormData({ ...formData, skill_type: "hard skills" })
                  }}
                />
                <RadioButton
                  label="Soft Skills"
                  name="soft-skills"
                  disabled
                  checked={formData?.skill_type === "soft skills"}
                  onChange={() => {
                    setFormData({ ...formData, skill_type: "soft skills" })
                  }}
                />
              </div>
            </div>
            <Input
              name="name"
              value={formData?.name}
              label="Training Name"
              id="name"
              type="text"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFormData({ ...formData, name: e.target.value })
              }}
            />
            <Dropdown
              label="Select Course"
              options={courseDropdownElements ?? []}
              multiple={true}
              loading={isFetchingCourses || isFetchingSingleTraining}
              // selected={formData?.course}
              onSelect={handleCourseSelection}
              defaultOption={defaultOptions}
              count={true}
              // showButton={true}
            />
            <Input
              name="Attendance Required"
              value={formData?.attendance_required}
              label="Attendance Required"
              id="attendance_required"
              type="number"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFormData({ ...formData, attendance_required: e.target.value })
              }}
            />
            <Textarea
              label="Training Needs"
              rows={6}
              id="training_needs"
              name="training_needs"
              maxLength={320}
              value={formData?.training_needs}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFormData({ ...formData, training_needs: e.target.value })
              }
            />
            <div className="grid gap-2 grid-cols-2">
              <div>
                <label className="text-sm text-headers mb-[4px] cursor-default font-medium">Start Date</label>
                <input
                  type="date"
                  className="p-[12px] h-[40px] text-body text-sm w-full outline-0 border hide_tap
                        rounded-[4px] border-grey-200 focus:border-primary-200 placeholder:text-sm
                         bg-white"
                  value={formData.start_date || ""}
                  onChange={handleStartDateChange}
                  placeholder=""
                  min={currentDate}
                  data-testid="start-date"
                />
              </div>

              <div>
                <label className="text-sm text-headers mb-[4px] cursor-default font-medium">End Date</label>
                <input
                  type="date"
                  className="p-[12px] h-[40px] text-body text-sm w-full outline-0 border hide_tap
                        rounded-[4px] border-grey-200 focus:border-primary-200 placeholder:text-sm
                         bg-white"
                  value={formData.end_date || ""}
                  onChange={handleEndDateChange}
                  placeholder=""
                  min={
                    formData.start_date
                      ? new Date(new Date(formData.start_date).setDate(new Date(formData.start_date).getDate() + 1))
                          .toISOString()
                          .split("T")[0]
                      : currentDate
                  }
                  data-testid="end-date"
                />
              </div>
            </div>
            <div className="flex flex-col gap-2">
              {formData?.training_vendor
                ? formData?.training_vendor?.map((vendor: any, index: number) => (
                    <div className="flex w-full space-x-2" key={index}>
                      <Input
                        name="Enter Vendor"
                        id={`vendor ${index}`}
                        value={vendor}
                        type="text"
                        className="w-full"
                        label={index === 0 && "Enter Vendor"}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleVendorChange(index, e.target.value)}
                      />
                      <button
                        className="cursor-pointer"
                        onClick={() => handleDeleteVendor(index)}
                        data-testid={`del-module-${index}`}
                      >
                        <Icon icon="ph:x" height={12} className="text-[#ADACB0] w-[12px]" />
                      </button>
                    </div>
                  ))
                : null}
              <button
                className="flex w-full justify-end mt-1 items-center text-xs text-primary-actions gap-1"
                onClick={addNewVendor}
                data-testid="add-vendor-btn"
              >
                <Icon icon="ph:plus" />
                Add New Vendor
              </button>
            </div>
          </div>
          <div className="modal-right-button-container mb-3">
            <Button
              theme="primary"
              className="min-w-[105px] mr-[8px] p-[8px]"
              type="button"
              data-testid="submit-btn"
              onClick={() => handleEditTraining(onClose)}
              loading={isEditingTraining}
              // disabled={!isFormChanged || isFormValid || isEditingTraining}
              disabled={(!isFormChanged && isFormValid) || isEditingTraining}
            >
              Save Changes
            </Button>
            <Button theme="transparent" className="w-[105px] px-[30px]" type="button" onClick={onClose}>
              Discard
            </Button>
          </div>
        </div>
      </Modal>
    </main>
  )
}

export default EditTraining
