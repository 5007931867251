import React from "react"

interface StatusProps {
  status: "PAID" | "UNPAID" | "IN-PROGRESS" | string
}

const PaymentStatus: React.FC<StatusProps> = ({ status }) => {
  let textColor: string, bgColor: string

  switch (status.toUpperCase()) {
    case "PAID":
    case "SUCCESS":
      textColor = "text-success-main"
      bgColor = "bg-success-lightest"
      break
    case "IN-PROGRESS":
    case "PENDING":
      textColor = "text-warning-dark"
      bgColor = "bg-warning-lightest"
      break
    case "UNPAID":
    case "FAILED":
      textColor = "text-error-main"
      bgColor = "bg-error-lightest"
      break
    default:
      textColor = "text-black"
      bgColor = "bg-gray-200"
  }

  return (
    <div className={`capitalize rounded-2xl w-fit text-xs font-medium px-2 py-1 ${textColor} ${bgColor}`}>{status}</div>
  )
}

export default PaymentStatus
