import { Button, Input, VerifyOTP } from "components/core"
import { AuthForm } from "components/pages/Auth"
import useLogin from "./useLogin"
import { Link } from "react-router-dom"
import { RenderIf } from "components/hoc"

export default function Login() {
  const { formData, handleChange, onSubmit, showVerify, isLoading, verifyCallback, disabledButton } = useLogin()

  return (
    <>
      <AuthForm title="Welcome back" caption="Sign in to your account" onSubmit={onSubmit}>
        <div>
          <Input
            label="Email Address"
            type="text"
            id="email"
            name="email"
            readOnly={isLoading}
            value={formData?.email}
            onChange={handleChange}
            className="mb-[24px]"
          />
          <Input
            label="Password"
            type="password"
            id="password"
            name="password"
            readOnly={isLoading}
            value={formData?.password}
            onChange={handleChange}
            className="mb-[32px]"
          />
          <Button theme="primary" className="w-full mb-[32px]" size="44" loading={isLoading} disabled={disabledButton}>
            Sign In
          </Button>
          <p className="text-sm text-center">
            <span className="text-body font-normal">Forgot password?</span>{" "}
            <Link to="/reset-password" className="text-primary-actions font-medium">
              Reset your password here
            </Link>
          </p>
        </div>
      </AuthForm>
      <RenderIf condition={showVerify}>
        <VerifyOTP email={formData.email} onClose={(e) => verifyCallback(e)} />
      </RenderIf>
    </>
  )
}
