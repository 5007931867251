import { useMemo, useState, ChangeEvent } from "react"
import { useDeleteMineHistoryMutation } from "services/api/dataSlice"
import { shortenString } from "utils/shortenText"
import { toast } from "react-toastify"
import { tryCatch } from "utils/tryCatch"
import { useGetDataSourcesQuery } from "services/api/dataSlice"
import { useTable } from "hooks"
import { DateType } from "types/data"
import { shortDate } from "utils/dateFormatter"
import { Icon } from "@iconify/react"
import { useLazyGetBeneficiariesUrlQuery } from "services/api/beneficiarySlice"
import JSZip from "jszip"

export default function useDataMining(refetchMineHistory?: () => void, refetchSingleDataSourceDetails?: () => void) {
  const { handleSearch, params } = useTable()
  const [dataSourceDetails, setDataSourceDetails] = useState({})
  const [selectedStatus, setSelectedStatus] = useState<any>([])
  const [goToUpdateData, setGoToUpdateData] = useState(false)
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
  const [selectedAction, setSelectedAction] = useState("")
  const [date, setDate] = useState<DateType>({ startDate: null, endDate: null })
  const {
    data: sources,
    isFetching: isFetchingSources,
    refetch: refetchDataSources,
  } = useGetDataSourcesQuery({
    ...params,
    from: shortDate(date.startDate) ?? shortDate(date.endDate) ?? "",
    to: shortDate(date.endDate) ?? shortDate(date.startDate) ?? "",
  })

  const [selectedData, setSelectedData] = useState<any>({})

  const [deleteMineHistory, { isLoading: isDeleting }] = useDeleteMineHistoryMutation()
  const [getBeneficiariesUrl] = useLazyGetBeneficiariesUrlQuery()

  // eslint-disable-next-line no-undef
  const [file, setFile] = useState<any>([])

  const [formData, setFormData] = useState({
    source: "",
    source_id: "",
    description: "",
    data_type: "",
    version: "",
    type: "File Upload",
    data_file: "",
  })

  const handleFileUpload = (e: any) => {
    const uploadedFile = e.target.files[0]
    setFile({
      name: uploadedFile?.name,
      size: (uploadedFile?.size / 1000).toFixed(2),
    })
    setFormData({
      ...formData,
      data_file: uploadedFile || "",
    })
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const onSubmit = async (e: any) => {
    e.preventDefault()
  }

  const handleDelete = () => {
    tryCatch(async () => {
      const callback = (res: any) => {
        if ([200, 201].includes(res?.code)) {
          toast.success(res?.message)
          setSelectedAction("")
          refetchMineHistory && refetchMineHistory()
          refetchSingleDataSourceDetails && refetchSingleDataSourceDetails()
        }
      }
      const res = await deleteMineHistory(selectedData.reference).unwrap()
      callback(res)
    })
  }

  const filterOptions = [
    { label: "All", value: "" },
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
  ]

  const typeOptions = [
    { label: "Households", value: "households" },
    { label: "Members", value: "members" },
  ]

  const versionOptions = [
    { label: "Version 1", value: "version 1" },
    { label: "Version 2", value: "version 2" },
    { label: "Version 3", value: "version 3" },
    { label: "Version 4", value: "version 4" },
    { label: "Version 5", value: "version 5" },
  ]
  const sourceList = sources?.data?.configurationSettings
  const sourceOptions = useMemo(() => {
    if (sourceList && Array.isArray(sourceList) && sourceList.length > 0) {
      return sourceList.map((item) => ({
        label: shortenString(item.configuration_name, 35),
        image: item.logo,
        value: item.configuration_id,
        name: item.configuration_name,
      }))
    }
    return []
  }, [sourceList])

  const getDownloadUrl = async (fileType: string): Promise<string | null> => {
    try {
      const res: any = await getBeneficiariesUrl({
        key: fileType,
      }).unwrap()

      if (res.status === "success" && res.data) {
        return res.data
      }
      throw new Error(res.message || "Failed to retrieve URL")
    } catch (error: any) {
      throw new Error(error || "Failed to retrieve URL")
    }
  }

  const createZipFile = async (fileUrls: string[]): Promise<string> => {
    try {
      const fileBlobs = await Promise.all(fileUrls.map((url) => fetch(url).then((res) => res.blob())))
      const zip = new JSZip()
      const fileNames = ["Households.csv", "Members.csv"]

      fileBlobs.forEach((blob, index) => {
        zip.file(fileNames[index], blob)
      })
      const zipBlob = await zip.generateAsync({ type: "blob" })
      const zipFileUrl = URL.createObjectURL(zipBlob)

      return zipFileUrl
    } catch (error: any) {
      throw new Error("Failed to create zip file: " + error)
    }
  }

  const handleDownloadSignedUrl = async (item: any) => {
    try {
      const householdsUrl = await getDownloadUrl(item.aws_households_file_key)
      const membersUrl = await getDownloadUrl(item.aws_members_file_key)

      if (householdsUrl && membersUrl) {
        const zipFileUrl = await createZipFile([householdsUrl, membersUrl])

        window.location.href = zipFileUrl

        toast.success("Download initiated")
      } else {
        toast.error("Failed to retrieve download URLs")
      }
    } catch (error: any) {
      toast.error("Failed to export data: " + error)
    }
  }

  const actions = [
    {
      name: "Add Batch",
      icon: <Icon icon="ph:plus" className="w-[18px] h-[18px] mr-[10px]" />,
      method: (item: any) => {
        setSelectedData(item)
        setSelectedAction("add-batch")
      },
    },
    {
      name: "Delete Data",
      icon: <Icon icon="ph:trash" className="w-[18px] h-[19px] mr-[10px]" />,
      method: (item: any) => {
        setSelectedData(item)
        setSelectedAction("Delete")
      },
    },
  ]

  const addBatchAction = [
    {
      name: "Update Batch",
      icon: <Icon icon="solar:cloud-upload-outline" className="w-[22px] h-[16px] mr-[6px]" />,
      method: (item: any) => {
        setSelectedData(item)
        setSelectedAction("update-data")
      },
    },
  ]

  const incompleteAction = [
    {
      name: "Update Batch",
      icon: <Icon icon="solar:cloud-upload-outline" className="w-[22px] h-[16px] mr-[6px]" />,
      method: (item: any) => {
        setSelectedData(item)
        setSelectedAction("update-data")
      },
    },
    {
      name: "Download Files",
      icon: <Icon icon="ph:download" className="w-[22px] h-[16px] mr-[6px]" />,
      method: (item: any) => {
        setSelectedData(item)
        handleDownloadSignedUrl(item)
      },
    },
  ]

  return {
    onSubmit,
    formData,
    setFormData,
    handleChange,
    isLoading: false,
    filterOptions,
    selectedStatus,
    setSelectedStatus,
    sourceOptions,
    handleFileUpload,
    file,
    setGoToUpdateData,
    goToUpdateData,
    openConfirmationModal,
    setOpenConfirmationModal,
    selectedAction,
    setSelectedAction,
    handleSearch,
    history,
    versionOptions,
    typeOptions,
    sources,
    dataSourceDetails,
    setDataSourceDetails,
    isFetchingSources,
    date,
    setDate,
    actions,
    selectedData,
    isDeleting,
    handleDelete,
    setSelectedData,
    refetchDataSources,
    addBatchAction,
    incompleteAction,
  }
}
