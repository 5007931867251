import { Breadcrumb, Button } from "components/core"
import useAccountGenerateReport from "./useAccountGenerateReport"
import { InterventionDetails, PaymentStructure, Preview, ReviewDetails } from "./components"
import { RenderIf } from "components/hoc"
import { ReportDetails, DateRange } from "components/pages/Reports"

export const AccountGenerateReport = () => {
  const { close, refresh, getInterventions, ...state } = useAccountGenerateReport()

  return (
    <>
      <div className="max-w-[1100px] w-full">
        <Breadcrumb options={state.breadcrumbs} showBack={true} />
        <div className="mb-[32px]">
          <h1 className="text-xl text-headers font-semibold mb-[8px]">Report Parameters</h1>
          <p className="text-sm text-body">
            Ensure that all information provided here are verified and correct before proceeding
          </p>
        </div>
        <form onSubmit={state.proceed}>
          <ReportDetails state={state} />
          <hr className="ml-[24px] my-[24px]" />
          <DateRange state={state} />
          <div className="flex justify-end">
            <Button
              theme="transparent"
              className="w-[171px] px-[30px]"
              type="button"
              size="44"
              disabled={state.disableApply}
              loading={state.isFetchingInterventions}
              onClick={getInterventions}
            >
              Apply
            </Button>
          </div>
          <RenderIf condition={state.interventions === null}>
            <p className="text-sm text-body">No data found within the selected range</p>
          </RenderIf>
          <RenderIf condition={!!state.interventions?.length && !state.isFetchingInterventions}>
            <hr className="ml-[24px] my-[24px]" />
            <InterventionDetails state={state} />
            <hr className="ml-[24px] my-[24px]" />
            <ReviewDetails state={state} />
            <hr className="ml-[24px] my-[24px]" />
            <PaymentStructure state={state} />
            <div className="flex justify-start items-center">
              <Button
                theme="primary"
                size="44"
                className="min-w-[171px] mr-[16px]"
                disabled={!state.formData.reportTitle || !state.formData?.interventions?.length}
              >
                Proceed
              </Button>
              <Button theme="transparent" className="w-[171px] px-[30px]" type="button" size="44" onClick={refresh}>
                Refresh
              </Button>
            </div>
          </RenderIf>
        </form>
      </div>
      <RenderIf condition={state.showPreview}>
        <Preview close={close} state={state} />
      </RenderIf>
    </>
  )
}
