import { RenderIf } from "components/hoc"
import { ReportPreviewLayout } from "components/layouts/Reports"
import { fullDateFormatter } from "utils/dateFormatter"
import { formatCurrency } from "utils/formatCurrency"

interface Props {
  close: () => void
  state: any
}

export const Preview = ({ close, state }: Props) => {
  const { formData, date } = state

  return (
    <ReportPreviewLayout
      close={close}
      generateReport={state.generateReport}
      loading={state.isGeneratingReport}
      name={state.formData?.reportTitle}
    >
      <>
        <RenderIf condition={!!formData.interventions?.length}>
          <div className="grid grid-cols-2 mb-6">
            <p className="text-base text-body w-[245px]">Intervention title:</p>
            <p className="text-base text-headers">
              {formData.interventions[0]?.label}
              {!!(formData.interventions?.length - 1) && <span>+{formData.interventions?.length - 1}</span>}
            </p>
          </div>
        </RenderIf>
        <RenderIf condition={!!formData.interventionStatus.length}>
          <div className="grid grid-cols-2 mb-6">
            <p className="text-base text-body w-[245px]">Status:</p>
            <p className="text-base text-headers">
              {formData.interventionStatus
                .filter((item: any) => item.value !== "")
                .map((item: any) => item.label)
                .join(", ")}
            </p>
          </div>
        </RenderIf>
        <RenderIf condition={!!formData.additionalEligibility.length}>
          <div className="grid grid-cols-2 mb-6">
            <p className="text-base text-body w-[245px]">Additional eligibility:</p>
            <p className="text-base text-headers">
              {formData.additionalEligibility.map((item: any) => item.label).join(", ")}
            </p>
          </div>
        </RenderIf>
        <RenderIf condition={date.createdDate.startDate && date.createdDate.endDate}>
          <div className="grid grid-cols-2 mb-6">
            <p className="text-base text-body w-[245px]">Date Created:</p>
            <p className="text-base text-headers">
              {fullDateFormatter(date.createdDate.startDate)} - {fullDateFormatter(date.createdDate.endDate)}
            </p>
          </div>
        </RenderIf>
        <RenderIf condition={date.startDate.startDate && date.startDate.endDate}>
          <div className="grid grid-cols-2 mb-6">
            <p className="text-base text-body w-[245px]">Start Date:</p>
            <p className="text-base text-headers">
              {fullDateFormatter(date.startDate.startDate)} - {fullDateFormatter(date.startDate.endDate)}
            </p>
          </div>
        </RenderIf>
        <RenderIf condition={date.endDate.startDate && date.endDate.endDate}>
          <div className="grid grid-cols-2 mb-6">
            <p className="text-base text-body w-[245px]">End Date:</p>
            <p className="text-base text-headers">
              {fullDateFormatter(date.endDate.startDate)} - {fullDateFormatter(date.endDate.endDate)}
            </p>
          </div>
        </RenderIf>
        <RenderIf condition={!!formData.currency.length}>
          <div className="grid grid-cols-2 mb-6">
            <p className="text-base text-body w-[245px]">Currency:</p>
            <p className="text-base text-headers">{formData.currency.map((item: any) => item.label).join(", ")}</p>
          </div>
        </RenderIf>
        <RenderIf condition={!!formData.paymentType.length}>
          <div className="grid grid-cols-2 mb-6">
            <p className="text-base text-body w-[245px]">Payment Type:</p>
            <p className="text-base text-headers">{formData.paymentType.map((item: any) => item.label).join(", ")}</p>
          </div>
        </RenderIf>
        <RenderIf condition={!!formData.zones.length}>
          <div className="grid grid-cols-2 mb-6">
            <p className="text-base text-body w-[245px]">Zones:</p>
            <div className="flex gap-2 items-center flex-wrap">
              {formData?.zones.map((zone: any) => (
                <div className="flex items-center space-x-2" key={zone.label}>
                  <p className="text-body py-1 px-2 text-xs bg-grey-50 font-medium rounded-full">{zone.label}</p>
                </div>
              ))}
            </div>
          </div>
        </RenderIf>
        <RenderIf condition={!!formData.states.length}>
          <div className="grid grid-cols-2 mb-6">
            <p className="text-base text-body w-[245px]">States:</p>
            <div className="flex gap-2 items-center flex-wrap">
              {formData?.states.map((state: any) => (
                <div className="flex items-center space-x-2 w-fit" key={state.label}>
                  <p className="text-body py-1 px-2 text-xs bg-grey-50 font-medium rounded-full">{state.label}</p>
                </div>
              ))}
            </div>
          </div>
        </RenderIf>
        <RenderIf condition={!!formData.lga.length}>
          <div className="grid grid-cols-2 mb-6">
            <p className="text-base text-body w-[245px]">Local Government Area:</p>
            <div className="flex gap-2 items-center flex-wrap">
              {formData?.lga.map((item: any) => (
                <div className="flex items-center space-x-2" key={item.label}>
                  <p className="text-body py-1 px-2 text-xs bg-grey-50 font-medium rounded-full">{item.label}</p>
                </div>
              ))}
            </div>
          </div>
        </RenderIf>
        <RenderIf condition={formData.fromEligibilityAmount && formData.toEligibilityAmount}>
          <div className="grid grid-cols-2 mb-6">
            <p className="text-base text-body w-[245px]">Base Amount:</p>
            <p className="text-base text-headers">
              {formatCurrency(formData.fromEligibilityAmount)} - {formatCurrency(formData.toEligibilityAmount)}
            </p>
          </div>
        </RenderIf>
        <RenderIf condition={formData.fromTotalAmount && formData.toTotalAmount}>
          <div className="grid grid-cols-2 mb-6">
            <p className="text-base text-body w-[245px]">Top Up Amount:</p>
            <p className="text-base text-headers">
              {formatCurrency(formData.fromTotalAmount)} - {formatCurrency(formData.toTotalAmount)}
            </p>
          </div>
        </RenderIf>
      </>
    </ReportPreviewLayout>
  )
}
