import { Fragment } from "react"
import { motion } from "framer-motion"
import { Avatar, Table } from "components/core"
import { singleStaffTrainingHeader } from "constants/trainings"

const StaffTrainingBeneficiaryTable = ({ data, params, selectLimit, changePage, isLoading, onClickRow }: any) => {
  const MotionTr = motion.tr

  return (
    <div>
      <Table
        data={data?.training_participants ?? []}
        headers={singleStaffTrainingHeader}
        onPageChange={changePage}
        selectLimit={selectLimit}
        totalCount={data?.total}
        loading={isLoading}
        perPage={Number(params?.limit)}
        page={Number(params?.page)}
      >
        <>
          {data?.training_participants
            ? data?.training_participants?.map((participant: any) => (
                <Fragment key={participant.training_beneficiary_id}>
                  <MotionTr
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                    className="cc-custom-table-data-row cursor-pointer"
                    onClick={() => onClickRow(participant?.training_beneficiary_id)}
                  >
                    <td>
                      <div className="flex gap-2 text-body text-sm">
                        <Avatar image={participant?.name} />
                        <div>
                          <h1>{participant?.name}</h1>
                          <p className="text-grey-base text-xs font-medium">{participant?.beneficiary_reference_id}</p>
                        </div>
                      </div>
                    </td>
                    <td>{participant?.beneficiary_reference_id ?? "--"}</td>
                    <td className="capitalize">
                      <div className="text-xs text-grey-base">
                        <span className="text-sm text-headers">{participant?.attendance_count}</span>/
                        {data?.attendance_required}
                      </div>
                    </td>
                    <td className="capitalize">{participant?.email ?? "--"}</td>
                    <td className="capitalize">{participant?.phone_number ?? "--"}</td>
                    <td className="capitalize">{participant?.user_type ?? "--"}</td>
                  </MotionTr>
                </Fragment>
              ))
            : null}
        </>
      </Table>
    </div>
  )
}

export default StaffTrainingBeneficiaryTable
