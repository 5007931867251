import { useTable } from "hooks"
import { formatCurrency } from "utils/formatCurrency"
import { useParams } from "react-router-dom"
import { useGetReportQuery, useLazyExportReportQuery } from "services/api/reportSlice"
import { fullDateFormatter } from "utils/dateFormatter"
import { addCommasToNumbers } from "utils/addCommasToNumbers"
import { tryCatch } from "utils/tryCatch"
import { exportData } from "utils/exportData"
import { removeSpecificKeys } from "utils/removeSpecificKeys"

export default function useLivelihoodReport() {
  const { reportId } = useParams()
  const breadcrumbs = [{ title: "Report" }, { title: "Livelihood" }]
  const { changePage, selectLimit, params } = useTable()
  const { data: report, isLoading: isLoadingReport } = useGetReportQuery({
    reportId,
    ...removeSpecificKeys({ ...params }, ["sortBy"]),
  })
  const [exportReportQuery, { isFetching: isExporting }] = useLazyExportReportQuery()

  const overview = [
    {
      title: "Total Intervention List",
      count: addCommasToNumbers(report?.data?.totalInterventionCount ?? 0),
      icon: "ph:handshake",
    },
    {
      title: "Total Number of States",
      count: addCommasToNumbers(report?.data?.totalStates ?? 0),
      icon: "ph:handshake",
    },
    {
      title: "Total Intervention Amount ",
      count: formatCurrency(report?.data?.totalAmount ?? 0),
      icon: "ph:handshake",
    },
    {
      title: "Total Livelihood Amount ",
      count: formatCurrency(report?.data?.eligibilityAmount ?? 0),
      icon: "ph:handshake",
    },
  ]

  const exportReport = async () => {
    tryCatch(async () => {
      const res = await exportReportQuery(reportId).unwrap()

      const data = res?.data?.interventions?.map((item: any) => {
        return {
          "Intervention Title": item?.interventionTitle,
          "No. of state": item?.totalStates,
          "No. of zone": item?.totalZones,
          "No. of LGA": item?.totalLgas,
          Amount: formatCurrency(item?.totalAmount),
          "Payment Status": item?.paymentStatus,
          "Created Date": fullDateFormatter(item?.createdAt),
          Eligibility: "livelihood",
        }
      })
      exportData(data, `${report?.data?.reportTitle} Report`)
    })
  }

  return {
    breadcrumbs,
    exportReport,
    overview,
    isLoadingReport,
    isExporting,
    selectLimit,
    params,
    report: report?.data,
    changePage,
  }
}
