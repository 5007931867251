import { createApi } from "@reduxjs/toolkit/query/react"
import { axiosBaseQuery } from "../axiosBaseQuery"
import { FetchedDataSources, MinedData, SingleData, mineDataType, updateDataType } from "types/data"
import { createSlice } from "@reduxjs/toolkit"

const dataMiningBaseUrl = process.env.REACT_APP_DATA_MINING_BASE_URL ?? ""
const baseUrl = process.env.REACT_APP_BASE_URL ?? ""

// Custom base query function to switch base URLs
const customBaseQuery = ({ baseUrl }: { baseUrl: string }) => {
  return axiosBaseQuery({
    baseUrl,
  })
}

export const dataSlice = createApi({
  reducerPath: "dataSlice",
  baseQuery: async (args, api, extraOptions) => {
    const { url } = args
    const selectedBaseUrl = url.startsWith("/config-settings") ? baseUrl : dataMiningBaseUrl
    return customBaseQuery({ baseUrl: selectedBaseUrl })(args, api, extraOptions)
  },
  tagTypes: ["Data", "Source", "mine", "data", "Datasets", "History", "batch", "update", "SingleDataSource"],
  refetchOnMountOrArgChange: true,
  endpoints(build) {
    return {
      getDataSources: build.query<FetchedDataSources, any>({
        query: (params) => ({ url: `/config-settings/?configurationType=source_register`, method: "get", params }),
        providesTags: ["Data", "Source"],
      }),
      getSingleDataSource: build.query<SingleData, any>({
        query: ({ configId }) => ({ url: `/config-settings/${configId}`, method: "get" }),
        transformResponse: (response: any) => response.data,
        providesTags: ["SingleDataSource"],
      }),
      getMineHistory: build.query<MinedData, any>({
        query: (params) => ({ url: `/mining`, method: "get", params }),
        transformResponse: (response: any) => response.data,
        providesTags: ["Data"],
      }),
      getSingleMineHistory: build.query<any, any>({
        query: (reference) => ({ url: `/mining/${reference}`, method: "get" }),
        transformResponse: (response: any) => response.data,
        providesTags: ["History"],
      }),
      mineData: build.mutation<mineDataType, any>({
        query: (data) => ({ url: "/mining", method: "post", data }),
        invalidatesTags: ["mine", "data"],
      }),
      updateMinedData: build.mutation<any, any>({
        query: ({ id, data }) => ({ url: `/mining/${id}`, method: "put", data }),
      }),
      deleteMineHistory: build.mutation<any, string>({
        query: (id) => ({ url: `/mining/${id}`, method: "delete" }),
        invalidatesTags: ["mine", "data"],
      }),
      getDatasets: build.query<any, void>({
        query: () => ({ url: `/mining/datasets`, method: "get" }),
        transformResponse: (response: any) => response.data,
        providesTags: ["Datasets"],
      }),
      addBatch: build.mutation<mineDataType, any>({
        query: (data) => ({ url: "/mining/batch", method: "post", data }),
        invalidatesTags: ["batch", "data"],
      }),
      updateData: build.mutation<updateDataType, any>({
        query: ({ batch_id, payload }) => ({ url: `/mining/batch/${batch_id}`, method: "put", data: payload }),
        invalidatesTags: ["batch"],
      }),
    }
  },
})

export const {
  useMineDataMutation,
  useDeleteMineHistoryMutation,
  useGetMineHistoryQuery,
  useLazyGetMineHistoryQuery,
  useGetDatasetsQuery,
  useGetSingleMineHistoryQuery,
  useUpdateMinedDataMutation,
  useAddBatchMutation,
  useUpdateDataMutation,
  useGetDataSourcesQuery,
  useGetSingleDataSourceQuery,
} = dataSlice

const data = createSlice({
  name: "data",
  initialState: {
    minedDataId: "",
  },
  reducers: {
    setMinedData: (state, action) => {
      const { reference } = action.payload
      state.minedDataId = reference
    },
  },
})

export const { setMinedData } = data.actions
export const dataReducer = data.reducer
