export const MainTrainingsHeading = [
  {
    name: "Training Name",
    sort: false,
    action: null
  },
  {
    name: "Training ID",
    sort: false,
    action: null
  },
  {
    name: "Training Type",
    sort: false,
    action: null
  },
  {
    name: "Participant Type",
    sort: false,
    action: null
  },
  {
    name: "Training Unit",
    sort: false,
    action: null
  },
  {
    name: "Status",
    sort: false,
    action: null
  },
  {
    name: "Attendance Required",
    sort: false,
    action: null
  },
  {
    name: "Date Created",
    sort: false,
    action: null
  },
  {
    name: "Duration",
    sort: false,
    action: null
  },
  {
    name: "Actions",
    sort: false,
    action: null
  }
]

export const courseListHeading = [
  {
    name: 'Course Title',
    sort: false,
    action: null
  },
  {
    name: 'Number of Modules',
    sort: false,
    action: null
  },
  {
    name: 'Participants Training Type',
    sort: false,
    action: null
  },
  {
    name: "Skill Type",
    sort: false,
    action: null
  },
  {
    name: "Date Created",
    sort: false,
    action: null
  },
  {
    name: 'Actions',
    sort: false,
    action: null
  },
]

export const singleCourseDetailsHeaders = [
  "Course ID",
  "Trainings Attached",
  "Participant Type",
  "Training Course Type",
  "Number of Modules",
  "Date Created"
]

export const moduleListHeading = [
  {
    name: 'Module Number',
    sort: false,
    action: null
  },
  {
    name: 'Module Title',
    sort: false,
    action: null
  },
  {
    name: 'Description',
    sort: false,
    action: null
  },
  {
    name: 'Actions',
    sort: false,
    action: null
  },
]

export const singleTrainingDetailsHeaders = [
  "Training ID",
  "Number of Participants",
  "Training Unit",
  "Participant Type",
  "Trainer/Vendor",
  "Course Name",
  "Number of Modules",
  "Date Created",
  "Duration",
  "Sessions",
  "Start Date",
  "End Date"
]

export const singleTrainingHeaders =[
  {
    name: 'Full Name',
    sort: false,
    action: null
  },
  {
    name: 'Attendance',
    sort: false,
    action: null
  },
  {
    name: 'Phone Number',
    sort: false,
    action: null
  },
  {
    name: 'State',
    sort: false,
    action: null
  },
  {
    name: 'LGA',
    sort: false,
    action: null
  },
  {
    name: 'Gender',
    sort: false,
    action: null
  },
]

export const trainingBeneficiaryHeaders = [
  "Gender",
  "Date of Birth",
  "Age",
  "State",
  "Session Required",
  "Course Title"
]

export const trainingBeneficiaryTableHeader =[
  {
    name: 'Day',
    sort: false,
    action: null
  },
  {
    name: 'Date and Time',
    sort: false,
    action: null
  },
  {
    name: 'Status',
    sort: false,
    action: null
  },
  {
    name: 'Action',
    sort: false,
    action: null
  },
]

export const singleStaffTrainingHeader = [
  {
    name: "Name",
    sort: false,
    action: null
  },
  {
    name: "Staff ID",
    sort: false,
    action: null
  },
  {
    name: "Attendance",
    sort: false,
    action: null
  },
  {
    name: "Email",
    sort: false,
    action: null
  },
  {
    name: "Phone Number",
    sort: false,
    action: null
  },
  {
    name: "User Type",
    sort: false,
    action: null
  }
]