import { createApi } from "@reduxjs/toolkit/query/react"
import { createSlice } from "@reduxjs/toolkit"
import { axiosBaseQuery } from "services/axiosBaseQuery"

const baseUrl = `${process.env.REACT_APP_PAYMENTS_BASE_URL}/`

export const grievanceSlice = createApi({
  reducerPath: "grievanceSlice",
  baseQuery: axiosBaseQuery({ baseUrl }),
  tagTypes: [
    "Grievances",
    "Grievance",
    "Grievance-Categories",
    "Grievance-sub-categories",
    "Grievances-analytics",
    "GrievanceLogs",
    "zones",
    "states",
    "grievance-comments",
    "grievance-comments-tags",
    "resolve-grievance",
  ],
  refetchOnMountOrArgChange: true,
  endpoints(build) {
    return {
      fetchGrievanceCategories: build.query<any, any>({
        query: () => ({
          url: "grievances/categories",
          method: "get",
        }),
        transformResponse: (response: any) => response.data,
        providesTags: ["Grievances", "Grievance-Categories"],
      }),
      fetchGrievanceSubCategories: build.query<any, any>({
        query: (id) => ({
          url: `grievances/sub-categories/${id}`,
          method: "get",
        }),
        transformResponse: (response: any) => response.data,
        providesTags: ["Grievances", "Grievance-sub-categories"],
      }),
      fetchOfficers: build.query<any, any>({
        query: (params) => ({
          url: `grievances/staffs`,
          method: "get",
          params,
        }),
        transformResponse: (response: any) => response.data,
        providesTags: ["Grievances", "Grievance-sub-categories"],
      }),
      createGrievance: build.mutation<any, any>({
        query: (data) => ({
          url: `grievances`,
          method: "post",
          data,
        }),
        invalidatesTags: ["Grievances", "Grievance", "Grievances-analytics", "GrievanceLogs"],
      }),

      fetchGrievanceAnalytics: build.query<any, any>({
        query: () => ({
          url: "grievances/analytics",
          method: "get",
        }),
        transformResponse: (response: any) => response.data,
        providesTags: ["Grievances", "Grievance", "Grievances-analytics", "GrievanceLogs"],
      }),

      fetchGrievances: build.query<any, any>({
        query: ({ params }) => ({
          url: "grievances",
          method: "get",
          params,
        }),
        transformResponse: (response: any) => response.data,
        providesTags: ["Grievances", "GrievanceLogs"],
      }),

      editGrievance: build.mutation<any, any>({
        query: ({ id, data }) => ({
          url: `grievances/${id}`,
          method: "patch",
          data,
        }),
        invalidatesTags: ["Grievances", "Grievance", "Grievances-analytics", "GrievanceLogs"],
      }),

      resolveGrievance: build.mutation<any, any>({
        query: ({ id, payload }) => ({
          url: `grievances/resolve/${id}`,
          method: "patch",
          data: payload,
        }),
        invalidatesTags: ["Grievances", "Grievance", "Grievances-analytics", "GrievanceLogs"],
      }),

      escalateGrievance: build.mutation<any, any>({
        query: ({ id, data }) => ({
          url: `grievances/escalate/${id}`,
          method: "post",
          data,
        }),
        invalidatesTags: ["Grievances", "Grievance", "Grievances-analytics", "GrievanceLogs"],
      }),
      assignGrievance: build.mutation<any, any>({
        query: ({ id, data }) => ({
          url: `grievances/assign/${id}`,
          method: "patch",
          data,
        }),
        invalidatesTags: ["Grievances", "GrievanceLogs"],
      }),

      reassignGrievance: build.mutation<any, any>({
        query: ({ id, data }) => ({
          url: `grievances/reassign/${id}`,
          method: "patch",
          data,
        }),
        invalidatesTags: ["Grievances", "GrievanceLogs"],
      }),

      fetchZones: build.query<any, any>({
        query: () => ({
          url: `grievances/zones`,
          method: "get",
        }),
        transformResponse: (response: any) => response.data,
        providesTags: ["zones"],
      }),

      fetchStates: build.query<any, any>({
        query: ({ id }) => ({
          url: `grievances/states/${id}`,
          method: "get",
        }),
        transformResponse: (response: any) => response.data,
        providesTags: ["states"],
      }),
      fetchCommunities: build.query<any, any>({
        query: () => ({
          url: `grievances/communities`,
          method: "get",
        }),
        transformResponse: (response: any) => response.data,
        providesTags: ["states"],
      }),
      postGrmComment: build.mutation<any, any>({
        query: ({ data }) => ({
          url: `grievances/comments`,
          method: "post",
          data,
        }),
        invalidatesTags: ["grievance-comments"],
      }),
      fetchGrmComments: build.query<any, any>({
        query: ({ id, params }) => ({
          url: `grievances/comments/${id}`,
          method: "get",
          params,
        }),
        transformResponse: (response: any) => response.data,
        providesTags: ["grievance-comments"],
      }),

      fetchGrmTags: build.query<any, any>({
        query: ({ id, params }) => ({
          url: `grievances/comments/${id}`,
          method: "get",
          params,
        }),
        transformResponse: (response: any) => response.data,
        providesTags: ["grievance-comments-tags"],
      }),

      unResolveGrievance: build.mutation<any, any>({
        query: (id) => ({
          url: `grievances/reopen/${id}`,
          method: "patch",
        }),
        invalidatesTags: ["Grievances"],
      }),
    }
  },
})

const grievances = createSlice({
  name: "grievances",
  initialState: {},
  reducers: {
    setGrievanceData: (state, action) => {
      Object.assign(state, action.payload)
    },
  },
})

export const {
  useFetchGrievanceCategoriesQuery,
  useFetchGrievanceSubCategoriesQuery,
  useFetchGrievanceAnalyticsQuery,
  useFetchOfficersQuery,
  useCreateGrievanceMutation,
  useEditGrievanceMutation,
  useEscalateGrievanceMutation,
  useFetchGrievancesQuery,
  useResolveGrievanceMutation,
  useAssignGrievanceMutation,
  useReassignGrievanceMutation,
  useFetchStatesQuery,
  useFetchZonesQuery,
  useFetchCommunitiesQuery,
  usePostGrmCommentMutation,
  useFetchGrmCommentsQuery,
  useFetchGrmTagsQuery,
  useUnResolveGrievanceMutation,
} = grievanceSlice

export const { setGrievanceData } = grievances.actions
export const grievanceReducer = grievances.reducer
