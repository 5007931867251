import React from "react"
import { Actions } from "components/core"
import { ReactComponent as Disconnected } from "assets/icons/plug-disconnected.svg"
import { ReactComponent as Connected } from "assets/icons/plug-connected.svg"
import { Icon } from "@iconify/react"
import { Configuration } from "types/configuration"
import { RenderIf } from "components/hoc"
import { PermissionModule } from "types/permission"

interface ConfigurationCardProps {
  configuration: Configuration
  actions: any[]
}

export const ConfigurationCard: React.FC<ConfigurationCardProps> = ({ actions, configuration }) => {
  const isDisconnected = ["disconnected", "inactive"].includes(configuration.status)

  return (
    <div
      className="
            flex flex-col justify-between p-4 border border-neutral-10
            hover:border-neutral-30 rounded-xl group transition-all ease-out duration-700"
    >
      <div className="flex items-center justify-between mb-[8px]">
        <h3 className="text-headers font-bold text-base capitalize truncate">{configuration?.configuration_name}</h3>
        <RenderIf condition={!!configuration.logo}>
          <div>
            <img src={configuration.logo} width={32} height={32} className="rounded-[6px]" alt="logo" />
          </div>
        </RenderIf>
      </div>
      <p className="text-sm text-neutral-base line-clamp-3 text-ellipsis capitalize-first h-[60px]">
        {configuration?.description}
      </p>
      <div className="flex justify-between items-center pt-[12px] border-t border-t-neutral-10 mt-[8px]">
        <div className="flex items-center gap-1">
          <div
            className={`
                        cc-button text-xs h-[32px] px-[13px] rounded-xs mr-[4px]
                        ${isDisconnected ? "" : "border-primary-actions bg-primary-50 text-primary-actions"}
                        `}
          >
            {isDisconnected ? (
              <>
                <Disconnected className="mr-[8px]" />
                Connect
              </>
            ) : (
              <>
                <Connected className="mr-[8px]" /> Connected
              </>
            )}
          </div>
          <Actions
            actions={actions}
            id={configuration.id}
            item={configuration}
            module={PermissionModule.CONFIGURATION}
            trigger={
              <div className="cc-button h-[32px] w-[32px]">
                <Icon icon="pepicons-pencil:dots-x" />
              </div>
            }
          />
        </div>
        <div className="text-xs text-body">
          <p className="font-medium">Connection Mode:</p>{" "}
          <p className="font-normal text-right capitalize-first">{configuration?.mode_of_connection}</p>
        </div>
      </div>
    </div>
  )
}
