import { formatTimestamp } from "utils/dateFormatter"
import { Icon } from "@iconify/react"
import { RenderIf } from "components/hoc"

interface Props {
  data: any
}

const ResolvedStatus = ({ status }: { status: string }) => {
  return (
    <div className="flex items-center gap-x-2">
      <RenderIf condition={status === "satisfied"}>
        <div className="rounded-full p-3 bg-primary-50 flex items-center justify-center">
          <Icon icon="ph:smiley-light" className="w-4 h-4 text-primary-actions" />
        </div>
      </RenderIf>
      <RenderIf condition={status === "partly satisfied"}>
        <div className="rounded-full p-3 bg-grey-100 flex items-center justify-center">
          <Icon icon="ph:smiley-meh-light" className="w-4 h-4 text-grey-800" />
        </div>
      </RenderIf>
      <RenderIf condition={status === "not satisfied"}>
        <div className="rounded-full p-3 bg-error-lightest flex items-center justify-center">
          <Icon icon="ph:smiley-sad" className="w-4 h-4 text-error-dark" />
        </div>
      </RenderIf>
      <span className="text-grey-base capitalize">{status}</span>
    </div>
  )
}
const GrievanceResolution = ({ data }: Props) => {
  return (
    <main>
      <div className="space-y-10">
        <div className="">
          <div className="flex flex-wrap items-center gap-2">
            <h1 className="font-medium">How was the grievance resolved</h1>
            <div className=" bg-[#D9D9D9] rounded-full w-2 h-2" />
            <p className="text-[#A1A1A1]">{data?.resolved_at ? formatTimestamp(data?.resolved_at) : null}</p>
          </div>
          <p className="text-wrap text-[#A1A1A1]">{data?.complaint_resolved_details}</p>
        </div>

        <div>
          <div>
            <h1 className="font-medium mb-2">Complaint Satisfaction Status</h1>
            <ResolvedStatus status={data?.complaint_satisfaction} />
          </div>
        </div>
      </div>
    </main>
  )
}

export default GrievanceResolution
