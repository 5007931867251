import { useState } from "react"
import { Icon } from "@iconify/react"
import { Textarea, Button, Modal } from "components/core"
import useDeclineModal from "./useDeclineModal"

const DeclineModal = ({
  closeModal,
  unit,
  approvalState,
  id,
}: {
  closeModal: () => void
  unit: string
  approvalState: any
  id: string
}) => {
  const [declineReason, setDeclineReason] = useState("")

  const { declineApproval, isDecliningPaymentSchedule } = useDeclineModal({ closeModal })

  const handleDeclinePayment = async () => {
    let department = unit.toUpperCase()
    let department_level

    if (unit === "account") {
      department_level = 1
      department = "ACCOUNTS"
    } else if (unit === "audit") {
      department_level = 2
    } else if (unit === "program-manager") {
      department_level = 3
      department = "PROGRAM MANAGER"
    }

    const data = {
      payment_schedule_id: id,
      approval_state: {
        ...approvalState,
        [department]: [false, declineReason],
      },
      manager_approved: unit === "program-manager" && false,
      department: department,
      department_level: department_level,
    }
    declineApproval(data)
  }

  return (
    <Modal
      title={
        <div className="bg-primary-50 w-12 h-12 flex items-center justify-center rounded-lg">
          <Icon icon="ph:circle-half" className="w-10 h-10 text-primary-actions" />
        </div>
      }
      closeModal={closeModal}
      width="w-[400px]"
      position="modal-center"
    >
      <div className="flex flex-col gap-4 pt-2 px-6 pb-6" data-testid="decline-modal">
        <div className="">
          <h1 className="text-xl text-headers">Reject Payment Schedule</h1>
          <p className="text-body font-normal text-sm">Kindly input reason for rejection</p>
        </div>

        <Textarea
          id="decline"
          name="decline"
          value={declineReason}
          rows={5}
          onChange={(e) => setDeclineReason(e.target.value)}
        />

        <div className="grid grid-cols-2 gap-x-2 w-full justify-between">
          <div className="w-full">
            <Button theme="transparent" size="40" onClick={closeModal} className="w-full py-5">
              Discard
            </Button>
          </div>
          <div className="w-full">
            <Button
              theme="secondary"
              size="40"
              className="w-full py-5"
              onClick={handleDeclinePayment}
              loading={isDecliningPaymentSchedule}
              disabled={declineReason?.length < 0}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default DeclineModal
