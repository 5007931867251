import { useEffect, useState } from "react"
import { useLazyGetLgaInStateQuery } from "services/api/commonSlice"
import { useAddCommunityMutation, useEditCommunityMutation } from "services/api/livelihoodSlice"
import { tryCatch } from "utils/tryCatch"
import { toast } from "react-toastify"
interface Option {
  label?: string
  value?: string
  id?: string
}
interface AddCommunityFormType {
  state: Option
  lga: Option
  title: string
}
export default function useAddEditCommunity({ communityDetails, action, closeModal, showSuccesModal }: any) {
  const [getLgaInState, { isFetching: isFetchingLga }] = useLazyGetLgaInStateQuery()
  const [addCommunity, { isLoading: isAddingCommunity }] = useAddCommunityMutation()
  const [editCommunity, { isLoading: isUpdatingCommunity }] = useEditCommunityMutation()
  const [lga, setLga] = useState([])
  const [formData, setFormData] = useState<AddCommunityFormType>({
    title: "",
    state: {},
    lga: {},
  })
  const callback = (res: any) => {
    if ([200, 201].includes(res?.code)) {
      if (action === "Edit") {
        toast.success(res?.message)
      } else {
        showSuccesModal && showSuccesModal()
      }
      closeModal && closeModal()
    }
  }
  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }
  const handleSubmit = (e: any) => {
    e.preventDefault()
    const data = {
      ...formData,
      state: formData?.state?.id?.toString(),
      lga: formData?.lga?.id?.toString(),
    }
    tryCatch(async () => {
      let res
      if (action === "Add") {
        res = await addCommunity(data).unwrap()
      } else {
        res = await editCommunity({ id: communityDetails.community_id, data }).unwrap()
      }
      callback(res)
    })
  }
  useEffect(() => {
    if (formData?.state?.id) {
      tryCatch(async () => {
        const res = await getLgaInState({
          ids: formData?.state?.id,
        }).unwrap()
        const options = res?.map((item: any) => ({
          label: item?.name,
          value: item?.name,
          id: item.id,
        }))
        if (!res?.map((item: any) => item.name)?.includes(formData?.lga?.value)) {
          setFormData((prevFormData: any) => ({
            ...prevFormData,
            lga: "",
          }))
        }
        setLga(options)
      })
    }
  }, [formData?.state?.id])

  useEffect(() => {
    if (action === "Edit" && communityDetails) {
      setFormData({
        title: communityDetails?.community_name ?? "",
        state: communityDetails?.state
          ? {
              label: communityDetails?.state?.name,
              value: communityDetails?.state?.name,
              id: communityDetails?.state?.id,
            }
          : {},
        lga: communityDetails?.lga
          ? {
              label: communityDetails?.lga?.name,
              value: communityDetails?.lga?.name,
              id: communityDetails?.lga?.id,
            }
          : {},
      })
    }
  }, [action])
  return {
    formData,
    handleChange,
    setFormData,
    lga,
    handleSubmit,
    isAddingCommunity,
    isFetchingLga,
    isUpdatingCommunity,
  }
}
