import { Icon } from "@iconify/react"
import { Actions, Table } from "components/core"
import { RenderIf } from "components/hoc"
import { AnimatePresence, motion } from "framer-motion"
import React, { useState } from "react"
import { MinedData, SingleMinedData } from "types/data"
import { ReactComponent as CsvFile } from "assets/svg/csv-file.svg"
import { ReactComponent as ApiFile } from "assets/svg/api-file.svg"
import { fullDateFormatter } from "utils/dateFormatter"
import { extractVersion } from "utils/extractVersion"
import ProgressBar from "../../ProgressBar/ProgressBar"
import { removePercentageAndConvertToNumber } from "utils/percentageToNumberConverter"

interface Params {
  page: number | string
  limit: number | string
  sortBy?: string
  search?: string
}

interface MineHistoryTableProps {
  currentTab: string | null
  completeDataSourceHeaders: any
  incompleteDataSourceHeaders: any
  history: MinedData | undefined
  // eslint-disable-next-line no-unused-vars
  changePage: (e: any) => void
  params: Params
  isFetchingHistory: boolean
  // eslint-disable-next-line no-unused-vars
  selectLimit: (e: any) => void
  actions: any
  addBatchAction: any
  batches: SingleMinedData[]
}
const MotionTr = motion.tr

const MineHistoryTable: React.FC<MineHistoryTableProps> = ({
  currentTab,
  completeDataSourceHeaders,
  incompleteDataSourceHeaders,
  history,
  changePage,
  params,
  isFetchingHistory,
  selectLimit,
  actions,
  addBatchAction,
  // batches,
}) => {
  const [toggledRowId, setToggledRowId] = useState<number | string | null>(null)

  const toggleArrow = (id: number | string) => {
    if (toggledRowId === id) {
      setToggledRowId(null)
    } else {
      setToggledRowId(id)
    }
  }
  return (
    <Table
      headers={currentTab === "complete-data" ? completeDataSourceHeaders : incompleteDataSourceHeaders}
      data={history?.mineHistory ?? []}
      onPageChange={changePage}
      totalCount={Number(history?.total)}
      perPage={Number(params?.limit)}
      page={Number(history?.currentPage)}
      loading={isFetchingHistory}
      emptyStateText="No data yet"
      emptyStateImage={"layout"}
      selectLimit={selectLimit}
    >
      <RenderIf condition={currentTab === "complete-data"}>
        {history?.mineHistory?.map((item) => (
          <React.Fragment key={item.reference}>
            <MotionTr
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              exit={{ opacity: 0, y: -20 }}
              className="cc-custom-table-data-row"
              data-testid={item.source_register_id}
            >
              <td className="w-[20px]">
                {item.batches && item.batches.length > 0 && (
                  <button data-testid="toggle" onClick={() => toggleArrow(item.reference)}>
                    <Icon
                      icon={
                        toggledRowId === item.reference
                          ? "heroicons:chevron-down-solid"
                          : "heroicons:chevron-right-solid"
                      }
                      className="text-xl text-neutral-base mr-4 cursor-pointer"
                    />
                  </button>
                )}
              </td>
              <td className="flex items-center w-[400px]">
                <RenderIf condition={item.response_format === "csv"}>
                  <CsvFile className="ml-[-16px]" />
                </RenderIf>
                <RenderIf condition={item.response_format === "api"}>
                  <ApiFile className="ml-[-16px]" />
                </RenderIf>
                <div>
                  <p className="text-sm text-body font-normal">{item.label}</p>
                  <p className="text-grey-base text-xs mt-[2px]">{item.description}</p>
                </div>
              </td>
              <RenderIf condition={currentTab === "complete-data"}>
                <td className="text-sm text-body font-normal">{fullDateFormatter(item.created_at)}</td>
              </RenderIf>
              <RenderIf condition={currentTab === "complete-data"}>
                <td className="text-sm text-body font-normal">{fullDateFormatter(item.updated_at)}</td>
              </RenderIf>
              <RenderIf condition={currentTab === "incomplete-data"}>
                <td className="text-sm text-body font-normal capitalize">{extractVersion(item.label)}</td>
              </RenderIf>
              <RenderIf condition={currentTab === "incomplete-data"}>
                <td className="text-sm text-body font-normal">{fullDateFormatter(item.created_at)}</td>
              </RenderIf>

              <td>
                <div className="flex gap-3 items-center w-[100px]">
                  <ProgressBar progress={removePercentageAndConvertToNumber(item.status) ?? 0} />
                  <span className="text-sm text-body font-normal whitespace-nowrap">
                    {removePercentageAndConvertToNumber(item.status)} %
                  </span>
                </div>
              </td>
              <td>
                <div className="flex gap-3 items-center w-[100px]">
                  <span className="text-[12px] text-body font-normal  capitalize">{item.mine_status}</span>
                </div>
              </td>
              <RenderIf condition={currentTab === "complete-data"}>
                <td className="w-[20px]">
                  <Actions item={item} id={item.source_register_id} actions={actions} />
                </td>
              </RenderIf>
            </MotionTr>

            <AnimatePresence>
              {toggledRowId === item.reference &&
                item.batches &&
                item.batches.length > 0 &&
                item.batches.map((batchItem) => (
                  <MotionTr
                    key={batchItem.reference}
                    className="cc-custom-table-data-row"
                    data-testid={batchItem.reference}
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.2 }}
                    // exit={{ opacity: 0, y: -20 }}
                  >
                    <td className="w-[0px] text-sm font-normal px-3 py-2.5 cursor-default "></td>
                    <td className="flex items-center text-sm font-normal px-3 py-2.5 cursor-default w-[400px]">
                      <RenderIf condition={batchItem.format === "csv"}>
                        <CsvFile className="ml-[-16px]" />
                      </RenderIf>
                      <RenderIf condition={batchItem.format === "api"}>
                        <ApiFile className="ml-[-16px]" />
                      </RenderIf>
                      <div className="">
                        <p className="text-sm text-body font-normal">{batchItem.label}</p>
                      </div>
                    </td>
                    <RenderIf condition={currentTab === "complete-data"}>
                      <td className="text-sm text-body font-normal  px-3 py-2.5 cursor-default">
                        {fullDateFormatter(batchItem.created_at)}
                      </td>
                    </RenderIf>
                    <RenderIf condition={currentTab === "incomplete-data"}>
                      <td className="text-sm text-body font-normal  px-3 py-2.5 cursor-default capitalize">
                        {extractVersion(item.label)}
                      </td>
                    </RenderIf>

                    <td className="text-sm text-body font-normal  px-3 py-2.5 cursor-default">
                      {fullDateFormatter(batchItem.updated_at)}
                    </td>
                    <td>
                      <div className="flex gap-3 items-center w-[100px]">
                        <ProgressBar progress={removePercentageAndConvertToNumber(batchItem.status) ?? 0} />
                        <span className="text-sm text-body font-normal whitespace-nowrap">
                          {removePercentageAndConvertToNumber(batchItem.status)} %
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="flex gap-3 items-center w-[100px]">
                        <span className="text-[12px] text-body font-normal whitespace-nowrap capitalize">
                          {batchItem.mine_status}
                        </span>
                      </div>
                    </td>
                    <td className="w-[20px] m-auto">
                      <Actions item={item} id={item.source_register_id} actions={addBatchAction} />
                    </td>
                  </MotionTr>
                ))}
            </AnimatePresence>
          </React.Fragment>
        ))}
      </RenderIf>
      <RenderIf condition={currentTab === "incomplete-data"}>
        {history?.mineHistory?.map((item) => (
          <React.Fragment key={item.reference}>
            <MotionTr
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              exit={{ opacity: 0, y: -20 }}
              className="cc-custom-table-data-row"
              data-testid={item.source_register_id}
            >
              <td className="w-[2px]"></td>
              <td className="flex items-center w-[400px]">
                <RenderIf condition={item.format === "csv"}>
                  <CsvFile className="ml-[-16px]" />
                </RenderIf>
                <RenderIf condition={item.format === "api"}>
                  <ApiFile className="ml-[-16px]" />
                </RenderIf>
                <div>
                  <p className="text-sm text-body font-normal">{item.label}</p>
                  <p className="text-grey-base text-xs mt-[2px]">{item.description}</p>
                </div>
              </td>
              <RenderIf condition={currentTab === "complete-data"}>
                <td className="text-sm text-body font-normal">{fullDateFormatter(item.created_at)}</td>
              </RenderIf>
              <RenderIf condition={currentTab === "complete-data"}>
                <td className="text-sm text-body font-normal">{fullDateFormatter(item.updated_at)}</td>
              </RenderIf>
              <RenderIf condition={currentTab === "incomplete-data"}>
                <td className="text-sm text-body font-normal capitalize">{extractVersion(item.label)}</td>
              </RenderIf>
              <RenderIf condition={currentTab === "incomplete-data"}>
                <td className="text-sm text-body font-normal">{fullDateFormatter(item.created_at)}</td>
              </RenderIf>

              <td>
                <div className="flex gap-3 items-center w-[100px]">
                  <ProgressBar progress={removePercentageAndConvertToNumber(item.status) ?? 0} />
                  <span className="text-sm text-body font-normal whitespace-nowrap">
                    {removePercentageAndConvertToNumber(item.status)} %
                  </span>
                </div>
              </td>
              <td>
                <div className="flex gap-3 items-center w-[100px]">
                  <span className="text-[12px] text-body font-normal whitespace-nowrap capitalize">
                    {item.mine_status}
                  </span>
                </div>
              </td>
              <td className="w-[20px]">
                <Actions item={item} id={item.source_register_id} actions={addBatchAction} />
              </td>
            </MotionTr>
          </React.Fragment>
        ))}
      </RenderIf>
    </Table>
  )
}

export default MineHistoryTable
