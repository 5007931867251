import { useState } from "react"
import { ISODateFormatter } from "utils/dateFormatter"
import { toast } from "react-toastify"
import { tryCatch } from "utils/tryCatch"
import { useGenerateReportMutation } from "services/api/reportSlice"
import { useNavigate } from "react-router-dom"
import { removeKeysWithEmptyValues } from "utils/removeKeysWithEmptyValues"
import { removeSpecificKeys } from "utils/removeSpecificKeys"

const initialFormData = {
  reportType: "GRM",
  reportTitle: "",
  categoryStatus: [],
  level: [],
  category_name: "",
  category_id: "",
  categoryType: [],
  assignedStatus: [],
  states: [],
}
const initialDate = {
  startDate: { startDate: null, endDate: null },
  endDate: { startDate: null, endDate: null },
}
export default function useGenerateGRMReport() {
  const navigate = useNavigate()
  const [step, setStep] = useState(1)
  const [showPreview, setShowPreview] = useState(false)
  const breadcrumbs = [{ title: "GRM" }, { title: "Report Parameters" }]
  const [date, setDate] = useState<any>({ ...initialDate })
  const [formData, setFormData] = useState({ ...initialFormData })
  const [generateReportMutation, { isLoading: isGeneratingReport }] = useGenerateReportMutation()

  const handleChange = (e: any) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }
  const proceed = (e: any) => {
    e.preventDefault()
    setShowPreview(true)
  }
  const refresh = () => {
    setFormData(initialFormData)
    setDate(initialDate)
  }
  const close = () => {
    setShowPreview(false)
  }
  const checkDate = (date: Date) => {
    return date ? ISODateFormatter(date) : ""
  }
  const generateReport = () => {
    tryCatch(async () => {
      const { states, category_name, assignedStatus, level, categoryStatus, categoryType } = formData
      const payload = {
        ...formData,
        level: level?.length ? level?.map((item: any) => item.value) : "",
        assignedStatus: assignedStatus?.length ? assignedStatus?.map((item: any) => item.value) : "",
        categoryStatus: categoryStatus?.length ? categoryStatus?.map((item: any) => item.value) : "",
        categoryType: categoryType?.length ? categoryType?.map((item: any) => item.value.toString()) : "",
        category: category_name ? [category_name] : "",
        states: states?.length ? states?.map((item: any) => item.id.toString()) : "",
        fromStartDate: checkDate(date.startDate.startDate),
        toStartDate: checkDate(date.startDate.endDate),
        fromEndDate: checkDate(date.endDate.startDate),
        toEndDate: checkDate(date.endDate.endDate),
      }

      const res = await generateReportMutation(
        removeKeysWithEmptyValues(removeSpecificKeys({ ...payload }, ["category_id", "category_name"])),
      ).unwrap()

      if ([200, 201].includes(res?.code)) {
        toast.success(res?.message)
        navigate(`/reports/grm/${res?.data?.report_id}`)
      }
    })
  }

  return {
    step,
    proceed,
    setStep,
    formData,
    setFormData,
    generateReport,
    breadcrumbs,
    showPreview,
    handleChange,
    isGeneratingReport,
    date,
    close,
    refresh,
    setDate,
  }
}
