import { Icon } from "@iconify/react"
import { RenderIf } from "components/hoc"
import { Button, Actions, Avatar } from "components/core"
import { fullDateFormatter } from "utils/dateFormatter"
import useGrievanceDetails from "./useGrievanceDetails"
import EscalateGrievance from "./EscalateGrievance/EscalateGrievance"
import EditGrievance from "./EditGrievance/EditGrievance"
import AssignGrievanceOfficer from "./AssignOfficer/AssignOfficer"
import ResolveGrievance from "./ResolveGrievance/resolveGrievance"
import { ModuleStepper } from "components/core"

const GrievanceDetails = ({ profile, isLoading }: { profile: any; isLoading: boolean }) => {
  const {
    actions,
    selectedAction,
    setSelectedAction,
    handleResolveGrievance,
    isResolvingGrievance,
    isEscalatingGrievance,
    escalateReason,
    setEscalateReason,
    handleEscalateGrievance,
    tabs,
    componentToBeDisplayed,
    currentTab,
    handleUnResolveGrievance,
    isUnResolvingGrievance,
  } = useGrievanceDetails({ grievance: profile })

  const handleTextAreaChange = (e: any) => {
    e.preventDefault()
    setEscalateReason(e.target.value)
  }

  return (
    <>
      <div className="relative h-full">
        <RenderIf condition={isLoading}>
          <h1>Loading...</h1>
        </RenderIf>
        <RenderIf condition={profile !== null && !isLoading}>
          <div>
            <div>
              <div className="flex flex-col gap-y-3 pb-6 border-b">
                <div className="flex justify-between">
                  <div className="flex gap-x-3">
                    <h1 className="text-3xl text-headers font-semibold">{profile?.complaint_name}</h1>
                    <div className=" self-center bg-grey-50 text-grey-base text-center rounded-full py-1 px-2">
                      {profile?.complaint_ticket}
                    </div>
                    <div
                      className={`self-center capitalize font-medium text-center rounded-full py-1 px-2 ${
                        profile?.status === "pending"
                          ? "text-warning-dark bg-warning-lightest"
                          : "text-success-dark bg-success-lightest"
                      }`}
                    >
                      {profile?.status}
                    </div>
                  </div>
                  <RenderIf condition={profile?.status !== "pending"}>
                    <button
                      onClick={handleUnResolveGrievance}
                      disabled={isUnResolvingGrievance}
                      className="p-2 border-[1px] border-neutral-300 rounded-lg"
                    >
                      {isUnResolvingGrievance ? "Loading..." : "unresolve grievance"}
                    </button>
                  </RenderIf>
                  <RenderIf condition={profile?.status === "pending"}>
                    <div className="flex items-center gap-x-3">
                      <button
                        onClick={() => setSelectedAction("edit-grievance")}
                        className="border self-center border-neutral-200 py-2 px-4 rounded-lg"
                      >
                        <Icon icon="ph:pencil-simple-line" width={24} height={24} className="text-[#747F9C]" />
                      </button>
                      <Button className="self-center" size="40" onClick={() => setSelectedAction("resolve-grievance")}>
                        Mark as Resolved
                      </Button>
                      <Actions
                        id="grievances-actions"
                        actions={actions}
                        item={[]}
                        iconType="caret"
                        buttonClassName="rounded-lg py-2 px-4"
                      />
                    </div>
                  </RenderIf>
                </div>
                <p className="text-body w-9/12">{profile?.description}</p>
              </div>

              <div className="grid gap-y-4 grid-rows-2 grid-cols-5 gap-x-2 py-5 border-b">
                <div className="border-r">
                  <p className="text-body text-xs">Date Created</p>
                  <p className="text-headers text-sm font-medium">{fullDateFormatter(profile?.created_at)}</p>
                </div>
                <div className="border-r text-center">
                  <p className="text-body text-xs">Mode of Entry</p>
                  <p className="capitalize text-headers text-sm font-medium">{profile?.entry_mode}</p>
                </div>
                <div className="border-r text-center">
                  <p className="text-body text-xs">State</p>
                  <p className="text-headers text-sm font-medium">{profile?.state_name}</p>
                </div>
                <div className="border-r text-center">
                  <p className="text-body text-xs">Length of Time</p>
                  <p className="text-headers text-sm font-medium">{profile?.length_of_time} Day(s)</p>
                </div>
                <div className="">
                  <p className="text-body text-xs text-center">Assigned GRM Officer</p>
                  <div className="flex justify-center items-center gap-x-1">
                    <RenderIf condition={profile?.assigned_to}>
                      <Avatar image={profile?.assigned_to_name} />
                      <p className="text-headers text-sm font-medium">{profile?.assigned_to_name}</p>
                      <RenderIf condition={profile?.status === "pending"}>
                        <Icon
                          icon="ph:pencil-simple-line"
                          width={16}
                          height={16}
                          className="text-[#747F9C]"
                          onClick={() => {
                            setSelectedAction("reassign-officer")
                          }}
                        />
                      </RenderIf>
                    </RenderIf>
                    <RenderIf condition={!profile?.assigned_to}>
                      <button
                        className=" text-primary-actions font-medium cursor-pointer"
                        onClick={() => {
                          setSelectedAction("assign-officer")
                        }}
                      >
                        Pending <span className="underline">(Assign Officer)</span>
                      </button>
                    </RenderIf>
                  </div>
                </div>
                <div className="border-r text-left">
                  <p className="text-body text-xs">Phone Number</p>
                  <p className="text-headers text-sm font-medium">{profile?.complaint_phone_number ?? "--"}</p>
                </div>
                <div className="border-r text-center">
                  <p className="text-body text-xs">Address</p>
                  <p className="text-headers text-sm font-medium">{profile?.complaint_address ?? "--"}</p>
                </div>
                <div className="border-r text-center">
                  <p className="text-body text-xs">Zone</p>
                  <p className="text-headers text-sm font-medium">{profile?.zone_name}</p>
                </div>
                <div className="border-r text-center">
                  <p className="text-body text-xs">LGA</p>
                  <p className="text-headers text-sm font-medium">{profile?.lga_name}</p>
                </div>
                <div className="text-center">
                  <p className="text-body text-xs ">Community</p>
                  <p className="text-headers  text-sm font-medium">{profile?.community_name ?? "--"}</p>
                </div>
              </div>

              <div className="mt-2">
                <div className="w-full">
                  <ModuleStepper tabs={tabs} currentTab={currentTab} />
                </div>
                {componentToBeDisplayed}
              </div>
            </div>
          </div>
        </RenderIf>

        {selectedAction === "escalate-grievance" && (
          <div className="">
            <EscalateGrievance
              value={escalateReason}
              onChange={handleTextAreaChange}
              close={() => {
                setSelectedAction("")
                setEscalateReason("")
              }}
              loading={isEscalatingGrievance}
              submit={() => handleEscalateGrievance(profile?.grievance_id)}
            />
          </div>
        )}
        {selectedAction === "resolve-grievance" && (
          <ResolveGrievance
            onClose={() => setSelectedAction("")}
            loading={isResolvingGrievance}
            onSubmit={handleResolveGrievance}
            grievance={profile}
          />
          // <ManageItem
          //   onClose={() => setSelectedAction("")}
          //   title="Resolve Grievance"
          //   loading={isResolvingGrievance}
          //   onSubmit={() => handleResolveGrievance(profile?.grievance_id)}
          //   actionName="Yes, Proceed"
          //   cancelBtnText="Discard"
          //   caption="Please confirm that the issues has been resolved before you proceed"
          //   icon={
          //     <div className="bg-primary-50 w-12 h-12 flex items-center justify-center rounded-lg">
          //       <Icon icon="ph:circle-half" className="w-10 h-10 text-primary-actions" />
          //     </div>
          //   }
          // />
        )}
      </div>
      {selectedAction === "edit-grievance" && (
        <EditGrievance onClose={() => setSelectedAction("")} grievance={profile} />
      )}
      {(selectedAction === "assign-officer" || selectedAction === "reassign-officer") && (
        <AssignGrievanceOfficer
          grievance={profile}
          onClose={() => setSelectedAction("")}
          selectedAction={selectedAction}
        />
      )}
    </>
  )
}

export default GrievanceDetails
