import { Icon } from "@iconify/react"
import { Modal, Button } from "components/core"
import useApprovalModal from "./useApproveModal"

const ApprovalModal = ({
  closeModal,
  unit,
  approvalState,
  id,
}: {
  closeModal: () => void
  unit: string
  approvalState: any
  id: string
}) => {
  const { approvePayment, isApprovingPaymentSchedule } = useApprovalModal({ closeModal, unit })

  const handleApprovePayment = () => {
    let department = unit.toUpperCase()
    let department_level

    if (unit === "account") {
      department_level = 1
      department = "ACCOUNTS"
    } else if (unit === "audit") {
      department_level = 2
    } else if (unit === "program-manager") {
      department_level = 3
      department = "PROGRAM MANAGER"
    }
    const data = {
      payment_schedule_id: id,
      approval_state: {
        ...approvalState,
        [department]: [true],
      },
      manager_approved: unit === "program-manager",
      department: department,
      department_level: department_level,
    }
    approvePayment(data)
  }

  return (
    <Modal
      title={
        <div className="bg-primary-50 w-12 h-12 flex items-center justify-center rounded-lg">
          <Icon icon="ph:circle-half" className="w-10 h-10 text-primary-actions" />
        </div>
      }
      closeModal={closeModal}
      width="w-[400px]"
      position="modal-center"
    >
      <div className="" data-testid="payment-approval-modal">
        <div className="flex flex-col gap-4 pt-2 px-6 pb-6">
          <div className="h-[100px]">
            <h1 className="text-xl text-headers">Accept Payment Schedule</h1>
            <p className="text-body font-normal text-sm">Kindly ensure all information is correct before approving.</p>
          </div>

          <div className="grid grid-cols-2 gap-x-2 w-full justify-between">
            <div className="w-full ">
              <Button theme="transparent" size="40" className="w-full py-5" onClick={closeModal}>
                Discard
              </Button>
            </div>
            <div className="w-full ">
              <Button
                theme="primary"
                size="40"
                onClick={handleApprovePayment}
                className="w-full py-5"
                loading={isApprovingPaymentSchedule}
              >
                Yes, Proceed
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ApprovalModal
