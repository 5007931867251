import Papa from "papaparse"
import { useMemo, useState } from "react"
import { removeKeysWithEmptyValues } from "utils/objectFormatter"
import { toast } from "react-toastify"
import { useInviteBulkStaffsMutation } from "services/api/staffSlice"

export default function useBulkUploadStaff(uploadFile, closeModal) {
  const [file, setFile] = useState({})
  const [csvData, setCsvData] = useState([])
  const [inviteBulkStaffs, { isLoading: isUploadingStaffs }] = useInviteBulkStaffsMutation()

  const formatPhoneNumber = (phoneNumber) => {
    if (phoneNumber.charAt(0) === "0") {
      return phoneNumber.slice(1)
    }
    return phoneNumber
  }

  const convertCsvtoJson = (file) => {
    setFile({
      name: file.name,
      size: (file.size / 1000).toFixed(2),
    })
    if (file) {
      const fileDetails = file
      const reader = new FileReader()
      reader.onload = () => {
        const data = reader.result
        // Parse CSV using PapaParse
        const result = Papa.parse(data, {
          header: true,
        })
        setCsvData(result.data)
      }
      reader.readAsText(fileDetails)
    }
  }

  const tableData = useMemo(
    () =>
      csvData.map((item) => {
        return removeKeysWithEmptyValues({
          first_name: item["First Name"],
          last_name: item["Last Name"],
          email: item["Email address"],
          phone_number: item["Phone number(optional)"]
            ? formatPhoneNumber(item["Phone number(optional)"].toString())
            : "",
          role: item["Role"],
          staff_number: item["ID"],
          user_type: item["User Type"],
        })
      }),
    [csvData],
  )

  const checkPreview = () => {
    if (tableData.length === 0) {
      toast.error('There are no driver details to upload. Kindly update csv file')
    } else {
      uploadFile()
    }
  }

  const handleBulkUpload = async () => {
    const res = await inviteBulkStaffs({ allStaff: tableData }).unwrap()
    if ([200, 201].includes(res?.code)) {
      toast.success(res?.message)
      closeModal()
      setFile({})
    }
  }

  return {
    file,
    convertCsvtoJson,
    checkPreview,
    formatPhoneNumber,
    tableData,
    csvData,
    handleBulkUpload,
    isUploadingStaffs
  }
}