import { Input, Button } from "components/core"
import { Dropdown } from "components/core/Dropdown/Dropdown"
import Phone from "components/core/PhoneInput/PhoneInput"
import RadioButton from "components/core/RadioButton/RadioButton"
import useStaffManagement from "pages/settings/StaffManagement/useStaffManagement"
import React from "react"

interface SingleStaffProps {
  action: string
  closeModal: () => void
  staffDetails: any
  // eslint-disable-next-line no-unused-vars
  setPhone: (e: any) => void
  phone?: any
  // eslint-disable-next-line no-unused-vars
  isInvitingStaff: boolean
  // eslint-disable-next-line no-unused-vars
  handleInviteStaff: (e: any) => void
  formData: any
  // eslint-disable-next-line no-unused-vars
  setFormData: (e: any) => void
  // eslint-disable-next-line no-unused-vars
  handleChange: (e: any) => void
  disableBtn: any
  isUpdatingStaff: boolean
  defaultRole?: string
}

const SingleStaff = ({
  action,
  closeModal,
  staffDetails,
  setPhone,
  phone,
  isInvitingStaff,
  handleInviteStaff,
  formData,
  setFormData,
  handleChange,
  disableBtn,
  isUpdatingStaff,
  defaultRole = "",
}: SingleStaffProps) => {
  const { activeRoles } = useStaffManagement()
  const userTypes = ["NCTU", "SCTU", "LCTU"]

  return (
    <form className="mb-6">
      <div className="px-6 pt-6 pb-[90px]">
        {action === "Add" && (
          <div className="mb-6">
            <p className="text-body font-semibold mb-3">Select user type</p>
            <div className="flex space-x-4">
              {userTypes.map((item: string) => (
                <RadioButton
                  key={item}
                  name={item}
                  label={item}
                  checked={item === formData?.user_type}
                  value={formData?.user_type}
                  onChange={() => setFormData({ ...formData, user_type: item })}
                  size="36"
                />
              ))}
            </div>
          </div>
        )}
        <div className={`${action === "Add" ? "border-t border-grey-200 pt-6" : ""}`}>
          <Input
            label="First Name"
            type="text"
            id="first_name"
            name="first_name"
            value={formData?.first_name}
            onChange={handleChange}
            className="mb-[16px]"
          />
          <Input
            label="Last Name"
            type="text"
            id="last_name"
            name="last_name"
            value={formData?.last_name}
            onChange={handleChange}
            className="mb-[16px]"
          />
          <Input
            label="Email Address"
            type="text"
            id="email"
            name="email"
            value={formData?.email}
            disabled={action === "Edit"}
            onChange={handleChange}
            className="mb-[16px]"
          />
          <Input
            label="ID"
            type="text"
            id="staff_number"
            optional={true}
            name="staff_number"
            value={formData?.staff_number}
            onChange={handleChange}
            className="mb-[16px]"
          />
          <Phone value={phone} onChange={setPhone} label="Phone number" optional={true} />
          <Dropdown
            label="Select Role"
            enableSearchOptions={true}
            disabled={!!defaultRole}
            selected={defaultRole || (action === "Edit" && staffDetails?.role ? staffDetails?.role : "")}
            options={activeRoles?.filter((item: any) => item.value !== formData?.role) ?? []}
            onSelect={(data: any) => {
              setFormData({ ...formData, role: data.value })
            }}
          />
        </div>
      </div>
      <div className="modal-right-button-container !pb-3">
        <Button
          theme="primary"
          size="44"
          className="min-w-[105px] mr-[8px]"
          loading={isInvitingStaff || isUpdatingStaff}
          onClick={handleInviteStaff}
          disabled={disableBtn}
        >
          {action === "Add" ? "Send Invite" : "Save Changes"}
        </Button>
        <Button theme="transparent" className="w-[105px] px-[30px]" type="button" onClick={closeModal} size="44">
          Discard
        </Button>
      </div>
    </form>
  )
}

export default SingleStaff
