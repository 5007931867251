export const dummyRoles = [
  {
    id: "1",
    title: "Compliance Officer",
    subTitle:
      "Ensures a company adheres to laws, regulations, and internal policies, minimizing risks and promoting ethical practices.",
    status: "active",
    staffs: [
      { id: "1", name: "Stephen Diala", avatar: null },
      {
        id: "2",
        name: "Cynthia Ilojeme",
        avatar: "https://avatars.githubusercontent.com/u/50671911?v=4",
      },
      { id: "3", name: "Egwuchukwu Diala", avatar: null },
    ],
  },
  {
    id: "2",
    title: "Compliance Analyst",
    subTitle:
      "Conducts assessments and monitors compliance activities, identifying areas of non-compliance and recommending corrective actions.",
    status: "active",
    staffs: [
      { id: "4", name: "Stephen Diala", avatar: null },
      {
        id: "5",
        name: "Cynthia Ilojeme",
        avatar: "https://avatars.githubusercontent.com/u/50671911?v=4",
      },
      { id: "6", name: "Egwuchukwu Diala", avatar: null },
    ],
  },
  {
    id: "3",
    title: "Training Coordinator",
    subTitle:
      "Designs and delivers compliance training programs, ensure employees savvy and adhere to regulatory requirements and internal policies.",
    status: "active",
    staffs: [
      { id: "7", name: "Stephen Diala", avatar: null },
      {
        id: "8",
        name: "Cynthia Ilojeme",
        avatar: "https://avatars.githubusercontent.com/u/50671911?v=4",
      },
      { id: "9", name: "Egwuchukwu Diala", avatar: null },
    ],
  },
  {
    id: "4",
    title: "Audit Specialist",
    subTitle:
      "Conducts internal audits to evaluate compliance with regulations and policies, identifying deficiencies, and suggests improvements.",
    status: "active",
    staffs: [
      { id: "10", name: "Stephen Diala", avatar: null },
      {
        id: "11",
        name: "Cynthia Ilojeme",
        avatar: "https://avatars.githubusercontent.com/u/50671911?v=4",
      },
      { id: "12", name: "Egwuchukwu Diala", avatar: null },
    ],
  },
  {
    id: "5",
    title: "Regulatory Affairs Manager",
    subTitle:
      "Ensures a company adheres to laws, regulations, and internal policies, minimizing risks and promoting ethical practices.",
    status: "deactivated",
    staffs: [
      { id: "13", name: "Stephen Diala", avatar: null },
      {
        id: "14",
        name: "Cynthia Ilojeme",
        avatar: "https://avatars.githubusercontent.com/u/50671911?v=4",
      },
      { id: "15", name: "Egwuchukwu Diala", avatar: null },
    ],
  },
];

export const dummyStaffs = [
  {
    id: "CC-12345-BS",
    first_name: "Stephen",
    last_name: "Diala",
    role_id: "1",
    role: "",
    image: null
  },
  {
    id: "CC-12345-BE",
    first_name: "Cynthia",
    last_name: "Ilojeme",
    role_id: "1",
    role: "",
    image: "https://avatars.githubusercontent.com/u/50671911?v=4",
  },
  {
    id: "CC-12345-BW",
    first_name: "Egwuchukwu",
    last_name: "Diala",
    role_id: "1",
    role: "",
    image: null
  },
]