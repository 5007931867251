import { RenderIf } from "components/hoc"
import { ReportPreviewLayout } from "components/layouts/Reports"
import { fullDateFormatter } from "utils/dateFormatter"

interface Props {
  state: any
  close: () => void
}

export const Preview = ({ close, state }: Props) => {
  const { formData, date } = state

  return (
    <ReportPreviewLayout
      close={close}
      generateReport={state.generateReport}
      loading={state.isGeneratingReport}
      name={state.formData?.reportTitle}
    >
      <>
        <RenderIf condition={formData?.category_name}>
          <div className="grid grid-cols-2 mb-6">
            <p className="text-base text-body w-[245px]">Category:</p>
            <p className="text-base text-headers">{formData?.category_name}</p>
          </div>
        </RenderIf>
        <RenderIf condition={!!formData?.categoryType.length}>
          <div className="grid grid-cols-2 mb-6">
            <p className="text-base text-body w-[245px]">Category Type:</p>
            <div className="flex gap-2 items-center flex-wrap">
              {formData?.categoryType?.map((item: any) => (
                <div className="flex items-center space-x-2 w-fit" key={item.label}>
                  <p className="text-body py-1 px-2 text-xs bg-grey-50 font-medium rounded-full">{item.label}</p>
                </div>
              ))}
            </div>
          </div>
        </RenderIf>
        <RenderIf condition={!!formData?.categoryStatus.length}>
          <div className="grid grid-cols-2 mb-6">
            <p className="text-base text-body w-[245px]">Category Status:</p>
            <div className="flex gap-2 items-center flex-wrap">
              {formData?.categoryStatus?.map((item: any) => (
                <div className="flex items-center space-x-2 w-fit" key={item.label}>
                  <p className="text-body py-1 px-2 text-xs bg-grey-50 font-medium rounded-full">{item.label}</p>
                </div>
              ))}
            </div>
          </div>
        </RenderIf>
        <RenderIf condition={date.startDate.startDate && date.startDate.endDate}>
          <div className="grid grid-cols-2 mb-6">
            <p className="text-base text-body w-[245px]">Start Date:</p>
            <p className="text-base text-headers">
              {fullDateFormatter(date.startDate.startDate)} - {fullDateFormatter(date.startDate.endDate)}
            </p>
          </div>
        </RenderIf>
        <RenderIf condition={date.endDate.startDate && date.endDate.endDate}>
          <div className="grid grid-cols-2 mb-6">
            <p className="text-base text-body w-[245px]">Resolved Date:</p>
            <p className="text-base text-headers">
              {fullDateFormatter(date.endDate.startDate)} - {fullDateFormatter(date.endDate.endDate)}
            </p>
          </div>
        </RenderIf>
        <RenderIf condition={!!formData?.assignedStatus.length}>
          <div className="grid grid-cols-2 mb-6">
            <p className="text-base text-body w-[245px]">Assigned Status:</p>
            <div className="flex gap-2 items-center flex-wrap">
              {formData?.assignedStatus?.map((item: any) => (
                <div className="flex items-center space-x-2 w-fit" key={item.label}>
                  <p className="text-body py-1 px-2 text-xs bg-grey-50 font-medium rounded-full">{item.label}</p>
                </div>
              ))}
            </div>
          </div>
        </RenderIf>
        <RenderIf condition={!!formData.states.length}>
          <div className="grid grid-cols-2 mb-6">
            <p className="text-base text-body w-[245px]">States:</p>
            <div className="flex gap-2 items-center flex-wrap">
              {formData?.states.map((state: any) => (
                <div className="flex items-center space-x-2 w-fit" key={state.label}>
                  <p className="text-body py-1 px-2 text-xs bg-grey-50 font-medium rounded-full">{state.label}</p>
                </div>
              ))}
            </div>
          </div>
        </RenderIf>
        <RenderIf condition={!!formData?.level.length}>
          <div className="grid grid-cols-2 mb-6">
            <p className="text-base text-body w-[245px]">Level:</p>
            <div className="flex gap-2 items-center flex-wrap">
              {formData?.level?.map((item: any) => (
                <div className="flex items-center space-x-2 w-fit" key={item.label}>
                  <p className="text-body py-1 px-2 text-xs bg-grey-50 font-medium rounded-full">{item.label}</p>
                </div>
              ))}
            </div>
          </div>
        </RenderIf>
      </>
    </ReportPreviewLayout>
  )
}
