import React from "react"
import { Icon } from "@iconify/react"
import { motion } from "framer-motion"
import { useNavigate } from "react-router-dom"
import { Table } from "components/core"
import { paymentScheduleHeader } from "constants/payments"
import { addCommasToNumbers } from "utils/addCommasToNumbers"
// import PaymentStatus from "../Status/PaymentStatus"
import { fullDateFormatter } from "utils/dateFormatter"
import PaymentReviewStatus from "../ReviewStatus/ReviewStatus"
import { usePermission } from "hooks/usePermission/usePermission"
import { RenderIf } from "components/hoc"

interface Props {
  data: any
  // eslint-disable-next-line no-unused-vars
  onPageChange?: (e: any) => void
  totalCount?: number
  perPage?: number
  page?: number
  loading?: boolean
  unit?: string
  // eslint-disable-next-line no-unused-vars
  selectLimit?: (e: any) => void
  module: string
  permissions: any
}

const MotionTr = motion.tr

const PaymentScheduleTable = ({
  data,
  onPageChange,
  totalCount,
  perPage,
  page,
  loading,
  unit,
  selectLimit,
  module,
  permissions,
}: Props) => {
  const { hasPermission } = usePermission()
  const navigate = useNavigate()

  const showSinglePage = hasPermission(module, permissions.singleView)

  const handleOpenSingleOpenPage = (item: { schedule_id: string }) => {
    if (showSinglePage) {
      navigate(`/payments/${item.schedule_id}/${unit}`)
    }
  }

  return (
    <Table
      headers={paymentScheduleHeader}
      data={data ?? []}
      onPageChange={onPageChange}
      totalCount={totalCount}
      perPage={perPage}
      page={page}
      loading={loading}
      selectLimit={selectLimit}
    >
      <>
        {data
          ? data?.map((item: any) => (
              <React.Fragment key={item.id}>
                <MotionTr
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3 }}
                  className="cc-custom-table-data-row"
                  data-testid={item?.id}
                  onClick={() => handleOpenSingleOpenPage(item)}
                >
                  <td className="flex items-center">
                    <div>
                      <p className="max-w-[280px] truncate capitalize">{item?.intervention_title}</p>
                      <p className="font-medium text-headers">
                        {addCommasToNumbers(item?.scheduled_beneficiaries_count)}{" "}
                        <span className="text-grey-base">Beneficiaries</span>
                      </p>
                    </div>
                  </td>
                  <td className="max-w-[150px] truncate capitalize">{item?.schedule_reference ?? item.schedule_id}</td>
                  <td>{item?.payment_cycle}</td>
                  <td>{fullDateFormatter(item.created_at)}</td>
                  <td>₦{addCommasToNumbers(Number(item?.amount * Number(item?.scheduled_beneficiaries_count)))}</td>
                  {/* <td>{<PaymentStatus status={item?.status} />}</td> */}
                  <td data-testid={`review-status-${item?.id}`}>
                    {item.review_status ? <PaymentReviewStatus status={item?.review_status} /> : "--"}
                  </td>
                  <RenderIf condition={showSinglePage}>
                    <td data-testid="single-click" onClick={() => handleOpenSingleOpenPage(item)}>
                      <div className="border border-[#E6E6E6] grid place-items-center p-[6px] rounded-[4px] cursor-pointer">
                        <Icon icon="ph:eye" className="w-4 h-4" />
                      </div>
                    </td>
                  </RenderIf>
                </MotionTr>
              </React.Fragment>
            ))
          : null}
      </>
    </Table>
  )
}

export default PaymentScheduleTable
