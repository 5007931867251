export const stringifyArray = (options: any[], key: string = "value") => {
  return options
    .map((option: any) => {
      const value = option[key]
      return typeof value === "string" ? value.toLowerCase() : value
    })
    .filter((value: string) => value !== "")
    .join(",")
}

export const stringifyArrayII = (options: any) => {
  return options
    .map((option: { value: any }) => option.value)
    .filter((value: string) => value !== "")
    .join(",")
}
