import { Icon } from "@iconify/react"
import { Button, Checkbox, Input } from "components/core"
import { Dropdown } from "components/core/Dropdown/Dropdown"
import { eligibilityCriterias, livelihoods } from "constants/intervention"
import useAdditionalEligibility from "./useAdditionalEligibility"

interface props {
  setFormData: any
  formData: any
  proceed: () => void
  back: () => void
}

const AdditionalEligibility = ({ setFormData, formData, proceed, back }: props) => {
  const {
    addEligibilityRow,
    eligibilities,
    deleteEligibilityRow,
    updateRowValue,
    submit,
    updateSelected,
    preSelectedEligibility,
    selected,
  } = useAdditionalEligibility({ formData, setFormData, proceed })

  return (
    <div className="mt-8">
      <div className="flex flex-col gap-y-4">
        {eligibilities.map(({ label, values, value }) => (
          <div key={label} className="border border-grey-200 rounded-lg px-4 pt-4 py-6">
            <label htmlFor={label} className="flex justify-between">
              <Checkbox
                checked={selected.includes(label) || preSelectedEligibility.includes(value)}
                id={label}
                label={label}
                className="relative cursor-pointer select-none rounded-lg px-3 py-[8px]"
                onChange={() => updateSelected(label)}
              />
              <Icon icon="iconamoon:arrow-down-2-fill" className="text-2xl" />
            </label>
            {(preSelectedEligibility.includes(value) || selected.includes(label)) && (
              <>
                <table className="w-full mt-4">
                  <thead>
                    <tr className="text-left h-8 border text-xs text-headers grid grid-cols-12 ">
                      <th className="pl-3 font-medium border-r col-span-6 py-2">Eligibility Criteria</th>
                      <th className="pl-3 font-medium border-r col-span-5 py-2">Percentage Covered</th>
                    </tr>
                  </thead>
                  <tbody>
                    {[...values].map(({ condition, percentage }: any, index: number) => {
                      return (
                        <tr className="border h-12 text-sm text-body grid grid-cols-12" key={index}>
                          <td className="border-r col-span-6">
                            <Dropdown
                              selected={condition}
                              containerVariant="!border-none h-10 !shadow-none"
                              options={label === "Co-responsibility eligibility" ? eligibilityCriterias : livelihoods}
                              search={false}
                              onSelect={(data: any) =>
                                updateRowValue({ label, key: "condition", value: { ...data }.value, index })
                              }
                            />
                          </td>
                          <td className="px-3 border-r col-span-5">
                            <Input
                              type="number"
                              id={String(index)}
                              name="percentage"
                              value={percentage}
                              measurement="%"
                              onChange={(e: any) =>
                                updateRowValue({ label, key: "percentage", value: e.target.value, index })
                              }
                              className="w-full"
                              variant="border-none !p-0 !h-[40px]"
                            />
                          </td>
                          <td className="p-3 col-span-1 flecx justify-center items-center">
                            <div data-testid="delete-row" onClick={() => deleteEligibilityRow(label, index)}>
                              <Icon icon="material-symbols:delete" className="text-2xl cursor-pointer text-grey-300" />
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
                <button
                  className="flex items-center bg-grey-200 p-4 cursor-pointer w-full"
                  onClick={() => addEligibilityRow(label)}
                >
                  <span
                    className="
                  bg-primary-actions h-4 w-4 text-sm flex justify-center items-center pb-0.5 mr-3 text-white rounded-full cursor-pointer
                  "
                  >
                    +
                  </span>
                  <p className="text-sm text-primary-actions font-medium rounded-br-lg rounded-bs-lg">Add new row</p>
                </button>
              </>
            )}
          </div>
        ))}
      </div>

      <div className="flex justify-start items-center mt-12 space-x-4">
        <Button theme="primary" size="44" className="min-w-[171px]" onClick={submit}>
          Proceed
        </Button>
        <Button theme="transparent" className="w-[171px] px-[30px]" type="button" onClick={back} size="44">
          Back
        </Button>
      </div>
    </div>
  )
}

export default AdditionalEligibility
