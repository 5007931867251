import { useState, ChangeEvent, useEffect, useMemo } from "react"
import { useTable } from "hooks"
import { useSearchParams } from "react-router-dom"
import { DateType } from "types/beneficiary"
import {
  useGetBeneficiariesQuery,
  useGetBeneficiaryAnalyticsQuery,
  useGetBeneficiaryHistoryAnalyticsQuery,
  useGetBeneficiaryHistoryQuery,
  useLazyGetBeneficiariesExportQuery,
  useLazyGetBeneficiariesUrlQuery,
} from "services/api/beneficiarySlice"
import { toast } from "react-toastify"
import { shortDate } from "utils/dateFormatter"
import { useGetInterventionsQuery } from "services/api/interventionSlice"
import { stringifyArray } from "utils/stringifyArray"

export default function useBeneficiary() {
  const [selectedStatus, setSelectedStatus] = useState<any>([])
  const [selectedAction, setSelectedAction] = useState("")
  const [goToUpdateData, setGoToUpdateData] = useState(false)
  const [openCompleteModal, setOpenCompleteModal] = useState(false)
  const [remarkType, setRemarkType] = useState<any>([])
  const [intervention, setIntervention] = useState<any>([])

  const [state, setState] = useState<any>([])
  const [date, setDate] = useState<DateType>({ startDate: null, endDate: null })
  const [formData, setFormData] = useState({
    title: "",
  })
  const { handleSearch, changePage, params, selectLimit } = useTable()

  const [searchParams] = useSearchParams()

  const {
    handleSearch: handleSearchHistory,
    params: paramsHistory,
    changePage: changePageHistory,
    selectLimit: selectLimitHistory,
  } = useTable()

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }
  const onSubmit = async (e: any) => {
    e.preventDefault()
  }

  const handleUpdateBeneficiary = (e: any) => {
    e.preventDefault()
    setGoToUpdateData(true)
    setTimeout(function () {
      setSelectedAction("update-completed")
    }, 5000)
  }

  const currentTab = searchParams.get("category")
  const { data: historyAnalytics, isFetching: isFetchingHistoryAnalytics } = useGetBeneficiaryHistoryAnalyticsQuery({})

  const beneficiaryHistoryDetails = [
    {
      title: "Total Document Uploaded",
      icon: "ph:books",
      count: historyAnalytics?.total_uploaded_documents ?? 0,
    },
    {
      title: "Total Updated Beneficiary",
      icon: "ph:user-switch",
      count: historyAnalytics?.total_updated_records ?? 0,
    },
  ]

  const tabs = [
    {
      title: "Verified Beneficiary",
      component: <p>Verified Beneficiary</p>,
      path: "banked-beneficiary",
    },
    {
      title: "Unverified Beneficiary",
      component: <p>Unverified Beneficiary</p>,
      path: "unbanked-beneficiary",
    },
  ]

  const breadCrumbs = [{ title: "Beneficiary" }, { title: "beneficiary Update History" }]

  const [getBeneficiaries, { isFetching: isExporting }] = useLazyGetBeneficiariesExportQuery()
  const [getBeneficiariesUrl, { isFetching: isDownloading }] = useLazyGetBeneficiariesUrlQuery()
  const { data: beneficiaryHistory, isFetching: isFetchingHistory } = useGetBeneficiaryHistoryQuery({
    limit: paramsHistory.limit,
    search: paramsHistory.search,
    page: paramsHistory.page,
    from: shortDate(date.startDate) ?? shortDate(date.endDate) ?? "",
    to: shortDate(date.endDate) ?? shortDate(date.startDate) ?? "",
  })

  const { data: beneficiaries, isFetching: isFetchingBeneficiaries } = useGetBeneficiariesQuery({
    ...params,
    type: currentTab === "unbanked-beneficiary" ? "unbanked" : "banked",
    sortBy: "",
    state: state.length ? stringifyArray(state) : "",
    remark: remarkType?.value,
    intervention: intervention?.value,
  })

  const { data: interventions, isFetching: isFetchingInterventions } = useGetInterventionsQuery({
    status: "",
  })

  const interventionsOptions = useMemo(() => {
    const options =
      interventions?.interventions?.map((intervention: any) => {
        return {
          label: intervention?.intervention_title,
          value: intervention?.intervention_id,
        }
      }) || []
    options.unshift({ label: "All", value: "" })

    return options
  }, [interventions])

  const remarkOptions = [
    {
      label: "All",
      value: "",
    },
    {
      label: "No BVN",
      value: "no bvn",
    },
    {
      label: "No Bank Code",
      value: "no bank code",
    },
    {
      label: "No Bank Account",
      value: "no bank account",
    },
    {
      label: "No Bank Name",
      value: "no bank name",
    },
    {
      label: "No Account Name",
      value: "no account name",
    },
    {
      label: "No Account Number",
      value: "no account number",
    },
  ]

  const { data: analytics, isFetching: isFetchingAnalytics } = useGetBeneficiaryAnalyticsQuery({})

  const beneficiaryDetails = [
    {
      title: "Total Beneficiaries",
      icon: "ph:users-three",
      count: analytics?.total_beneficiaries ?? 0,
    },
    {
      title: "Total Verified Beneficiaries",
      icon: "ph:handshake",
      count: analytics?.total_banked_beneficiaries ?? 0,
    },
    {
      title: "Total Unverified Beneficiaries",
      icon: "ph:database",
      count: analytics?.total_unbanked_beneficiaries ?? 0,
    },
    {
      title: "Beneficiary Update History",
      icon: "ph:book-open",
    },
  ]

  const exportBeneficiaries = async () => {
    try {
      const res = await getBeneficiaries({
        limit: Number.MAX_SAFE_INTEGER,
        page: 1,
        export: true,
        search: params?.search,
        remark: remarkType?.value,
        intervention: intervention?.value,
        state: state.length ? stringifyArray(state) : "",
        type: currentTab === "banked-beneficiary" ? "banked" : "unbanked",
      }).unwrap()

      if (res.status === "success" && res.data) {
        const downloadUrl = res.data
        window.location.href = downloadUrl
      } else {
        toast.error(`Failed to export beneficiaries: ${res.message}`)
      }
    } catch (error: any) {
      toast.error(`An error occurred while exporting beneficiaries: ${error.message || error}`)
    }
  }

  // eslint-disable-next-line no-unused-vars
  const handleDownload = async (url: string, setOpenCompleteUpdateModal?: any) => {
    try {
      if (url) {
        const downloadUrl = url
        window.location.href = downloadUrl
        setOpenCompleteUpdateModal(false)
      } else {
        toast.error("Failed to download inconsistent data")
      }
    } catch (error: any) {
      toast.error("An error occurred while exporting beneficiaries:", error)
    }
  }

  const handleDownloadSignedUrl = async (url: string) => {
    try {
      const res: any = await getBeneficiariesUrl({
        key: url,
      }).unwrap()
      if (res.status === "success" && res.data) {
        const downloadUrl = res.data
        window.location.href = downloadUrl
        toast.success("Download url generated")
      } else {
        toast.error("Failed to export beneficiaries:", res.message)
      }
    } catch (error: any) {
      toast.error("An error occurred while exporting beneficiaries:", error)
    }
  }
  useEffect(() => {
    setRemarkType([])
    setState([])
    setDate({ startDate: null, endDate: null })
    setIntervention([])
  }, [currentTab])
  return {
    beneficiaryDetails,
    selectedStatus,
    state,
    params,
    changePage,
    handleSearch,
    setState,
    setSelectedStatus,
    tabs,
    currentTab,
    selectedAction,
    setSelectedAction,
    formData,
    setFormData,
    handleChange,
    setGoToUpdateData,
    goToUpdateData,
    openCompleteModal,
    setOpenCompleteModal,
    handleUpdateBeneficiary,
    onSubmit,
    breadCrumbs,
    beneficiaryHistoryDetails,
    date,
    setDate,
    beneficiaries,
    isFetchingBeneficiaries,
    analytics,
    isFetchingAnalytics,
    selectLimit,
    exportBeneficiaries,
    isExporting,
    remarkType,
    setRemarkType,
    remarkOptions,
    handleDownload,
    beneficiaryHistory,
    isFetchingHistory,
    changePageHistory,
    paramsHistory,
    selectLimitHistory,
    handleSearchHistory,
    handleDownloadSignedUrl,
    isDownloading,
    isFetchingHistoryAnalytics,
    interventionsOptions,
    setIntervention,
    isFetchingInterventions,
  }
}
