import { Button, Calendar, Dropdown, Input, Textarea } from "components/core"
import { genders, loanStatus } from "constants/global"
import dayjs from "dayjs"
import { LOAN_STATUS } from "types/livelihood"
import useSingleMember from "./useSingleMember"
import { BVN_LENGTH, NIN_LENGTH } from "constants/validation"

interface Props {
  onClose: () => void
  member?: any
}

export const SingleMember = ({ onClose, member }: Props) => {
  const state = useSingleMember({ member, onClose })

  return (
    <form className="relative" onSubmit={state.submit}>
      <div className="modal-right-body !h-[75vh]">
        <Input
          label="First Name"
          type="text"
          id="firstName"
          name="firstName"
          // readOnly={loading}
          placeholder="Enter Member's First Name"
          value={state.formData?.firstName}
          onChange={state.handleChange}
          className="mb-[16px]"
        />
        <Input
          label="Last Name"
          type="text"
          id="lastName"
          name="lastName"
          // readOnly={loading}
          placeholder="Enter Member's Last Name"
          value={state.formData?.lastName}
          onChange={state.handleChange}
          className="mb-[16px]"
        />
        <Dropdown
          label="Gender"
          placeholder="-Select Gender-"
          options={genders.slice(1)}
          selectInput={true}
          search={false}
          selected={state.formData.gender}
          onSelect={(data: any) => {
            state.setFormData({ ...state.formData, gender: data.value })
          }}
        />
        <div className="grid grid-cols-2 gap-[24px] mb-[16px]">
          <Input
            label="NIN"
            type="text"
            id="nin"
            name="nin"
            maxLength={NIN_LENGTH}
            // readOnly={loading}
            placeholder="Enter NIN"
            value={state.formData?.nin}
            onChange={state.handleChange}
          />
          <Input
            label="BVN"
            type="text"
            id="bvn"
            name="bvn"
            maxLength={BVN_LENGTH}
            // readOnly={loading}
            placeholder="Enter BVN"
            value={state.formData?.bvn}
            onChange={state.handleChange}
          />
        </div>
        <Input
          label="Savings Per Cycle"
          type="number"
          id="savingPerCycle"
          name="savingPerCycle"
          // readOnly={loading}
          placeholder="Enter Amount"
          value={state.formData?.savingPerCycle}
          onChange={state.handleChange}
          className="mb-[16px]"
        />
        <Input
          label="Amount Saved"
          type="number"
          id="amountSaved"
          name="amountSaved"
          // readOnly={loading}
          placeholder="Enter Amount"
          value={state.formData?.amountSaved}
          onChange={state.handleChange}
          className="mb-[16px]"
        />
        <Input
          label="Loan Collected"
          type="number"
          id="loanCollected"
          name="loanCollected"
          // readOnly={loading}
          placeholder="Enter Amount"
          value={state.formData?.loanCollected}
          onChange={state.handleChange}
          className="mb-[16px]"
        />
        {state.formData?.loanCollected && (
          <>
            <Calendar
              value={state.formData.loanDate}
              placeholder="Select Date"
              label="Loan Date"
              minDate={new Date()}
              id="startDate"
              containerVariant="mb-[16px]"
              onChange={(e) => state.setFormData({ ...state.formData, loanDate: dayjs(e).format("YYYY-MM-DD") })}
            />
            <Dropdown
              label="Loan Status"
              placeholder="-Select Status-"
              options={loanStatus}
              selectInput={true}
              search={false}
              selected={state.formData.repaymentStatus}
              wrapperClassName="mb-[16px]"
              onSelect={(data: any) => {
                state.setFormData({ ...state.formData, repaymentStatus: data.value })
              }}
            />
          </>
        )}
        {state.formData.repaymentStatus === LOAN_STATUS.DEFAULTER && (
          <Textarea
            label="Reason for Defaulting"
            id="reasonForDefaulting"
            name="reasonForDefaulting"
            rows={2}
            placeholder="Type reason for defaulting..."
            // readOnly={loading}
            value={state.formData?.reasonForDefaulting}
            onChange={state.handleChange}
            maxLength={320}
          />
        )}
        <Textarea
          label="Remark"
          id="remark"
          name="remark"
          rows={2}
          placeholder="Type remark..."
          // readOnly={loading}
          value={state.formData?.remark}
          onChange={state.handleChange}
          maxLength={320}
        />
      </div>
      <div className="modal-right-button-container">
        <Button
          theme="primary"
          size="44"
          className="min-w-[105px] mr-[8px] capitalize"
          disabled={state.buttonStatus}
          loading={state.loading}
        >
          {member ? "Save Changes" : "Create Member"}
        </Button>
        <Button
          theme="transparent"
          size="44"
          className="min-w-[105px] mr-[8px] capitalize"
          type="button"
          disabled={state.loading}
          onClick={onClose}
        >
          Discard
        </Button>
      </div>
    </form>
  )
}
