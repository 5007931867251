export const monthlyChartLabels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

export const defaultOptions = {
  plugins: {
    legend: {
      display: false,
      align: "start",
      position: "bottom",
    },
    datalabels: {
      formatter: function () {
        return ``
      },
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      display: false, // Remove y-axis labels
    },
  },
}

export const reportChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: false, // Hide the legend
    },
    tooltip: {
      callbacks: {
        label: function (tooltipItem: any) {
          return `${tooltipItem.raw}%` // Only show the percentage in the tooltip
        },
      },
    },
    datalabels: {
      color: "#FFFFFF", // Set the label text color to white
      font: {
        weight: "bold",
        size: 14,
      },
      formatter: (value: any) => `${value}%`, // Format the labels to show percentages
    },
  },
}

export const lineChartOptions = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
        // color: 'rgba(0, 0, 0, .04)',
      },
      ticks: {
        display: true,
        beginAtZero: true,
      },
    },
    y: {
      grid: {
        display: true,
        // color: 'rgba(0, 0, 0, .04)',
      },
      ticks: {
        display: true,
        beginAtZero: true,
      },
    },
  },
}
