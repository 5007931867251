import { useEffect, useMemo, useState } from "react"
import { useTable } from "hooks"
import { useNavigate, useSearchParams } from "react-router-dom"
import { Icon } from "@iconify/react"
import { tryCatch } from "utils/tryCatch"
import {
  useChangeConfigurationStatusMutation,
  useDeleteConfigurationMutation,
  useGetConfigurationsQuery,
} from "services/api/configurationSlice"
import { CONFIG_TYPE, Configuration } from "types/configuration"
import { toast } from "react-toastify"
import { usePermission } from "hooks/usePermission/usePermission"
import { AllPermissions, PermissionModule } from "types/permission"

export default function useConfiguration() {
  const { CONFIGURATION } = PermissionModule
  const { UPDATE_SINGLE_CONFIG, VIEW_SINGLE_CONFIG, DELETE_CONFIG } = AllPermissions
  const { hasModulePermission } = usePermission()
  const { handleSearch, params, resetParams, searchValue } = useTable()
  const navigate = useNavigate()
  const [selectedStatus, setSelectedStatus] = useState<any>([])
  const [selectedAction, setSelectedAction] = useState("")
  const [selectedConfiguration, setSelectedConfiguration] = useState<any>()
  const [searchParams] = useSearchParams()
  const currentTab = searchParams.get("category")
  const configurationType =
    currentTab === "PSP"
      ? CONFIG_TYPE.psp
      : currentTab === "verification-platform"
      ? CONFIG_TYPE.verification_platform
      : CONFIG_TYPE.source_register
  const breadCrumbs = [{ title: "Settings" }, { title: "Configuration Settings" }]

  const [changeConfigurationStatus, { isLoading: isChangingStatus }] = useChangeConfigurationStatusMutation()
  const [deleteConfiguration, { isLoading: isDeletingConfiguration }] = useDeleteConfigurationMutation()
  const { data: configurations, isFetching: isFetchingConfigurations } = useGetConfigurationsQuery(
    { configurationType, status: selectedStatus.value, ...params },
    { skip: !currentTab || !hasModulePermission(CONFIGURATION) },
  )

  const filterOptions = [
    { label: "All", value: "" },
    { label: "Connected", value: "connected" },
    { label: "Disconnected", value: "disconnected" },
  ]

  const tabs = [
    {
      title: "Verification Platform",
      path: "?category=verification-platform",
      active: "verification-platform",
    },
    {
      title: "PSP",
      path: "?category=PSP",
      active: "PSP",
    },
    {
      title: "Source Register",
      path: "?category=source-register",
      active: "source-register",
    },
  ]

  const manageConfiguration = () => {
    tryCatch(async () => {
      const callback = (res: any) => {
        if (res?.code === 200) {
          setSelectedAction("")
          toast.success(res?.message)
        }
      }
      if (selectedAction === "delete") {
        const res = await deleteConfiguration(selectedConfiguration.configuration_id).unwrap()
        callback(res)
      }
      if (["connect", "disconnect"].includes(selectedAction)) {
        const status = ["inactive", "disconnected"].includes(selectedConfiguration.status)
          ? "connected"
          : "disconnected"
        const res = await changeConfigurationStatus({
          configId: selectedConfiguration.configuration_id,
          status,
        }).unwrap()
        callback(res)
      }
    })
  }

  const selectActionProxy = (action: string, data: any) => {
    setSelectedAction(action)
    setSelectedConfiguration(data)
  }

  const updateConfigStatus = async (data: Configuration) => {
    const status = data?.status === "disconnected" ? "connected" : "disconnected"
    const res = await changeConfigurationStatus({ configId: data?.configuration_id, status }).unwrap()
    if (res?.code === 200) toast.success(res?.message)
    setSelectedAction("")
  }

  const cardActions = [
    {
      name: "Edit Connection",
      icon: <Icon icon="ph:pencil-line" className="w-[24px] h-[24px] mr-[14.5px]" />,
      method: (data: any) => selectActionProxy("edit", data),
      permission: UPDATE_SINGLE_CONFIG,
    },
    {
      name: "View Connection",
      icon: <Icon icon="ph:eye" className="w-[24px] h-[24px] mr-[14.5px]" />,
      method: (data: any) => selectActionProxy("view", data),
      permission: VIEW_SINGLE_CONFIG,
    },
    {
      name: "Disconnect",
      icon: <Icon icon="mdi:arrow" className="w-[24px] h-[24px] mr-[14.5px]" />,
      method: (data: any) => updateConfigStatus(data),
      permission: UPDATE_SINGLE_CONFIG,
    },
    {
      name: "Connect",
      icon: <Icon icon="mdi:arrow" className="w-[24px] h-[24px] mr-[14.5px]" />,
      method: (data: any) => updateConfigStatus(data),
      permission: UPDATE_SINGLE_CONFIG,
    },
    {
      name: "Delete",
      icon: <Icon icon="ph:trash" className="w-[24px] h-[24px] mr-[14.5px]" />,
      method: (data: any) => selectActionProxy("delete", data),
      permission: DELETE_CONFIG,
    },
  ]

  const buttonName = useMemo(() => {
    if (currentTab === "verification-platform") return "New"
    if (currentTab === "PSP") return "PSP"
    return "Register"
  }, [currentTab])

  const resetFilters = () => {
    resetParams()
    setSelectedStatus([])
  }

  useEffect(() => {
    if (!currentTab) navigate(tabs[0].path)
  }, [])

  return {
    breadCrumbs,
    handleSearch,
    selectedStatus,
    setSelectedStatus,
    filterOptions,
    selectedAction,
    setSelectedAction,
    selectedConfiguration,
    tabs,
    currentTab,
    configurations,
    cardActions,
    buttonName,
    setSelectedConfiguration,
    manageConfiguration,
    configurationType,
    isFetchingConfigurations,
    isDeletingConfiguration,
    updateConfigStatus,
    isChangingStatus,
    resetFilters,
    searchValue,
    params,
  }
}
