import React from "react"
import { Icon } from "@iconify/react"

interface SearchInputProps {
  id: string
  name: string
  placeholder?: string
  value?: string
  // eslint-disable-next-line no-unused-vars
  onChange: any
  readOnly?: boolean
  defaultValue?: string
  pattern?: string
  inputMode?: "none" | "text" | "tel" | "url" | "email" | "numeric" | "decimal" | "search"
  className?: string
}

export const Search = React.memo(function Search({
  id,
  name,
  placeholder,
  value,
  onChange,
  readOnly,
  defaultValue,
  pattern,
  inputMode,
  className,
}: SearchInputProps) {
  return (
    <div className={`relative ${className}`}>
      <input
        id={id}
        name={name}
        type="search"
        placeholder={placeholder}
        value={value}
        defaultValue={defaultValue}
        inputMode={inputMode}
        pattern={pattern}
        data-testid="search"
        aria-labelledby={id}
        onChange={onChange}
        readOnly={readOnly}
        className={`
                h-[42px] pl-[40px] pr-[8px] text-headers bg-[#F9F9FB]
                text-base md:min-w-[288px] w-full outline-0 border border-[#E3E5EB] font-normal hide_tap
                rounded-[4px] focus:border-primary-200
                `}
      />
      <div className="flex items-center absolute top-0 left-0 cursor-pointer hide_tap h-full">
        <Icon icon="ph:magnifying-glass" className="h-6 w-6 left-3  text-[#D1D4DE] inset-x-0 absolute z-10" />
      </div>
    </div>
  )
})
