import { createApi } from "@reduxjs/toolkit/query/react"
import { axiosBaseQuery } from "../axiosBaseQuery"
import { FetchedCoresponsibility } from "types/coResponsibility"
import { GET_ALL_CORESPONSIBILITY } from "constants/api"

const baseUrl = process.env.REACT_APP_BASE_URL ?? ""

export const coResponsibilitySlice = createApi({
  reducerPath: "coResponsibilitySlice",
  baseQuery: axiosBaseQuery({ baseUrl }),
  tagTypes: ["coResponsibilities", "beneficiaryList"],
  refetchOnMountOrArgChange: true,
  endpoints(build) {
    return {
      getAllCoresponsibility: build.query<FetchedCoresponsibility, any>({
        query: (params) => ({ url: `${GET_ALL_CORESPONSIBILITY}`, method: "get", params }),
        transformResponse: (response: any) => response.data,
        providesTags: ["coResponsibilities"],
      }),
      getInterventionBeneficiaryDetails: build.query<any, any>({
        query: ({ interventionId, ...rest }) => ({
          url: `/co-responsibility/${interventionId}`,
          method: "get",
          params: rest,
        }),
        transformResponse: (response: any) => response.data,
        providesTags: ["beneficiaryList"],
      }),
      getInterventionBeneficiaryHistory: build.query<any, any>({
        query: ({ beneficiaryId, ...rest }) => ({
          url: `/livelihoods/intervention-history/${beneficiaryId}`,
          method: "get",
          params: rest,
        }),
        transformResponse: (response: any) => response.data,
      }),
      exportInterventionBeneficiaryHistory: build.query<any, any>({
        query: ({ beneficiaryId, ...rest }) => ({
          url: `/livelihoods/intervention-history/${beneficiaryId}?noLimit=true`,
          method: "get",
          params: rest,
        }),
        transformResponse: (response: any) => response.data,
      }),
    }
  },
})

export const {
  useGetAllCoresponsibilityQuery,
  useLazyGetAllCoresponsibilityQuery,
  useGetInterventionBeneficiaryDetailsQuery,
  useGetInterventionBeneficiaryHistoryQuery,
  useLazyExportInterventionBeneficiaryHistoryQuery,
} = coResponsibilitySlice
