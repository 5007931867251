import { completeDataSourceHeaders, incompleteDataSourceHeaders } from "constants/data"
import { useTable } from "hooks"
import { useEffect, useState } from "react"
import { useSearchParams, useParams, useNavigate } from "react-router-dom"
import { useGetMineHistoryQuery } from "services/api/dataSlice"
import { useGetSingleDataSourceQuery } from "services/api/dataSlice"
import { DateType } from "types/data"
import { fullDateFormatter, shortDate } from "utils/dateFormatter"

export default function useViewDataSource() {
  const { handleSearch, params, changePage, selectLimit, setParams } = useTable()
  const [selectedStatus, setSelectedStatus] = useState<any>([])

  const [date, setDate] = useState<DateType>({ startDate: null, endDate: null })

  // const [params] = useState<Params>({ page: 1, per_page: 10 })

  const filterOptions = [
    { label: "All", value: "" },
    { label: "Pending", value: "pending" },
    { label: "Failed", value: "failed" },
  ]

  const tabs = [
    {
      title: "Complete Data",
      path: "complete-data",
      component: <p>Complete</p>,
    },
    {
      title: "Incomplete Data",
      path: "incomplete-data",
      component: <p>Incomplete</p>,
    },
  ]

  const [searchParams] = useSearchParams()
  const currentTab = searchParams.get("category")
  const { id } = useParams()

  const {
    data: history,
    isFetching: isFetchingHistory,
    refetch: refetchMineHistory,
  } = useGetMineHistoryQuery({
    ...params,
    sortBy: "",
    source_id: id,
    status: selectedStatus?.value,
    from: shortDate(date.startDate) ?? shortDate(date.endDate) ?? "",
    to: shortDate(date.endDate) ?? shortDate(date.startDate) ?? "",
    type: currentTab === "complete-data" ? "complete" : "incomplete",
  })

  const {
    data: singleDataSourceDetails,
    isLoading: isFetchingSingleDataSourceDetails,
    refetch: refetchSingleDataSourceDetails,
  } = useGetSingleDataSourceQuery({
    configId: id,
  })
  const batches = history
    ? history.mineHistory.reduce((acc: any[], curr) => {
        if (curr.batches) {
          return acc.concat(curr.batches)
        }
        return acc
      }, [])
    : []

  const navigate = useNavigate()
  useEffect(() => {
    if (!currentTab) {
      navigate("?category=complete-data")
    }
  }, [])

  useEffect(() => {
    refetchMineHistory()
  }, [currentTab, refetchMineHistory])

  const breadCrumbs = [
    { title: "Data Exchange and Quality Assurance" },
    { title: singleDataSourceDetails?.configuration_name ?? "" },
  ]

  const viewDataResourceCardDetails = {
    loading: isFetchingSingleDataSourceDetails,
    header: singleDataSourceDetails?.configuration_name,
    subTitle: singleDataSourceDetails?.description,
    dateCreated: fullDateFormatter(singleDataSourceDetails?.created_at),
    lastUpdated: fullDateFormatter(singleDataSourceDetails?.updated_at),
    totalMinedData: singleDataSourceDetails?.mine_count,
    logo: singleDataSourceDetails?.logo,
  }

  const historyTableProps = {
    currentTab,
    completeDataSourceHeaders,
    incompleteDataSourceHeaders,
    history,
    changePage,
    params,
    isFetchingHistory,
    selectLimit,
    batches,
  }

  const filtersProps = {
    currentTab,
    handleSearch,
    filterOptions,
    selectedStatus,
    setSelectedStatus,
    date,
    setDate,
  }

  const dataTabProps = {
    tabs,
    currentTab,
    setParams,
    params,
    setDate,
    setSelectedStatus,
  }

  return {
    breadCrumbs,
    filterOptions,
    tabs,
    currentTab,
    date,
    setDate,
    history,
    isFetchingHistory,
    singleDataSourceDetails,
    isFetchingSingleDataSourceDetails,
    handleSearch,
    selectedStatus,
    setSelectedStatus,
    changePage,
    refetchMineHistory,
    selectLimit,
    setParams,
    params,
    refetchSingleDataSourceDetails,
    batches,
    viewDataResourceCardDetails,
    historyTableProps,
    filtersProps,
    dataTabProps,
  }
}
