import { Icon } from "@iconify/react"
import { Dropdown } from "components/core"
import { defaultFilter } from "constants/global"
import { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { useGetAllWardQuery, useLazyGetLgaInStateQuery } from "services/api/commonSlice"
import { convertStateToDropdownOptions } from "utils/dropdown"
import { tryCatch } from "utils/tryCatch"

interface Props {
  state: any
}

export const Location = ({ state }: Props) => {
  const { formData, setFormData } = state
  const [location, setLocation] = useState<any>({ lga: [], states: [] })
  const { states } = useSelector((state: any) => state.common)

  const { data: ward, isFetching: isFetchingWard } = useGetAllWardQuery("")
  const [getLgaInStateQuery, { isFetching: isFetchingLga }] = useLazyGetLgaInStateQuery()

  const getLgaInState = () => {
    const stateIds = formData.states.map((item: any) => item.id).join(",")
    tryCatch(async () => {
      const res = await getLgaInStateQuery({ ids: stateIds }).unwrap()
      const options = res?.map((item: any) => ({
        label: item?.name,
        value: item?.name,
        id: item.id,
      }))
      setLocation((prevLocation: any) => ({
        ...prevLocation,
        lga: [...defaultFilter, ...options],
      }))
    })
  }

  useEffect(() => {
    if (formData.states?.length) {
      getLgaInState()
      return
    }
    setLocation((prevLocation: any) => ({ ...prevLocation, lga: [] }))
    setFormData((prevFormData: any) => ({ ...prevFormData, lga: [] }))
  }, [formData.states?.length])

  const wardOptions = useMemo(() => {
    return ward?.length
      ? ward?.map((item: any) => {
          return { label: item.ward_declared, value: item.ward_declared }
        })
      : []
  }, [ward])

  return (
    <div>
      <p className="mb-[8px] font-medium text-sm text-headers">
        <Icon icon="ph:pencil-line" className="inline w-[20px] h-[20px]" /> LOCATION
      </p>
      <div className="pl-[24px] w-full mb-[20px] rounded-sm">
        <table className="w-full">
          <thead>
            <tr className="text-left border text-xs text-body">
              <th className="p-[8px] font-medium border-r text-start w-[270px]">State</th>
              <th className="p-[8px] font-medium text-start w-[270px]">Local Government</th>
              <th className="p-[8px] font-medium text-start w-[270px]">Ward</th>
            </tr>
          </thead>
          <tbody>
            <tr className="border text-sm text-body">
              <td className="border-r">
                <Dropdown
                  selected={formData.states}
                  containerVariant="!border-none !shadow-none"
                  wrapperClassName="!mb-0"
                  options={[...defaultFilter, ...convertStateToDropdownOptions(states)]}
                  enableSearchOptions={true}
                  multiple={true}
                  count={true}
                  onSelect={(data: any) => setFormData({ ...formData, states: data })}
                />
              </td>
              <td className="border-r">
                <Dropdown
                  selected={formData.lga}
                  containerVariant="!border-none !shadow-none"
                  wrapperClassName="!mb-0"
                  loading={isFetchingLga}
                  options={location.lga}
                  enableSearchOptions={true}
                  multiple={true}
                  count={true}
                  onSelect={(data: any) => setFormData({ ...formData, lga: data })}
                />
              </td>
              <td>
                <Dropdown
                  selected={formData.ward}
                  containerVariant="!border-none !shadow-none"
                  wrapperClassName="!mb-0"
                  options={[...defaultFilter, ...wardOptions]}
                  enableSearchOptions={true}
                  loading={isFetchingWard}
                  multiple={true}
                  count={true}
                  onSelect={(data: any) => setFormData({ ...formData, ward: data })}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}
