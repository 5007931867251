import { useEffect, useMemo, useState } from "react"
import { useGetPermissionsQuery } from "services/api/commonSlice"

interface Permission {
  module: string
  action: string[]
  availableActions: string[]
}

export default function useAssignPermissions(prevState: Permission[]) {
  const { data: allPermissions, isLoading: isLoadingPermissions } = useGetPermissionsQuery("")
  const [permissions, setPermissions] = useState<Permission[]>([])

  const selectPermission = (isSelected: boolean, action: string, module: string) => {
    const updatedPermissions = permissions.map((item) => {
      if (item.module === module) {
        return {
          ...item,
          module: item.module,
          action: isSelected ? [...item.action, action] : item.action.filter((item) => item !== action),
        }
      }
      return item
    })
    setPermissions(updatedPermissions)
  }

  const toggleSelectAllActions = (isSelected: boolean, module: string) => {
    const updatedPermissions = permissions.map((item) => {
      if (item.module === module) {
        return {
          ...item,
          module: item.module,
          action: isSelected ? item?.availableActions : [],
        }
      }
      return item
    })
    setPermissions(updatedPermissions)
  }

  useEffect(() => {
    if (allPermissions?.length) {
      const enhanced = allPermissions.map((item: any) => {
        return { module: item.module, availableActions: item.action, action: [] }
      })
      setPermissions(enhanced)
    }
  }, [allPermissions])

  useEffect(() => {
    if (permissions?.length && prevState) {
      let newState = permissions?.map((permission) => {
        for (let i = 0; i < prevState.length; i++) {
          if (prevState[i].module === permission.module) {
            return {
              module: permission?.module,
              action: prevState[i]?.action,
              availableActions: permission.availableActions,
            }
          }
        }
        return permission
      })
      setPermissions(newState)
    }
  }, [prevState, permissions?.length])

  const enhancedPermission = useMemo(() => {
    return permissions
      ?.map((item) => {
        if (item.action.length) {
          return {
            module: item.module,
            action: item.action,
          }
        }
        return null
      })
      .filter((item) => item !== null)
  }, [permissions])

  return { permissions, enhancedPermission, selectPermission, toggleSelectAllActions, isLoadingPermissions }
}
