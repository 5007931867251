export const duration = [
  { label: "Months", value: "months" },
  { label: "Years", value: "years" },
]

export const genders = [
  { label: "All", value: "" },
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
]

export const paymentStatus = [
  {
    value: "",
    label: "All",
  },
  {
    value: "paid",
    label: "Paid",
  },
  {
    value: "unpaid",
    label: "Unpaid",
  },
  {
    value: "in-progress",
    label: "In-Progress",
  },
]

export const interventionStatus = [
  {
    label: "All",
    value: "",
  },
  {
    label: "Pending",
    value: "pending_approval",
  },
  {
    label: "In-progress",
    value: "in-progress",
  },
  {
    label: "Completed",
    value: "completed",
  },
]

export const loanStatus = [
  {
    label: "Paid",
    value: "paid",
  },
  {
    label: "Defaulter",
    value: "defaulter",
  },
  {
    label: "No loan collected",
    value: "no-loan-collected",
  },
  {
    label: "In progress",
    value: "in-progress",
  },
]

export const period = [
  {
    label: "Monthly",
    value: "monthly",
  },
  {
    label: "Yearly",
    value: "yearly",
  },
  {
    label: "Custom",
    value: "custom",
  },
]

export const monthSummaryOptions = [
  {
    label: "January",
    value: "01",
  },
  {
    label: "February",
    value: "02",
  },
  {
    label: "March",
    value: "03",
  },
  {
    label: "April",
    value: "04",
  },
  {
    label: "May",
    value: "05",
  },
  {
    label: "June",
    value: "06",
  },
  {
    label: "July",
    value: "07",
  },
  {
    label: "August",
    value: "08",
  },
  {
    label: "September",
    value: "09",
  },
  {
    label: "October",
    value: "10",
  },
  {
    label: "November",
    value: "11",
  },
  {
    label: "December",
    value: "12",
  },
]

export const paymentPSP = [{ label: "Remita", value: "REMITA" }]

export const paymentCycle = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
]

export const defaultFilter = [
  {
    label: "All",
    value: "",
  },
]
