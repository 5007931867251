import Livelihood from "pages/livelihood/Livelihood"
import ViewLivelihood from "pages/livelihood/ViewLivelihood/ViewLivelihood"
import Community from "pages/livelihood/Community/Community"
import SingleCommunity from "pages/livelihood/SingleCommunity/SingleCommunity"
import SingleGroup from "pages/livelihood/SingleGroup/SingleGroup"
import GroupMember from "pages/livelihood/GroupMember/GroupMember"
import ViewLivelihoodBeneficiary from "pages/livelihood/ViewLivelihoodBeneficiary/ViewLivelihoodBeneficiary"
import { Restricted } from "components/hoc"
import { AllPermissions, PermissionModule } from "types/permission"

const { LIVELIHOOD } = PermissionModule
const { VIEW_SINGLE_COMMUNITY, VIEW_SINGLE_GROUP } = AllPermissions

export const livelihoodRoutes = [
    {
        path: "livelihood",
        element: <Livelihood />,
    },
    {
        path: "livelihood/:id",
        element: <ViewLivelihood />,
    },
    {
        path: "livelihood/:id/:beneficiary_id",
        element: <ViewLivelihoodBeneficiary />,
    },
    {
        path: "livelihood/community",
        element: <Community />,
    },
    {
        path: 'livelihood/community/:id',
        element: (
            <Restricted module={LIVELIHOOD} permission={VIEW_SINGLE_COMMUNITY} showError={true}>
                <SingleCommunity />
            </Restricted>
        )
    },
    {
        path: 'livelihood/community/:id/group/:id',
        element: (
            <Restricted module={LIVELIHOOD} permission={VIEW_SINGLE_GROUP} showError={true}>
                <SingleGroup />
            </Restricted>
        )
    },
    {
        path: 'livelihood/community/:id/group/:id/member/:id',
        element: <GroupMember />
    },
]