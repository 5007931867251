import { useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { toast } from "react-toastify"
import { useGetDepartmentsQuery } from "services/api/commonSlice"
import { useCreateInterventionMutation, useEditInterventionMutation } from "services/api/interventionSlice"
import { removeKeysWithEmptyValues } from "utils/objectFormatter"
import { tryCatch } from "utils/tryCatch"

interface Props {
  formData: any
}

export default function useReview({ formData }: Props) {
  const [showMore, setShowMore] = useState<boolean>(false)
  const navigate = useNavigate()
  const [confirmIntervention, setConfirmIntervention] = useState(false)
  const [searchParams] = useSearchParams()
  const id = searchParams.get("interventionId")

  const quotaStructure = Object.entries(formData.quota_structure).map(([key, value]) => ({
    state: key,
    quota: value,
  }))

  const [createIntervention, { isLoading: isCreatingIntervention }] = useCreateInterventionMutation()
  const [editIntervention, { isLoading: isEditingIntervention }] = useEditInterventionMutation()
  const { data: departments } = useGetDepartmentsQuery({})
  const new_quota_structure = formData.states.reduce((acc: any, state: any) => {
    const { id, value } = state
    if (formData.quota_structure[value] !== undefined) {
      acc[id] = formData.quota_structure[value]
    }
    return acc
  }, {})

  const createEditIntervention = async () => {
    const callback = (res: any) => {
      if ([200, 201].includes(res?.code)) {
        navigate("/intervention")
        toast.success(res?.message)
      }
    }
    const selectedDepartments = departments
      ?.filter((dept: any) => formData.departments.some((item: any) => item.value === dept.name))
      .map((zone: any) => zone.id)
    const selectedZones = formData.zones.map((item: any) => item.id)
    const selectedStates = formData.states.map((item: any) => item.id)
    const selectedLgas = formData.lga.map((item: any) => item.id)

    const data = removeKeysWithEmptyValues({
      ...formData,
      zones: selectedZones,
      states: selectedStates,
      lga: selectedLgas,
      departments: selectedDepartments,
      data_source: formData.data_source,
      quota_structure: new_quota_structure,
    })
    tryCatch(async () => {
      let res
      if (!id) {
        res = await createIntervention(data).unwrap()
      } else {
        res = await editIntervention({ id, data }).unwrap()
      }
      callback(res)
    })
  }

  return {
    setShowMore,
    createEditIntervention,
    showMore,
    isEditingIntervention,
    quotaStructure,
    setConfirmIntervention,
    isCreatingIntervention,
    confirmIntervention,
  }
}
