import { Breadcrumb, Button, Calendar, Search, Table } from "components/core"
import useSingleCommunity from "./useSingleCommunity"
import { Icon } from "@iconify/react"
import { singleCommunityHeader } from "constants/livelihood"
import { AddNewGroup } from "./components/AddNewGroup/AddNewGroup"
import { RenderIf, Restricted } from "components/hoc"
import HeaderLoader from "components/core/Loader/HeaderLoader/HeaderLoader"
import { fullDateFormatter } from "utils/dateFormatter"
import { useNavigate } from "react-router-dom"
import { AllPermissions, PermissionModule } from "types/permission"
import { formatCurrency } from "utils/formatCurrency"
import { usePermission } from "hooks"

const { LIVELIHOOD } = PermissionModule
const { ADD_GROUP, VIEW_SINGLE_GROUP } = AllPermissions

export default function SingleCommunity() {
  const { hasPermission } = usePermission()
  const navigate = useNavigate()
  const communityState = useSingleCommunity()

  return (
    <main data-testid="single-community">
      <Breadcrumb options={communityState.breadCrumbs} showBack={true} />
      <RenderIf condition={communityState?.isLoadingGroups}>
        <HeaderLoader />
      </RenderIf>
      <RenderIf condition={!communityState?.isLoadingGroups}>
        <>
          <div className="mb-[23px]">
            <h1 className="text-headers text-2xl font-bold mb-[24px]">
              {communityState?.community?.community?.community_name}
            </h1>
            <div className="flex items-center py-[24px] border-y">
              {communityState.overview.map((item) => (
                <div key={item.title} className="flex items-center gap-4 mr-[40px]">
                  <div className="relative w-10 h-10 grid place-items-center bg-grey-100 rounded-full shrink-0">
                    <Icon icon={item.icon} className="w-6 h-6 text-secondary-base" />
                  </div>
                  <div className="grid">
                    <span className="text-sm text-body">{item.title}</span>
                    <p className="text-base font-medium text-headers">{item.count}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div>
            <div className="flex items-center justify-between mb-[16px]">
              <div className="flex">
                <Search
                  id="search"
                  name="search"
                  placeholder="Search"
                  onChange={communityState.handleSearch}
                  className="mr-[8px]"
                />
                <Calendar
                  startDate={communityState.date.startDate}
                  endDate={communityState.date.endDate}
                  onChange={communityState.setDate}
                  selectsRange={true}
                />
              </div>
              <div className="flex space-x-2">
                <Button
                  type="button"
                  size="40"
                  disabled={false}
                  onClick={communityState.exportCommunityProxy}
                  theme="transparent"
                >
                  <Icon icon="uiw:download" className="text-paragraph w-[18px] h-[18px] mr-3" />
                  {communityState?.isExporting ? "Exporting..." : "Export"}
                </Button>
                <Restricted module={LIVELIHOOD} permission={ADD_GROUP}>
                  <Button
                    type="button"
                    size="40"
                    theme="primary"
                    onClick={() => communityState.setSelectedAction("add-group")}
                  >
                    <Icon icon="ph:plus" className="w-[18px] h-[18px] mr-[8px]" />
                    Add New Group
                  </Button>
                </Restricted>
              </div>
            </div>
            <Table
              headers={singleCommunityHeader}
              paginateData={!!communityState.community?.groups}
              data={communityState?.community?.groups || []}
              onPageChange={communityState.changePage}
              selectLimit={communityState.selectLimit}
              totalCount={communityState?.community?.count}
              perPage={communityState.params.limit}
              page={communityState.params.page}
              loading={communityState?.isFetchingGroups}
            >
              <>
                {communityState.community?.groups?.map((item) => (
                  <tr
                    data-testid={item?.group_id}
                    key={item?.group_id}
                    className="cc-custom-table-data-row cursor-pointer"
                    onClick={() =>
                      hasPermission(LIVELIHOOD, VIEW_SINGLE_GROUP) &&
                      navigate(
                        `group/${item?.group_id}?communityName=${communityState?.community?.community?.community_name}`,
                      )
                    }
                  >
                    <td className="flex items-center ">
                      <p title={item?.group_name} className="max-w-[200px] truncate capitalize">
                        {item?.group_name}
                      </p>
                    </td>
                    <td className="capitalize-first">{item?.ward || "N/A"}</td>
                    <td>{item?.member_count || "N/A"}</td>
                    <td>{formatCurrency(item?.total_amount_saved) || "N/A"}</td>
                    <td>{formatCurrency(item?.loan_given) || "N/A"}</td>
                    <td>{item?.duration || "N/A"}</td>
                    <td className="capitalize-first">{fullDateFormatter(item?.created_at)}</td>
                  </tr>
                ))}
              </>
            </Table>
          </div>
        </>
      </RenderIf>
      <RenderIf condition={communityState.selectedAction === "add-group"}>
        <AddNewGroup
          community={communityState?.community?.community}
          onClose={() => communityState.setSelectedAction("")}
        />
      </RenderIf>
    </main>
  )
}
