import { RenderIf } from "components/hoc"
import { ReportPreviewLayout } from "components/layouts/Reports"
import { fullDateFormatter } from "utils/dateFormatter"
import { formatCurrency } from "utils/formatCurrency"

interface Props {
  close: () => void
  state: any
}

export const Preview = ({ close, state }: Props) => {
  const { formData, date } = state

  return (
    <ReportPreviewLayout
      close={close}
      generateReport={state.generateReport}
      loading={state.isGeneratingReport}
      name={state.formData?.reportTitle}
    >
      <>
        <RenderIf condition={!!formData.interventions?.length}>
          <div className="grid grid-cols-2 mb-6">
            <p className="text-base text-body w-[245px]">Intervention title:</p>
            <p className="text-base text-headers">
              {formData.interventions[0]?.label}{" "}
              {!!(formData.interventions?.length - 1) && <span>+{formData.interventions?.length - 1}</span>}
            </p>
          </div>
        </RenderIf>
        <RenderIf condition={!!formData.paymentStatus?.length}>
          <div className="grid grid-cols-2 mb-6">
            <p className="text-base text-body w-[245px]">Status:</p>
            <p className="text-base text-headers">{formData.paymentStatus.map((item: any) => item.label).join(", ")}</p>
          </div>
        </RenderIf>
        <RenderIf condition={date.createdDate.startDate && date.createdDate.endDate}>
          <div className="grid grid-cols-2 mb-6">
            <p className="text-base text-body w-[245px]">Date Created:</p>
            <p className="text-base text-headers">
              {fullDateFormatter(date.createdDate.startDate)} - {fullDateFormatter(date.createdDate.endDate)}
            </p>
          </div>
        </RenderIf>
        <RenderIf condition={!!formData.reviewStatus?.length}>
          <div className="grid grid-cols-2 mb-6">
            <p className="text-base text-body w-[245px]">Review Status:</p>
            <p className="text-base text-headers">{formData.reviewStatus.map((item: any) => item.label).join(", ")}</p>
          </div>
        </RenderIf>
        <RenderIf condition={formData.fromTotalAmount && formData.toTotalAmount}>
          <div className="grid grid-cols-2 mb-6">
            <p className="text-base text-body w-[245px]">Top Up Amount:</p>
            <p className="text-base text-headers">
              {formatCurrency(formData.fromTotalAmount)} - {formatCurrency(formData.toTotalAmount)}
            </p>
          </div>
        </RenderIf>
        <RenderIf condition={!!formData.paymentCycle}>
          <div className="grid grid-cols-2 mb-6">
            <p className="text-base text-body w-[245px]">Payment Cycle:</p>
            <p className="text-base text-headers">{formData.paymentCycle}</p>
          </div>
        </RenderIf>
        <RenderIf condition={!!formData.paymentRecurrence.length}>
          <div className="grid grid-cols-2 mb-6">
            <p className="text-base text-body w-[245px]">Payment Recurrence:</p>
            <p className="text-base text-headers">
              {formData.paymentRecurrence.map((item: any) => item.label).join(", ")}
            </p>
          </div>
        </RenderIf>
        <RenderIf condition={!!formData.psp.length}>
          <div className="grid grid-cols-2 mb-6">
            <p className="text-base text-body w-[245px]">PSP:</p>
            <p className="text-base text-headers">{formData.psp.map((item: any) => item.label).join(", ")}</p>
          </div>
        </RenderIf>
      </>
    </ReportPreviewLayout>
  )
}
