import { useSelector } from "react-redux"
import { addCommasToNumbers } from "utils/addCommasToNumbers"

export default function useDownloadPayments() {
  const downloadData = useSelector((state: any) => state?.payments)

  const dateObject: Date = new Date(downloadData?.created_at)

  // Define the options with correct types
  const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "short" }

  // Get the formatted date string
  const formattedDate: string[] = dateObject.toLocaleDateString("en-US", options).split(" ")

  const paymentDate = `${formattedDate[0]}-${formattedDate[1]}`

  function transformData(input: any) {
    return {
      state: input?.state || "NA",
      batch: input?.payment_cycle ?? "NA",
      benCount: input?.scheduled_beneficiaries_count || "0",
      Amount: addCommasToNumbers(Number(input?.amount * Number(input?.scheduled_beneficiaries_count))) || "NA",
    }
  }

  const tableData = transformData(downloadData)

  return { downloadData, tableData, paymentDate }
}
