import { Button, Modal, Dropdown, Input, Textarea } from "components/core"
import useEditGrievance from "./useEditGrievance"
import Phone from "components/core/PhoneInput/PhoneInput"
import { genderOptionsII } from "mocks/payments"
// import { nigerianStates } from "constants/intervention"
import { complaintType, complaintLevel, complaintMode } from "mocks/grievance"
import { FormEventHandler } from "react"

const EditGrievance = ({ onClose, grievance }: { onClose: () => void; grievance: any }) => {
  const {
    formData,
    setFormData,
    grievancesCategoriesOptions,
    isLoadingGrievanceCategories,
    subCategoriesOptions,
    handleEditGrievance,
    isEditingGrievance,
    isFormChanged,
    isFormValid,
    isFetchingCommunities,
    grievanceLGAOptions,
    grievanceStateOptions,
    grievanceZonesOptions,
    grievanceCommunityOption,
    isFetchingStates,
    isFetchingZones,
    phone,
    setPhone,
  } = useEditGrievance({
    grievance,
    onClose,
  })

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e): void => {
    e.preventDefault()
    handleEditGrievance()
  }
  return (
    <main>
      <Modal title="Edit Grievance" position="modal-right" width="w-[432px]" closeModal={onClose}>
        <form onSubmit={handleSubmit}>
          <div className="modal-right-body flex flex-col gap-y-4">
            <Input
              type="text"
              disabled={true}
              id="complaint_name"
              name="complaint_name"
              value={formData?.complaint_name}
              label="Complainant Name"
            />
            <div className="grid grid-cols-2 gap-x-2">
              <Dropdown
                label="Complainant Type"
                selected={formData?.complaint_type_name}
                options={complaintType}
                onSelect={(data: any) => {
                  setFormData({ ...formData, complaint_type: data?.value, complaint_type_name: data?.label })
                }}
              />
              <Dropdown
                label="Gender"
                selected={formData?.gender}
                options={genderOptionsII.slice(1)}
                onSelect={(data: any) => {
                  setFormData({
                    ...formData,
                    gender: data?.value,
                  })
                }}
              />
            </div>
            <Dropdown
              label="Complaint Mode"
              selected={formData?.complaint_mode}
              options={complaintMode}
              onSelect={(data: any) => {
                setFormData({
                  ...formData,
                  complaint_mode: data?.value,
                })
              }}
            />
            <Input
              label="Complainant Address"
              type="text"
              name="complaint_address"
              id="complaint_address"
              value={formData?.complaint_address}
              onChange={(e: any) => {
                setFormData({ ...formData, complaint_address: e.target.value })
              }}
            />

            <Phone value={phone} onChange={setPhone} label="Phone number" />

            <Dropdown
              label="Category"
              selected={formData?.category_id_name}
              enableSearchOptions={true}
              loading={isLoadingGrievanceCategories}
              options={grievancesCategoriesOptions}
              onSelect={(data: any) => {
                setFormData({
                  ...formData,
                  category_id: data?.value,
                  category_id_name: data?.label,
                })
              }}
            />

            <Dropdown
              label="Sub Category"
              selected={formData?.sub_category_id_name}
              enableSearchOptions={true}
              loading={false}
              options={subCategoriesOptions ?? []}
              onSelect={(data: any) => {
                setFormData({
                  ...formData,
                  sub_category_id: data?.value,
                  sub_category_id_name: data?.label,
                })
              }}
            />

            <Textarea
              label="Description"
              id="description"
              name="description"
              rows={4}
              value={formData?.description}
              onChange={(e) => {
                e.preventDefault()
                setFormData({
                  ...formData,
                  description: e.target.value,
                })
              }}
              maxLength={340}
            />

            <div className="grid grid-cols-2 gap-4">
              <Dropdown
                label="Zone"
                selected={formData?.zone_name}
                options={grievanceZonesOptions}
                loading={isFetchingZones}
                onSelect={(data: any) => {
                  setFormData({
                    ...formData,
                    zone_name: data?.label,
                    zone_id: data?.value,
                  })
                }}
              />
              <Dropdown
                label="State"
                loading={isFetchingStates}
                selected={formData?.state_name}
                options={grievanceStateOptions}
                onSelect={(data: any) => {
                  setFormData({
                    ...formData,
                    state_id: data?.value,
                    state_name: data?.label,
                  })
                }}
              />
              <Dropdown
                label="LGA"
                loading={isFetchingStates}
                selected={formData?.lga_name}
                options={grievanceLGAOptions}
                onSelect={(data: any) => {
                  setFormData({
                    ...formData,
                    lga_name: data?.label,
                    lga_id: data?.value,
                  })
                }}
              />
              {/* <Dropdown
          label="Ward"
          selected={formData?.location}
          options={nigerianStates.slice(1)}
          onSelect={(data: any) => {
            setFormData({
              ...formData,
              location: data?.value,
            })
          }}
        /> */}
              <Dropdown
                label="Community"
                selected={formData?.community_name ?? ""}
                options={grievanceCommunityOption}
                loading={isFetchingCommunities}
                onSelect={(data: any) => {
                  setFormData({
                    ...formData,
                    community_id: String(data?.value),
                    community_name: data?.label,
                  })
                }}
              />
            </div>

            {/* <Dropdown
              label="Location"
              selected={formData?.location}
              options={nigerianStates.slice(1)}
              onSelect={(data: any) => {
                setFormData({
                  ...formData,
                  location: data?.value,
                })
              }}
            /> */}

            <Dropdown
              label="Registration Level"
              selected={formData?.level}
              options={complaintLevel}
              onSelect={(data: any) => {
                setFormData({
                  ...formData,
                  level: data?.value,
                })
              }}
            />
          </div>

          <div className="modal-right-button-container mb-3">
            <Button
              theme="primary"
              className="min-w-[105px] mr-[8px] p-[8px]"
              type="submit"
              data-testid="submit-btn"
              loading={isEditingGrievance}
              disabled={!isFormChanged || isEditingGrievance || !isFormValid}
            >
              Save Changes
            </Button>
            <Button theme="transparent" className="w-[105px] px-[30px]" type="button" onClick={onClose}>
              Discard
            </Button>
          </div>
        </form>
      </Modal>
    </main>
  )
}

export default EditGrievance
