export function extractVersion(str: string): string | null {
  const versionPattern1 = /version (\d+)/
  const versionPattern2 = /V(\d+)/

  let match = str?.match(versionPattern1)
  if (match && match[1]) {
    return `version ${match[1]}`
  }

  match = str?.match(versionPattern2)
  if (match && match[1]) {
    return `version ${match[1]}`
  }

  return null // Return null if no version number is found
}
