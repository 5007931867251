import { fullDateFormatter } from "./dateFormatter"
import jsPDF from "jspdf"
import html2canvas from "html2canvas"

export const convertJsonToCsv = (jsonData: any) => {
  // Prepare CSV header using the selected fields
  const headers = Object.keys(jsonData[0])
  const csvHeader = headers.join(",") + "\n"

  // Prepare CSV rows
  const csvRows = jsonData.map((item: any) => {
    return Object.values(item).join(",") + "\n"
  })

  // Combine header and rows into a single CSV string
  const csvContent = csvHeader + csvRows.join("")

  return csvContent
}

export const exportData = (data: any, tableTitle: string) => {
  const csvContent = convertJsonToCsv(data)
  // Create a Blob with the CSV data
  const blob = new Blob([csvContent], { type: "text/csv" })
  // Create a URL for the Blob
  const url = URL.createObjectURL(blob)
  // Create a download link and trigger a click
  const link = document.createElement("a")
  link.href = url
  link.download = `${tableTitle}-${fullDateFormatter(new Date())}.csv`
  link.click()
}

export const downloadPdf = (id: string, name?: string) => {
  const input = document.getElementById(id)
  if (!input) return

  html2canvas(input)
    .then((canvas: any) => {
      const imgData = canvas.toDataURL("image/png")
      const pdf = new jsPDF()
      const imgProps = pdf.getImageProperties(imgData)
      const pdfWidth = pdf.internal.pageSize.getWidth()
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width

      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight)
      pdf.save(name ? `${name}.pdf` : "download.pdf")
    })
    .catch(() => {
      return
    })
}
