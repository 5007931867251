import { Icon } from "@iconify/react"
import { useNavigate } from "react-router-dom"
import { addCommasToNumbers } from "utils/addCommasToNumbers"
import { RenderIf } from "components/hoc"
import HeaderLoader from "components/core/Loader/HeaderLoader/HeaderLoader"

const TrainingStatistics = ({ isLoading, statisticsData }: any) => {
  const navigate = useNavigate()

  return (
    <div className="border-y mt-3 py-6 flex flex-col gap-6" data-testid="training-stats">
      <RenderIf condition={isLoading}>
        <HeaderLoader />
      </RenderIf>

      <RenderIf condition={!isLoading}>
        <div className="flex justify-between w-full flex-wrap gap-4">
          {statisticsData?.map((statistic: { title: string; icon: string; count: number; viewCourses: boolean }) => (
            <div key={statistic.title} className="flex items-center gap-4">
              <div>
                <div className="w-10 h-10 grid place-items-center bg-grey-100 rounded-full">
                  <Icon icon={`${statistic.icon}`} className="w-6 h-6 text-body" />
                </div>
              </div>

              <div className="grid">
                <h1 className="text-body text-sm">{statistic.title}</h1>
                <p className="text-headers text-sm">
                  {addCommasToNumbers(statistic.count)}
                  <RenderIf condition={statistic.viewCourses}>
                    {" "}
                    <button
                      data-testid="courses-router"
                      onClick={() => navigate("/training/courses")}
                      className=" text-semibold text-[#2B892B]"
                    >
                      {"(View Courses >>>)"}
                    </button>
                  </RenderIf>
                </p>
              </div>
            </div>
          ))}
        </div>
      </RenderIf>
    </div>
  )
}

export default TrainingStatistics
