import { configureStore, combineReducers } from "@reduxjs/toolkit"
import storage from "redux-persist/lib/storage"
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist"
import { authReducer, authSlice } from "services/api/authSlice"
import { rolesSlice } from "services/api/rolesSlice"
import { staffSlice } from "services/api/staffSlice"
import { dataReducer, dataSlice } from "services/api/dataSlice"
import { configurationSlice } from "services/api/configurationSlice"
import { interventionSlice } from "services/api/interventionSlice"
import { beneficiarySlice } from "services/api/beneficiarySlice"
import { paymentSlice, paymentsReducer, transactionsReducer } from "services/api/paymentSlice"
import { grievanceSlice, grievanceReducer } from "services/api/grievanceSlice"
import { clearTokenCookie } from "utils/getCookie"
import { commonSlice, commonReducer } from "services/api/commonSlice"
import { coResponsibilitySlice } from "services/api/coResponsibilitySlice"
import { livelihoodSlice } from "services/api/livelihoodSlice"
import { trainingSlice } from "services/api/trainingSlice"
import { reportSlice } from "services/api/reportSlice"

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["auth", "data", "payments", "transactions", "common", "grievances"],
}

const rootReducer = combineReducers({
  auth: authReducer,
  data: dataReducer,
  payments: paymentsReducer,
  transactions: transactionsReducer,
  common: commonReducer,
  grievances: grievanceReducer,
  [rolesSlice.reducerPath]: rolesSlice.reducer,
  [staffSlice.reducerPath]: staffSlice.reducer,
  [authSlice.reducerPath]: authSlice.reducer,
  [dataSlice.reducerPath]: dataSlice.reducer,
  [configurationSlice.reducerPath]: configurationSlice.reducer,
  [interventionSlice.reducerPath]: interventionSlice.reducer,
  [beneficiarySlice.reducerPath]: beneficiarySlice.reducer,
  [paymentSlice.reducerPath]: paymentSlice.reducer,
  [commonSlice.reducerPath]: commonSlice.reducer,
  [grievanceSlice.reducerPath]: grievanceSlice.reducer,
  [coResponsibilitySlice.reducerPath]: coResponsibilitySlice.reducer,
  [livelihoodSlice.reducerPath]: livelihoodSlice.reducer,
  [trainingSlice.reducerPath]: trainingSlice.reducer,
  [reportSlice.reducerPath]: reportSlice.reducer,
})

const reducerProxy = (state, action) => {
  if (action?.type === "logout") {
    // Handles logout
    rootReducer({}, action)
    clearTokenCookie()
    return
  }
  return rootReducer(state, action)
}

const persistedReducer = persistReducer(persistConfig, reducerProxy)

const middleware = (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }).concat(
    rolesSlice.middleware,
    staffSlice.middleware,
    authSlice.middleware,
    dataSlice.middleware,
    configurationSlice.middleware,
    interventionSlice.middleware,
    beneficiarySlice.middleware,
    paymentSlice.middleware,
    commonSlice.middleware,
    grievanceSlice.middleware,
    coResponsibilitySlice.middleware,
    livelihoodSlice.middleware,
    trainingSlice.middleware,
    reportSlice.middleware
  )

const store = configureStore({
  reducer: persistedReducer,
  middleware,
})

const persistor = persistStore(store)

export { store, persistor, reducerProxy, middleware }
