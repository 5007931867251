import { createApi } from "@reduxjs/toolkit/query/react"
import { axiosBaseQuery } from "../axiosBaseQuery"
import { createSlice } from "@reduxjs/toolkit"
import { cookieDateFormat } from "utils/dateFormatter"
import { clearTokenCookie } from "utils/getCookie"

const baseUrl = `${process.env.REACT_APP_BASE_URL}`

// AUTH API SLICE
export const authSlice = createApi({
  reducerPath: "authSlice",
  baseQuery: axiosBaseQuery({ baseUrl }),
  keepUnusedDataFor: 0,
  endpoints(build) {
    return {
      login: build.mutation<any, { email: string; password: string }>({
        query: (data) => ({ url: "/auth/login", method: "post", data }),
      }),
      resetPassword: build.mutation<any, { email: string }>({
        query: (data) => ({ url: "/staff/forgot-password", method: "post", data }),
      }),
      createPassword: build.mutation<any, { password: string }>({
        query: (data) => ({ url: "/auth/create-user-password", method: "put", data }),
      }),
      verifyOtp: build.mutation<any, { userOtp: string }>({
        query: (data) => ({ url: "/auth/verify-otp", method: "post", data }),
      }),
      resendOtp: build.mutation<any, { email: string }>({
        query: (data) => ({ url: "/auth/resend-otp", method: "post", data }),
      }),
      refreshToken: build.mutation<any, { refreshToken: string }>({
        query: (data) => ({ url: "/auth/refresh", method: "post", data }),
      }),
    }
  },
})

export const {
  useLoginMutation,
  useResetPasswordMutation,
  useCreatePasswordMutation,
  useVerifyOtpMutation,
  useResendOtpMutation,
  useRefreshTokenMutation,
} = authSlice

// AUTH SLICE
const auth = createSlice({
  name: "auth",
  initialState: {
    refreshToken: "",
  },
  reducers: {
    setCredentials: (state, action) => {
      Object.assign(state, action.payload)
    },
    updateToken: (state, action) => {
      const { refreshToken, token, expires } = action.payload
      state.refreshToken = refreshToken
      const formattedDate = cookieDateFormat(expires)
      clearTokenCookie() // clear previous token
      document.cookie = `token=${token}; expiry=${formattedDate}; path=/`
    },
  },
})

export const { setCredentials, updateToken } = auth.actions
export const authReducer = auth.reducer
