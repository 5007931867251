import { Avatar, Button, Modal, Status } from "components/core"
import React from "react"

interface viewStaffProps {
  closeModal: () => void
  staffDetails: any
  showModal: () => void
}

export const ViewStaff = ({ closeModal, staffDetails, showModal }: viewStaffProps) => {
  return (
    <Modal title="Single Staff View" position="modal-right" width="w-[432px]" closeModal={closeModal}>
      <div>
        <div className="px-5 py-6">
          <div className="bg-primary-30 p-4 rounded-lg">
            <div className="flex justify-between items-start border-b-[0.5px] pb-3">
              <div className="flex items-start">
                <Avatar
                  key={staffDetails?.id}
                  image={`${staffDetails?.first_name} ${staffDetails?.last_name}`}
                  alt={staffDetails?.first_name}
                  size="32"
                />
                <div className="text-body text-xs ml-3">
                  <p className="text-sm capitalize">{`${staffDetails.first_name} ${staffDetails.last_name}`}</p>
                  <p className="text-grey-base">{staffDetails.email}</p>
                  <p className="mt-1">Staff ID: {staffDetails.staff_id}</p>
                </div>
              </div>
              <Status status={staffDetails.status} />
            </div>
            <div className="flex text-xs text-body mt-3">
              <div className="pr-4 border-r ">
                <p className="text-xs mb-0.5 text-[#A1A1A1]">Role assigned:</p>
                <p className="">{staffDetails.role}</p>
              </div>
              <div className="pl-3">
                <p className="text-xs mb-0.5 text-[#A1A1A1]">Phone number:</p>
                <p>{staffDetails.phone_number ?? "N/A"}</p>
              </div>
            </div>
          </div>
          {staffDetails.status !== "inactive" && (
            <div className="modal-right-button-container">
              <Button
                theme={staffDetails?.status === "active" ? "secondary" : "primary"}
                className="min-w-[105px] mb-4"
                onClick={showModal}
                size="44"
              >
                {`${staffDetails?.status === "active" ? "Deactivate" : "Activate"} Staff`}
              </Button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}
