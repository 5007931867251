import { Outlet } from "react-router-dom";
import { ReportsLayout } from "components/layouts";
import { InterventionReport, InterventionSummary, InterventionGenerateReport } from "pages/reports/intervention";
import { BeneficiaryReport, BeneficiarySummary, BeneficiaryGenerateReport } from "pages/reports/beneficiary";
import { PaymentUnitGenerateReport, PaymentUnitReport, PaymentUnitSummary } from "pages/reports/payment-unit";
import { AccountReport, AccountSummary, AccountGenerateReport } from "pages/reports/account";
import LivelihoodSummary from "pages/reports/livelihood/LivelihoodSummary/LivelihoodSummary";
import CoResponsibilitySummary from "pages/reports/coResponsibility/CoResponsibilitySummary/CoResponsibilitySummary";
import GRMSummary from "pages/reports/grm/GRMSummary/GRMSummary";
import AuditSummary from "pages/reports/audit/AuditSummary/AuditSummary";
import GenerateAuditReport from "pages/reports/audit/GenerateAuditReport/GenerateAuditReport";
import AuditReport from "pages/reports/audit/AuditReport/AuditReport";
import CoResponsibilityReport from "pages/reports/coResponsibility/CoresponsibilityReport/CoResponsibilityReport";
import GenerateCoresponsbilityReport from "pages/reports/coResponsibility/GenerateCoResponsibilityReport/GenerateCoresponsbilityReport";
import LivelihoodReport from "pages/reports/livelihood/LivelihoodReport/LivelihoodReport";
import GenerateLivelihoodReport from "pages/reports/livelihood/GenerateLivelihoodReport/GenerateLivelihoodReport";
import GRMReport from "pages/reports/grm/GRMReport/GRMReport";
import GenerateGRMReport from "pages/reports/grm/GenerateGRMReport/GenerateGRMReport";

export const reportsRoutes = [
    {
        path: "/reports/",
        errorElement: <div>Something went wrong</div>,
        element: (
            <ReportsLayout>
                <Outlet />
            </ReportsLayout>
        ),
        children: [
            {
                path: "intervention",
                element: <InterventionSummary />,
            },
            {
                path: "beneficiary",
                element: <BeneficiarySummary />,
            },
            {
                path: "payment-unit",
                element: <PaymentUnitSummary />,
            },
            {
                path: "account",
                element: <AccountSummary />,
            },
            {
                path: "audit",
                element: <AuditSummary/>,
            },
            {
                path: "co-responsibility",
                element: <CoResponsibilitySummary/>,
            },
            {
                path: "livelihood",
                element: <LivelihoodSummary/>,
            },
            {
                path: "grm",
                element: <GRMSummary/>,
            },
        ],
    },
    {
        path: "/reports/intervention/:reportId",
        element: <InterventionReport />
    },
    {
        path: "/reports/intervention/generate-report",
        element: <InterventionGenerateReport />,
    },
    {
        path: "/reports/beneficiary/:reportId",
        element: <BeneficiaryReport />
    },
    {
        path: "/reports/beneficiary/generate-report",
        element: <BeneficiaryGenerateReport />,
    },
    {
        path: "/reports/payment-unit/:reportId",
        element: <PaymentUnitReport />
    },
    {
        path: "/reports/payment-unit/generate-report",
        element: <PaymentUnitGenerateReport />,
    },
    {
        path: "/reports/account/:reportId",
        element: <AccountReport />
    },
    {
        path: "/reports/account/generate-report",
        element: <AccountGenerateReport />,
    },
    {
        path: "/reports/audit/:reportId",
        element: <AuditReport />
    },
    {
        path: "/reports/audit/generate-report",
        element: <GenerateAuditReport />,
    },
    {
        path: "/reports/co-responsibility/:reportId",
        element: <CoResponsibilityReport />
    },
    {
        path: "/reports/co-responsibility/generate-report",
        element: <GenerateCoresponsbilityReport />,
    },
    {
        path: "/reports/livelihood/:reportId",
        element: <LivelihoodReport />
    },
    {
        path: "/reports/livelihood/generate-report",
        element: <GenerateLivelihoodReport />,
    },
    {
        path: "/reports/grm/:reportId",
        element: <GRMReport />
    },
    {
        path: "/reports/grm/generate-report",
        element: <GenerateGRMReport />,
    },
]