import { Icon } from "@iconify/react"
import { Button, Calendar, Filter, Search } from "components/core"
import { RenderIf, Restricted } from "components/hoc"
import React, { ChangeEvent } from "react"
import { DateType } from "types/data"
import { AllPermissions, PermissionModule } from "types/permission"

interface ViewDataSourceFiltersProps {
  currentTab: string | null
  // eslint-disable-next-line no-unused-vars
  handleSearch: (e: ChangeEvent<HTMLInputElement>) => void
  filterOptions: any
  selectedStatus: any
  setSelectedStatus: any
  date: DateType
  // eslint-disable-next-line no-unused-vars
  setDate: (date: { startDate: Date | null; endDate: Date | null }) => void
  // eslint-disable-next-line no-unused-vars
  setSelectedAction: (action: string) => void
  // eslint-disable-next-line no-unused-vars
  setSelectedData: (action: string) => void
}

export const ViewDataSourceFilters: React.FC<ViewDataSourceFiltersProps> = ({
  currentTab,
  handleSearch,
  filterOptions,
  selectedStatus,
  setSelectedStatus,
  date,
  setDate,
  setSelectedAction,
  setSelectedData,
}) => {
  const { DATA_MINING } = PermissionModule
  const { MINE_DATA } = AllPermissions
  return (
    <div>
      <div className="flex items-center justify-between mb-6">
        <div className="flex space-x-2">
          <RenderIf condition={currentTab === "complete-data"}>
            <Search id="search" name="search" placeholder="Search" onChange={handleSearch} />
          </RenderIf>
          <RenderIf condition={currentTab === "incomplete-data"}>
            <Search id="search" name="search" placeholder="Search" onChange={handleSearch} />
          </RenderIf>

          <RenderIf condition={currentTab === "incomplete-data"}>
            <Filter
              data-testid="filter-dropdown"
              title="Status"
              options={filterOptions}
              selectedOption={selectedStatus}
              setSelectedOption={setSelectedStatus}
            />
          </RenderIf>

          <Calendar id={"2"} startDate={date.startDate} endDate={date.endDate} onChange={setDate} selectsRange={true} />
        </div>
        <Restricted module={DATA_MINING} permission={MINE_DATA}>
          <RenderIf condition={currentTab === "complete-data"}>
            <Button
              theme="primary"
              type="button"
              size="40"
              onClick={() => {
                setSelectedAction("mine-data")
                setSelectedData("")
              }}
            >
              <Icon icon="solar:cloud-upload-outline" className="w-6 h-6 mr-[8px]" />
              Mine Data
            </Button>
          </RenderIf>
        </Restricted>
      </div>
    </div>
  )
}
