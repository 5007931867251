import React from "react"

export const BeneficiaryDetailsCardLoader: React.FC = () => {
  return (
    <div
      role="status"
      className="
      grid gap-3 overflow-hidden cursor-pointer p-4 border border-neutral-10
      rounded-xl group animate-pulse transition-all ease-out duration-700"
    >
      <div className="flex flex-col gap-2 pb-3 transition-all ease-out duration-700">
        <div className="bg-neutral-10 h-3 w-1/3 rounded-full"></div>
        <div className="h-2 bg-neutral-10 rounded-full"></div>
        <div className="h-2 bg-neutral-10 rounded-full"></div>
        <div className="h-2 bg-neutral-10 rounded-full"></div>
      </div>
      <div className="h-0.5 bg-neutral-10 rounded-lg" />
      <div className="flex items-center justify-between">
        <div className="flex items-center -space-x-3">
          <div className="w-6 h-6 bg-neutral-10 rounded-full border-white border" />
          <div className="w-6 h-6 bg-neutral-10 rounded-full border-white border" />
          <div className="w-6 h-6 bg-neutral-10 rounded-full border-white border" />
        </div>
        <div className="h-6 w-14 bg-neutral-10 rounded-full border-neutral-40" />
      </div>
      <span className="sr-only">Loading...</span>
    </div>
  )
}
