import { useTable } from "hooks"
import { useState } from "react"
import { useGetAllCoresponsibilityQuery, useLazyGetAllCoresponsibilityQuery } from "services/api/coResponsibilitySlice"
import { stringifyArray } from "utils/stringifyArray"
import { tryCatch } from "utils/tryCatch"
import { removeKeysWithEmptyValues } from "utils/removeKeysWithEmptyValues"
import { exportData } from "utils/exportData"
import dayjs from "dayjs"
import { useSelector } from "react-redux"
import { shortDate } from "utils/dateFormatter"

interface DateType {
  startDate: Date | null
  endDate: Date | null
}

export default function useCoresponsibility() {
  const { states: nigerianStates } = useSelector((state: any) => state.common)
  const [selectedState, setSelectedState] = useState<any>([])
  const [selectedStatus, setSelectedStatus] = useState<any>([])
  const [date, setDate] = useState<DateType>({ startDate: null, endDate: null })
  const dateRangeFilter = date.endDate && { fromDate: shortDate(date.startDate), toDate: shortDate(date.endDate) }
  const { handleSearch, changePage, params, selectLimit } = useTable()
  const { data: coResponsibilities, isFetching } = useGetAllCoresponsibilityQuery({
    ...params,
    ...dateRangeFilter,
    status: selectedStatus.length ? stringifyArray(selectedStatus) : "",
    state: selectedState.length ? stringifyArray(selectedState) : "",
  })

  const [getAllCoresponsibility, { isFetching: isExporting }] = useLazyGetAllCoresponsibilityQuery()

  const exportAllCoresponsibilities = () => {
    tryCatch(async () => {
      const res = await getAllCoresponsibility(
        removeKeysWithEmptyValues({
          search: params.search ?? "",
          status: selectedStatus.length ? stringifyArray(selectedStatus) : "",
          state: selectedState.length ? stringifyArray(selectedState) : "",
          ...dateRangeFilter,
          noLimit: true,
        }),
      ).unwrap()
      const data = res?.coResponsibility?.map((item: any) => {
        return {
          "intervention name": item?.intervention_title,
          "Total amount": item?.total_amount,
          "Top-up amount": item?.top_amount,
          "Total number of beneficiary": item?.total_number_of_beneficiary,
          "Payment method": item?.payment_method,
          "Date Created": dayjs(item.created_at).format("MMMM DD YYYY"),
          Status: item?.status,
        }
      })
      exportData(data, "Co-responsibility List")
    })
  }
  return {
    date,
    setDate,
    selectedState,
    setSelectedState,
    selectedStatus,
    setSelectedStatus,
    selectLimit,
    params,
    changePage,
    handleSearch,
    coResponsibilities,
    isFetching,
    nigerianStates,
    isExporting,
    exportAllCoresponsibilities,
  }
}
