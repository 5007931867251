interface TextAreaProps {
  id: string
  name: string
  rows?: number
  placeholder?: string
  value: string
  label?: string
  // eslint-disable-next-line
  onChange: (e: any) => void
  readOnly?: boolean
  maxLength?: number
  disabled?: boolean
  className?: string
}

export const Textarea = ({
  id,
  name,
  placeholder,
  label,
  value,
  onChange,
  readOnly,
  rows,
  maxLength,
  disabled,
  className,
}: TextAreaProps) => {
  const disabledStyle = disabled ? "bg-grey-50" : "bg-white"

  return (
    <div className={`relative ${className}`}>
      {label && (
        <label
          htmlFor={id}
          className={`
            ${disabled ? "bg-none" : "bg-brand_white"} block
            text-sm text-headers font-medium mb-[4px] cursor-default`}
        >
          {label}
        </label>
      )}
      <textarea
        id={id}
        name={name}
        rows={rows}
        maxLength={maxLength}
        disabled={disabled}
        onChange={onChange}
        placeholder={placeholder || ""}
        data-testid={id}
        aria-labelledby={id}
        readOnly={readOnly}
        value={value}
        className={`
        p-[12px] text-body text-base w-full outline-0 border hide_tap
        rounded-[4px] border-grey-200 focus:border-primary-200 ${disabledStyle} 
        `}
      />
      <p className="text-xs text-neutral-30 text-right">
        {value.length}/{maxLength ?? "320"} words
      </p>
    </div>
  )
}
