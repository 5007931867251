import { useMemo, useState } from "react"
import {
  useFetchOfficersQuery,
  useAssignGrievanceMutation,
  useReassignGrievanceMutation,
} from "services/api/grievanceSlice"
import { useTable } from "hooks"
import { toast } from "react-toastify"
import { tryCatch } from "utils/tryCatch"

// interface Props {
//   formData: any
//   setFormData?: any
//   proceed: () => void
// }

export default function useAssignOfficer({ onClose, grievance, selectedAction }: any) {
  const { handleSearch: searchNCTU, params: nctuParams, resetParams: resetNCTUParams } = useTable()
  const { handleSearch: searchSCTU, params: sctuParams, resetParams: resetSCTUParms } = useTable()
  const { handleSearch: searchLCTU, params: lctuParams, resetParams: resetLCTUParams } = useTable()

  const [currentTab, setCurrentTab] = useState("nctu")

  const [staffId, setStaffId] = useState(grievance?.assigned_to ?? "")

  const resetParamsProxy = () => {
    if (currentTab === "nctu") {
      resetNCTUParams()
    }
    if (currentTab === "sctu") {
      resetSCTUParms()
    }
    if (currentTab === "lctu") {
      resetLCTUParams()
    }
  }

  const officerInfo = useMemo(() => {
    if (currentTab === "nctu") {
      return {
        handleSearch: searchNCTU,
        ...nctuParams,
      }
    }
    if (currentTab === "sctu") {
      return {
        handleSearch: searchSCTU,
        ...sctuParams,
      }
    }
    if (currentTab === "lctu") {
      return {
        handleSearch: searchLCTU,
        ...lctuParams,
      }
    }
  }, [currentTab, nctuParams, sctuParams, lctuParams])

  const queryParams = {
    user_type: String(currentTab)?.toUpperCase(),
    search:
      currentTab === "nctu" ? nctuParams?.search : currentTab === "lctu" ? lctuParams?.search : sctuParams?.search,
  }

  const { data: grmOfficers, isFetching: isLoadingOfficers } = useFetchOfficersQuery(queryParams)

  const tabs = [
    {
      title: "NCTU",
      path: "nctu",
    },
    // {
    //   title: "SCTU",
    //   path: "sctu",
    // },
    // {
    //   title: "LCTU",
    //   path: "lctu",
    // },
  ]

  const [assignGrievance, { isLoading: isLoadingAssignGrievance }] = useAssignGrievanceMutation({})
  const [reassignGrievance, { isLoading: isLoadingReassignGrievance }] = useReassignGrievanceMutation({})

  const handleSubmit = async () => {
    // eslint-disable-next-line no-unused-vars
    const data = {
      staff_id: staffId,
    }

    const callback = (res: any) => {
      if ([200, 201].includes(res?.code)) {
        toast.success(res.message)
        onClose()
      }
    }
    let res: any

    tryCatch(async () => {
      if (selectedAction === "reassign-officer") {
        res = await reassignGrievance({ id: grievance?.grievance_id, data }).unwrap()
      }
      if (selectedAction === "assign-officer") {
        res = await assignGrievance({ id: grievance?.grievance_id, data }).unwrap()
      }

      callback(res)
    })
  }

  return {
    tabs,
    currentTab,
    isLoadingOfficers,
    grmOfficers,
    handleSearch: officerInfo?.handleSearch,
    search: officerInfo?.search,
    resetParamsProxy,
    staffId,
    setStaffId,
    setCurrentTab,
    isLoadingAssignGrievance,
    isLoadingReassignGrievance,
    handleSubmit,
  }
}
