import { Search, Button, Calendar } from "components/core"
import { Icon } from "@iconify/react"
import { RenderIf } from "components/hoc"

// interface Option {
//   label: string
//   value: string | number
// }

interface PaymentFiltersInterface {
  // filterOptions?: Option[]
  // filterLoading?: boolean
  // filterMultiple?: boolean
  // selectedOption?: Option[] | Option
  // // eslint-disable-next-line no-unused-vars
  // setSelectedOption?: (data: Option | Option[]) => void
  // eslint-disable-next-line no-unused-vars
  handleSearch: (e: any) => void
  // eslint-disable-next-line no-unused-vars
  handleDate: (e: any) => void
  // containerClass?: string
  showGenerateScheduleButton: boolean
  exportFunction?: () => void
  searchName: string
  startDate: any
  endDate?: any
  selectsRange: boolean
  generateActionFunction?: () => void
  showExport: boolean
}

const PaymentTableFilters = ({
  // filterOptions,
  // filterLoading,
  // filterMultiple,
  // containerClass,
  // selectedOption,
  // setSelectedOption,
  handleSearch,
  searchName,
  startDate,
  endDate,
  handleDate,
  selectsRange,
  showGenerateScheduleButton,
  generateActionFunction,
  exportFunction,
  showExport,
}: PaymentFiltersInterface) => {
  return (
    <div className="flex flex-wrap gap-4 items-center justify-between mb-4 mt-6">
      <div className="flex space-x-2">
        <Search id="search" name={searchName} placeholder="Search" onChange={handleSearch} />
        {/* <Filter
          title="Status"
          options={filterOptions}
          loading={filterLoading}
          multiple={filterMultiple}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          containerClass={containerClass}
        /> */}
        <Calendar startDate={startDate} endDate={endDate} onChange={handleDate} selectsRange={selectsRange} />
      </div>
      <div className="flex space-x-2">
        <RenderIf condition={showExport}>
          <Button type="button" size="40" theme="transparent" onClick={exportFunction}>
            <Icon icon="uiw:download" className="text-[#747F9C] w-4 h-4 mr-3" />
            Export
          </Button>
        </RenderIf>

        <RenderIf condition={showGenerateScheduleButton}>
          <Button type="button" size="40" theme="primary" className="" onClick={generateActionFunction}>
            <Icon icon="ph:money" className="w-6 h-6 mr-[8px]" />
            Generate Payment Schedule
          </Button>
        </RenderIf>
      </div>
    </div>
  )
}

export default PaymentTableFilters
