import { useState, useEffect, useMemo } from "react"
import { removeKeysWithEmptyValues } from "utils/removeKeysWithEmptyValues"
import { useInviteSingleStaffMutation, useUpdateStaffDetailsMutation } from "services/api/staffSlice"
import { removeSpecificKeys } from "utils/removeSpecificKeys"
import useInputValidate from "hooks/useInputValidate/useInputValidate"
import { toast } from "react-toastify"
import { tryCatch } from "utils/tryCatch"
import { Staff } from "types/staff"

export interface UseAddEditStaffProps {
  closeModal?: () => void
  action: string
  refetch?: () => void
  staffDetails?: Staff
  defaultRole?: string
}

export default function useAddEditStaff({
  action,
  closeModal,
  staffDetails,
  refetch,
  defaultRole,
}: UseAddEditStaffProps) {
  const [viewType, setViewType] = useState("single-staff")
  const [showPreview, setShowPreview] = useState(false)
  const [formData, setFormData] = useState<{ [key: string]: any }>({
    first_name: "",
    last_name: "",
    email: "",
    staff_number: "",
    phone_number: "",
    role: defaultRole ?? "",
    user_type: staffDetails?.user_type ?? "",
  })

  const [phone, setPhone] = useState<{ phoneNumber: string | number; countryCode: string }>({
    phoneNumber: "",
    countryCode: "+234",
  })

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }
  const { validateFormData } = useInputValidate()

  const [inviteSingleStaff, { isLoading: isInvitingStaff }] = useInviteSingleStaffMutation()
  const [updateStaffDetails, { isLoading: isUpdatingStaff }] = useUpdateStaffDetailsMutation()

  const callback = (res: any) => {
    if ([200, 201].includes(res?.code)) {
      toast.success(res?.message)
      closeModal && closeModal()
      refetch && refetch()
    }
  }

  const handleInviteStaff = async (e: any) => {
    e.preventDefault()
    const data =
      action === "Add"
        ? removeKeysWithEmptyValues({ ...formData })
        : removeKeysWithEmptyValues(removeSpecificKeys({ ...formData }, ["email", "user_type"]))
    tryCatch(async () => {
      let res
      if (action === "Add") {
        res = await inviteSingleStaff(data).unwrap()
        callback(res)
      } else {
        res = await updateStaffDetails({
          staffId: staffDetails?.staff_id ?? "",
          data,
        }).unwrap()
        callback(res)
      }
    })
  }

  const disableBtn = useMemo(() => {
    if (action === "Add") {
      if (viewType === "single-staff") {
        const payload = removeSpecificKeys({ ...formData }, ["phone_number", "staff_number", "role", "user_type"])
        return !validateFormData(payload) || !formData.role || !formData.user_type
      }
    }
    return false
  }, [action, viewType, formData])

  useEffect(() => {
    if (phone.phoneNumber) {
      setFormData({
        ...formData,
        phone_number: phone.phoneNumber,
      })
    }
  }, [phone])

  useEffect(() => {
    if (action === "Edit" && staffDetails) {
      setFormData({
        first_name: staffDetails?.first_name ?? "",
        last_name: staffDetails?.last_name ?? "",
        email: staffDetails?.email ?? "",
        staff_number: staffDetails?.staff_number ?? "",
        phone_number: staffDetails?.phone_number ?? "",
        role: staffDetails?.role ?? "",
        user_type: staffDetails?.user_type ?? "",
      })
      setPhone({
        phoneNumber: staffDetails.phone_number ?? "",
        countryCode: "+234",
      })
    }
  }, [action])

  return {
    formData,
    handleInviteStaff,
    setFormData,
    setPhone,
    phone,
    isUpdatingStaff,
    isInvitingStaff,
    handleChange,
    disableBtn,
    setViewType,
    viewType,
    showPreview,
    setShowPreview,
  }
}
