import Coresponsibility from "pages/Co-responsibility/Co-Responsibility"
import ViewCoResponsibility from "pages/Co-responsibility/viewCoResponsibility/ViewCoResponsibility"
import CoresponsibiltyBeneficiary from "pages/Co-responsibility/CoresponsibilityBeneficiary/CoresponsibiltyBeneficiary"
import { Restricted } from "components/hoc"
import { AllPermissions, PermissionModule } from "types/permission"

const { CO_RESPONSIBILITY } = PermissionModule
const { VIEW_SINGLE_CORESPONSIBILITY } = AllPermissions

export const coResponsibilityRoutes = [
    {
        path: "co-responsibility",
        element: <Coresponsibility />,
      },
      {
        path: 'co-responsibility/:id',
        element: <ViewCoResponsibility />
      },
      {
        path: 'co-responsibility/:id/beneficiary/:beneficiaryId',
        element: <CoresponsibiltyBeneficiary />
      },
      {
        path: 'co-responsibility/:id/:stateId',
        element: (
          <Restricted module={CO_RESPONSIBILITY} permission={VIEW_SINGLE_CORESPONSIBILITY} showError={true}>
            <ViewCoResponsibility />
          </Restricted>
        )
      },
]