import { Status } from "components/core"
import { RenderIf } from "components/hoc"
import { ReportPreviewLayout } from "components/layouts/Reports"
import { fullDateFormatter } from "utils/dateFormatter"
import { formatCurrency } from "utils/formatCurrency"

interface Props {
  close: () => void
  state: any
}

export const Preview = ({ close, state }: Props) => {
  const { formData, date, isGeneratingReport, generateReport } = state

  return (
    <ReportPreviewLayout
      close={close}
      loading={isGeneratingReport}
      generateReport={generateReport}
      name={formData?.reportTitle}
    >
      <>
        <RenderIf condition={!!formData.interventions?.length}>
          <div className="flex mb-[24px]">
            <p className="text-base text-body w-[245px] shrink-0">Intervention:</p>
            <p className="text-base text-headers">
              {formData.interventions[0]?.label}{" "}
              {!!(formData.interventions?.length - 1) && <span>+{formData.interventions?.length - 1}</span>}
            </p>
          </div>
        </RenderIf>
        {!!formData?.status?.length && (
          <div className="flex mb-[24px]">
            <p className="text-base text-body w-[245px] shrink-0">Status:</p>
            <div className="flex flex-wrap">
              {formData?.status.map((item: any) => (
                <Status key={item?.label} status={item?.label} className="mr-[8px] mb-[8px] bg-[#E3E5EB] text-body" />
              ))}
            </div>
          </div>
        )}
        {!!formData?.states?.length && (
          <div className="flex mb-[24px]">
            <p className="text-base text-body w-[245px] shrink-0">States:</p>
            <div className="flex flex-wrap">
              {formData?.states.map((item: any) => (
                <Status key={item?.label} status={item?.label} className="mr-[8px] mb-[8px] bg-[#E3E5EB] text-body" />
              ))}
            </div>
          </div>
        )}
        {date.createdDate.startDate && (
          <div className="flex mb-[24px]">
            <p className="text-base text-body w-[245px] shrink-0">Date Created:</p>
            <p className="text-base text-headers">
              {fullDateFormatter(date.createdDate.startDate)} - {fullDateFormatter(date.createdDate.endDate)}
            </p>
          </div>
        )}
        {date.reviewDate.startDate && (
          <div className="flex mb-[24px]">
            <p className="text-base text-body w-[245px] shrink-0">Review Created:</p>
            <p className="text-base text-headers">
              {fullDateFormatter(date.reviewDate.startDate)} - {fullDateFormatter(date.reviewDate.endDate)}
            </p>
          </div>
        )}
        {date.startDate.startDate && (
          <div className="flex mb-[24px]">
            <p className="text-base text-body w-[245px] shrink-0">Start Date:</p>
            <p className="text-base text-headers">
              {fullDateFormatter(date.startDate.startDate)} - {fullDateFormatter(date.startDate.endDate)}
            </p>
          </div>
        )}
        {date.endDate.startDate && (
          <div className="flex mb-[24px]">
            <p className="text-base text-body w-[245px] shrink-0">End Date:</p>
            <p className="text-base text-headers">
              {fullDateFormatter(date.endDate.startDate)} - {fullDateFormatter(date.endDate.endDate)}
            </p>
          </div>
        )}
        {!!formData.reviewStatus.length && (
          <div className="flex mb-[24px]">
            <p className="text-base text-body w-[245px] shrink-0">Review Status:</p>
            <div className="flex flex-wrap">
              {formData?.reviewStatus.map((item: any) => (
                <Status key={item?.label} status={item?.label} className="mr-[8px] mb-[8px] bg-[#E3E5EB] text-body" />
              ))}
            </div>
          </div>
        )}
        {date.startDate.startDate && (
          <div className="flex mb-[24px]">
            <p className="text-base text-body w-[245px] shrink-0">Start Date:</p>
            <p className="text-base text-headers">
              {fullDateFormatter(date.startDate.startDate)} - {fullDateFormatter(date.startDate.endDate)}
            </p>
          </div>
        )}
        {date.endDate.startDate && (
          <div className="flex mb-[24px]">
            <p className="text-base text-body w-[245px] shrink-0">End Date:</p>
            <p className="text-base text-headers">
              {fullDateFormatter(date.endDate.startDate)} - {fullDateFormatter(date.endDate.endDate)}
            </p>
          </div>
        )}
        <RenderIf condition={formData.fromTotalAmount && formData.toTotalAmount}>
          <div className="flex mb-[24px]">
            <p className="text-base text-body w-[245px] shrink-0">Amount:</p>
            <p className="text-base text-headers">
              {formatCurrency(formData.fromTotalAmount)} - {formatCurrency(formData.toTotalAmount)}
            </p>
          </div>
        </RenderIf>
        {formData?.paymentCycle && (
          <div className="flex mb-[24px]">
            <p className="text-base text-body w-[245px] shrink-0">Payment Cycle:</p>
            <p className="text-base text-headers">{formData?.paymentCycle}</p>
          </div>
        )}
        {!!formData.paymentRecurrence?.length && (
          <div className="flex mb-[24px]">
            <p className="text-base text-body w-[245px] shrink-0">Payment Recurrence:</p>
            <div className="flex flex-wrap">
              {formData?.paymentRecurrence.map((item: any) => (
                <Status key={item?.label} status={item?.label} className="mr-[8px] mb-[8px] bg-[#E3E5EB] text-body" />
              ))}
            </div>
          </div>
        )}
        {!!formData.psp?.length && (
          <div className="flex mb-[24px]">
            <p className="text-base text-body w-[245px] shrink-0">PSP:</p>
            <div className="flex flex-wrap">
              {formData?.psp.map((item: any) => (
                <Status key={item?.label} status={item?.label} className="mr-[8px] mb-[8px] bg-[#E3E5EB] text-body" />
              ))}
            </div>
          </div>
        )}
      </>
    </ReportPreviewLayout>
  )
}
