import React from "react"
import { motion } from "framer-motion"
import { useNavigate } from "react-router-dom"

interface dataProps {
  title: string
  path: string
  component?: any
}

interface Params {
  page: number
  limit: number
  sortBy?: string
  search?: string
}

interface dataTabProps {
  tabs: dataProps[]
  currentTab: string | null
  // eslint-disable-next-line no-unused-vars
  setParams: (params: Params) => void
  params: Params
  // eslint-disable-next-line no-unused-vars
  setDate: (date: { startDate: Date | null; endDate: Date | null }) => void
  // eslint-disable-next-line no-unused-vars
  setSelectedStatus: (status: any[]) => void
}
export const DataTabs: React.FC<dataTabProps> = ({
  tabs,
  currentTab,
  setParams,
  params,
  setDate,
  setSelectedStatus,
}) => {
  const navigate = useNavigate()
  return (
    <>
      <h3 className="text-[#3A3F4E] text-lg font-medium mb-3">Data List</h3>
      <div className="flex justify-between mb-[24px]">
        <div className="w-full border-b-[1.5px] border-b-grey-200">
          {tabs.map((tab) => (
            <motion.button
              key={tab.title}
              disabled={currentTab === tab.path}
              className={`
                                text-sm font-normal mr-[24px] pt-[8px] pb-[16px] px-[16px] 
                                ${currentTab === tab.path ? "selected-tab" : "unselected-tab"}
                                `}
              onClick={() => {
                navigate(`?category=${tab.path}`)
                setParams({ ...params, search: "", page: 1 })
                setDate({ startDate: null, endDate: null })
                setSelectedStatus([])
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
            >
              {tab.title}
            </motion.button>
          ))}
        </div>
      </div>
    </>
  )
}
