import { Breadcrumb, Search, Table, Calendar, Button } from "components/core"
import React from "react"
import useBeneficiary from "pages/beneficiary/useBeneficiary"
import { beneficiariesHistoryHeaders } from "constants/beneficiary"
import { motion } from "framer-motion"
import { Icon } from "@iconify/react"
import { ReactComponent as CsvFile } from "assets/svg/csv-file.svg"
import { fullDateFormatter } from "utils/dateFormatter"

const MotionTr = motion.tr

const UpdateBeneficiaryHistory = () => {
  const {
    breadCrumbs,
    beneficiaryHistoryDetails,
    date,
    setDate,
    beneficiaryHistory,
    isFetchingHistory,
    changePageHistory,
    selectLimitHistory,
    handleSearchHistory,
    handleDownloadSignedUrl,
    paramsHistory,
  } = useBeneficiary()

  return (
    <main data-testid="requesting-approval">
      <Breadcrumb options={breadCrumbs} showBack={true} />
      <h1 className="text-headers text-2xl font-bold">Beneficiary Update History</h1>
      <div className="flex items-center py-[24px] border-y mt-2">
        {beneficiaryHistoryDetails.map((details) => (
          <div key={details.title} className="flex items-center gap-4 mr-[40px]">
            <div className="relative w-10 h-10 grid place-items-center bg-grey-100 rounded-full">
              <Icon icon={details.icon} className="w-6 h-6 text-body" />
            </div>
            <div className="grid">
              <span className="text-sm text-body">{details.title}</span>
              <p className="text-base font-medium text-headers">{details.count}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="flex items-center justify-between mb-4 mt-6">
        <div className="flex space-x-2">
          <Search id="search" name="search" placeholder="Search" onChange={handleSearchHistory} />
          <Calendar startDate={date.startDate} endDate={date.endDate} onChange={setDate} selectsRange={true} />
        </div>
      </div>
      <Table
        headers={beneficiariesHistoryHeaders}
        data={beneficiaryHistory?.beneficiaries ?? []}
        onPageChange={changePageHistory}
        totalCount={Number(beneficiaryHistory?.total)}
        perPage={Number(paramsHistory?.limit)}
        page={Number(beneficiaryHistory?.currentPage)}
        selectLimit={selectLimitHistory}
        loading={isFetchingHistory}
        emptyStateText="No data yet"
        emptyStateImage={"layout"}
      >
        <>
          {beneficiaryHistory?.beneficiaries?.map((item: any) => (
            <React.Fragment key={item.id}>
              <MotionTr
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="cc-custom-table-data-row"
                data-testid={item.id}
              >
                <td className="flex items-center">
                  <div className="flex items-center">
                    <CsvFile className="ml-[-16px]" />
                    <p className="max-w-[280px] truncate capitalize">{item?.title || "-"}</p>
                  </div>
                </td>
                <td className="text-sm text-body font-normal">{item.file_number || "-"}</td>
                <td className="text-sm text-body font-normal">{fullDateFormatter(item.created_at)}</td>
                <td className="text-sm text-body font-normal">{item.found_beneficiaries}</td>
                <td className="text-sm text-body font-normal">{item.updated}</td>
                <td className="text-sm text-body font-normal">{item.not_updated}</td>
                <td className="w-[20px]">
                  <Button
                    onClick={() => handleDownloadSignedUrl(item.filename)}
                    className="border rounded-[4px] py-[6px] px-[12px] border-[#E6E6E6] cursor-pointer flex items-center gap-[8px]"
                  >
                    <Icon icon="ph:file-arrow-down" className="w-5 h-5" />
                    Download
                  </Button>
                </td>
              </MotionTr>
            </React.Fragment>
          ))}
        </>
      </Table>
    </main>
  )
}

export default UpdateBeneficiaryHistory
