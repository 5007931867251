import { Icon } from "@iconify/react"
import { Breadcrumb, Button, CardLoader, Filter, Search } from "components/core"
import { dummyRoles } from "mocks/roles"
import { RenderIf, Restricted } from "components/hoc"
import { RoleManagement, RoleCard } from "components/pages/RoleAndPermissions"
import useRolesAndPermissions from "./useRolesAndPermissions"
import { AllPermissions, PermissionModule } from "types/permission"

export default function RolesAndPermissions() {
  const { ROLES_AND_PERMISSION } = PermissionModule
  const { CREATE_ROLE } = AllPermissions
  const {
    breadCrumbs,
    handleSearch,
    filterOptions,
    selectedStatus,
    setSelectedStatus,
    setSelectedAction,
    selectedAction,
    roles,
    isFetchingRoles,
  } = useRolesAndPermissions()

  return (
    <main data-testid="roles-and-permissions">
      <div className="mb-[23px]">
        <Breadcrumb options={breadCrumbs} />
        <h1 className="text-headers text-2xl font-bold">Roles and Permissions</h1>
      </div>
      <div>
        <div className="flex items-center justify-between mb-[16px]">
          <div className="flex">
            <Search
              id="search"
              name="search"
              placeholder="Search by role name"
              onChange={handleSearch}
              className="mr-[8px]"
            />
            <Filter
              title="Status"
              options={filterOptions}
              selectedOption={selectedStatus}
              setSelectedOption={setSelectedStatus}
            />
          </div>
          <Restricted module={ROLES_AND_PERMISSION} permission={CREATE_ROLE}>
            <Button type="button" size="40" theme="primary" onClick={() => setSelectedAction("create")}>
              <Icon icon="ph:plus" className="w-6 h-6 mr-[8px]" />
              Create Role
            </Button>
          </Restricted>
        </div>
        <div>
          <RenderIf condition={isFetchingRoles}>
            <div className="grid gap-4 grid-cols-3">
              {dummyRoles.map((role) => (
                <CardLoader key={role?.title} />
              ))}
            </div>
          </RenderIf>
          <RenderIf condition={!isFetchingRoles && roles?.roles?.length! > 0}>
            <div className="grid gap-4 grid-cols-3 2xl:grid-cols-4">
              {roles?.roles?.map((role) => (
                <RoleCard
                  id={role.role_id}
                  key={role?.role_id}
                  title={role?.role_name}
                  subTitle={role?.description}
                  status={role?.status}
                  users={role?.top_staff}
                  otherStaffCount={Number(role?.staff_count) - role?.top_staff.length}
                />
              ))}
            </div>
          </RenderIf>
          <RenderIf condition={!isFetchingRoles && (roles?.roles?.length! < 1 || !roles)}>
            <div className="flex flex-col gap-2 py-36 w-full max-w-96 items-center mx-auto">
              <Icon icon="ph:grid-nine-fill" className="text-neutral-10 w-32 h-32" />
              <p className="text-base text-center text-neutral-base">
                Uh oh! It seems the role&apos;s desk is a little lonely. Time to bring some life into this corner of the
                universe!
              </p>
            </div>
          </RenderIf>
        </div>
      </div>
      {selectedAction === "create" && <RoleManagement title="create" onClose={() => setSelectedAction("")} />}
    </main>
  )
}
