import { Icon } from "@iconify/react"
import { Avatar, Breadcrumb, Button, Filter, ManageItem, Search, Table, Toggle, Status } from "components/core"
import useViewRole from "./useViewRole"
import { ReassignRole, RoleManagement } from "components/pages/RoleAndPermissions"
import { statuses } from "constants/staffs"
import { RenderIf, Restricted } from "components/hoc"
import { staffMembersHeaders } from "constants/roles"
import AddEditStaff from "components/pages/StaffManagements/AddEditStaff/AddEditStaff/AddEditStaff"
import { Calendar } from "components/core/DatePicker/DatePicker"
import { fullDateFormatter } from "utils/dateFormatter"
import HeaderLoader from "components/core/Loader/HeaderLoader/HeaderLoader"
import { AllPermissions, PermissionModule } from "types/permission"

export default function ViewRole() {
  const { ROLES_AND_PERMISSION } = PermissionModule
  const { DELETE_ROLE, DEACTIVATE_ROLE, EDIT_ROLE } = AllPermissions
  const {
    breadCrumbs,
    selectedAction,
    setSelectedAction,
    roleDetails,
    manageRole,
    staff,
    isDeletingRole,
    isUpdatingRoleStatus,
    role,
    handleSearch,
    selectedStatus,
    setSelectedStatus,
    changePage,
    isLoadingStaff,
    date,
    setDate,
    isExporting,
    exportStaff,
    setSelectedStaff,
    selectedStaff,
    refetchStaff,
    isLoadingRole,
    selectLimit,
    params,
  } = useViewRole()

  return (
    <main data-testid="view-role">
      <Breadcrumb options={breadCrumbs} showBack={true} />
      <RenderIf condition={isLoadingRole}>
        <HeaderLoader />
      </RenderIf>
      <RenderIf condition={!!role?.role_name && !isLoadingRole}>
        <h1 className="text-headers text-3xl font-bold mb-[12px] capitalize">{role?.role_name}</h1>
        <div className="flex justify-between items-end mb-[24px]">
          <p className="text-body text-base basis-[60%] capitalize-first">{role?.description}</p>
          <div className="flex items-center">
            <Restricted module={ROLES_AND_PERMISSION} permission={DELETE_ROLE}>
              <Button
                type="button"
                data-testid="deleteRole"
                onClick={() => setSelectedAction("deleteRole")}
                className="w-[40px] h-[40px]"
              >
                <Icon icon="ph:trash" className="w-[18px] h-[19.5px] text-paragraph" />
              </Button>
            </Restricted>
            <Restricted module={ROLES_AND_PERMISSION} permission={DEACTIVATE_ROLE}>
              <div className="ml-[8px] cc-button cc-button--transparent h-[40px] px-[16px]">
                <span className="mr-[8px]">{role?.status === "active" ? "Deactivate" : "Activate"} Role</span>
                <Toggle
                  size="20"
                  checked={role?.status === "active"}
                  onChange={() => setSelectedAction("updateRoleStatus")}
                />
              </div>
            </Restricted>
            <Restricted module={ROLES_AND_PERMISSION} permission={EDIT_ROLE}>
              <Button
                type="button"
                theme="transparent"
                className="ml-[8px]"
                size="40"
                onClick={() => setSelectedAction("editRole")}
              >
                <Icon icon="ph:pencil-simple-line" className="w-5 h-5 mr-[8px]" /> Edit Role
              </Button>
            </Restricted>
          </div>
        </div>
        <div className="flex items-center py-[24px] border-y">
          {roleDetails.map((details) => (
            <div key={details.title} className="flex items-center gap-4 mr-[40px]">
              <div className="relative w-10 h-10 grid place-items-center bg-grey-100 rounded-full">
                <Icon icon={details.icon} className="w-6 h-6 text-secondary-base" />
                <div className={`${details.theme} w-3 h-3 border border-white rounded-full absolute top-0 right-0`} />
              </div>
              <div className="grid">
                <span className="text-sm text-body">{details.title}</span>
                <p className="text-base font-medium text-headers">{details.count}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="pt-[36px] mb-[24px]">
          <h2 className="text-headers text-lg font-medium mb-[12px]">Staff Members</h2>
          <div className="flex items-center justify-between">
            <div className="flex space-x-2">
              <Search id="search" name="search" placeholder="Search" onChange={handleSearch} />
              <Filter
                title="Status"
                options={statuses}
                selectedOption={selectedStatus}
                setSelectedOption={setSelectedStatus}
                multiple={true}
              />
              <Calendar startDate={date.startDate} endDate={date.endDate} onChange={setDate} selectsRange={true} />
            </div>
            <div className="flex space-x-2">
              <Button type="button" size="40" disabled={isExporting} onClick={exportStaff} theme="transparent">
                <Icon icon="uiw:download" className="text-paragraph w-[18px] h-[18px] mr-3" />
                {isExporting ? "Exporting..." : "Export"}
              </Button>
              <Button type="button" size="40" theme="primary" onClick={() => setSelectedAction("addStaff")}>
                <Icon icon="ph:plus" className="w-[18px] h-[18px] mr-[8px]" />
                Add New
              </Button>
            </div>
          </div>
          <Table
            headers={staffMembersHeaders}
            paginateData={!!staff?.staff}
            data={staff?.staff || []}
            onPageChange={changePage}
            selectLimit={selectLimit}
            totalCount={staff?.count}
            perPage={params?.limit}
            page={Number(staff?.page ?? 0)}
            loading={isLoadingStaff}
          >
            <>
              {staff?.staff?.map((item) => (
                <tr data-testid={item?.staff_id} key={item?.staff_id} className="cc-custom-table-data-row">
                  <td>
                    <div className="flex items-center">
                      <Avatar
                        key={item?.id}
                        image={item?.url ?? `${item?.first_name} ${item?.last_name}`}
                        alt={item?.first_name}
                        size="24"
                      />
                      <div className="ml-[16px]">
                        <p className="max-w-[280px] truncate capitalize">
                          {item?.first_name} {item?.last_name}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <p className="w-max">{item?.email}</p>
                  </td>
                  <td>{fullDateFormatter(item?.added_on) ?? "N/A"}</td>
                  <td>
                    <Status status={item.status} />
                  </td>
                  <td className="w-[20px]">
                    <Button
                      type="button"
                      size="40"
                      className="px-[8.5px]"
                      onClick={() => {
                        setSelectedStaff(item)
                        setSelectedAction("editStaff")
                      }}
                    >
                      <Icon icon="ph:pencil-simple-line-bold" className="w-[15.63px] h-[15.63px]" />
                    </Button>
                  </td>
                </tr>
              ))}
            </>
          </Table>
        </div>
      </RenderIf>
      <RenderIf condition={selectedAction === "editRole"}>
        <RoleManagement title="edit" onClose={() => setSelectedAction("")} role={role} />
      </RenderIf>
      <RenderIf condition={selectedAction === "deleteRole"}>
        <ManageItem
          title="Do you want to delete the role?"
          actionName="Delete"
          cancelBtnText="Cancel"
          caption={`${
            staff?.staff?.length
              ? // eslint-disable-next-line max-len
                "Confirming this action will delete this role and you will have to re-assign every user connected to this role. Be sure you want to perform this action."
              : "Confirming this action will delete this role. Be sure you want to perform this action."
          }
            `}
          loading={isDeletingRole}
          onClose={() => setSelectedAction("")}
          onSubmit={manageRole}
        />
      </RenderIf>
      <RenderIf condition={selectedAction === "updateRoleStatus"}>
        <ManageItem
          title={`Do you want to ${role?.status === "active" ? "deactivate" : "activate"} the role?`}
          actionName={role?.status === "active" ? "Deactivate" : "Activate"}
          cancelBtnText="Cancel"
          // eslint-disable-next-line max-len
          caption={`Confirming this action will ${
            role?.status === "active" ? "deactivate" : "activate"
          } this role including all the Staff members assigned to the role`}
          loading={isUpdatingRoleStatus}
          onClose={() => setSelectedAction("")}
          onSubmit={manageRole}
        />
      </RenderIf>
      <RenderIf condition={selectedAction === "reassignRole"}>
        <ReassignRole roleId={role?.role_id} initialStaff={staff?.staff} onClose={() => setSelectedAction("")} />
      </RenderIf>
      <RenderIf condition={["editStaff", "addStaff"].includes(selectedAction)}>
        <AddEditStaff
          defaultRole={role?.role_name}
          refetch={refetchStaff}
          closeModal={() => setSelectedAction("")}
          action={selectedAction === "editStaff" ? "Edit" : "Add"}
          staff={selectedStaff}
        />
      </RenderIf>
    </main>
  )
}
