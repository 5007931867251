interface MessageBoxProps {
  sender: string
  time: string
  text: string
}

const MessageBox = ({ sender, time, text }: MessageBoxProps) => {
  return (
    <div>
      <div className="bg-[#E7F4E7] px-[19px] py-[16px] rounded-[16px] text-[#111827] rounded-tr-none">
        <span>{text}</span>
      </div>
      <div className="flex items-center gap-x-2 justify-end text-[#A0AEC0] font-medium text-xs">
        <span>{sender}</span>
        <div className=" bg-[#D9D9D9] rounded-full w-2 h-2" />
        <span>{time}</span>
      </div>
    </div>
  )
}

export default MessageBox
