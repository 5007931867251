import { Button, Modal, Dropdown, Textarea } from "components/core"
import { grievanceSatisfiedOptions } from "mocks/grievance"
import { useState } from "react"

interface ResolveProps {
  onClose: () => void
  loading?: boolean
  onSubmit: any
  grievance: any
}

const ResolveGrievance = ({ onClose, loading, grievance, onSubmit }: ResolveProps) => {
  const [formData, setFormData] = useState({
    complaint_satisfaction: "",
    complaint_resolved_details: "",
    complaint_feedback: "",
  })

  const handleSubmit = (e: any) => {
    e.preventDefault()
    onSubmit({ id: grievance?.grievance_id, payload: formData })
  }

  const isFormValid = Object.values(formData).every((value) => value !== "")

  return (
    <main>
      <Modal title="Resolve Grievance" position="modal-right" width="w-[432px]" closeModal={onClose}>
        <form onSubmit={handleSubmit}>
          <div className="modal-right-body flex flex-col gap-y-4">
            <Textarea
              label="How was this grievance resolved"
              id="complaint_resolved_details"
              name="complaint_resolved_details"
              rows={4}
              value={formData?.complaint_resolved_details ?? ""}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  complaint_resolved_details: e.target.value,
                })
              }
              maxLength={340}
            />
            <Dropdown
              label="Complaint Satisfaction"
              selected={formData?.complaint_satisfaction}
              options={grievanceSatisfiedOptions}
              onSelect={(data: any) => {
                setFormData({
                  ...formData,
                  complaint_satisfaction: data?.value,
                })
              }}
            />

            <Textarea
              label="Complainant Feedback"
              id="complaint_feedback"
              name="complaint_feedback"
              rows={4}
              value={formData?.complaint_feedback ?? ""}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  complaint_feedback: e.target.value,
                })
              }
              maxLength={340}
            />
          </div>
          <div className="modal-right-button-container mb-3">
            <Button
              theme="primary"
              className="min-w-[105px] mr-[8px] p-[8px]"
              type="submit"
              data-testid="submit-btn"
              loading={loading}
              disabled={loading || !isFormValid}
            >
              Save Changes
            </Button>
            <Button theme="transparent" className="w-[105px] px-[30px]" type="button" onClick={onClose}>
              Discard
            </Button>
          </div>
        </form>
      </Modal>
    </main>
  )
}

export default ResolveGrievance
