import { useTable } from "hooks"
import useDataSources from "hooks/useDataSources/useDataSources"
import { useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useGetStatesInZoneQuery } from "services/api/commonSlice"
import { useGetInterventionsQuery } from "services/api/interventionSlice"
import { formatCurrency } from "utils/formatCurrency"
import { stringifyArray } from "utils/stringifyArray"
import { AllPermissions } from "types/permission"

export default function useIntervention() {
  const [selectedStatus, setSelectedStatus] = useState<any>([])
  const [paymentType, setPaymentType] = useState<any>([])
  const [selectedState, setSelectedState] = useState<any>([])
  const [dataSource, setDataSource] = useState<any>([])
  const { handleSearch, changePage, params } = useTable()
  const loggedUserId = useSelector((state: any) => state.auth.staff_id)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const id = searchParams.get("reviewerId")
  const { dataSources, isFetchingSources } = useDataSources()
  const { EDIT_INTERVENTION, VIEW_SINGLE_INTERVENTION } = AllPermissions

  const actions = [
    {
      name: "Edit intervention",
      method: (data: any) => {
        navigate(`/intervention/new-intervention?interventionId=${data.intervention_id}`)
      },
      permission: EDIT_INTERVENTION,
    },
    {
      name: "View intervention",
      method: (data: any) => {
        navigate(`${data?.intervention_id}?category=additional-information`)
      },
      permission: VIEW_SINGLE_INTERVENTION,
    },
  ]
  const breadCrumbs = [{ title: "Interventions" }, { title: "Requesting Approvals" }]

  const { data: interventions, isFetching: isFetchingInterventions } = useGetInterventionsQuery({
    intervention_reviewer: id ?? "",
    search: params?.search ?? "",
    states: selectedState.length ? stringifyArray(selectedState, "id") : "",
    paymentType: paymentType.length ? stringifyArray(paymentType) : "",
    dataSource: dataSource.length ? stringifyArray(dataSource) : "",
    status: selectedStatus.length ? stringifyArray(selectedStatus) : "",
  })
  const { data: states, isLoading: isFetchingStates } = useGetStatesInZoneQuery({ ids: "1,2,3,4,5,6" })

  const nigerianStates = useMemo(
    () =>
      states?.map((item: any) => {
        return {
          label: `${item?.name}`,
          value: `${item?.name}`,
          id: item.id,
        }
      }) ?? [],
    [states],
  )
  const pendingInterventions = useMemo(
    () =>
      interventions?.interventions?.filter((item: any) =>
        ["pending_approval", "awaiting_start_date", "rejected", "failed", "processing", "approved"].includes(
          item.status,
        ),
      ) ?? [],
    [interventions],
  )

  const inProgressInterventions = useMemo(
    () => interventions?.interventions?.filter((item: any) => item.status === "in-progress") ?? [],
    [interventions],
  )

  const completedInterventions = useMemo(
    () => interventions?.interventions?.filter((item: any) => item.status === "completed") ?? [],
    [interventions],
  )

  const requestingApproval = useMemo(
    () =>
      interventions?.interventions?.filter(
        (item: any) => item.intervention_reviewer === loggedUserId && ["pending_approval"].includes(item.status),
      ) ?? [],
    [interventions, id],
  )

  const interventionDetails = [
    {
      title: "Total Interventions (Count)",
      icon: "ph:handshake",
      count: `${interventions?.allInterventions ?? 0}`,
    },
    {
      title: "Total Interventions (Value)",
      icon: "ph:handshake",
      count: `${formatCurrency(interventions?.sumOfAllInterventions ?? 0)}`,
    },
    {
      title: "Requesting Approval",
      icon: "cil:brush-alt",
      count: `${requestingApproval?.length}`,
    },
  ]

  return {
    interventionDetails,
    selectedStatus,
    paymentType,
    selectedState,
    params,
    interventions,
    isFetchingSources,
    dataSources,
    breadCrumbs,
    changePage,
    actions,
    pendingInterventions,
    inProgressInterventions,
    completedInterventions,
    loggedUserId,
    handleSearch,
    requestingApproval,
    dataSource,
    isFetchingInterventions,
    setDataSource,
    setSelectedState,
    setPaymentType,
    setSelectedStatus,
    nigerianStates,
    isFetchingStates,
  }
}
