import { RenderIf } from "components/hoc"
import React from "react"
import { fullDateFormatter } from "utils/dateFormatter"
import { DataResourceCardLoader } from "components/pages/DataMining"

interface ViewDataResourceCardProps {
  header?: string
  subTitle?: string
  logo?: string
  dateCreated?: string
  lastUpdated?: string
  totalMinedData?: string | number
  loading?: boolean
}

export const ViewDataResourceCard: React.FC<ViewDataResourceCardProps> = ({
  header,
  subTitle,
  dateCreated,
  lastUpdated,
  totalMinedData,
  logo,
  loading,
}) => {
  return (
    <>
      <RenderIf condition={!!loading}>
        <DataResourceCardLoader />
      </RenderIf>
      <RenderIf condition={!loading}>
        <div className="flex justify-between p-6 bg-primary-900 rounded-[8px] mt-3 gap-8 min-h-[194px]">
          <div className="flex flex-col justify-between">
            <div>
              <h3 className="text-white font-semibold text-2xl capitalize">{header}</h3>
              <p className="text-grey-300 mt-1 text-base line-clamp-2 text-ellipsis overflow-hidden">{subTitle}</p>
            </div>

            <div className="mt-6 flex divide-x child-not-last:pr-6 child-not-first:pl-6">
              <div className="flex flex-col">
                <span className="text-grey-300 text-xs font-[500]">Date Created</span>
                <span className="text-white text-sm mt-[2px]">{fullDateFormatter(dateCreated) || "-"}</span>
              </div>
              <div className="flex flex-col">
                <span className="text-grey-300 text-xs font-[500]">Last Updated</span>
                <span className="text-white text-sm mt-[2px]">{fullDateFormatter(lastUpdated) || "-"}</span>
              </div>
              <div className="flex flex-col">
                <span className="text-grey-300 text-xs font-[500]">Total Data Mined</span>
                <span className="text-white text-sm mt-[2px]">{totalMinedData}</span>
              </div>
            </div>
          </div>
          <div className="w-21 h-21">
            <RenderIf condition={!!logo}>
              <img src={logo} alt="" />
            </RenderIf>
          </div>
        </div>
      </RenderIf>
      <div className="bg-[#E6E6E6] h-[1px] my-6"></div>
    </>
  )
}
