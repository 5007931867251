export const reportHistoryHeader = [
  {
    name: "report title",
    sort: false,
    action: null,
  },
  {
    name: "date generated",
    sort: false,
    action: null,
  },
  {
    name: "generated by",
    sort: false,
    action: null,
  },
  {
    name: "",
    sort: false,
    action: null,
  },
]

export const interventionReportHeader = [
  {
    name: "intervention title",
    sort: false,
    action: null,
  },
  {
    name: "no. of state",
    sort: false,
    action: null,
  },
  {
    name: "no. of zone",
    sort: false,
    action: null,
  },
  {
    name: "no. of LGA",
    sort: false,
    action: null,
  },
  {
    name: "amount",
    sort: false,
    action: null,
  },
  {
    name: "status",
    sort: false,
    action: null,
  },
  {
    name: "created date",
    sort: false,
    action: null,
  },
  {
    name: "eligibility",
    sort: false,
    action: null,
  },
]

export const beneficiaryReportHeader = [
  {
    name: "beneficiary name",
    sort: false,
    action: null,
  },
  {
    name: "state",
    sort: false,
    action: null,
  },
  {
    name: "LGA",
    sort: false,
    action: null,
  },
  {
    name: "ward",
    sort: false,
    action: null,
  },
  {
    name: "community",
    sort: false,
    action: null,
  },
  {
    name: "status",
    sort: false,
    action: null,
  },
]

export const paymentUnitReportHeader = [
  {
    name: "Intervention",
    sort: false,
    action: null,
  },
  {
    name: "schedule id",
    sort: false,
    action: null,
  },
  {
    name: "payment cycle",
    sort: false,
    action: null,
  },
  {
    name: "date created",
    sort: false,
    action: null,
  },
  {
    name: "amount",
    sort: false,
    action: null,
  },
  {
    name: "status",
    sort: false,
    action: null,
  },
]

export const reviewDetailsStatus = [
  {
    label: "Paid",
    value: "paid",
  },
  {
    label: "In Progress",
    value: "in progress",
  },
  {
    label: "Unpaid",
    value: "unpaid",
  },
]

export const reviewStatus = [
  {
    label: "Approved",
    value: "approved",
  },
  {
    label: "Declined",
    value: "declined",
  },
  {
    label: "Pending account approval",
    value: "pending account approval",
  },
]

export const grevianceHistoryHeader = [
  {
    name: "Complainer Name",
    sort: false,
    action: null,
  },
  {
    name: "Category",
    sort: false,
    action: null,
  },
  {
    name: "Category Type",
    sort: false,
    action: null,
  },
  {
    name: "Assigned Officer",
    sort: false,
    action: null,
  },
  {
    name: "Level",
    sort: false,
    action: null,
  },
  {
    name: "Status",
    sort: false,
    action: null,
  },
  {
    name: "Created Date",
    sort: false,
    action: null,
  },
]
