import { Breadcrumb, Button } from "components/core"
import { EmptyState } from "components/core/Table/EmptyState/EmptyState"
import { RenderIf } from "components/hoc"
import { ReportDetails } from "components/pages/Reports"
import { PaymentStructure } from "pages/reports/account/AccountGenerateReport/components"
import { DateRange } from "./components/DateRange"
import { InterventionDetails } from "./components/InterventionDetails"
import { Preview } from "./components/Preview"
import { ReviewDetails } from "./components/ReviewDetails"
import useGenerateAuditReport from "./useGenerateAuditReport"

const GenerateAuditReport = () => {
  const { close, refresh, ...state } = useGenerateAuditReport()

  return (
    <>
      <div className="max-w-[1100px] w-full">
        <Breadcrumb options={state.breadcrumbs} showBack={true} />
        <div className="mb-[32px]">
          <h1 className="text-xl text-headers font-semibold mb-[8px]">Report Parameters</h1>
          <p className="text-sm text-body">
            Ensure that all information provided here are verified and correct before proceeding
          </p>
        </div>
      </div>
      <div>
        <ReportDetails state={state} />
        <hr className="ml-6 my-6" />
        <DateRange state={state} />
        <div className="flex justify-end">
          <Button
            theme="transparent"
            className="w-[171px] px-[30px]"
            type="button"
            size="44"
            disabled={state.disableApply}
            loading={state.isFetchingInterventions}
            onClick={state.getInterventions}
          >
            Apply
          </Button>
        </div>
        <RenderIf condition={!!state.interventions?.length && !state.isFetchingInterventions}>
          <InterventionDetails state={state} />
          <hr className="ml-6 my-6" />
          <ReviewDetails state={state} />
          <hr className="ml-6 my-6" />
          <PaymentStructure state={state} />
          <div className="flex justify-start items-center mt-8">
            <Button
              theme="primary"
              size="44"
              className="min-w-[171px] mr-[16px]"
              onClick={state.proceed}
              disabled={!state.formData.reportTitle || !state.formData.interventions?.length}
            >
              Proceed
            </Button>
            <Button theme="transparent" className="w-[171px] px-[30px]" type="button" size="44" onClick={refresh}>
              Refresh
            </Button>
          </div>
        </RenderIf>
        <RenderIf condition={state.interventions?.length === 0 && !state.isFetchingInterventions}>
          <EmptyState emptyStateText="No data found within the selected range" />
        </RenderIf>
      </div>
      <RenderIf condition={state.showPreview}>
        <Preview close={close} state={state} />
      </RenderIf>
    </>
  )
}

export default GenerateAuditReport
