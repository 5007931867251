import { Avatar, Breadcrumb, Button, Calendar, Filter, Search, Status, Table } from "components/core"
import useGroupMember from "./useGroupMember"
import { groupHeader } from "constants/livelihood"
import { Icon } from "@iconify/react"

export default function GroupMember() {
  const communityState = useGroupMember()

  return (
    // <Restricted module={ROLES_AND_PERMISSION} permission={VIEW_ALL_ROLES} showError={true}>
    <main>
      <div className="mb-[23px]">
        <Breadcrumb options={communityState.breadCrumbs} showBack={true} />
        <div className="flex items-center mb-[32px]">
          <Avatar image="Aminat Adeola" alt="{officer?.name}" size="89" />
          <div className="ml-[10px]">
            <p className="capitalize text-headers text-2xl font-semibold mb-[8px]">Aminat Adeola</p>
            <Status status="Tiger Coporative" className="bg-grey-100" />
            <p className="text-xs font-medium text-grey-base mt-[8px]">CC-12345-BS</p>
          </div>
        </div>
        <div className="p-6 bg-primary-900 rounded-[8px] mt-3 gap-8 mb-[36px]">
          <div className="grid grid-cols-5 divide-x child-not-last:pr-6 child-not-first:pl-6">
            {communityState.overviewTop.map((item) => (
              <div key={item.name}>
                <p className="text-primary-100 text-xs font-medium">{item?.name}</p>
                <p className="text-primary-30 text-sm mt-[2px]">{item?.value || "N/A"}</p>
              </div>
            ))}
          </div>
          <hr className="my-[24px]" />
          <div className="grid grid-cols-5 divide-x child-not-last:pr-6 child-not-first:pl-6">
            {communityState.overviewBottom.map((item) => (
              <div key={item.name}>
                <p className="text-primary-100 text-xs font-medium">{item?.name}</p>
                <p className="text-primary-30 text-sm mt-[2px]">{item?.value || "N/A"}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div>
        <div className="flex items-center justify-between mb-[16px]">
          <div className="flex items-center">
            <Search
              id="search"
              name="search"
              placeholder="Search"
              onChange={communityState.handleSearch}
              className="mr-[8px]"
            />
            <Filter
              title="Status"
              options={[
                { label: "Paid", value: "paid" },
                { label: "Unpaid", value: "unpaid" },
              ]}
              selectedOption={communityState.selectedStatus}
              setSelectedOption={communityState.setSelectedStatus}
              containerClass="w-[120px] mr-[8px]"
            />
            <Calendar
              startDate={communityState.date.startDate}
              endDate={communityState.date.endDate}
              onChange={communityState.setDate}
              selectsRange={true}
            />
          </div>
          <Button type="button" size="40" disabled={false} onClick={communityState.exportHistory} theme="transparent">
            <Icon icon="uiw:download" className="text-paragraph w-[18px] h-[18px] mr-3" />
            {/* {isExporting ? "Exporting..." : "Export"} */}
            Export
          </Button>
        </div>
        <Table
          headers={groupHeader}
          paginateData={!!communityState.history}
          data={communityState?.history?.history || []}
          onPageChange={communityState.changePage}
          selectLimit={communityState.selectLimit}
          totalCount={communityState?.history?.total}
          perPage={communityState.params.limit}
          page={communityState.params.page}
          loading={false}
        >
          <>
            {/* {beneficiaries?.beneficiaries?.map((item) => (
                            <tr data-testid={item?.nin} key={item?.nin} className="cc-custom-table-data-row">
                                <td className="flex items-center ">
                                    <div className="flex items-center">
                                        <Avatar
                                            key={item?.nin}
                                            //image={item?.profile_pic || item?.name}
                                            alt={item?.name}
                                            size="24"
                                        />
                                        <div className="ml-[16px]">
                                            <p title={item?.name} className="max-w-[200px] truncate capitalize">
                                                {item?.name}
                                            </p>
                                            <p className="text-grey-base text-xs">{item?.household_id}</p>
                                        </div>
                                    </div>
                                </td>
                                <td className="capitalize-first">{item?.state || "N/A"}</td>
                                <td>{fullDateFormatter(item?.date_of_birth) || "N/A"}</td>
                                <td>{item?.nin || "N/A"}</td>
                                <td>{item?.bvn || "N/A"}</td>
                                <td>{item?.account_number || "N/A"}</td>
                                <td className="capitalize-first">{item?.gender || "N/A"}</td>
                                <td className="uppercase">{item?.source_register || "N/A"}</td>
                            </tr>
                        ))} */}
          </>
        </Table>
      </div>
    </main>
    // </Restricted>
  )
}
