import { SingleBeneficiary } from "types/beneficiary"
import React from "react"
import getFirstTwoNames from "utils/getFirstTwoNames"
import { fullDateFormatter } from "utils/dateFormatter"

interface BeneficiaryDetailsCardProps {
  beneficiary: SingleBeneficiary
  currentTab: string | null
}

const BeneficiaryDetailsCard: React.FC<BeneficiaryDetailsCardProps> = ({ beneficiary, currentTab }) => {
  const beneficiaryDetailsCardHeaders = [
    "Gender",
    "Date of Birth",
    "Age",
    "State",
    "LGA",
    "Ward",
    currentTab === "unbanked-beneficiary" ? "Error Remark(s)" : "Household ID",
    "NIN",
    "BVN",
    "Bank Name",
    "Account Name",
    "Account Number",
  ]

  const upperPartHeaders = beneficiaryDetailsCardHeaders.slice(0, 6)
  const lowerPartHeaders = beneficiaryDetailsCardHeaders.slice(6)

  const upperDetails = [
    beneficiary?.gender,
    fullDateFormatter(beneficiary?.date_of_birth),
    beneficiary?.age,
    beneficiary?.state,
    beneficiary?.lga,
    beneficiary?.ward,
  ]

  const lowerDetails = [
    currentTab === "unbanked-beneficiary" ? beneficiary?.error_remark?.join(", ") : beneficiary?.household_id,
    beneficiary?.nin || "-",
    beneficiary?.bvn || "-",
    beneficiary?.bank || "-",
    getFirstTwoNames(beneficiary?.account_name) || "-",
    beneficiary?.account_number || "-",
  ]

  return (
    <div className="bg-primary-900 min-h-[172px] rounded-lg p-6 flex flex-col justify-between">
      <div className="grid grid-cols-6 divide-x-[0.5px] divide-[#E6E6E6] child-not-first:pl-4">
        {upperPartHeaders?.map((header, index) => (
          <div className="flex  flex-col gap-[2px]" key={header}>
            <span className="text-[#C6E4C4] text-xs font-medium">{header}</span>
            <p className="text-primary-30 font-normal text-sm mt-[2px] capitalize">{upperDetails[index] ?? "-"}</p>
          </div>
        ))}
      </div>
      <div className="h-[0.5px] bg-grey-200 my-6"></div>
      <div className="grid grid-cols-6 divide-x-[0.5px] divide-[#E6E6E6] child-not-first:pl-4">
        {lowerPartHeaders?.map((header, index) => (
          <div className="flex  flex-col gap-[2px]" key={header}>
            <span className="text-[#C6E4C4] text-xs font-medium">{header}</span>
            <p className="text-primary-30 font-normal text-sm mt-[2px] capitalize">{lowerDetails[index] ?? "-"}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default BeneficiaryDetailsCard
