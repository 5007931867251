import { useState } from "react"
import { useTable } from "hooks"
import { DateType } from "types/data"
import { useGetTransactionsQuery, useGetTransactionReportQuery } from "services/api/paymentSlice"
import { shortDate, formatTimestamp } from "utils/dateFormatter"
import { toast } from "react-toastify"
import { tryCatch } from "utils/tryCatch"
import { exportData } from "utils/exportData"
import { addCommasToNumbers } from "utils/addCommasToNumbers"

export default function useTransactions() {
  const [selectedStatus, setSelectedStatus] = useState<any>([])
  const [selectedAction, setSelectedAction] = useState("")
  const [date, setDate] = useState<DateType>({ startDate: null, endDate: null })
  const [isExportingLoading, setIsExportingLoading] = useState<boolean>(false)

  const { handleSearch, params, changePage, selectLimit } = useTable()

  const { data } = useGetTransactionReportQuery({})

  const breadCrumbs = [{ title: "Payments" }, { title: "Transactions" }]
  const TransactionsSummaryInfo = [
    {
      title: "Total Transactions Count",
      count: data?.total_transaction_count ?? 0,
      indicator: null,
    },
    {
      title: "Total Transactions Amount",
      count: addCommasToNumbers(Number(data?.total_transaction_amount ?? 0)),
      indicator: "null",
    },
  ]

  const { data: transactions, isFetching: isFetchingTransactions } = useGetTransactionsQuery({
    search: params?.search ?? "",
    page: params?.page,
    limit: params?.limit,
    from: shortDate(date?.startDate) ?? shortDate(date?.endDate) ?? "",
    to: shortDate(date?.endDate) ?? shortDate(date?.startDate) ?? "",
  })

  const exportTransactionData = () => {
    setIsExportingLoading(true)
    if (isFetchingTransactions) {
      toast.error("Beneficiaries are still being fetched")
      setIsExportingLoading(false)
      return
    }

    const data = transactions?.transactions

    if (!data || data.length === 0) {
      toast.error("There are no Transactions in this payment schedule")
      setIsExportingLoading(false)
      return
    }

    const download_data = data.map((item: any) => {
      return {
        "Payment Schedule Id": item?.schedule_reference,
        "Total Amount": `${item?.currency ?? ""} ${Number(item?.amount)}`,
        "Date & Time": formatTimestamp(item?.created_at)?.replace(/,/g, "") ?? "--",
        "Transaction Narration": item?.narration,
      }
    })

    tryCatch(() => {
      exportData(download_data, "Transactions Data")
    })
    setIsExportingLoading(false)
  }

  return {
    date,
    setDate,
    selectedStatus,
    setSelectedStatus,
    selectedAction,
    setSelectedAction,
    breadCrumbs,
    TransactionsSummaryInfo,
    handleSearch,
    params,
    changePage,
    selectLimit,
    transactions,
    isFetchingTransactions,
    exportTransactionData,
    isExportingLoading,
    setIsExportingLoading,
  }
}
