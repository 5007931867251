import { useState } from "react"
import { useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { useTable } from "hooks"
import { addCommasToNumbers } from "utils/addCommasToNumbers"
import { useGetTransactionBeneficiariesQuery } from "services/api/paymentSlice"
import { stringifyArrayII } from "utils/stringifyArray"

export default function useTransactionBatch() {
  const { batchId } = useParams()
  const [selectedGender, setSelectedGender] = useState<any>([])

  const breadCrumbs = [{ title: "Transactions" }, { title: `Transaction Batch Beneficiaries` }]

  const { params, changePage, selectLimit, handleSearch } = useTable()

  const { data: transactionsBeneficiaries, isFetching: isFetchingTransactionBeneficiaries } =
    useGetTransactionBeneficiariesQuery({
      id: batchId,
      params: {
        page: params?.page,
        limit: params?.limit,
        search: params?.search,
        gender: selectedGender?.length ? stringifyArrayII(selectedGender) : "",
      },
    })

  const transactionInfo = useSelector((state: any) => state?.transactions)

  const transactionInfoTitle = transactionInfo?.narration

  const transactionInfoData = [
    transactionInfo?.batch_reference,
    addCommasToNumbers(Number(transactionInfo?.batch_beneficiaries_count)),
    addCommasToNumbers(Number(transactionInfo?.total_amount_paid)),
    transactionInfo?.fee_amount
      ? `${transactionInfo?.currency ?? ""} ${addCommasToNumbers(Number(transactionInfo?.fee_amount))} `
      : "NGN 0",
  ]

  return {
    breadCrumbs,
    transactionInfoData,
    params,
    changePage,
    selectLimit,
    handleSearch,
    selectedGender,
    setSelectedGender,
    transactionsBeneficiaries,
    isFetchingTransactionBeneficiaries,
    transactionInfoTitle,
  }
}
