import { useDeclinePaymentScheduleMutation } from "services/api/paymentSlice"
import { toast } from "react-toastify"
import { tryCatch } from "utils/tryCatch"

export default function useDeclineModal({ closeModal }: { closeModal: () => void }) {
  const [declinePaymentSchedule, { isLoading: isDecliningPaymentSchedule }] = useDeclinePaymentScheduleMutation({})

  const declineApproval = async (data: any) => {
    const callback = (res: any) => {
      if ([200, 201].includes(res?.code)) {
        closeModal()
        toast.success(res.message)
      } else {
        toast.error(res.message)
      }
      closeModal()
    }

    tryCatch(async () => {
      let res: any = await declinePaymentSchedule(data).unwrap()
      callback(res)
    })
  }

  return { declineApproval, isDecliningPaymentSchedule }
}
