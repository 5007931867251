import { Status } from "components/core"
import { ReportPreviewLayout } from "components/layouts/Reports"
import { fullDateFormatter } from "utils/dateFormatter"
import { formatCurrency } from "utils/formatCurrency"

interface Props {
  close: () => void
  state: any
}

export const Preview = ({ close, state }: Props) => {
  const { formData, date, isGeneratingReport, generateReport } = state

  return (
    <ReportPreviewLayout
      close={close}
      loading={isGeneratingReport}
      generateReport={generateReport}
      name={formData?.reportTitle}
    >
      <>
        <div className="flex mb-[24px]">
          <p className="text-base text-body w-[245px] shrink-0">Intervention Title:</p>
          <p className="text-base text-headers">
            {formData.interventions[0].label}{" "}
            {!!(formData.interventions?.length - 1) && <span>+{formData.interventions?.length - 1}</span>}
          </p>
        </div>
        {!!formData?.status?.length && (
          <div className="flex  mb-[24px]">
            <p className="text-base text-body w-[245px] shrink-0">Status:</p>
            <div className="flex flex-wrap">
              {formData?.status.map((item: any) => (
                <Status key={item?.label} status={item?.label} className="mr-[8px] mb-[8px] bg-[#E3E5EB] text-body" />
              ))}
            </div>
          </div>
        )}
        {!!formData?.additionalEligibility?.length && (
          <div className="flex mb-[24px]">
            <p className="text-base text-body w-[245px] shrink-0">Additional Eligibility:</p>
            <div className="flex flex-wrap">
              {formData?.additionalEligibility.map((item: any) => (
                <Status key={item?.label} status={item?.label} className="mr-[8px] mb-[8px] bg-[#E3E5EB] text-body" />
              ))}
            </div>
          </div>
        )}
        {date.createdDate.startDate && (
          <div className="flex mb-[24px]">
            <p className="text-base text-body w-[245px] shrink-0">Date Created:</p>
            <p className="text-base text-headers">
              {fullDateFormatter(date.createdDate.startDate)} - {fullDateFormatter(date.createdDate.endDate)}
            </p>
          </div>
        )}
        {date.reviewDate.startDate && (
          <div className="flex mb-[24px]">
            <p className="text-base text-body w-[245px] shrink-0">Review Created:</p>
            <p className="text-base text-headers">
              {fullDateFormatter(date.reviewDate.startDate)} - {fullDateFormatter(date.reviewDate.endDate)}
            </p>
          </div>
        )}
        {date.startDate.startDate && (
          <div className="flex mb-[24px]">
            <p className="text-base text-body w-[245px] shrink-0">Start Date:</p>
            <p className="text-base text-headers">
              {fullDateFormatter(date.startDate.startDate)} - {fullDateFormatter(date.startDate.endDate)}
            </p>
          </div>
        )}
        {date.endDate.startDate && (
          <div className="flex mb-[24px]">
            <p className="text-base text-body w-[245px] shrink-0">End Date:</p>
            <p className="text-base text-headers">
              {fullDateFormatter(date.endDate.startDate)} - {fullDateFormatter(date.endDate.endDate)}
            </p>
          </div>
        )}
        {!!formData?.zones?.length && (
          <div className="flex mb-[24px]">
            <p className="text-base text-body w-[245px] shrink-0">Zones:</p>
            <div className="flex flex-wrap">
              {formData?.zones.map((item: any) => (
                <Status key={item?.label} status={item?.label} className="mr-[8px] mb-[8px] bg-[#E3E5EB] text-body" />
              ))}
            </div>
          </div>
        )}
        {!!formData.states?.length && (
          <div className="flex mb-[24px]">
            <p className="text-base text-body w-[245px] shrink-0">States:</p>
            <div className="flex flex-wrap">
              {formData?.states.map((item: any) => (
                <Status key={item?.label} status={item?.label} className="mr-[8px] mb-[8px] bg-[#E3E5EB] text-body" />
              ))}
            </div>
          </div>
        )}
        {!!formData.lga?.length && (
          <div className="flex mb-[24px]">
            <p className="text-base text-body w-[245px] shrink-0">Local Government Area:</p>
            <div className="flex flex-wrap">
              {formData?.lga.map((item: any) => (
                <Status key={item?.label} status={item?.label} className="mr-[8px] mb-[8px] bg-[#E3E5EB] text-body" />
              ))}
            </div>
          </div>
        )}
        {formData.eligibilityAmountFrom && (
          <div className="flex mb-[24px]">
            <p className="text-base text-body w-[245px] shrink-0">Eligibility Amount:</p>
            <p className="text-base text-headers">
              {formatCurrency(formData.eligibilityAmountFrom)} - {formatCurrency(formData.eligibilityAmountTo)}
            </p>
          </div>
        )}
        {!!formData.paymentType?.length && (
          <div className="flex mb-[24px]">
            <p className="text-base text-body w-[245px] shrink-0">Payment Type:</p>
            <div className="flex flex-wrap">
              {formData?.paymentType?.map((item: any) => (
                <Status key={item?.label} status={item?.label} className="mr-[8px] mb-[8px] bg-[#E3E5EB] text-body" />
              ))}
            </div>
          </div>
        )}
        {!!formData.currency?.length && (
          <div className="flex mb-[24px]">
            <p className="text-base text-body w-[245px] shrink-0">Payment Type:</p>
            <div className="flex flex-wrap">
              {formData?.currency?.map((item: any) => (
                <Status key={item?.label} status={item?.label} className="mr-[8px] mb-[8px] bg-[#E3E5EB] text-body" />
              ))}
            </div>
          </div>
        )}
        {formData.totalAmountFrom && (
          <div className="flex">
            <p className="text-base text-body w-[245px] shrink-0">Total Amount:</p>
            <p className="text-base text-headers">
              {formatCurrency(formData.totalAmountFrom)} - {formatCurrency(formData.totalAmountTo)}
            </p>
          </div>
        )}
      </>
    </ReportPreviewLayout>
  )
}
