import { Outlet, createBrowserRouter } from "react-router-dom"
import { DashboardLayout } from "components/layouts"
import { AuthLayout } from "components/layouts/AuthLayout/AuthLayout"
import Login from "pages/auth/Login/Login"
import ResetPassword from "pages/auth/ResetPassword/ResetPassword"
import CreatePassword from "pages/auth/CreatePassword/CreatePassword"
import DataMining from "pages/data/DataExchange/DataMining"
import ViewDataSource from "pages/data/ViewDataSource/ViewDataSource"
import {
  livelihoodRoutes,
  coResponsibilityRoutes,
  interventionRoutes,
  settingsRoutes,
  paymentsRoutes,
  beneficiaryRoutes,
  reportsRoutes,
  trainingRoutes,
  grievanceRoutes
} from "./modules"

const router = createBrowserRouter([
  {
    path: "",
    errorElement: <div>Something went wrong</div>,
    element: (
      <AuthLayout>
        <Outlet />
      </AuthLayout>
    ),
    children: [
      {
        path: "/",
        element: <Login />,
      },
      {
        path: "/reset-password",
        element: <ResetPassword />,
      },
      {
        path: "/create-password",
        element: <CreatePassword />,
      },
    ],
  },
  {
    path: "/",
    errorElement: <div>Something went wrong</div>,
    element: (
      <DashboardLayout>
        <Outlet />
      </DashboardLayout>
    ),
    children: [
      {
        path: "dashboard",
        element: <p>dashboard</p>,
      },
      ...livelihoodRoutes,
      ...coResponsibilityRoutes,
      ...interventionRoutes,
      ...settingsRoutes,
      ...paymentsRoutes,
      ...beneficiaryRoutes,
      ...reportsRoutes,
      ...trainingRoutes,
      ...grievanceRoutes,
      {
        path: "data-mining",
        element: <DataMining />,
      },
      {
        path: "data-mining/:id",
        element: <ViewDataSource />,
      },
    ],
  },
  {
    path: "*",
    element: <div>Page Not Found!</div>,
  },
])

export { router }
