import { useTable } from "hooks"
import { useParams } from "react-router-dom"
import { useGetReportQuery, useLazyExportReportQuery } from "services/api/reportSlice"
import { addCommasToNumbers } from "utils/addCommasToNumbers"
import { exportData } from "utils/exportData"
import { formatCurrency } from "utils/formatCurrency"
import { removeSpecificKeys } from "utils/removeSpecificKeys"
import { tryCatch } from "utils/tryCatch"

export default function useBeneficiaryReport() {
  const { reportId } = useParams()
  const { params, changePage, selectLimit } = useTable()
  const { data: report, isLoading: isLoadingReport } = useGetReportQuery({
    reportId,
    ...removeSpecificKeys(params, ["sortBy"]),
  })
  const [exportReportQuery, { isFetching: isExporting }] = useLazyExportReportQuery()

  const breadcrumbs = [{ title: "Report" }, { title: "Beneficiary" }]

  const overview = [
    {
      title: "Total Beneficiary List",
      count: addCommasToNumbers(report?.data?.result?.totalBeneficiaryCount ?? 0),
      icon: "ph:users-three",
    },
    {
      title: "Total Number of States",
      count: addCommasToNumbers(report?.data?.result?.totalNumberOfStates ?? 0),
      icon: "ph:handshake",
    },
    {
      title: "Total Intervention Amount ",
      count: formatCurrency(report?.data?.result?.totalInterventionAmount ?? 0),
      icon: "ph:handshake",
    },
    {
      title: "Total Eligibility Amount ",
      count: formatCurrency(report?.data?.result?.totalEligibilityAmount ?? 0),
      icon: "ph:handshake",
    },
  ]

  const exportReport = async () => {
    tryCatch(async () => {
      const res = await exportReportQuery(reportId).unwrap()

      const data = res?.data?.interventions?.map((item: any) => {
        return {
          "beneficiary name": item?.beneficiary_name,
          state: item?.state ?? "N/A",
          LGA: item?.lga ?? "N/A",
          ward: item?.ward ?? "N/A",
          community: item?.community ?? "N/A",
          status: item?.status,
        }
      })
      exportData(data, `${report?.data?.reportTitle} - Beneficiary Report`)
    })
  }

  return {
    breadcrumbs,
    overview,
    changePage,
    selectLimit,
    params,
    report,
    isLoadingReport,
    isExporting,
    exportReport,
  }
}
