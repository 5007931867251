import { Button, Modal } from "components/core"
import "./matchVariables.css"
import useMatchVariables from "./useMatchVariables"
import { RenderIf } from "components/hoc"

interface MatchVariablesProps {
  onClose: () => void
  // eslint-disable-next-line no-unused-vars
  setSelectedAction: (data: any) => void
  setSelectedConfiguration: () => void
  title: string
  formData: any
  configuration: any
  file?: any
}

export const MatchVariables = ({
  onClose,
  setSelectedAction,
  title,
  formData,
  configuration,
  setSelectedConfiguration,
  file,
}: MatchVariablesProps) => {
  const { currentTab, setCurrentTab, tabs, handleChange, isLoadingDatasets, isLoading, household, members, onSubmit } =
    useMatchVariables({ setSelectedAction, title, formData, configuration, setSelectedConfiguration, file })

  return (
    <Modal title="Match variables" position="modal-right" width="w-[631px]" closeModal={onClose}>
      <form className="relative" onSubmit={onSubmit}>
        <div className="">
          <div className="p-[16px]">
            <div className="bg-[#E3E5EB] p-[4px] grid grid-cols-2 rounded-[4px] sticky top-[-24px]">
              {tabs.map((tab) => (
                <button
                  key={tab}
                  type="button"
                  className={`
                            text-sm font-medium ${
                              currentTab === tab ? "bg-white rounded-[4px] py-[14px] text-headings" : "text-paragraph"
                            }
                            `}
                  onClick={() => setCurrentTab(tab)}
                >
                  {tab}
                </button>
              ))}
            </div>
          </div>
          <div className="mv-table-container">
            <table className="mv-table">
              <thead>
                <tr className="text-base text-headings font-semibold">
                  <th className="mv-text">
                    <p>Table columns</p>
                  </th>
                  <th className="mv-text">
                    <p>Variable Assignment</p>
                  </th>
                </tr>
              </thead>
              <tbody className="text-sm text-body font-normal">
                {isLoadingDatasets && (
                  <tr>
                    <td colSpan={2} className="text-center py-[20px]">
                      Loading...
                    </td>
                  </tr>
                )}
                <RenderIf condition={currentTab === "Household" && !isLoadingDatasets}>
                  {Object.entries(household).map(([key]) => (
                    <tr key={key}>
                      <td className="mv-text">
                        <p>{key}</p>
                      </td>
                      <td>
                        <input
                          name={key}
                          id={key}
                          data-testid={key}
                          placeholder="Input match"
                          value={household[key]}
                          className="mv-input"
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                  ))}
                </RenderIf>
                <RenderIf condition={currentTab === "Members" && !isLoadingDatasets}>
                  {Object.entries(members).map(([key]) => (
                    <tr key={key}>
                      <td className="mv-text">
                        <p>{key}</p>
                      </td>
                      <td>
                        <input
                          name={key}
                          id={key}
                          data-testid={key}
                          placeholder="Input match"
                          value={members[key]}
                          className="mv-input"
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                  ))}
                </RenderIf>
              </tbody>
            </table>
          </div>
        </div>
        <div className="modal-right-button-container">
          <Button
            theme="primary"
            size="44"
            className="min-w-[105px] mr-[8px] capitalize"
            loading={isLoading}
            disabled={isLoadingDatasets}
          >
            Match
          </Button>
          <Button
            theme="transparent"
            size="44"
            className="min-w-[105px] mr-[8px] capitalize"
            type="button"
            onClick={onClose}
            disabled={isLoading}
            //disabled={button.disabled}
          >
            Discard
          </Button>
        </div>
      </form>
    </Modal>
  )
}
