import { useState } from "react"
import { useFetchGrievanceAnalyticsQuery } from "services/api/grievanceSlice"

export default function useGrievanceStatistics() {
  const [viewGrievanceStats, setViewGrievanceStats] = useState(false)

  const { data: grievanceStats, isLoading: isLoadingAnalytics } = useFetchGrievanceAnalyticsQuery({})

  function handleToggle() {
    setViewGrievanceStats((viewGrievanceStats) => !viewGrievanceStats)
  }

  const statisticsData = [
    {
      title: "Total Grievances",
      count: grievanceStats?.total_grievance ?? 0,
      viewStats: true,
    },
    {
      title: "Pending Grievances",
      count: grievanceStats?.grievance_pending ?? 0,
      viewStats: false,
    },
    {
      title: "Resolved Grievances",
      count: grievanceStats?.grievance_resolved ?? 0,
      viewStats: false,
    },
  ]

  const statisticsBreakDown = [
    {
      title: "National Grievance",
      stats: [
        {
          title: "Total",
          count: grievanceStats?.national_grievance?.total ?? 0,
        },
        {
          title: "Pending",
          count: grievanceStats?.national_grievance?.pending ?? 0,
        },
        {
          title: "Resolved",
          count: grievanceStats?.national_grievance?.resolved ?? 0,
        },
      ],
    },
    {
      title: "State Grievance",
      stats: [
        {
          title: "Total",
          count: grievanceStats?.state_grievance?.total ?? 0,
        },
        {
          title: "Pending",
          count: grievanceStats?.state_grievance?.pending ?? 0,
        },
        {
          title: "Resolved",
          count: grievanceStats?.state_grievance?.resolved ?? 0,
        },
      ],
    },
    {
      title: "Local Grievance",
      stats: [
        {
          title: "Total",
          count: grievanceStats?.local_grievance?.total ?? 0,
        },
        {
          title: "Pending",
          count: grievanceStats?.local_grievance?.pending ?? 0,
        },
        {
          title: "Resolved",
          count: grievanceStats?.local_grievance?.resolved ?? 0,
        },
      ],
    },
  ]

  return {
    viewGrievanceStats,
    setViewGrievanceStats,
    handleToggle,
    statisticsData,
    statisticsBreakDown,
    isLoadingAnalytics,
    grievanceStats,
  }
}
