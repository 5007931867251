import { ChangeEvent, FormEvent, useEffect, useState } from "react"
import { useGetDatasetsQuery } from "services/api/dataSlice"
import { tryCatch } from "utils/tryCatch"
import { useCreateConfigurationMutation, useUpdateConfigurationMutation } from "services/api/configurationSlice"
import { toast } from "react-toastify"
import { Configuration } from "types/configuration"
import { removeKeysWithEmptyValues } from "utils/objectFormatter"
import dayjs from "dayjs"
import { useUpload } from "hooks"
import { useGetPreSignedUrlMutation } from "services/api/staffSlice"

interface UseMatchVariables {
  // eslint-disable-next-line no-unused-vars
  setSelectedAction: (e: any) => void
  setSelectedConfiguration: () => void
  title: string
  formData: any
  configuration: Configuration
  file?: any
}

export default function useMatchVariables({
  title,
  setSelectedAction,
  formData,
  configuration,
  setSelectedConfiguration,
  file,
}: UseMatchVariables) {
  const { upload, uploading } = useUpload()
  const [currentTab, setCurrentTab] = useState("Household")
  const [household, setHousehold] = useState<any>({})
  const [members, setMembers] = useState<any>({})
  const tabs = ["Household", "Members"]
  const [getPreSignedUrl, { isLoading: isLoadingSignedUrl }] = useGetPreSignedUrlMutation()
  const { data: fetchedDatasets, isLoading: isLoadingDatasets } = useGetDatasetsQuery()
  const [createConfiguration, { isLoading: isCreatingConfig }] = useCreateConfigurationMutation()
  const [updateConfiguration, { isLoading: isEditingConfig }] = useUpdateConfigurationMutation()

  const swapObjectKeysAndValues = (obj: any) => {
    let result: { [key: string]: any } = {}
    Object.entries(obj).map(([key, value]) => {
      return (result[value + ""] = key)
    })
    return result
  }

  const convertArrayOfStringToObject = (arrayOfString: any) => {
    const h = arrayOfString.map((item: any) => {
      return { [item]: "" }
    })
    const result: { [key: string]: any } = {}
    h.forEach((obj: any) => {
      Object.entries(obj).map(([key, value]) => {
        return (result[key + ""] = value)
      })
    })
    return result
  }

  useEffect(() => {
    if (fetchedDatasets?.household?.length) {
      let result
      if (title === "edit") {
        const prev = swapObjectKeysAndValues(configuration?.dataset?.household)
        const fetchedHousehold = convertArrayOfStringToObject(fetchedDatasets.household)
        result = { ...fetchedHousehold, ...prev }
      } else {
        result = convertArrayOfStringToObject(fetchedDatasets.household)
      }
      setHousehold(result)
    }
    if (fetchedDatasets?.member?.length) {
      let result
      if (title === "edit") {
        const prev = swapObjectKeysAndValues(configuration?.dataset?.member)
        const fetchedMember = convertArrayOfStringToObject(fetchedDatasets.member)
        result = { ...fetchedMember, ...prev }
      } else {
        result = convertArrayOfStringToObject(fetchedDatasets.member)
      }
      setMembers(result)
    }
  }, [fetchedDatasets])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    currentTab === "Household"
      ? setHousehold({ ...household, [name]: value })
      : setMembers({ ...members, [name]: value })
  }

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    tryCatch(async () => {
      let logo_url = ""
      const enhancedHousehold = swapObjectKeysAndValues(removeKeysWithEmptyValues({ ...household }))
      const enhancedMember = swapObjectKeysAndValues(removeKeysWithEmptyValues({ ...members }))

      let payload = {
        ...formData,
        handler: formData?.configurationName.toLowerCase(),
        dataset: { household: enhancedHousehold, member: enhancedMember },
      }

      if (file) {
        let timestamp = dayjs()?.format("YYYYMMDD-HHmmss")
        let formData = { logo: `${timestamp}${file?.name}` }
        const resSignedUrl = await getPreSignedUrl(formData).unwrap()
        const res = await upload({ file, timestamp, res: resSignedUrl })

        if (res?.response?.status === 200) {
          logo_url = res?.url
        }
      }

      if ((file && logo_url) || !file) {
        payload = removeKeysWithEmptyValues({ ...payload, logo: logo_url || (configuration?.logo ?? "") })
        const res =
          title === "edit"
            ? await updateConfiguration({ ...payload, configId: configuration.configuration_id }).unwrap()
            : await createConfiguration(payload).unwrap()

        if ([200, 201].includes(res?.code)) {
          toast.success(res?.message)
          setSelectedAction("")
          setSelectedConfiguration()
        }
      }
    })
  }

  return {
    currentTab,
    setCurrentTab,
    handleChange,
    household,
    members,
    tabs,
    isLoadingDatasets,
    isLoading: isCreatingConfig || isEditingConfig || uploading || isLoadingSignedUrl,
    onSubmit,
  }
}
