import { ChangeEvent, useMemo, useState } from "react"
import { Configuration } from "types/configuration"

interface ConfigurationManagementArgs {
  title: string
  type: string
  configuration: Configuration
}

export default function useConfigurationManagement({ title, configuration, type }: ConfigurationManagementArgs) {
  const [showMatch, setShowMatch] = useState(false)
  const [file, setFile] = useState<any>(null)
  const [formData, setFormData] = useState({
    configurationName: configuration?.configuration_name ?? "",
    description: configuration?.description ?? "",
    modeOfConnection: configuration?.mode_of_connection ?? "",
    configurationType: configuration?.configuration_type ?? type,
  })

  const handleImageDrop = (file: any) => {
    setFile(file)
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const modeOfConnections = useMemo(() => {
    return [
      { label: "File upload", value: "file upload" },
      { label: "API", value: "API" },
    ]
  }, [])

  const button = useMemo(() => {
    if (title === "edit")
      return {
        name: "Update",
        disabled: !formData.description,
      }
    return {
      name: "Create Configuration",
      disabled: Object.values(formData).some((item) => item === ""),
    }
  }, [title, formData])

  return {
    title,
    formData,
    setFormData,
    handleChange,
    button,
    modeOfConnections,
    handleImageDrop,
    file,
    setFile,
    showMatch,
    setShowMatch,
  }
}
