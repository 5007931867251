import { useRef } from "react"
import { Bar, Line, Pie, Doughnut } from "react-chartjs-2"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ArcElement,
  BarElement,
  ChartOptions,
} from "chart.js"
import { ChartProps } from "types/chart"
import ChartDataLabels from "chartjs-plugin-datalabels"

// Register required Chart.js elements
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  BarElement,
  ChartDataLabels,
  Title,
  Tooltip,
  Legend,
  Filler,
)

// Default chart options
const defaultOptions: ChartOptions<"line"> = {
  maintainAspectRatio: false,
  responsive: true,
  animation: { duration: 1000 },
}

export const ChartWrapper = ({ type, data, containerClass, options, plugins }: ChartProps) => {
  const chartRef = useRef(null)
  const chartOptions = { ...defaultOptions, ...options }

  return (
    <div className={`relative ${containerClass}`}>
      {type === "line" && <Line ref={chartRef} data={data} options={chartOptions} />}
      {type === "pie" && <Pie ref={chartRef} data={data} options={chartOptions} />}
      {type === "bar" && <Bar ref={chartRef} data={data} options={chartOptions} />}
      {type === "doughnut" && <Doughnut ref={chartRef} data={data} options={chartOptions} plugins={plugins} />}
    </div>
  )
}
