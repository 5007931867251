import React from "react"
import { Icon } from "@iconify/react"
import { useNavigate } from "react-router-dom"
import usePaymentUnit from "./usePaymentUnit"
import { Breadcrumb, Table } from "components/core"
import { addCommasToNumbers } from "utils/addCommasToNumbers"
import { paymentUnitHeader } from "constants/payments"
import { motion } from "framer-motion"
// import PaymentStatus from "components/pages/Payments/Status/PaymentStatus"
import PaymentReviewStatus from "components/pages/Payments/ReviewStatus/ReviewStatus"
import { GeneratePaymentSchedule } from "components/pages/Payments/GeneratePaymentSchedule/GeneratePaymentSchedule"
import PaymentScheduleDetails from "components/pages/Payments/PaymentScheduleDetails/PaymentScheduleDetails"
import PaymentTableFilters from "components/pages/Payments/PaymentTable/PaymentFilters/PaymentFilters"
import { fullDateFormatter } from "utils/dateFormatter"
// import { paymentStatus } from "mocks/payments"
import { AllPermissions, PermissionModule } from "types/permission"
import { usePermission } from "hooks/usePermission/usePermission"
import { RenderIf, Restricted } from "components/hoc"

const MotionTr = motion.tr

const PaymentUnit = () => {
  const { PAYMENT_UNIT } = PermissionModule

  const {
    VIEW_ALL_PAYMENT_SCHEDULE,
    GENERATE_PAYMENT_SCHEDULE,
    EXPORT_ALL_PAYMENT_SCHEDULE,
    VIEW_SINGLE_PAYMENT_SCHEDULE,
  } = AllPermissions

  const { hasPermission } = usePermission()
  const {
    breadCrumbs,
    date,
    setDate,
    handleSearch,
    params,
    changePage,
    selectLimit,
    // selectedStatus,
    // setSelectedStatus,
    unit,
    paymentScheduleData,
    exportPaymentSchedule,
    isFetchingPaymentScheduleData,
    selectedAction,
    setSelectedAction,
  } = usePaymentUnit()

  const navigate = useNavigate()

  const showSinglePage = hasPermission(PAYMENT_UNIT, VIEW_SINGLE_PAYMENT_SCHEDULE)

  const handleOpenSinglePage = (item: { schedule_id: string }) => {
    if (showSinglePage) {
      navigate(`/payments/${item.schedule_id}/${unit}`)
    }
  }

  const handleShowExport = hasPermission(PAYMENT_UNIT, EXPORT_ALL_PAYMENT_SCHEDULE)

  return (
    <Restricted module={PAYMENT_UNIT} permission={VIEW_ALL_PAYMENT_SCHEDULE}>
      <main data-testid="payment-unit">
        <div className="mb-[23px]">
          <Breadcrumb options={breadCrumbs} />
          <h1 className="text-headers text-2xl font-bold">Payment Schedule</h1>
        </div>

        <PaymentScheduleDetails />

        <PaymentTableFilters
          // filterOptions={paymentStatus}
          // filterLoading={false}
          // filterMultiple={true}
          // selectedOption={selectedStatus}
          // setSelectedOption={setSelectedStatus}
          showGenerateScheduleButton={hasPermission(PAYMENT_UNIT, GENERATE_PAYMENT_SCHEDULE)}
          searchName="search"
          handleSearch={handleSearch}
          startDate={date?.startDate}
          endDate={date?.endDate}
          handleDate={setDate}
          selectsRange={true}
          exportFunction={exportPaymentSchedule}
          generateActionFunction={() => setSelectedAction("generate-schedule")}
          showExport={handleShowExport}
        />

        <Table
          headers={paymentUnitHeader}
          data={paymentScheduleData?.schedules ?? []}
          onPageChange={changePage}
          selectLimit={selectLimit}
          totalCount={paymentScheduleData?.total}
          perPage={Number(params?.limit)}
          page={Number(params?.page)}
          loading={isFetchingPaymentScheduleData}
        >
          <>
            {paymentScheduleData !== undefined
              ? paymentScheduleData?.schedules?.map((item: any, index: number) => (
                  <React.Fragment key={item.id}>
                    <MotionTr
                      initial={{ opacity: 0, y: -20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3 }}
                      className="cc-custom-table-data-row"
                      data-testid={item.id}
                      onClick={() => handleOpenSinglePage(item)}
                    >
                      <td className="flex items-center">
                        <div>
                          <p className="max-w-[280px] truncate capitalize">{item?.intervention_title}</p>
                          <p className="font-medium text-headers">
                            {addCommasToNumbers(item?.scheduled_beneficiaries_count)}{" "}
                            <span className="text-grey-base">Beneficiaries</span>
                          </p>
                        </div>
                      </td>
                      <td className="max-w-[150px] truncate capitalize">
                        {item?.schedule_reference ?? item.schedule_id}
                      </td>
                      <td>{item.payment_cycle}</td>
                      <td>{fullDateFormatter(item.created_at)}</td>
                      <td>₦{addCommasToNumbers(Number(item?.amount * Number(item?.scheduled_beneficiaries_count)))}</td>
                      {/* <td>{<PaymentStatus status={item.status} />}</td> */}
                      <td>{item.review_status ? <PaymentReviewStatus status={item?.review_status} /> : "--"}</td>
                      <RenderIf condition={showSinglePage}>
                        <td data-testid={`payment-unit-${index}`} onClick={() => handleOpenSinglePage(item)}>
                          <div className="border border-[#E6E6E6] grid place-items-center p-[6px] rounded-[4px] cursor-pointer">
                            <Icon icon="ph:eye" className="w-4 h-4" />
                          </div>
                        </td>
                      </RenderIf>
                    </MotionTr>
                  </React.Fragment>
                ))
              : null}
          </>
        </Table>
        {selectedAction === "generate-schedule" && <GeneratePaymentSchedule onClose={() => setSelectedAction("")} />}
      </main>
    </Restricted>
  )
}

export default PaymentUnit
