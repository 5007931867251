import { Icon } from "@iconify/react"
import { addCommasToNumbers } from "utils/addCommasToNumbers"
import useTransactions from "pages/payments/Transactions/useTransactions"

const PaymentsTransactionsSummary = () => {
  const { TransactionsSummaryInfo } = useTransactions()
  return (
    <div data-testid="transactions-summary" className="flex items-center py-[24px] border-y mt-11">
      {TransactionsSummaryInfo.map((details, index) => (
        <div key={details.title} className="flex items-center gap-4 mr-[40px]">
          <div className="relative w-10 h-10 grid place-items-center bg-grey-100 rounded-full">
            <div className={`${details.indicator} absolute top-0 right-1 w-2 h-2 rounded-full`} />
            <Icon icon="ph:money" className="w-6 h-6 text-body" />
          </div>
          <div className="grid">
            <span className="text-sm text-body" data-testid={`schedule-title-${index}`}>
              {details.title}
            </span>
            <p className="text-base font-medium text-headers">{addCommasToNumbers(details.count)}</p>
          </div>
        </div>
      ))}
    </div>
  )
}

export default PaymentsTransactionsSummary
