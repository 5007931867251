import { Avatar, Breadcrumb, Calendar, Filter, Search, Status, Table, Button } from "components/core"
import React from "react"
import useViewLivelihoodBeneficiary from "./useViewLivelihoodBeneficiary"
import { interventionHistoryHeader } from "constants/livelihood"
import { formatCurrency } from "utils/formatCurrency"
import { Icon } from "@iconify/react"
import { fullDateFormatter } from "utils/dateFormatter"
import { interventionStatus } from "constants/intervention"
import { RenderIf } from "components/hoc"
import HeaderLoader from "components/core/Loader/HeaderLoader/HeaderLoader"

const ViewLivelihoodBeneficiary = () => {
  const {
    breadCrumbs,
    summaryData,
    handleSearch,
    changePage,
    selectLimit,
    selectedStatus,
    setSelectedStatus,
    setDate,
    date,
    beneficiary,
    isFetching,
    isLoading,
    exportInterventionHistory,
    isExporting,
  } = useViewLivelihoodBeneficiary()
  return (
    <main data-testid="view-livelihood-beneficiary">
      <RenderIf condition={isLoading}>
        <HeaderLoader />
      </RenderIf>
      <RenderIf condition={!isLoading}>
        <Breadcrumb options={breadCrumbs} showBack={true} />
        <div className="flex items-center">
          <Avatar key={1} image={beneficiary?.beneficiary?.name} alt={beneficiary?.beneficiary?.name} size="89" />
          <div className="ml-4">
            <p className="font-semibold text-2xl text-headers mb-2">{beneficiary?.beneficiary?.name}</p>
            <p className="mt-2 text-xs text-[#A1A1A1]">{beneficiary?.beneficiary?.beneficiary_id ?? "N/A"}</p>
          </div>
        </div>
        <div className="p-6 bg-primary-900 rounded-[8px] mt-8">
          <div className="mt-3 flex divide-x child-not-last:pr-40 child-not-first:pl-6 ">
            {summaryData.map((data) => (
              <div key={data.name}>
                <p className="text-primary-100 text-xs font-medium">{data.name}</p>
                <p className="text-primary-30 text-sm mt-[2px]">{data.value || "N/A"}</p>
              </div>
            ))}
          </div>
        </div>
      </RenderIf>
      <div className="mt-8">
        <p className="text-headings font-medium text-2xl">Intervention History</p>
        <div className="flex justify-between items-center mt-3">
          <div className="flex space-x-2">
            <Search id="search" name="search" placeholder="Search" onChange={handleSearch} />
            <Filter
              title="Status"
              options={interventionStatus}
              selectedOption={selectedStatus}
              multiple={true}
              setSelectedOption={setSelectedStatus}
            />
            <Calendar startDate={date.startDate} endDate={date.endDate} onChange={setDate} selectsRange={true} />
          </div>
          <Button
            type="button"
            size="40"
            theme="transparent"
            onClick={exportInterventionHistory}
            disabled={isExporting}
          >
            <Icon icon="uiw:download" className="text-[#747F9C] w-5 h-5 mr-3" />
            {isExporting ? "Exporting..." : "Export"}
          </Button>
        </div>
        <Table
          headers={interventionHistoryHeader}
          data={beneficiary?.history ?? []}
          onPageChange={changePage}
          selectLimit={selectLimit}
          totalCount={beneficiary?.beneficiary?.count}
          perPage={beneficiary?.beneficiary?.limit}
          page={beneficiary?.beneficiary?.page}
          loading={isFetching}
        >
          <>
            {beneficiary?.history?.map((item: any) => (
              <tr data-testid={item?.intervention_id} key={item?.intervention_id} className="cc-custom-table-data-row">
                <td className="flex items-center">
                  <p className="">{item?.intervention_title}</p>
                </td>
                <td>
                  <p className="w-max">{formatCurrency(item?.amount)}</p>
                </td>
                <td>
                  <p className="w-max">{formatCurrency(item?.top_amount)}</p>
                </td>
                <td>
                  <p className="w-max">
                    <Status status={item?.status} />
                  </p>
                </td>
                <td>{fullDateFormatter(item.created_at)}</td>
                <td>{item.payment_cycle}</td>
              </tr>
            ))}
          </>
        </Table>
      </div>
    </main>
  )
}

export default ViewLivelihoodBeneficiary
