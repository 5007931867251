export const coResponsibilityHeader = [
  {
    name: "FULL NAME",
    sort: false,
  },
  {
    name: "STATE",
    sort: false,
  },
  {
    name: "LGA",
    sort: false,
  },
  {
    name: "WARD",
    sort: false,
  },
  {
    name: "COMMUNITY",
    sort: false,
  },
  {
    name: "GENDER",
    sort: false,
  },
]
