import { Button, OTPInput, Modal } from "components/core"
import { AuthForm } from "components/pages/Auth"
import useVerifyOTP from "./useVerifyOTP"
import { RenderIf } from "components/hoc"

interface VerifyOTPProps {
  // eslint-disable-next-line no-unused-vars
  onClose: (e: any) => void
  email: string
}

export const VerifyOTP = ({ onClose, email }: VerifyOTPProps) => {
  const { otp, handleChange, onSubmit, loading, resendOtpCode, countDown, disabledButton, onPaste } = useVerifyOTP({
    callback: onClose,
    email,
  })

  return (
    <Modal position="modal-center" width="464px">
      <AuthForm
        title="Verification Code"
        caption={`Enter the OTP code sent to ${email}`}
        onSubmit={onSubmit}
        showLogo={false}
      >
        <>
          <div className="flex justify-center mb-[24px]">
            <OTPInput
              label="Verification Code"
              value={otp.code}
              onPaste={(e: any) => onPaste(e)}
              onChange={(e: any) => handleChange(e)}
              error=""
            />
          </div>
          <p className="text-sm text-center mb-[32px]">
            <span className="text-body font-normal">Didn’t get OTP?</span>{" "}
            <RenderIf condition={countDown === "00:00"}>
              <button
                type="button"
                className="text-primary-actions font-medium"
                onClick={resendOtpCode}
                disabled={loading}
              >
                Resend
              </button>
            </RenderIf>
            <RenderIf condition={countDown !== "00:00"}>
              <span className="text-primary-actions font-medium">Resend in {countDown}</span>
            </RenderIf>
          </p>
          <Button theme="primary" className="w-full" size="44" loading={loading} disabled={disabledButton}>
            Verify
          </Button>
        </>
      </AuthForm>
    </Modal>
  )
}
