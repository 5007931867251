interface Props {
  icon: any
  title: string
  value: any
  stat?: any
  containerClassName?: string
}

export const OverviewItem = ({ icon, title, value, containerClassName, stat }: Props) => {
  const statColor = (statCount: number | undefined) => {
    if (statCount === undefined) return ""
    if (statCount < 0) {
      return "text-red-500"
    } else if (statCount > 0) {
      return "text-[#019939]"
    }
    return ""
  }

  return (
    <div className={`p-[16px] rounded-[10px] border w-full ${containerClassName}`}>
      <div className="mb-[12px] h-[40px] w-[40px] rounded-full bg-grey-100 flex items-center justify-center">
        {icon}
      </div>
      <p className="text-sm text-body mb-[12px]">{title}</p>
      <p className="text-base font-bold text-headers">{value}</p>
      {stat !== undefined && (
        <p className="text-xs">
          <span className={`font-semibold ${statColor(stat)}`}>{stat}%</span> from the last 30 days
        </p>
      )}
    </div>
  )
}
