import { Icon } from "@iconify/react"
import { Dropdown } from "components/core"
import { grievanceStatus } from "constants/grievances"
import { defaultFilter } from "mocks/staffs"
import { useEffect, useMemo, useState } from "react"
import { useFetchGrievanceCategoriesQuery, useFetchGrievanceSubCategoriesQuery } from "services/api/grievanceSlice"

interface Props {
  state: any
}

const CategoryDetails = ({ state }: Props) => {
  const { formData, setFormData } = state
  const [subCategoriesOptions, setSubCategoriesOptions] = useState([])
  const { data: grievanceCategories, isLoading: isLoadingGrievanceCategories } = useFetchGrievanceCategoriesQuery({})

  const { data: grievanceSubCategories, isLoading: isLoadingGrievanceSubCategories } =
    useFetchGrievanceSubCategoriesQuery(formData?.category_id, {
      skip: !formData?.category_id,
    })

  const grievancesCategoriesOptions = useMemo(() => {
    return (
      grievanceCategories?.categories?.map((category: any) => {
        return {
          label: category?.name,
          value: category?.id,
        }
      }) || []
    )
  }, [grievanceCategories])

  useEffect(() => {
    const subCategoryOptions = grievanceSubCategories?.sub_categories?.map((subCategory: any) => {
      return {
        label: subCategory?.name,
        value: subCategory?.id,
      }
    })

    setSubCategoriesOptions(subCategoryOptions)
  }, [grievanceSubCategories])

  return (
    <div>
      <p className="mb-[8px] font-medium text-sm text-headers">
        <Icon icon="ph:pencil-line" className="inline w-[20px] h-[20px]" /> CATEGORY DETAILS
      </p>
      <div className="pl-[24px] w-full mb-[20px] rounded-sm">
        <table className="w-full">
          <thead>
            <tr className="text-left border text-xs text-body">
              <th className="p-[8px] font-medium border-r text-start w-[270px]">Category</th>
              <th className="p-[8px] font-medium border-r text-start w-[270px]">Category Type</th>
              <th className="p-[8px] font-medium text-start w-[270px]">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr className="border h-12 text-sm text-body">
              <td className="border-r col-span-5">
                <Dropdown
                  placeholder="Please Select"
                  selected={formData.category_name}
                  containerVariant="!border-none !shadow-none"
                  wrapperClassName="!mb-0"
                  options={grievancesCategoriesOptions}
                  search={true}
                  loading={isLoadingGrievanceCategories}
                  onSelect={(data: any) =>
                    setFormData({ ...formData, category_name: data.label, category_id: data.value })
                  }
                />
              </td>
              <td className="border-r col-span-5">
                <Dropdown
                  placeholder="Please Select"
                  selected={formData.categoryType}
                  containerVariant="!border-none !shadow-none"
                  wrapperClassName="!mb-0"
                  multiple={true}
                  options={subCategoriesOptions}
                  loading={isLoadingGrievanceSubCategories}
                  search={false}
                  onSelect={(data: any) => setFormData({ ...formData, categoryType: data })}
                />
              </td>
              <td className="border-r col-span-5">
                <Dropdown
                  placeholder="Please Select"
                  selected={formData.categoryStatus}
                  containerVariant="!border-none !shadow-none"
                  wrapperClassName="!mb-0"
                  options={[...defaultFilter, ...grievanceStatus]}
                  multiple={true}
                  search={false}
                  onSelect={(data: any) => setFormData({ ...formData, categoryStatus: data })}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default CategoryDetails
