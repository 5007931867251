import React from "react"
import { Icon } from "@iconify/react"
import { Button, Calendar, Filter, Search, Table } from "components/core"
import useCoresponsibility from "./useCoresponsibility"
import { coResponsibilityheader } from "constants/co-responsibility"
import dayjs from "dayjs"
import { formatCurrency } from "utils/formatCurrency"
import { paymentStatus } from "mocks/payments"
import { defaultFilter } from "mocks/staffs"
import { useNavigate } from "react-router-dom"
import { convertStateToDropdownOptions } from "utils/dropdown"
import PaymentStatus from "components/pages/Payments/Status/PaymentStatus"

const Coresponsibility = () => {
  const {
    selectedState,
    setSelectedState,
    selectedStatus,
    setSelectedStatus,
    handleSearch,
    changePage,
    selectLimit,
    setDate,
    date,
    coResponsibilities,
    isFetching,
    nigerianStates,
    exportAllCoresponsibilities,
    isExporting,
  } = useCoresponsibility()

  const navigate = useNavigate()
  return (
    <main data-testid="co-responsiblity">
      <h1 className="text-headers text-2xl font-bold">Co-Responsibility</h1>
      <div className="flex items-center justify-between mb-4 mt-6">
        <div className="flex items-end space-x-2">
          <Search id="search" name="search" placeholder="Search" onChange={handleSearch} />
          <Filter
            title="Status"
            options={paymentStatus}
            selectedOption={selectedStatus}
            multiple={true}
            setSelectedOption={setSelectedStatus}
          />
          <Filter
            title="State"
            options={[...defaultFilter, ...convertStateToDropdownOptions(nigerianStates)]}
            multiple={true}
            selectedOption={selectedState}
            setSelectedOption={setSelectedState}
          />
          <Calendar startDate={date.startDate} endDate={date.endDate} onChange={setDate} selectsRange={true} />
        </div>
        <Button type="button" size="40" disabled={false} theme="transparent" onClick={exportAllCoresponsibilities}>
          <Icon icon="uiw:download" className="text-[#747F9C] w-4 h-4 mr-3" />
          {isExporting ? "Exporting" : "Export"}
        </Button>
      </div>
      <Table
        headers={coResponsibilityheader}
        data={coResponsibilities?.coResponsibility ?? []}
        onPageChange={changePage}
        selectLimit={selectLimit}
        totalCount={coResponsibilities?.totalCount}
        perPage={coResponsibilities?.limit}
        page={coResponsibilities?.page}
        loading={isFetching}
      >
        <>
          {coResponsibilities?.coResponsibility?.map((item) => (
            <tr
              data-testid={item?.co_responsibility_id}
              key={item?.co_responsibility_id}
              className="cc-custom-table-data-row cursor-pointer"
              onClick={() => navigate(`${item?.intervention_id}?status=${item?.status}`)}
            >
              <td className="flex items-center ">
                <div>
                  <p className="max-w-[280px] truncate capitalize">{item?.co_responsibility_id}</p>
                  <p className="text-grey-base text-xs max-w-[250px] truncate">{item?.intervention_title}</p>
                </div>
              </td>
              <td>
                <p className="w-max">{formatCurrency(item?.total_amount)}</p>
              </td>
              <td>
                <p className="w-max">{formatCurrency(item?.top_amount)}</p>
              </td>
              <td>
                <p className="w-max">{item?.total_number_of_beneficiary}</p>
              </td>
              <td className="w-max capitalize">{item?.payment_method}</td>
              <td>{dayjs(item.created_at).format("MMMM DD, YYYY")}</td>
              <td>
                <PaymentStatus status={item?.status} />
              </td>
            </tr>
          ))}
        </>
      </Table>
    </main>
  )
}

export default Coresponsibility
