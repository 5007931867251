import CommentBox from "./sendComment/Comment"
import useGrievanceComments from "./useGrievanceComments"
import MessageBox from "./MessageBox"
import { RenderIf } from "components/hoc"
import { Icon } from "@iconify/react"

const GrievanceComments = ({ data }: any) => {
  const { isFetchingMessages, comments } = useGrievanceComments({
    data,
  })

  return (
    <main className="flex flex-col">
      <div className="flex-1 overflow-y-auto px-4 py-2 mb-8 pb-10">
        <RenderIf condition={!isFetchingMessages}>
          <RenderIf condition={comments?.length > 0}>
            <div className="flex flex-col h-full  items-end justify-end gap-6">
              {comments.map((message: any, index: number) => (
                <MessageBox key={index} sender={message.sender} text={message.text} time={message.time} />
              ))}
            </div>
          </RenderIf>
        </RenderIf>
        <RenderIf condition={isFetchingMessages}>
          <div className="flex items-center justify-center">
            <Icon icon="svg-spinners:bars-rotate-fade" width="24" height="24" className="bg-primary" />
          </div>
        </RenderIf>
      </div>

      {/* Fixed comment box */}
      <RenderIf condition={data?.status !== "resolved"}>
        <div className="">
          <CommentBox data={data} />
        </div>
      </RenderIf>
    </main>
    // <main className="relative flex flex-col h-full">
    //   <div className="flex-1 overflow-y-auto px-4 py-2 mb-8 pb-10" style={{ maxHeight: "calc(100vh - 30vh)" }}>
    //     <RenderIf condition={!isFetchingMessages}>
    //       <RenderIf condition={comments?.length > 0}>
    //         <div className="flex flex-col items-end justify-end gap-6">
    //           {comments.map((message, index) => (
    //             <MessageBox key={index} sender={message.sender} text={message.text} time={message.time} />
    //           ))}
    //         </div>
    //       </RenderIf>
    //     </RenderIf>
    //   </div>

    //   <div>
    //     <CommentBox
    //       comment={comment}
    //       setComment={setComment}
    //       isLoading={isPostingComment}
    //       onSubmit={handlePostComment}
    //     />
    //   </div>
    // </main>
  )
}

export default GrievanceComments
