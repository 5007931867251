import { Breadcrumb, Button } from "components/core"
import useBeneficiaryGenerateReport from "./useBeneficiaryGenerateReport"
import { BeneficiaryDetails, Location, PaymentSchedule, Preview } from "./components"
import { RenderIf } from "components/hoc"
import { ReportDetails } from "components/pages/Reports"
import { BENEFICIARY_STATUS } from "types/beneficiary"

export const BeneficiaryGenerateReport = () => {
  const { close, refresh, ...state } = useBeneficiaryGenerateReport()

  return (
    <>
      <div className="max-w-[1100px] w-full">
        <Breadcrumb options={state.breadcrumbs} showBack={true} />
        <div className="mb-[32px]">
          <h1 className="text-xl text-headers font-semibold mb-[8px]">Report Parameters</h1>
          <p className="text-sm text-body">
            Ensure that all information provided here are verified and correct before proceeding
          </p>
        </div>
        <form onSubmit={state.proceed}>
          <ReportDetails state={state} />
          <hr className="ml-[24px] my-[24px]" />
          <BeneficiaryDetails state={state} />
          <hr className="ml-[24px] my-[24px]" />
          <Location state={state} />
          {state.formData?.status?.label === BENEFICIARY_STATUS.VERIFIED && (
            <>
              <hr className="ml-[24px] my-[24px]" />
              <PaymentSchedule state={state} />
            </>
          )}
          <div className="flex justify-start items-center">
            <Button
              theme="primary"
              size="44"
              className="min-w-[171px] mr-[16px]"
              disabled={!state.formData.reportTitle.trim()}
            >
              Proceed
            </Button>
            <Button theme="transparent" className="w-[171px] px-[30px]" type="button" size="44" onClick={refresh}>
              Refresh
            </Button>
          </div>
        </form>
      </div>
      <RenderIf condition={state.showPreview}>
        <Preview close={close} state={state} />
      </RenderIf>
    </>
  )
}
