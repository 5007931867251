import { Avatar, Breadcrumb, Button, Filter, Search, Status, Table } from "components/core"
import useSingleGroup from "./useSingleGroup"
import { groupHeader } from "constants/livelihood"
import { formatCurrency } from "utils/formatCurrency"
import { RenderIf } from "components/hoc"
import HeaderLoader from "components/core/Loader/HeaderLoader/HeaderLoader"
import { genders, loanStatus } from "constants/global"
import SingleGroupMember from "../ViewSingleGroupMember/SingleGroupMember"
import { Icon } from "@iconify/react"
import { ManageMember } from "./components"
import { usePermission } from "hooks"
import { AllPermissions, PermissionModule } from "types/permission"

const { LIVELIHOOD } = PermissionModule
const { VIEW_SINGLE_MEMBER } = AllPermissions

export default function SingleGroup() {
  const { hasPermission } = usePermission()
  const state = useSingleGroup()
  const { group } = state

  return (
    <main>
      <Breadcrumb options={state.breadCrumbs} showBack={true} />
      <RenderIf condition={state?.isLoadingGroup}>
        <HeaderLoader />
      </RenderIf>
      <RenderIf condition={!state?.isLoadingGroup}>
        <>
          <div className="mb-[23px]">
            <div className="p-6 bg-primary-900 rounded-[8px] mt-3 gap-8 mb-[36px]">
              <div className="flex justify-between border-b-[.3px] border-b-grey-200 pb-[32px]">
                <h3 className="text-white font-semibold text-2xl capitalize">{group?.group?.group_name}</h3>
              </div>
              <div className="mt-[16px] flex divide-x child-not-last:pr-6 child-not-first:pl-6 ">
                {state.overview.map((item) => (
                  <div key={item.name}>
                    <p className="text-primary-100 text-xs font-medium">{item?.name}</p>
                    <p className="text-primary-30 text-sm mt-[2px]">{item?.value || "N/A"}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div>
            <div className="flex items-center justify-between mb-[16px]">
              <div className="flex items-center">
                <Search
                  id="search"
                  name="search"
                  placeholder="Search"
                  onChange={state.handleSearch}
                  className="mr-[8px]"
                />
                <Filter
                  title="Status"
                  options={[{ label: "All", value: "" }, ...loanStatus]}
                  selectedOption={state.selectedStatus}
                  setSelectedOption={state.setSelectedStatus}
                  containerClass="w-[120px] mr-[8px]"
                />
                <Filter
                  title="Gender"
                  options={genders}
                  selectedOption={state.selectedGender}
                  setSelectedOption={state.setSelectedGender}
                  containerClass="w-[120px]"
                />
              </div>
              <Button type="button" size="40" theme="primary" onClick={() => state.setSelectedAction("add-member")}>
                <Icon icon="ph:plus" className="w-[18px] h-[18px] mr-[8px]" />
                Add New Member
              </Button>
            </div>
            <Table
              headers={groupHeader}
              paginateData={!!group?.members}
              data={group?.members || []}
              onPageChange={state.changePage}
              selectLimit={state.selectLimit}
              totalCount={group?.count}
              perPage={group?.limit}
              page={state.params.page}
              loading={state?.isFetchingGroup}
            >
              <>
                {group?.members?.map((item) => (
                  <tr
                    data-testid={item?.member_id}
                    key={item?.member_id}
                    className="cc-custom-table-data-row cursor-pointer"
                    onClick={() => {
                      if (hasPermission(LIVELIHOOD, VIEW_SINGLE_MEMBER)) {
                        state.setSelectedAction("view")
                        state.setSelectedMember(item)
                      }
                    }}
                  >
                    <td className="flex items-center ">
                      <div className="flex items-center">
                        <Avatar
                          key={item?.member_id}
                          image={`${item?.first_name} ${item?.last_name}`}
                          alt={item?.first_name}
                          size="24"
                        />
                        <div className="ml-[16px]">
                          <p
                            title={`${item?.first_name} ${item?.last_name}`}
                            className="max-w-[200px] truncate capitalize"
                          >
                            {item?.first_name} {item?.last_name}
                          </p>
                          {/* <p className="text-grey-base text-xs">{item?.household_id}</p> */}
                        </div>
                      </div>
                    </td>
                    <td>{formatCurrency(item?.amount_saved) || "-"}</td>
                    <td>{formatCurrency(item?.saving_per_cycle) || "-"}</td>
                    <td>{formatCurrency(item?.loan_collected) || "-"}</td>
                    <td>
                      <Status status={item?.repayment_status} />
                    </td>
                    <td className="capitalize-first">{item?.gender || "-"}</td>
                    <td>{item?.remark || "-"}</td>
                  </tr>
                ))}
              </>
            </Table>
          </div>
        </>
      </RenderIf>
      <RenderIf condition={state.selectedAction === "view"}>
        <SingleGroupMember
          closeModal={() => state.setSelectedAction("")}
          details={state.selectedMember}
          editMember={() => state.setSelectedAction("add-member")}
        />
      </RenderIf>
      <RenderIf condition={state.selectedAction === "add-member"}>
        <ManageMember member={state.selectedMember} onClose={() => state.setSelectedAction("")} />
      </RenderIf>
    </main>
  )
}
