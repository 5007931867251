import { useState } from "react"
import { DateType } from "types/data"
import { useTable } from "hooks"
import { useLocation } from "react-router-dom"
import { useGetPaymentScheduleQuery } from "services/api/paymentSlice"
// import { stringifyArrayII } from "utils/stringifyArray"
import { tryCatch } from "utils/tryCatch"
import { fullDateFormatter, shortDate } from "utils/dateFormatter"
import { exportData } from "utils/exportData"

export default function useProgramManager() {
  // const [selectedStatus, setSelectedStatus] = useState<any>([])
  const [selectedAction, setSelectedAction] = useState("")
  const [date, setDate] = useState<DateType>({ startDate: null, endDate: null })

  const location = useLocation()
  const pathname = location.pathname
  const unit = pathname.split("/").pop()

  const { handleSearch, params, changePage, selectLimit } = useTable()

  const breadCrumbs = [{ title: "Payments" }, { title: "Program Manager" }]

  const { data: paymentScheduleData, isFetching: isFetchingPaymentScheduleData } = useGetPaymentScheduleQuery({
    search: params?.search ?? "",
    // status: selectedStatus.length ? stringifyArrayII(selectedStatus) : "",
    limit: params?.limit ?? 10,
    page: params?.page,
    from: shortDate(date?.startDate) ?? shortDate(date?.endDate) ?? "",
    to: shortDate(date?.endDate) ?? shortDate(date?.startDate) ?? "",
  })

  const scheduleData = paymentScheduleData?.schedules
    ?.map((item: any) => {
      if (item?.departments_approved.length > 0) {
        if (item?.departments_approved?.map((item: string) => item.toLowerCase()).includes("audit")) {
          return item
        }
      }
      return null
    })
    .filter((item: any) => item !== null)

  const exportPaymentSchedule = () => {
    tryCatch(() => {
      const data = paymentScheduleData?.schedules?.map((item: any) => {
        const date = fullDateFormatter(item.created_at)
        return {
          Intervention: `${item?.intervention_title}`,
          "No. of Beneficiaries": item?.scheduled_beneficiaries_count,
          "Schedule ID": item?.schedule_reference ?? item.schedule_id,
          "Payment Cycle": item?.payment_cycle,
          Amount: item?.amount ? Number(item?.amount) * Number(item?.scheduled_beneficiaries_count) : 0,
          "Date Created": date?.replace(/,/g, ""),
          Status: item?.status,
          "Review Status": item?.review_status ?? "--",
        }
      })
      exportData(data, `Payment Schedule Data`)
    })
  }

  return {
    breadCrumbs,
    handleSearch,
    params,
    changePage,
    selectLimit,
    date,
    setDate,
    unit,
    selectedAction,
    exportPaymentSchedule,
    setSelectedAction,
    // selectedStatus,
    // setSelectedStatus,
    paymentScheduleData,
    isFetchingPaymentScheduleData,
    scheduleData,
  }
}
