import React from "react"
import { Icon } from "@iconify/react"
import { Link } from "react-router-dom"
import { RenderIf } from "components/hoc"
import { Avatar, Status } from "components/core"
import "./roleCard.css"

interface RoleCardProps {
  /**
   * Id of role
   */
  id: string
  /**
   * Name of role
   */
  title: string
  /**
   * Description of role
   */
  subTitle: string
  /**
   * Status of the role
   */
  status: string
  /**
   * Other staffs in the role
   */
  otherStaffCount?: string | number
  /**
   * Users assigned to the role
   */
  users: any
}

/**
 * Role card component for rendering roles
 */
export const RoleCard: React.FC<RoleCardProps> = (props) => {
  return (
    <Link
      to={props?.id}
      className="
      flex flex-col justify-between cursor-pointer p-4 border border-neutral-10
      hover:border-neutral-30 rounded-xl group transition-all ease-out duration-700"
    >
      <div className="flex items-center justify-between mb-[8px]">
        <h3 className="text-headers font-bold text-base capitalize truncate">{props?.title}</h3>
        <Icon
          icon="ph:arrow-right"
          className="move-icon -z-[1] text-neutral-base w-6 h-6 opacity-0 group-hover:opacity-100 transition-all ease-out duration-500"
        />
      </div>
      <p className="text-sm text-neutral-base line-clamp-3 h-[60px] text-ellipsis capitalize-first">
        {props?.subTitle}
      </p>
      <div className="flex justify-between pt-[12px] border-t border-t-neutral-10 mt-[8px]">
        <div className="flex items-center gap-1">
          <div className="flex items-center -space-x-2.5">
            {props?.users?.map((user: any) => (
              <Avatar
                key={`${user?.first_name} ${user?.last_name}`}
                image={user?.profile_pic || `${user?.first_name} ${user?.last_name}`}
                alt={user?.first_name}
                size="24"
              />
            ))}
          </div>
          <RenderIf condition={!!Number(props?.otherStaffCount)}>
            <span className="text-sm text-neutral-base">
              +{props?.otherStaffCount} {Number(props?.otherStaffCount) > 0 ? "other(s)" : ""}
            </span>
          </RenderIf>
        </div>
        <Status status={props.status} />
      </div>
    </Link>
  )
}
