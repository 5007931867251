import { useEffect, useState, useMemo, ChangeEvent } from "react"
import {
  useFetchGrievanceCategoriesQuery,
  useFetchGrievanceSubCategoriesQuery,
  useFetchZonesQuery,
  useFetchStatesQuery,
  useFetchCommunitiesQuery,
} from "services/api/grievanceSlice"
import { useNavigate } from "react-router-dom"

interface Props {
  formData: any
  setFormData: any
  proceed: () => void
}

export default function useBasicInformation({ formData, proceed, setFormData }: Props) {
  const [subCategoriesOptions, setSubCategoriesOptions] = useState([])
  const [phone, setPhone] = useState<{ phoneNumber: string; countryCode: string }>({
    phoneNumber: "",
    countryCode: "+234",
  })
  const navigate = useNavigate()

  const nextPage = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault()
    proceed()
    navigate(`?grm=nctu`)
  }

  const { data: grievanceZones, isFetching: isFetchingZones } = useFetchZonesQuery({})

  const { data: grievanceStates, isFetching: isFetchingStates } = useFetchStatesQuery(
    { id: formData?.zone_id },
    { skip: !formData?.zone_id },
  )

  const { data: grievanceCategories, isLoading: isLoadingGrievanceCategories } = useFetchGrievanceCategoriesQuery({})

  const { data: grievanceSubCategories, isLoading: isLoadingGrievanceSubCategories } =
    useFetchGrievanceSubCategoriesQuery(formData?.category_id, {
      skip: formData?.category_id?.length === 0,
    })

  const { data: grievanceCommunities, isFetching: isFetchingCommunities } = useFetchCommunitiesQuery({})

  const grievanceCommunityOption = useMemo(() => {
    return grievanceCommunities?.map((community: any) => {
      return {
        label: community?.community_name,
        value: community?.community_id,
      }
    })
  }, [grievanceCommunities])

  const grievanceZonesOptions = useMemo(() => {
    return grievanceZones?.map((zones: any) => {
      return {
        label: zones.name,
        value: zones.id,
      }
    })
  }, [grievanceZones])

  const grievanceStateOptions = useMemo(() => {
    return grievanceStates?.map((state: any) => {
      return {
        label: state.name,
        value: state.id,
      }
    })
  }, [grievanceStates])

  const grievanceLGAOptions = useMemo(() => {
    if (formData?.state_id) {
      const state = grievanceStates?.find((state: any) => state.id === formData?.state_id)
      return state?.lgas?.map((lga: any) => {
        return {
          label: lga?.name,
          value: lga?.id,
        }
      })
    }
  }, [formData?.state_id])

  const grievancesCategoriesOptions = useMemo(() => {
    return (
      grievanceCategories?.categories?.map((category: any) => {
        return {
          label: category?.name,
          value: category?.id,
        }
      }) || []
    )
  }, [grievanceCategories])

  useEffect(() => {
    setSubCategoriesOptions([])

    setFormData((prevData: any) => ({
      ...prevData,
      sub_category_id: "",
      sub_category_id_name: "",
    }))
  }, [formData?.category_id])

  useEffect(() => {
    if (phone.phoneNumber.length > 0) {
      setFormData({
        ...formData,
        complaint_phone_number: `${phone.countryCode}${phone.phoneNumber}`,
      })
    }
  }, [phone])

  useEffect(() => {
    const subCategoryOptions =
      grievanceSubCategories !== "undefined"
        ? grievanceSubCategories?.sub_categories?.map((subCategory: any) => {
            return {
              label: subCategory?.name,
              value: subCategory?.id,
            }
          })
        : []

    setSubCategoriesOptions(subCategoryOptions)
  }, [isLoadingGrievanceSubCategories, grievanceSubCategories, formData?.category_id])

  const disableBtn = useMemo(() => {
    return (
      !formData.complaint_name.length ||
      !formData.complaint_type.length ||
      !formData.description.length ||
      !formData?.gender.length ||
      !formData?.state_name.length ||
      !formData?.level.length ||
      String(formData?.sub_category_id)?.length === 0 ||
      !formData?.zone_name.length ||
      !formData?.complaint_mode.length ||
      !formData?.lga_name.length ||
      !formData?.complaint_address.length
    )
  }, [formData])

  return {
    disableBtn,
    nextPage,
    grievancesCategoriesOptions,
    isLoadingGrievanceCategories,
    isLoadingGrievanceSubCategories,
    subCategoriesOptions,
    grievanceZonesOptions,
    isFetchingZones,
    isFetchingStates,
    grievanceStateOptions,
    grievanceLGAOptions,
    grievanceCommunityOption,
    isFetchingCommunities,
    phone,
    setPhone,
  }
}
