import { useState } from "react"
import { useTable } from "hooks"
import { formatCurrency } from "utils/formatCurrency"
import { useGetGroupQuery } from "services/api/livelihoodSlice"
import { useParams, useSearchParams } from "react-router-dom"
import { removeSpecificKeys } from "utils/removeSpecificKeys"
import { fullDateFormatter } from "utils/dateFormatter"
// import { useGetRolesQuery } from "services/api/rolesSlice"
// import { usePermission } from "hooks/usePermission/usePermission"
// import { PermissionModule } from "types/permission"

export default function useSingleGroup() {
  //const { ROLES_AND_PERMISSION } = PermissionModule
  const [searchParams] = useSearchParams()
  const communityName = searchParams?.get("communityName")
  const { id: groupId } = useParams()
  const { handleSearch, params, changePage, selectLimit } = useTable()
  const [selectedMember, setSelectedMember] = useState<any>(null)
  const [selectedGender, setSelectedGender] = useState<any>([])
  const [selectedStatus, setSelectedStatus] = useState<any>([])
  const [selectedAction, setSelectedAction] = useState("")
  //const { hasModulePermission } = usePermission()
  const enhancedParams = {
    ...params,
    groupId,
    gender: selectedGender?.value,
    status: selectedStatus?.value,
  }
  const {
    data: group,
    isFetching: isFetchingGroup,
    isLoading: isLoadingGroup,
  } = useGetGroupQuery(removeSpecificKeys(enhancedParams, ["sortBy"]), { skip: !groupId })

  const breadCrumbs = [{ title: "Livelihood" }, { title: "Community List" }, { title: group?.group?.group_name ?? "" }]

  const overview = [
    { name: "Group ID", value: group?.group?.group_id ?? "" },
    { name: "Total Amount Saved", value: formatCurrency(50000) },
    {
      name: "Loans Given",
      value: formatCurrency(120000),
    },
    { name: "Date Created", value: fullDateFormatter(group?.group?.created_at) },
    { name: "Number of Members", value: group?.count ?? "" },
    { name: "Duration", value: group?.group?.duration ?? "N/A" },
    { name: "Community", value: communityName },
  ]

  return {
    breadCrumbs,
    handleSearch,
    overview,
    changePage,
    selectLimit,
    params,
    group,
    selectedGender,
    setSelectedGender,
    selectedStatus,
    setSelectedStatus,
    isFetchingGroup,
    isLoadingGroup,
    selectedAction,
    setSelectedAction,
    selectedMember,
    setSelectedMember,
    // roles,
    // isFetchingRoles,
  }
}
