import { useEffect, useMemo } from "react"
import { RenderIf } from "components/hoc"
import EmptyUser from "assets/svg/empty-user.svg"
import { SpinnerLoader, Dropdown, Input } from "components/core"
import RadioButton from "components/core/RadioButton/RadioButton"

const SingleCitizenUpload = ({
  beneficiariesData,
  isLoading,
  selectedCitizen,
  setSelectedCitizen,
  filteredBeneficiary,
  setFilteredBeneficiary,
  houseHoldId,
}: any) => {
  const citizenDropdown = useMemo(() => {
    return beneficiariesData?.household_beneficiaries?.map((house: any) => {
      return {
        label: house?.name,
        value: house.reference,
      }
    })
  }, [beneficiariesData])

  useEffect(() => {
    if (selectedCitizen) {
      const selectedBeneficiary = beneficiariesData?.household_beneficiaries?.find(
        (house: any) => house.reference === selectedCitizen,
      )
      setFilteredBeneficiary(selectedBeneficiary || null)
    } else {
      setFilteredBeneficiary(null) // Reset if no citizen is selected
    }
  }, [selectedCitizen, beneficiariesData])

  return (
    <div className="w-full">
      <RenderIf condition={isLoading}>
        <div className="flex items-center justify-center mt-10">
          <SpinnerLoader size={98} />
        </div>
      </RenderIf>
      <RenderIf condition={!isLoading && beneficiariesData?.household_beneficiaries?.length === 0}>
        <div className="flex justify-center flex-col items-center mt-10">
          <img src={EmptyUser} alt="empty state" />
          <h1 className="text-body text-sm font-semibold">This household has no beneficiaries</h1>
        </div>
      </RenderIf>
      <RenderIf
        condition={!isLoading && beneficiariesData?.household_beneficiaries?.length > 0 && houseHoldId.length !== 0}
      >
        <div className="flex flex-col gap-y-3 w-full">
          <Dropdown
            label="Select Beneficiary"
            options={citizenDropdown ?? []}
            onSelect={(data: any) => setSelectedCitizen(data?.value)}
          />
          <RenderIf condition={selectedCitizen.length > 0}>
            <div className="flex flex-col gap-5">
              <Input
                label="Beneficiary Name"
                id="name"
                name="name"
                value={filteredBeneficiary?.name}
                type="text"
                disabled
              />
              <div className="flex gap-x-4 border-b border-b-grey-200 pb-4">
                <RadioButton
                  name={"gender"}
                  label={"Male"}
                  checked={filteredBeneficiary?.gender === "Male"}
                  value={filteredBeneficiary?.gender}
                  disabled
                  size="36"
                />
                <RadioButton
                  name={"gender"}
                  label={"Female"}
                  checked={filteredBeneficiary?.gender === "Female"}
                  value={filteredBeneficiary?.gender}
                  disabled
                  size="36"
                />
              </div>
              <Input
                label="Phone Number"
                id="phone_number"
                name="phone_number"
                value={filteredBeneficiary?.telephone_no}
                type="tel"
                disabled
              />
              <Input label="State" id="state" name="state" value={filteredBeneficiary?.state} type="text" disabled />
              <Input label="LGA" id="lga" name="lga" value={filteredBeneficiary?.lga} type="text" disabled />
            </div>
          </RenderIf>
        </div>
      </RenderIf>
    </div>
  )
}

export default SingleCitizenUpload
