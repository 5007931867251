import { useMemo, useState } from "react"
import { useTable } from "hooks"
import { useGetBeneficiariesQuery } from "services/api/beneficiarySlice"

export default function useBeneficiaries(interventionId: string) {
  const [currentTab, setCurrentTab] = useState("Banked Beneficiaries")
  const [selectedState, setSelectedState] = useState<any>([])
  const [selectedGender, setSelectedGender] = useState<any>([])
  const {
    handleSearch: handleSearchBanked,
    params: paramsBanked,
    changePage: changePageBanked,
    resetParams: resetParamsBanked,
    selectLimit: selectLimitBanked,
  } = useTable()
  const {
    handleSearch: handleSearchUnbanked,
    params: paramsUnbanked,
    changePage: changePageUnbanked,
    resetParams: resetParamsUnbanked,
    selectLimit: selectLimitUnbanked,
  } = useTable()

  const { data: bankedBeneficiaries, isFetching: isFetchingBankedBeneficiaries } = useGetBeneficiariesQuery(
    {
      intervention: interventionId,
      limit: paramsBanked.limit,
      search: paramsBanked.search,
      page: paramsBanked.page,
      gender: selectedGender?.value,
      state: currentTab === "Banked Beneficiaries" ? selectedState.value?.toLowerCase() ?? "" : "",
      type: "banked",
    },
    { skip: !interventionId },
  )
  const { data: unbankedBeneficiaries, isFetching: isFetchingUnbankedBeneficiaries } = useGetBeneficiariesQuery(
    {
      intervention: interventionId,
      limit: paramsUnbanked.limit,
      search: paramsUnbanked.search,
      page: paramsUnbanked.page,
      gender: selectedGender?.value,
      state: currentTab !== "Banked Beneficiaries" ? selectedState.value?.toLowerCase() ?? "" : "",
      type: "unbanked",
    },
    { skip: !interventionId },
  )

  const tabs = [
    {
      name: "Banked Beneficiaries",
      value: "Verified Beneficiaries",
      count: bankedBeneficiaries?.total ?? "...",
    },
    {
      name: "Unbanked Beneficiaries",
      value: "Unverified Beneficiaries",
      count: unbankedBeneficiaries?.total ?? "...",
    },
  ]

  const genderOptions = [
    { label: "All", value: "" },
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ]

  const resetParamsProxy = () => {
    currentTab === "Banked Beneficiaries" ? resetParamsBanked() : resetParamsUnbanked()
    setSelectedState([])
    setSelectedGender([])
  }

  const beneficiaries = useMemo(() => {
    if (currentTab === "Banked Beneficiaries") {
      return {
        beneficiaries: bankedBeneficiaries,
        handleSearch: handleSearchBanked,
        isFetchingBeneficiaries: isFetchingBankedBeneficiaries,
        changePage: changePageBanked,
        selectLimit: selectLimitBanked,
        ...paramsBanked,
      }
    }
    return {
      beneficiaries: unbankedBeneficiaries,
      handleSearch: handleSearchUnbanked,
      isFetchingBeneficiaries: isFetchingUnbankedBeneficiaries,
      changePage: changePageUnbanked,
      selectLimit: selectLimitUnbanked,
      ...paramsUnbanked,
    }
  }, [
    currentTab,
    bankedBeneficiaries,
    unbankedBeneficiaries,
    isFetchingBankedBeneficiaries,
    isFetchingUnbankedBeneficiaries,
  ])

  return {
    handleSearch: beneficiaries.handleSearch,
    selectedGender,
    setSelectedGender,
    selectedState,
    setSelectedState,
    genderOptions,
    tabs,
    currentTab,
    setCurrentTab,
    beneficiaries: beneficiaries.beneficiaries,
    isFetchingBeneficiaries: beneficiaries.isFetchingBeneficiaries,
    changePage: beneficiaries.changePage,
    resetParamsProxy,
    selectLimit: beneficiaries.selectLimit,
    limit: beneficiaries.limit,
    page: beneficiaries.page,
  }
}
