import React from "react"
import { Breadcrumb, Search, Button, Table, ManageItem } from "components/core"
import { Icon } from "@iconify/react"
import { motion } from "framer-motion"
import useSingleCourse from "./useSingleCourse"
import SingleCourseInfo from "components/pages/Courses/SingleCourse/SingleCourseInfo/SingleCourseInfo"
import { moduleListHeading } from "constants/trainings"
import { RenderIf } from "components/hoc"

const SingleCourse = () => {
  const {
    breadCrumbs,
    isFetchingSingleCourse,
    singleCourseData,
    singleCourseDetailsData,
    handleSearch,
    params,
    changePage,
    selectLimit,
    selectedAction,
    setSelectedModule,
    selectedModule,
    setSelectedAction,
    isDeletingModule,
    handleDeleteModule,
    exportModuleInformation,
  } = useSingleCourse()

  const MotionTr = motion.tr

  return (
    <main>
      <div>
        <Breadcrumb options={breadCrumbs} showBack goBackRoute="/training/courses" />
      </div>
      <SingleCourseInfo
        isLoading={isFetchingSingleCourse}
        courseTitle={singleCourseData?.title}
        detailsData={singleCourseDetailsData}
      />
      <div className="mt-4">
        <div>
          <h1 className="text-headers font-medium text-lg mb-3">Modules</h1>
          <div className="flex justify-between">
            <Search placeholder="search" id="search" name="search" onChange={handleSearch} />
            <div className="flex space-x-2">
              <Button type="button" size="40" theme="transparent" onClick={exportModuleInformation}>
                <Icon icon="uiw:download" className="text-[#747F9C] w-4 h-4 mr-3" />
                Export
              </Button>
            </div>
          </div>
        </div>

        <div className="mt-6">
          <Table
            data={singleCourseData?.modules ?? []}
            headers={moduleListHeading}
            onPageChange={changePage}
            selectLimit={selectLimit}
            totalCount={singleCourseData?.total}
            loading={isFetchingSingleCourse}
            perPage={Number(params?.limit)}
            page={Number(params?.page)}
          >
            <>
              {singleCourseData?.modules
                ? singleCourseData?.modules?.map((module: any) => (
                    <React.Fragment key={module?.module_id}>
                      <MotionTr
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.3 }}
                        className="cc-custom-table-data-row"
                      >
                        <td className="text-body text-sm">{module?.course_module_reference}</td>
                        <td className="text-body text-sm capitalize">{module?.name}</td>
                        <td>
                          <h2 className="text-grey-base">{`${String(module?.description).slice(0, 50)}...`}</h2>
                        </td>
                        <RenderIf condition={singleCourseData?.modules?.length > 1}>
                          <td>
                            <div className="space-x-3.5">
                              <button
                                className="text-body align-middle py-[8px] px-[12px] rounded-lg border border-grey-200 cursor-pointer"
                                onClick={() => {
                                  setSelectedAction("delete-module")
                                  setSelectedModule(module)
                                }}
                              >
                                <Icon icon="ph:trash" className="w-4 h-4" />
                              </button>
                            </div>
                          </td>
                        </RenderIf>
                      </MotionTr>
                    </React.Fragment>
                  ))
                : null}
            </>
          </Table>
        </div>
      </div>

      {selectedAction === "delete-module" && (
        <ManageItem
          onClose={() => setSelectedAction("")}
          title="Do you want to delete this module"
          actionName="Delete"
          cancelBtnText="Discard"
          loading={isDeletingModule}
          onSubmit={() => handleDeleteModule(selectedModule?.module_id ?? "", () => setSelectedAction(""))}
          icon={
            <div className="bg-error-lightest w-12 h-12 flex items-center justify-center rounded-lg">
              <Icon icon="ph:trash" className="w-10 h-10 text-error-main" />
            </div>
          }
          caption={
            "Confirming this action will delete this course and it is irreversible. Be sure you want to perform this action."
          }
        />
      )}
    </main>
  )
}

export default SingleCourse
