import { useState } from "react"
import { useTable } from "hooks"
import { formatCurrency } from "utils/formatCurrency"
import { DateRangeType } from "types/common"
import { useGetAllGroupsQuery, useLazyExportCommunityQuery } from "services/api/livelihoodSlice"
import { useParams, useSearchParams } from "react-router-dom"
import { fullDateFormatter, shortDate } from "utils/dateFormatter"
import { tryCatch } from "utils/tryCatch"
import { exportData } from "utils/exportData"
import { removeSpecificKeys } from "utils/removeSpecificKeys"
// import { useGetRolesQuery } from "services/api/rolesSlice"
// import { usePermission } from "hooks/usePermission/usePermission"
// import { PermissionModule } from "types/permission"

export default function useSingleCommunity() {
  //const { ROLES_AND_PERMISSION } = PermissionModule
  const { id: communityId } = useParams()
  const [searchParams] = useSearchParams()
  const communityName = searchParams.get("name") ?? ""
  const [exportCommunity, { isFetching: isExporting }] = useLazyExportCommunityQuery()
  const { handleSearch, params, changePage, selectLimit } = useTable()
  const [date, setDate] = useState<DateRangeType>({ startDate: null, endDate: null })
  //const { hasModulePermission } = usePermission()
  const [selectedAction, setSelectedAction] = useState("")
  const dateRange = date.endDate && { fromDate: shortDate(date.startDate), toDate: shortDate(date.endDate) }
  const {
    data: groups,
    isFetching: isFetchingGroups,
    isLoading: isLoadingGroups,
  } = useGetAllGroupsQuery(removeSpecificKeys({ ...params, ...dateRange, communityId }, ["sortBy"]), {
    skip: !communityId,
  })

  const breadCrumbs = [
    { title: "Livelihood" },
    { title: "Community List" },
    { title: groups?.community?.community_name ?? "" },
  ]

  const overview = [
    {
      title: "Total Group List",
      icon: "ph:users-three",
      count: groups?.totalGroupList ?? 0,
    },
    {
      title: "Total Amount Contributed",
      icon: "ph:handshake",
      count: formatCurrency(groups?.totalAmountContributed ?? 0),
    },
    {
      title: "Total Loan Given",
      icon: "ph:handshake",
      count: formatCurrency(groups?.totalLoanAmount ?? 0),
    },
  ]

  const exportCommunityProxy = () => {
    tryCatch(async () => {
      const res = await exportCommunity({ ...dateRange, communityId }).unwrap()

      const data = res?.staff?.map((item: any) => {
        return {
          "Group name": item?.group_name,
          Ward: item?.ward,
          "No. of Members": item?.member_count,
          "Total savings amount": item?.total_amount_saved,
          "Total loan given": item?.loan_given,
          Duration: item?.duration,
          "Date created": fullDateFormatter(item?.cretaed_at),
        }
      })
      exportData(data, communityName)
    })
  }

  //exportCommunity

  return {
    breadCrumbs,
    handleSearch,
    selectedAction,
    setSelectedAction,
    exportCommunityProxy,
    overview,
    changePage,
    selectLimit,
    params,
    date,
    setDate,
    isFetchingGroups,
    isLoadingGroups,
    community: groups,
    isExporting,
    communityName,
    // roles,
    // isFetchingRoles,
  }
}
