import { Icon } from "@iconify/react"
import { Dropdown } from "components/core"
import { defaultFilter } from "mocks/staffs"
import { useGetStatesInZoneQuery } from "services/api/commonSlice"
import { convertStateToDropdownOptions } from "utils/dropdown"

interface Props {
  state: any
}

export const InterventionDetails = ({ state }: Props) => {
  const { formData, setFormData } = state
  const { data: states, isLoading: isFetchingStates } = useGetStatesInZoneQuery({ ids: "1,2,3,4,5,6" })

  return (
    <div>
      <p className="mb-[8px] font-medium text-sm text-headers">
        <Icon icon="ph:pencil-line" className="inline w-[20px] h-[20px]" /> INTERVENTION DETAILS
      </p>
      <div className="pl-[24px] w-full mb-[20px] rounded-sm">
        <table className="w-full">
          <thead>
            <tr className="text-left border text-xs text-body">
              <th className="p-[8px] font-medium border-r text-start w-[270px]">Intervention</th>
              <th className="p-[8px] font-medium border-r text-start w-[270px]">State</th>
            </tr>
          </thead>
          <tbody>
            <tr className="border h-12 text-sm text-body">
              <td className="border-r col-span-5">
                <Dropdown
                  placeholder="Please Select"
                  defaultOption={formData.interventions}
                  containerVariant="!border-none !shadow-none"
                  wrapperClassName="!mb-0"
                  loading={state.isFetchingInterventions}
                  options={state?.interventions}
                  enableSearchOptions={true}
                  multiple={true}
                  onSelect={(data: any) => setFormData({ ...formData, interventions: data })}
                />
              </td>
              <td className="border-r col-span-5">
                <Dropdown
                  placeholder="Please Select"
                  selected={formData.states}
                  containerVariant="!border-none !shadow-none"
                  wrapperClassName="!mb-0"
                  multiple={true}
                  options={[...defaultFilter, ...convertStateToDropdownOptions(states)]}
                  loading={isFetchingStates}
                  enableSearchOptions={true}
                  onSelect={(data: any) => setFormData({ ...formData, states: data })}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}
