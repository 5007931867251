/* eslint-disable max-len */
import { ReactNode, useEffect, useLayoutEffect, useRef } from "react"
import { NavLink, useLocation, useNavigate } from "react-router-dom"

interface Props {
  children: ReactNode
}

export const ReportsLayout = ({ children }: Props) => {
  const ref = useRef<HTMLDivElement>(null)
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const menu = [
    { name: "Intervention Report", link: "/reports/intervention" },
    { name: "Beneficiary Report", link: "/reports/beneficiary" },
    { name: "Payment Unit Report", link: "/reports/payment-unit" },
    { name: "Account Report", link: "/reports/account" },
    { name: "Audit Report", link: "/reports/audit" },
    { name: "Co-responsibility Report", link: "/reports/co-responsibility" },
    { name: "Livelihood Report", link: "/reports/livelihood" },
    { name: "GRM Report", link: "/reports/grm" },
  ]

  useLayoutEffect(() => {
    if (ref?.current) ref.current.scrollTop = 0
  }, [pathname])

  useEffect(() => {
    const page = pathname.split("/")[2]
    if (page) {
      // const isPageAllowed = checkPermissions(pathname)
      // if (isPageAllowed) return
      return
    }
    navigate(menu[0].link)
  }, [])

  return (
    <div className="flex relative h-[80vh]">
      <ul className="bg-grey-30 px-[20px] py-[16px] border border-[#E6E6E6] rounded-lg mr-[60px] w-[292px] shrink-0 h-fit static top-[600px]">
        {menu.map((item) => (
          <li key={item.name} className="mb-[2px]">
            <NavLink
              to={item.link}
              className={`py-[18px] hover:bg-[#F1F1F1] rounded-[10px] text-sm font-medium flex items-center hover:pl-[30px]`}
              style={({ isActive }) =>
                isActive ? { color: "#2B892B", backgroundColor: "#F1F1F1", paddingLeft: "30px" } : { color: "#5C5C5C" }
              }
            >
              <div className="mr-[10px]">
                {location.pathname !== item.link ? (
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="7" cy="7" r="6.5" stroke="#747F9C" />
                  </svg>
                ) : (
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M7 0C5.61553 0 4.26216 0.410543 3.11101 1.17971C1.95987 1.94888 1.06266 3.04213 0.532846 4.32122C0.00303299 5.6003 -0.13559 7.00776 0.134506 8.36563C0.404603 9.7235 1.07129 10.9708 2.05026 11.9497C3.02922 12.9287 4.2765 13.5954 5.63437 13.8655C6.99224 14.1356 8.3997 13.997 9.67879 13.4672C10.9579 12.9373 12.0511 12.0401 12.8203 10.889C13.5895 9.73785 14 8.38447 14 7C13.998 5.14409 13.2599 3.36475 11.9476 2.05242C10.6353 0.740087 8.85592 0.00195988 7 0ZM10.0733 5.76558L6.30404 9.53481C6.25403 9.58487 6.19465 9.62459 6.12928 9.65168C6.06391 9.67878 5.99384 9.69273 5.92308 9.69273C5.85232 9.69273 5.78225 9.67878 5.71688 9.65168C5.65151 9.62459 5.59213 9.58487 5.54212 9.53481L3.92673 7.91942C3.8257 7.81838 3.76893 7.68135 3.76893 7.53846C3.76893 7.39557 3.8257 7.25854 3.92673 7.1575C4.02777 7.05646 4.16481 6.9997 4.30769 6.9997C4.45058 6.9997 4.58762 7.05646 4.68866 7.1575L5.92308 8.3926L9.31135 5.00365C9.36138 4.95362 9.42077 4.91394 9.48613 4.88686C9.5515 4.85979 9.62156 4.84585 9.69231 4.84585C9.76306 4.84585 9.83312 4.85979 9.89848 4.88686C9.96385 4.91394 10.0232 4.95362 10.0733 5.00365C10.1233 5.05368 10.163 5.11307 10.1901 5.17844C10.2171 5.2438 10.2311 5.31386 10.2311 5.38461C10.2311 5.45537 10.2171 5.52542 10.1901 5.59079C10.163 5.65616 10.1233 5.71555 10.0733 5.76558Z"
                      fill="#2B892B"
                    />
                  </svg>
                )}
              </div>
              {item.name}
            </NavLink>
          </li>
        ))}
      </ul>
      <div ref={ref} className="overflow-y-auto h-[88vh] w-full no-scrollbar">
        {children}
      </div>
    </div>
  )
}
