import useInputValidate from "hooks/useInputValidate/useInputValidate"
import { ChangeEvent, useMemo, useState } from "react"
import { useResetPasswordMutation } from "services/api/authSlice"
import { tryCatch } from "utils/tryCatch"

export default function useResetPassword() {
  const { validateFormData } = useInputValidate()
  const [resetPassword, { isLoading }] = useResetPasswordMutation()
  const [showVerify, setShowVerify] = useState(false)
  const [formData, setFormData] = useState({ email: "" })

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const onSubmit = () => {
    tryCatch(async () => {
      const res = await resetPassword(formData).unwrap()
      if (res?.code === 200) setShowVerify(true)
    })
  }

  const disabledButton = useMemo(() => {
    return !validateFormData(formData)
  }, [formData])

  return {
    formData,
    setFormData,
    handleChange,
    showVerify,
    setShowVerify,
    onSubmit,
    isLoading,
    disabledButton,
  }
}
