import { Icon } from "@iconify/react"
import { Dropdown, Input } from "components/core"
import { currencies } from "constants/currency"
import { defaultFilter } from "constants/global"
import { paymentTypes } from "constants/intervention"
import { useMemo } from "react"

interface Props {
  state: any
}

export const PaymentSchedule = ({ state }: Props) => {
  const { formData, setFormData, handleChange } = state

  const currencyOptions = useMemo(() => {
    return currencies.map((item: any) => {
      return { label: item.name, value: item.name }
    })
  }, [])

  return (
    <div className="mb-[56px]">
      <p className="mb-[8px] font-medium text-sm text-headers">
        <Icon icon="ph:pencil-line" className="inline w-[20px] h-[20px]" /> PAYMENT STRUCTURE
      </p>
      <div className="pl-[24px] w-full mb-[20px] rounded-sm">
        <table className="w-full">
          <thead>
            <tr className="text-left border text-xs text-body">
              <th className="p-[8px] font-medium border-r text-start w-[270px]">Eligibility Amount</th>
              <th className="p-[8px] font-medium border-r text-start w-[270px]">Payment Type</th>
              <th className="p-[8px] font-medium border-r text-start w-[270px]">Currency</th>
              <th className="p-[8px] font-medium text-start w-[270px]">Total Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr className="border h-12 text-sm text-body">
              <td className="px-3 border-r">
                <div className="flex">
                  <Input
                    type="number"
                    id="eligibilityAmountFrom"
                    name="eligibilityAmountFrom"
                    // readOnly={loading}
                    placeholder="From"
                    value={formData?.eligibilityAmountFrom}
                    onChange={handleChange}
                    className="!w-full border-r"
                    variant="border-none !p-0 !h-[40px]"
                  />
                  <Input
                    type="number"
                    id="eligibilityAmountTo"
                    name="eligibilityAmountTo"
                    // readOnly={loading}
                    placeholder="To"
                    value={formData?.eligibilityAmountTo}
                    onChange={handleChange}
                    className="!w-full pl-3"
                    variant="border-none !p-0 !h-[40px]"
                  />
                </div>
              </td>
              <td className="border-r">
                <Dropdown
                  //selected={formData?.paymentType}
                  containerVariant="!border-none !shadow-none"
                  wrapperClassName="!mb-0"
                  options={paymentTypes}
                  multiple={true}
                  count={true}
                  onSelect={(data: any) => setFormData({ ...formData, paymentType: data })}
                />
              </td>
              <td className="border-r">
                <Dropdown
                  //selected={formData?.currency}
                  containerVariant="!border-none !shadow-none"
                  wrapperClassName="!mb-0"
                  options={[...defaultFilter, ...currencyOptions]}
                  multiple={true}
                  count={true}
                  onSelect={(data: any) => setFormData({ ...formData, currency: data })}
                />
              </td>
              <td className="px-3">
                <div className="flex">
                  <Input
                    type="number"
                    id="totalAmountFrom"
                    name="totalAmountFrom"
                    // readOnly={loading}
                    placeholder="From"
                    value={formData?.totalAmountFrom}
                    onChange={handleChange}
                    className="!w-full border-r"
                    variant="border-none !p-0 !h-[40px]"
                  />
                  <Input
                    type="number"
                    id="totalAmountTo"
                    name="totalAmountTo"
                    // readOnly={loading}
                    placeholder="To"
                    value={formData?.totalAmountTo}
                    onChange={handleChange}
                    className="!w-full pl-3"
                    variant="border-none !p-0 !h-[40px]"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}
