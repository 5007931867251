import { Icon } from "@iconify/react"
import { Dropdown, Input } from "components/core"
import { paymentStatus } from "constants/global"

interface Props {
  state: any
}

export const PaymentStructure = ({ state }: Props) => {
  const { formData, handleChange, setFormData } = state

  return (
    <div className="mb-[56px]">
      <p className="mb-[8px] font-medium text-sm text-headers">
        <Icon icon="ph:pencil-line" className="inline w-[20px] h-[20px]" /> PAYMENT STRUCTURE
      </p>
      <div className="pl-[24px] w-full mb-[20px] rounded-sm">
        <table className="w-full">
          <thead>
            <tr className="text-left border text-xs text-body">
              <th className="p-[8px] border-r font-medium text-start w-[270px]">Top up Amount</th>
              <th className="p-[8px] border-r font-medium text-start w-[270px]">Base Amount</th>
              <th className="p-[8px] border-r font-medium text-start w-[270px]">Payment Status</th>
            </tr>
          </thead>
          <tbody>
            <tr className="border h-12 text-sm text-body">
              <td className="px-3 border-r">
                <div className="flex space-x-2">
                  <Input
                    type="number"
                    id="fromEligibilityAmount"
                    name="fromEligibilityAmount"
                    placeholder="From"
                    value={formData?.fromEligibilityAmount}
                    onChange={handleChange}
                    className="!w-full"
                    variant="border-y-0 border-l-0 !p-0 !h-[40px]"
                  />
                  <Input
                    type="number"
                    id="toEligibilityAmount"
                    name="toEligibilityAmount"
                    placeholder="To"
                    value={formData?.toEligibilityAmount}
                    onChange={handleChange}
                    className="!w-full"
                    variant="border-none !p-0 !h-[40px]"
                  />
                </div>
              </td>
              <td className="px-3 border-r">
                <div className="flex space-x-2">
                  <Input
                    type="number"
                    id="fromTotalAmount"
                    name="fromTotalAmount"
                    placeholder="From"
                    value={formData?.fromTotalAmount}
                    onChange={handleChange}
                    className="!w-full"
                    variant="border-y-0 border-l-0 !p-0 !h-[40px]"
                  />
                  <Input
                    type="number"
                    id="toTotalAmount"
                    name="toTotalAmount"
                    placeholder="To"
                    value={formData?.toTotalAmount}
                    onChange={handleChange}
                    className="!w-full"
                    variant="border-none !p-0 !h-[40px]"
                  />
                </div>
              </td>
              <td className="col-span-5">
                <Dropdown
                  selected={formData.paymentStatus}
                  containerVariant="!border-none !shadow-none"
                  wrapperClassName="!mb-0"
                  options={paymentStatus}
                  placeholder="Please Select"
                  multiple={true}
                  search={false}
                  onSelect={(data: any) => setFormData({ ...formData, paymentStatus: data })}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}
