import { useGetPaymentScheduleAnalyticsQuery } from "services/api/paymentSlice"

export default function usePayments() {
  const { data: scheduleAnalytics, isFetching: isFetchingAnalytics } = useGetPaymentScheduleAnalyticsQuery({})

  const paymentSchedulesDetails = [
    {
      title: "Total Payment Schedule",
      count: scheduleAnalytics?.total_payment_schedule ?? 0,
      indicator: null,
    },
    // {
    //   title: "Schedule Paid",
    //   count: scheduleAnalytics?.total_schedule_paid ?? 0,
    //   indicator: "bg-success-main",
    // },
    // {
    //   title: "Schedule In-Progress",
    //   count: scheduleAnalytics?.total_schedule_in_progress ?? 0,
    //   indicator: "bg-warning-dark",
    // },
    // {
    //   title: "Schedule Unpaid",
    //   count: scheduleAnalytics?.total_schedule_unpaid ?? 0,
    //   indicator: "bg-error-main",
    // },
  ]

  return { paymentSchedulesDetails, scheduleAnalytics, isFetchingAnalytics }
}
