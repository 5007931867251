import React from "react"

interface StatusProps {
  status: "paid" | "pending" | "on-hold" | string
}

const StatusComponent: React.FC<StatusProps> = ({ status }) => {
  let textColor: string, bgColor: string

  switch (status) {
    case "paid":
      textColor = "text-success-main"
      bgColor = "bg-success-lightest"
      break
    case "pending":
      textColor = "text-warning-dark"
      bgColor = "bg-warning-lightest"
      break
    case "in-progress":
      textColor = "text-warning-dark"
      bgColor = "bg-warning-lightest"
      break
    case "on-hold":
      textColor = "text-error-dark"
      bgColor = "bg-error-lightest"
      break
    default:
      textColor = "text-black"
      bgColor = "bg-gray-200"
  }

  return (
    <div className={`capitalize rounded-2xl w-fit text-xs font-medium px-2 py-1 ${textColor} ${bgColor}`}>{status}</div>
  )
}

export default StatusComponent
