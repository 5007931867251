import { createApi } from "@reduxjs/toolkit/query/react"
import { axiosBaseQuery } from "services/axiosBaseQuery"
import {
  FetchedBeneficiaries,
  FetchedBeneficiariesExport,
  FetchedBeneficiaryIntervention,
  SingleBeneficiary,
} from "types/beneficiary"

const baseUrl = process.env.REACT_APP_DATA_MINING_BASE_URL ?? ""

export const beneficiarySlice = createApi({
  reducerPath: "beneficiarySlice",
  baseQuery: axiosBaseQuery({ baseUrl }),
  tagTypes: ["Beneficiaries", "Beneficiary", "Analytics", "Intervention", "Transactions"],
  refetchOnMountOrArgChange: true,
  endpoints(build) {
    return {
      getBeneficiaries: build.query<FetchedBeneficiaries, any>({
        query: (params) => ({ url: `/beneficiary`, method: "get", params }),
        transformResponse: (response: any) => response.data,
        providesTags: ["Beneficiaries"],
      }),
      getBeneficiary: build.query<SingleBeneficiary, any>({
        query: ({ reference, params }) => ({ url: `/beneficiary/${reference}`, method: "get", params }),
        transformResponse: (response: any) => response.data,
        providesTags: ["Beneficiary"],
      }),
      getBeneficiaryAnalytics: build.query<any, any>({
        query: () => ({ url: "/beneficiary/analytics", method: "get" }),
        transformResponse: (response: any) => response.data,
        providesTags: ["Analytics"],
      }),
      getBeneficiaryInterventionHistory: build.query<FetchedBeneficiaryIntervention, any>({
        query: ({ reference, params }) => ({ url: `/beneficiary/${reference}/interventions`, method: "get", params }),
        transformResponse: (response: any) => response.data,
        providesTags: ["Intervention"],
      }),
      getBeneficiariesExport: build.query<FetchedBeneficiariesExport, any>({
        query: (params) => ({ url: "/beneficiary", method: "get", params }),
        transformResponse: (response: any) => response,
        providesTags: ["Beneficiaries"],
      }),
      getBeneficiariesExportById: build.query<FetchedBeneficiaries, any>({
        query: (params) => ({ url: `/beneficiary/export?`, method: "get", params }),
        transformResponse: (response: any) => response,
        providesTags: ["Beneficiaries"],
      }),
      updateBeneficiary: build.mutation<any, any>({
        query: (data) => ({ url: `/beneficiary`, method: "put", data }),
        transformResponse: (response: any) => response,
      }),
      getBeneficiaryHistory: build.query<FetchedBeneficiaries, any>({
        query: (params) => ({ url: `/beneficiary/update-history?`, method: "get", params }),
        transformResponse: (response: any) => response.data,
        providesTags: ["Beneficiaries"],
      }),
      getBeneficiariesUrl: build.query<FetchedBeneficiaries, any>({
        query: (params) => ({ url: `/beneficiary/download?`, method: "get", params }),
        transformResponse: (response: any) => response,
        providesTags: ["Beneficiaries"],
      }),
      getBeneficiaryHistoryAnalytics: build.query<any, any>({
        query: () => ({ url: "/beneficiary/update-history-analytics", method: "get" }),
        transformResponse: (response: any) => response.data,
        providesTags: ["Analytics"],
      }),
      getBeneficiaryPaymentHistory: build.query<FetchedBeneficiaries, any>({
        query: ({ reference, params }) => ({ url: `/transactions/beneficiary/${reference}`, method: "get", params }),
        transformResponse: (response: any) => response,
        providesTags: ["Beneficiaries", "Transactions"],
      }),
    }
  },
})

export const {
  useGetBeneficiariesQuery,
  useGetBeneficiaryQuery,
  useGetBeneficiaryAnalyticsQuery,
  useGetBeneficiaryInterventionHistoryQuery,
  useLazyGetBeneficiariesQuery,
  useLazyGetBeneficiariesExportQuery,
  useLazyGetBeneficiariesExportByIdQuery,
  useUpdateBeneficiaryMutation,
  useGetBeneficiaryHistoryQuery,
  useLazyGetBeneficiariesUrlQuery,
  useGetBeneficiaryHistoryAnalyticsQuery,
  useGetBeneficiaryPaymentHistoryQuery,
} = beneficiarySlice
