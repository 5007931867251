import { ReactNode } from "react"
import "./authLayout.css"
import { RedirectIfToken } from "components/hoc"

export const AuthLayout = ({ children }: { children: ReactNode }) => {
  return (
    <RedirectIfToken>
      <main className="flex flex-col items-center pt-[160px] min-h-screen w-full cc-bg">{children}</main>
    </RedirectIfToken>
  )
}
