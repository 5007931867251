import { Icon } from "@iconify/react"
import useSendGrievanceComment from "./useSendComment"

const CommentBox = ({ data }: any) => {
  const { comment, setComment, isPostingComment, handlePostComment } = useSendGrievanceComment()

  const handleChange = (e: any) => {
    setComment(e.target.value)
  }

  const handleClearInput = () => {
    setComment("")
  }

  return (
    <div className="absolute bottom-0 left-0 right-0 bg-white py-4 px-6 border rounded-md ">
      <div className="flex flex-col">
        <textarea
          className="flex-1 w-full focus:outline-none text-body text-sm  px-3 py-2 mr-4"
          rows={4}
          placeholder="Enter Comment"
          value={comment}
          onChange={handleChange}
        />
        <div className="flex justify-end gap-x-2">
          <button
            onClick={handleClearInput}
            className="text-body text-xs py-[6px] px-[12px] rounded-lg hover:text-gray-700 font-medium border-[1px] border-[#E6E6E6]"
          >
            Cancel
          </button>
          <button
            disabled={comment.length < 1}
            className="bg-primary-actions flex items-center
            gap-x-2  text-white font-medium disabled:cursor-not-allowed disabled:bg-opacity-55  text-xs py-[6px] px-[12px] rounded-md"
            onClick={() => handlePostComment(data)}
          >
            {isPostingComment ? "sending..." : " Send"}
            <Icon icon="ph:paper-plane-tilt" className="text-white" />
          </button>
        </div>
      </div>
    </div>
  )
}

export default CommentBox
