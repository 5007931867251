import React from "react"
import useTransactions from "./useTransactions"
import { Icon } from "@iconify/react"
import { motion } from "framer-motion"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { Search, Button, Calendar, Breadcrumb, Table } from "components/core"
import PaymentsTransactionsSummary from "components/pages/Payments/Transactions/TransactionsSummary/TransactionsSummary"
import { paymentsTransactionsHeaders } from "constants/payments"
import { formatTimestamp } from "utils/dateFormatter"
import { addCommasToNumbers } from "utils/addCommasToNumbers"
import { singleTransactionData } from "services/api/paymentSlice"
import { AllPermissions, PermissionModule } from "types/permission"
import { Restricted, RenderIf } from "components/hoc"
import { usePermission } from "hooks"

const PaymentsTransactions = () => {
  const { TRANSACTIONS } = PermissionModule
  const { hasPermission } = usePermission()
  const { VIEW_TRANSACTIONS, VIEW_BATCHES } = AllPermissions
  const {
    breadCrumbs,
    date,
    setDate,
    handleSearch,
    params,
    changePage,
    selectLimit,
    transactions,
    isFetchingTransactions,
    exportTransactionData,
    isExportingLoading,
  } = useTransactions()

  const dispatch = useDispatch()

  const navigate = useNavigate()

  const MotionTr = motion.tr

  const showBatches = hasPermission(TRANSACTIONS, VIEW_BATCHES)

  const navigateToSingleTransactionPage = (item: { transaction_id: string }) => {
    if (showBatches) {
      dispatch(singleTransactionData(item))
      navigate(`/payments/transactions/${item?.transaction_id}`)
    }
  }

  return (
    <Restricted module={TRANSACTIONS} permission={VIEW_TRANSACTIONS}>
      <main data-testid="payments-transactions">
        <div className="mb-[23px]">
          <Breadcrumb options={breadCrumbs} />
          <h1 className="text-headers text-2xl font-bold">Transactions</h1>
        </div>

        <PaymentsTransactionsSummary />

        <div className="flex flex-wrap gap-4 items-center justify-between mb-4 mt-6">
          <div className="flex  space-x-2">
            <Search id="search" name="Search" placeholder="Search" onChange={handleSearch} />
            <Calendar startDate={date?.startDate} endDate={date?.endDate} onChange={setDate} selectsRange={true} />
          </div>

          <Button
            type="button"
            size="40"
            theme="transparent"
            loading={isExportingLoading}
            onClick={exportTransactionData}
          >
            <Icon icon="uiw:download" className="text-[#747F9C] w-4 h-4 mr-3" />
            Export
          </Button>
        </div>

        <div>
          <Table
            headers={paymentsTransactionsHeaders}
            data={transactions?.transactions ?? []}
            onPageChange={changePage}
            totalCount={Number(transactions?.total)}
            perPage={Number(params?.limit)}
            page={Number(params?.page)}
            selectLimit={selectLimit}
            loading={isFetchingTransactions}
          >
            <>
              {transactions !== undefined
                ? transactions?.transactions?.map((item: any) => (
                    <React.Fragment key={item.transaction_id}>
                      <MotionTr
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.3 }}
                        className="cc-custom-table-data-row"
                        data-testid={`transactions ${item?.transaction_id}`}
                        onClick={() => navigateToSingleTransactionPage(item)}
                      >
                        <td className="max-w-[150px] truncate capitalize">{item?.schedule_reference}</td>
                        <td>{`₦ ${addCommasToNumbers(Number(item?.amount))}`}</td>
                        <td>{formatTimestamp(item.created_at)}</td>
                        <td>{item?.narration}</td>
                        <RenderIf condition={showBatches}>
                          <td onClick={() => navigateToSingleTransactionPage(item)}>
                            <div className="border border-[#E6E6E6] grid place-items-center p-[6px] rounded-[4px] cursor-pointer">
                              <Icon icon="ph:eye" className="w-4 h-4" />
                            </div>
                          </td>
                        </RenderIf>
                      </MotionTr>
                    </React.Fragment>
                  ))
                : null}
            </>
          </Table>
        </div>
      </main>
    </Restricted>
  )
}

export default PaymentsTransactions
