export const convertStateToDropdownOptions = (states: any[]) => {
  return (
    states?.map((item: any) => {
      return {
        label: `${item?.name}`,
        value: `${item?.name}`,
        id: item.id,
      }
    }) ?? []
  )
}
