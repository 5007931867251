import useInputValidate from "hooks/useInputValidate/useInputValidate"
import { ChangeEvent, useMemo, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { toast } from "react-toastify"
import { useCreatePasswordMutation } from "services/api/authSlice"
import { tryCatch } from "utils/tryCatch"

export default function useCreatePassword() {
  const { validateFormData } = useInputValidate()
  const [searchParams] = useSearchParams()
  const email = searchParams.get("email")
  const [createPassword, { isLoading }] = useCreatePasswordMutation()
  const navigate = useNavigate()
  const [formData, setFormData] = useState({ password: "", email })

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const onSubmit = () => {
    tryCatch(async () => {
      const res = await createPassword(formData).unwrap()
      if (res?.code === 200) {
        toast.success(res?.message)
        navigate("/")
      }
    })
  }

  const disabledButton = useMemo(() => {
    return !validateFormData(formData)
  }, [formData])

  return {
    formData,
    setFormData,
    handleChange,
    onSubmit,
    isLoading,
    disabledButton,
  }
}
