import { Button, Input, Textarea } from "components/core"
import { Dropdown } from "components/core/Dropdown/Dropdown"
import { genderOptionsII } from "mocks/payments"
import Phone from "components/core/PhoneInput/PhoneInput"
// import { nigerianStates } from "constants/intervention"
import { useNavigate } from "react-router-dom"
import useBasicInformation from "./useBasicInformation"
import { complaintType, complaintLevel, complaintMode } from "mocks/grievance"
import { RenderIf } from "components/hoc"

interface Props {
  formData: any
  // eslint-disable-next-line no-unused-vars
  handleChange: (e: any) => void
  proceed: () => void
  setFormData: any
}

const BasicInformation = ({ formData, handleChange, proceed, setFormData }: Props) => {
  const navigate = useNavigate()

  const {
    disableBtn,
    nextPage,
    grievancesCategoriesOptions,
    isLoadingGrievanceCategories,
    isLoadingGrievanceSubCategories,
    subCategoriesOptions,
    grievanceZonesOptions,
    isFetchingZones,
    isFetchingStates,
    grievanceStateOptions,
    grievanceLGAOptions,
    grievanceCommunityOption,
    isFetchingCommunities,
    phone,
    setPhone,
  } = useBasicInformation({ formData, proceed, setFormData })

  return (
    <form className="flex flex-col gap-y-4" onSubmit={nextPage}>
      <Input
        label="Complainant Name"
        type="text"
        id="name"
        value={formData?.complaint_name}
        onChange={handleChange}
        name="complaint_name"
      />
      <div className="grid grid-cols-2 gap-4">
        <Dropdown
          label="Complainant Type"
          selected={formData?.complaint_type_name}
          options={complaintType}
          onSelect={(data: any) => {
            setFormData({ ...formData, complaint_type: data?.value, complaint_type_name: data?.label })
          }}
        />

        <Dropdown
          label="Gender"
          selected={formData?.gender}
          options={genderOptionsII.slice(1)}
          onSelect={(data: any) => {
            setFormData({
              ...formData,
              gender: data?.value,
            })
          }}
        />
      </div>

      <Dropdown
        label="Complaint Mode"
        selected={formData?.complaint_mode}
        options={complaintMode}
        onSelect={(data: any) => {
          setFormData({
            ...formData,
            complaint_mode: data?.value,
          })
        }}
      />

      <Phone value={phone} onChange={setPhone} label="Phone number" />

      <Input
        label="Complainant Address"
        type="text"
        id="complaint_address"
        value={formData?.complaint_address}
        onChange={handleChange}
        name="complaint_address"
      />
      <Textarea
        label="Description"
        id="description"
        name="description"
        rows={4}
        value={formData?.description}
        onChange={handleChange}
        maxLength={340}
      />
      <Dropdown
        label="Category"
        selected={formData?.category_id_name}
        enableSearchOptions={true}
        loading={isLoadingGrievanceCategories}
        options={grievancesCategoriesOptions}
        onSelect={(data: any) => {
          setFormData({
            ...formData,
            category_id: data?.value,
            category_id_name: data?.label,
          })
        }}
      />
      <Dropdown
        label="Sub Category"
        selected={formData?.sub_category_id_name}
        enableSearchOptions={true}
        loading={isLoadingGrievanceSubCategories}
        options={subCategoriesOptions}
        onSelect={(data: any) => {
          setFormData({
            ...formData,
            sub_category_id: data?.value,
            sub_category_id_name: data?.label,
          })
        }}
      />

      <RenderIf condition={formData?.category_id === "8c4d7624-36eb-11ef-bdcd-6f3f37ec1b91"}>
        <Dropdown
          label="Do you want to continue with NASSP? "
          selected={formData?.nassp}
          options={[
            { label: "Yes", value: true },
            { label: "No", value: false },
          ]}
          onSelect={(data: any) => {
            setFormData({
              ...formData,
              nassp: data?.value,
            })
          }}
        />
      </RenderIf>

      <div className="grid grid-cols-2 gap-4">
        <Dropdown
          label="Zone"
          selected={formData?.zone}
          options={grievanceZonesOptions}
          loading={isFetchingZones}
          onSelect={(data: any) => {
            setFormData({
              ...formData,
              zone_name: data?.label,
              zone_id: data?.value,
            })
          }}
        />
        <Dropdown
          label="State"
          loading={isFetchingStates}
          selected={formData?.state_name}
          options={grievanceStateOptions}
          onSelect={(data: any) => {
            setFormData({
              ...formData,
              state_name: data?.label,
              state_id: data?.value,
            })
          }}
        />
        <Dropdown
          label="LGA"
          loading={isFetchingStates}
          selected={formData?.lga}
          options={grievanceLGAOptions}
          onSelect={(data: any) => {
            setFormData({
              ...formData,
              lga_name: data?.label,
              lga_id: data?.value,
            })
          }}
        />
        {/* <Dropdown
          label="Ward"
          selected={formData?.location}
          options={nigerianStates.slice(1)}
          onSelect={(data: any) => {
            setFormData({
              ...formData,
              location: data?.value,
            })
          }}
        /> */}
        <Dropdown
          label="Community"
          selected={formData?.community_name ?? ""}
          options={grievanceCommunityOption}
          loading={isFetchingCommunities}
          onSelect={(data: any) => {
            setFormData({
              ...formData,
              community_id: String(data?.value),
              community_name: data?.label,
            })
          }}
        />
      </div>

      <Dropdown
        label="Registration Level"
        selected={formData?.level}
        options={complaintLevel}
        onSelect={(data: any) => {
          setFormData({
            ...formData,
            level: data?.value,
          })
        }}
      />
      <div className="flex justify-start items-center mt-12 space-x-4">
        <Button theme="primary" size="44" className="min-w-[171px]" disabled={disableBtn}>
          Proceed
        </Button>
        <Button
          theme="transparent"
          className="w-[171px] px-[30px]"
          type="button"
          onClick={() => navigate(-1)}
          size="44"
          data-testid="discard-btn"
        >
          Discard
        </Button>
      </div>
    </form>
  )
}

export default BasicInformation
