import { InterventionDetails } from "components/pages/Interventions"
import { useLocation } from "react-router-dom"

export default function ViewIntervention() {
  const { pathname } = useLocation()
  const interventionId = pathname.split("/")[2] ?? ""

  return (
    <main data-testid="view-intervention">
      <InterventionDetails interventionId={interventionId} />
    </main>
  )
}
