import { useCallback, useState } from "react"
import { useNavigate } from "react-router-dom"

interface eligibilityTableData {
  condition: string
  percentage: string
  // eligibility_category: string
}

interface EligibilityCriteria {
  [key: string]: any[]
}

interface props {
  setFormData: any
  formData: any
  proceed: () => void
}

export default function useAdditionalEligibility({ formData, setFormData, proceed }: props) {
  const navigate = useNavigate()
  const eligibilityCriteriaValues: eligibilityTableData = {
    condition: "",
    percentage: "",
  }

  const additionalEligibility = [
    {
      label: "Co-responsibility eligibility",
      value: "co-responsibility",
      values: formData?.eligibility_criteria?.["co-responsibility"] ?? [],
    },
    {
      label: "Livelihood eligibility",
      value: "livelihood",
      values: formData?.eligibility_criteria?.["livelihood"] ?? [],
    },
  ]

  const [eligibilities, setEligibilities] = useState<any[]>(additionalEligibility)

  const addEligibilityRow = (label: string) => {
    const index = eligibilities.findIndex((el) => el.label === label)

    const newEligibilities = [...eligibilities]
    newEligibilities[index] = {
      ...newEligibilities[index],
      values: [...newEligibilities[index].values, { ...eligibilityCriteriaValues }],
    }
    setEligibilities(newEligibilities)
  }

  const deleteEligibilityRow = useCallback((label: string, index: number) => {
    setEligibilities((prevEligibilities) => {
      return prevEligibilities.map((eligibility) => {
        if (eligibility.label === label) {
          return {
            ...eligibility,
            values: eligibility.values.filter((_: any, i: any) => i !== index),
          }
        }
        return eligibility
      })
    })
  }, [])

  const updateRowValue = ({
    label,
    key,
    value,
    index,
  }: {
    label: string
    key: string
    value: string
    index: number
  }) => {
    const newEligibilities = [...eligibilities]
    const elIndex: any = (newEligibilities as any).findIndex((el: { label: string }) => el.label === label)
    const eligibility = [...newEligibilities[elIndex].values]
    const updatedElig = { ...eligibility[index], [key]: value }

    eligibility[index] = updatedElig

    newEligibilities[elIndex] = {
      ...newEligibilities[elIndex],
      values: eligibility,
    }
    setEligibilities(newEligibilities)
  }

  const submit = () => {
    const payload = eligibilities.reduce((acc, eligibility) => {
      acc[eligibility.value] = eligibility.values
      return acc
    }, {})

    const filteredCriteria = Object.entries(payload).reduce((acc, [key, value]) => {
      if (Array.isArray(value) && value.length > 0) {
        acc[key] = value
      }
      return acc
    }, {} as EligibilityCriteria)

    setFormData({ ...formData, eligibility_criteria: filteredCriteria })
    proceed()
  }
  const [selected, setSelected] = useState<string[]>([])

  const updateSelected = (label: string) => {
    const newSelected = [...selected]

    if (selected.includes(label)) return setSelected(newSelected.filter((val) => val !== label))
    return setSelected([...newSelected, label])
  }
  const disableBtn = eligibilities.some((item) => {
    if (item.values.length > 0) {
      return item.values.every((obj: any) => Object.values(obj).every((value) => value !== ""))
    }
    return false // If values array is empty, it should be considered invalid
  })

  const preSelectedEligibility = Object.keys(formData.eligibility_criteria)

  return {
    eligibilities,
    setEligibilities,
    preSelectedEligibility,
    submit,
    disableBtn,
    updateSelected,
    navigate,
    selected,
    addEligibilityRow,
    deleteEligibilityRow,
    updateRowValue,
  }
}
