import { Breadcrumb, Button, Search, Filter, Table, Avatar, Status, ManageItem } from "components/core"
import { Icon } from "@iconify/react"
import { staffManagementHeaders } from "mocks/staffs"
import { Actions } from "components/core/Table/Actions/Actions"
import AddEditStaff from "components/pages/StaffManagements/AddEditStaff/AddEditStaff/AddEditStaff"
import useStaffManagement from "./useStaffManagement"
import { ViewStaff } from "components/pages/StaffManagements/ViewStaff/ViewStaff"
import { statuses } from "constants/staffs"
import { Restricted } from "components/hoc"
import { AllPermissions, PermissionModule } from "types/permission"

const StaffManagement = () => {
  const {
    staffs,
    roleFilterOptions,
    actions,
    changePage,
    handleSearch,
    selectedStatus,
    setSelectedStatus,
    selectedRole,
    setSelectedRole,
    breadCrumbs,
    selectedAction,
    setSelectedAction,
    isDeactivatingStaff,
    isActivatingStaff,
    activateDeactivateStaff,
    isFetchingStaffs,
    isFetchingRoles,
    resetSelection,
    selectedStaff,
    selectLimit,
    exportAllStaff,
    isExporting,
  } = useStaffManagement()

  const { STAFF_MANAGEMENT } = PermissionModule
  const { VIEW_ALL_STAFF, ADD_STAFF } = AllPermissions

  return (
    <Restricted module={STAFF_MANAGEMENT} permission={VIEW_ALL_STAFF} showError={true}>
      <main data-testid="staff-management">
        <div className="mb-[23px]">
          <Breadcrumb options={breadCrumbs} />
          <h1 className="text-headers text-2xl font-bold">Staff Management</h1>
        </div>
        <div className="flex items-center justify-between mb-4">
          <div className="flex space-x-2">
            <Search id="search" name="search" placeholder="Search" onChange={handleSearch} />
            <Filter
              title="Status"
              options={statuses}
              multiple={true}
              selectedOption={selectedStatus}
              setSelectedOption={setSelectedStatus}
            />
            <Filter
              title="Role"
              options={roleFilterOptions}
              multiple={true}
              loading={isFetchingRoles}
              selectedOption={selectedRole}
              setSelectedOption={setSelectedRole}
            />
          </div>
          <div className="flex space-x-2">
            <Restricted module={STAFF_MANAGEMENT} permission={VIEW_ALL_STAFF}>
              <Button type="button" size="40" disabled={isExporting} onClick={exportAllStaff} theme="transparent">
                <Icon icon="uiw:download" className="text-[#747F9C] w-5 h-5 mr-3" />
                {isExporting ? "Exporting..." : "Export"}
              </Button>
            </Restricted>
            <Restricted module={STAFF_MANAGEMENT} permission={ADD_STAFF}>
              <Button type="button" size="40" theme="primary" onClick={() => setSelectedAction("Add")}>
                <Icon icon="ph:plus" className="w-6 h-6 mr-[8px]" />
                Add New Staff
              </Button>
            </Restricted>
          </div>
        </div>
        <Table
          headers={staffManagementHeaders}
          data={staffs?.staff ?? []}
          onPageChange={changePage}
          selectLimit={selectLimit}
          totalCount={staffs?.count}
          perPage={staffs?.limit}
          page={staffs?.page}
          loading={isFetchingStaffs}
        >
          <>
            {staffs?.staff?.map((item) => (
              <tr data-testid={item?.staff_id} key={item?.staff_id} className="cc-custom-table-data-row">
                <td className="flex items-center ">
                  <div className="flex items-center">
                    <Avatar
                      key={item?.id}
                      image={`${item?.first_name} ${item?.last_name}`}
                      alt={item?.first_name}
                      size="32"
                    />
                    <div className="ml-[16px]">
                      <p className="max-w-[280px] truncate capitalize">
                        {item?.first_name} {item.last_name}
                      </p>
                      <p className="text-grey-base text-xs">{item?.email}</p>
                    </div>
                  </div>
                </td>
                <td>
                  <p className="w-max">{item?.staff_id ?? "N/A"}</p>
                </td>
                <td>
                  <p className="w-max">{item?.staff_number ?? "N/A"}</p>
                </td>
                <td>
                  <p className="w-max">{item?.phone_number ? `0${item?.phone_number}` : "N/A"}</p>
                </td>
                <td className="w-max capitalize">{item?.role}</td>
                <td>
                  <Status status={item?.status} />
                </td>
                <td className="w-[20px]">
                  <Actions actions={actions} module={STAFF_MANAGEMENT} id={item?.id} item={item} />
                </td>
              </tr>
            ))}
          </>
        </Table>
        {selectedAction === "View" && (
          <ViewStaff
            closeModal={resetSelection}
            staffDetails={selectedStaff}
            showModal={() => setSelectedAction(selectedStaff?.status === "active" ? "Deactivate" : "Activate")}
          />
        )}
        {["Add", "Edit"].includes(selectedAction) && (
          <AddEditStaff
            closeModal={resetSelection}
            action={selectedAction}
            staff={selectedStaff}
            resetSelection={resetSelection}
          />
        )}
        {["Deactivate", "Activate"].includes(selectedAction) && (
          <ManageItem
            title={`Do you want to ${selectedAction.toLowerCase()}  the user?`}
            actionName={selectedAction}
            cancelBtnText="Cancel"
            caption={`Confirming this action will ${selectedAction.toLowerCase()} this staff and 
            they would not be able to access their account until it's reactivated`}
            loading={isActivatingStaff || isDeactivatingStaff}
            onClose={resetSelection}
            onSubmit={activateDeactivateStaff}
          />
        )}
      </main>
    </Restricted>
  )
}

export default StaffManagement
