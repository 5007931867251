import RolesAndPermissions from "pages/settings/RolesAndPermissions/RolesAndPermissions"
import ViewRole from "pages/settings/RolesAndPermissions/ViewRole/ViewRole"
import StaffManagement from "pages/settings/StaffManagement/StaffManagement"
import Configuration from "pages/settings/Configuration/Configuration"
import { Restricted } from "components/hoc"
import { AllPermissions, PermissionModule } from "types/permission"

const { ROLES_AND_PERMISSION, CONFIGURATION } = PermissionModule
const { VIEW_ALL_ROLES, VIEW_SINGLE_ROLE, VIEW_ALL_CONFIG } = AllPermissions

export const settingsRoutes = [
  {
    path: "settings/roles-and-permissions",
    element: (
      <Restricted module={ROLES_AND_PERMISSION} permission={VIEW_ALL_ROLES} showError={true}>
        <RolesAndPermissions />
      </Restricted>
    ),
  },
  {
    path: "settings/staff-management",
    element: <StaffManagement />,
  },
  {
    path: "settings/roles-and-permissions/:id",
    element: (
      <Restricted module={ROLES_AND_PERMISSION} permission={VIEW_SINGLE_ROLE} showError={true}>
        <ViewRole />
      </Restricted>
    )
  },
  {
    path: "settings/configuration",
    element: (
      <Restricted module={CONFIGURATION} permission={VIEW_ALL_CONFIG} showError={true}>
        <Configuration />
      </Restricted>
    )
  },
]