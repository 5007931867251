import React from "react"

interface TrainingStatusProps {
  status: "not-started" | "in-progress" | "completed" | "unknown" | "absent" | "attended"
}

const TrainingStatus: React.FC<TrainingStatusProps> = ({ status }) => {
  let classes = ""

  switch (status) {
    case "not-started":
      classes = "bg-gray-50 text-gray-base"
      break
    case "in-progress":
    case "unknown":
      classes = "bg-warning-lightest text-warning-dark"
      break
    case "completed":
    case "attended":
      classes = "bg-success-lightest text-success-main"
      break
    case "absent":
      classes = "bg-error-lightest text-error-main"
      break
    default:
      break
  }

  return (
    <div>
      <div
        className={`${classes} inline-block py-[4px] px-[12px] text-center rounded-full font-medium text-xs capitalize text-nowrap`}
      >
        {status.replace("-", " ")}
      </div>
    </div>
  )
}

export default TrainingStatus
