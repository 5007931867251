import { Icon } from "@iconify/react"
import { Button, Search, Calendar, ManageItem } from "components/core"
import { DataResourceCard, DataResourceCardLoader } from "components/pages/DataMining"
import { dataMining } from "mocks/data-mining"
import useDataMining from "./useDataMining"
import { MineUpdateData } from "components/pages/DataMining/MineData/MineUpdateData/MineUpdateData"
import { RenderIf, Restricted } from "components/hoc"
import { ReactComponent as EmptyData } from "assets/svg/empty-data-sources.svg"
import { fullDateFormatter } from "utils/dateFormatter"
import { useState } from "react"
import { AllPermissions, PermissionModule } from "types/permission"
import ProgressDialog from "components/pages/DataMining/ViewDataSource/ProgressDialog/ProgressDialog"
import { AnimatePresence } from "framer-motion"

export default function DataMining() {
  const {
    setSelectedAction,
    selectedAction,
    sources,
    setDataSourceDetails,
    isFetchingSources,
    date,
    setDate,
    handleSearch,
    refetchDataSources,
  } = useDataMining()
  const [openPercentageModal, setOpenPercentageModal] = useState<boolean | undefined>(false)
  const [openCompleteMiningModal, setOpenCompleteMiningModal] = useState<boolean>(false)
  const [openStatusDialog, setOpenStatusDialog] = useState<boolean>(false)

  const { CONFIGURATION, DATA_MINING } = PermissionModule
  const { VIEW_ALL_CONFIG, MINE_DATA } = AllPermissions
  return (
    <Restricted module={CONFIGURATION} permission={VIEW_ALL_CONFIG} showError={true}>
      <main data-testid="data-mining">
        <div className="mb-[24px]">
          <h1 className="text-headers text-2xl font-bold">Data Exchange and Quality Assurance</h1>
        </div>
        <div>
          <div className="flex items-center justify-between mb-6">
            <div className="flex space-x-2">
              <Search id="search" name="search" placeholder="Search" onChange={handleSearch} />
              <Calendar startDate={date.startDate} endDate={date.endDate} onChange={setDate} selectsRange={true} />
            </div>
            <Restricted module={DATA_MINING} permission={MINE_DATA}>
              <Button
                theme="primary"
                type="button"
                size="40"
                onClick={() => {
                  setSelectedAction("mine-data")
                }}
              >
                <Icon icon="solar:cloud-upload-outline" className="w-6 h-6 mr-[8px]" />
                Mine Data
              </Button>
            </Restricted>
          </div>
          <RenderIf condition={isFetchingSources}>
            <div className="grid gap-4 grid-cols-3">
              {dataMining.map((data) => (
                <DataResourceCardLoader key={data.id} />
              ))}
            </div>
          </RenderIf>
          <RenderIf condition={!isFetchingSources && dataMining?.length! > 0}>
            <div className="grid grid-cols-3 2xl:grid-cols-4 gap-4">
              {sources?.data?.configurationSettings.map((data: any) => (
                <DataResourceCard
                  onClick={() => setDataSourceDetails(data)}
                  id={data.configuration_id}
                  key={data.id}
                  logo={data.logo}
                  title={data.configuration_name}
                  subTitle={data.description}
                  date={fullDateFormatter(data.created_at)}
                  count={data.mine_count}
                />
              ))}
            </div>
          </RenderIf>
          <RenderIf
            condition={
              !isFetchingSources &&
              (sources?.data?.configurationSettings?.length! < 1 || !sources?.data?.configurationSettings)
            }
          >
            <div className="flex flex-col gap-2 py-36 w-full max-w-96 items-center mx-auto">
              <EmptyData className="w-100" />
              <h3>No Data Yet</h3>
            </div>
          </RenderIf>
        </div>
        {selectedAction === "mine-data" && (
          <MineUpdateData
            refetchDataSources={refetchDataSources}
            onCloseMineData={() => setSelectedAction("")}
            setOpenCompleteMiningModal={() => setOpenCompleteMiningModal(true)}
            openPercentageModal={openPercentageModal}
            setOpenPercentageModal={setOpenPercentageModal}
          />
        )}
        <RenderIf condition={openCompleteMiningModal}>
          <ManageItem
            showCloseIcon
            title="Do you want to complete mining in the background?"
            actionName="Keep Running"
            cancelBtnText="Yes, Proceed"
            caption="Mining these large data can take hours and we advise to run the mining process in the background"
            onClose={() => {
              setOpenCompleteMiningModal(false)
              setOpenStatusDialog(true)
            }}
            onSubmit={() => {
              setSelectedAction("mine-data")
              setOpenPercentageModal && setOpenPercentageModal(true)
              setOpenCompleteMiningModal && setOpenCompleteMiningModal(false)
            }}
          />
        </RenderIf>
        <AnimatePresence>
          {openStatusDialog && (
            <ProgressDialog refetch={refetchDataSources} onClose={() => setOpenStatusDialog(false)} />
          )}
        </AnimatePresence>
      </main>
    </Restricted>
  )
}
