import { ReactComponent as NasscoLogo } from "../assets/svg/nassco-logo.svg"
import { ReactComponent as NasscoIcon } from "../assets/icons/nassco-icon.svg"
import { ReactComponent as WorldbankLogo } from "../assets/svg/worldbank-logo.svg"

export const dataMining = [
  {
    id: "1",
    title: "NASSCO",
    subTitle:
      "Laying a strong foundation of rigorous and reliable evidence of poor and vulnerable households in Nigeria....",
    logo: <NasscoLogo />,
    date: "February 23, 2023",
    count: 5,
  },
  {
    id: "2",
    title: "World Bank",
    subTitle:
      "Laying a strong foundation of rigorous and reliable evidence of poor and vulnerable households in Nigeria....",
    logo: <WorldbankLogo />,
    date: "February 23, 2023",
    count: 5,
  },
  {
    id: "3",
    title: "NASSCO",
    subTitle:
      "Laying a strong foundation of rigorous and reliable evidence of poor and vulnerable households in Nigeria....",
    logo: <NasscoLogo />,
    date: "February 23, 2023",
    count: 5,
  },
  {
    id: "4",
    title: "NASSCO",
    subTitle:
      "Laying a strong foundation of rigorous and reliable evidence of poor and vulnerable households in Nigeria....",
    logo: <NasscoLogo />,
    date: "February 23, 2023",
    count: 5,
  },  
]


export const dataSources = [
  {
    id: 1,
    name: "nassco_data_may2021 (V1)",
    description: "Sub-text",
    file_type: "csv",
    added_on: "February 23, 2023",
    last_updated: "February 23, 2023",
    progress_status: "100",
    batch: [
      {
        id: 1.1,
        name: "nassco_data_may2021 (V1)",
        description: "Sub-text",
        file_type: "csv",
        added_on: "February 23, 2023",
        last_updated: "February 23, 2023",
        progress_status: "100",
      },
      {
        id: 1.1,
        name: "nassco_data_may2021 (V1)",
        description: "Sub-text",
        file_type: "csv",
        added_on: "February 23, 2023",
        last_updated: "February 23, 2023",
        progress_status: "100",
      },
    ],
  },
  {
    id: 2,
    name: "nassco_data_may2021 (V2)",
    description: "Sub-text",
    file_type: "api",
    added_on: "February 23, 2023",
    last_updated: "February 23, 2023",
    progress_status: "20",
  },
  {
    id: 3,
    name: "nassco_data_may2021 (V3)",
    description: "Sub-text",
    file_type: "api",
    added_on: "February 23, 2023",
    last_updated: "February 23, 2023",
    progress_status: "100",
  },
  {
    id: 4,
    name: "nassco_data_may2021 (V4)",
    description: "Sub-text",
    file_type: "csv",
    added_on: "February 23, 2023",
    last_updated: "February 23, 2023",
    progress_status: "100",
  },
  {
    id: 5,
    name: "nassco_data_may2021 (V5)",
    description: "Sub-text",
    file_type: "csv",
    added_on: "February 23, 2023",
    last_updated: "February 23, 2023",
    progress_status: "100",
  },
  {
    id: 6,
    name: "nassco_data_may2021 (V1)",
    description: "Sub-text",
    file_type: "csv",
    added_on: "February 23, 2023",
    last_updated: "February 23, 2023",
    progress_status: "100",
  },
  {
    id: 7,
    name: "nassco_data_may2021 (V2)",
    description: "Sub-text",
    file_type: "api",
    added_on: "February 23, 2023",
    last_updated: "February 23, 2023",
    progress_status: "20",
  },
  {
    id: 8,
    name: "nassco_data_may2021 (V3)",
    description: "Sub-text",
    file_type: "api",
    added_on: "February 23, 2023",
    last_updated: "February 23, 2023",
    progress_status: "100",
  },
  {
    id: 9,
    name: "nassco_data_may2021 (V4)",
    description: "Sub-text",
    file_type: "csv",
    added_on: "February 23, 2023",
    last_updated: "February 23, 2023",
    progress_status: "100",
  },
  {
    id: 10,
    name: "nassco_data_may2021 (V5)",
    description: "Sub-text",
    file_type: "csv",
    added_on: "February 23, 2023",
    last_updated: "February 23, 2023",
    progress_status: "100",
  },
]


export const dummySources = [
  {
    id: 1,
    title: "National Social Safety-Nets Coordinating Office (NASSCO)",
    image: <NasscoIcon />,
  },
  {
    id: 2,
    title: "National Social Safety-Nets Coordinating Office (NASSCO)",
    image: <NasscoIcon />,
  },
  {
    id: 3,
    title: "National Social Safety-Nets Coordinating Office (NASSCO)",
    image: <NasscoIcon />,
  },
  {
    id: 4,
    title: "National Social Safety-Nets Coordinating Office (NASSCO)",
    image: <NasscoIcon />,
  }
]
