import { ReactNode } from "react"
import { useSelector } from "react-redux"
import { Navigate, useLocation } from "react-router-dom"

export const RedirectIfToken = ({ children }: { children: ReactNode }) => {
  const token = useSelector((state: any) => state?.auth?.token)
  let location = useLocation()

  // Redirect user to dashboard if user is logged in
  if (token) return <Navigate to="/intervention" state={{ from: location }} replace />

  return children
}
