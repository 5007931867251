import React, { useRef } from "react"
import { ReactComponent as Folder } from "assets/svg/folder.svg"
import { ReactComponent as CSV } from "assets/svg/csv-file.svg"
import { ReactComponent as Delete } from "assets/svg/delete.svg"

interface UploadFileCardProps {
  // eslint-disable-next-line no-unused-vars
  handleFileUpload?: (e: React.ChangeEvent<HTMLInputElement>) => void
  // eslint-disable-next-line no-unused-vars
  handleFileDelete?: () => void
  uploadedFile: any
}

const UploadBeneficiaryCard = ({ handleFileUpload, uploadedFile, handleFileDelete }: UploadFileCardProps) => {
  const fileRef = useRef<HTMLInputElement>(null)
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      handleFileUpload && handleFileUpload(e)
    }
  }

  const handleClick = () => {
    if (fileRef.current) {
      fileRef.current.value = ""
    }
  }

  return (
    <div>
      <div className="border-[0.3px] border-primary-actions bg-primary-30 mt-6 rounded-lg">
        <label htmlFor="file" onClick={handleClick}>
          <div className="h-60 flex flex-col items-center justify-center">
            <Folder />
            <div className="text-sm text-body text-center mt-6">
              <p>
                Drag or{" "}
                <span
                  className={`text-primary-actions  ${
                    uploadedFile.length !== 0 ? "cursor-not-allowed bg-gray-200" : "cursor-pointer"
                  }`}
                >
                  choose file
                </span>{" "}
                to upload
              </p>
              <p>Only .csv allowed and must not exceed 5mb</p>
            </div>
          </div>
        </label>

        <input
          data-testid="test-file"
          type="file"
          id="file"
          name="file"
          accept=".csv"
          className="hidden"
          ref={fileRef}
          onChange={handleFileChange}
          disabled={uploadedFile.length !== 0}
        />
      </div>
      {uploadedFile.name && (
        <div className="border border-[#E8E8E8] pr-4 py-2 flex justify-between  mt-6 rounded-lg items-center">
          <div className="flex">
            <CSV />
            <div>
              <p className="text-sm mt-1 text_neutral_600 font-medium">{uploadedFile?.name}</p>
              <p className="text-[10px] text-neutral_400">
                {uploadedFile?.size} kb of {uploadedFile?.size} kb
              </p>
            </div>
          </div>

          <Delete
            onClick={() => {
              handleFileDelete && handleFileDelete()
            }}
            className="cursor-pointer"
          />
        </div>
      )}
    </div>
  )
}

export default UploadBeneficiaryCard
