export const getCookie = (name: string) => {
  const cookies = document.cookie.split(";")
  for (const element of cookies) {
    const cookie = element.trim()
    // Check if this cookie has the name we're looking for
    if (cookie.startsWith(name + "=")) {
      // Return the value of the cookie
      return cookie.substring(name.length + 1)
    }
  }
  // Return null if the cookie was not found
  return null
}

export const clearTokenCookie = () => {
  document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/"
}
