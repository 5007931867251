import { Breadcrumb, CardLoader, Filter, Search } from "components/core"
import useIntervention from "../useIntervention"
import { paymentTypes, nigerianStates } from "constants/intervention"
import { EmptyState } from "components/core/Table/EmptyState/EmptyState"
import { RenderIf } from "components/hoc"
import { defaultFilter } from "mocks/staffs"
import { InterventionCardContainer } from "components/pages/Interventions"

const RequestingApproval = () => {
  const {
    breadCrumbs,
    handleSearch,
    selectedState,
    isFetchingInterventions,
    setSelectedState,
    dataSource,
    setDataSource,
    actions,
    requestingApproval,
    setPaymentType,
    paymentType,
    isFetchingSources,
    dataSources,
  } = useIntervention()

  return (
    <main data-testid="requesting-approval">
      <Breadcrumb options={breadCrumbs} showBack={true} />
      <h1 className="text-headers text-2xl font-bold">Requesting Approvals</h1>
      <div className="flex space-x-2 mb-4 mt-8">
        <Search id="search" name="search" placeholder="Search" onChange={handleSearch} />
        <Filter
          title="State"
          options={nigerianStates}
          loading={false}
          multiple={true}
          selectedOption={selectedState}
          setSelectedOption={setSelectedState}
        />
        <Filter
          title="Payment type"
          options={paymentTypes}
          loading={false}
          selectedOption={paymentType}
          setSelectedOption={setPaymentType}
        />
        <Filter
          title="Data source"
          options={[...defaultFilter, ...dataSources]}
          loading={isFetchingSources}
          multiple={true}
          selectedOption={dataSource}
          setSelectedOption={setDataSource}
        />
      </div>
      <RenderIf condition={isFetchingInterventions}>
        <div className="grid gap-4 grid-cols-3">
          {[1, 2, 3, 4, 5, 6].map((item) => (
            <CardLoader key={item} />
          ))}
        </div>
      </RenderIf>
      <RenderIf condition={!isFetchingInterventions && !!requestingApproval?.length}>
        <InterventionCardContainer
          title="PENDING"
          actions={actions}
          showStatus={false}
          data={requestingApproval}
          cardCanvasClass="h-[calc(100vh-340px)]"
        />
      </RenderIf>
      <RenderIf condition={!isFetchingInterventions && !requestingApproval?.length}>
        <EmptyState emptyStateText="Uh oh! There are no interventions requesting your approval at the moment" />
      </RenderIf>
    </main>
  )
}

export default RequestingApproval
