import { useEffect, useState, useMemo } from "react"
import { useTable } from "hooks"
import {
  useFetchGrievanceCategoriesQuery,
  useFetchGrievanceSubCategoriesQuery,
  useFetchGrievancesQuery,
} from "services/api/grievanceSlice"
import useGrievanceStatistics from "../GrievanceStatistics/useGrievanceStatistics"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
dayjs.extend(relativeTime)

export default function useGrievanceInfo() {
  const [currentTab, setCurrentTab] = useState("Pending")
  const [selectedCategory, setSelectedCategory] = useState<any>([])
  const [selectedSubCategory, setSelectedSubCategory] = useState<any>([])
  const [selectedGrievance, setSelectedGrievance] = useState<any>(null)
  const [subCategoryOptions, setSubCategoryOptions] = useState([])
  const [grievanceFormattedData, setGrievanceFormattedData] = useState<any>([])

  const { grievanceStats } = useGrievanceStatistics()

  const { data: grievanceCategories, isLoading: isLoadingGrievanceCategories } = useFetchGrievanceCategoriesQuery({})

  const grievancesCategoriesOptions = useMemo(() => {
    return (
      grievanceCategories?.categories?.map((category: any) => {
        return {
          label: category?.name,
          value: category?.id,
        }
      }) || []
    )
  }, [grievanceCategories])

  const { data: grievanceSubCategories, isLoading: isLoadingGrievanceSubCategories } =
    useFetchGrievanceSubCategoriesQuery(selectedCategory?.value, {
      skip: selectedCategory?.length === 0,
    })

  useEffect(() => {
    const subCategoryOptions =
      grievanceSubCategories !== "undefined"
        ? grievanceSubCategories?.sub_categories?.map((subCategory: any) => {
            return {
              label: subCategory?.name,
              value: subCategory?.id,
            }
          })
        : []

    setSubCategoryOptions(subCategoryOptions)
  }, [isLoadingGrievanceSubCategories, grievanceSubCategories, selectedCategory])

  useEffect(() => {
    if (isLoadingGrievanceSubCategories || !grievanceSubCategories) return

    const subCategoryOptions =
      grievanceSubCategories.sub_categories?.map((subCategory: any) => ({
        label: subCategory.name,
        value: subCategory.id,
      })) || []

    setSubCategoryOptions(subCategoryOptions)
  }, [grievanceSubCategories])

  const { handleSearch: handleSearchPending, params: pendingParams, resetParams: resetParamsPending } = useTable()

  const { handleSearch: handleSearchResolved, params: resolvedParams, resetParams: resetParamsResolved } = useTable()

  const resetParamsProxy = () => {
    currentTab === "Pending" ? resetParamsPending() : resetParamsResolved()
    setSelectedCategory("")
    setSelectedSubCategory("")
    setSubCategoryOptions([])
    setSelectedGrievance(null)
  }

  const tabs = [
    {
      name: "Pending",
      value: "Pending",
      count: grievanceStats?.grievance_pending ?? 0,
    },

    {
      name: "Resolved",
      value: "Resolved",
      count: grievanceStats?.grievance_resolved ?? 0,
    },
  ]

  const grievanceInformation = useMemo(() => {
    if (currentTab === "Pending") {
      return {
        handleSearch: handleSearchPending,
        ...pendingParams,
      }
    }

    return {
      ...resolvedParams,
      handleSearch: handleSearchResolved,
    }
  }, [currentTab, pendingParams, resolvedParams, handleSearchPending, handleSearchResolved])

  const params = {
    category_id: selectedCategory?.value,
    sub_category_id: selectedSubCategory?.value,
    search: currentTab === "Pending" ? pendingParams.search : resolvedParams.search,
    status: currentTab?.toLowerCase(),
  }

  const { currentData: grievanceData, isFetching: isLoadingGrievanceData } = useFetchGrievancesQuery({ params })

  const groupDataByDate = (data: any) => {
    const today = dayjs().startOf("day")
    const yesterday = dayjs().subtract(1, "day").startOf("day")

    return data.reduce((acc: any, item: any) => {
      const itemDate = dayjs(item?.created_at)
      let dateLabel = `From ${itemDate.format("DD|MM|YYYY")}`

      if (itemDate.isSame(today, "day")) {
        dateLabel = "Today"
      } else if (itemDate.isSame(yesterday, "day")) {
        dateLabel = "Yesterday"
      }

      if (!acc[dateLabel]) {
        acc[dateLabel] = []
      }

      acc[dateLabel].push(item)
      return acc
    }, {})
  }

  // // const grievanceFormattedData = useMemo(() => {
  // //   return grievanceData?.grievances?.length > 0 ? groupDataByDate(grievanceData?.grievances) : []
  // // }, [grievanceData, isLoadingGrievanceData, selectedGrievance])

  useEffect(() => {
    const data = grievanceData?.grievances?.length > 0 ? groupDataByDate(grievanceData?.grievances) : []
    setGrievanceFormattedData(data)
  }, [grievanceData, isLoadingGrievanceData])

  // // useEffect(() => {
  // //   const formattedData = grievanceData?.grievances?.length > 0 ? groupDataByDate(grievanceData?.grievances) : []
  // //   setGrievanceFormattedData(formattedData)
  // // }, [grievanceData, isLoadingGrievanceData ])

  // Set the first item as the default selected grievance

  useEffect(() => {
    if (Object.keys(grievanceFormattedData).length > 0) {
      const firstDateLabel = Object.keys(grievanceFormattedData)[0]
      const firstGrievance = grievanceFormattedData[firstDateLabel][0]
      setSelectedGrievance(firstGrievance)
    } else {
      setSelectedGrievance(null)
    }
  }, [grievanceFormattedData])

  useEffect(() => {
    setSelectedGrievance(null)
  }, [currentTab])

  return {
    tabs,
    currentTab,
    setCurrentTab,
    handleSearch: grievanceInformation?.handleSearch,
    resetParamsProxy,
    selectedCategory,
    setSelectedCategory,
    selectedSubCategory,
    setSelectedSubCategory,
    isLoadingGrievanceCategories,
    grievancesCategoriesOptions,
    isLoadingGrievanceSubCategories,
    subCategoryOptions,
    isLoadingGrievanceData,
    grievanceData,
    grievanceFormattedData,
    selectedGrievance,
    setSelectedGrievance,
  }
}
