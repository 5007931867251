import { Icon } from "@iconify/react"
import { Calendar } from "components/core"

interface Props {
  state: any
}

export const DateRange = ({ state }: Props) => {
  const { date, setDate } = state

  return (
    <div>
      <p className="mb-[8px] font-medium text-sm text-headers">
        <Icon icon="ph:pencil-line" className="inline w-[20px] h-[20px]" /> DATE RANGE
      </p>
      <div className="pl-[24px] w-full mb-[20px] rounded-sm">
        <table className="w-full">
          <thead>
            <tr className="text-left border text-xs text-body">
              <th className="p-[8px] font-medium border-r text-start w-[270px]">Created Date</th>
              <th className="p-[8px] font-medium border-r text-start">Start Date</th>
              <th className="p-[8px] font-medium text-start">End Date</th>
            </tr>
          </thead>
          <tbody>
            <tr className="border h-12 text-sm text-body">
              <td className="px-3 border-r">
                <Calendar
                  startDate={date.createdDate.startDate}
                  endDate={date.createdDate.endDate}
                  onChange={(e) => setDate({ ...date, createdDate: e })}
                  selectsRange={true}
                  variant="!border-0 !px-0 !justify-start"
                />
              </td>
              <td className="px-3 border-r">
                <Calendar
                  startDate={date.startDate.startDate}
                  endDate={date.startDate.endDate}
                  onChange={(e) => setDate({ ...date, startDate: e })}
                  selectsRange={true}
                  variant="!border-0 !px-0 !justify-start"
                />
              </td>
              <td className="px-3">
                <Calendar
                  startDate={date.endDate.startDate}
                  endDate={date.endDate.endDate}
                  onChange={(e) => setDate({ ...date, endDate: e })}
                  selectsRange={true}
                  variant="!border-0 !px-0 !justify-start"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}
