import { Icon } from "@iconify/react"
import { Button, Modal } from "components/core"
import { RenderIf } from "components/hoc"
import { usePermission } from "hooks/usePermission/usePermission"
import { CONFIG_TYPE, Configuration } from "types/configuration"
import { AllPermissions, PermissionModule } from "types/permission"
import { fullDateFormatter } from "utils/dateFormatter"

interface ConfigurationDetailsProps {
  onClose: () => void
  configuration: Configuration
  // eslint-disable-next-line no-unused-vars
  setSelectedAction: (e: any) => void
  // eslint-disable-next-line no-unused-vars
  onSubmit: (e: any) => void
  loading: boolean
}

export const ConfigurationDetails = ({
  onClose,
  setSelectedAction,
  configuration,
  onSubmit,
  loading,
}: ConfigurationDetailsProps) => {
  const { hasPermission } = usePermission()
  const { CONFIGURATION } = PermissionModule
  const { UPDATE_SINGLE_CONFIG } = AllPermissions
  const configType = (type: string) => {
    if (type === CONFIG_TYPE.psp) return "PSP"
    if (type === CONFIG_TYPE.source_register) return "Source Register"
    return "Verification Platform"
  }

  return (
    <Modal title="Configuration Details" position="modal-right" width="w-[432px]" closeModal={onClose}>
      <form className="relative">
        <div className="modal-right-body">
          <div className="flex justify-between items-center mb-[24px]">
            <div className="flex">
              <RenderIf condition={!!configuration.logo}>
                <img
                  src={configuration?.logo}
                  width={62}
                  height={62}
                  className="rounded-[11.63px] mr-[16px]"
                  alt={configuration?.configuration_name}
                />
              </RenderIf>
              <div>
                <h5 className="text-2xl text-headers font-semibold capitalize">{configuration?.configuration_name}</h5>
                <p className="text-sm text-grey-base">{configType(configuration?.configuration_type)}</p>
              </div>
            </div>
            <RenderIf condition={hasPermission(CONFIGURATION, UPDATE_SINGLE_CONFIG)}>
              <Button
                type="button"
                theme="transparent"
                className="ml-[8px]"
                size="40"
                onClick={() => setSelectedAction("edit")}
              >
                <Icon icon="ph:pencil-simple-line" className="w-5 h-5 mr-[8px]" />
                Edit
              </Button>
            </RenderIf>
          </div>
          <div className="mb-[28px]">
            <p className="text-sm text-body mb-[8px]">Description</p>
            <p className="text-base text-headers capitalize-first">{configuration?.description}</p>
          </div>
          <div className="grid grid-cols-2 font-normal">
            <div>
              <p className="text-sm text-body">Date created</p>
              <p className="text-base text-headers">{fullDateFormatter(configuration?.created_at)}</p>
            </div>
            <div>
              <p className="text-sm text-body">Connection Mode</p>
              <p className="text-base text-headers">{configuration?.mode_of_connection}</p>
            </div>
          </div>
        </div>
        <RenderIf condition={hasPermission(CONFIGURATION, UPDATE_SINGLE_CONFIG)}>
          <div className="modal-right-button-container">
            <Button
              theme="primary"
              size="44"
              type="button"
              className="min-w-[105px] mr-[8px] capitalize"
              loading={loading}
              onClick={() => onSubmit(configuration)}
              //disabled={button.disabled}
            >
              {["disconnected", "inactive"].includes(configuration?.status) ? "connect" : "disconnect"} Configuration
            </Button>
          </div>
        </RenderIf>
      </form>
    </Modal>
  )
}
