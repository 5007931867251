import { AdditionalInformation, Beneficiaries, DataAnalysis } from "components/pages/Interventions"
import { currencies } from "constants/currency"
import { usePermission } from "hooks"
import { useMemo, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { toast } from "react-toastify"
import { useGetInterventionQuery, useUpdateInterventionStatusMutation } from "services/api/interventionSlice"
import { PermissionModule } from "types/permission"
import { addCommasToNumbers } from "utils/addCommasToNumbers"
import { fullDateFormatter } from "utils/dateFormatter"
import { formatCurrency } from "utils/formatCurrency"
import { capitalizeFirstLetter } from "utils/textFormatter"
import { tryCatch } from "utils/tryCatch"

export default function useInterventionDetails(interventionId: string) {
  const { INTERVENTION } = PermissionModule
  const navigate = useNavigate()
  const { hasModulePermission, hasPermission } = usePermission()
  const [searchParams] = useSearchParams()
  const currentTab = searchParams.get("category")
  const [selectedAction, setSelectedAction] = useState("")
  const [reason, setReason] = useState("")
  const [updateInterventionStatus, { isLoading: isUpdatingStatus }] = useUpdateInterventionStatusMutation()
  const { data: intervention, isFetching: isFetchingIntervention } = useGetInterventionQuery(interventionId, {
    skip: !interventionId || !hasModulePermission(INTERVENTION),
  })

  const breadCrumbs = [{ title: "Intervention" }, { title: intervention?.intervention_title ?? "" }]
  const currency = currencies.find((currency) => currency.name === intervention?.currency)

  const overviewDetails: { name: string; value: any }[] = [
    { name: "Intervention ID", value: intervention?.intervention_id },
    {
      name: "Amount",
      value: formatCurrency(intervention?.amount, currency?.currency, currency?.symbol),
    },
    { name: "Start Date", value: fullDateFormatter(intervention?.start_date) },
    { name: "End Date", value: fullDateFormatter(intervention?.end_date) },
    {
      name: "Number of Beneficiary",
      value: addCommasToNumbers(Number(intervention?.total_number_of_beneficiary ?? 0)),
    },
    { name: "Payment Method", value: capitalizeFirstLetter(intervention?.payment_method ?? "") },
    { name: "Status", value: capitalizeFirstLetter(intervention?.status?.replace(/_/g, " ") ?? "") },
  ]

  const tabs = [
    {
      title: "Additional Information",
      component: intervention && <AdditionalInformation data={intervention} />,
      path: "?category=additional-information",
      active: "additional-information",
    },
    {
      title: "Beneficiaries",
      component: intervention && <Beneficiaries interventionId={intervention?.intervention_id ?? ""} />,
      path: "?category=beneficiaries",
      active: "beneficiaries",
    },
    {
      title: "Data Analysis",
      component: intervention && (
        <DataAnalysis
          interventionId={intervention?.intervention_id ?? ""}
          interventionCurrency={intervention?.currency}
        />
      ),
      path: "?category=data-analysis",
      active: "data-analysis",
    },
  ]

  const componentToBeDisplayed = useMemo(() => {
    return tabs.find((tab) => tab.active === currentTab)?.component
  }, [currentTab, intervention])

  const updateInterventionStatusProxy = (status: string) => {
    tryCatch(async () => {
      let res
      let interventionId = intervention?.intervention_id
      const callback = (res: any) => {
        toast.success(res?.message)
        setSelectedAction("")
      }

      if (selectedAction === "approve") {
        res = await updateInterventionStatus({ interventionId, data: { status } }).unwrap()
      } else {
        res = await updateInterventionStatus({ interventionId, data: { rejection_reason: reason } }).unwrap()
      }

      if (res && [200, 201].includes(res?.code)) callback(res)
    })
  }

  const editIntervention = () => {
    navigate(`/intervention/new-intervention?interventionId=${intervention?.intervention_id}`)
  }

  return {
    breadCrumbs,
    overviewDetails,
    tabs,
    currentTab,
    componentToBeDisplayed,
    intervention,
    selectedAction,
    setSelectedAction,
    reason,
    setReason,
    updateInterventionStatusProxy,
    isUpdatingStatus,
    isFetchingIntervention,
    editIntervention,
    hasPermission,
  }
}
