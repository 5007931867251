import { useState, useEffect, useMemo } from "react"
import {
  useFetchGrievanceCategoriesQuery,
  useFetchGrievanceSubCategoriesQuery,
  useEditGrievanceMutation,
  useFetchZonesQuery,
  useFetchStatesQuery,
  useFetchCommunitiesQuery,
} from "services/api/grievanceSlice"
import { toast } from "react-toastify"
import { tryCatch } from "utils/tryCatch"

const generateFormData = (data: any) => ({
  complaint_name: data?.complaint_name ?? "",
  complaint_type: data?.complaint_type ?? "",
  description: data?.description ?? "",
  gender: data?.gender ?? "",
  state_name: data?.state_name ?? "",
  level: data?.level ?? "",
  // assigned_to: data?.assigned_to ?? "",
  category_id: data?.category_id ?? "",
  sub_category_id: data?.sub_category_id ?? "",
  category_id_name: data?.category_id_name ?? "",
  sub_category_id_name: data?.sub_category_id_name ?? "",
  // assigned_to_name: data?.assigned_to_name ?? "",
  complaint_type_name: data?.complaint_type ?? "",
  complaint_mode: data?.complaint_mode ?? "",
  state_id: data?.state_id ?? "",
  zone_name: data?.zone_name ?? "",
  zone_id: data?.zone_id ?? "",
  lga_id: data?.lga_id ?? "",
  lga_name: data?.lga_name ?? "",
  ward: "",
  community_name: data?.community_name ?? null,
  community_id: data?.community_id ?? null,
  complaint_address: data?.complaint_address ?? "",
  complaint_phone_number: data?.complaint_phone_number ?? null,
})

export default function useEditGrievance({ grievance, onClose }: any) {
  const [originalFormData] = useState(() => generateFormData(grievance))
  const [formData, setFormData] = useState(() => generateFormData(grievance))
  const [subCategoriesOptions, setSubCategoriesOptions] = useState([])
  const [grievanceLGAOptions, setGrievanceLGAOptions] = useState([])
  const [phone, setPhone] = useState<{ phoneNumber: string; countryCode: string }>({
    phoneNumber: "",
    countryCode: "+234",
  })

  useEffect(() => {
    if (grievance?.complaint_phone_number) {
      setPhone({
        ...phone,
        phoneNumber: grievance?.complaint_phone_number,
      })
    }
  }, [grievance?.complaint_phone_number])

  const { data: grievanceZones, isFetching: isFetchingZones } = useFetchZonesQuery({})

  const { data: grievanceCategories, isLoading: isLoadingGrievanceCategories } = useFetchGrievanceCategoriesQuery({})
  const [editGrievance, { isLoading: isEditingGrievance }] = useEditGrievanceMutation({})

  const { data: grievanceSubCategories, isLoading: isLoadingGrievanceSubCategories } =
    useFetchGrievanceSubCategoriesQuery(formData?.category_id, {
      skip: formData?.category_id?.length === 0,
    })

  const { data: grievanceStates, isFetching: isFetchingStates } = useFetchStatesQuery(
    { id: formData?.zone_id },
    { skip: !formData?.zone_id },
  )

  useEffect(() => {
    if (originalFormData?.zone_id !== formData?.zone_id) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        state_id: "",
        state_name: "",
        lga_id: "",
        lga_name: "",
      }))
    }

    if (originalFormData?.state_id !== formData?.state_id) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        lga_id: "",
        lga_name: "",
      }))
    }
  }, [formData?.zone_id])

  const { data: grievanceCommunities, isFetching: isFetchingCommunities } = useFetchCommunitiesQuery({})

  const grievancesCategoriesOptions = useMemo(() => {
    return (
      grievanceCategories?.categories?.map((category: any) => {
        return {
          label: category?.name,
          value: category?.id,
        }
      }) || []
    )
  }, [grievanceCategories])

  const grievanceCommunityOption = useMemo(() => {
    return grievanceCommunities?.map((community: any) => {
      return {
        label: community?.community_name,
        value: community?.community_id,
      }
    })
  }, [grievanceCommunities])

  const grievanceZonesOptions = useMemo(() => {
    return grievanceZones?.map((zones: any) => {
      return {
        label: zones.name,
        value: zones.id,
      }
    })
  }, [grievanceZones])

  const grievanceStateOptions = useMemo(() => {
    return grievanceStates?.map((state: any) => {
      return {
        label: state.name,
        value: state.id,
      }
    })
  }, [grievanceStates])

  useEffect(() => {
    const state = grievanceStates?.find((state: any) => state?.id === formData?.state_id)
    setGrievanceLGAOptions(
      state?.lgas?.map((lga: any) => ({
        label: lga?.name,
        value: lga?.id,
      })) || [],
    )
  }, [formData?.state_id, grievanceStates])

  const handleEditGrievance = async () => {
    // eslint-disable-next-line no-unused-vars
    const {
      // eslint-disable-next-line no-unused-vars
      complaint_type_name, // eslint-disable-next-line no-unused-vars
      complaint_name, // eslint-disable-next-line no-unused-vars
      category_id_name, // eslint-disable-next-line no-unused-vars
      sub_category_id_name, // eslint-disable-next-line no-unused-vars
      ward, // eslint-disable-next-line no-unused-vars
      ...data
    } = formData

    data.gender = data.gender?.toLowerCase()
    data.level = data.level.toLowerCase()

    const callback = (res: any) => {
      if ([200, 201].includes(res?.code)) {
        toast.success(res.message)
        onClose()
      }
    }

    tryCatch(async () => {
      let res = await editGrievance({ id: grievance?.grievance_id, data }).unwrap()
      callback(res)
    })
  }

  const isFormChanged = useMemo(() => {
    return JSON.stringify(formData) !== JSON.stringify(originalFormData)
  }, [formData, originalFormData])

  useEffect(() => {
    if (phone.phoneNumber.length > 0) {
      setFormData({
        ...formData,
        complaint_phone_number: `${phone.countryCode}${phone.phoneNumber}`,
      })
    }
  }, [phone])

  const isFormValid = useMemo(() => {
    // eslint-disable-next-line no-unused-vars
    const {
      // eslint-disable-next-line no-unused-vars
      complaint_type_name, // eslint-disable-next-line no-unused-vars
      complaint_name, // eslint-disable-next-line no-unused-vars
      // assigned_to, // eslint-disable-next-line no-unused-vars
      // eslint-disable-next-line no-unused-vars
      category_id_name, // eslint-disable-next-line no-unused-vars
      sub_category_id_name, // eslint-disable-next-line no-unused-vars
      ward, // eslint-disable-next-line no-unused-vars
      community_id, // eslint-disable-next-line no-unused-vars
      community_name, // eslint-disable-next-line no-unused-vars
      complaint_phone_number, // eslint-disable-next-line no-unused-vars
      ...data
    } = formData

    return Object.values(data).every((value) => value !== "")
  }, [formData])

  useEffect(() => {
    const subCategoryOptions =
      grievanceSubCategories !== "undefined"
        ? grievanceSubCategories?.sub_categories?.map((subCategory: any) => {
            return {
              label: subCategory?.name,
              value: subCategory?.id,
            }
          })
        : []

    setSubCategoriesOptions(subCategoryOptions)
  }, [isLoadingGrievanceSubCategories, grievanceSubCategories, formData?.category_id])

  useEffect(() => {
    if (formData?.category_id !== originalFormData?.category_id) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        sub_category_id: "",
        sub_category_id_name: "",
      }))
    }
  }, [formData?.category_id, originalFormData?.category_id])

  return {
    formData,
    setFormData,
    grievancesCategoriesOptions,
    isLoadingGrievanceCategories,
    subCategoriesOptions,
    isEditingGrievance,
    handleEditGrievance,
    isFormChanged,
    isFormValid,
    isFetchingCommunities,
    grievanceLGAOptions,
    grievanceStateOptions,
    grievanceZonesOptions,
    grievanceCommunityOption,
    isFetchingStates,
    isFetchingZones,
    phone,
    setPhone,
  }
}
