import { RenderIf } from "components/hoc"
import { Input, SpinnerLoader } from "components/core"
import RadioButton from "components/core/RadioButton/RadioButton"
import EmptyUser from "assets/svg/empty-user.svg"

const SingleStaffUpload = ({ isFetchingStaff, staffData, staffId }: any) => {
  return (
    <main>
      <div>
        <RenderIf condition={isFetchingStaff}>
          <div className="flex items-center justify-center mt-10">
            <SpinnerLoader size={98} />
          </div>
        </RenderIf>

        <RenderIf condition={!isFetchingStaff && !staffData && !!staffId}>
          <div className="flex justify-center flex-col items-center mt-10">
            <img src={EmptyUser} alt="empty state" />
            <h1 className="text-body text-sm font-semibold">There is no staff with this id</h1>
          </div>
        </RenderIf>

        <RenderIf condition={!isFetchingStaff && staffData && staffId?.length !== 0}>
          <div className="flex flex-col gap-y-6 w-full">
            <Input
              label="Staff Name"
              id="name"
              name="name"
              value={`${staffData?.first_name} ${staffData?.last_name}`}
              type="text"
              disabled
            />
            <Input label="Staff Email" id="email" name="email" value={staffData?.email} type="text" disabled />
            <Input
              label="Phone Number"
              id="phone_number"
              name="phone_number"
              value={staffData?.phone_number}
              type="tel"
              disabled
            />
            <div className="flex flex-col gap-2">
              <h1 className="text-sm  text-headers">Staff Type</h1>
              <div className="flex gap-x-4">
                <RadioButton
                  name={"user_type"}
                  label={"NCTU"}
                  checked={staffData?.user_type === "NCTU"}
                  value={staffData?.user_type}
                  disabled
                  size="36"
                />
                <RadioButton
                  name={"user_type"}
                  label={"SCTU"}
                  checked={staffData?.user_type === "SCTU"}
                  value={staffData?.user_type}
                  disabled
                  size="36"
                />
                <RadioButton
                  name={"user_type"}
                  label={"LCTU"}
                  checked={staffData?.user_type === "LCTU"}
                  value={staffData?.user_type}
                  disabled
                  size="36"
                />
              </div>
            </div>
          </div>
        </RenderIf>
      </div>
    </main>
  )
}

export default SingleStaffUpload
