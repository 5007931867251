import "./spinner.css"

interface SpinnerProps {
  width?: string
  height?: string
}

export const Spinner = ({ width, height }: SpinnerProps) => {
  return (
    <div
      data-testid="spinner"
      className={`${height || "h-full"} ${width ?? "w-full"} flex justify-center items-center`}
    >
      <div className="spinner-icon"></div>
    </div>
  )
}
