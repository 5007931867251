export const createGrievanceSteps = [
  'Basic Information',
  // 'Assign GRM Officer',
  'Review'
];
export const grievanceStatus = [
  {
    label: "Pending",
    value: "pending"
  },
  {
    label: "Resolved",
    value: "resolved"
  },
];