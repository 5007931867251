/* eslint-disable no-unused-vars */
export enum CONFIGURATION_STATUS {
  DISCONNECTED = "inactive",
  CONNECTED = "active",
}

export enum CONFIG_TYPE {
  verification_platform = "verification_platform",
  psp = "psp",
  source_register = "source_register",
}

export interface Configuration {
  id: number
  configuration_id: string
  configuration_name: string
  description: string
  mode_of_connection: string
  handler: string
  configuration_type: string
  logo: string
  created_at: string
  updated_at: string
  status: string
  dataset: {
    household: { [key: string]: any }
    member: { [key: string]: any }
  }
}

export interface FetchedConfigurations {
  configurationSettings: Configuration[]
  count: number
  limit: string
  page: number
}
