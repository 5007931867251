import { Modal } from "components/core"
import { useState } from "react"
import { ReactComponent as SingleIcon } from "assets/icons/single.svg"
import { ReactComponent as MultiIcon } from "assets/icons/multi.svg"
import { MultipleMember, SingleMember } from "./components"

interface Props {
  onClose: () => void
  member?: any
}

const type = {
  SINGLE: "single",
  MULTIPLE: "multiple",
}

export const ManageMember = ({ onClose, member }: Props) => {
  const [currentTab, setCurrentTab] = useState("single")

  const tabs = [
    {
      name: "single",
      icon: <SingleIcon fill={currentTab === type.SINGLE ? "#1F1F1F" : "#5C5C5C"} />,
    },
    {
      name: "multiple",
      icon: <MultiIcon fill={currentTab === type.MULTIPLE ? "#1F1F1F" : "#5C5C5C"} />,
    },
  ]

  return (
    <Modal
      title={member ? "Edit Member" : "Add New Member"}
      position="modal-right"
      width="w-[432px]"
      closeModal={onClose}
    >
      <div className="border border-neutral-10 bg-neutral-3 rounded-lg p-1 grid grid-cols-2 h-14 mx-6 mt-6">
        {tabs.map((tab) => (
          <button
            type="button"
            disabled={!!member}
            className={`
              text-sm font-medium py-2 flex justify-center items-center
              ${currentTab === tab.name ? "text-grey-250 bg-white rounded shadow-sm" : "text-paragraph"}
            `}
            onClick={() => setCurrentTab(tab.name)}
            key={tab.name}
          >
            {tab.icon}
            <p className="ml-2 capitalize">{tab.name} member</p>
          </button>
        ))}
      </div>
      {currentTab === type.SINGLE ? (
        <SingleMember member={member} onClose={onClose} />
      ) : (
        <MultipleMember onClose={onClose} />
      )}
    </Modal>
  )
}
