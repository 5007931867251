import "./status.css"

interface StatusProps {
  status?: any
  className?: string
}

export const Status = ({ status, className }: StatusProps) => {
  return (
    <div className={`flex items-center  w-fit px-[8px] rounded-[18px] ${className || `cc-status--${status}`}`}>
      <p className={`text-xs px-[8px] py-[4px] rounded-3xl capitalize font-medium`}>{status?.replace(/[_-]/g, " ")}</p>
    </div>
  )
}
