import { useState } from "react"
import { useFetchTrainingBeneficiaryQuery, useCreateTrainingBeneficiaryMutation } from "services/api/trainingSlice"
import { tryCatch } from "utils/tryCatch"
import { toast } from "react-toastify"
import { shortDate } from "utils/dateFormatter"

export default function useAddCitizenBeneficiary() {
  const [currentTab, setCurrentTab] = useState("manual")
  const [houseHoldId, setHouseHoldId] = useState("")
  const [selectedCitizen, setSelectedCitizen] = useState([])
  const [filteredBeneficiary, setFilteredBeneficiary] = useState<any>(null)

  const { data: beneficiariesData, isFetching: isFetchingBeneficiaries } = useFetchTrainingBeneficiaryQuery(
    {
      id: houseHoldId,
      beneficiary_type: "citizen",
    },
    { skip: !(houseHoldId && houseHoldId.length === 9) },
  )

  const tabs = [
    {
      name: "Enter Manually",
      value: "manual",
      icon: "ph:user",
    },

    {
      name: "Bulk Upload",
      value: "bulk",
      icon: "ph:users-three",
    },
  ]

  const [addSingleCitizenBeneficiary, { isLoading: isCreatingSingleTraining }] = useCreateTrainingBeneficiaryMutation(
    {},
  )

  const handleCreateSingleTraining = async ({ id, onClose }: any) => {
    const payload = {
      id: id,
      beneficiary_type: "citizen",
      entry_mode: currentTab,
      beneficiary_data: {
        beneficiary_reference_id: filteredBeneficiary?.reference ?? "",
        beneficiary_name: filteredBeneficiary?.name ?? "",
        phone_number: filteredBeneficiary?.telephone_number ?? "",
        gender: String(filteredBeneficiary?.gender).toLowerCase() ?? "",
        lga: filteredBeneficiary?.lga ?? "",
        state: filteredBeneficiary?.state ?? "",
        dob: filteredBeneficiary?.date_of_birth ? shortDate(filteredBeneficiary?.date_of_birth) : "",
        age: filteredBeneficiary?.age ?? null,
      },
    }

    const callback = (res: any) => {
      if ([200, 201].includes(res?.code)) {
        toast.success(res.message)
        onClose()
      }
    }

    tryCatch(async () => {
      let res = await addSingleCitizenBeneficiary(payload).unwrap()
      callback(res)
    })
  }

  return {
    currentTab,
    setCurrentTab,
    tabs,
    beneficiariesData,
    isFetchingBeneficiaries,
    houseHoldId,
    setHouseHoldId,
    selectedCitizen,
    setSelectedCitizen,
    filteredBeneficiary,
    setFilteredBeneficiary,
    isCreatingSingleTraining,
    handleCreateSingleTraining,
  }
}
