import { Button, Input } from "components/core"
import { AuthForm } from "components/pages/Auth"
import useCreatePassword from "./useCreatePassword"

export default function CreatePassword() {
  const { formData, handleChange, onSubmit, isLoading, disabledButton } = useCreatePassword()

  return (
    <AuthForm title="Create new password" caption="Enter a new password for your account" onSubmit={onSubmit}>
      <div>
        <Input
          label="Password"
          type="password"
          id="password"
          name="password"
          readOnly={isLoading}
          value={formData?.password}
          onChange={handleChange}
          className="mb-[24px]"
        />
        <Button theme="primary" size="44" className="w-full mb-[32px]" loading={isLoading} disabled={disabledButton}>
          Proceed to Sign In
        </Button>
      </div>
    </AuthForm>
  )
}
