import { useTable } from "hooks"
import { useMemo } from "react"
import { useGetGRMSummaryQuery, useGetReportsQuery } from "services/api/reportSlice"
import { addCommasToNumbers } from "utils/addCommasToNumbers"
import { removeSpecificKeys } from "utils/removeSpecificKeys"
import { calculatePercentage, getReportCustomDateRange, getReportYear } from "utils/report"
import useReportSummary from "hooks/useReportSummary/useReportSummary"
import { Period } from "types/common"

export default function useGRMSummary() {
  const {
    actions,
    deleteReport,
    isDeletingReport,
    selectedReportId,
    setSelectedReportId,
    date,
    setDate,
    periodFilter,
    setPeriodFilter,
    navigate,
  } = useReportSummary()

  const { changePage, selectLimit, params } = useTable()
  const {
    data: reports,
    isLoading: isLoadingHistory,
    isFetching: isFetchingHistory,
  } = useGetReportsQuery({
    type: "GRM",
    ...removeSpecificKeys({ ...params }, ["sortBy"]),
  })
  const { data: summary, isLoading: isLoadingSummary } = useGetGRMSummaryQuery({
    month: periodFilter.type === Period.MONTHLY ? periodFilter?.selected : "",
    year: getReportYear(periodFilter),
    ...getReportCustomDateRange(date, periodFilter),
  })

  const overview = [
    {
      title: "Total Count of Grievance",
      count: addCommasToNumbers(summary?.data?.total_grievances),
      icon: "ph:users-three",
      stat: summary?.data?.total_grievances_percentage_increase,
    },
    {
      title: "Total National Grievance",
      count: addCommasToNumbers(summary?.data?.total_national_grievances),
      icon: "ph:handshake",
      stat: summary?.data?.total_national_grievances_percentage_increase,
    },
    {
      title: "Total State Grievance",
      count: addCommasToNumbers(summary?.data?.total_state_grievances),
      icon: "ph:handshake",
      stat: summary?.data?.total_state_grievances_percentage_increase,
    },
    {
      title: "Total Local Govt Grievance",
      count: addCommasToNumbers(summary?.data?.total_local_grievances),
      icon: "ph:users-three",
      stat: summary?.data?.total_local_grievances_percentage_increase,
    },
    {
      title: "Total escalated grievance",
      count: addCommasToNumbers(summary?.data?.total_escalated_grievances),
      icon: "ph:handshake",
      stat: summary?.data?.total_escalated_grievances_percentage_increase,
    },
  ]

  const chartData = useMemo(() => {
    const data = [summary?.data?.totalResolvedGrievances, summary?.data?.totalPendingGrievances]
    const percentage = calculatePercentage(data)

    const filteredData = percentage.reduce<{ labels: string[]; data: number[]; backgroundColor: string[] }>(
      (acc, value, index) => {
        if (Number(data[index]) !== 0) {
          acc.labels.push(["Resolved Grievance", "Pending Grievance"][index])
          acc.data.push(value)
          acc.backgroundColor.push(["#C6E4C4", "#2B892B"][index])
        }
        return acc
      },
      { labels: [], data: [], backgroundColor: [] },
    )
    return {
      labels: filteredData.labels,
      datasets: [
        {
          data: filteredData.data,
          backgroundColor: filteredData.backgroundColor,
          borderWidth: 6,
        },
      ],
    }
  }, [summary?.data])

  const topCategoriesCount = summary?.data?.topCategories?.map((item: any) => item.grievance_count)
  const topCategoriesName = summary?.data?.topCategories?.map((item: any) => item.category_name)
  const percentage = useMemo(() => calculatePercentage(topCategoriesCount ?? []), [topCategoriesCount])

  const categoryData = useMemo(() => {
    return {
      labels: topCategoriesName,
      datasets: [
        {
          data: [...percentage],
          backgroundColor: ["#E7F4E7", "#C6E4C4", "#7AC379", "#2B892B", "#024F0B"],
          barThickness: 100,
          borderRadius: 8,
        },
      ],
    }
  }, [summary?.data?.topCategories])

  return {
    navigate,
    summary: summary?.data,
    overview,
    periodFilter,
    date,
    isLoadingHistory,
    isFetchingHistory,
    setDate,
    isLoadingSummary,
    selectedReportId,
    setSelectedReportId,
    setPeriodFilter,
    chartData,
    deleteReport,
    isDeletingReport,
    reports: reports?.data,
    selectLimit,
    changePage,
    actions,
    categoryData,
  }
}
