import { Avatar, Button, Modal } from "components/core"
import { Combobox, Transition } from "@headlessui/react"
import { Icon } from "@iconify/react"
import { Fragment } from "react"
import useReassignRole from "./useReassignRole"
import { Staff } from "types/staff"

export interface ReassignRoleProps {
  onClose: () => void
  initialStaff: Staff[] | undefined
  roleId: string | undefined
}

export const ReassignRole = ({ initialStaff, onClose, roleId }: ReassignRoleProps) => {
  const { submit, roles, staff, setStaff, disabled, loading } = useReassignRole({ initialStaff, roleId })

  return (
    <Modal title="Re-assign Roles" position="modal-right" width="w-[631px]" closeModal={onClose}>
      <form className="relative" onSubmit={submit}>
        <div className="h-[85vh] overflow-y-auto">
          <table className="table-auto w-full text-left">
            <thead>
              <tr>
                <th className="py-4 px-6 border-b border-b-neutral-10 border-r border-r-neutral-10">Staff Name</th>
                <th className="py-4 px-6 border-b border-b-neutral-10">Role Assigned</th>
              </tr>
            </thead>
            <tbody>
              {staff?.map((item, index) => (
                <tr key={item?.staff_id}>
                  <td className="py-2 px-6 border-b border-b-neutral-10 border-r border-r-neutral-10">
                    <div className="flex items-center gap-2">
                      <Avatar image={item?.image ?? `${item?.first_name} ${item?.last_name}`} size="32" />
                      <div className="grid">
                        <h5 className="font-medium text-body text-sm">{`${item?.first_name} ${item?.last_name}`}</h5>
                        <p className="font-normal text-xs text-grey-base">{item?.staff_number}</p>
                      </div>
                    </div>
                  </td>
                  <td className="border-b border-b-neutral-10">
                    <Combobox
                      onChange={(role: { title: string }) => {
                        const updatedStaff = [...staff]
                        updatedStaff[index] = { ...updatedStaff[index], role: role?.title }
                        setStaff(updatedStaff)
                      }}
                    >
                      <div className="relative">
                        <div className="relative w-full cursor-default bg-white text-left focus:outline-none">
                          <input
                            type="text"
                            id="roleName"
                            name="roleName"
                            disabled={true}
                            placeholder="Select a new role"
                            //readOnly={loading}
                            defaultValue={item?.role}
                            //onChange={handleChange}
                            className={`bg-transparent px-[24px] ${item?.role ? "capitalize" : ""}`}
                          />
                          <Combobox.Button
                            data-testid={item?.staff_id}
                            className="absolute inset-y-0 right-0 flex items-center pr-6"
                          >
                            <Icon icon="ph:caret-down" className="h-6 w-6 text-neutral-base" aria-hidden="true" />
                          </Combobox.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                          // afterLeave={() => setQuery("")}
                        >
                          <Combobox.Options
                            className="absolute z-[101] my-1 max-h-60 w-full overflow-auto rounded-md bg-white
                                                        py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm"
                          >
                            {roles?.length === 0 ? (
                              <div className="relative cursor-default select-none px-4 py-2 text-neutral-70">
                                Nothing found.
                              </div>
                            ) : (
                              roles?.map((role) => (
                                <Combobox.Option
                                  key={role?.id}
                                  className={({ active }) =>
                                    `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                                      active ? "bg-primary-actions text-white" : "text-headers"
                                    }`
                                  }
                                  value={{ id: role?.role_id, title: role?.role_name }}
                                >
                                  {({ selected, active }) => (
                                    <>
                                      <span
                                        className={`block truncate capitalize ${
                                          selected ? "font-medium" : "font-normal"
                                        }`}
                                      >
                                        {role?.role_name}
                                      </span>
                                      {selected ? (
                                        <span
                                          className={`
                                            absolute inset-y-0 left-0 flex items-center pl-3
                                            ${active ? "text-white" : "text-primary-base"}
                                            `}
                                        >
                                          <Icon icon="ph:check" className="h-5 w-5" aria-hidden="true" />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Combobox.Option>
                              ))
                            )}
                          </Combobox.Options>
                        </Transition>
                      </div>
                    </Combobox>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="modal-right-button-container">
          <Button theme="primary" size="44" className="mr-[8px] w-[102px]" loading={loading} disabled={disabled}>
            Confirm
          </Button>
          <Button theme="transparent" size="44" type="button" onClick={onClose} disabled={loading}>
            Discard
          </Button>
        </div>
      </form>
    </Modal>
  )
}
