import { useNavigate } from "react-router-dom"
import { Icon } from "@iconify/react"
import { Breadcrumb } from "components/core"
import React from "react"

interface Option {
  title: string
  method?: () => void
}

interface BreadcrumbProps {
  options: Option[]
}
export const ViewDataResourceBreadCrumbs: React.FC<BreadcrumbProps> = ({ options }) => {
  const navigate = useNavigate()
  return (
    <div className="flex items-center">
      <button
        data-testid="navigate"
        type="button"
        className="
      grid place-items-center w-[30px] h-[30px] rounded-full border border-neutral-10
      hover:bg-neutral-5 transition-colors duration-300 ease-out mr-[8px]
      "
        onClick={() => navigate("/data-mining")}
      >
        <Icon icon="ph:arrow-left" className="text-neutral-base" />
      </button>
      <Breadcrumb options={options} />
    </div>
  )
}
