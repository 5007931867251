import { useMemo } from "react"
import { useGetConfigurationsQuery } from "services/api/configurationSlice"

export default function useDataSources() {
  const { data: configurations, isFetching: isFetchingSources } = useGetConfigurationsQuery({
    configurationType: "source_register",
    status: "connected",
  })

  const dataSources = useMemo(() => {
    return (
      configurations?.configurationSettings?.map((item: any) => {
        return {
          label: item?.configuration_name.replace(/_/g, " "),
          value: item?.configuration_name,
          id: item.configuration_id,
        }
      }) ?? []
    )
  }, [configurations])

  return { dataSources, isFetchingSources }
}
