import { Button, Modal, Search, Avatar, TableLoader } from "components/core"
import useAssignOfficer from "./useAssignOfficer"
import { RenderIf } from "components/hoc"
import RadioButton from "components/core/RadioButton/RadioButton"

const AssignGrievanceOfficer = ({ onClose, grievance, selectedAction }: any) => {
  const {
    tabs,
    currentTab,
    setCurrentTab,
    handleSearch,
    isLoadingOfficers,
    grmOfficers,
    staffId,
    setStaffId,
    isLoadingAssignGrievance,
    isLoadingReassignGrievance,
    handleSubmit,
  } = useAssignOfficer({ onClose, grievance, selectedAction })

  return (
    <main>
      <Modal title="Assign GRM Officer" position="modal-right" width="w-[432px]" closeModal={onClose}>
        <div className="modal-right-body no-scroll">
          <div className="w-full border-b-[1.5px] border-b-grey-200">
            {tabs.map((tab) => (
              <button
                key={tab.title}
                data-testid={`${tab.path}-id`}
                disabled={currentTab === tab.path}
                className={`
                  text-sm font-normal mr-[24px] pt-[8px] pb-[16px] px-[16px]
                  ${currentTab === tab.path ? "selected-tab" : "unselected-tab"}
                  `}
                onClick={() => setCurrentTab(tab.path)}
              >
                {tab.title}
              </button>
            ))}
          </div>
          <div className="">
            <div className="mt-6 mb-3">
              <Search onChange={handleSearch} id="Search" name="search" placeholder="Search by name, grievance id" />
            </div>
            <RenderIf condition={isLoadingOfficers}>
              <TableLoader />
            </RenderIf>
            <RenderIf condition={!isLoadingOfficers && !grmOfficers?.officers?.length}>
              <h1>There are no officials at this time</h1>
            </RenderIf>
            <RenderIf condition={!isLoadingOfficers && grmOfficers?.officers?.length > 0}>
              <div className="flex gap-y-4 flex-col" data-testid="grm-officers">
                {grmOfficers?.officers?.map((officer: any) => (
                  <div key={officer?.id} className="border-b last:border-b-0 py-3 flex justify-between">
                    <div className="flex items-center gap-x-2">
                      <Avatar
                        key={officer?.staff_id}
                        image={`${officer?.first_name} ${officer?.last_name}`}
                        alt={officer?.name}
                        size="32"
                      />
                      <div className="flex flex-col gap-y-1">
                        <p className="capitalize text-body">{`${officer?.first_name} ${officer?.last_name}`}</p>
                        <p className="text-grey-base font-medium text-sm">{officer?.email}</p>
                      </div>
                    </div>
                    <RadioButton
                      key={officer?.staff_id}
                      name={officer?.staff_id}
                      checked={officer?.staff_id === staffId}
                      value={staffId}
                      onChange={() => setStaffId(officer?.staff_id)}
                      size="44"
                    />
                  </div>
                ))}
              </div>
            </RenderIf>
          </div>
          <div className="modal-right-button-container mb-3">
            <Button
              theme="primary"
              className="min-w-[105px] mr-[8px] p-[8px]"
              onClick={handleSubmit}
              loading={isLoadingAssignGrievance || isLoadingReassignGrievance}
              disabled={staffId === grievance.assigned_to || staffId?.length === 0}
            >
              Assign Officer
            </Button>
            <Button theme="transparent" className="w-[105px] px-[30px]" type="button" onClick={onClose}>
              Discard
            </Button>
          </div>
        </div>
      </Modal>
    </main>
  )
}

export default AssignGrievanceOfficer
