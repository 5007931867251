import React from "react"
import { Icon } from "@iconify/react"
import { Link } from "react-router-dom"
import { RenderIf } from "components/hoc"

interface DataResourceCardProps {
  logo: string
  title?: string
  subTitle?: string
  date: string
  count?: string | number
  id?: string | number
  onClick?: () => void
}

export const DataResourceCard: React.FC<DataResourceCardProps> = ({
  logo,
  title,
  subTitle,
  date,
  count,
  id,
  onClick,
}) => {
  return (
    <Link
      onClick={onClick}
      to={`${id}?
      category=complete-data`}
      data-testid="link-component"
      className="cursor-pointer p-4 border border-neutral-10 
  hover:border-neutral-30 rounded-xl group transition-all ease-out duration-700"
    >
      <div
        className="pb-3 border-b border-b-neutral-10
        group-hover:border-b-neutral-30 "
      >
        <div className="flex items-center justify-between">
          <div className="w-14 h-14">
            <RenderIf condition={!!logo}>
              <img className="w-full h-full object-cover" src={logo} alt="" />
            </RenderIf>
          </div>
          <Icon
            icon="ph:arrow-right"
            className="move-icon -z-[1] text-neutral-base w-6 h-6 opacity-0 group-hover:opacity-100 transition-all ease-out duration-500"
          />
        </div>

        <h3 className="text-headers font-semibold text-base capitalize mt-6">{title}</h3>
        <p className="text-sm text-neutral-base line-clamp-2 text-ellipsis overflow-hidden capitalize mt-1">
          {subTitle}
        </p>
      </div>
      <div>
        <div className="mt-[14px] flex items-center justify-between">
          <div className="flex items-center gap-1">
            <Icon icon="ph-calendar-blank" className="text-neutral-base w-4 h-4" />
            <span className="text-sm text-neutral-base ">{date}</span>
          </div>
          <div className="text-body text-[12px] font-medium px-2 py-1 border border-neutral-10 w-fit rounded-[24px]">
            {count} Data Mined
          </div>
        </div>
      </div>
    </Link>
  )
}
