import { Button, Input, Textarea } from "components/core"
import { Calendar } from "components/core/DatePicker/DatePicker"
import { Dropdown } from "components/core/Dropdown/Dropdown"
import dayjs from "dayjs"
import { defaultFilter } from "mocks/staffs"
import useBasicInformation from "./useBasicInformation"

interface Props {
  formData: any
  // eslint-disable-next-line no-unused-vars
  handleChange: (e: any) => void
  setFormData: any
  proceed: () => void
}

const BasicInformation = ({ formData, setFormData, handleChange, proceed }: Props) => {
  const {
    isFetchingStaffs,
    isFetchingStates,
    isFetchingLga,
    dataSources,
    isFetchingSources,
    reviewers,
    navigate,
    nextPage,
    disableBtn,
    isFetchingZones,
    geopoliticalZones,
    location,
    isFetchingVersions,
    versions,
    selectedVersionName,
  } = useBasicInformation({ formData, setFormData, proceed })

  return (
    <form className="mt-8" onSubmit={nextPage}>
      <Input
        label="Intervention Title"
        type="text"
        id="intervention_title"
        name="intervention_title"
        value={formData.intervention_title}
        onChange={handleChange}
        className="mb-[16px]"
      />
      <Textarea
        label="Description"
        id="description"
        name="description"
        rows={4}
        value={formData?.description}
        onChange={handleChange}
        maxLength={340}
      />
      <Dropdown
        label="Intervention Reviewer"
        selected={formData.reviewer_name}
        enableSearchOptions={true}
        loading={isFetchingStaffs}
        options={reviewers}
        onSelect={(data: any) => {
          setFormData({ ...formData, reviewer_name: data.value, intervention_reviewer: data.id })
        }}
      />
      <div className="grid grid-cols-2 gap-4">
        <Calendar
          value={formData.start_date}
          placeholder="Start Date"
          label="Start Date"
          minDate={new Date()}
          variant="h-[48px]"
          id="startDate"
          onChange={(e) => setFormData({ ...formData, start_date: dayjs(e).format("YYYY-MM-DD") })}
        />
        <Calendar
          value={formData.end_date}
          placeholder="End Date"
          label="End Date"
          minDate={formData.start_date ? formData.start_date : new Date()}
          id="endDate"
          variant="h-[48px]"
          onChange={(e) => {
            setFormData({ ...formData, end_date: dayjs(e).format("YYYY-MM-DD") })
          }}
        />
      </div>
      <div className="grid grid-cols-2 gap-4 mt-4">
        <Input
          label="Total Number of Recipient/Beneficiary"
          type="number"
          id="total_number_of_beneficiary"
          name="total_number_of_beneficiary"
          value={formData.total_number_of_beneficiary}
          onChange={handleChange}
          allowDecimals={false}
          className="mb-[16px]"
        />
        <Input
          label="Number of Eligible Household Member"
          type="number"
          id="number_of_eligible_household_member"
          name="number_of_eligible_household_member"
          value={formData.number_of_eligible_household_member}
          onChange={handleChange}
          allowDecimals={false}
          className="mb-[16px]"
        />
      </div>
      <Dropdown
        label="Zones"
        defaultOption={formData.zones}
        multiple={true}
        showButton={true}
        loading={isFetchingZones}
        options={[...defaultFilter, ...geopoliticalZones]}
        onSelect={(data: any) => {
          setFormData((prevFormData: any) => ({ ...prevFormData, zones: data }))
        }}
      />
      <Dropdown
        label="States"
        defaultOption={formData.states}
        enableSearchOptions={true}
        showButton={true}
        clearSelected={formData.zones.length === 0}
        loading={isFetchingStates}
        multiple={true}
        options={location.states}
        onSelect={(data: any) => {
          setFormData((prevFormData: any) => ({
            ...prevFormData,
            states: data,
          }))
        }}
      />
      <Dropdown
        label="Local Government Area"
        enableSearchOptions={true}
        defaultOption={formData.lga}
        clearSelected={formData.states.length === 0}
        showButton={true}
        multiple={true}
        loading={isFetchingLga}
        options={location.lga}
        onSelect={(data: any) => {
          setFormData((prevFormData: any) => ({ ...prevFormData, lga: data }))
        }}
      />
      <Dropdown
        label="Data Source"
        optional={true}
        selected={formData.data_source}
        loading={isFetchingSources}
        options={dataSources}
        onSelect={(data: any) => {
          setFormData((prevFormData: any) => ({
            ...prevFormData,
            data_source: data.value,
            data_source_id: data.id,
            version: "",
          }))
        }}
      />
      {formData.data_source && (
        <Dropdown
          label="Version"
          selected={selectedVersionName}
          enableSearchOptions={true}
          loading={isFetchingVersions}
          options={versions}
          onSelect={(data: any) => {
            setFormData({ ...formData, version: data?.version, mine_history_id: data?.id })
          }}
        />
      )}
      <div className="flex justify-start items-center mt-12 space-x-4">
        <Button theme="primary" size="44" className="min-w-[171px]" disabled={disableBtn}>
          Proceed
        </Button>
        <Button
          theme="transparent"
          className="w-[171px] px-[30px]"
          type="button"
          onClick={() => navigate(-1)}
          size="44"
        >
          Discard
        </Button>
      </div>
    </form>
  )
}

export default BasicInformation
