import { Table, ChartWrapper } from "components/core"
import useDataAnalysis from "./useDataAnalysis"
import { dataAnalysisHeader } from "constants/intervention"
import { ReactComponent as Ecllipse } from "assets/icons/ecllipse.svg"
import { formatCurrency } from "utils/formatCurrency"
import { currencies } from "constants/currency"

interface Props {
  interventionId: string
  interventionCurrency: string
}

export const DataAnalysis = ({ interventionId, interventionCurrency }: Props) => {
  const {
    totalBeneficiaryExpectedAnalysis,
    totalBeneficiaryExpectedCount,
    totalInterventionFunds,
    analysis,
    isFetchingAnalysis,
    // isFetchingDisbursementAnalysis,
    disbursementAnalysis,
    beneficiariesLeft,
    beneficiariesPaid,
  } = useDataAnalysis(interventionId)

  const currency = currencies.find((currency) => currency.name === interventionCurrency)

  return (
    <div>
      <div className="flex bg-grey-50 border border-grey-200 rounded-[8px] mb-[32px]">
        <div className="flex flex-col justify-between p-[24px] border-r border-r-grey-200 w-[70%]">
          <div>
            <div className="mb-[16px]">
              <p className="text-sm text-headings font-medium mb-[8px]">Total Intervention Fund</p>
              <p className="text-xl text-headers font-semibold">
                {formatCurrency(
                  disbursementAnalysis?.total_intervention_fund ?? 0,
                  currency?.currency,
                  currency?.symbol,
                )}
              </p>
            </div>
            <ChartWrapper
              type="bar"
              options={totalInterventionFunds.options}
              data={totalInterventionFunds?.data}
              containerClass="h-[100px]"
            />
          </div>
          <div className="flex">
            <p className="text-sm text-paragraph mr-[24px]">
              <Ecllipse fill="#2B892B" className="inline mr-[8px]" />
              Amount Disbursed
            </p>
            <p className="text-sm text-paragraph">
              <Ecllipse fill="#E6BB20" className="inline mr-[8px]" />
              Amount Left
            </p>
          </div>
        </div>
        <div className="p-[24px] w-[30%]">
          <div className="mb-[28px]">
            <p className="text-sm text-headings font-medium mb-[8px]">Total Beneficiary Expected</p>
            <p className="text-xl text-headers font-semibold">{totalBeneficiaryExpectedCount}</p>
          </div>
          <div className="flex items-center">
            <ChartWrapper
              type="pie"
              options={totalBeneficiaryExpectedAnalysis.options}
              data={totalBeneficiaryExpectedAnalysis?.data}
              containerClass="w-[150px] h-[140px]"
            />
            <div className="ml-[24px]">
              <div className="flex item-center">
                <Ecllipse fill="#2B892B" />
                <p className="text-sm text-paragraph ml-[8px] mt-[-4px]">Beneficiaries Paid</p>
              </div>
              <p className="text-sm text-paragraph mr-[24px] mb-[16px]">{beneficiariesPaid}</p>
              <div>
                <div className="flex item-center">
                  <Ecllipse fill="#E6BB20" />
                  <p className="text-sm text-paragraph ml-[8px] mt-[-4px]">Beneficiary Left</p>
                </div>
                <p className="text-sm text-paragraph">{beneficiariesLeft}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className="text-base text-headers font-semibold">Intervention breakdown</p>
      <Table
        headers={dataAnalysisHeader}
        paginateData={!!analysis?.analysis}
        data={analysis?.analysis || []}
        // onPageChange={changePage}
        // totalCount={staff?.count}
        // perPage={staff?.limit}
        page={analysis?.currentPage}
        loading={isFetchingAnalysis}
      >
        <>
          {analysis?.analysis?.map((item: any) => (
            <tr data-testid={item?.id} key={item?.id} className="cc-custom-table-data-row">
              <td className="capitalize-first">{item?.state || "-"}</td>
              <td>{item?.no_of_expected_beneficiaries || "-"}</td>
              <td>{item?.no_of_received_beneficiaries || "-"}</td>
              <td>{item?.amount_disbursed || "-"}</td>
              <td>{item?.data_received || "-"}</td>
              <td className="uppercase">{item?.source_register || "-"}</td>
            </tr>
          ))}
        </>
      </Table>
    </div>
  )
}
