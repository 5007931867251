export const paymentHistoryStatus = (item: any) => {
  switch (item) {
    case "PENDING_CREDIT":
      return "pending"
    case "completed":
      return "paid"
    default:
      return "Not Available"
  }
}
