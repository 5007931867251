import { createApi } from "@reduxjs/toolkit/query/react"
import { axiosBaseQuery } from "../axiosBaseQuery"
import { FetchedStaff, InviteStaffType, InviteBulkStaffType } from "types/staff"
import {
  GET_ALL_STAFF,
  SINGLE_INVITE_STAFF,
  EDIT_SINGLE_STAFF,
  DEACTIVATE_STAFF,
  INVITE_BULK_STAFFS,
  ACTIVATE_STAFF,
} from "constants/api"

const baseUrl = process.env.REACT_APP_BASE_URL ?? ""

export const staffSlice = createApi({
  reducerPath: "staffSlice",
  baseQuery: axiosBaseQuery({ baseUrl }),
  tagTypes: ["Staffs", "Staff", "StaffByRole", "Data", "Source", "SingleDataSource"],
  refetchOnMountOrArgChange: true,
  endpoints(build) {
    return {
      getAllStaff: build.query<FetchedStaff, any>({
        query: (params) => ({ url: `${GET_ALL_STAFF}`, method: "get", params }),
        transformResponse: (response: any) => response.data,
        providesTags: ["Staffs"],
      }),
      inviteSingleStaff: build.mutation<InviteStaffType, any>({
        query: (data) => ({ url: `${SINGLE_INVITE_STAFF}`, method: "post", data }),
        invalidatesTags: ["Staffs", "Staff"],
      }),
      inviteBulkStaffs: build.mutation<InviteBulkStaffType, any>({
        query: (data) => ({ url: `${INVITE_BULK_STAFFS}`, method: "post", data }),
        invalidatesTags: ["Staffs"],
      }),
      updateStaffDetails: build.mutation<InviteStaffType, { staffId: string; data: any }>({
        query: ({ staffId, data }) => ({ url: `${EDIT_SINGLE_STAFF}${staffId}`, method: "patch", data }),
        invalidatesTags: ["Staffs", "Staff"],
      }),
      deactivateStaff: build.mutation<InviteStaffType, string>({
        query: (staffId) => ({ url: `${DEACTIVATE_STAFF}${staffId}`, method: "put" }),
        invalidatesTags: ["Staffs", "Staff"],
      }),
      activateStaff: build.mutation<InviteStaffType, { staffId: string; data: any }>({
        query: ({ staffId, data }) => ({ url: `${ACTIVATE_STAFF}${staffId}`, method: "patch", data }),
        invalidatesTags: ["Staffs", "Staff"],
      }),
      getStaffByRole: build.query<FetchedStaff, any>({
        query: (params) => ({ url: `/staff`, method: "get", params }),
        transformResponse: (response: any) => response.data,
        providesTags: ["StaffByRole"],
      }),
      exportStaffByRole: build.query<any, any>({
        query: ({ roleName, params }) => ({ url: `/staff?role=${roleName}&noLimit=true`, method: "get", params }),
        transformResponse: (response: any) => response.data,
      }),
      getPreSignedUrl: build.mutation<any, any>({
        query: (data) => ({ url: "/staff/pre-signed-url", method: "post", data }),
      }),
    }
  },
})

export const {
  useGetAllStaffQuery,
  useInviteSingleStaffMutation,
  useInviteBulkStaffsMutation,
  useUpdateStaffDetailsMutation,
  useDeactivateStaffMutation,
  useActivateStaffMutation,
  useGetStaffByRoleQuery,
  useLazyExportStaffByRoleQuery,
  useLazyGetAllStaffQuery,
  useGetPreSignedUrlMutation,
} = staffSlice
