import { Avatar, Button, Modal, Status } from "components/core"
import React from "react"
import { formatCurrency } from "utils/formatCurrency"

interface viewSingleGroupProps {
  closeModal: () => void
  details: any
  editMember: () => void
}

const SingleGroupMember = ({ closeModal, details, editMember }: viewSingleGroupProps) => {
  return (
    <Modal title="Member Details" position="modal-right" width="w-[432px]" closeModal={closeModal}>
      <div className="px-5 py-6">
        <div className="flex items-center">
          <Avatar
            key={1}
            image={`${details?.first_name} ${details?.last_name}`}
            alt={`${details?.first_name} ${details?.last_name}`}
            size="89"
          />
          <div className="ml-4">
            <p className="font-semibold text-2xl text-headers mb-0.5">
              {details?.first_name} {details?.last_name}
            </p>
            <p className="mt-2 rounded-full text-xs text-[#454545] bg-grey-100 px-2 py-1 w-fit">
              {details?.state ?? "N/A"} {details?.state ? "State" : ""}
            </p>
            <p className="text-[#A1A1A1] text-xs mt-2">{details?.member_id}</p>
          </div>
        </div>
        <div className="mt-10">
          <div className="grid grid-cols-2 text-xs text-body mt-3 justify-items-start">
            <div className="">
              <p className="text-xs mb-0.5 text-[#A1A1A1]">Gender</p>
              <p className="capitalize mt-2">{details?.gender}</p>
            </div>
            <div className="pl-3 items-start">
              <p className="text-xs mb-0.5 text-[#A1A1A1]">LGA</p>
              <p className="mt-2">{details?.lga ?? "N/A"}</p>
            </div>
          </div>
          <div className="grid grid-cols-2 text-xs text-body mt-6 justify-items-start">
            <div className="">
              <p className="text-xs mb-0.5 text-[#A1A1A1]">NIN</p>
              <p className="mt-2">{details?.nin ?? "N/A"}</p>
            </div>
            <div className="pl-3">
              <p className="text-xs mb-0.5 text-[#A1A1A1]">BVN</p>
              <p className="mt-2">{details?.bvn ?? "N/A"}</p>
            </div>
          </div>
          <div className="grid grid-cols-2 text-xs text-body mt-6 justify-items-start">
            <div className="">
              <p className="text-xs mb-0.5 text-[#A1A1A1]">Amount Saved</p>
              <p className="mt-2">{formatCurrency(details?.amount_saved ?? 0)}</p>
            </div>
            <div className="pl-3">
              <p className="text-xs mb-0.5 text-[#A1A1A1]">Savings per Cycle</p>
              <p className="mt-2">{formatCurrency(details?.saving_per_cycle ?? 0)}</p>
            </div>
          </div>
          <div className="grid grid-cols-2 text-xs text-body mt-6 justify-items-start">
            <div className="">
              <p className="text-xs mb-0.5 text-[#A1A1A1]">Loan Collected</p>
              <p className="mt-2">{formatCurrency(details?.loan_collected ?? 0)}</p>
            </div>
            <div className="pl-3">
              <p className="text-xs mb-0.5 text-[#A1A1A1]">Repayment Status</p>
              <p className="mt-1">
                <Status status={details?.repayment_status ?? "N/A"} />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 left-0 w-full">
        <div className="flex items-center py-[12px] px-[32px]">
          <Button theme="primary" size="44" className="min-w-[105px] mr-[8px] capitalize" onClick={editMember}>
            Edit Member
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default SingleGroupMember
