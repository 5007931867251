import React, { useMemo } from "react"
import { Icon } from "@iconify/react"
import { NavLink, useLocation } from "react-router-dom"
import "./navLink.css"
import { RenderIf } from "components/hoc"

interface NavigationLinkProps {
  to: string // App route
  name: string // Name of defined route
  icon: string // Icon for route
  count?: string | number // Number count
}

// Navigation component for routing throughout the app
export const NavigationLink: React.FC<NavigationLinkProps> = (props) => {
  const location = useLocation()
  const isActive = useMemo(() => {
    return location.pathname === props.to
  }, [location.pathname, props.to])

  return (
    <NavLink to={props.to} className={`cc-nav-link ${isActive ? "cc-nav-link--active" : "cc-nav-link--inactive"}`}>
      <RenderIf condition={props.icon.startsWith("http")}>
        <img src={props.icon} alt={props.name} className="w-6 h-6 rounded-full object-center object-cover" />
      </RenderIf>
      <RenderIf condition={!props.icon.startsWith("http")}>
        <Icon icon={props.icon} className={`w-6 h-6`} />
      </RenderIf>
      {props.name}
      {/* <RenderIf condition={!!props.count}>
        <div className='w-fit h-fit bg-secondary-base py-1 px-2 rounded-3xl text-secondary-5 font-bold text-xs'>
          {props.count}
        </div>
      </RenderIf> */}
    </NavLink>
  )
}
