import React from "react"
import { Switch } from "@headlessui/react"
import "./toggle.css"

interface ToggleProps {
  /**
   * Renders the size of the Toggle component
   */
  size: "20" | "24"
  /**
   * Shows the checked state of the Toggle component
   */
  checked?: boolean
  /**
   * OnChange callback for Toggle component
   */
  // eslint-disable-next-line no-unused-vars
  onChange?: (checked: boolean) => void
}

/**
 * Toggle component for user interaction
 */
export const Toggle: React.FC<ToggleProps> = (props) => {
  return (
    <Switch
      data-testid="toggle"
      checked={props?.checked}
      onChange={props?.onChange}
      className={`
      ${props.checked ? "bg-success-main" : "bg-neutral-20 hover:bg-neutral-30"} cc-toggle--${props.size}
          relative flex items-center shrink-0 disabled:bg-neutral-10 cursor-pointer
          rounded-full border-2 border-transparent transition-all duration-200 ease-in-out
      `}
    >
      <span
        aria-hidden="true"
        className={`${props.checked ? `cc-toggle--move-${props.size}` : "translate-x-0"} cc-toggle--inner-${props.size}
            pointer-events-none inline-block transform rounded-full bg-white shadow-lg ring-0 transition-all duration-200 ease-in-out`}
      />
    </Switch>
  )
}
