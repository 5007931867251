import { Icon } from "@iconify/react"
import { RenderIf } from "components/hoc"

export const UpdateStatus = ({ progress }: { progress: number | undefined }) => {
  return (
    <>
      <RenderIf condition={!!progress && progress !== 100}>
        <div className="text-neutral-base flex items-center gap-1 text-[11px]">
          <Icon className="text-sm  loading-indicator" icon="lucide:loader-circle" />
          <span>Uploading...</span>
        </div>
      </RenderIf>
      <RenderIf condition={!!progress && progress === 100}>
        <div className="text-neutral-base flex items-center gap-1 text-[11px]">
          <Icon className="text-sm ml-[3px] text-primary-actions" icon="ph:check-circle-light" />
          <span>Completed</span>
        </div>
      </RenderIf>
    </>
  )
}
