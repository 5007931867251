import { createApi } from "@reduxjs/toolkit/query/react"
import { Zone } from "types/common"
import { axiosBaseQuery } from "../axiosBaseQuery"
import { createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify"

// COMMON SLICE
const common = createSlice({
  name: "common",
  initialState: {
    states: [],
    zones: [],
  },
  reducers: {
    setStates: (state, action) => {
      state.states = action.payload ?? []
    },
    setZones: (state, action) => {
      if (action.payload) {
        state.zones = action.payload.map((item: any) => {
          return { label: item.name, value: item.id }
        })
      }
    },
  },
})

export const { setStates, setZones } = common.actions
export const commonReducer = common.reducer

const baseUrl = `${process.env.REACT_APP_BASE_URL}/common`

export const commonSlice = createApi({
  reducerPath: "commonSlice",
  baseQuery: axiosBaseQuery({ baseUrl }),
  tagTypes: ["States", "Zones", "Lga", "departments", "permissions", "AllStates"],
  refetchOnMountOrArgChange: true,
  endpoints(build) {
    return {
      getZones: build.query<Zone[], any>({
        query: () => ({ url: `/zones`, method: "get" }),
        transformResponse: (response: any) => response.data,
        providesTags: ["Zones"],
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled
            dispatch(setZones(data))
          } catch (error) {
            toast.error("Error fetching zones!!!")
          }
        },
      }),
      getStatesInZone: build.query<any, any>({
        query: (params) => ({ url: `/zones/states`, method: "get", params }),
        transformResponse: (response: any) => response.data,
        providesTags: ["States"],
      }),
      getLgaInState: build.query<any, any>({
        query: (params) => ({ url: `/states/lga`, method: "get", params }),
        transformResponse: (response: any) => response.data,
        providesTags: ["Lga"],
      }),
      getDepartments: build.query<any, any>({
        query: () => ({ url: `/departments`, method: "get" }),
        transformResponse: (response: any) => response.data,
        providesTags: ["departments"],
      }),
      getPermissions: build.query<any, any>({
        query: () => ({ url: `/default-permissions`, method: "get" }),
        transformResponse: (response: any) => response?.data?.permissions || [],
        providesTags: ["permissions"],
      }),
      getAllStates: build.query<any, any>({
        query: () => ({ url: `/states`, method: "get" }),
        transformResponse: (response: any) => response.data,
        providesTags: ["AllStates"],
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled
            dispatch(setStates(data))
          } catch (error) {
            toast.error("Error fetching states!!!")
          }
        },
      }),
      getAllCommunities: build.query<any, any>({
        query: () => ({ url: `/communities`, method: "get" }),
        transformResponse: (response: any) => response.data,
      }),
      getAllWard: build.query<any, any>({
        query: () => ({ url: `/wards`, method: "get" }),
        transformResponse: (response: any) => response.data,
      }),
    }
  },
})

export const {
  useGetDepartmentsQuery,
  useGetZonesQuery,
  useLazyGetZonesQuery,
  useLazyGetStatesInZoneQuery,
  useGetStatesInZoneQuery,
  useLazyGetLgaInStateQuery,
  useGetLgaInStateQuery,
  useGetPermissionsQuery,
  useLazyGetAllStatesQuery,
  useGetAllCommunitiesQuery,
  useGetAllWardQuery,
} = commonSlice
