import { useTable } from "hooks"
import { useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { useGetInterventionAdditionalEligibilityQuery } from "services/api/interventionSlice"
import { useGetSingleLivelihoodQuery } from "services/api/livelihoodSlice"
import { fullDateFormatter } from "utils/dateFormatter"
import { formatCurrency } from "utils/formatCurrency"
import { removeSpecificKeys } from "utils/removeSpecificKeys"
import { stringifyArray } from "utils/stringifyArray"

export default function useViewLivelihood() {
  const [selectedGender, setSelectedGender] = useState<any>([])
  const [selectedState, setSelectedState] = useState<any>([])
  const [selectedCategory, setSelectedCategory] = useState<any>([])
  const { handleSearch, changePage, selectLimit, params } = useTable()
  const { states: nigerianStates } = useSelector((state: any) => state.common)
  const { id: interventionId } = useParams()

  const {
    data: livelihood,
    isFetching,
    isLoading,
  } = useGetSingleLivelihoodQuery(
    {
      id: interventionId,
      params: {
        category_id: "livelihood",
        category_name: selectedCategory.value,
        state: selectedState?.length ? stringifyArray(selectedState) : "",
        gender: selectedGender.value,
        ...removeSpecificKeys({ ...params }, ["sortBy"]),
      },
    },
    { skip: !interventionId },
  )

  const { data: additionalEligibility, isLoading: isFetchingCategory } =
    useGetInterventionAdditionalEligibilityQuery(null)

  const categoryOptions = useMemo(
    () =>
      additionalEligibility
        ?.filter((item: any) => item.category === "livelihood")
        .map((item: any) => {
          return {
            label: item.criteria,
            value: item.criteria,
            id: item.reference,
          }
        }),
    [additionalEligibility],
  )

  const breadCrumbs = [{ title: "Livelihood" }, { title: livelihood?.result?.intervention_title }]

  const summaryData: { name: string; value: any }[] = [
    { name: "Intervention ID", value: livelihood?.result?.intervention_id },
    {
      name: "Base Amount",
      value: formatCurrency(livelihood?.result?.amount ?? 0),
    },
    { name: "Date Created", value: fullDateFormatter(livelihood?.result?.date_created) },
    { name: "Number of Beneficiary", value: livelihood?.result?.no_of_beneficiary },
    { name: "Payment Method", value: livelihood?.result?.payment_method },
    { name: "Status", value: livelihood?.result?.status },
  ]
  return {
    breadCrumbs,
    summaryData,
    selectedGender,
    selectLimit,
    handleSearch,
    changePage,
    setSelectedGender,
    nigerianStates,
    setSelectedState,
    selectedState,
    selectedCategory,
    setSelectedCategory,
    isFetching,
    livelihood,
    isLoading,
    isFetchingCategory,
    params,
    categoryOptions,
  }
}
