import { currencies } from "constants/currency"

export const formatCurrency = (money: any, format = "NGN", symbol = "₦") => {
  const formattedAmount = new Intl.NumberFormat(`en-${format?.substring(0, 2)}`, {
    style: "currency",
    currency: format ?? "NGN",
  }).format(money)
  return formattedAmount.replace(format ?? "NGN", symbol ?? "₦")
}

export const getCurrencyFormat = (currencySymbol: string) => {
  if (!currencySymbol) return "NGN"
  const item = currencies.find((item) => item?.symbol === currencySymbol)
  return item ? item.currency : "NGN"
}
