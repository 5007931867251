import { Icon } from "@iconify/react"
import { Actions, Button, Calendar, ChartWrapper, Dropdown, ManageItem, Spinner, Table } from "components/core"
import { reportHistoryHeader } from "constants/reports"
import useAccountSummary from "./useAccountSummary"
import { reportChartOptions } from "utils/chartOptions"
import { formatCurrency } from "utils/formatCurrency"
import { useNavigate } from "react-router-dom"
import { monthSummaryOptions, period } from "constants/global"
import { RenderIf } from "components/hoc"
import { Period } from "types/common"
import HeaderLoader from "components/core/Loader/HeaderLoader/HeaderLoader"
import { fullDateFormatter, getPreviousYears } from "utils/dateFormatter"
import { OverviewItem } from "components/pages/Reports"
import { addCommasToNumbers } from "utils/addCommasToNumbers"

export const AccountSummary = () => {
  const navigate = useNavigate()
  const { date, reports, selectedReportId, summary, periodFilter, ...state } = useAccountSummary()

  return (
    <div className="w-full">
      <div className="mb-[32px]">
        <h1 className="text-xl text-headers font-semibold mb-[8px]">Account Report</h1>
        <p className="text-sm text-body">
          Ensure that all information provided here are verified and correct before proceeding
        </p>
      </div>
      <RenderIf condition={state.isLoadingSummary && state.isLoadingHistory}>
        <HeaderLoader />
      </RenderIf>
      <RenderIf condition={!state.isLoadingSummary}>
        <>
          <div className="flex justify-end">
            <Button type="button" size="40" theme="primary" onClick={() => navigate("generate-report")}>
              Generate Report
            </Button>
          </div>
          <div className="grid grid-cols-3 gap-[16px] py-[24px] border-b mb-[32px] w-full">
            {state.overview.map((details) => (
              <OverviewItem
                key={details.title}
                icon={<Icon icon={details.icon} className="w-6 h-6 text-secondary-base" />}
                title={details.title}
                value={details.count}
                stat={details.stat}
              />
            ))}
          </div>
          <div className="mb-[32px] border border-[#E6E6E6] rounded-[10px] p-[24px] min-h-[440px]">
            <div className="mb-[40px] flex items-center">
              <Dropdown
                selected={periodFilter?.type}
                wrapperClassName="w-32 !mb-0"
                containerVariant="h-10"
                options={period}
                onSelect={(data: any) => {
                  state.setPeriodFilter({ type: data.value, selected: "" })
                }}
              />
              <div className="ml-[24px]">
                <RenderIf condition={periodFilter?.type === Period.MONTHLY}>
                  <Dropdown
                    selected={periodFilter?.selected}
                    wrapperClassName="w-32 !mb-0"
                    containerVariant="h-10"
                    options={monthSummaryOptions}
                    onSelect={(data: any) => {
                      state.setPeriodFilter({ ...periodFilter, selected: data.value })
                    }}
                  />
                </RenderIf>
                <RenderIf condition={periodFilter?.type === Period.YEARLY}>
                  <Dropdown
                    selected={periodFilter?.selected}
                    wrapperClassName="w-32 !mb-0"
                    containerVariant="h-10"
                    options={getPreviousYears()}
                    onSelect={(data: any) => {
                      state.setPeriodFilter({ ...periodFilter, selected: data.value })
                    }}
                  />
                </RenderIf>
                <RenderIf condition={periodFilter?.type === Period.CUSTOM}>
                  <Calendar
                    startDate={date.startDate}
                    endDate={date.endDate}
                    onChange={state.setDate}
                    selectsRange={true}
                    containerVariant="w-fit"
                  />
                </RenderIf>
              </div>
            </div>
            <RenderIf condition={state.isFetchingSummary}>
              <Spinner />
            </RenderIf>
            <RenderIf condition={!state.isFetchingSummary}>
              <div className="flex items-center">
                <div className="w-[356px] mr-[48px]">
                  <ChartWrapper
                    type="doughnut"
                    options={reportChartOptions}
                    data={state.chartData}
                    containerClass="h-[305px]"
                  />
                </div>
                <div className="w-full">
                  <div>
                    <div className="mb-[4px] flex items-center">
                      <div className="w-[10px] h-[10px] rounded-full bg-[#024F0B] mr-[5px]" />
                      <span className="text-sm text-[#7F8493]">Unpaid Status</span>
                    </div>
                    <p className="text-xl text-[#363840] font-semibold">
                      {formatCurrency(summary?.unpaid_status_amount ?? 0)}
                    </p>
                    <p className="text-xs text-[#B1BAC0] mt-[4px]">
                      {addCommasToNumbers(summary?.unpaid_status_count ?? 0)} Interventions
                    </p>
                  </div>
                  <hr className="my-[24px]" />
                  <div>
                    <div className="mb-[4px] flex items-center">
                      <div className="w-[10px] h-[10px] rounded-full bg-[#C6E4C4] mr-[5px]" />
                      <span className="text-sm text-[#7F8493]">In progress Status</span>
                    </div>
                    <p className="text-xl text-[#363840] font-semibold">
                      {formatCurrency(summary?.in_progress_status_amount ?? 0)}
                    </p>
                    <p className="text-xs text-[#B1BAC0] mt-[4px]">
                      {addCommasToNumbers(summary?.in_progress_status_count ?? 0)} Interventions
                    </p>
                  </div>
                  <hr className="my-[24px]" />
                  <div>
                    <div className="mb-[4px] flex items-center">
                      <div className="w-[10px] h-[10px] rounded-full bg-[#2B892B] mr-[5px]" />
                      <span className="text-sm text-[#7F8493]">Paid Status</span>
                    </div>
                    <p className="text-xl text-[#363840] font-semibold">
                      {formatCurrency(summary?.paid_status_amount ?? 0)}
                    </p>
                    <p className="text-xs text-[#B1BAC0] mt-[4px]">
                      {addCommasToNumbers(summary?.paid_status_count ?? 0)} Interventions
                    </p>
                  </div>
                </div>
              </div>
            </RenderIf>
          </div>
          <div className="grid grid-cols-3 gap-[16px] py-[24px] border-y mb-[32px] w-full">
            {state.overviewB.map((details) => (
              <OverviewItem
                key={details.title}
                icon={<Icon icon={details.icon} className="w-6 h-6 text-secondary-base" />}
                title={details.title}
                value={details.count}
                stat={details.stat}
              />
            ))}
          </div>
        </>
      </RenderIf>
      <RenderIf condition={!state.isLoadingSummary}>
        <Table
          headers={reportHistoryHeader}
          data={reports?.reports ?? []}
          onPageChange={state.changePage}
          selectLimit={state.selectLimit}
          totalCount={Number(reports?.total ?? 0)}
          perPage={state.params?.limit}
          page={Number(reports?.page ?? 0)}
          loading={state?.isFetchingHistory}
        >
          <>
            {reports?.reports?.map((report: any) => (
              <tr data-testid={report?.report_title} key={report?.report_title} className="cc-custom-table-data-row">
                <td title={report?.report_title} className="capitalize w-[300px]">
                  {report?.report_title}
                </td>
                <td>{fullDateFormatter(report?.generated_date)}</td>
                <td>{report?.generated_by}</td>
                <td className="w-[60px]">
                  <Actions actions={state.actions} id={report?.report_id} item={report} />
                </td>
              </tr>
            ))}
          </>
        </Table>
      </RenderIf>
      <RenderIf condition={!!selectedReportId}>
        <ManageItem
          title="Do you want to delete report?"
          actionName="Delete"
          cancelBtnText="Cancel"
          caption="Confirming this action will delete this report. Be sure you want to perform this action."
          loading={state.isDeletingReport}
          onClose={() => state.setSelectedReportId("")}
          onSubmit={state.deleteReport}
        />
      </RenderIf>
    </div>
  )
}
