import { ChangeEvent, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { useLazyGetInterventionsQuery } from "services/api/interventionSlice"
import { useGenerateReportMutation } from "services/api/reportSlice"
import { ISODateFormatter, shortDate } from "utils/dateFormatter"
import { removeKeysWithEmptyValues } from "utils/objectFormatter"
import { tryCatch } from "utils/tryCatch"

const initialDate = {
  createdDate: { startDate: null, endDate: null },
  reviewDate: { startDate: null, endDate: null },
  startDate: { startDate: null, endDate: null },
  endDate: { startDate: null, endDate: null },
}

const initialFormData = {
  reportTitle: "",
  interventions: [],
  totalAmountFrom: "",
  totalAmountTo: "",
  eligibilityAmountFrom: "",
  eligibilityAmountTo: "",
  zones: [],
  states: [],
  lga: [],
  additionalEligibility: [],
  status: [],
  paymentType: [],
  currency: [],
}

export default function useInterventionGenerateReport() {
  const navigate = useNavigate()
  const [showPreview, setShowPreview] = useState(false)
  const [date, setDate] = useState<any>({ ...initialDate })
  const [formData, setFormData] = useState({ ...initialFormData })

  const isDateRangeEmpty = (dateRange: { startDate: any; endDate: any }) => {
    return dateRange.startDate === null && dateRange.endDate === null
  }

  const disableApply = [date.createdDate, date.reviewDate, date.startDate, date.endDate].every(isDateRangeEmpty)

  const [generateReportMutation, { isLoading: isGeneratingReport }] = useGenerateReportMutation()
  const [getInterventionsMutation, { data: interventionsData, isFetching: isFetchingInterventions }] =
    useLazyGetInterventionsQuery()

  const getInterventions = () => {
    tryCatch(async () => {
      await getInterventionsMutation({
        fromCreatedDate: shortDate(date.createdDate.startDate),
        toCreatedDate: shortDate(date.createdDate.endDate),
        fromReviewDate: shortDate(date.reviewDate.startDate),
        toReviewDate: shortDate(date.reviewDate.endDate),
        fromStartDate: shortDate(date.startDate.startDate),
        toStartDate: shortDate(date.startDate.endDate),
        fromEndDate: shortDate(date.endDate.startDate),
        toEndDate: shortDate(date.endDate.endDate),
      })
    })
  }

  const enhancedIntervention = useMemo(() => {
    const { interventions } = interventionsData || {}
    if (!interventions) return interventions === null ? null : []

    return interventions.map(({ intervention_title, intervention_id }) => ({
      label: intervention_title,
      value: intervention_id,
    }))
  }, [interventionsData])

  const breadcrumbs = [{ title: "Intervention" }, { title: "Report Parameters" }]

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const proceed = (e: any) => {
    e.preventDefault()
    setShowPreview(true)
  }

  const checkDate = (date: Date) => {
    return date ? ISODateFormatter(date) : ""
  }

  const generateReport = () => {
    tryCatch(async () => {
      const { reportTitle, interventions, status, zones, states, lga, additionalEligibility, currency, paymentType } =
        formData
      const payload = {
        reportType: "intervention",
        reportTitle,
        interventionIds: interventions?.map((item: any) => item.value),
        interventionStatus: status?.length ? status?.map((item: any) => item.value) : "",
        paymentType: paymentType?.length ? paymentType?.map((item: any) => item.value) : "",
        fromTotalAmount: formData.totalAmountFrom ? Number(formData.totalAmountFrom) : "",
        toTotalAmount: formData.totalAmountTo ? Number(formData.totalAmountTo) : "",
        fromEligibilityAmount: formData.eligibilityAmountFrom ? Number(formData.eligibilityAmountFrom) : "",
        toEligibilityAmount: formData.eligibilityAmountTo ? Number(formData.eligibilityAmountTo) : "",
        zones: zones?.length ? zones?.map((item: any) => item.value.toString()) : "",
        states: states?.length ? states?.map((item: any) => item.id.toString()) : "",
        lga: lga?.length ? lga?.map((item: any) => item.id.toString()) : "",
        additionalEligibility: additionalEligibility?.length
          ? additionalEligibility?.map((item: any) => item.value)
          : "",
        currency: currency?.length ? currency?.map((item: any) => item.value) : "",
        fromCreatedDate: checkDate(date.createdDate.startDate),
        toCreatedDate: checkDate(date.createdDate.endDate),
        fromReviewDate: checkDate(date.reviewDate.startDate),
        toReviewDate: checkDate(date.reviewDate.endDate),
        fromStartDate: checkDate(date.startDate.startDate),
        toStartDate: checkDate(date.startDate.endDate),
        fromEndDate: checkDate(date.endDate.startDate),
        toEndDate: checkDate(date.endDate.endDate),
      }

      const res = await generateReportMutation(removeKeysWithEmptyValues(payload)).unwrap()

      if ([200, 201].includes(res?.code)) {
        toast.success(res?.message)
        navigate(`/reports/intervention/${res?.data?.report_id}`)
      }
    })
  }

  const close = () => {
    setShowPreview(false)
  }

  const refresh = () => {
    setFormData(initialFormData)
    setDate(initialDate)
  }

  return {
    breadcrumbs,
    formData,
    setFormData,
    handleChange,
    date,
    setDate,
    proceed,
    showPreview,
    close,
    disableApply,
    interventions: enhancedIntervention,
    isFetchingInterventions,
    generateReport,
    isGeneratingReport,
    refresh,
    getInterventions,
  }
}
