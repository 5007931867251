import { useTable } from "hooks"
import { useGetInterventionAnalysisQuery, useGetInterventionDisbursementAnalysisQuery } from "services/api/paymentSlice"
import { useGetBeneficiariesQuery } from "services/api/beneficiarySlice"

export default function useDataAnalysis(interventionId: string) {
  const { params } = useTable()
  const { data: analysis, isFetching: isFetchingAnalysis } = useGetInterventionAnalysisQuery({
    params: {
      page: params.page,
      limit: params.limit,
      intervention_id: interventionId,
    },
    data: { payment_schedule_id: "" },
  })
  const { data: disbursementAnalysis, isFetching: isFetchingDisbursementAnalysis } =
    useGetInterventionDisbursementAnalysisQuery({
      interventionId: interventionId,
      data: { payment_schedule_id: "" },
    })
  const { data: beneficiaries, isFetching: isFetchingBeneficiaries } = useGetBeneficiariesQuery({
    intervention: interventionId,
    limit: 20,
    page: 1,
    type: "banked",
  })

  const totalInterventionFunds = {
    data: {
      labels: ["Amount Disbursed", "Amount Left"],
      datasets: [
        {
          data: [disbursementAnalysis?.total_paid_amount ?? 0, disbursementAnalysis?.total_pending_amount ?? 0],
          backgroundColor: ["#2B892B", "#E6BB20"],
          borderWidth: 0,
          barThickness: 24,
          borderRadius: 4,
        },
      ],
    },
    options: {
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false, // Disable tooltips since we're displaying labels always
        },
        datalabels: { display: false },
      },
      scales: {
        x: {
          barPercentage: 1,
          grid: {
            display: false,
          },
        },
        y: { display: false, barPercentage: 1 },
      },
      indexAxis: "y",
    },
  }

  const totalBeneficiaryExpectedAnalysis = {
    data: {
      labels: ["Beneficiary pending", "Received"],
      datasets: [
        {
          data: [65, 35],
          backgroundColor: ["#E6BB20", "#2B892B"],
          borderWidth: 2,
        },
      ],
    },
    options: {
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false, // Disable tooltips since we're displaying labels always
        },
        datalabels: { display: false },
      },
    },
  }

  return {
    analysis,
    isFetchingAnalysis,
    isFetchingBeneficiaries,
    totalBeneficiaryExpectedCount: beneficiaries?.total || "...",
    totalBeneficiaryExpectedAnalysis,
    beneficiariesLeft: disbursementAnalysis?.beneficiaries_left || 0,
    beneficiariesPaid: disbursementAnalysis?.beneficiaries_paid || 0,
    totalInterventionFunds,
    isFetchingDisbursementAnalysis,
    disbursementAnalysis,
  }
}
