import { currencies } from "constants/currency"
import { useTable } from "hooks"
import { useMemo, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import { useGetLgaInStateQuery } from "services/api/commonSlice"
import { useGetInterventionBeneficiaryDetailsQuery } from "services/api/coResponsibilitySlice"
import { useGetInterventionAdditionalEligibilityQuery, useGetInterventionQuery } from "services/api/interventionSlice"
import { fullDateFormatter } from "utils/dateFormatter"
import { convertStateToDropdownOptions } from "utils/dropdown"
import { formatCurrency } from "utils/formatCurrency"
import { removeSpecificKeys } from "utils/removeSpecificKeys"
import { stringifyArray } from "utils/stringifyArray"

const co_responsibility = "co-responsibility"

export default function useViewCoResponsibility() {
  const { id: interventionId } = useParams()
  const [searchParams] = useSearchParams()
  const [selectedState, setSelectedState] = useState<any>([])
  const [selectedLga, setSelectedLga] = useState<any>([])
  const [selectedCategory, setSelectedCategory] = useState<any>([])
  const [selectedGender, setSelectedGender] = useState<any>([])
  const { handleSearch, params, changePage, resetParams, selectLimit } = useTable()
  const { data: lgaInSelectedStates, isLoading: isFetchingLga } = useGetLgaInStateQuery(
    { ids: stringifyArray(selectedState, "id") },
    { skip: !selectedState.length },
  )
  const { data: eligibility = [], isLoading: isFetchingEligibility } =
    useGetInterventionAdditionalEligibilityQuery(null)
  const {
    data: intervention,
    isFetching: isFetchingIntervention,
    isLoading: isLoadingIntervention,
  } = useGetInterventionQuery(interventionId, { skip: !interventionId })
  const {
    data: coResponsibility,
    isFetching: isFetchingCoResponsibility,
    isLoading: isLoadingCoResponsibility,
  } = useGetInterventionBeneficiaryDetailsQuery(
    {
      interventionId,
      state: selectedState?.length ? stringifyArray(selectedState, "value") : "",
      lga: selectedLga?.length ? stringifyArray(selectedLga, "value") : "",
      category_name: selectedCategory?.length ? stringifyArray(selectedCategory, "value") : "",
      ...removeSpecificKeys({ ...params, gender: selectedGender.value }, ["sortBy"]),
    },
    { skip: !interventionId },
  )

  const categories = useMemo(() => {
    return eligibility?.length
      ? eligibility
          ?.filter((item: any) => item?.category === co_responsibility)
          ?.map((item: any) => {
            return { label: item?.criteria, value: item?.criteria }
          })
      : []
  }, [eligibility])

  const breadCrumbs = [
    { title: "Co-responsibility" },
    { title: `${coResponsibility?.result?.intervention_title ?? ""}` },
  ]
  const currency = currencies.find((currency) => currency.name === coResponsibility?.result?.currency)

  const overviewDetails: { name: string; value: any }[] = [
    { name: "Intervention ID", value: coResponsibility?.result?.intervention_id },
    {
      name: "Base Amount",
      value: formatCurrency(coResponsibility?.result?.amount ?? 0, currency?.currency, currency?.symbol),
    },
    { name: "Number of Beneficiary", value: coResponsibility?.result?.no_of_beneficiary },
    { name: "Payment Method:", value: coResponsibility?.result?.payment_method },
    { name: "Date & Time Created", value: fullDateFormatter(coResponsibility?.result?.date_created) },
    { name: "Status", value: searchParams.get("status") },
  ]

  const interventionStates = useMemo(() => {
    return intervention?.states?.length ? convertStateToDropdownOptions(intervention?.states) : []
  }, [intervention?.states])

  const statesLga = useMemo(() => {
    return lgaInSelectedStates?.length ? convertStateToDropdownOptions(lgaInSelectedStates) : []
  }, [lgaInSelectedStates])

  return {
    breadCrumbs,
    overviewDetails,
    handleSearch,
    params,
    changePage,
    resetParams,
    selectLimit,
    coResponsibility: coResponsibility?.result,
    isFetchingCoResponsibility,
    isFetchingIntervention,
    interventionId,
    setSelectedGender,
    selectedGender,
    isLoadingCoResponsibility,
    selectedLga,
    setSelectedLga,
    selectedState,
    setSelectedState,
    isLoadingIntervention,
    interventionStates,
    // interventionStatesLga,
    selectedCategory,
    setSelectedCategory,
    categories,
    isFetchingEligibility,
    statesLga,
    isFetchingLga,
  }
}
