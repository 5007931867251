import { Icon } from "@iconify/react"
import { Dropdown } from "components/core"
import { interventionStatus } from "constants/global"
import { useMemo } from "react"
import { useGetInterventionAdditionalEligibilityQuery } from "services/api/interventionSlice"

interface Props {
  state: any
}

export const InterventionDetails = ({ state }: Props) => {
  const { formData, setFormData } = state
  const { data: additionalEligibility, isLoading: isFetchingCategory } =
    useGetInterventionAdditionalEligibilityQuery(null)

  const categoryOptions = useMemo(
    () =>
      additionalEligibility
        ?.filter((item: any) => item.category === "co-responsibility")
        .map((item: any) => {
          return {
            label: item.criteria,
            value: item.criteria,
            id: item.reference,
          }
        }),
    [additionalEligibility],
  )
  return (
    <div>
      <p className="mb-[8px] font-medium text-sm text-headers">
        <Icon icon="ph:pencil-line" className="inline w-[20px] h-[20px]" /> INTERVENTION DETAILS
      </p>
      <div className="pl-[24px] w-full mb-[20px] rounded-sm">
        <table className="w-full">
          <thead>
            <tr className="text-left border text-xs text-body">
              <th className="p-[8px] border-r font-medium text-start w-[270px]">Intervention Title</th>
              <th className="p-[8px] font-medium border-r text-start w-[270px]">Additional Eligibility</th>
              <th className="p-[8px] font-medium  text-start w-[270px]">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr className="border h-12 text-sm text-body">
              <td className="border-r col-span-5">
                <Dropdown
                  placeholder="Please Select"
                  defaultOption={formData.interventions}
                  containerVariant="!border-none !shadow-none"
                  wrapperClassName="!mb-0"
                  loading={state.isFetchingInterventions}
                  options={state?.interventions}
                  enableSearchOptions={true}
                  multiple={true}
                  onSelect={(data: any) => setFormData({ ...formData, interventions: data })}
                />
              </td>
              <td className="border-r col-span-5">
                <Dropdown
                  placeholder="Please Select"
                  selected={formData.additionalEligibility}
                  containerVariant="!border-none !shadow-none"
                  wrapperClassName="!mb-0"
                  loading={isFetchingCategory}
                  options={categoryOptions}
                  search={false}
                  multiple={true}
                  onSelect={(data: any) => setFormData({ ...formData, additionalEligibility: data })}
                />
              </td>
              <td className="border-r col-span-5">
                <Dropdown
                  selected={formData.interventionStatus}
                  containerVariant="!border-none !shadow-none"
                  wrapperClassName="!mb-0"
                  options={interventionStatus}
                  placeholder="Please Select"
                  multiple={true}
                  search={false}
                  onSelect={(data: any) => setFormData({ ...formData, interventionStatus: data })}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}
