import { useTable } from "hooks"
import { useParams } from "react-router-dom"
import { useGetReportQuery, useLazyExportReportQuery } from "services/api/reportSlice"
import { addCommasToNumbers } from "utils/addCommasToNumbers"
import { fullDateFormatter } from "utils/dateFormatter"
import { exportData } from "utils/exportData"
import { formatCurrency } from "utils/formatCurrency"
import { removeSpecificKeys } from "utils/removeSpecificKeys"
import { tryCatch } from "utils/tryCatch"

export default function useAccountReport() {
  const { reportId } = useParams()
  const { params, changePage, selectLimit } = useTable()
  const { data: report, isLoading: isLoadingReport } = useGetReportQuery({
    reportId,
    ...removeSpecificKeys(params, ["sortBy"]),
  })
  const [exportReportQuery, { isFetching: isExporting }] = useLazyExportReportQuery()
  const breadcrumbs = [{ title: "Report" }, { title: "Account" }]

  const overview = [
    {
      title: "Total Intervention List",
      count: addCommasToNumbers(report?.data?.total_interventions_list ?? 0),
      icon: "ph:users-three",
    },
    {
      title: "Total Beneficiary",
      count: addCommasToNumbers(report?.data?.total_beneficiaries ?? 0),
      icon: "ph:handshake",
    },
    {
      title: "Total Intervention Amount",
      count: formatCurrency(report?.data?.total_intervention_amount ?? 0),
      icon: "ph:handshake",
    },
  ]

  const exportReport = async () => {
    tryCatch(async () => {
      const res = await exportReportQuery(reportId).unwrap()

      const data = res?.data?.interventions?.map((item: any) => {
        return {
          intervention: item?.intervention_name,
          "schedule id": item?.schedule_id,
          "payment cycle": item?.payment_cycle,
          "date created": fullDateFormatter(item?.date_created),
          amount: formatCurrency(item?.amount),
          status: item?.payment_status,
        }
      })
      exportData(data, `${report?.data?.reportTitle} - Account Report`)
    })
  }

  return {
    breadcrumbs,
    overview,
    changePage,
    selectLimit,
    params,
    report,
    isLoadingReport,
    exportReport,
    isExporting,
  }
}
