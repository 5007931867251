import { ReactNode } from "react"
import { ReactComponent as Close } from "assets/icons/close.svg"
import "./Modal.css"

interface ModalProps {
  closeModal?: () => void
  className?: string
  authModal?: boolean
  styles?: string
  children: ReactNode
  title?: string | ReactNode
  position: "modal-right" | "modal-center"
  width?: string
}

export const Modal = ({
  children,
  title,
  className,
  styles,
  width,
  closeModal,
  authModal = false,
  position,
}: ModalProps) => {
  const close = () => {
    closeModal && closeModal()
  }

  return (
    <div className="overflow-hidden">
      <div className="z-[500] overlay"></div>
      <div className={`modal ${position} ${width || "w-[398px]"} ${styles || ""} overflow-y-hidden`}>
        <div
          className={`
          ${className || ""} ${!authModal ? "bg-white w-full" : ""}
          ${position ? "rounded-[8px]" : "rounded-2xl"}
          `}
        >
          {title && (
            <div
              className={`
                ${position === "modal-right" ? "border-b border-[#ECEEEE]" : ""}
                flex justify-between items-center py-[12px] px-[24px]
                `}
            >
              <p className={`text-lg text-headers font-medium capitalize-first`}>{title}</p>
              {closeModal && (
                <button
                  type="button"
                  data-testid="close-modal"
                  onClick={close}
                  className="p-2 bg-[#F9F9FB] rounded-[4px] focus:border-none flex items-center justify-center"
                >
                  <Close />
                </button>
              )}
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  )
}
