import Grievance from "pages/grievance/Grievance"
import CreateGrievance from "pages/grievance/CreateGrievance/CreateGrievance"

export const grievanceRoutes = [
  {
    path: "grievance",
    element: <Grievance />
  },
  {
    path: 'grievance/create',
    element: <CreateGrievance />
  },
]