import { useState } from "react"
import { useTable } from "hooks"
import { formatCurrency } from "utils/formatCurrency"
import { DateRangeType } from "types/common"
// import { useGetRolesQuery } from "services/api/rolesSlice"
// import { usePermission } from "hooks/usePermission/usePermission"
// import { PermissionModule } from "types/permission"

export default function useGroupMember() {
  //const { ROLES_AND_PERMISSION } = PermissionModule
  const { handleSearch, params, changePage, selectLimit } = useTable()
  const [selectedGender, setSelectedGender] = useState<any>([])
  const [selectedStatus, setSelectedStatus] = useState<any>([])
  const [date, setDate] = useState<DateRangeType>({ startDate: null, endDate: null })
  //const { hasModulePermission } = usePermission()
  const history = { history: [], total: 0 }
  //   const { data: roles, isFetching: isFetchingRoles } = useGetRolesQuery(
  //     {
  //       ...params,
  //       roleStatus: selectedStatus.value ?? "",
  //     },
  //     { skip: !hasModulePermission(ROLES_AND_PERMISSION) },
  //   )

  const breadCrumbs = [
    { title: "Livelihood" },
    { title: "Community List" },
    { title: "Kogbagidi Group" },
    { title: "Aminat Adeola" },
  ]

  const overviewTop = [
    { name: "Gender", value: "Female" },
    { name: "Age", value: 45 },
    { name: "State", value: "lagos" },
    { name: "LGA", value: "Mushin" },
    { name: "Ward", value: "WARD 4" },
  ]

  const overviewBottom = [
    { name: "Amount Saved", value: formatCurrency(50000) },
    { name: "Saving Per Cycle", value: formatCurrency(50000) },
    { name: "Cycle Saved", value: 8 },
    { name: "BVN", value: 6664626463 },
    { name: "NIN", value: 6664626463 },
  ]

  const exportHistory = () => {
    alert("export")
  }

  return {
    breadCrumbs,
    handleSearch,
    overviewTop,
    overviewBottom,
    changePage,
    selectLimit,
    params,
    history,
    selectedGender,
    setSelectedGender,
    selectedStatus,
    setSelectedStatus,
    exportHistory,
    date,
    setDate,
    // roles,
    // isFetchingRoles,
  }
}
