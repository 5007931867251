import { Icon } from "@iconify/react"
import { Button, Input } from "components/core"
import { RenderIf } from "components/hoc"
import { useMemo, useState } from "react"
import { checkEmptyProperties } from "utils/checkEmptyProperties"

interface props {
  formData: any
  // eslint-disable-next-line no-unused-vars
  setFormData: (e: any) => void
  proceed: () => void
  back: () => void
}

type QuotaState = {
  [key: string]: string
}

const QuotaStructure = ({ formData, setFormData, proceed, back }: props) => {
  const [quota, setQuota] = useState<QuotaState>(() =>
    (formData.states as any).reduce((acc: any, curr: any) => {
      acc[curr.value] = formData?.quota_structure?.[curr.value] ?? ""
      return acc
    }, {}),
  )

  const unassignedQuota = useMemo(() => {
    if (Object.values(quota).some((value) => value)) {
      const totalAssignedQuota = Object.values(quota).reduce((acc: number, curr: string) => acc + Number(curr), 0)
      return formData.total_number_of_beneficiary - totalAssignedQuota
    }
    return formData.total_number_of_beneficiary
  }, [quota])

  const nextPage = () => {
    setFormData({ ...formData, quota_structure: quota })
    proceed()
  }

  return (
    <div className="mt-8">
      <div className="bg-[#011304] border-primary-actions px-3 py-4 grid grid-cols-2 rounded text-white">
        <div className="">
          <p className="text-primary-100 text-xs">Total Number of Recipient/Beneficiary:</p>
          <p className="text-sm mt-2">{formData.total_number_of_beneficiary}</p>
        </div>
        <div className="">
          <p className="text-xs text-primary-100">Unassigned Quota Left:</p>
          <p className="text-sm mt-2">{unassignedQuota}</p>
        </div>
      </div>
      <table className="w-full mt-6">
        <thead>
          <tr className="text-left h-12 border text-sm text-headers grid grid-cols-12 ">
            <th className="pl-3 font-medium border-r col-span-9 py-3">States</th>
            <th className="pl-3 font-medium col-span-3 py-3">Quota</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(quota).map(([state, value]: any[]) => (
            <tr className="border h-12 text-sm text-body grid grid-cols-12" key={state}>
              <td className="pl-3 border-r col-span-9 py-3">{state}</td>
              <td className="px-3 col-span-3">
                <Input
                  type="number"
                  id="quota"
                  allowDecimals={false}
                  name="quota"
                  value={value}
                  onChange={(e: any) => setQuota((prevQuota: any) => ({ ...prevQuota, [state]: e.target.value }))}
                  className="w-full"
                  variant="border-none !p-0 !h-[40px]"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <RenderIf condition={unassignedQuota !== 0}>
        <p className="flex items-center mt-3">
          <Icon icon="ph:info" className="text-grey-base font-semibold" />
          <span className="text-grey-base text-xs font-medium ml-2">
            {" "}
            Please ensure the total quota matches the number of beneficiaries.
          </span>
        </p>
      </RenderIf>
      <div className="flex justify-start items-center mt-12 space-x-4">
        <Button
          theme="primary"
          size="44"
          className="min-w-[171px]"
          onClick={nextPage}
          disabled={unassignedQuota !== 0 || checkEmptyProperties(quota)}
        >
          Proceed
        </Button>
        <Button theme="transparent" className="w-[171px] px-[30px]" type="button" onClick={back} size="44">
          Back
        </Button>
      </div>
    </div>
  )
}

export default QuotaStructure
