/* eslint-disable no-unused-vars */
import React, { Dispatch, SetStateAction, useEffect } from "react"
import { usePagination, DOTS } from "./usePagination"
import { Icon } from "@iconify/react"
import "./pagination.css"
import { Button } from "../Button/Button"

export interface PaginationProps {
  /**
   * Total number of data in the db.
   */
  count: number
  customStyle?: string
  /**
   * An array of limits you can select.
   */
  limits: Array<string>
  /**
   * Current limit selected.
   */
  currentLimit: string
  /**
   * Updates  the current limit.
   */
  updateCurrentLimit: Dispatch<SetStateAction<string>>
  /**
   * Current page.
   */
  currentPage: number
  /**
   * Total pages.
   */
  totalPages: number
  /**
   * Current page.
   */
  dataLength: number
  /**
   * Go to previous page.
   */
  prev: React.MouseEventHandler<HTMLButtonElement>
  /**
   * Go to next page.
   */
  next: React.MouseEventHandler<HTMLButtonElement>
  /**
   * Go to specific page.
   */
  goToPage: (v: string | number) => void
  /**
   * Other unknown attributes
   */
  [x: string]: any
}

/**
 * Pagination component for iterating through data on a table
 */

export const Pagination: React.FC<PaginationProps> = ({
  currentPage = 1,
  totalPages,
  goToPage,
  prev,
  next,
  limits,
  currentLimit,
  customStyle,
  updateCurrentLimit,
}) => {
  const dropdownButton = React.useRef<HTMLButtonElement>(null!)
  const dropdownMenu = React.useRef<HTMLDivElement>(null!)
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false)
  const paginationRange = usePagination({
    pages: totalPages,
    siblingCount: 1,
    currentPage,
  })

  useEffect(() => {
    // Close the dropdown when clicking outside of it
    window.addEventListener("click", (event: Event) => {
      if (
        !dropdownButton?.current?.contains(event?.target as Node) &&
        !dropdownMenu?.current?.contains(event?.target as Node)
      ) {
        dropdownMenu?.current?.classList?.add("hidden")
        setIsDropdownOpen(false)
      }
    })
  }, [])

  if (currentPage === 0 || paginationRange?.length! < 1) {
    return null
  }

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen)
    if (isDropdownOpen) {
      dropdownMenu?.current?.classList?.remove("hidden")
    } else {
      dropdownMenu?.current?.classList?.add("hidden")
    }
  }

  const setCurrentLimit = (v: string) => {
    updateCurrentLimit(v)
    toggleDropdown()
  }

  return (
    <div className={`cc-pagination-container ${customStyle ?? "pt-4 px-4 pb-2.5 flex justify-between"}`}>
      <div className="flex items-center gap-2 justify-start">
        <Button
          // theme="secondaryStroke"
          size="32"
          type="button"
          onClick={prev}
          disabled={currentPage === 1}
        >
          <Icon icon="heroicons:chevron-left-solid" className="text-xl text-neutral-base mr-4" />
          <p className="text-xs text-body">Prev</p>
        </Button>
        <div className="flex items-center">
          {paginationRange?.map((item) =>
            item === DOTS ? (
              <button key={item.toString()} type="button" className="cc-pagination-number-button-inactive" disabled>
                &#8230;
              </button>
            ) : (
              <button
                key={item.toString()}
                className={
                  currentPage === item ? "cc-pagination-number-button-active" : "cc-pagination-number-button-inactive"
                }
                type="button"
                disabled={currentPage === item}
                onClick={() => goToPage(item)}
              >
                {item}
              </button>
            ),
          )}
        </div>
        <Button
          // theme="secondaryStroke"
          size="32"
          type="button"
          onClick={next}
          disabled={currentPage === totalPages}
        >
          <Icon icon="heroicons:chevron-right-solid" className="text-xl  text-neutral-base mr-4" />
          <p className="text-xs text-body">Next</p>
        </Button>
      </div>
      <div className="relative inline-block text-left">
        <div className="inline-flex justify-center w-full focus:outline-none items-center gap-2">
          <span className="text-neutral-base text-sm font-normal">Rows per page</span>
          <button
            id="dropdown-button"
            ref={dropdownButton}
            onClick={() => toggleDropdown()}
            className="flex p-1 bg-neutral-3 border rounded border-neutral-10 items-center text-neutral-80 text-sm font-normal"
          >
            {currentLimit}{" "}
            <Icon
              icon="ph:caret-up-down-fill"
              className={`${
                isDropdownOpen ? "-rotate-180" : "rotate-0"
              } transform transition-all duration-300 ease-out text-neutral-80`}
            />
          </button>
        </div>
        {isDropdownOpen && (
          <div
            id="dropdown-menu"
            ref={dropdownMenu}
            className="origin-top-right absolute bottom-[10px] right-0 mt-2 max-w-48 
            max-h-48 overflow-y-scroll scrollbar-hide rounded-md shadow-lg bg-white"
          >
            <div
              className="grid gap-1 py-1.5 p-1.5"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="dropdown-button"
            >
              {limits.map((limit) => (
                <button
                  key={limit}
                  onClick={() => {
                    toggleDropdown()
                    updateCurrentLimit(limit)
                  }}
                  className={`flex rounded-md px-4 py-2 text-sm text-neutral-700 cursor-pointer 
                  ${currentLimit === limit ? "bg-neutral-5" : "hover:bg-neutral-3"}`}
                  role="menuitem"
                >
                  {limit}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
