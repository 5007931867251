import { Icon } from "@iconify/react"
import { Button, Modal } from "components/core"
import useDeleteModal from "./useDeleteModal"

const DeleteModal = ({ closeModal, id, unit }: { closeModal: () => void; id: string; unit: string }) => {
  const { deletePayment, isDeletingPaymentSchedule } = useDeleteModal({ closeModal, id, unit })

  const handleDeleteSchedule = () => {
    const data = {
      department: unit?.toUpperCase(),
    }
    deletePayment(data)
  }

  return (
    <Modal
      title={
        <div className="bg-primary-50 w-12 h-12 flex items-center justify-center rounded-lg">
          <Icon icon="ph:circle-half" className="w-10 h-10 text-primary-actions" />
        </div>
      }
      closeModal={closeModal}
      width="w-[400px]"
      position="modal-center"
    >
      <div className="flex flex-col gap-4 pt-2 px-6 pb-6" data-testid="delete-modal">
        <div className="h-[100px]">
          <h1 className="text-xl text-headers">Delete Payment Schedule</h1>
          <p className="text-body font-normal capitalize text-sm">
            Are You sure you want to delete this payment schedule?
          </p>
        </div>

        <div className="grid grid-cols-2 gap-x-2 w-full justify-between">
          <div className="w-full ">
            <Button theme="transparent" size="40" className="w-full py-5" onClick={closeModal}>
              Discard
            </Button>
          </div>
          <div className="w-full ">
            <Button
              theme="primary"
              size="40"
              onClick={handleDeleteSchedule}
              className="w-full py-5"
              loading={isDeletingPaymentSchedule}
            >
              Yes, Proceed
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default DeleteModal
