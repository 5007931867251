import { Icon } from "@iconify/react"
import { Dropdown } from "components/core"
import { defaultFilter } from "mocks/staffs"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useLazyGetLgaInStateQuery, useLazyGetStatesInZoneQuery } from "services/api/commonSlice"
import { tryCatch } from "utils/tryCatch"

interface Props {
  state: any
}

export const Location = ({ state }: Props) => {
  const { formData, setFormData } = state
  const { zones } = useSelector((state: any) => state.common)
  const [location, setLocation] = useState<any>({ lga: [], states: [] })

  const [getStatesInZoneQuery, { isLoading: isFetchingStates }] = useLazyGetStatesInZoneQuery()
  const [getLgaInStateQuery, { isLoading: isFetchingLga }] = useLazyGetLgaInStateQuery()

  const getLgaInState = () => {
    const stateIds = formData.states.map((item: any) => item.id).join(",")
    tryCatch(async () => {
      const res = await getLgaInStateQuery({ ids: stateIds }).unwrap()
      const options = res?.map((item: any) => ({
        label: item?.name,
        value: item?.name,
        id: item.id,
      }))
      setLocation((prevLocation: any) => ({
        ...prevLocation,
        lga: [...defaultFilter, ...options],
      }))
    })
  }

  const getStatesInZone = () => {
    const zoneIds = formData.zones.map((item: any) => item.value).join(",")
    tryCatch(async () => {
      const res = await getStatesInZoneQuery({ ids: zoneIds }).unwrap()
      const options = res?.map((item: any) => ({
        label: item?.name,
        value: item?.name,
        id: item.id,
      }))
      setLocation((prevLocation: any) => ({
        ...prevLocation,
        states: [...defaultFilter, ...options],
      }))
    })
  }

  useEffect(() => {
    if (formData.states?.length) {
      getLgaInState()
      return
    }
    setLocation((prevLocation: any) => ({ ...prevLocation, lga: [] }))
    setFormData((prevFormData: any) => ({ ...prevFormData, lga: [] }))
  }, [formData.states?.length])

  useEffect(() => {
    if (formData.zones?.length) {
      getStatesInZone()
      return
    }
    setLocation((prevLocation: any) => ({ ...prevLocation, states: [] }))
    setFormData((prevFormData: any) => ({ ...prevFormData, states: [] }))
  }, [formData.zones?.length])

  return (
    <div>
      <p className="mb-[8px] font-medium text-sm text-headers">
        <Icon icon="ph:pencil-line" className="inline w-[20px] h-[20px]" /> LOCATION
      </p>
      <div className="pl-[24px] w-full mb-[20px] rounded-sm">
        <table className="w-full">
          <thead>
            <tr className="text-left border text-xs text-body">
              <th className="p-[8px] font-medium border-r text-start w-[270px]">Zone</th>
              <th className="p-[8px] font-medium border-r text-start w-[270px]">State</th>
              <th className="p-[8px] font-medium text-start w-[270px]">Local Government</th>
            </tr>
          </thead>
          <tbody>
            <tr className="border text-sm text-body">
              <td className="border-r">
                <Dropdown
                  defaultOption={formData.zones}
                  containerVariant="!border-none !shadow-none"
                  wrapperClassName="!mb-0"
                  options={[...defaultFilter, ...zones]}
                  showButton={true}
                  enableSearchOptions={true}
                  multiple={true}
                  count={true}
                  onSelect={(data: any) => setFormData({ ...formData, zones: data })}
                />
              </td>
              <td className="border-r">
                <Dropdown
                  defaultOption={formData.states}
                  containerVariant="!border-none !shadow-none"
                  loading={isFetchingStates}
                  wrapperClassName="!mb-0"
                  showButton={true}
                  options={location.states}
                  enableSearchOptions={true}
                  multiple={true}
                  count={true}
                  onSelect={(data: any) => setFormData({ ...formData, states: data })}
                />
              </td>
              <td className="border-r">
                <Dropdown
                  defaultOption={formData.lga}
                  containerVariant="!border-none !shadow-none"
                  wrapperClassName="!mb-0"
                  loading={isFetchingLga}
                  options={location.lga}
                  enableSearchOptions={true}
                  multiple={true}
                  count={true}
                  onSelect={(data: any) => setFormData({ ...formData, lga: data })}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}
