export const completeDataSourceHeaders = [
    {
      name: "",
      sort: false,
      action: null,
    },
    {
      name: "name",
      sort: false,
      action: null,
    },
    {
      name: "added on",
      sort: false,
      action: null,
    },
    {
      name: "last updated",
      sort: true,
      action: null,
    },
    {
      name: "progress",
      sort: false,
      action: null,
    },
    {
      name: "status",
      sort: false,
      action: null,
    },
    {
      name: "",
      sort: false,
      action: null,
    },
  ]


export  const incompleteDataSourceHeaders = [
    {
        name: "",
        sort: false,
        action: null,
      },
    {
      name: "name",
      sort: false,
      action: null,
    },
    {
      name: "version type",
      sort: false,
      action: null,
    },
    {
      name: "added on",
      sort: false,
      action: null,
    },
    {
      name: "progress",
      sort: false,
      action: null,
    },
    {
      name: "status",
      sort: false,
      action: null,
    },
    {
      name: "",
      sort: false,
      action: null,
    },
  ]