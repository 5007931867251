/* eslint-disable no-unused-vars */
export interface FetchedLivelihood {
  livelihood: {
    intervention_id: string
    intervention_title: string
    total_number_of_beneficiary: number
    created_at: string
    total_amount: string
    payment_method: string
    top_amount: string
    eligibility_category: string
    currency: string
    livelihood_id: string
    percentage: string
    status: string
  }[]
  communityCount: string
  totalBeneficiaries: string
  totalInterventionCount: string
  page: number
  limit: number
  totalCount: number
}

export interface FetchedCommunities {
  total_community_list: number
  total_amount_contributed: number
  total_loan_given: number
  limit: number
  page: number
  communities: {
    currency: string
    community_id: string
    community_name: string
    state: {
      name: string
      id: number
    }
    lga: {
      name: string
      id: number
    }
    no_of_groups: number
    total_savings_amount: number
    no_of_beneficiaries: number
    total_loan_given: number
  }[]
}

export interface AddCommunityType {
  title: string
  lga: string
  state: string
}

export interface Group {
  group_id: string
  group_name: string
  ward: string
  lga: string
  duration: string
  state: string
  community_id: string
  created_at: Date
  updated_at: Date
  member_count: string
  loan_given: string
  total_amount_saved: string
}

export interface FetchedGroups {
  community: {
    id: number
    community_id: string
    community_name: string
    lga: string
    state: string
    created_at: Date
    updated_at: Date
  }
  groups: Group[]
  page: number
  limit: number
  count: number
  totalAmountContributed: number
  totalLoanAmount: number
  totalGroupList: number
}

export interface FetchedGroup {
  group: Group
  members: {
    id: number
    member_id: string
    first_name: string
    last_name: string
    amount_saved: string
    loan_collected: string
    saving_per_cycle: string
    cooperative: string
    gender: string
    remark: string
    repayment_status: string
    group_id: string
    created_at: Date
    updated_at: Date
  }[]
  page: number
  limit: number
  count: number
}

export enum LOAN_STATUS {
  DEFAULTER = "defaulter",
  NO_LOAN_COLLECTED = "no loan collected",
  PAID = "paid",
}
