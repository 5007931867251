import { Search, Filter } from "components/core"
import GrievanceDetails from "./GrievanceDetails/GrievanceDetails"
import useGrievanceInfo from "./useGrievanceInfo"
import { RenderIf } from "components/hoc"
import InfoCard from "./InfoCard"
import ProfileInfoLoader from "components/core/Loader/ProfileInfoLoader/ProfileInfoLoader"
import EmptyGrievanceState from "./EmptyState"

const GrievanceInfo = () => {
  const {
    tabs,
    currentTab,
    setCurrentTab,
    resetParamsProxy,
    handleSearch,
    selectedCategory,
    setSelectedCategory,
    selectedSubCategory,
    setSelectedSubCategory,
    isLoadingGrievanceCategories,
    grievancesCategoriesOptions,
    isLoadingGrievanceSubCategories,
    subCategoryOptions,
    grievanceData,
    isLoadingGrievanceData,
    grievanceFormattedData,
    setSelectedGrievance,
    selectedGrievance,
  } = useGrievanceInfo()

  const handleSelectGrievance = (item: any) => {
    setSelectedGrievance(item)
  }

  return (
    <div className="w-full grid grid-cols-6 h-svh">
      <section className="col-span-2 pr-6 pt-6 h-full border-r overflow-y-auto flex flex-col">
        <div className="bg-[#E3E5EB] p-[4px] grid grid-cols-2 rounded-[4px]">
          {tabs.map((tab) => (
            <button
              key={tab.name}
              data-testid={tab.value}
              type="button"
              className={`
              flex items-center justify-center text-sm font-medium px-[16px] py-[6px]
              ${currentTab === tab.name ? "bg-white rounded-[4px] text-headings" : "text-paragraph"}
              `}
              onClick={() => {
                resetParamsProxy()
                setCurrentTab(tab.name)
              }}
            >
              {tab.value}
              <div
                className={`flex items-center border w-fit px-[8px] rounded-[18px] text-white ml-[4px] ${
                  currentTab === tab.name ? "bg-primary-actions" : "bg-placeholder"
                }`}
              >
                <p className={`text-xs px-[8px] py-[4px] rounded-3xl capitalize font-medium`}>{tab.count}</p>
              </div>
            </button>
          ))}
        </div>
        <div className="py-2 flex flex-col gap-y-4 flex-shrink-0">
          <Search onChange={handleSearch} id="Search" name="search" placeholder="Search by name, grievance id" />
          <div className="flex gap-x-2">
            <Filter
              title="Category:"
              options={grievancesCategoriesOptions ?? []}
              loading={isLoadingGrievanceCategories}
              selectedOption={selectedCategory}
              setSelectedOption={setSelectedCategory}
              multiple={false}
            />
            <Filter
              title="Sub Category:"
              options={subCategoryOptions}
              loading={isLoadingGrievanceSubCategories}
              selectedOption={selectedSubCategory}
              setSelectedOption={setSelectedSubCategory}
            />
          </div>
        </div>

        <RenderIf condition={isLoadingGrievanceData}>
          <ProfileInfoLoader />
        </RenderIf>

        <RenderIf condition={!isLoadingGrievanceData && !grievanceData?.grievances?.length}>
          <div className="h-[50vh] flex items-center justify-center">
            <EmptyGrievanceState currentTab={currentTab} />
          </div>
        </RenderIf>

        <RenderIf condition={!isLoadingGrievanceData && grievanceData?.grievances?.length > 0}>
          <div data-testid="rendered-grievance" className="py-2 w-full">
            {Object.keys(grievanceFormattedData).map((dateLabel) => (
              <div key={dateLabel} className="mb-6 flex flex-col ">
                <h2 className="font-medium text-body text-sm mb-4">{dateLabel}</h2>
                {grievanceFormattedData[dateLabel].map((item: any, index: any) => (
                  <div className="" key={item?.complaint_name} data-testid={`info-${index}`}>
                    <InfoCard
                      profile={item}
                      selected={selectedGrievance?.grievance_id === item?.grievance_id}
                      onClick={() => handleSelectGrievance(item)}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
        </RenderIf>
      </section>

      <section className="col-span-4 pt-6 pl-6 h-full overflow-y-auto no-scrollbar relative">
        <GrievanceDetails profile={selectedGrievance} isLoading={isLoadingGrievanceData} />
      </section>
    </div>
  )
}

export default GrievanceInfo
