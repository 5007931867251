export const paymentUnitHeader = [
  {
    name: "intervention",
    sort: false,
    action: null,
  },
  {
    name: "schedule id",
    sort: false,
    action: null,
  },
  {
    name: "payment cycle",
    sort: false,
    action: null,
  },
  {
    name: "date created",
    sort: false,
    action: null,
  },
  {
    name: "total amount",
    sort: false,
    action: null,
  },
  // {
  //   name: "status",
  //   sort: false,
  //   action: null,
  // },
  {
    name: "review status",
    sort: false,
    action: null,
  },
  {
    name: "",
    sort: false,
    action: null,
  },
]

export const paymentScheduleHeader = [
  {
    name: "intervention",
    sort: false,
    action: null,
  },
  {
    name: "schedule id",
    sort: false,
    action: null,
  },
  {
    name: "payment cycle",
    sort: false,
    action: null,
  },
  {
    name: "date created",
    sort: false,
    action: null,
  },
  {
    name: " total amount",
    sort: false,
    action: null,
  },
  // {
  //   name: "status",
  //   sort: false,
  //   action: null,
  // },
  {
    name: "review status",
    sort: false,
    action: null,
  },
  {
    name: "",
    sort: false,
    action: null,
  },
]

export const paymentScheduleInfoHeaders = [
  {
    name: "full name",
    sort: true,
    action: null,
  },
  {
    name: "state",
    sort: true,
    action: null,
  },
  {
    name: "date of birth",
    sort: false,
    action: null,
  },
  {
    name: 'LGA',
    sort: false,
    action: null
  },
  {
    name: 'Ward',
    sort: false,
    action: null
  },
  {
    name: 'Community',
    sort: false,
    action: null
  },
  {
    name: "Account number",
    sort: false,
    action: null,
  },
  {
    name: "Account name",
    sort: false,
    action: null,
  },
  {
    name: 'Bank Name',
    sort: false,
    action: null,
  },
  {
    name: 'NIN',
    sort: false,
    action: null,
  },
  {
    name: 'BVN',
    sort: false,
    action: null,
  },
  {
    name: "gender",
    sort: false,
    action: null,
  },
  {
    name: "source register",
    sort: false,
    action: null,
  },
]

export const paymentsTransactionsHeaders = [
  {
    name: "schedule id",
    sort: false,
    action: null,
  },
  {
    name: "total amount",
    sort: false,
    action: null,
  },
  {
    name: "Transaction Date & Time",
    sort: false,
    action: null,
  },
  {
    name: "Transaction Narration",
    sort: false,
    action: null,
  },
  {
    name: "",
    sort: false,
    action: null,
  },
]

export const paymentScheduleDetailsHeaders = [
  "Schedule ID",
  "Amount",
  "Date and Time Created",
  "Number of Beneficiary",
  "Payment Method",
  "Cycle"
]

export const paymentHeader = [
  'State',
  'Payment Cycle',
  'Ben Count',
  'Amount',
]

export const transactionsInfoHeader = [
  "Schedule ID",
  "Total Batches",
  "Total Batch Amount",
  "Number of Beneficiaries",
]

export const singleTransactionsHeaders = [
  {
    name: "Batch Reference",
    sort: false,
    action: null,
  },
  {
    name: "Number of Beneficiaries",
    sort: false,
    action: null,
  },
  {
    name: "Total Amount (Processed in a batch)",
    sort: false,
    action: null,
  },
  {
    name: "Transaction Fee",
    sort: false,
    action: null,
  },
  {
    name: "Transaction Date & Time",
    sort: false,
    action: null
  },
  {
    name: "Status",
    sort: false,
    action: null,
  },
  {
    name: "",
    sort: false,
    action: null,
  },
]

export const transactionsBatchInfoHeader = [
  "Batch Reference",
  "Number of Beneficiaries",
  "Total Amount In Batch",
  'Transaction Fee'
]

export const transactionBatchHeaders = [
  {
    name: 'Beneficiary Name',
    sort: false,
    action: null
  },
  {
    name: 'Beneficiary ID',
    sort: false,
    action: null
  },
  {
    name: 'State',
    sort: false,
    action: null
  },
  {
    name: 'Account No',
    sort: false,
    action: null
  },
  {
    name: 'Gender',
    sort: false,
    action: null
  },
  {
    name: 'Amount',
    sort: false,
    action: null
  },
  {
    name: 'Transaction Reference',
    sort: false,
    action: null
  },
  {
    name: 'Date & Time',
    sort: false,
    action: null
  },
  {
    name: 'Status',
    sort: false,
    action: null
  },
]