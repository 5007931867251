import { useTable } from "hooks"
import { useState } from "react"
import { useGetAllLivelihoodQuery, useLazyGetAllLivelihoodQuery } from "services/api/livelihoodSlice"
import { addCommasToNumbers } from "utils/addCommasToNumbers"
import { shortDate } from "utils/dateFormatter"
import { removeKeysWithEmptyValues } from "utils/removeKeysWithEmptyValues"
import { stringifyArray } from "utils/stringifyArray"
import { tryCatch } from "utils/tryCatch"
import dayjs from "dayjs"
import { exportData } from "utils/exportData"
import { useSelector } from "react-redux"

interface DateType {
  startDate: Date | null
  endDate: Date | null
}

export default function useLivelihood() {
  const { states: nigerianStates } = useSelector((state: any) => state.common)
  const [selectedState, setSelectedState] = useState<any>([])
  const [selectedStatus, setSelectedStatus] = useState<any>([])
  const { handleSearch, changePage, selectLimit, params } = useTable()
  const [date, setDate] = useState<DateType>({ startDate: null, endDate: null })
  const dateRangeFilter = date.endDate && { fromDate: shortDate(date.startDate), toDate: shortDate(date.endDate) }

  const { data: livelihoods, isFetching } = useGetAllLivelihoodQuery({
    ...params,
    ...dateRangeFilter,
    status: selectedStatus.length ? stringifyArray(selectedStatus) : "",
    state: selectedState.length ? stringifyArray(selectedState) : "",
  })

  const [getAllLivelihood, { isFetching: isExporting }] = useLazyGetAllLivelihoodQuery()

  const exportAllLivelihoods = () => {
    tryCatch(async () => {
      const res = await getAllLivelihood(
        removeKeysWithEmptyValues({
          search: params.search ?? "",
          status: selectedStatus.length ? stringifyArray(selectedStatus) : "",
          state: selectedState.length ? stringifyArray(selectedState) : "",
          ...dateRangeFilter,
          noLimit: true,
        }),
      ).unwrap()
      const data = res?.livelihood?.map((item: any) => {
        return {
          "Livelihood ID": item?.livelihood_id,
          "intervention name": item?.intervention_title,
          "Total amount": item?.total_amount,
          "Top-up amount": item?.top_amount,
          "Total number of beneficiary": item?.total_number_of_beneficiary,
          "Payment method": item?.payment_method,
          percentage: `${item?.percentage}%`,
          currency: item?.currency,
          "Date Created": dayjs(item.created_at).format("MMMM DD YYYY"),
          Status: item?.status,
        }
      })
      exportData(data, "Livelihood List")
    })
  }

  const summaryData = [
    {
      title: "Total Beneficiaries",
      icon: "ph:users-three",
      count: addCommasToNumbers(Number(livelihoods?.totalBeneficiaries) ?? 0),
    },
    {
      title: "Total Livelihood",
      icon: "ph:handshake",
      count: addCommasToNumbers(Number(livelihoods?.totalCount) ?? 0),
    },
    {
      title: "Total Community List",
      icon: "cil:brush-alt",
      count: addCommasToNumbers(Number(livelihoods?.communityCount) ?? 0),
    },
  ]

  return {
    date,
    setDate,
    summaryData,
    nigerianStates,
    setSelectedState,
    selectedStatus,
    setSelectedStatus,
    selectedState,
    handleSearch,
    changePage,
    selectLimit,
    exportAllLivelihoods,
    isExporting,
    livelihoods,
    isFetching,
  }
}
