import { Icon } from "@iconify/react"
import { Dropdown, Input } from "components/core"
import { defaultFilter } from "constants/global"
import { reviewStatus } from "constants/reports"

interface Props {
  state: any
}

export const ReviewDetails = ({ state }: Props) => {
  const { formData, setFormData, handleChange } = state

  return (
    <div>
      <p className="mb-[8px] font-medium text-sm text-headers">
        <Icon icon="ph:pencil-line" className="inline w-[20px] h-[20px]" /> REVIEW DETAILS
      </p>
      <div className="pl-[24px] w-full mb-[20px] rounded-sm">
        <table className="w-full">
          <thead>
            <tr className="text-left border text-xs text-body">
              <th className="p-[8px] font-medium border-r text-start w-[270px]">Amount</th>
              <th className="p-[8px] font-medium text-start w-[270px]">Review Status</th>
            </tr>
          </thead>
          <tbody>
            <tr className="border h-12 text-sm text-body">
              <td className="px-3 border-r">
                <div className="flex">
                  <Input
                    type="number"
                    id="amountFrom"
                    name="amountFrom"
                    // readOnly={loading}
                    placeholder="From"
                    value={formData?.amountFrom}
                    onChange={handleChange}
                    className="!w-full border-r"
                    variant="border-none !p-0 !h-[40px]"
                  />
                  <Input
                    type="number"
                    id="amountTo"
                    name="amountTo"
                    // readOnly={loading}
                    placeholder="To"
                    value={formData?.amountTo}
                    onChange={handleChange}
                    className="!w-full pl-[10px]"
                    variant="border-none !p-0 !h-[40px]"
                  />
                </div>
              </td>
              <td>
                <Dropdown
                  defaultOption={formData?.reviewStatus}
                  placeholder="Select"
                  containerVariant="!border-none !shadow-none"
                  wrapperClassName="!mb-0"
                  options={[...defaultFilter, ...reviewStatus]}
                  multiple={true}
                  count={true}
                  onSelect={(data: any) => setFormData({ ...formData, reviewStatus: data })}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}
