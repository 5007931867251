import { useTable } from "hooks"
import { useParams } from "react-router-dom"
import { useGetReportQuery, useLazyExportReportQuery } from "services/api/reportSlice"
import { addCommasToNumbers } from "utils/addCommasToNumbers"
import { fullDateFormatter } from "utils/dateFormatter"
import { exportData } from "utils/exportData"
import { formatCurrency } from "utils/formatCurrency"
import { removeSpecificKeys } from "utils/removeSpecificKeys"
import { tryCatch } from "utils/tryCatch"

export default function useInterventionReport() {
  const { reportId } = useParams()
  const { params, changePage, selectLimit } = useTable()
  const { data: report, isLoading: isLoadingReport } = useGetReportQuery({
    reportId,
    ...removeSpecificKeys(params, ["sortBy"]),
  })
  const [exportReportQuery, { isFetching: isExporting }] = useLazyExportReportQuery()

  const breadcrumbs = [{ title: "Report" }, { title: "Intervention" }]

  const overview = [
    {
      title: "Total Intervention List",
      count: addCommasToNumbers(report?.data?.totalInterventionCount ?? 0),
      icon: "ph:handshake",
    },
    {
      title: "Total Number of States",
      count: addCommasToNumbers(report?.data?.totalStates ?? 0),
      icon: "ph:handshake",
    },
    {
      title: "Total Intervention Amount",
      count: formatCurrency(report?.data?.totalAmount ?? 0),
      icon: "ph:handshake",
    },
    {
      title: "Total Eligibility Amount",
      count: formatCurrency(report?.data?.eligibilityAmount ?? 0),
      icon: "ph:handshake",
    },
  ]

  const exportReport = async () => {
    tryCatch(async () => {
      const res = await exportReportQuery(reportId).unwrap()

      const data = res?.data?.interventions?.map((item: any) => {
        return {
          "intervention title": item?.interventionTitle,
          "no. of state": item?.totalStates,
          "no. of zone": item?.totalZones,
          "no. of LGA": item?.totalLgas,
          amount: formatCurrency(item?.totalAmount),
          status: item?.status,
          "created date": fullDateFormatter(item?.createdDate),
          eligibility: item?.eligibility,
        }
      })
      exportData(data, `${report?.data?.reportTitle} - Intervention Report`)
    })
  }

  return {
    breadcrumbs,
    overview,
    changePage,
    selectLimit,
    params,
    report,
    isLoadingReport,
    exportReport,
    isExporting,
  }
}
