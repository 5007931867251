import { Button, Input } from "components/core"
import { Dropdown } from "components/core/Dropdown/Dropdown"
import RadioButton from "components/core/RadioButton/RadioButton"
import { paymentMechanism, paymentRecurrence } from "constants/intervention"
import usePayment from "./useInterventionPayments"

interface Props {
  formData: any
  // eslint-disable-next-line no-unused-vars
  handleChange: (e: any) => void
  proceed: () => void
  back: () => void
  setFormData: any
}

const Payment = ({ formData, setFormData, handleChange, proceed, back }: Props) => {
  const { disableBtn, allCurrencies, selectedCurrencyName } = usePayment({ formData })

  return (
    <div className="mt-8">
      <p className="pb-2 text-xs text-headers font-medium">Payment method</p>
      <div className="grid grid-cols-2 gap-4 mb-4">
        <RadioButton
          name="Cash"
          label="Cash"
          checked={formData.payment_method === "cash"}
          value={formData.payment_method}
          onChange={() => setFormData({ ...formData, payment_method: "cash" })}
          customClass="w-6 h-6 !border-grey-200"
          variant="border border-grey-200 rounded-lg p-4"
        />
        <RadioButton
          name="Kind"
          disabled={true}
          label="Kind"
          checked={formData.payment_method === "kind"}
          value={formData.payment_method}
          // onChange={() => setFormData({ ...formData, payment_method: "kind" })}
          variant="border border-grey-200 rounded-lg p-4 bg-grey-150"
          customClass="w-6 h-6 !border-grey-200"
        />
      </div>
      {formData.payment_method === "cash" && (
        <Input
          type="number"
          id="amount"
          label={
            <>
              Amount<span className="text-body ml-1">(Value)</span>
            </>
          }
          name="amount"
          value={formData.amount}
          onChange={handleChange}
          className="w-full"
        />
      )}
      <div className="grid grid-cols-2 gap-4 mt-4 items-center">
        <Dropdown
          label="Payment Recurrence"
          selected={formData.payment_recurrence}
          options={paymentRecurrence}
          onSelect={(data: any) => {
            setFormData({ ...formData, payment_recurrence: data.value })
          }}
        />
        <Input
          type="number"
          id="payment_cycle"
          label="Payment Cycle"
          name="payment_cycle"
          value={formData.payment_cycle}
          onChange={handleChange}
          className="mb-2 w-full"
        />
      </div>
      <div className="grid grid-cols-2 gap-4 mt-4 items-center">
        <Dropdown
          label="Payment Mechanism"
          selected={formData.payment_mechanism}
          options={paymentMechanism}
          search={false}
          onSelect={(data: any) => {
            setFormData({ ...formData, payment_mechanism: data.value })
          }}
        />
        <Dropdown
          label="Currency"
          enableSearchOptions={true}
          selected={selectedCurrencyName}
          options={allCurrencies}
          onSelect={(data: any) => {
            setFormData({ ...formData, currency: data.value })
          }}
        />
      </div>
      <div className="flex justify-start items-center mt-12 space-x-4">
        <Button theme="primary" size="44" className="min-w-[171px]" onClick={proceed} disabled={disableBtn}>
          Proceed
        </Button>
        <Button theme="transparent" className="w-[171px] px-[30px]" type="button" onClick={back} size="44">
          Back
        </Button>
      </div>
    </div>
  )
}

export default Payment
