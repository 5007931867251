export default function HeaderLoader() {
  return (
    <div className="p-6 rounded-[8px] mt-3 gap-8 min-h-[194px] group animate-pulse transition-all ease-out duration-700">
      <div className="flex flex-col gap-2 transition-all ease-out duration-700 pb-[32px]">
        <div className="bg-neutral-10 h-3 w-1/3 rounded-full"></div>
        <div className="h-2 bg-neutral-10 rounded-full"></div>
        <div className="h-2 bg-neutral-10 rounded-full"></div>
        <div className="h-2 bg-neutral-10 rounded-full"></div>
      </div>
      <div className="h-[.3px] bg-neutral-10 rounded-lg" />
      <div className="mt-[16px] grid grid-cols-4 gap-[20px]">
        {[1, 2, 3, 4].map((item) => (
          <div key={item} className="gap-2 transition-all ease-out duration-400 w-full">
            <div className="w-full h-3 bg-neutral-10 rounded-full mb-[8px]"></div>
            <div className="w-full h-2 bg-neutral-10 rounded-full"></div>
          </div>
        ))}
      </div>
    </div>
  )
}
