import { useDeletePaymentScheduleMutation } from "services/api/paymentSlice"
import { toast } from "react-toastify"
import { tryCatch } from "utils/tryCatch"
import { useNavigate } from "react-router-dom"

export default function useDeleteModal({ closeModal, id, unit }: { closeModal: () => void; id: string; unit: string }) {
  const navigate = useNavigate()

  const [deletePaymentSchedule, { isLoading: isDeletingPaymentSchedule }] = useDeletePaymentScheduleMutation({})

  const deletePayment = async (data: any) => {
    const callback = (res: any) => {
      if ([200, 201].includes(res?.code)) {
        toast.success(res.message)
        navigate(`/payments/${unit}`)
      }
    }

    tryCatch(async () => {
      let res = await deletePaymentSchedule({ id, data }).unwrap()
      callback(res)
      closeModal()
    })
  }

  return {
    deletePayment,
    isDeletingPaymentSchedule,
  }
}
